// @flow

import { createComponentWithProxy } from 'react-fela'
import classNames from 'classnames'

const styleRules = ({ theme, width = 'auto' }) => ({
  backgroundColor: theme.DataGrid.cell.base,
  boxSizing: 'border-box',
  className: classNames('DataGridCellRenderer', {
    [`--width-${width}`]: width !== 'auto',
  }).replace(/\s/, ''),
  color: theme.DataGrid.cell.text,
  flexGrow: 1,
  paddingBottom: theme.spacing(0.5),
  paddingLeft: theme.Grid.gutter,
  paddingRight: theme.Grid.gutter,
  paddingTop: theme.spacing(0.5),
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  minWidth: width,
  width,
})

export default createComponentWithProxy(styleRules)
