// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import Tick from 'react-ui/assets/icons/tick.svg'
import Icon from 'react-ui/components/Icon'

export type StepType = {
  display: Node,
  label: string,
}
const styleRules = ({ isActive, theme }) => ({
  checkedTick: {
    fill: 'transparent',
  },
  step: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
  },
  stepCircle: {
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'normal',
    justifyContent: 'center',
    fontWeight: 'bold',
    borderRadius: '50%',
    backgroundColor: theme.palette.lightBackground,
    color: 'rgba(76, 85, 140, 0.30)',
    ...(isActive && {
      backgroundColor: '#08B2CC',
      color: theme.palette.input.label.base,
    }),
    width: '80px',
    height: '80px',
    fontSize: '34px',
    [theme.breakpoints.queries.md]: {
      width: '120px',
      height: '120px',
      fontSize: '50px',
    },
  },
  label: {
    color: theme.palette.input.error.text,
    fontSize: '14px',
    lineHeight: '18px',
    textAlign: 'center',
    position: 'absolute',
    bottom: '-90px',
    minHeight: '85px',
    [theme.breakpoints.queries.md]: {
      fontSize: 'inherit',
      lineHeight: '22px',
    },
  },
})

const Step = ({ styles, rules, step, isActive }) => {
  const { display, label } = step

  return (
    <>
      <div className={styles.step}>
        <div className={styles.stepCircle}>
          {isActive ? (
            <Icon
              as={Tick}
              extend={rules.checkedTick}
              scale={2}
              data-testid="Icon"
            />
          ) : (
            display
          )}
        </div>
        <div className={styles.label}>{label}</div>
      </div>
    </>
  )
}

export default connect(styleRules)(Step)
