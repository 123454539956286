// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ axis = 'vertical', theme, units }) => ({
  className: `Spacer--${axis}--space-${units}`,
  display: axis === 'horizontal' ? 'inline-block' : 'block',
  height: axis === 'vertical' ? theme.spacing(units) : 0,
  width: axis === 'horizontal' ? `calc(${theme.Grid.gutter} * ${units})` : 0,
})

export default createComponentWithProxy(styleRules, 'span')
