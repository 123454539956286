// @flow

import React, {
  type Element,
  type Node,
  Children,
  cloneElement,
  useState,
} from 'react'

import AccordionItem from './AccordionItem'

type ChildType = Element<AccordionItem>

type PropsType = {
  children: Node,
  multiple?: boolean,
}

const Accordion = ({ children, multiple = true }: PropsType) => {
  const [openChildren, setOpenChildren] = useState({})

  const onToggleOpen = key => {
    setOpenChildren(prevOpenChildren => ({
      ...(multiple ? prevOpenChildren : {}),
      [key]: !prevOpenChildren[key],
    }))
  }

  const modifiedChildren = Children.map(
    (children: Node),
    (child: ChildType, index) => {
      const childKey = child.key || `Accordion__child-${index}`
      return cloneElement(child, {
        isOpen: openChildren[childKey],
        onToggleOpen: () => onToggleOpen(childKey),
      })
    },
  )

  return <>{modifiedChildren}</>
}

export default Accordion
