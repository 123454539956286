// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'

import {
  BasicSummaryTable,
  DonutSummary as DonutSummaryChart,
} from 'react-ui/components/Charts'
import ReportCard from 'react-ui/components/ReportCard'

import ReportsView from '../ReportsView'
import ReportWithSettings from '../ReportWithSettings'
import TenantFormulasLoader from '../TenantFormulasLoader'

type PropsType = {
  print?: boolean,
  title: React.Node,
  +variables: Object,
}

const query = graphql`
  query EffectivenessOutcomesReportQuery(
    $formula: ID!
    $from: DateTime!
    $tenant: ID!
    $to: DateTime!
    $report_view: ReportView
  ) {
    viewer {
      reports {
        effectiveness_baseline(
          formula: $formula
          from: $from
          tenant: $tenant
          to: $to
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
        effectiveness_score_change(
          formula: $formula
          from: $from
          tenant: $tenant
          to: $to
          report_view: $report_view
        ) {
          traces {
            name
            type
            x
            y
          }
        }
      }
    }
  }
`

const EffectivenessOutcomesReport = ({
  print,
  title,
  variables,
}: PropsType) => {
  // Localization
  const { t: translation } = useTranslation('reporting')

  return (
    <ReportsView print={print} title={title}>
      <TenantFormulasLoader variables={{ tenants: variables.tenants }}>
        {tenants =>
          tenants.map(tenant =>
            tenant.effectiveness_important_formulas.map(
              ({ id, formula, label }) => (
                <ReportWithSettings
                  key={id}
                  query={query}
                  variables={{
                    formula: formula.id,
                    from: variables.from,
                    tenant: tenant.id,
                    to: variables.to,
                    report_view: variables.report_view,
                  }}
                >
                  {data => {
                    const {
                      effectiveness_baseline,
                      effectiveness_score_change,
                    } = data.viewer.reports

                    return (
                      <ReportCard title={`${tenant.name}: ${label}`} key={id}>
                        <ReportCard.SubHeading>
                          {translation('initial_distribution_of_results')}
                        </ReportCard.SubHeading>
                        <BasicSummaryTable
                          traces={effectiveness_baseline.traces}
                        />

                        <ReportCard.SubHeading>
                          {translation('change_in_results_over_time')}
                        </ReportCard.SubHeading>
                        <DonutSummaryChart
                          traces={effectiveness_score_change.traces}
                          title={translation('total_individuals')}
                        />
                      </ReportCard>
                    )
                  }}
                </ReportWithSettings>
              ),
            ),
          )
        }
      </TenantFormulasLoader>
    </ReportsView>
  )
}
export default EffectivenessOutcomesReport
