// @flow

import React from 'react'
import { connect } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import Divider from 'react-ui/components/Divider'
import { dateTimeFormat } from 'services/dateTime'

import { type FelaPropsType } from 'react-ui/typing'
import { type ClinicianNotesListView_clinician_note } from './__generated__/ClinicianNotesListView_clinician_note.graphql'
import { type ClinicianNotesListViewQueryResponse } from './__generated__/ClinicianNotesListViewQuery.graphql'

type PropsType = FelaPropsType & {
  clinician_note: ClinicianNotesListView_clinician_note,
}

const styleRules = ({ theme }) => ({
  ClinicianNotesListView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    margin: '5px',
    paddingBottom: theme.Grid.gutter,
    textAlign: 'left',
  },
  Header: {
    fontSize: theme.Small.fontSize,
    color: theme.palette.component.textMuted,
  },
  text: {
    marginTop: theme.Grid.gutter,
  },
})

const ClinicianNotesListView = (props: PropsType) => {
  if (!props.clinician_note) return null

  const { clinician_note: { nodes }, styles } = props

  return (nodes || []).map(note => {
    if (!note) return null
    const { id, created_at, author: { name }, text } = note

    return (
      <div className={styles.ClinicianNotesListView} key={id}>
        <header className={styles.Header}>
          {dateTimeFormat(new Date(created_at))}, {name}
        </header>

        <div className={styles.text}>{text}</div>
        <Divider />
      </div>
    )
  })
}

export const ClinicianNotesListViewLoader = createFragmentContainer(
  connect(styleRules)(ClinicianNotesListView),
  {
    clinician_note: graphql`
      fragment ClinicianNotesListView_clinician_note on ClinicianNoteConnection {
        nodes {
          id
          created_at
          text
          author {
            name
          }
        }
      }
    `,
  },
)

const query = graphql`
  query ClinicianNotesListViewQuery($userId: ID!) {
    user(id: $userId) {
      clinician_notes {
        ...ClinicianNotesListView_clinician_note
      }
    }
  }
`

const ClinicianNotesListViewQueryLoader = ({ userId }: { userId: string }) => (
  <QueryRenderer
    environment={useRelayEnvironment()}
    query={query}
    render={response => (
      <QueryRendererLoadingIndicator response={response}>
        {(res: ClinicianNotesListViewQueryResponse) => {
          if (res.user && res.user.clinician_notes) {
            const notesList = res.user.clinician_notes
            return <ClinicianNotesListViewLoader clinician_note={notesList} />
          }

          return null
        }}
      </QueryRendererLoadingIndicator>
    )}
    variables={{
      userId,
    }}
  />
)

export default ClinicianNotesListViewQueryLoader
