// @flow

import { createComponentWithProxy } from 'react-fela'

const cardStyle = ({
  backgroundColor,
  maxWidth,
  minHeight,
  padding,
  justifyContent,
}) => ({
  backgroundColor: backgroundColor || '#fff',
  borderRadius: '5px',
  boxShadow: '0 3px 6px 2px rgba(7, 7, 8, 0.14)',
  display: 'flex',
  flexDirection: 'column',
  lineHeight: '2rem',
  margin: '10px',
  maxWidth: maxWidth || '100%',
  minHeight: minHeight || '100%',
  overflow: 'hidden',
  padding: padding || '30px',
  justifyContent: justifyContent || 'unset',
})

export default createComponentWithProxy(cardStyle)
