// @flow

import { graphql } from 'react-relay'

export const assignableCliniciansQuery = graphql`
  query AssignableCliniciansPaginationQuery(
    $individuals: IndividualsSelectionInput!
    $tenant_id: ID!
    $count: Int!
    $cursor: String
    $search: String
  ) {
    viewer {
      ...AssignableCliniciansPagination_tenant
    }
  }
`

export const assignableCliniciansFragment = graphql`
  fragment AssignableCliniciansPagination_tenant on Viewer
    @refetchable(queryName: "AssignableCliniciansPaginationRefetchQuery") {
    tenant(id: $tenant_id) {
      multi_select_assignable_clinicians(
        search: $search
        individuals: $individuals
        first: $count
        after: $cursor
      )
        @connection(
          key: "AssignableCliniciansPagination_multi_select_assignable_clinicians"
        ) {
        edges {
          node {
            email
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`
