// @flow

/**
 * This is a direct copy of the createConnectedRouter function within Found:
 * https://github.com/4Catalyzer/found/blob/master/src/createConnectedRouter.js
 * (as of version 0.4.9)
 *
 * The only change we've made was changing the `pure` argument to react-redux
 * from `false` to `true`, as the router was re-rendering whenever our Redux
 * store updated unrelated data (such as flash messages).
 * See QUOLL-173 for more details.
 */

import { connect } from 'react-redux'
import ActionTypes from 'found/ActionTypes'
import createBaseRouter from 'found/createBaseRouter'

function resolveMatch(match) {
  return {
    type: ActionTypes.RESOLVE_MATCH,
    payload: match,
  }
}

// $DisableFlow (this was copied from a third-party library)
export default function createConnectedRouter({
  getFound = ({ found }) => found,
  ...options
}) {
  // $DisableFlow (this was copied from a third-party library)
  return connect(
    state => {
      const { match, resolvedMatch } = getFound(state)
      return { match, resolvedMatch }
    },
    {
      onResolveMatch: resolveMatch,
    },
    null,
    {
      pure: true, // THIS LINE WAS CHANGED
      getDisplayName: () => 'ConnectedRouter',
    },
  )(createBaseRouter(options))
}
