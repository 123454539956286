/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IndividualsAdminInvitePage_viewer$ref = any;
export type IndividualsAdminInvitePage_QueryVariables = {||};
export type IndividualsAdminInvitePage_QueryResponse = {|
  +viewer: {|
    +$fragmentRefs: IndividualsAdminInvitePage_viewer$ref
  |}
|};
export type IndividualsAdminInvitePage_Query = {|
  variables: IndividualsAdminInvitePage_QueryVariables,
  response: IndividualsAdminInvitePage_QueryResponse,
|};
*/


/*
query IndividualsAdminInvitePage_Query {
  viewer {
    ...IndividualsAdminInvitePage_viewer
    id
  }
}

fragment IndividualsAdminInvitePage_viewer on Viewer {
  currentUser {
    current_role {
      __typename
      ... on RoleInterface {
        __isRoleInterface: __typename
        id
        tenant {
          hide_mobile_on_invite
          id
        }
        tenants {
          ...UserInviteForm_tenants
          id
        }
      }
      ... on AdminRole {
        id
      }
      ... on ClinicianRole {
        id
      }
      ... on IndividualRole {
        id
      }
      ... on LeadClinicianRole {
        id
      }
      ... on ManagerRole {
        id
      }
      ... on OwnerRole {
        id
      }
      ... on ResearcherRole {
        id
      }
      ... on SuperRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
    id
  }
}

fragment UserInviteForm_tenants on Tenant {
  id
  name
  deactivated
  emr_provider_id
  emr_integration {
    emr_provider {
      name
      user_id_field_name
    }
    required
  }
  allowAdminClinicalAssignments
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IndividualsAdminInvitePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "IndividualsAdminInvitePage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IndividualsAdminInvitePage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tenant",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hide_mobile_on_invite",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tenant",
                        "kind": "LinkedField",
                        "name": "tenants",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deactivated",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emr_provider_id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmrIntegrationType",
                            "kind": "LinkedField",
                            "name": "emr_integration",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EmrProvider",
                                "kind": "LinkedField",
                                "name": "emr_provider",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "user_id_field_name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "required",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowAdminClinicalAssignments",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "RoleInterface",
                    "abstractKey": "__isRoleInterface"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "AdminRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "ClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "LeadClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "ManagerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "OwnerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "ResearcherRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "SuperRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v2/*: any*/),
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "41e07c121835bf5522f09a8e46bd0f4a",
    "id": null,
    "metadata": {},
    "name": "IndividualsAdminInvitePage_Query",
    "operationKind": "query",
    "text": "query IndividualsAdminInvitePage_Query {\n  viewer {\n    ...IndividualsAdminInvitePage_viewer\n    id\n  }\n}\n\nfragment IndividualsAdminInvitePage_viewer on Viewer {\n  currentUser {\n    current_role {\n      __typename\n      ... on RoleInterface {\n        __isRoleInterface: __typename\n        id\n        tenant {\n          hide_mobile_on_invite\n          id\n        }\n        tenants {\n          ...UserInviteForm_tenants\n          id\n        }\n      }\n      ... on AdminRole {\n        id\n      }\n      ... on ClinicianRole {\n        id\n      }\n      ... on IndividualRole {\n        id\n      }\n      ... on LeadClinicianRole {\n        id\n      }\n      ... on ManagerRole {\n        id\n      }\n      ... on OwnerRole {\n        id\n      }\n      ... on ResearcherRole {\n        id\n      }\n      ... on SuperRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment UserInviteForm_tenants on Tenant {\n  id\n  name\n  deactivated\n  emr_provider_id\n  emr_integration {\n    emr_provider {\n      name\n      user_id_field_name\n    }\n    required\n  }\n  allowAdminClinicalAssignments\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ade696228fe1873e206f3b5617b36cd';

module.exports = node;
