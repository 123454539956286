// @flow

import { graphql } from 'react-relay'

export const query = {
  user_question_sets: graphql`
    fragment UserQuestionSetTable_user_question_sets on UserQuestionSet
      @relay(plural: true) {
      ...UserQuestionSetDetailsView_user_question_set
      id
      submitted_answer_sets {
        submitted_at
        skip_reason_value
        skipped
        answered_by {
          ... on RoleInterface {
            id
            user {
              name
            }
          }
        }
      }
      question_set {
        id
        label
      }
    }
  `,
}
