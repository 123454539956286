// @flow

import React from 'react'
import { connect } from 'react-fela'

import { withoutFelaProps } from 'shared/services/fela'
import logo from 'shared/ui/Styles/images/innowell_icon.png'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  as?: string,
  logoUrl?: string,
  roleName?: string,
  tenantName: string,
}

const styleRules = ({ theme }) => ({
  RoleCard: {
    alignItems: 'flex-start',
    className: 'RoleCard',
    minWidth: '250px',
    display: 'flex',
    flexFlow: 'row nowrap',
    fontSize: '18px',
    justifyContent: 'stretch',
    lineHeight: theme.spacing(1),
  },

  contentRegion: {
    boxSizing: 'border-box',
    className: 'RoleCard__contentRegion',
    display: 'flex',
    flex: '0 1 100%',
    '-ms-flex': '0 1 auto',
    flexFlow: 'column wrap',
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
  },

  logo: {
    backgroundColor: 'white',
    backgroundPosition: 'center center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: '5px',
    boxSizing: 'border-box',
    className: 'RoleCard__logo',
    display: 'block',
    flex: `0 0 ${theme.spacing(2)}`,
    height: theme.spacing(2),
    width: theme.spacing(2),
  },

  tenantName: {
    className: 'RoleCard__tenantName',
    display: 'block',
    fontWeight: 'bold',
    lineHeight: 'inherit',
  },
})

const RoleCard = ({
  as: Component = 'div',
  logoUrl = logo,
  roleName,
  styles,
  tenantName,
  ...props
}: PropsType) => (
  <Component {...withoutFelaProps(props)} className={styles.RoleCard}>
    <div
      aria-label="logo"
      className={styles.logo}
      role="img"
      style={{ backgroundImage: `url("${logoUrl}")` }}
    />

    <div className={styles.contentRegion}>
      <strong className={styles.tenantName}>{tenantName || '-'}</strong>
      <div className={styles.roleName}>{roleName}</div>
    </div>
  </Component>
)

export default connect(styleRules)(RoleCard)
