/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type RetakeQuestionnaireDropdown_individual$ref: FragmentReference;
declare export opaque type RetakeQuestionnaireDropdown_individual$fragmentType: RetakeQuestionnaireDropdown_individual$ref;
export type RetakeQuestionnaireDropdown_individual = {|
  +tenant: ?{|
    +initial_questionnaire: {|
      +id: string
    |},
    +summary_questionnaire: ?{|
      +id: string
    |},
  |},
  +$refType: RetakeQuestionnaireDropdown_individual$ref,
|};
export type RetakeQuestionnaireDropdown_individual$data = RetakeQuestionnaireDropdown_individual;
export type RetakeQuestionnaireDropdown_individual$key = {
  +$data?: RetakeQuestionnaireDropdown_individual$data,
  +$fragmentRefs: RetakeQuestionnaireDropdown_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "RetakeQuestionnaireDropdown_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "initial_questionnaire",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "summary_questionnaire",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '69009f7085df03b0808b2a23bf42d510';

module.exports = node;
