// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RemoveSupportPersonAssignmentMutationResponse,
  RemoveSupportPersonAssignmentMutationVariables,
} from './__generated__/RemoveSupportPersonAssignmentMutation.graphql'

type CommitArgs = {
  environment: Object,
  onCompleted?: RemoveSupportPersonAssignmentMutationResponse => void,
  onError?: (result: Object) => void,
  variables: RemoveSupportPersonAssignmentMutationVariables,
}

const mutation = graphql`
  mutation RemoveSupportPersonAssignmentMutation(
    $input: SupportPersonAssignmentRemoveInput!
  ) {
    supportPersonAssignmentRemove(input: $input) {
      clientMutationId
      support_person_assignment {
        id
        nickname
        last_requested_at
        individual {
          ... on IndividualRole {
            id
            has_support_person_attached_to_individual
            support_person_assignments {
              id
            }
          }
        }
      }
      viewer {
        currentUser {
          id
          current_role {
            ... on SupportPersonRole {
              id
              supportees {
                ...SupportingSupporteeSelectionPage_supportees
              }
              individual_assignments {
                ...SupportingSupporteeInfoWrapper_individual_assignments
              }
            }
            ... on RoleInterface {
              id
              parent {
                ...TenantContext_parent
              }
              tenant {
                ...NotificationDisplay_tenant
              }
            }
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: CommitArgs) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
