// @flow

import { createRenderer } from 'fela'
import webPreset from 'fela-preset-web'

import { queryOrder as mediaQueryOrder } from 'react-ui/themes/synergy-base-theme/breakpoints'
import { pixelsToRems } from 'shared/plugins/fela-pixels-to-rems'

const pixelsToRemsDefault = pixelsToRems({ baseEmInPixels: 14 })

const defaultRenderer = () =>
  createRenderer({
    mediaQueryOrder,
    plugins: [...webPreset, pixelsToRemsDefault],
  })

export { defaultRenderer }
