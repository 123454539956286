// @flow

import React, { type Node, useContext } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { type UserContext_user } from './__generated__/UserContext_user.graphql'

type PropsType = {
  children: Node,
  user: UserContext_user,
}

const UserContext: any = React.createContext({})
export const UserContextConsumer = UserContext.Consumer
export const useCurrentUser = () => {
  const user = useContext(UserContext)
  return user
}

export const UserContextProviderComponent = ({ children, user }: PropsType) => (
  <UserContext.Provider value={user}>{children}</UserContext.Provider>
)

export const UserContextProvider = createFragmentContainer(
  UserContextProviderComponent,
  {
    user: graphql`
      fragment UserContext_user on User {
        id
        homepage_path
        legal_name
        preferred_name
        completed_onboarding
        show_individual_onboarding
        user_research_programs_responses {
          id
        }
        unanswered_tenant_research_programs {
          id
        }
        current_role {
          ... on RoleInterface {
            id
            role_type
          }
          ... on IndividualRole {
            id
          }
        }
        roles {
          nodes {
            ... on RoleInterface {
              id
              role_type
            }
          }
        }
      }
    `,
  },
)

export default UserContext
