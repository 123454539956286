// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import Can from 'react-ui/utils/Can'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import IndividualTasksItem from './IndividualTasksItem'

import type { InitialQuestionnaireUpdateTask_individual } from './__generated__/InitialQuestionnaireUpdateTask_individual.graphql'

type PropsType = {
  individual: InitialQuestionnaireUpdateTask_individual,
}

const InitialQuestionnaireUpdateTaskComponent = (props: PropsType) => {
  const { individual: { tenant } } = props
  const { t: translation } = useTranslation('dashboard')
  const questionnaireId = tenant?.initial_questionnaire?.id || ''

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  if (!tenant || !tenant.initial_questionnaire) return null

  return (
    <Can>
      {({ CAN_CREATE_USER_QUESTIONNAIRE }) => {
        return CAN_CREATE_USER_QUESTIONNAIRE ? (
          <IndividualTasksItem
            header={translation('retake_full_questionnaire')}
            onClick={start}
          >
            {translation(
              'retaking_the_full_questionnaire_resets_your_baseline_across_all_domains',
            )}
          </IndividualTasksItem>
        ) : null
      }}
    </Can>
  )
}

export default createFragmentContainer(
  InitialQuestionnaireUpdateTaskComponent,
  {
    individual: graphql`
      fragment InitialQuestionnaireUpdateTask_individual on IndividualRole {
        tenant {
          initial_questionnaire {
            id
          }
        }
      }
    `,
  },
)
