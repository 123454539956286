// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'
import omit from 'lodash/fp/omit'

import { type ThemeType } from 'react-ui/themes/synergy-base-theme'
import { felaProps } from 'shared/services/fela'
import createComponentId from 'shared/services/id'

import type { FelaOptionalPropsType, FelaPropsType } from '../../typing'

export type AvatarStylesPropTypes = {
  componentId?: string,
  imageUrl?: string,
  initials?: string,
  theme?: ThemeType,
  withBorder?: boolean,
}

export type AvatarPropTypes = AvatarStylesPropTypes & {
  as?: string,
}

const styleRules = ({
  theme: { palette },
  imageUrl,
  withBorder,
}) => ({
  Avatar: {
    backgroundImage: imageUrl ? `url(${imageUrl})` : undefined,
    backgroundRepeat: 'no-repeat',
    backgroundColor: withBorder
      ? // TODO: Change for a theme color
        palette.input.placeholder
      : palette.themelet.grey.backgroundColor,
    backgroundPosition: '50% 50%',
    backgroundSize: 'cover',
    borderRadius: '42px',
    border: withBorder ? `2px solid ${palette.themelet.grey.color}` : 'none',
    boxSizing: 'border-box',
    className: classNames('Avatar', {
      [`--img-${String(imageUrl)}`]: !!imageUrl,
    }).replace(/\s/g, ''),
    color: palette.themelet.grey.color,
    display: 'inline-block',
    flexGrow: 0,
    flexShrink: 0,
    height: '42px',
    lineHeight: '42px',
    marginTop: '0',
    marginBottom: '0',
    textAlign: 'center',
    position: 'relative',
    width: '42px',
  },

  initials: {
    className: classNames('Avatar__initials', {
      '--hidden': !imageUrl,
    }).replace(/\s/g, ''),
    color: 'inherit',
    display: imageUrl ? 'none' : 'inline',
  },
})

const defaultId = createComponentId(__filename)

const Avatar = ({
  as,
  initials,
  styles,
  componentId = defaultId,
  ...props
}: AvatarPropTypes & FelaPropsType) => {
  const Component: string = (as: any) || 'div'
  return (
    <Component
      {...omit(['imageUrl', 'withBorder', ...felaProps])(props)}
      className={styles.Avatar}
      data-component-id={componentId}
    >
      <span className={styles.initials}>{initials}</span>
    </Component>
  )
}

const Connected: React.StatelessFunctionalComponent<
  AvatarPropTypes & FelaOptionalPropsType,
> = connect(styleRules)(Avatar)

export default Connected
