// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useRelayEnvironment } from 'react-relay'
import { useRouter } from 'found'

import { commit as commitUserQuestionnaireRefresh } from 'mutations/UserQuestionnaireRefresh'
import {
  type GoToQuestionnaireInputType,
  useRouteQuestionnaire,
} from 'services/questionnaire/useRouteQuestionnaire'
import { LoadingSpinner } from 'care-ui'

type EffectType = {
  answereeId?: string,
  assessmentRequestId?: string,
  environment: {},
  goToQuestionnaire: GoToQuestionnaireInputType => void,
  questionnaireId: string,
  showStartPage?: boolean,
}

export function effect({
  showStartPage,
  goToQuestionnaire,
  environment,
  questionnaireId,
  answereeId,
  assessmentRequestId,
}: EffectType) {
  if (showStartPage) {
    goToQuestionnaire({ questionnaireId })
  } else {
    commitUserQuestionnaireRefresh({
      environment,
      variables: {
        input: { questionnaireId, answereeId, assessmentRequestId },
      },
      onCompleted: ({ userQuestionnaireRefresh }) => {
        if (!userQuestionnaireRefresh)
          throw new Error('No userQuestionnaireRefresh returned')

        const { user_questionnaire } = userQuestionnaireRefresh

        if (user_questionnaire.ready_to_complete) {
          goToQuestionnaire({ questionnaireId })
          return
        }

        const userQuestionnaireQuestionSet =
          userQuestionnaireRefresh.user_questionnaire
            .first_user_questionnaires_question_set

        if (!userQuestionnaireQuestionSet) {
          // This `goToQuestionnaire` call has been put in to get around a state where the user have completed all the
          // question sets, but for some reason the questionnaire has not been submitted. This is the major cause of
          // having no userQuestionnaireQuestionSet. The other cause is due to configuration not being complete.
          goToQuestionnaire({ questionnaireId })

          throw new Error('No questionnaireQuestionSetId found')
        }

        goToQuestionnaire({
          questionSetId:
            userQuestionnaireQuestionSet.questionnaires_question_set.id,
        })
      },
    })
  }
}

export default function UserQuestionnaireLoadingPage() {
  const {
    match: {
      params: {
        questionnaireId,
        showStartPage,
        answereeId,
        assessmentRequestId,
      },
    },
  } = useRouter()
  const environment = useRelayEnvironment()

  const goToQuestionnaire = useRouteQuestionnaire({
    answereeUserId: answereeId,
    assessmentRequestId,
  })

  // Localization
  const { t: translation } = useTranslation('shared')

  useEffect(() => {
    effect({
      showStartPage,
      goToQuestionnaire,
      environment,
      questionnaireId,
      answereeId,
      assessmentRequestId,
    })
  }, [])

  return <LoadingSpinner message={translation('starting_questionnaire')} />
}
