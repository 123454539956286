/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MyEscalationsTable_escalations$ref: FragmentReference;
declare export opaque type MyEscalationsTable_escalations$fragmentType: MyEscalationsTable_escalations$ref;
export type MyEscalationsTable_escalations = $ReadOnlyArray<{|
  +created_at: any,
  +label: string,
  +$refType: MyEscalationsTable_escalations$ref,
|}>;
export type MyEscalationsTable_escalations$data = MyEscalationsTable_escalations;
export type MyEscalationsTable_escalations$key = $ReadOnlyArray<{
  +$data?: MyEscalationsTable_escalations$data,
  +$fragmentRefs: MyEscalationsTable_escalations$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "MyEscalationsTable_escalations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    }
  ],
  "type": "Escalation",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '76cc0f871ece9eb3268fdc54d2e11811';

module.exports = node;
