/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserTrackableRawScore_user_trackable$ref: FragmentReference;
declare export opaque type UserTrackableRawScore_user_trackable$fragmentType: UserTrackableRawScore_user_trackable$ref;
export type UserTrackableRawScore_user_trackable = {|
  +result: ?{|
    +value: ?number,
    +lower_bound: ?number,
    +upper_bound: ?number,
  |},
  +$refType: UserTrackableRawScore_user_trackable$ref,
|};
export type UserTrackableRawScore_user_trackable$data = UserTrackableRawScore_user_trackable;
export type UserTrackableRawScore_user_trackable$key = {
  +$data?: UserTrackableRawScore_user_trackable$data,
  +$fragmentRefs: UserTrackableRawScore_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserTrackableRawScore_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrackableResult",
      "kind": "LinkedField",
      "name": "result",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lower_bound",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upper_bound",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '43e7431fb35b096491b2e0b4acfc13f3';

module.exports = node;
