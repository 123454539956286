/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserTrackableChartControls_user$ref: FragmentReference;
declare export opaque type UserTrackableChartControls_user$fragmentType: UserTrackableChartControls_user$ref;
export type UserTrackableChartControls_user = {|
  +fitbit: {|
    +connected: boolean
  |},
  +$refType: UserTrackableChartControls_user$ref,
|};
export type UserTrackableChartControls_user$data = UserTrackableChartControls_user;
export type UserTrackableChartControls_user$key = {
  +$data?: UserTrackableChartControls_user$data,
  +$fragmentRefs: UserTrackableChartControls_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserTrackableChartControls_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Fitbit",
      "kind": "LinkedField",
      "name": "fitbit",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connected",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c216fa7525e8621dc1390358806e429c';

module.exports = node;
