// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import FitbitLogo from 'react-ui/assets/images/FitbitLogo.svg'
import Card from 'react-ui/components/Card/Card'
import Modal from 'react-ui/components/Modal'
import { commit as commitDisconnectOfIndividualFitbit } from 'mutations/Fitbit/DisconnectFitbit'
import {
  trackFitbitConnectModalViewed,
  trackFitbitDisconnected,
  trackFitbitDisconnectModalViewed,
} from 'services/analytics/events'
import { Button } from 'care-ui'

import FitbitConnectActivity from '../FitbitConnectActivity'
import FitbitDisconnectActivity from '../FitbitDisconnectActivity'

import { widgetStyle } from './FitbitConnectionCard.style'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  authLink?: string,
  environment: Object,
  isConnected: boolean,
  isOpen?: boolean,
  viewOnlyMode?: boolean,
}

const FitbitConnectionCard = (props: PropTypes) => {
  const {
    styles,
    rules,
    isConnected,
    authLink,
    environment,
    viewOnlyMode,
  } = props
  const [isOpen, setIsOpen] = useState(false)

  const onToggleOpen = () => setIsOpen(prev => !prev)

  const handleFitbitModalOpen = () => {
    onToggleOpen()
    // eslint-disable-next-line no-unused-expressions
    isConnected
      ? trackFitbitDisconnectModalViewed()
      : trackFitbitConnectModalViewed()
  }

  const { t: translation } = useTranslation('shared')

  const connectButton = () => (
    <Button
      variant="text"
      onClick={handleFitbitModalOpen}
      disabled={viewOnlyMode}
      ariaLabel={
        isConnected
          ? translation('disconnect_your_fitbit')
          : translation('connect_your_fitbit')
      }
      dataTestId="fitbit"
    >
      {isConnected
        ? translation('disconnect')
        : translation('connect_your_fitbit')}
    </Button>
  )

  const handleDisconnectClick = () => {
    commitDisconnectOfIndividualFitbit({
      environment,
      variables: {
        input: {},
      },
      onCompleted: () => {
        onToggleOpen()
      },
    })

    trackFitbitDisconnected()
  }

  return (
    <Card extend={rules.cardDefaults}>
      <div className={styles.logoContainer}>
        <FitbitLogo className={styles.logo} />
      </div>

      <div className={styles.connectButton}>{connectButton()}</div>

      <div className={styles.text}>
        {translation(
          'you_can_always_disconnect_reconnect_at_any_time_here_is_more_information_on_innowells',
        )}
      </div>

      <Modal
        contentStyle={rules.modal()}
        isOpen={isOpen}
        onRequestClose={onToggleOpen}
        showClose={false}
        shrinkwrap
      >
        {!isConnected ? (
          <FitbitConnectActivity
            authLink={authLink}
            closeModal={onToggleOpen}
          />
        ) : (
          <FitbitDisconnectActivity
            closeModal={onToggleOpen}
            disconnect={handleDisconnectClick}
          />
        )}
      </Modal>
    </Card>
  )
}

export default connect(widgetStyle)(FitbitConnectionCard)
