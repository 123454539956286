// @flow

import React from 'react'

import { Button } from 'care-ui'

type PropsType = {
  disabled?: boolean,
  label: string,
  onToggleOpen?: () => void,
}

export default ({ onToggleOpen, label, disabled }: PropsType) => (
  <Button onClick={onToggleOpen} disabled={disabled}>
    {label}
  </Button>
)
