// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const MicrophoneDisabledIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="microphoneDisabledIcon"
        version="1.1"
        viewBox="0 0 27 29"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
      >
        <g
          id="_x36_.8.8-Exit_x2F_end-meeting_1_"
          transform="translate(-704.000000, -930.000000)"
        >
          <g id="VIDEO-CALLs_1_" transform="translate(0.000000, 896.000000)">
            <g
              id="Video-Controls-inactive_1_"
              transform="translate(486.000000, 14.000000)"
            >
              <g
                id="button-_x2F_-your-mic_1_"
                transform="translate(199.000000, 1.000000)"
              >
                <g id="Group-5_1_" transform="translate(20.000000, 20.000000)">
                  <g id="icon-_x2F_-28x28-_x2F_-microphone_1_">
                    <g>
                      <path
                        id="path-1_3_"
                        className="st0"
                        d="M10.6,5.7v6.4c0,1.8,1.4,3.2,3.2,3.2c1.8,0,3.2-1.4,3.2-3.2V5.7c0-1.8-1.4-3.2-3.2-3.2
                        C12.1,2.5,10.6,4,10.6,5.7z M15.1,22.8v3.9c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3v-3.9C7.2,22.2,3,17.6,3,12.1
                        c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3c0,4.6,3.7,8.3,8.3,8.3c4.6,0,8.3-3.7,8.3-8.3c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3
                        C24.6,17.6,20.5,22.2,15.1,22.8z M8.1,5.7c0-3.2,2.6-5.7,5.7-5.7c3.2,0,5.7,2.6,5.7,5.7v6.4c0,3.2-2.6,5.7-5.7,5.7
                        c-3.2,0-5.7-2.6-5.7-5.7V5.7z"
                      />
                    </g>
                  </g>
                  <g id="Line_1_">
                    <g>
                      <path
                        id="path-3_1_"
                        className="st0"
                        d="M1.7,24.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4L22.9,0.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4
                          L1.7,24.3z"
                      />
                    </g>
                    <g>
                      <path
                        className="st1"
                        d="M1,25.6c-0.5,0-1-0.2-1.4-0.6c-0.8-0.8-0.8-2,0-2.8L22.2-0.4c0.8-0.8,2-0.8,2.8,0c0.8,0.8,0.8,2,0,2.8
                            L2.4,25C2,25.4,1.5,25.6,1,25.6z M23.6,0c-0.3,0-0.5,0.1-0.7,0.3L0.3,22.9c-0.4,0.4-0.4,1,0,1.4c0.4,0.4,1,0.4,1.4,0
                            L24.3,1.7c0.4-0.4,0.4-1,0-1.4C24.1,0.1,23.9,0,23.6,0z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default MicrophoneDisabledIcon
