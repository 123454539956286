// @flow

import { type ThemeType } from 'care-ui/atoms'

type SizeStyleProps = {
  size?: 'lg' | 'md',
  theme: ThemeType,
}

export const sizeStyle = (props: SizeStyleProps) => {
  const { size, theme } = props

  // large size
  if (size === 'lg') {
    return {
      ...theme.care.typography.desktop.bodyLg,
    }
  }

  // medium size | Default
  return {
    ...theme.care.typography.desktop.bodySm,
  }
}

const getWidthStyle = (variant, width) => {
  if (variant === 'info') {
    return { width: width || '300px' }
  }

  return {}
}

type PropsType = {
  left: string,
  theme: ThemeType,
  top: string,
  variant?: 'tooltip' | 'info',
  width?: string,
}

export const tooltipContainerStyle = () => ({
  display: 'flex',
  ':hover span': {
    visibility: 'visible',
    opacity: '1',
  },
})

export const textStyle = ({ theme, left, top, variant, width }: PropsType) => {
  const widthStyle = getWidthStyle(variant, width)

  return {
    left,
    top,
    visibility: 'hidden',
    whiteSpace: 'nowrap',
    position: 'fixed',
    zIndex: '100',
    opacity: '0',
    transition: 'opacity 0.1s',
    backgroundColor: theme.care.palette.surface.subtle,
    borderRadius: theme.care.radius.xxs,
    padding: theme.care.spacing.xxs,
    boxShadow: theme.care.elevation['2'],
    textAlign: 'left',
    textWrap: 'wrap',
    ...widthStyle,
  }
}
