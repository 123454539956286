// @flow

import { CHANGE_CURRENT_USER_ROLE, LOGGED_OUT } from './actionTypes'

type ChangeCurrentUserRoleType = {
  ownerTenants: ?$ReadOnlyArray<$ReadOnly<{ [key: string]: any }>>,
  roleType: string,
  roles: $ReadOnlyArray<string>,
  tenant: $ReadOnly<{ [key: string]: any }>,
  type: 'CHANGE_CURRENT_USER_ROLE',
  userId: string,
}

type LoggedOutType = {
  type: 'LOGGED_OUT',
}

export type ActionsType = ChangeCurrentUserRoleType | LoggedOutType

export function changeCurrentUserRole(
  userId: string,
  tenant: $ReadOnly<{ [key: string]: any }>,
  ownerTenants: ?$ReadOnlyArray<$ReadOnly<{ [key: string]: any }>>,
  roleType: string,
  roles: $ReadOnlyArray<string>,
): ChangeCurrentUserRoleType {
  return {
    type: CHANGE_CURRENT_USER_ROLE,
    userId,
    tenant,
    ownerTenants,
    roleType,
    roles,
  }
}

export function userLoggedOut(): LoggedOutType {
  return {
    type: LOGGED_OUT,
  }
}
