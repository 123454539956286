/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AboutMeTab_user$ref: FragmentReference;
declare export opaque type AboutMeTab_user$fragmentType: AboutMeTab_user$ref;
export type AboutMeTab_user = {|
  +legal_name: ?string,
  +profileDisplayFields: $ReadOnlyArray<{|
    +id: string,
    +label: string,
    +displayValue: ?string,
  |}>,
  +$fragmentRefs: AvatarLoader_user$ref,
  +$refType: AboutMeTab_user$ref,
|};
export type AboutMeTab_user$data = AboutMeTab_user;
export type AboutMeTab_user$key = {
  +$data?: AboutMeTab_user$data,
  +$fragmentRefs: AboutMeTab_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AboutMeTab_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legal_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "DisplayField",
      "kind": "LinkedField",
      "name": "profileDisplayFields",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "displayValue",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarLoader_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4d75e42db5e20fac3cf09c3005ece808';

module.exports = node;
