// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSetState } from 'react-use'
import { withFormik } from 'formik'
import { camelCase } from 'lodash/fp'

import { Button, Divider, DropDownMenuItem, RadioButton } from 'care-ui'

import type { FormikProps } from 'services/flow'

// SORT DIRECTIONS
const getSortDirection = translation => [
  {
    name: 'sortDirection',
    value: 'ASC',
    id: 'sortDirection-asc',
    label: translation('ascending'),
  },
  {
    name: 'sortDirection',
    value: 'DESC',
    id: 'sortDirection-desc',
    label: translation('descending'),
  },
]

type ValuesType = {
  prioritise: boolean,
  sortCode: string,
  sortColumn: string,
  sortDirection: string,
  sortId: string,
}

// eslint-disable-next-line no-unused-vars
type PropsType<T> = FormikProps & {
  sortOptions: Array<any>,
  toggleDropDownMenu: () => void,
  updateQueryVariables: ({}) => void,
  values: ValuesType,
}

const Form = <T = any>(props: PropsType<T>) => {
  const {
    values,
    toggleDropDownMenu,
    updateQueryVariables,
    sortOptions,
    handleChange,
  } = props

  const [state, setState] = useSetState(values)

  const handleSubmit = () => {
    toggleDropDownMenu()

    const { sortColumn, sortCode, sortDirection, sortId, prioritise } = state

    const queryValue = {
      column: sortColumn,
      direction: sortDirection,
      id: sortId,
    }

    updateQueryVariables({ sort: queryValue, prioritise, sortCode })
  }

  const handleOnChange = (e, option = {}) => {
    handleChange(e)

    const { name, value } = e?.target || {}

    const valueObject = {
      sortColumn: option?.header,
      sortId: option?.id,
      prioritise: !!option?.prioritise,
    }

    setState({ ...state, ...valueObject, [name]: value })
  }

  const handleOnDirectionChange = e => {
    handleChange(e)

    const { name, value } = e?.target || {}
    setState({ ...state, [name]: value })
  }

  // Localization
  const { t: translation } = useTranslation('dataTable')

  const SORT_DIRECTIONS = getSortDirection(translation)

  return (
    <form noValidate>
      {sortOptions.map(option => {
        const sortCode = `${option.header}-${camelCase(option.label)}`
        const isChecked = state.sortCode === sortCode

        return (
          <DropDownMenuItem key={option.label}>
            <RadioButton
              inputAttributes={{
                id: sortCode,
                name: 'sortCode',
                value: sortCode,
                label: option.label,
                checked: isChecked,
                onChange: e => handleOnChange(e, option),
              }}
              dataTestId="sortOptionLabel"
              ariaLabel={option.value}
            />
          </DropDownMenuItem>
        )
      })}
      <Divider />

      {/* DIRECTION SORT */}
      {SORT_DIRECTIONS.map(option => (
        <DropDownMenuItem key={option.label}>
          <RadioButton
            inputAttributes={{
              id: option.id,
              name: 'sortDirection',
              value: option.value,
              label: option.label,
              checked: state.sortDirection === option.value,
              onChange: e => handleOnDirectionChange(e),
            }}
            dataTestId="sortDirectionLabel"
            ariaLabel={option.label}
          />
        </DropDownMenuItem>
      ))}

      <Button
        variant="primary"
        onClick={handleSubmit}
        ariaLabel={translation('apply')}
        dataTestId="apply"
      >
        {translation('apply')}
      </Button>
    </form>
  )
}

const TableSortForm = withFormik({
  mapPropsToValues: ({ queryVariables }) => {
    const { prioritise, sortCode, sort } = queryVariables

    return {
      sortColumn: sort?.column,
      sortDirection: sort?.direction,
      sortId: sort?.id,
      sortCode,
      prioritise,
    }
  },
})(Form)

export default TableSortForm
