// @flow

import { createComponentWithProxy } from 'react-fela'

type StyleProps = {
  color: string,
  theme: {
    Grid: {
      gutter: string,
    },
    spacing: number => string,
  },
}

export default createComponentWithProxy(({ color, theme }: StyleProps) => ({
  alignItems: 'center',
  className: 'SegmentLabel',
  display: 'flex',
  flexDirection: 'row',
  ':before': {
    backgroundColor: color,
    borderRadius: '50%',
    content: '""',
    display: 'inline-block',
    height: '14px',
    lineHeight: theme.spacing(1),
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
    width: '14px',
  },
}))
