// @flow

import * as React from 'react'
import { QueryRenderer } from 'react-relay'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import {
  type withRelayEnvironmentPropsType,
  withRelayEnvironment,
} from 'containers/RelayEnvironment'

type PropsType = withRelayEnvironmentPropsType & {
  children: (data: any) => React.Node,
  query: Object,
  +variables: {},
}

const ReportWithSettings = ({
  children,
  query,
  variables,
  environment,
}: PropsType) => (
  <QueryRenderer
    environment={environment}
    query={query}
    render={res => (
      <QueryRendererLoadingIndicator response={res}>
        {children}
      </QueryRendererLoadingIndicator>
    )}
    variables={variables}
  />
)

export default withRelayEnvironment(ReportWithSettings)
