// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { first, flow, reject, sortBy } from 'lodash/fp'

import EscalationModal from 'components/EscalationModal'
import { commit as commitNotificationUpdate } from 'mutations/NotificationUpdate'

import type { NotificationDisplay_tenant } from './__generated__/NotificationDisplay_tenant.graphql'
import type { NotificationDisplay_user } from './__generated__/NotificationDisplay_user.graphql'

type PropsType = {
  relay: Object,
  tenant: ?NotificationDisplay_tenant,
  user: NotificationDisplay_user,
}

const firstNotification = flow(sortBy('id'), reject({ seen: true }), first)

const NotificationDisplay = (props: PropsType) => {
  const { relay: { environment }, user } = props

  const helpNowProviders = props.tenant?.help_now_modal_providers || {}
  const notification = firstNotification(user.notifications)

  if (!notification) {
    return null
  }

  const updateNotification = () => {
    commitNotificationUpdate({
      environment,
      user,
      variables: {
        input: {
          id: notification.id,
          notification: {
            seen: true,
          },
        },
      },
    })
  }

  return (
    <EscalationModal
      contentLabel={notification.subject.title}
      description={notification.subject.description}
      helpNowProviders={helpNowProviders}
      updateNotification={updateNotification}
    />
  )
}

export const NotificationDisplayLoader = createFragmentContainer(
  NotificationDisplay,
  {
    user: graphql`
      fragment NotificationDisplay_user on User {
        id
        notifications {
          id
          seen
          subject {
            __typename
            ... on Escalation {
              kind
              title
              description
              id
            }
          }
        }
      }
    `,
    tenant: graphql`
      fragment NotificationDisplay_tenant on Tenant {
        id
        help_now_modal_providers {
          id
          phone_number
          slug
          logo_url
          url
          description
          title
        }
      }
    `,
  },
)
