// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import MinorArrowRight from 'react-ui/assets/icons/minor-arrow-right.svg'
import Icon from 'react-ui/components/Icon'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ active = false, theme }) => ({
  MenuItem: {
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    className: 'MenuItem',
    color: theme.palette.link.linkColor,
    display: 'flex',
    flexDirection: 'row',
    fontSize: '18px',
    fontWeight: active ? 500 : 300,
    justifyContent: 'space-between',
    maxWidth: '80%',
    lineHeight: theme.spacing(1),
    marginTop: 0,
    marginBottom: theme.spacing(1),
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'left',
    textDecoration: 'none',
  },

  icon: {
    className: 'MenuItem__icon',
    color: 'inherit',
    lineHeight: theme.spacing(1),
    marginLeft: '1em',
  },
})

type PropTypes = FelaPropsType & {
  as: string,
  children?: React.Node,
  external?: boolean,
  label: React.Node,
}

const MenuWithSubItems = ({
  as = 'div',
  children,
  label,
  styles,
  external,
}: PropTypes) => {
  let Component = as
  if (external) {
    Component = 'div'
  }
  return (
    <Component className={styles.MenuItem}>
      {label}
      {!!React.Children.count(children) && (
        <span className={styles.icon}>
          <Icon as={MinorArrowRight} scale={0.57142857} />
        </span>
      )}
    </Component>
  )
}

const enhance = connect(styleRules)

export default enhance(MenuWithSubItems)
