// @flow

import { buildPolicyHash } from 'services/policies'

import { ADD_POLICIES } from './actionTypes'

type AddPoliciesActionType = {
  policies: Object,
  type: 'ADD_POLICIES',
}

export type ActionsType = AddPoliciesActionType

export function addPolicies(
  policies: $ReadOnlyArray<{ +enabled: boolean, +name: string }>,
): AddPoliciesActionType {
  return {
    type: ADD_POLICIES,
    policies: buildPolicyHash(policies),
  }
}
