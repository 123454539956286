/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type TenantFormulasLoaderQueryVariables = {|
  tenants: $ReadOnlyArray<string>
|};
export type TenantFormulasLoaderQueryResponse = {|
  +viewer: {|
    +tenants_with_id: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +effectiveness_important_formulas: $ReadOnlyArray<{|
        +id: string,
        +label: string,
        +formula: {|
          +id: string,
          +label: string,
        |},
      |}>,
      +safety_important_formulas: $ReadOnlyArray<{|
        +id: string,
        +label: string,
        +formula: {|
          +id: string,
          +label: string,
        |},
      |}>,
    |}>
  |}
|};
export type TenantFormulasLoaderQuery = {|
  variables: TenantFormulasLoaderQueryVariables,
  response: TenantFormulasLoaderQueryResponse,
|};
*/


/*
query TenantFormulasLoaderQuery(
  $tenants: [ID!]!
) {
  viewer {
    tenants_with_id(ids: $tenants) {
      id
      name
      effectiveness_important_formulas: important_formulas(kind: effectiveness) {
        id
        label
        formula {
          id
          label
        }
      }
      safety_important_formulas: important_formulas(kind: safety) {
        id
        label
        formula {
          id
          label
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "tenants"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v3 = [
  (v1/*: any*/),
  (v2/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "Formula",
    "kind": "LinkedField",
    "name": "formula",
    "plural": false,
    "selections": [
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "ids",
      "variableName": "tenants"
    }
  ],
  "concreteType": "Tenant",
  "kind": "LinkedField",
  "name": "tenants_with_id",
  "plural": true,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": "effectiveness_important_formulas",
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "effectiveness"
        }
      ],
      "concreteType": "ImportantFormula",
      "kind": "LinkedField",
      "name": "important_formulas",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": "important_formulas(kind:\"effectiveness\")"
    },
    {
      "alias": "safety_important_formulas",
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "safety"
        }
      ],
      "concreteType": "ImportantFormula",
      "kind": "LinkedField",
      "name": "important_formulas",
      "plural": true,
      "selections": (v3/*: any*/),
      "storageKey": "important_formulas(kind:\"safety\")"
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantFormulasLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantFormulasLoaderQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cc944312186ff1347b0e9d93bfbe8f1d",
    "id": null,
    "metadata": {},
    "name": "TenantFormulasLoaderQuery",
    "operationKind": "query",
    "text": "query TenantFormulasLoaderQuery(\n  $tenants: [ID!]!\n) {\n  viewer {\n    tenants_with_id(ids: $tenants) {\n      id\n      name\n      effectiveness_important_formulas: important_formulas(kind: effectiveness) {\n        id\n        label\n        formula {\n          id\n          label\n        }\n      }\n      safety_important_formulas: important_formulas(kind: safety) {\n        id\n        label\n        formula {\n          id\n          label\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a43feacbcd6db280c2dea4be2e1e764e';

module.exports = node;
