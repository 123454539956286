// @flow

// Localization Plans
// 1. Have a single trnanslation resource JSON file. - Done
// this will help to automate the process of updating any locale to our platform
// We can get rid of script to generate JSON and can only generate CSV
// 2. For optimization purpose, translations will be divided based on project folder structure -Done [In progress]
// 3. There will be no duplicate text, for this all text used in multiple places will be categorasied under app
// 4. Key name should be downcase and underscored whole text

const resources = {
  en: {
    assessment: {
      a_quick_check_in_on_mental_health_incorporating_multiple_instruments_and_evaluating_general_health_history_and_wellbeing:
        'A quick check-in on mental health, incorporating multiple instruments, and evaluating general health history and wellbeing.',
      answer_one: 'Answer',
      answer_other: 'Answers',
      answered_by: 'Answered by',
      answered_on: 'Answered on',
      answered: 'Answered',
      assessments_that_dont_apply_to_the_patient_have_been_removed_from_the_list:
        "Assessments that don't apply to the patient have been removed from the list.",
      assessments: 'Assessments',
      back_to_details: 'Back to details',
      complete_all: '(Complete all)',
      completed: 'Completed',
      completed_on: 'Completed on',
      cluster: 'Cluster',
      full_questionnaire: 'Full Questionnaire',
      incomplete: 'Incomplete',
      individual: 'Individual',
      last_answered: 'Last Answered',
      library: 'Library',
      no_assessment_request: 'No assessment requests found',
      no_results_found: 'No results found',
      provide_a_comprehensive_overview_of_mental_health_across_multiple_domains_evaluating_symptoms_functioning_risk_indicators_and_support_systems:
        'Provide a comprehensive overview of mental health across multiple domains, evaluating symptoms, functioning, risk indicators, and support systems.',
      question_set: 'Question Set',
      question: 'Question',
      questionnaire: 'Questionnaire',
      rating: 'Rating',
      request_assessments: 'Request Assessments',
      requested_assessments: 'Requested Assessments',
      requested_on: 'Requested on',
      requested: 'Requested',
      search_bar: 'Search Bar',
      search_for_questionnaire: 'Search for questionnaire...',
      select_a_person: 'Select a person',
      select_person_to_send_a_request_to: 'Select person to send a request to',
      select_questionnaire_to_request: 'Select questionnaire(s) to request',
      send_requests: 'Send request(s)',
      skipped: 'Skipped',
      status: 'Status',
      summary_questionnaire: 'Summary Questionnaire',
      take_assessment: 'Take assessment',
      unanswered: 'Unanswered',
      updating_list: 'Updating List',
      view_answers_from: 'View answers from',
      view_answers: 'View Answers',
      out_of: 'out of',
      approx: 'approx',
      to_complete: 'to complete',
      secure_and_confidential: 'secure and confidential',
      saved_automatically: 'saved automatically',
      skip: 'Skip',
      next: 'Next',
      submit: 'Submit',
      submitting: 'Submitting',
      need_one: 'need',
      need_other: 'needs',
      completing: 'completing',
      sorry_your_role_is_not_supported_for_questionnaires:
        'Sorry, your role is not supported for Questionnaires.',
      well_done: 'Well done',
      you_are_almost_done: "You're almost done",
      of_the_initial_questionnaire_completed:
        'of the initial questionnaire completed',
      you_can_still_submit_your_answers_and_return_to_the_skipped_incomplete_questions_anytime_or_if_you_prefer_you_can_choose_to_do_them_with_a_support_person_or_your_clinician:
        'You can still submit your answers and return to the skipped/incomplete questions anytime or if you prefer you can choose to do them with a support person or your clinician',
      your_dashboard_has_sorted_your_results_into_health_cards_so_you_can_focus_on_whats_important_and_explore_a_range_of_care_options_that_help_you_meet_your_health_goals:
        'Your dashboard has sorted your results into health cards so you can focus on what’s important and explore a range of care options that help you meet your health goals.',
      you_can_discuss_your_results_with_your_clinician_your_clinician_will_work_with_you_through_the_dashboard_to_tailor_treatment_to_your_needs:
        'You can discuss your results with your clinician. Your clinician will work with you through the dashboard to tailor treatment to your needs.',
      skipped_and_incomplete_sections: 'Skipped and Incomplete Sections',
      completed_sections: 'Completed Sections',
      click_submit_to_enter_your_clinical_input:
        'Click submit to enter your clinical input',
      routinely_update_your_responses_to_keep_your_clinical_input_current_in_this_individuals_dashboard_it_will_help_you_monitor_progress_over_time_and_support_clinical_decision_making:
        'Routinely update your responses to keep your clinical input current in this individual’s dashboard. It will help you monitor progress over time and support clinical decision making.',
      your_input: 'Your Input',
      view_input: 'View Input',
      your_answers_can_be_viewed_by_yourself_and_other_connected_innowell_clinicians:
        'Your answers can be viewed by yourself and other connected Innowell clinicians.',
      thank_you: 'Thank you',
      select_all_that_apply: 'Select all that apply',
      the_feet_should_be_between: 'The feet should be between',
      the_inches_should_be_between: 'The inches should be between',
      score: 'Score',
      range: 'Range',
      na: 'N/A',
      up_to: 'Up to',
      select_options: 'Select Options',
      clinician: 'Clinician',
      support_person: 'Support Person',
      questionnaire_completed: 'Questionnaire completed',
      you_have_already_successfully_submitted_this_questionnaire:
        'You have already successfully submitted this questionnaire.',
      home: 'Home',
      section_completed: 'Section completed',
      if_you_wish_to_edit_any_previous_answers_you_can_do_so_via_the_dashboard:
        'If you wish to edit any previous answers, you can do so via the dashboard.',
      next_section: 'Next section',
      reason_to_skip: 'Reason to skip',
      submiting: 'Submitting',
      no_defaults: 'No Defaults',
      browse_care_options: 'Browse care options',
      view_details: 'View Details',
    },
    auth: {
      already_have_an_account: 'Already have an account?',
      australia: 'Australia',
      back_to_login: 'Back to login',
      canada: 'Canada',
      current_password: 'Current Password',
      current_password_is_required: 'Current password is required',
      email: 'Email',
      email_is_a_required_field: 'Email is a required field',
      email_is_required: 'Email is required',
      email_must_be_a_valid_email: 'Email must be a valid email',
      enter_old_password: 'Enter old password',
      enter_verification_code: 'Enter verification code',
      forgot_your_password: 'Forgot your password?',
      get_help_signing_in: 'Get help signing in.',
      i_cant_set_it_up_now_log_me_out: "I can't set it up now, log me out",
      i_dont_have_the_code_now_log_me_out:
        "I don't have the code now, log me out",
      if_the_email_address_or_region_provided_is_registered_on_our_system_an_email_with_the_instructions_to_reset_the_password_will_be_sent_shortly_this_password_reset_is_only_valid_for_the_next_6_hours:
        'If the email address or region provided is registered on our system, an email with the instructions to reset the password will be sent shortly. This password reset is only valid for the next 6 hours.',
      if_you_do_not_receive_an_email_in_5_minutes_please_check_your_region_setting_and_email_address:
        'If you do not receive an email in 5 minutes, please check your region setting and email address.',
      invalid_verification_code: 'Invalid verification code',
      keep_me_signed_in: 'Keep me signed in',
      must_match_password: 'Must match password',
      new_passwords_must_match: 'New passwords must match',
      next: 'Next',
      password: 'Password',
      password_confirmation: 'Password confirmation',
      password_confirmation_is_required: 'Password confirmation is required',
      password_is_a_required_field: 'Password is a required field',
      password_is_required: 'Password is required',
      password_must_be_at_least_6_characters:
        'Password must be at least 6 characters',
      password_must_be_at_least_8_characters:
        'Password must be at least 8 characters',
      please_check_your_email: 'Please check your email',
      please_create_a_new_password: 'Please create a new password',
      please_enter_verification_code: 'Please enter verification code',
      please_enter_your_authenticator_app_verification_code_below:
        'Please enter your authenticator app verification code below.',
      please_enter_your_email_below_to_receive_password_reset_instructions:
        'Please enter your email below to receive password reset instructions',
      qr_code: 'QR Code',
      request_new_invite: 'Request new invite',
      request_the_link_again: 'Request the link again',
      save: 'Save',
      scan_the_qr_code_with_your_authentication_app_and_enter_the_verification_code_below:
        'Scan the QR code with your authentication app and enter the verification code below.',
      secure_your_account: 'Secure your account',
      select_your_region: 'Select your region',
      send_instructions: 'Send instructions',
      sign_in: 'Sign In',
      signing_in: 'Signing in',
      signing_out: 'Signing out',
      sorry_an_error_occured_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Sorry, an error occured with our server. We've notified our team so that they can address it. Please try again.",
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_refresh_the_page_before_trying_to_login_again:
        "Sorry, an error occurred with our server. We've notified our team so that they can address it. Please refresh the page before trying to login again.",
      sorry_we_couldnt_change_your_passowrd:
        "Sorry, we couldn't change your passowrd",
      sorry_your_new_password: 'Sorry, your new password',
      sorry_your_new_password_is: 'Sorry, your new password is',
      sorry_your_token: 'Sorry, your token',
      success: 'Success',
      this_invite_is_invalid: 'This invite is invalid.',
      update_your_password: 'Update your password',
      use_the_button_below_to_request_a_new_invite_from_your_service:
        'Use the button below to request a new invite from your service.',
      username: 'Username',
      username_is_a_required_field: 'Username is a required field',
      welcome_to_the_innowell_platform: 'Welcome to the Innowell Platform',
      your_password_has_been_updated: 'Your password has been updated.',
    },
    banners: {
      your_session_will_timeout_soon_click_here_to_reload_and_avoid_having_to_sign_in_again:
        'Your session will timeout soon. Click here to re-load and avoid having to sign in again.',
      your_session_has_now_timed_out_click_here_to_sign_in_again:
        'Your session has now timed out. Click here to sign in again.',
    },
    graphs: {
      apply: 'Apply',
      back_to_dashboard: 'Back to dashboard',
      cancel: 'Cancel',
      custom: 'Custom',
      monthly: 'Monthly',
      signIn: 'sign in',
      signIns: 'sign ins',
      weekly: 'Weekly',
      yearly: 'Yearly',
    },
    dashboard: {
      by_keeping_up_to_date_you_can_track_your_health_and_well_being_over_time:
        'By keeping up-to-date you can track your health and well-being over time.',
      click_here: 'click here',
      click_here_to_start: 'Click here to start',
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        'Click on a health card to find fact sheets, browse a number of care options and review your results.',
      common_tasks: 'Common Tasks',
      continue: 'Continue',
      download_a_pdf_of_their_health_history:
        'Download a PDF of their health history.',
      download_health_history_pdf: 'Download health history PDF',
      finish_questionnaire: 'Finish questionnaire',
      finish_the_questionnaire_to_create_a_baseline_of_your_health_and_well_being:
        'Finish the questionnaire to create a baseline of your health and well-being.',
      for_more_information_on_the_use_of_your_data:
        'For more information on the use of your data,',
      full_questionnaire: 'Full questionnaire',
      give_input: 'Give input',
      health_history_pdf: 'Health history PDF',
      i_accept: 'I accept',
      i_decline: 'I decline',
      invite_edit_support_person: 'Invite/Edit support person',
      it_appears_we_dont_have_all_your_answers:
        'It appears we don’t have all your answers.',
      its_your_history_and_you_can_take_it_with_you:
        "It's your history and you can take it with you.",
      loading: 'Loading',
      no_health_cards_found: 'No health cards found.',
      out_of: 'out of',
      please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements:
        'Please ensure you take appropriate steps to protect the privacy of this document in accordance with the clinical governance requirements',
      provide_feedback_to_mark_progress_of_your_service:
        'Provide feedback to mark progress of your service.',
      research_and_data_sharing: 'Research and Data Sharing',
      request_assessments: 'Request assessment(s)',
      retake_full_questionnaire: 'Retake Full Questionnaire',
      retake_questionnaire: 'Retake questionnaire',
      retake_summary_questionnaire: 'Retake Summary Questionnaire',
      retaking_the_full_questionnaire_resets_your_baseline_across_all_domains:
        'Retaking the Full Questionnaire resets your baseline across all domains.',
      save: 'Save',
      select_change_focus_areas: 'Select / change focus areas',
      send_an_email_with_a_list_of_assessments_to_be_completed:
        'Send an email with a list of assessments to be completed.',
      summary_questionnaire: 'Summary questionnaire',
      thank_you_your_selection_was_recorded:
        'Thank you, your selection was recorded.',
      view_requested_assessments: 'View requested assessment(s)',
      view_requested_assessments_from_your_clinician_and_review_previous_results:
        'View requested assessment(s) from your clinician and review previous results.',
      you_can_find_these_in_the_requested_assessments_under_the_common_tasks_section_on_your_dashboard:
        'You can find these in the Requested assessment(s) under the Common tasks section on your dashboard.',
      you_can_invite_a_support_person_to_answer_some_questions_about_your_overall_health_and_well_being:
        'You can invite a support person to answer some questions about your overall health and well-being',
      your_clinician_has_requested_you_to_update_some_questionnaires:
        'Your clinician has requested you to update some questionnaires.',
      your_health_priorities: 'Your health priorities',
      your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_your_data_is_anonymous_to_everyone_other_than_you_and_your_service_provider_contributing_your_identified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care:
        "Your service participates in the following research program(s) to develop a greater depth of understanding of people's needs. Your data is anonymous to everyone other than you, and your service provider. Contributing your identified data is completely voluntary and will not affect your clinical care.",
    },
    dataTable: {
      actioned: 'Actioned',
      active: 'Active',
      add: 'Add',
      add_note_count: 'Add note{{count}}',
      apply: 'Apply',
      ascending: 'Ascending',
      assign_clinicians: 'Assign Clinicians',
      assign_individual: 'Assign individual',
      breakdown_of_this_individuals_cluster_probability:
        "Breakdown of this individual's cluster probability:",
      clinicians: 'Clinicians',
      date: 'Date',
      descending: 'Descending',
      discharge: 'Discharge',
      edit: 'Edit',
      email_address: 'Email address',
      employee_name: 'Employee name',
      filter: 'Filter',
      give_input: 'Give input',
      help_requested: 'Help requested',
      no_rating: 'No Rating',
      notifications: 'Notifications',
      invited: 'Invited',
      open_between_time1_hours_and_time2_hours:
        'Open between {{time1}} hours and {{time2}} hours',
      open_more_than_time_hours: 'Open more than {{time}} hours',
      patient_name: 'Patient name',
      remove: 'Remove',
      removed: 'Removed',
      stb_notifications: 'STB notifications',
      stratified_scoring_provides_an_overall_summary_of_a_persons_clinical_needs_based_on_their_initial_answers_in_6_areas_depression_anxiety_psychosis_mania_functioning_and_suicidal_thoughts_and_behaviours_the_distinct_groups_have_been_derived_using_clustering_analysis_based_on_meaningful_differences_observed_between_the_groups_this_information_should_be_used_to_guide_personalised_decisions_about_the_level_of_care_a_person_requires:
        "Stratified scoring provides an overall summary of a person's clinical needs based on their initial answers in 6 areas (depression, anxiety, psychosis, mania, functioning and suicidal thoughts and behaviours). The distinct groups have been derived using clustering analysis based on meaningful differences observed between the groups. This information should be used to guide personalised decisions about the level of care a person requires.",
      suicidal_thoughts_and_behaviours: 'Suicidal thoughts and behaviours',
      reinvite: 'Re-invite',
      resend_invitation: 'Resend Invitation',
      resend_role_invitation: 'Resend {{role}} Invitation',
      reset_mfa: 'Reset MFA',
      recent_activity: 'Recent activity',
      search: 'Search',
      sort: 'Sort',
      status: 'Status',
      total: 'total',
      this_clinician_has_not_onboarded_or_is_not_active:
        'This clinician has not onboarded or is not active',
      this_person_is_active_at_another_service:
        'This person is active at another service',
      this_person_is_already_a_staff_member:
        'This person is already a staff member',
    },
    feedback: {
      contact_us: 'Contact us',
      description_is_required: 'Description is required',
      description: 'Description',
      for_privacy_purposes_if_reporting_on_behalf_of_an_individual_please_do_not_include_any_sensitive_and_personal_information_of_the_individual_user_eg_email_address_or_user_name_innowell_will_be_responsible_for_responding_to_your_query_any_followup_with_the_individual_thereafter_should_be_managed_through_you_and_your_service:
        'For privacy purposes, if reporting on behalf of an individual, please do not include any sensitive and personal information of the individual user (e.g. email address or user name). Innowell will be responsible for responding to your query. Any follow-up with the individual thereafter should be managed through you and your service.',
      let_us_know_how_we_can_help: 'Let us know how we can help',
      please_include_as_much_information_as_possible:
        'Please include as much information as possible',
      please_select: '--Please Select--',
      send: 'Send',
      subject_is_required: 'Subject is required',
      subject: 'Subject',
      topic_is_required: 'Topic is required',
      topic: 'Topic',
      what_if_i_am_reporting_on_behalf_of_an_individual:
        'What if I am reporting on behalf of an individual?',
      your_feedback_is_important_to_us_it_helps_us_to_continue_improving_how_the_innowell_platform_works_best_for_you:
        'Your feedback is important to us. It helps us to continue improving how the Innowell Platform works best for you.',
    },
    health: {
      browse_care_options: 'Browse care options',
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        'Click on a health card to find fact sheets, browse a number of care options and review your results',
      health_priorities: 'Health Priorities',
      initial_cluster_stage: 'Initial Cluster Stage',
      latest_results: 'Latest Results',
      notifications_history: 'Notifications History',
      retake_questionnaire: 'Retake questionnaire',
      save: 'Save',
      select_health_domains: 'Select Health Domains',
      select_health_priorities: 'Select Health Priorities',
      select_up_to_3_domains_to_focus_on_to_improve_your_overall_wellbeing:
        'Select up to 3 domains to focus on to improve your overall well-being.',
      thank_you: 'Thank you',
      these_are_the_focus_areas_to_improve_your_overall_wellbeing:
        'These are the focus areas to improve your overall wellbeing',
      update_health_priorities: 'Update Health Priorities',
      update: 'Update',
      view_answers: 'View answers',
      view_assessment_library: 'View assessment library',
      you_currently_have_no_health_priorities_selected:
        'You currently have no Health Priorities selected.',
      you_have_chosen_3_health_priorities_you_can_update_these_anytime:
        'You have chosen 3 health priorities. You can update these anytime.',
    },
    hooks: {
      initial_questionnaire: 'Initial questionnaire',
      research_and_data_sharing: 'Research and data sharing',
      skipped: 'Skipped',
      tell_us_a_bit_about_yourself: 'Tell us a bit about yourself',
      the_health_assessment_for_this_section_is_not_complete:
        'The health assessment for this section is not complete.',
      to_get_a_result_for_this_health_card_you_can_update_your_answers_anytime:
        'To get a result for this health card you can update your answers anytime.',
      unanswered: 'Unanswered',
      view_results: 'View results',
      you_chose_to_skip_and_do_this_question_set_at_a_later_time:
        'You chose to skip and do this question set at a later time.',
      you_chose_to_skip_and_do_this_question_set_with_a_support_person:
        'You chose to skip and do this question set with a support person.',
      you_chose_to_skip_and_do_this_question_set_with_your_clinician_this_request_has_been_shared_with_them:
        'You chose to skip and do this question set with your clinician. This request has been shared with them.',
      you_chose_to_skip_this_question_set_because_you_preferred_not_to_answer:
        'You chose to skip this question set because you preferred not to answer.',
    },
    insights: {
      baseline_scores: 'Baseline Scores',
      changes_between_baseline_ratings_and_latest_ratings_by_domain:
        'Changes between baseline ratings and latest ratings by domain.',
      clinician_sign_ins: 'Clinician Sign Ins',
      deteriorate: 'Deteriorate',
      displays_results_from_the_beginning_of_your_innowell_journey_until_the_latest_completed_calendar_quarter:
        'Displays results from the beginning of your Innowell journey until the latest completed calendar quarter',
      domain_ratings_have_been_normalised_into_a_standard_developed_by_innowell:
        'Domain ratings have been normalised into a standard developed by Innowell.',
      high: 'High',
      good: 'Good',
      individual_onboarding: 'Individual Onboarding',
      individual_sign_ins: 'Individual Sign Ins',
      initial_domain_ratings_recorded_during_the_selected_time_period:
        'Initial domain ratings recorded during the selected time period.',
      insights: 'Insights',
      insights_new: 'Insights-New',
      latest_ratings_must_fall_within_selected_time_frame_to_be_displayed_domains_that_have_only_a_baseline_rating_have_been_excluded_from_this_chart:
        'Latest ratings must fall within selected time frame to be displayed. Domains that have only a Baseline rating have been excluded from this chart.',
      lifetime: 'Lifetime',
      mild: 'Mild',
      moderate: 'Moderate',
      no_change: 'No Change',
      no_data_available_for_this_period: 'No data available for this period.',
      no_domains_with_a_baseline_score_can_be_found_within_thin_perion:
        'No domains with a baseline score can be found within this period.',
      note_percentages_have_been_rounded_to_whole_numbers:
        'Note: Percentages have been rounded to whole numbers.',
      number_of_clinician_sign_ins: 'Number of clinician sign ins',
      number_of_individual_sign_ins: 'Number of individual sign ins',
      recovery_trajectories: 'Recovery Trajectories',
      recovery_trajectory_can_only_be_calculated_with_at_least_two_results_no_domains_with_a_latest_result_found_within_this_period:
        'Recovery Trajectory can only be calculated with at least two results. No domains with a latest result found within this period.',
      skipped: 'Skipped',
      stb_escalations: 'STB Escalations',
      summary_questionnaire: 'Summary Questionnaire',
      this_graph_visualises_the_number_of_clinician_sign_ins_over_the_specified_period_helping_to_track_employee_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_clinician_signed_in:
        'This graph visualises the number of clinician sign ins over the specified period, helping to track employee engagement and activity on the platform. The graph will break down the sign ins based on the number of times each clinician signed in.',
      this_graph_visualises_the_number_of_individual_sign_ins_over_the_specified_period_helping_to_track_user_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_individual_signed_in:
        'This graph visualises the number of individual sign ins over the specified period, helping to track user engagement and activity on the platform. The graph will break down the sign ins based on the number of times each individual signed in.',
      this_graph_visualises_the_number_of_individuals_who_have_successfully_completed_the_onboarding_process_over_the_specified_period:
        'This graph visualises the number of individuals who have successfully completed the onboarding process over the specified period.',
      this_graph_visualises_the_number_of_suicidal_thoughts_and_behaviours_notifications_over_the_specified_period:
        'This graph visualises the number of Suicidal Thoughts and Behaviours notifications over the specified period.',
      this_graph_visualises_the_number_of_summary_questionnaires_that_have_been_completed_over_the_specified_period_the_top_line_will_show_the_total_number_of_questionnaires_completed_and_the_bottom_line_will_show_the_total_number_of_unique_individuals_that_completed_those_questionnaires:
        'This graph visualises the number of Summary Questionnaires that have been completed over the specified period. The top line will show the total number of questionnaires completed and the bottom line will show the total number of unique individuals that completed those questionnaires.',
      try_changing_the_date_range_using_the_date_selector_at_the_top_of_the_page:
        'Try changing the date range using the date selector at the top of the page.',
      welcome_to_your_insights_page_we_ll_be_updating_it_regularly_for_you_with_new_features_so_if_there_are_metrics_you_d_like_to_see_or_you_have_questions_let_us_know_at:
        'Welcome to your Insights page. We’ll be updating it regularly for you with new features, so if there are metrics you’d like to see or you have questions, let us know at',
    },
    dialog: {
      are_you_sure_you_want_to_discharge_them:
        'are_you_sure_you_want_to_discharge_them',
      cancel: 'Cancel',
      continue_to_discharge: 'Continue to Discharge',
      has_unresolved_notifications: 'has unresolved notifications.',
      this_individual: 'This individual',
    },
    onboarding: {
      about_you: 'About you',
      all_answers_are_secure_and_strictly_confidential:
        'All answers are secure and strictly confidential.',
      and: 'and',
      back: 'Back',
      benefits: 'Benefits',
      by_answering_these_questions_it_helps_you_and_your_provider_better_understand_you:
        'By answering these questions, it helps you and your provider better understand you.',
      completed: 'Completed',
      confirm_password: 'Confirm password',
      continue: 'Continue',
      create_password: 'Create Password',
      enter_a_password: 'Enter a password',
      enter_a_username: 'Enter a username',
      enter_your_first_name_and_surname: 'Enter your first name and surname',
      enter_your_name: 'Enter your name',
      first_name_and_surname: 'First Name and Surname',
      great_job: 'Great job',
      i_understand_and_agree_with_the: 'I understand and agree with the',
      if_you_skip_some_questions_you_can_update_them_later_in_your_profile_settings:
        'If you skip some questions, you can update them later in your profile settings.',
      is_not_unique: 'is not unique',
      legal_name_is_required: 'Legal name is required',
      loading: 'Loading',
      next: 'Next',
      name_must_contain_at_least_a_first_name_and_a_surname:
        'Name must contain at least a first name and a surname.',
      of: 'of',
      out_of: 'out of',
      password_must_be_at_least_8_characters:
        'Password must be at least 8 characters',
      password_must_be_at_most_20_characters:
        'Password must be at most 20 characters',
      password_must_contain_a_mix_of_numbers_and_letters:
        'Password must contain a mix of numbers and letters',
      password_must_contain_a_mix_of_upper_and_lower_case_letters:
        'Password must contain a mix of upper and lower case letters',
      password_must_match: 'Password must match',
      password: 'Password',
      preferred_name_is_required: 'Preferred name is required',
      preferred_name: 'Preferred Name',
      privacy_policy: 'privacy policy',
      question: 'Question',
      remember_you_can_edit_your_information_at_any_time_from_the_profile_settings_in_your_main_menu:
        'Remember, you can edit your information at any time from the profile settings in your main menu',
      repeat_password: 'Repeat password',
      save: 'Save',
      setup_your_innowell_profile_as_everyone_comes_with_a_unique_background:
        'Setup your Innowell profile, as everyone comes with a unique background.',
      skip: 'Skip',
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Sorry, an error occurred with our server. We've notified our team so that they can address it. Please try again.",
      start: 'Start',
      tell_us_a_bit_about_yourself: 'Tell us a bit about yourself',
      terms_and_conditions: 'terms and conditions',
      this_is_a_short_set_of_questions_before_the_full_questionnaire_that_should_only_take_you_a_few_minutes_to_complete:
        'This is a short set of questions, before the full questionnaire, that should only take you a few minutes to complete.',
      unable_to_render_form_without_questionnaire:
        'Unable to render form without Questionnaire',
      unknown_page: 'Unknown page',
      username: 'Username',
      what_is_your_preferred_name: 'What is your preferred name?',
      you_can_skip_some_of_these_questions_if_you_dont_feel_comfortable_answering_them:
        'You can skip some of these questions if you don’t feel comfortable answering them.',
      you_must_agree_with_the_privacy_policy_and_terms_and_conditions:
        'You must agree with the privacy policy and terms and conditions',
      your_profile: 'Your Profile',
      your_session_has_now_timed_out_click_here_to_sign_in_again:
        'Your session has now timed out. Click here to sign in again.',
      your_session_will_timeout_soon_click_here_to_reload_and_avoid_having_to_sign_in_again:
        'Your session will timeout soon. Click here to re-load and avoid having to sign in again.',
    },
    reporting: {
      active_user: 'Active User:',
      accessibility_and_equity: 'Accessibility and Equity',
      active_users_include_all_individuals_with_an_active_account_status_at_any_time_during_the_selected_time_period_this_includes_a_user_that_may_have_initially_been_active_and_later_switched_to_a_read_only_or_discharged_status_during_the_selected_time_period:
        "Active users include all individuals with an 'active' account status at any time during the selected time period. This includes a user that may have initially been 'active' and later switched to a 'read only' or 'discharged' status during the selected time period.",
      apply: 'Apply',
      all: 'All',
      a_user_account_status_becomes_active_under_insights_if_they_have_received_a_platform_invitation_regardless_if_they_have_accepted_the_invitation_or_not_as_long_as_the_invitation_was_not_removed_and_never_reinvited:
        "A user account status becomes 'active' under insights if they have received a Platform invitation regardless if they have accepted the invitation or not, as long as the invitation was not 'removed' and never 're-invited'.",
      average_time_to_complete_the_initial_questionnaire:
        'Average time to complete the initial questionnaire',
      average_time_from_platform_invitation_to_initial_questionnaire_completion:
        'Average time from Platform invitation to initial questionnaire completion',
      average_time_from_platform_invitation_to_individual_working_on_a_care_option:
        'Average time from Platform invitation to individual working on a care option',
      average_time_for_a_clinician_to_respond_to_a_notification:
        'Average time for a clinician to respond to a notification',
      breakdown: 'Breakdown',
      cancel: 'Cancel',
      change_in_results_over_time: 'Change in results over time',
      continuity_and_coordination: 'Continuity and Coordination',
      conversely_if_a_user_had_a_read_only_or_discharged_status_and_was_reactivated_during_the_selected_time_period_they_would_also_be_counted_as_active:
        "Conversely, if a user had a 'read only' or 'discharged' status and was re-activated during the selected time period, they would also be counted as 'active'.",
      client_insight: 'Client Insight',
      clinician: 'clinician',
      clinician_breakdown: 'Clinician breakdown',
      clinicians_use_of_the_platform: 'Clinicians use of the Platform',
      custom_range: 'Custom range',
      dashboard_views: 'Dashboard views',
      definitions: 'Definitions',
      discharged_individuals: 'Discharged individuals',
      efficiency_expenditure_and_cost: 'Efficiency, Expenditure, and Cost',
      effectiveness_and_outcomes: 'Effectiveness and Outcomes',
      exit_print_view: 'Exit print view',
      filter_by: 'Filter by',
      insights: 'Insights',
      individual_use_of_the_innowell_platform:
        'Individual use of the Innowell Platform',
      individual_care_option_satisfaction:
        'Individual care option satisfaction',
      innowell_platform_email_invitation: 'Innowell Platform email invitation:',
      initial_distribution_of_results: 'Initial distribution of results',
      initial_questionnaire_completions: 'Initial questionnaire completions',
      last_30_days: 'Last 30 days',
      last_7_days: 'Last 7 days',
      last_3_days: 'Last 3 days',
      metrics_are_an_important_starting_point_for_discussion_around_a_services_strengths_and_weaknesses_these_metrics_document_the_performance_of_your_service_after_implementing_the_innowell_platform_into_its_pathways_as_usual_practice_when_sample_sizes_are_small_descriptive_statistics_may_be_incorrect_care_should_be_taken_with_interpretation_and_extrapolation:
        'Metrics are an important starting point for discussion around a service’s strengths and weaknesses. These metrics document the performance of your service after implementing the Innowell Platform into its pathways as usual practice. When sample sizes are small, descriptive statistics may be incorrect. Care should be taken with interpretation and extrapolation.',
      notifications_for_suicide_thoughts_and_behaviour:
        'Notifications for Suicide thoughts and behaviour',
      number_of_service_notifications_upon_first_questionnaire_completion:
        'Number of service notifications upon first questionnaire completion',
      number_of_service_notifications_over_the_time_period_filtered:
        'Number of service notifications over the time period filtered',
      safety: 'Safety',
      satisfaction_and_acceptability: 'Satisfaction and Acceptability',
      service_insight: 'Service Insight',
      service: 'service',
      selected: 'selected',
      since_inception: 'Since inception',
      time_period: 'Time period:',
      the_time_of_innowell_platform_email_invitation_refers_to_the_latest_time_between_the_time_of_first_invitation_sent_and_the_time_of_invitation_that_was_accepted_by_the_user:
        'The time of Innowell Platform email invitation refers to the latest time between the time of first invitation sent and the time of invitation that was accepted by the user',
      total_individuals: 'Total individuals',
      update: 'Update',
      unless_otherwise_stated_all_metrics_reference_activities_or_times_recorded_during_the_selected_time_period_for_example_the_total_number_of_individuals_who_responded_to_a_questionnaire_would_only_display_the_individuals_who_responded_to_the_questionnaire_during_that_selected_time_period:
        'Unless otherwise stated, all metrics reference activities or times recorded during the selected time period. For example, the total number of individuals who responded to a questionnaire would only display the individuals who responded to the questionnaire during that selected time period.',
      use_of_the_innowell_platform: 'Use of the Innowell Platform',
      view_all_for_printing: 'View all for printing',
      working: 'Working',
      workforce: 'Workforce',
    },
    scoreCard: {
      activity_result: 'Activity Result',
      are: 'are',
      clinician: 'clinician',
      get_started: 'Get started',
      have: 'have',
      has: 'has',
      in_bed: 'In Bed',
      in_progress: 'In Progress',
      is: 'is',
      link: 'Link',
      my_result: 'My Result',
      no_results_for_this_time_period: 'No results for this time period',
      requested: 'Requested',
      requested_support: 'requested support',
      request_this: 'Request this',
      sleep_result: 'Sleep Result',
      started: 'started',
      steps_over_goal: 'Steps over goal',
      support: 'Support',
      supporting: 'Supporting',
      their: 'their',
      this_care_option_requires_your_clinicians_support:
        "This care option requires your clinician's support",
      total_steps: 'Total steps',
      unnamed_user: 'Unnamed User',
      visit: 'Visit',
      waiting_for: 'Waiting for',
      you: 'you',
      your: 'your',

      currentResultCardForumulaPage: {
        ago: 'ago',
        currentResultHeading: 'Current Result:',
        defaultDescription:
          'To find out your score simply complete the questionnaire.',
        lastUpdated: 'Last updated:',
        unanswered: 'Unanswered',
      },
      currentResultCardPage: {
        moreInformation: 'More information about',
        retakeQuestionnaire: 'Retake questionnaire',
      },
      scoreCardsCareOptionsPage: {
        all: 'All',
        careOptionsDescription:
          'Choose from the self-care options or request an option with a clinician.',
        careOptionsHeading: 'Care Options',
        selfCare: 'Self Care',
        workWithClinician: 'Work with Clinician',
      },
      scoreCardsChangeLogPage: {
        asOf: 'As of',
        wasUpdated: 'was updated.',
        learnMore: 'Learn more.',
      },
      scoreCardsNotificationsPage: {
        flashMessage: 'Thank you, your input was recorded.',
        linkText: 'notification',
        registeredIndividual: 'This individual has registered a',
        selectCareOption: 'Please select a care option and then submit',
        submitButton: 'Submit',
        takeAction: 'Please take action.',
      },
      scoreCardsPreviousAnswersPage: {
        answeredBy: 'Answered by',
        answeredOn: 'Answered on',
        completed: 'Completed',
        notSubmitted: 'Not Submitted',
        previousAnswersHeading: 'Previous answers',
        questionSet: 'Question Set',
        rating: 'Rating',
        results: 'Results',
        seeAll: 'See all',
        skipped: 'Skipped',
        status: 'Status',
      },
    },
    shared: {
      a: 'a',
      a_new_version_of_the_app_has_been_released:
        'A new version of the app has been released.',
      about_innowell: 'About Innowell',
      acknowledgements: 'Acknowledgements',
      actions: 'Actions',
      active_admin: 'Active Admin',
      add_another_person: 'Add another person',
      add_note: 'Add Note',
      add_support_person: 'Add Support Person',
      add_your_support_persons_email: 'Add your support person’s email',
      add_your_support_persons_name: 'Add your support person’s name',
      added: 'Added',
      age_gender: 'AGE / GENDER',
      ago: 'ago',
      and: 'and',
      another: 'Another',
      answer: 'Answer',
      are_you_sure_you_would_like_to_disconnect:
        'Are you sure you would like to disconnect?',
      assessments: 'Assessments',
      back: 'Back',
      back_to_dashboard: 'Back to dashboard',
      back_to_home: 'Back to Home',
      back_to_individual_list: 'Back to Individual list',
      beta: 'BETA Fr',
      benefits_of_connecting_fitbit_to_the_innowell_platform:
        'Benefits of connecting Fitbit to the Innowell Platform',
      benefits_of_innowell: 'Benefits of Innowell',
      call: 'Call',
      cancel: 'Cancel',
      changing_roles: 'Changing Roles',
      changing_tenant: 'Changing Tenant',
      copyright_2017: 'Copyright 2017',
      chat: 'Chat',
      check_out_the_new: 'Check out the new',
      click_here: 'Click here',
      clinicians: 'Clinicians',
      complete_the_questionnaire_in_one_sitting_preferred_or_come_back_over_time_if_necessary:
        'Complete the questionnaire in one sitting, preferred, or come back over time if necessary.',
      confirm_new_password: 'Confirm New Password',
      connect_your_fitbit: 'Connect your Fitbit',
      connected_organisations_and_access: 'Connected organisations and access',
      continue: 'Continue',
      contact_us: 'Contact us',
      contributing_your_deidentified_data_is_completely_voluntary_and_you_can_change_your_mind_at_any_time_furthermore_your_decision_whether_or_not_to_have_your_deidentified_data_used_for_research_purposes_will_not_affect_your_standard_clinical_care_or_your_current_or_future_relationship_with_your_service_provider_or_health_professional_or_innowell:
        'Contributing your de-identified data is completely voluntary and you can change your mind at any time. Furthermore, your decision whether or not to have your de-identified data used for research purposes will not affect your standard clinical care, or your current or future relationship with your service provider, or health professional, or Innowell.',
      copied_to_clipboard: 'Copied to clipboard.',
      copy_failed: 'Copy failed.',
      create_note: 'Create Note',
      dashboard: 'Dashboard',
      data_policy: 'data policy',
      date_of_birth: 'Date of Birth',
      date_time: 'Date / Time',
      different_perspectives_can_help_add_more_information_to_your_health_profile_they_will_receive_an_email_invite_to_answer_a_questionnaire_about_your_health_and_well_being:
        'Different perspectives can help add more information to your health profile. They will receive an email invite to answer a questionnaire about your health and well being.',
      disconnect: 'Disconnect',
      disconnect_your_fitbit: 'Disconnect your Fitbit',
      dismiss: 'Dismiss',
      each_health_area_has_associated_activities_called_care_options_that_provide_tools_for_improvement:
        'Each health area has associated activities, called Care Options, that provide tools for improvement.',
      email: 'email',
      email_address: 'Email Address',
      employees: 'Employees',
      enter_new_password: 'Enter new password',
      enter_note: 'Enter note',
      error: 'Error',
      error_code_errorcode: 'Error code: {{errorCode}}',
      faqs: 'FAQs',
      filter: 'filter',
      for_more_information_about_how_innowell_uses_data_please_see:
        'For more information about how Innowell uses data please see,',
      for_more_information_on_how_your_data_will_be_used:
        'for more information on how your data will be used.',
      get_realtime_updates_via_your_dashboard:
        'Get real-time updates via your dashboard',
      get_support: 'Get support',
      give_input: 'Give Input',
      has_been_removed: 'HAS BEEN REMOVED',
      health_card: 'Health Card',
      health_history: 'Health History',
      health_snapshot: 'Health Snapshot',
      healthy: 'Healthy',
      help_requested: 'Help Requested',
      history_of_requests: 'History of requests',
      i_need_help_now: 'I need help now',
      if_you_remove_a_support_person_they_wont_be_notified:
        'If you remove a support person they won’t be notified.',
      immediate_access_to_your_results_and_a_holistic_overview_of_your_health_and_wellbeing:
        'Immediate access to your results and a holistic overview of your health and wellbeing.',
      important_notes: 'Important notes',
      individual_info: 'Individual Info',
      individuals: 'Individuals',
      initial_questionnaire: 'Initial Questionnaire',
      initial_score: 'INITIAL SCORE',
      invalid_relationship_type: 'Invalid relationship type',
      invite: 'Invite',
      invite_sent_to: 'INVITE SENT TO:',
      invite_support_person: 'Invite support person',
      last_active: 'Last active',
      last_completed: 'Last Completed:',
      last_modified_june_2022: 'Last modified: June 2022',
      last_requested: 'Last Requested',
      lets_go: 'Lets go',
      load_more: 'Load More',
      managers: 'Managers',
      medical_director_id: 'Medical Director ID',
      more_info: 'More Info',
      move_time_range_back: 'Move time range back',
      move_time_range_forward: 'Move time range forward',
      name: 'Name',
      need_help: 'Need help',
      need_help_now: 'Need Help Now?',
      new: 'NEW!',
      new_password: 'New Password',
      no_clinician_notes: 'No Clinician Notes',
      no_not_interested: 'No, not interested',
      no_recent_activity: 'No Recent Activity',
      no_results_found: 'No results found',
      not_submitted: 'Not Submitted',
      notes: 'Notes',
      notifications: 'Notifications',
      notifications_history: 'Notifications history',
      on_this_page_you_can_see_the_organisations_and_clinicians_that_you_are_working_with:
        'On this page you can see the organisations and clinicians that you are working with',
      oops: 'Oops!',
      organisations: 'Organisations',
      please: 'Please',
      please_contact_your_service_to_change_these_settings:
        'Please contact your service to change these settings.',
      please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements_of_your_service:
        'Please ensure you take appropriate steps to protect the privacy of this document in accordance with the clinical governance requirements of your service.',
      preferred_name: 'Preferred Name',
      preview_notification: 'Preview Notification',
      privacy_policy: 'privacy policy',
      profile: 'Profile',
      profile_settings: 'Profile Settings',
      question: 'Question',
      recent_activity: 'Recent Activity',
      reinvite_support: 'Re-invite support',
      relationship: 'Relationship',
      remove: 'Remove',
      removed: 'Removed',
      repeat_password: 'Repeat password',
      report: 'Report',
      request_assessment_button: 'request assessment button',
      requested_assessments: 'Requested Assessments',
      research_and_data_sharing: 'Research and data sharing',
      research_data_sharing: 'Research Data Sharing',
      researchdatasharingpage: 'ResearchDataSharingPage',
      results_download_security_warning: 'Results download - security warning',
      results_of_the_questionnaire_are_shared_with_your_clinician_to_help_support_your_mental_health_care:
        'Results of the questionnaire are shared with your clinician to help support your mental health care.',
      resources: 'Resources',
      save: 'Save',
      see_more_details: 'See more details',
      sent_to: 'SENT TO',
      sign_out: 'Sign out',
      something_went_wrong_please_try_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email:
        'Something went wrong. Please try again later. If you need immediate help, please contact your service or send us a support email.',
      somethings_gone_wrong_but_its_likely_temporary_please_wait_a_few_moments_and_try_refreshing_the_page_or_come_back_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email:
        "Something's gone wrong, but it's likely temporary. Please wait a few moments and try refreshing the page or come back again later. If you need immediate help, please contact your service or send us a support email.",
      sorry_this_page_is_taking_way_too_long_to_load_please_wait_a_few_moments_and_try_refreshing_the_page_or_come_back_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email:
        'Sorry, this page is taking way too long to load. Please wait a few moments and try refreshing the page or come back again later. If you need immediate help, please contact your service or send us a support email.',
      start: 'Start',
      starting_questionnaire: 'Starting Questionnaire',
      submitted_at: 'Submitted at:',
      support_person: 'Support Person',
      support_persons_email_is_required: 'Support Person’s Email is required',
      support_persons_email_must_be_a_valid_email:
        'Support Person’s Email must be a valid email',
      support_persons_name_is_required: 'Support Person’s Name is required',
      support_request: 'SUPPORT REQUEST',
      switch_back: 'Switch Back',
      switch_individual: 'Switch Individual',
      switch_to: 'Switch To',
      syncing_your_fitbit_with_the_platform_allows_you_to_view_your_health_and_wellbeing_information_in_one_place:
        'Syncing your fitbit with the Platform allows you to view your health and wellbeing information in one place.',
      terms_and_conditions: 'terms and conditions',
      thank_you_for_sharing_your_deidentified_data_with_innowell:
        'Thank you for sharing your de-identified data with Innowell.',
      thank_you_your_selection_was_recorded:
        'Thank you, your selection was recorded.',
      the_innowell_platform_is_not_a_diagnostic_tool:
        'The Innowell platform is not a diagnostic tool.',
      they_will_not_be_able_to_view_any_of_your_health_information:
        'They will not be able to view any of your health information.',
      they_will_not_be_able_to_view_any_of_your_health_information_if_you_remove_a_support_person_they_wont_be_notified:
        'They will not be able to view any of your health information. If you remove a support person they won’t be notified.',
      they_will_not_be_notified_of_being_removed:
        'They will not be notified of being removed.',
      to_manage_access_to_your_data_please_contact_the_relevant_service:
        'To manage access to your data please contact the relevant service',
      to_old_summary_dashboard: 'to old summary dashboard.',
      to_reload_and_avoid_errors: 'to reload and avoid errors.',
      track_your_activity_and_sleep_progress:
        'Track your activity and sleep progress.',
      trend_graphs: 'trend graphs',
      unanswered: 'Unanswered',
      unauthorised_access: 'Unauthorised Access',
      view_health_history: 'View health history',
      view_profile: 'View profile',
      view_your_fitbit_data_in_the_context_of_your_health_and_wellbeing:
        'View your Fitbit data in the context of your health and wellbeing',
      we_cant_find_this_page: "We can't find this page",
      we_know_there_is_an_important_link_between_fitness_exercise_sleep_and_mental_health_this_is_the_reason_why_innowell_has_linked_with_fitbit:
        'We know there is an important link between fitness, exercise, sleep and mental health. This is the reason why Innowell has linked with Fitbit.',
      website: 'Website',
      yes_i_want_to_connect: 'Yes, I want to connect',
      you_are_in_read_only_mode: 'You are in read only mode.',
      you_are_not_authorised_to_access_this_page_for_access_please_contact_your_supervisor_or_systems_admin:
        'You are not authorised to access this page. For access, please contact your supervisor or systems admin.',
      you_can_always_disconnect_reconnect_at_any_time_here_is_more_information_on_innowells:
        'You can always disconnect / re-connect at any time. Here is more information on Innowell’s',
      your_information_is_secure_and_strictly_confidential:
        'Your information is secure and strictly confidential.',
      your_personal_information_is_deidentified_and_no_one_can_link_your_name_email_address_or_date_of_birth_to_your_health_information:
        'Your personal information is de-identified and no one can link your name, email address or date of birth to your health information.',
      your_personal_information_will_be_always_stored_in_the_innowell_platform_and_only_your_deidentified_data_will_be_used_for_research_purposes_the_deidentified_data_will_be_extracted_used_for_statistical_analysis_and_to_improve_the_overall_effectiveness_of_the_innowell_platform:
        'Your personal information will be always stored in The Innowell Platform and only your de-identified data will be used for research purposes. The de-identified data will be extracted, used for statistical analysis, and to improve the overall effectiveness of The Innowell Platform.',
      your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_this_information_is_used_to_improve_the_delivery_of_person_centred_care_contributing_your_deidentified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care_read_our:
        "Your service participates in the following research program(s) to develop a greater depth of understanding of people's needs. This information is used to improve the delivery of person centred care. Contributing your de-identified data is completely voluntary and will not affect your clinical care. Read our",
      your_session_has_expired_please_sign_in_again_to_continue:
        'Your session has expired. Please sign in again to continue.',
      your_support_person: 'Your Support Person',
      version: 'Version',
      resource_library: 'Resource Library',
      escalation_emails: 'Escalation emails',
      contact_email: 'Contact Email',
      contact_phone_number: 'Contact Phone Number',
      welcome: 'Welcome',
      welcome_back: 'Welcome back',
      insights: 'Insights',
      view_insights: 'View Insights',
      client_management: 'Client Management',
      invite_individuals: 'Invite individuals',
      staff_management: 'Staff Management',
    },
    staff: {
      actioned_by: 'Actioned by',
      ago: 'ago',
      all: 'All',
      an_invitation_to: 'an invitation to',
      and: 'and',
      are_you_sure_you_want_to_continue: 'Are you sure you want to continue?',
      assign_clinician: 'Assign Clinician',
      assign_clinicians: 'Assign Clinicians',
      assign_individuals: 'Assign individuals',
      assign: 'Assign',
      assign_no_one: '-- Assign no one --',
      assigned_please_allow_around_5_minutes_for_assignments_to_complete:
        'assigned. Please allow around 5 minutes for assignments to complete.',
      assigned: 'Assigned',
      care_option_actioned: 'Care option actioned',
      clinician_one: 'clinician',
      clinician_other: 'clinicians',
      continue: 'Continue',
      copied_to_clipboard: 'Copied to clipboard.',
      copy_failed: 'Copy failed.',
      could_not_be_assigned: 'could not be assigned',
      date_of_birth: 'Date of Birth',
      date: 'Date',
      discharge: 'Discharge',
      done: 'Done',
      email: 'Email',
      email_address: 'Email Address',
      email_must_be_a_valid_email: 'Email must be a valid email',
      employee_name: 'Employee name',
      employee_one: 'employee',
      employees_other: 'employees',
      failed: 'failed',
      faqs: 'FAQs',
      following_invitations_may_have_failed_because_one_or_more_clinicians_may_not_yet_be_active:
        'Following invitations may have failed because one or more clinicians may not yet be active',
      following_invitations_may_have_failed_because_they_are_currently_active_in_another_tenant:
        'Following invitations may have failed because they are currently active in another tenant.',
      for: 'for',
      from: 'from',
      health_card: 'Health Card',
      help_requested: 'Help Requested',
      including_those_with_unresolved_notifications:
        'including those with unresolved notifications.',
      individual_one: 'individual',
      individual_other: 'individuals',
      individuals_will_receive_an_sms_from_innowell_reminding_them_to_complete_their_onboarding_individuals_can_update_or_remove_their_mobile_number_on_their_profile_page_via_the_profile_settings_menu_at_any_time:
        'Individuals will receive an SMS from Innowell reminding them to complete their onboarding. Individuals can update or remove their mobile number on their profile page via the Profile Settings menu at any time.',
      initial_questionnaire: 'Initial Questionnaire',
      invitation_one: 'invitation',
      invitation_other: 'invitations',
      invitation_type: 'Invitation Type',
      invitations_sent_please_allow_around_5_minutes_for_reinvites_to_reach_individuals:
        'Invitations sent. Please allow around 5 minutes for re-invites to reach individuals.',
      invitations_sent: 'Invitations Sent',
      invitations_to_one: 'invitation to',
      invitations_to_other: 'invitation to',
      invite: 'Invite',
      invite_invite_type: 'Invite {{invite_type}}',
      invitations: 'invitations',
      invite_inidividual: 'Invite Individual',
      last_active: 'Last active',
      loading: 'Loading',
      manager: 'Manager',
      mobile_phone: 'Mobile Phone',
      more: 'more...',
      no_common_actions_ensure_selected_rows_have_a_common_role:
        'No common actions. Ensure selected rows have a common role.',
      no: 'No',
      or_role_type_cannot_be_of_a_global_tenant:
        ' or {{role_type}} cannot be of a global tenant',
      patient_name: 'Patient name',
      please_note_all_individuals_will_be_discharged:
        'Please note, all individuals will be discharged',
      preferred_name: 'Preferred Name',
      primary_clinician: 'Primary clinician',
      reason: 'Reason',
      reinvite: 'Re-invite',
      reinvites_sent_please_allow_around_5_minutes_for_reinvites_to_reach_individuals:
        'Re-invites sent. Please allow around 5 minutes for re-invites to reach individuals.',
      reinvites_sent: 'Re-invites Sent',
      remove_role: 'Remove Role',
      remove: 'Remove',
      request_assessments: 'Request Assessments',
      resend_invitation: 'Resend Invitation',
      resend_invitations: 'Resend Invitations',
      resend_role_invitations: 'Resend Role Invitations',
      resend: 'Resend',
      role: 'role',
      roles: 'Role(s)',
      role_type: 'Role Type',
      search_for_questionnaire: 'Search for questionnaire...',
      select_questionnaires_to_request: 'Select questionnaire(s) to request',
      send_requests: 'Send request(s)',
      sent: 'sent',
      service: 'Service',
      service_group: 'Service Group',
      status: 'Status',
      to_the_selected_clinicians: 'to the selected clinicians',
      unassigned: 'Unassigned',
      yes_assign: 'Yes, assign',
      yes_discharge: 'Yes, discharge',
      yes_reinvite: 'Yes, re-invite',
      yes_remove_role: 'Yes, remove role',
      yes_remove: 'Yes, remove',
      yes_resend_invitations: 'Yes, resend invitations',
      yes_resend: 'Yes, resend',
      you_are_about_to_assign: 'You are about to assign',
      you_are_about_to_discharge: 'You are about to discharge',
      you_are_about_to_reinvite: 'You are about to re-invite',
      you_are_about_to_remove: 'You are about to remove',
      you_are_about_to_resend: 'You are about to resend',
    },
    supporting: {
      age_range: 'Age range',
      alberta_health_services: 'Alberta Health Services',
      call: 'Call',
      chat: 'Chat',
      chats_for_life: 'Chats for Life',
      checkin: 'Check-in',
      close: 'Close',
      description: 'Description',
      for: 'for',
      hours_a_day_7_days_a_week: '24 hours a day, 7 days a week',
      how_often_should_you_answer_this_questionnaire:
        'How often should you answer this questionnaire?',
      if_you_or_someone_you_know_needs_immediate_help_call:
        'If you or someone you know needs immediate help, call',
      important_information: 'Important information',
      kids_help_phone: 'Kids Help Phone',
      need_help_now: 'Need Help now?',
      now: 'now.',
      or_call_5196466000_ext_65196: ', or call 519-646-6000, ext. 65196',
      or: 'or',
      phone_hours: 'Phone hours',
      ready_to_submit: 'Ready to submit',
      remove_yourself_as_support_person: 'Remove yourself as support person',
      remove: 'Remove',
      start_questionnaire: 'Start questionnaire',
      submit: 'Submit',
      submitted: 'Submitted',
      submitting: 'Submitting...',
      summary_questionnaire: 'Summary Questionnaire',
      talk_to_someone: 'Talk to Someone',
      text: 'Text',
      thank_you_for_successfully_completing_this_questionnaire:
        'Thank you for successfully completing this questionnaire.',
      theres_no_right_or_wrong_way_to_go_about_supporting_someone_often_the_first_step_is_having_an_open_conversation_while_this_can_be_difficult_to_do_check_out_the:
        'There’s no right or wrong way to go about supporting someone, often the first step is having an open conversation. While this can be difficult to do, check out the',
      this_questionnaire_will_help_provide_an_additional_perspective_on_the_health_and_well_being_of_the_person_you_are_supporting:
        'This questionnaire will help provide an additional perspective on the health and well being of the person you are supporting.',
      this_questionnaire_will_help_to_provide_different_perspectives_on_the_health_and_well_being_of_the_person_you_are_caring_for:
        'This questionnaire will help to provide different perspectives on the health and well being of the person you are caring for.',
      to_help_you_plan_better_for_these_conversations:
        'to help you plan better for these conversations.',
      website_or_contact: 'website, or contact',
      website_to_help_you_plan_better_for_these_conversations:
        'website to help you plan better for these conversations.',
      website: 'website',
      welcome_name_and_thank_you_for_your_time_today:
        'Welcome {{name}} and thank you for your time today!',
      what_else_can_i_do_now: 'What else can I do now?',
      what_is_this_questionnaire_for: 'What is this questionnaire for?',
      who_will_see_your_answers: 'Who will see your answers?',
      you_are_not_supporting_anyone: 'You are not supporting anyone',
      you_can_reanswer_the_questionnaire_at_anytime_by_logging_into_the_innowell_platform_the_person_you_are_caring_for_can_also_send_an_email_request_for_you_to_update_the_questionnaire:
        'You can re-answer the questionnaire at anytime by logging into the Innowell platform. The person you are caring for can also send an email request for you to update the questionnaire.',
      your_answers_will_be_seen_by_the_person_you_are_caring_for_and_their_connected_clinicians:
        'Your answers will be seen by the person you are caring for and their connected clinicians.',
      your_answers_will_only_be_seen_by_the_person_you_are_supporting_and_their_connected_clinicians:
        'Your answers will only be seen by the person you are supporting and their connected clinicians.',
      youve_been_requested_to_answer_the_following_questionnaires_for:
        "You've been requested to answer the following questionnaire(s) for...",
    },
    settings: {
      add_your_mobile_number: 'Add your mobile number',
      back: 'Back',
      clinicians_and_healthcare_providers:
        'Clinicians and healthcare providers',
      contact_us: 'Contact us',
      eg_0426664444: 'e.g. 0426664444',
      email_address: 'Email address',
      email_change_requested_awaiting_confirmation:
        'Email change requested, awaiting confirmation.',
      email: 'Email',
      for_further_details: 'for further details.',
      health_history: 'Health History',
      innowell_will_never_share_your_mobile_number_if_you_want_more_information_on_your_data_please_see_our:
        'Innowell will never share your mobile number. If you want more information on your data please see our',
      legal_name_must_contain_at_least_a_first_name_and_a_family_name:
        'Legal name must contain at least a first name and a family name.',
      legal_name: 'Legal name',
      manage_your_settings: 'Manage your settings',
      mobile_number_is_10_digits_and_starts_with_04_eg_0426664444:
        'Mobile number is 10 digits and starts with 04 e.g. 0426664444',
      mobile_number: 'Mobile number',
      notifications_settings: 'Notifications Settings',
      on_this_page_you_can_manage_your_information_including_your_contact_details:
        'On this page, you can manage your information, including your contact details',
      or_call_519_646_6000_ext_65196: 'or call 519-646-6000, ext. 65196',
      password: 'Password',
      please_refer_to_the: 'Please refer to the',
      preferred_language: 'Preferred Language',
      preferred_name: 'Preferred name',
      privacy_policy: 'Privacy policy',
      profile_settings: 'Profile Settings',
      profile: 'Profile',
      research_and_data_sharing: 'Research and data sharing',
      sms_notifications: 'SMS notifications',
      sorry_this_username_has_already_been_taken:
        'Sorry, this username has already been taken',
      to_be_removed_from_the_innowell_platform_email_your_request_to:
        'To be removed from the Innowell platform, email your request to:',
      update_email: 'Update email',
      update_name: 'Update name',
      update_phone_number: 'Update phone number',
      update_preferred_language: 'Update preferred language',
      update_username: 'Update username',
      update: 'Update',
      username: 'Username',
      what_is_your_email: 'What is your email?',
      what_is_your_legal_name: 'What is your Legal Name?',
      what_is_your_preferred_language: 'What is your Preferred Language?',
      what_is_your_preferred_name: 'What is your Preferred Name?',
      what_is_your_username: 'What is your Username?',
      your_mobile_number_will_be_used_to_send_you_reminders_and_notifications_you_can_turn_notifications_on_or_off_at_any_time_on_your_notifications_page:
        'Your mobile number will be used to send you reminders and notifications. You can turn notifications on or off at any time on `your notifications` page.',
      your_mobile_number_will_be_used_to_send_you_reminders_and_notifications_you_can_turn_sms_notifications_on_or_off_anytime:
        'Your mobile number will be used to send you reminders and notifications. You can turn SMS notifications on or off anytime.',
    },
    validations: {
      email_validation_error: 'Email validation error',
      field_is_required: '{{field}} is required',
      mobile_number_must_follow_04: 'Mobile number must follow 04********',
      or_role_type_cannot_be_of_a_global_tenant:
        'or {{role_type}} cannot be of a global tenant',
      phone_number_has_already_been_taken:
        'Phone number has already been taken',
      phone_validation_error: 'Phone validation error',
      service_group_is_required_for_the_value_role_type:
        'Service group is required for the {{value}} role type',
      this_individual_email_address_cannot_be_used_for_a_staff_role_for_support_on_how_to_invite_an_individual_to_a_staff_role_see_the:
        'This individual (email address) cannot be used for a staff role. For Support on how to invite an individual to a staff role - see the',
      this_individual_email_address_is_active_in_another_service_and_cannot_be_invited:
        'This individual (email address) is active in another service and cannot be invited',
      this_individual_email_address_is_already_active_and_cannot_be_invited:
        'This individual (email address) is already active and cannot be invited',
      this_owner_email_address_cannot_be_used_for_a_manager_role_for_support_on_how_to_invite_an_owner_to_a_manager_role_see_the:
        'This owner (email address) cannot be used for a manager role. For Support on how to invite an owner to a manager role - see the',
      this_staff_member_email_address_cannot_be_used_for_an_individual_role_for_support_on_how_to_invite_a_staff_member_to_an_individual_role_see_the:
        'This staff member (email address) cannot be used for an individual role. For support on how to invite a staff member to an individual role - see the',
      this_support_person_email_address_cannot_be_used_for_a_staff_role_for_support_on_how_to_invite_a_support_person_to_a_staff_role_see_the:
        'This support person (email address) cannot be used for a staff role. For Support on how to invite a support person to a staff role - see the',
    },
  },
  fr: {
    assessment: {
      a_quick_check_in_on_mental_health_incorporating_multiple_instruments_and_evaluating_general_health_history_and_wellbeing:
        'Un enregistrement rapide de la santé mentale, intégrant plusieurs instruments et évaluant les antécédents de santé généraux et le bien-être.',
      answer_one: 'Répondre',
      answer_other: 'Réponses',
      answered_by: 'Répondu par',
      answered_on: 'Répondu le',
      answered: 'Répondu',
      assessments_that_dont_apply_to_the_patient_have_been_removed_from_the_list:
        "Les évaluations qui ne s'appliquent pas au patient ont été supprimées de la liste.",
      assessments: 'Évaluations',
      back_to_details: 'Retour aux détails',
      complete_all: '(Complétez tout)',
      completed: 'Complété',
      completed_on: 'Terminé le',
      cluster: 'Grappe',
      full_questionnaire: 'Questionnaire complet',
      incomplete: 'Incomplet',
      individual: 'Individuel',
      last_answered: 'Dernière réponse',
      library: 'Bibliothèque',
      no_assessment_request: "Aucune demande d'évaluation trouvée",
      no_results_found: 'Aucun résultat trouvé',
      provide_a_comprehensive_overview_of_mental_health_across_multiple_domains_evaluating_symptoms_functioning_risk_indicators_and_support_systems:
        'Fournir un aperçu complet de la santé mentale dans plusieurs domaines, en évaluant les symptômes, le fonctionnement, les indicateurs de risque et les systèmes de soutien.',
      question_set: 'Ensemble de questions',
      question: 'Question',
      questionnaire: 'Questionnaire',
      rating: 'Notation',
      request_assessments: 'Demander des évaluations',
      requested_assessments: 'Évaluations demandées',
      requested_on: 'Demandé le',
      requested: 'Demandé',
      search_bar: 'Barre de recherche',
      search_for_questionnaire: 'Rechercher un questionnaire...',
      select_a_person: 'Sélectionnez une personne',
      select_person_to_send_a_request_to:
        'Sélectionnez la personne à qui envoyer une demande',
      select_questionnaire_to_request:
        'Sélectionnez le(s) questionnaire(s) à demander',
      send_requests: 'Envoyer des demandes',
      skipped: 'Sauté',
      status: 'Statut',
      summary_questionnaire: 'Questionnaire récapitulatif',
      take_assessment: 'Passer une évaluation',
      unanswered: 'Sans réponse',
      updating_list: 'Mise à jour de la liste',
      view_answers_from: 'Afficher les réponses de',
      view_answers: 'Afficher les réponses',
      out_of: 'de',
      approx: 'environ',
      to_complete: 'compléter',
      secure_and_confidential: 'sécurisé et confidentiel',
      saved_automatically: 'enregistré automatiquement',
      skip: 'Sauter',
      next: 'Suivant',
      submit: 'Soumettre',
      submitting: 'Soumission',
      need_one: 'besoin',
      need_other: 'besoins',
      completing: 'terminer',
      sorry_your_role_is_not_supported_for_questionnaires:
        "Désolé, votre rôle n'est pas pris en charge pour les questionnaires.",
      well_done: 'Bien joué',
      you_are_almost_done: 'Tu as presque fini',
      of_the_initial_questionnaire_completed:
        'du questionnaire initial complété',
      you_can_still_submit_your_answers_and_return_to_the_skipped_incomplete_questions_anytime_or_if_you_prefer_you_can_choose_to_do_them_with_a_support_person_or_your_clinician:
        'Vous pouvez toujours soumettre vos réponses et revenir aux questions sautées/incomplètes à tout moment ou si vous préférez, vous pouvez choisir de les faire avec une personne de soutien ou votre clinicien.',
      your_dashboard_has_sorted_your_results_into_health_cards_so_you_can_focus_on_whats_important_and_explore_a_range_of_care_options_that_help_you_meet_your_health_goals:
        "Votre tableau de bord a classé vos résultats dans des cartes de santé afin que vous puissiez vous concentrer sur ce qui est important et explorer une gamme d'options de soins qui vous aident à atteindre vos objectifs de santé.",
      you_can_discuss_your_results_with_your_clinician_your_clinician_will_work_with_you_through_the_dashboard_to_tailor_treatment_to_your_needs:
        'Vous pouvez discuter de vos résultats avec votre clinicien. Votre clinicien travaillera avec vous via le tableau de bord pour adapter le traitement à vos besoins.',
      skipped_and_incomplete_sections: 'Sections ignorées et incomplètes',
      completed_sections: 'Sections terminées',
      click_submit_to_enter_your_clinical_input:
        'Cliquez sur soumettre pour entrer votre saisie clinique',
      routinely_update_your_responses_to_keep_your_clinical_input_current_in_this_individuals_dashboard_it_will_help_you_monitor_progress_over_time_and_support_clinical_decision_making:
        'Mettez régulièrement à jour vos réponses pour maintenir vos saisies cliniques à jour dans le tableau de bord de cet individu. Cela vous aidera à surveiller les progrès au fil du temps et à soutenir la prise de décisions cliniques.',
      your_input: 'Votre saisie',
      view_input: 'Voir la saisie',
      your_answers_can_be_viewed_by_yourself_and_other_connected_innowell_clinicians:
        "Vos réponses peuvent être consultées par vous-même et d'autres cliniciens connectés à Innowell.",
      thank_you: 'Merci',
      select_all_that_apply: "Sélectionnez tout ce qui s'applique",
      the_feet_should_be_between: 'Les pieds doivent être entre',
      the_inches_should_be_between: 'Les pouces doivent être compris entre',
      score: 'Score fr',
      range: 'Gamme',
      na: 'N/A fr',
      up_to: "Jusqu'à",
      select_options: 'Sélectionnez les options',
      clinician: 'Clinicien',
      support_person: 'Personne de soutien',
      questionnaire_completed: 'Questionnaire complété',
      you_have_already_successfully_submitted_this_questionnaire:
        'Vous avez déjà soumis ce questionnaire avec succès.',
      home: 'Accueil',
      section_completed: 'Section terminée',
      if_you_wish_to_edit_any_previous_answers_you_can_do_so_via_the_dashboard:
        'Si vous souhaitez modifier des réponses précédentes, vous pouvez le faire via le tableau de bord.',
      next_section: 'Section suivante',
      reason_to_skip: 'Raison de sauter',
      submiting: 'Soumission',
      no_defaults: 'Aucune valeur par défaut',
      browse_care_options: 'Parcourir les options de soins',
      view_details: 'Afficher les détails',
    },
    auth: {
      already_have_an_account: 'Vous avez déjà un compte ?',
      australia: 'Australie',
      back_to_login: 'Retour à la connexion',
      canada: 'Canada',
      current_password: 'Mot de passe actuel',
      current_password_is_required: 'Le mot de passe actuel est requis',
      email: 'E-mail',
      email_is_a_required_field: "L'e-mail est un champ requis",
      email_is_required: "L'e-mail est requis",
      email_must_be_a_valid_email: "L'e-mail doit être valide",
      enter_old_password: "Entrez l'ancien mot de passe",
      enter_verification_code: 'Entrez le code de vérification',
      forgot_your_password: 'Mot de passe oublié ?',
      get_help_signing_in: "Obtenez de l'aide pour vous connecter.",
      i_cant_set_it_up_now_log_me_out:
        'Je ne peux pas le configurer maintenant, déconnectez-moi',
      i_dont_have_the_code_now_log_me_out:
        "Je n'ai pas le code maintenant, déconnectez-moi",
      if_the_email_address_or_region_provided_is_registered_on_our_system_an_email_with_the_instructions_to_reset_the_password_will_be_sent_shortly_this_password_reset_is_only_valid_for_the_next_6_hours:
        "Si l'adresse e-mail ou la région fournie est enregistrée dans notre système, un e-mail avec les instructions pour réinitialiser le mot de passe sera envoyé sous peu. Cette réinitialisation du mot de passe est valide uniquement pendant les 6 prochaines heures.",
      if_you_do_not_receive_an_email_in_5_minutes_please_check_your_region_setting_and_email_address:
        'Si vous ne recevez pas un e-mail dans les 5 minutes, veuillez vérifier votre région et votre adresse e-mail.',
      invalid_verification_code: 'Code de vérification invalide',
      keep_me_signed_in: 'Rester connecté',
      must_match_password: 'Doit correspondre au mot de passe',
      new_passwords_must_match:
        'Les nouveaux mots de passe doivent correspondre',
      next: 'Suivant',
      password: 'Mot de passe',
      password_confirmation: 'Confirmation du mot de passe',
      password_confirmation_is_required:
        'La confirmation du mot de passe est requise',
      password_is_a_required_field: 'Le mot de passe est un champ requis',
      password_is_required: 'Le mot de passe est requis',
      password_must_be_at_least_6_characters:
        'Le mot de passe doit comporter au moins 6 caractères',
      password_must_be_at_least_8_characters:
        'Le mot de passe doit comporter au moins 8 caractères',
      please_check_your_email: 'Veuillez vérifier votre e-mail',
      please_create_a_new_password: 'Veuillez créer un nouveau mot de passe',
      please_enter_verification_code: 'Veuillez entrer le code de vérification',
      please_enter_your_authenticator_app_verification_code_below:
        "Veuillez entrer le code de vérification de votre application d'authentification ci-dessous.",
      please_enter_your_email_below_to_receive_password_reset_instructions:
        'Veuillez entrer votre e-mail ci-dessous pour recevoir les instructions de réinitialisation du mot de passe',
      qr_code: 'Code QR',
      request_new_invite: 'Demander une nouvelle invitation',
      request_the_link_again: 'Demander à nouveau le lien',
      save: 'Enregistrer',
      scan_the_qr_code_with_your_authentication_app_and_enter_the_verification_code_below:
        "Scannez le code QR avec votre application d'authentification et entrez le code de vérification ci-dessous.",
      secure_your_account: 'Sécurisez votre compte',
      select_your_region: 'Sélectionnez votre région',
      send_instructions: 'Envoyer les instructions',
      sign_in: 'Se connecter',
      signing_in: 'Connexion en cours',
      signing_out: 'Déconnexion en cours',
      sorry_an_error_occured_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Désolé, une erreur est survenue avec notre serveur. Nous avons informé notre équipe afin qu'elle puisse la résoudre. Veuillez réessayer.",
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_refresh_the_page_before_trying_to_login_again:
        "Désolé, une erreur est survenue avec notre serveur. Nous avons informé notre équipe afin qu'elle puisse la résoudre. Veuillez actualiser la page avant de tenter de vous reconnecter.",
      sorry_we_couldnt_change_your_passowrd:
        "Désolé, nous n'avons pas pu changer votre mot de passe",
      sorry_we_couldnt_change_your_password:
        "Désolé, nous n'avons pas pu changer votre mot de passe",
      sorry_your_new_password: 'Désolé, votre nouveau mot de passe',
      sorry_your_new_password_is: 'Désolé, votre nouveau mot de passe est',
      sorry_your_token: 'Désolé, votre jeton',
      success: 'Succès',
      this_invite_is_invalid: 'Cette invitation est invalide.',
      update_your_password: 'Mettez à jour votre mot de passe',
      use_the_button_below_to_request_a_new_invite_from_your_service:
        'Utilisez le bouton ci-dessous pour demander une nouvelle invitation à votre service.',
      username: "Nom d'utilisateur",
      username_is_a_required_field: "Le nom d'utilisateur est un champ requis",
      welcome_to_the_innowell_platform: 'Bienvenue sur la plateforme Innowell',
      your_password_has_been_updated: 'Votre mot de passe a été mis à jour.',
    },
    banners: {
      your_session_will_timeout_soon_click_here_to_reload_and_avoid_having_to_sign_in_again:
        'Votre session expirera bientôt. Cliquez ici pour recharger et éviter de devoir vous reconnecter.',
      your_session_has_now_timed_out_click_here_to_sign_in_again:
        'Votre session a expiré. Cliquez ici pour vous reconnecter.',
    },
    graphs: {
      apply: 'Appliquer',
      back_to_dashboard: 'Retour au tableau de bord',
      cancel: 'Annuler',
      custom: 'Coutume',
      monthly: 'Mensuel',
      signIn: 'se connecter',
      signIns: 'connexionss',
      weekly: 'Hebdomadaire',
      yearly: 'Annuel',
    },
    dashboard: {
      by_keeping_up_to_date_you_can_track_your_health_and_well_being_over_time:
        'En vous tenant au courant, vous pouvez suivre votre santé et votre bien-être au fil du temps.',
      click_here: 'cliquez ici',
      click_here_to_start: 'Cliquez ici pour commencer',
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        'Cliquez sur une carte Santé pour trouver des fiches d’information, parcourir un certain nombre d’options de soins et consulter vos résultats.',
      common_tasks: 'Tâches courantes',
      continue: 'Continuer',
      download_a_pdf_of_their_health_history:
        'Téléchargez un PDF de leurs antécédents médicaux.',
      download_health_history_pdf: "Télécharger l'historique de santé PDF",
      finish_questionnaire: 'Terminer le questionnaire',
      finish_the_questionnaire_to_create_a_baseline_of_your_health_and_well_being:
        'Terminez le questionnaire pour créer une base de référence sur votre santé et votre bien-être.',
      for_more_information_on_the_use_of_your_data:
        'Pour plus d’informations sur l’utilisation de vos données,',
      full_questionnaire: 'Questionnaire complet',
      give_input: 'Donner votre avis',
      health_history_pdf: 'Antécédents de santé PDF',
      i_accept: "J'accepte",
      i_decline: 'Je refuse',
      invite_edit_support_person: "Inviter/Modifier une personne d'assistance",
      it_appears_we_dont_have_all_your_answers:
        'Il semble que nous n’ayons pas toutes vos réponses.',
      its_your_history_and_you_can_take_it_with_you:
        "C'est votre histoire et vous pouvez l'emporter avec vous.",
      loading: 'Chargement',
      no_health_cards_found: 'Aucune carte de santé trouvée.',
      out_of: 'de',
      please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements:
        'Veuillez vous assurer de prendre les mesures appropriées pour protéger la confidentialité de ce document conformément aux exigences de gouvernance clinique.',
      provide_feedback_to_mark_progress_of_your_service:
        'Fournissez des commentaires pour marquer la progression de votre service.',
      research_and_data_sharing: 'Recherche et partage de données',
      request_assessments: 'Demander une ou des évaluations',
      retake_full_questionnaire: 'Reprendre le questionnaire complet',
      retake_questionnaire: 'Reprendre le questionnaire',
      retake_summary_questionnaire: 'Reprendre le questionnaire récapitulatif',
      retaking_the_full_questionnaire_resets_your_baseline_across_all_domains:
        'Reprendre le questionnaire complet réinitialise votre référence dans tous les domaines.',
      save: 'Sauvegarder',
      select_change_focus_areas:
        'Sélectionner/modifier les zones de mise au point',
      send_an_email_with_a_list_of_assessments_to_be_completed:
        'Envoyez un e-mail avec une liste des évaluations à compléter.',
      summary_questionnaire: 'Questionnaire récapitulatif',
      thank_you_your_selection_was_recorded:
        'Merci, votre sélection a été enregistrée.',
      view_requested_assessments: 'Afficher les évaluations demandées',
      view_requested_assessments_from_your_clinician_and_review_previous_results:
        'Consultez les évaluations demandées par votre clinicien et examinez les résultats précédents.',
      you_can_find_these_in_the_requested_assessments_under_the_common_tasks_section_on_your_dashboard:
        'Vous pouvez les trouver dans la ou les évaluations demandées sous la section Tâches courantes de votre tableau de bord.',
      you_can_invite_a_support_person_to_answer_some_questions_about_your_overall_health_and_well_being:
        'Vous pouvez inviter une personne de soutien à répondre à quelques questions sur votre santé et votre bien-être.',
      your_clinician_has_requested_you_to_update_some_questionnaires:
        'Votre clinicien vous a demandé de mettre à jour certains questionnaires.',
      your_health_priorities: 'Vos priorités en matière de santé',
      your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_your_data_is_anonymous_to_everyone_other_than_you_and_your_service_provider_contributing_your_identified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care:
        'Votre service participe au(x) programme(s) de recherche suivant(s) pour développer une meilleure compréhension des besoins des gens. Vos données sont anonymes pour toute personne autre que vous et votre fournisseur de services. La contribution de vos données identifiées est entièrement volontaire et n’affectera pas vos soins cliniques.',
    },
    dataTable: {
      actioned: 'Traité',
      active: 'Actif',
      add: 'Ajouter',
      add_note_count: 'Ajouter une note{{count}}',
      apply: 'Appliquer',
      ascending: 'Ascendant',
      assign_clinicians: 'Affecter des cliniciens',
      assign_individual: 'Attribuer un individu',
      breakdown_of_this_individuals_cluster_probability:
        'Répartition de la probabilité de regroupement de cet individu :',
      clinicians: 'Cliniciens',
      date: 'Date Fr',
      descending: 'Descendant',
      discharge: 'Décharge',
      edit: 'Modifier',
      email_address: 'Adresse e-mail',
      employee_name: "Nom de l'employé",
      filter: 'Filtrer',
      give_input: 'Donner votre avis',
      help_requested: 'Aide demandée',
      no_rating: 'Aucune note',
      notifications: 'Notifications Fr',
      invited: 'Invité',
      open_between_time1_hours_and_time2_hours:
        'Ouvert entre {{time1}} heures et {{time2}} heures',
      open_more_than_time_hours: 'Ouvert depuis plus de {{time}} heures',
      patient_name: 'Nom du patient',
      remove: 'Retirer',
      removed: 'Supprimé',
      status: 'Statut',
      stb_notifications: 'Notifications STB',
      stratified_scoring_provides_an_overall_summary_of_a_persons_clinical_needs_based_on_their_initial_answers_in_6_areas_depression_anxiety_psychosis_mania_functioning_and_suicidal_thoughts_and_behaviours_the_distinct_groups_have_been_derived_using_clustering_analysis_based_on_meaningful_differences_observed_between_the_groups_this_information_should_be_used_to_guide_personalised_decisions_about_the_level_of_care_a_person_requires:
        "Le scoring stratifié fournit un résumé global des besoins cliniques d'une personne basé sur ses réponses initiales dans 6 domaines (dépression, anxiété, psychose, manie, fonctionnement et pensées et comportements suicidaires). Les groupes distincts ont été dérivés à l'aide d'une analyse de regroupement basée sur des différences significatives observées entre les groupes. Ces informations doivent être utilisées pour orienter les décisions personnalisées concernant le niveau de soins requis par une personne.",
      suicidal_thoughts_and_behaviours: 'Pensées et comportements suicidaires',
      reinvite: 'Réinviter',
      resend_invitation: "Renvoyer l'invitation",
      resend_role_invitation: "Renvoyer {{role}} l'invitation",
      reset_mfa: 'Réinitialiser MFA',
      recent_activity: 'Activité récente',
      search: 'Recherche',
      sort: 'Trier',
      total: 'totale',
      this_clinician_has_not_onboarded_or_is_not_active:
        "Ce clinicien n'a pas été intégré ou n'est pas actif",
      this_person_is_active_at_another_service:
        'Cette personne est active dans un autre service',
      this_person_is_already_a_staff_member:
        'Cette personne fait déjà partie du personnel',
    },
    feedback: {
      contact_us: 'Contactez-nous',
      description_is_required: 'La description est obligatoire',
      description: 'Description',
      for_privacy_purposes_if_reporting_on_behalf_of_an_individual_please_do_not_include_any_sensitive_and_personal_information_of_the_individual_user_eg_email_address_or_user_name_innowell_will_be_responsible_for_responding_to_your_query_any_followup_with_the_individual_thereafter_should_be_managed_through_you_and_your_service:
        "Pour des raisons de confidentialité, si vous signalez au nom d'un individu, veuillez ne pas inclure d'informations sensibles et personnelles sur l'utilisateur individuel (par exemple, adresse e-mail ou nom d'utilisateur). Innowell sera responsable de répondre à votre demande. Tout suivi avec l'individu devra ensuite être géré par vous et votre service.",
      let_us_know_how_we_can_help:
        'Faites-nous savoir comment nous pouvons vous aider',
      please_include_as_much_information_as_possible:
        "Veuillez inclure autant d'informations que possible",
      please_select: '--Veuillez sélectionner--',
      send: 'Envoyer',
      subject_is_required: 'Le sujet est obligatoire',
      subject: 'Sujet',
      topic_is_required: 'Le sujet est obligatoire',
      topic: 'Sujet',
      what_if_i_am_reporting_on_behalf_of_an_individual:
        "Que faire si je signale au nom d'un individu ?",
      your_feedback_is_important_to_us_it_helps_us_to_continue_improving_how_the_innowell_platform_works_best_for_you:
        'Vos commentaires sont importants pour nous. Ils nous aident à continuer à améliorer la manière dont la plateforme Innowell fonctionne le mieux pour vous.',
    },
    health: {
      click_on_a_health_card_to_find_fact_sheets_browse_a_number_of_care_options_and_review_your_results:
        "Cliquez sur une carte de santé pour trouver des fiches d'information, parcourir plusieurs options de soins et examiner vos résultats",
      latest_results: 'Derniers résultats',
      view_assessment_library: "Voir la bibliothèque d'évaluations",
      browse_care_options: 'Parcourir les options de soins',
      retake_questionnaire: 'Refaire le questionnaire',
      update: 'Mettre à jour',
      update_health_priorities: 'Mettre à jour les priorités de santé',
      view_answers: 'Voir les réponses',
      save: 'Enregistrer',
      select_up_to_3_domains_to_focus_on_to_improve_your_overall_wellbeing:
        "Sélectionnez jusqu'à 3 domaines sur lesquels vous concentrer pour améliorer votre bien-être global.",
      thank_you: 'Merci',
      select_health_domains: 'Sélectionner les domaines de santé',
      health_priorities: 'Priorités de santé',
      notifications_history: 'Historique des notifications',
      initial_cluster_stage: 'Phase initiale du cluster',
      these_are_the_focus_areas_to_improve_your_overall_wellbeing:
        'Ce sont les domaines sur lesquels se concentrer pour améliorer votre bien-être global',
      you_currently_have_no_health_priorities_selected:
        "Vous n'avez actuellement aucune priorité de santé sélectionnée.",
      you_have_chosen_3_health_priorities_you_can_update_these_anytime:
        'Vous avez choisi 3 priorités de santé. Vous pouvez les mettre à jour à tout moment.',
      select_health_priorities: 'Sélectionner les priorités de santé',
    },
    hooks: {
      initial_questionnaire: 'Questionnaire initial',
      research_and_data_sharing: 'Recherche et partage des données',
      skipped: 'Passé',
      tell_us_a_bit_about_yourself: 'Parlez-nous un peu de vous',
      the_health_assessment_for_this_section_is_not_complete:
        'L’évaluation de la santé pour cette section n’est pas terminée.',
      to_get_a_result_for_this_health_card_you_can_update_your_answers_anytime:
        'Pour obtenir un résultat pour cette carte de santé, vous pouvez mettre à jour vos réponses à tout moment.',
      unanswered: 'Non répondu',
      view_results: 'Voir les résultats',
      you_chose_to_skip_and_do_this_question_set_at_a_later_time:
        'Vous avez choisi de passer cet ensemble de questions et de le faire plus tard.',
      you_chose_to_skip_and_do_this_question_set_with_a_support_person:
        'Vous avez choisi de passer cet ensemble de questions et de le faire avec une personne de soutien.',
      you_chose_to_skip_and_do_this_question_set_with_your_clinician_this_request_has_been_shared_with_them:
        'Vous avez choisi de passer cet ensemble de questions et de le faire avec votre clinicien. Cette demande a été partagée avec eux.',
      you_chose_to_skip_this_question_set_because_you_preferred_not_to_answer:
        'Vous avez choisi de passer cet ensemble de questions car vous avez préféré ne pas répondre.',
    },
    insights: {
      baseline_scores: 'Scores de base',
      changes_between_baseline_ratings_and_latest_ratings_by_domain:
        'Changements entre les notes de base et les dernières notes par domaine.',
      clinician_sign_ins: 'Connexions des cliniciens',
      deteriorate: 'Détériorer',
      displays_results_from_the_beginning_of_your_innowell_journey_until_the_latest_completed_calendar_quarter:
        "Affiche les résultats depuis le début de votre parcours Innowell jusqu'au dernier trimestre civil terminé",
      domain_ratings_have_been_normalised_into_a_standard_developed_by_innowell:
        'Les évaluations de domaine ont été normalisées dans une norme développée par Innowell.',
      good: 'Bon',
      high: 'Élevé',
      individual_onboarding: 'Intégration individuelle',
      individual_sign_ins: 'Connexions individuelles',
      initial_domain_ratings_recorded_during_the_selected_time_period:
        'Évaluations initiales du domaine enregistrées pendant la période sélectionnée.',
      insights: 'Connaissances',
      insights_new: 'Nouveaux aperçus',
      lifetime: 'Durée de vie',
      latest_ratings_must_fall_within_selected_time_frame_to_be_displayed_domains_that_have_only_a_baseline_rating_have_been_excluded_from_this_chart:
        "Les dernières notes doivent correspondre à la période sélectionnée pour être affichées. Les domaines qui n'ont qu'une note de base ont été exclus de ce graphique.",
      moderate: 'Modéré',
      mild: 'Doux',
      no_change: 'Aucun changement',
      no_data_available_for_this_period:
        'Aucune donnée disponible pour cette période.',
      no_domains_with_a_baseline_score_can_be_found_within_thin_perion:
        'Aucun domaine avec un score de référence ne peut être trouvé au cours de cette période.',
      note_percentages_have_been_rounded_to_whole_numbers:
        'Remarque : Les pourcentages ont été arrondis aux nombres entiers.',
      number_of_clinician_sign_ins: 'Nombre de connexions de cliniciens',
      number_of_individual_sign_ins: 'Nombre de connexions individuelles',
      improved: 'Amélioré',
      recovery_trajectories: 'Trajectoires de récupération',
      recovery_trajectory_can_only_be_calculated_with_at_least_two_results_no_domains_with_a_latest_result_found_within_this_period:
        'La trajectoire de récupération ne peut être calculée qu’avec au moins deux résultats. Aucun domaine avec un dernier résultat trouvé au cours de cette période.',
      skipped: 'Sauté',
      stb_escalations: 'Escalades STB',
      summary_questionnaire: 'Questionnaire récapitulatif',
      this_graph_visualises_the_number_of_clinician_sign_ins_over_the_specified_period_helping_to_track_employee_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_clinician_signed_in:
        "Ce graphique visualise le nombre de connexions de cliniciens sur la période spécifiée, aidant ainsi à suivre l'engagement et l'activité des employés sur la plateforme. Le graphique décomposera les connexions en fonction du nombre de fois où chaque clinicien s'est connecté.",
      this_graph_visualises_the_number_of_individual_sign_ins_over_the_specified_period_helping_to_track_user_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_individual_signed_in:
        "Ce graphique visualise le nombre de connexions individuelles sur la période spécifiée, aidant ainsi à suivre l'engagement et l'activité des utilisateurs sur la plateforme. Le graphique décomposera les connexions en fonction du nombre de fois où chaque individu s'est connecté.",
      this_graph_visualises_the_number_of_individuals_who_have_successfully_completed_the_onboarding_process_over_the_specified_period:
        "Ce graphique visualise le nombre de personnes qui ont terminé avec succès le processus d'intégration au cours de la période spécifiée.",
      this_graph_visualises_the_number_of_suicidal_thoughts_and_behaviours_notifications_over_the_specified_period:
        'Ce graphique visualise le nombre de notifications de pensées et de comportements suicidaires sur la période spécifiée.',
      this_graph_visualises_the_number_of_summary_questionnaires_that_have_been_completed_over_the_specified_period_the_top_line_will_show_the_total_number_of_questionnaires_completed_and_the_bottom_line_will_show_the_total_number_of_unique_individuals_that_completed_those_questionnaires:
        'Ce graphique visualise le nombre de questionnaires récapitulatifs qui ont été complétés au cours de la période spécifiée. La ligne du haut indiquera le nombre total de questionnaires remplis et la ligne du bas indiquera le nombre total de personnes uniques ayant rempli ces questionnaires.',
      try_changing_the_date_range_using_the_date_selector_at_the_top_of_the_page:
        "Essayez de modifier la plage de dates à l'aide du sélecteur de date en haut de la page.",
      welcome_to_your_insights_page_we_ll_be_updating_it_regularly_for_you_with_new_features_so_if_there_are_metrics_you_d_like_to_see_or_you_have_questions_let_us_know_at:
        "Bienvenue sur votre page Insights. Nous le mettrons régulièrement à jour pour vous avec de nouvelles fonctionnalités, donc s'il y a des statistiques que vous aimeriez voir ou si vous avez des questions, faites-le nous savoir à",
    },
    dialog: {
      are_you_sure_you_want_to_discharge_them:
        'Êtes-vous sûr de vouloir les décharger ?',
      cancel: 'Annuler',
      continue_to_discharge: 'Continuer la sortie',
      has_unresolved_notifications: 'a des notifications non résolues',
      this_individual: 'Cet individu',
    },
    onboarding: {
      about_you: 'À propos de vous',
      all_answers_are_secure_and_strictly_confidential:
        'Toutes les réponses sont sécurisées et strictement confidentielles.',
      and: 'et',
      back: 'Retour',
      benefits: 'Avantages',
      by_answering_these_questions_it_helps_you_and_your_provider_better_understand_you:
        'En répondant à ces questions, cela aide vous et votre prestataire à mieux vous comprendre.',
      completed: 'Terminé',
      confirm_password: 'Confirmez le mot de passe',
      continue: 'Continuer',
      create_password: 'Créer un mot de passe',
      enter_a_password: 'Entrez un mot de passe',
      enter_a_username: "Entrez un nom d'utilisateur",
      enter_your_first_name_and_surname: 'Entrez votre prénom et nom',
      enter_your_name: 'Entrez votre nom',
      first_name_and_surname: 'Prénom et nom',
      great_job: 'Bon travail',
      i_understand_and_agree_with_the: "Je comprends et j'accepte les",
      if_you_skip_some_questions_you_can_update_them_later_in_your_profile_settings:
        'Si vous sautez certaines questions, vous pouvez les mettre à jour plus tard dans les paramètres de votre profil.',
      is_not_unique: "n'est pas unique",
      legal_name_is_required: 'Le nom légal est requis',
      loading: 'Chargement',
      next: 'Suivant',
      name_must_contain_at_least_a_first_name_and_a_surname:
        'Le nom doit contenir au moins un prénom et un nom.',
      of: 'de',
      out_of: 'de',
      password_must_be_at_least_8_characters:
        'Le mot de passe doit comporter au moins 8 caractères',
      password_must_be_at_most_20_characters:
        'Le mot de passe doit comporter au maximum 20 caractères',
      password_must_contain_a_mix_of_numbers_and_letters:
        'Le mot de passe doit contenir un mélange de chiffres et de lettres',
      password_must_contain_a_mix_of_upper_and_lower_case_letters:
        'Le mot de passe doit contenir un mélange de majuscules et de minuscules',
      password_must_match: 'Le mot de passe doit correspondre',
      password: 'Mot de passe',
      preferred_name_is_required: 'Le nom préféré est requis',
      preferred_name: 'Nom préféré',
      privacy_policy: 'politique de confidentialité',
      question: 'Question',
      remember_you_can_edit_your_information_at_any_time_from_the_profile_settings_in_your_main_menu:
        "N'oubliez pas, vous pouvez modifier vos informations à tout moment dans les paramètres de profil de votre menu principal",
      repeat_password: 'Répétez le mot de passe',
      save: 'Enregistrer',
      setup_your_innowell_profile_as_everyone_comes_with_a_unique_background:
        'Créez votre profil Innowell, car chacun a un parcours unique.',
      skip: 'Passer',
      sorry_an_error_occurred_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again:
        "Désolé, une erreur s'est produite sur notre serveur. Nous avons informé notre équipe afin qu'elle puisse y remédier. Veuillez réessayer.",
      start: 'Commencer',
      tell_us_a_bit_about_yourself: 'Parlez-nous un peu de vous',
      terms_and_conditions: 'conditions générales',
      this_is_a_short_set_of_questions_before_the_full_questionnaire_that_should_only_take_you_a_few_minutes_to_complete:
        "Il s'agit d'un court ensemble de questions avant le questionnaire complet, qui devrait prendre quelques minutes pour être complété.",
      unable_to_render_form_without_questionnaire:
        "Impossible d'afficher le formulaire sans le questionnaire",
      unknown_page: 'Page inconnue',
      username: "Nom d'utilisateur",
      what_is_your_preferred_name: 'Quel est votre nom préféré ?',
      you_can_skip_some_of_these_questions_if_you_dont_feel_comfortable_answering_them:
        "Vous pouvez sauter certaines de ces questions si vous ne vous sentez pas à l'aise d'y répondre.",
      you_must_agree_with_the_privacy_policy_and_terms_and_conditions:
        'Vous devez accepter la politique de confidentialité et les conditions générales',
      your_profile: 'Votre profil',
      your_session_has_now_timed_out_click_here_to_sign_in_again:
        'Votre session a expiré. Cliquez ici pour vous reconnecter.',
      your_session_will_timeout_soon_click_here_to_reload_and_avoid_having_to_sign_in_again:
        'Votre session expirera bientôt. Cliquez ici pour recharger et éviter de devoir vous reconnecter.',
    },
    reporting: {
      active_user: 'Utilisateur actif :',
      accessibility_and_equity: 'Accessibilité et équité',
      active_users_include_all_individuals_with_an_active_account_status_at_any_time_during_the_selected_time_period_this_includes_a_user_that_may_have_initially_been_active_and_later_switched_to_a_read_only_or_discharged_status_during_the_selected_time_period:
        "Les utilisateurs actifs incluent toutes les personnes ayant un statut de compte 'actif' à tout moment pendant la période sélectionnée. Cela inclut un utilisateur qui a pu être initialement 'actif' et qui a ensuite changé pour un statut 'lecture seule' ou 'dischargé' pendant la période sélectionnée.",
      apply: 'Appliquer',
      all: 'Tout',
      a_user_account_status_becomes_active_under_insights_if_they_have_received_a_platform_invitation_regardless_if_they_have_accepted_the_invitation_or_not_as_long_as_the_invitation_was_not_removed_and_never_reinvited:
        "Le statut du compte utilisateur devient 'actif' dans les insights s'ils ont reçu une invitation à la plateforme, qu'ils aient accepté l'invitation ou non, tant que l'invitation n'a pas été 'retirée' et qu'ils n'ont jamais été 'réinvités'.",
      average_time_to_complete_the_initial_questionnaire:
        'Temps moyen pour compléter le questionnaire initial',
      average_time_from_platform_invitation_to_initial_questionnaire_completion:
        "Temps moyen entre l'invitation de la plateforme et la complétion du questionnaire initial",
      average_time_from_platform_invitation_to_individual_working_on_a_care_option:
        "Temps moyen entre l'invitation de la plateforme et l'individu travaillant sur une option de soin",
      average_time_for_a_clinician_to_respond_to_a_notification:
        'Temps moyen pour un clinicien de répondre à une notification',
      breakdown: 'Détail',
      cancel: 'Annuler',
      change_in_results_over_time: 'Évolution des résultats au fil du temps',
      continuity_and_coordination: 'Continuité et coordination',
      conversely_if_a_user_had_a_read_only_or_discharged_status_and_was_reactivated_during_the_selected_time_period_they_would_also_be_counted_as_active:
        "Inversement, si un utilisateur avait un statut 'lecture seule' ou 'dischargé' et a été réactivé pendant la période sélectionnée, il serait également compté comme 'actif'.",
      client_insight: 'Compréhension du client',
      clinician: 'clinicien',
      clinician_breakdown: 'Répartition des cliniciens',
      clinicians_use_of_the_platform:
        'Utilisation de la plateforme par les cliniciens',
      custom_range: 'Plage personnalisée',
      dashboard_views: 'Vues du tableau de bord',
      definitions: 'Définitions',
      discharged_individuals: 'Individus sortis',
      efficiency_expenditure_and_cost: 'Efficacité, dépenses et coût',
      effectiveness_and_outcomes: 'Efficacité et résultats',
      exit_print_view: 'Quitter le mode impression',
      filter_by: 'Filtrer par',
      insights: 'Perspectives',
      individual_use_of_the_innowell_platform:
        'Utilisation individuelle de la plateforme Innowell',
      individual_care_option_satisfaction:
        "Satisfaction concernant l'option de soin individuelle",
      innowell_platform_email_invitation:
        'Invitation par email à la plateforme Innowell :',
      initial_questionnaire_completions: 'Complétions du questionnaire initial',
      initial_distribution_of_results: 'Distribution initiale des résultats',
      last_30_days: 'Derniers 30 jours',
      last_7_days: 'Derniers 7 jours',
      last_3_days: 'Derniers 3 jours',
      metrics_are_an_important_starting_point_for_discussion_around_a_services_strengths_and_weaknesses_these_metrics_document_the_performance_of_your_service_after_implementing_the_innowell_platform_into_its_pathways_as_usual_practice_when_sample_sizes_are_small_descriptive_statistics_may_be_incorrect_care_should_be_taken_with_interpretation_and_extrapolation:
        'Les métriques constituent un point de départ important pour discuter des forces et des faiblesses d’un service. Ces métriques documentent les performances de votre service après l’intégration de la plateforme Innowell dans ses parcours comme pratique habituelle. Lorsque les tailles d’échantillons sont petites, les statistiques descriptives peuvent être incorrectes. Il convient d’être prudent dans l’interprétation et l’extrapolation.',
      notifications_for_suicide_thoughts_and_behaviour:
        'Notifications pour pensées et comportements suicidaires',
      number_of_service_notifications_upon_first_questionnaire_completion:
        'Nombre de notifications de service après la complétion du premier questionnaire',
      number_of_service_notifications_over_the_time_period_filtered:
        'Nombre de notifications de service sur la période filtrée',
      safety: 'Sécurité',
      satisfaction_and_acceptability: 'Satisfaction et acceptabilité',
      service_insight: 'Compréhension du service',
      service: 'service fr',
      selected: 'sélectionné',
      since_inception: 'Depuis le début',
      time_period: 'Période :',
      the_time_of_innowell_platform_email_invitation_refers_to_the_latest_time_between_the_time_of_first_invitation_sent_and_the_time_of_invitation_that_was_accepted_by_the_user:
        "L'heure de l'invitation par email à la plateforme Innowell fait référence à la dernière heure entre l'heure de la première invitation envoyée et l'heure de l'invitation qui a été acceptée par l'utilisateur.",
      total_individuals: 'Total des individus',
      update: 'Mettre à jour',
      unless_otherwise_stated_all_metrics_reference_activities_or_times_recorded_during_the_selected_time_period_for_example_the_total_number_of_individuals_who_responded_to_a_questionnaire_would_only_display_the_individuals_who_responded_to_the_questionnaire_during_that_selected_time_period:
        "Sauf indication contraire, toutes les métriques font référence aux activités ou aux périodes enregistrées pendant la période sélectionnée. Par exemple, le nombre total d'individus ayant répondu à un questionnaire afficherait uniquement les individus ayant répondu au questionnaire pendant cette période sélectionnée.",
      use_of_the_innowell_platform: 'Utilisation de la plateforme Innowell',
      view_all_for_printing: 'Voir tout pour impression',
      working: 'En cours',
      workforce: "Main-d'œuvre",
    },
    scoreCard: {
      activity_result: 'Résultat de activité',
      are: 'sont',
      clinician: 'clinicien',
      get_started: 'Commencer',
      have: 'avoir',
      has: 'a',
      in_bed: 'Au lit',
      in_progress: 'En cours',
      is: 'est',
      link: 'Lien',
      my_result: 'Mon résultat',
      no_results_for_this_time_period: 'Aucun résultat pour cette période',
      requested: 'Demandé',
      requested_support: "demandé de l'aide",
      request_this: 'Demander ceci',
      sleep_result: 'Résultat du sommeil',
      started: 'commencé',
      steps_over_goal: 'Pas au dessus de lobjectif',
      support: 'Soutien',
      supporting: 'Justificatif',
      their: 'leur',
      this_care_option_requires_your_clinicians_support:
        'Cette option de soins nécessite le soutien de votre clinicien',
      total_steps: 'Nombre total détapes',
      unnamed_user: 'Utilisateur anonyme',
      visit: 'Visite',
      waiting_for: 'En attendant',
      you: 'toi',
      your: 'ton',

      currentResultCardForumulaPage: {
        ago: 'il y a',
        currentResultHeading: 'Résultat actuel:',
        defaultDescription:
          'Pour connaître votre score, remplissez simplement le questionnaire.',
        lastUpdated: 'Dernière mise à jour:',
        unanswered: 'Sans réponse',
      },
      currentResultCardPage: {
        moreInformation: "Plus d'informations sur",
        retakeQuestionnaire: 'Questionnaire de rattrapage',
      },
      scoreCardsCareOptionsPage: {
        all: 'Tout',
        careOptionsDescription:
          'Choisissez parmi les options d’autosoins ou demandez une option auprès d’un clinicien.',
        careOptionsHeading: 'Options de soins',
        selfCare: 'Soins auto-administrés',
        workWithClinician: 'Travailler avec le clinicien',
      },
      scoreCardsChangeLogPage: {
        asOf: 'Dès',
        wasUpdated: 'a été mis à jour.',
        learnMore: 'Apprendre encore plus.',
      },
      scoreCardsNotificationsPage: {
        flashMessage: 'Merci, votre contribution a été enregistrée.',
        linkText: 'notification',
        registeredIndividual: 'Cette personne a enregistré un',
        selectCareOption:
          'Veuillez sélectionner une option de soins, puis soumettre',
        submitButton: 'Soumettre',
        takeAction: "S'il vous plaît, agissez.",
      },
      scoreCardsPreviousAnswersPage: {
        answeredBy: 'Répondu par',
        answeredOn: 'Répondu le',
        completed: 'Complété',
        notSubmitted: 'Non soumis',
        previousAnswersHeading: 'Réponses précédentes',
        questionSet: 'Ensemble de questions',
        rating: 'Notation',
        results: 'Résultats',
        seeAll: 'Voir tout',
        skipped: 'Sauté',
        status: 'Statut',
      },
    },
    shared: {
      a: 'a',
      a_new_version_of_the_app_has_been_released:
        "Une nouvelle version de l'application a été publiée.",
      about_innowell: 'À propos Innowell',
      acknowledgements: 'Remerciements',
      actions: 'Actions',
      active_admin: 'Admin Actif',
      add_another_person: 'Ajouter une autre personne',
      add_note: 'Ajouter une note',
      add_support_person: 'Ajouter une personne de soutien',
      add_your_support_persons_email:
        "Ajouter l'adresse électronique de votre personne de soutien",
      add_your_support_persons_name:
        'Ajouter le nom de votre personne de soutien',
      added: 'Ajouté',
      age_gender: 'Âge/Sexe',
      ago: 'il y a',
      and: 'et',
      another: 'Autre',
      answer: 'Réponse',
      are_you_sure_you_would_like_to_disconnect:
        'Êtes-vous sûr de vouloir vous déconnecter ?',
      assessments: 'Évaluations',
      back: 'Retour',
      back_to_dashboard: 'Retour au tableau de bord',
      back_to_home: "Retour à l'accueil",
      back_to_individual_list: 'Retour à la liste des individus',
      beta: 'BETA',
      benefits_of_connecting_fitbit_to_the_innowell_platform:
        'Avantages de la connexion de Fitbit à la plateforme Innowell',
      benefits_of_innowell: "Avantages d'Innowell",
      call: 'Appeler',
      cancel: 'Annuler',
      changing_roles: 'Changement de rôles',
      changing_tenant: 'Changer de locataire',
      chat: 'Chat',
      check_out_the_new: 'Découvrez le nouveau',
      copyright_2017: 'Droit auteur 2017',
      click_here: 'Cliquez ici',
      clinicians: 'Cliniciens',
      complete_the_questionnaire_in_one_sitting_preferred_or_come_back_over_time_if_necessary:
        'Remplissez le questionnaire en une seule séance, de préférence, ou revenez-y au fil du temps si nécessaire.',
      confirm_new_password: 'Confirmer le nouveau mot de passe',
      connect_your_fitbit: 'Connectez votre Fitbit',
      connected_organisations_and_access: 'Organisations connectées et accès',
      continue: 'Continuer',
      contact_us: 'Contactez-nous',
      contributing_your_deidentified_data_is_completely_voluntary_and_you_can_change_your_mind_at_any_time_furthermore_your_decision_whether_or_not_to_have_your_deidentified_data_used_for_research_purposes_will_not_affect_your_standard_clinical_care_or_your_current_or_future_relationship_with_your_service_provider_or_health_professional_or_innowell:
        "Votre contribution de données anonymisées est entièrement volontaire et vous pouvez changer d'avis à tout moment. De plus, votre décision d'autoriser ou non l'utilisation de vos données anonymisées à des fins de recherche n'affectera pas vos soins cliniques standard, ni vos relations actuelles ou futures avec votre fournisseur de services, votre professionnel de la santé ou Innowell.",
      copied_to_clipboard: 'Copié dans le presse-papiers.',
      copy_failed: 'Échec de la copie.',
      create_note: 'Créer une note',
      dashboard: 'Tableau de bord',
      data_policy: 'politique de données',
      date_of_birth: 'Date de naissance',
      date_time: 'Date / Heure',
      different_perspectives_can_help_add_more_information_to_your_health_profile_they_will_receive_an_email_invite_to_answer_a_questionnaire_about_your_health_and_well_being:
        'Différentes perspectives peuvent aider à ajouter plus dinformations à votre profil de santé. Ils recevront une invitation par e-mail pour répondre à un questionnaire sur leur santé et leur bien-être.',
      disconnect: 'Déconnecter',
      disconnect_your_fitbit: 'Déconnectez votre Fitbit',
      dismiss: 'Ignorer',
      each_health_area_has_associated_activities_called_care_options_that_provide_tools_for_improvement:
        "Chaque domaine de santé a des activités associées, appelées options de soins, qui fournissent des outils d'amélioration.",
      email: 'courriel',
      email_address: 'Adresse électronique',
      employees: 'Employés',
      enter_new_password: 'Entrez le nouveau mot de passe',
      enter_note: 'Entrez une note',
      error: 'Erreur',
      error_code_errorcode: "Code d'erreur : {{errorCode}}",
      faqs: 'FAQ',
      filter: 'Filtrer',
      for_more_information_about_how_innowell_uses_data_please_see:
        "Pour plus d'informations sur la façon dont Innowell utilise les données, veuillez consulter",
      for_more_information_on_how_your_data_will_be_used:
        "pour plus d'informations sur la façon dont vos données seront utilisées.",
      get_realtime_updates_via_your_dashboard:
        'Obtenez des mises à jour en temps réel via votre tableau de bord',
      get_support: 'Obtenir du soutien',
      give_input: 'Donner un avis',
      has_been_removed: 'A été supprimé',
      health_card: 'Carte de santé',
      health_history: 'Historique de santé',
      health_snapshot: 'Aperçu de la santé',
      healthy: 'Sain',
      help_requested: 'Aide demandée',
      history_of_requests: 'Historique des demandes',
      i_need_help_now: "J'ai besoin d'aide maintenant",
      if_you_remove_a_support_person_they_wont_be_notified:
        'Si vous supprimez une personne de soutien, elle ne sera pas notifiée.',
      immediate_access_to_your_results_and_a_holistic_overview_of_your_health_and_wellbeing:
        'Accès immédiat à vos résultats et à une vue densemble holistique de votre santé et de votre bien-être.',
      important_notes: 'Notes importantes',
      individual_info: 'Informations individuelles',
      individuals: 'Individus',
      initial_questionnaire: 'Questionnaire initial',
      initial_score: 'SCORE INITIAL',
      invalid_relationship_type: 'Type de relation invalide',
      invite: 'Inviter',
      invite_sent_to: 'INVITATION ENVOYÉE À :',
      invite_support_person: 'Inviter une personne de soutien',
      last_active: 'Dernière activité',
      last_completed: 'Dernière complétion',
      last_modified_june_2022: 'Dernière modification : juin 2022',
      last_requested: 'Dernière demande',
      lets_go: 'Allons-y',
      load_more: 'Charger plus',
      managers: 'Gestionnaires',
      medical_director_id: 'ID du directeur médical',
      more_info: 'Plus dinfos',
      move_time_range_back: 'Déplacer la plage de temps en arrière',
      move_time_range_forward: 'Déplacer la plage de temps en avant',
      name: 'Nom',
      need_help: 'Besoin daide',
      need_help_now: 'Besoin daide maintenant ?',
      new: 'Nouveau !',
      new_password: 'Nouveau mot de passe',
      no_clinician_notes: 'Aucune note de clinicien',
      no_not_interested: 'Non, pas intéressé',
      no_recent_activity: 'Aucune activité récente',
      no_results_found: 'Aucun résultat trouvé',
      not_submitted: 'Non soumis',
      notes: 'Notes',
      notifications: 'Notifications fr',
      notifications_history: 'Historique des notifications',
      on_this_page_you_can_see_the_organisations_and_clinicians_that_you_are_working_with:
        'Sur cette page, vous pouvez voir les organisations et les cliniciens avec lesquels vous travaillez',
      oops: 'Oups !',
      organisations: 'Organisations',
      please: 'Sil vous plaît',
      please_contact_your_service_to_change_these_settings:
        'Veuillez contacter votre service pour modifier ces paramètres.',
      please_ensure_you_take_appropriate_steps_to_protect_the_privacy_of_this_document_in_accordance_with_the_clinical_governance_requirements_of_your_service:
        'Veuillez prendre les mesures appropriées pour protéger la confidentialité de ce document conformément aux exigences de gouvernance clinique de votre service.',
      preferred_name: 'Nom préféré',
      preview_notification: 'Aperçu de la notification',
      privacy_policy: 'politique de confidentialité',
      profile: 'Profil',
      profile_settings: 'Paramètres du profil',
      question: 'Question',
      recent_activity: 'Activité récente',
      reinvite_support: 'Réinviter le soutien',
      relationship: 'Relation',
      remove: 'Retirer',
      removed: 'Retiré',
      repeat_password: 'Répéter le mot de passe',
      report: 'Signaler',
      request_assessment_button: "bouton de demande d'évaluation",
      requested_assessments: 'Évaluations demandées',
      research_and_data_sharing: 'Recherche et partage de données',
      research_data_sharing: 'Recherche et partage de données',
      researchdatasharingpage: 'RechercheDataSharingPage',
      results_download_security_warning:
        'Téléchargement des résultats - avertissement de sécurité',
      results_of_the_questionnaire_are_shared_with_your_clinician_to_help_support_your_mental_health_care:
        'Les résultats du questionnaire sont partagés avec votre clinicien pour vous aider à soutenir votre santé mentale.',
      resources: 'Ressources',
      save: 'Enregistrer',
      see_more_details: 'Voir plus de détails',
      sent_to: 'ENVOYÉ À',
      sign_out: 'Se déconnecter',
      something_went_wrong_please_try_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email:
        "Quelque chose a mal tourné. Veuillez réessayer plus tard. Si vous avez besoin d'aide immédiate, veuillez contacter votre service ou envoyez-nous un e-mail de support.",
      somethings_gone_wrong_but_its_likely_temporary_please_wait_a_few_moments_and_try_refreshing_the_page_or_come_back_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email:
        "Quelque chose s'est mal passé, mais c'est probablement temporaire. Veuillez patienter quelques instants et réessayer de rafraîchir la page ou revenir plus tard. Si vous avez besoin d'aide immédiate, veuillez contacter votre service ou envoyez-nous un e-mail de support.",
      sorry_this_page_is_taking_way_too_long_to_load_please_wait_a_few_moments_and_try_refreshing_the_page_or_come_back_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email:
        "Désolé, cette page met trop de temps à charger. Veuillez patienter quelques instants et réessayer de rafraîchir la page ou revenir plus tard. Si vous avez besoin d'aide immédiate, veuillez contacter votre service ou envoyez-nous un e-mail de support.",
      start: 'Démarrer',
      starting_questionnaire: 'Questionnaire de départ',
      submitted_at: 'Soumis le',
      support_person: 'Personne de soutien',
      support_persons_email_is_required:
        "L'e-mail de la personne de soutien est requis",
      support_persons_email_must_be_a_valid_email:
        "L'e-mail de la personne de soutien doit être valide",
      support_persons_name_is_required:
        'Le nom de la personne de soutien est requis',
      support_request: 'DEMANDE DE SOUTIEN',
      switch_back: 'Revenir en arrière',
      switch_individual: "Changer d'individu",
      switch_to: 'Passer à',
      syncing_your_fitbit_with_the_platform_allows_you_to_view_your_health_and_wellbeing_information_in_one_place:
        'Synchroniser votre Fitbit avec la plateforme vous permet de visualiser vos informations sur la santé et le bien-être en un seul endroit.',
      terms_and_conditions: 'Conditions générales',
      thank_you_for_sharing_your_deidentified_data_with_innowell:
        "Merci d'avoir partagé vos données anonymisées avec Innowell.",
      thank_you_your_selection_was_recorded:
        'Merci, votre sélection a été enregistrée.',
      the_innowell_platform_is_not_a_diagnostic_tool:
        "La plateforme Innowell n'est pas un outil de diagnostic.",
      they_will_not_be_able_to_view_any_of_your_health_information:
        'Ils ne pourront pas voir aucune de vos informations de santé.',
      they_will_not_be_able_to_view_any_of_your_health_information_if_you_remove_a_support_person_they_wont_be_notified:
        'Ils ne pourront pas voir aucune de vos informations de santé. Si vous supprimez une personne de soutien, elle ne sera pas notifiée.',
      they_will_not_be_notified_of_being_removed:
        'Ils ne seront pas informés de leur suppression.',
      to_manage_access_to_your_data_please_contact_the_relevant_service:
        "Pour gérer l'accès à vos données, veuillez contacter le service concerné",
      to_old_summary_dashboard: "vers l'ancien tableau de bord récapitulatif.",
      to_reload_and_avoid_errors: 'pour recharger et éviter les erreurs.',
      track_your_activity_and_sleep_progress:
        'Suivez votre activité et votre progression de sommeil.',
      trend_graphs: 'graphiques de tendance',
      unanswered: 'Non répondu',
      unauthorised_access: 'Accès non autorisé',
      view_health_history: "Voir l'historique de santé",
      view_profile: 'Voir le profil',
      view_your_fitbit_data_in_the_context_of_your_health_and_wellbeing:
        'Visualisez vos données Fitbit dans le contexte de votre santé et de votre bien-être',
      we_cant_find_this_page: 'Nous ne pouvons pas trouver cette page',
      we_know_there_is_an_important_link_between_fitness_exercise_sleep_and_mental_health_this_is_the_reason_why_innowell_has_linked_with_fitbit:
        "Nous savons qu'il existe un lien important entre la forme physique, l'exercice, le sommeil et la santé mentale. C'est la raison pour laquelle Innowell s'est associé à Fitbit.",
      website: 'Site web',
      yes_i_want_to_connect: 'Oui, je veux me connecter',
      you_are_in_read_only_mode: 'Vous êtes en mode lecture seule.',
      you_are_not_authorised_to_access_this_page_for_access_please_contact_your_supervisor_or_systems_admin:
        "Vous n'êtes pas autorisé à accéder à cette page. Pour accéder, veuillez contacter votre superviseur ou votre administrateur système.",
      you_can_always_disconnect_reconnect_at_any_time_here_is_more_information_on_innowells:
        "Vous pouvez toujours vous déconnecter/re-connecter à tout moment. Voici plus d'informations sur Innowell",
      your_information_is_secure_and_strictly_confidential:
        'Vos informations sont sécurisées et strictement confidentielles.',
      your_personal_information_is_deidentified_and_no_one_can_link_your_name_email_address_or_date_of_birth_to_your_health_information:
        'Vos informations personnelles sont anonymisées et personne ne peut lier votre nom, votre adresse électronique ou votre date de naissance à vos informations de santé.',
      your_personal_information_will_be_always_stored_in_the_innowell_platform_and_only_your_deidentified_data_will_be_used_for_research_purposes_the_deidentified_data_will_be_extracted_used_for_statistical_analysis_and_to_improve_the_overall_effectiveness_of_the_innowell_platform:
        "Vos informations personnelles seront toujours stockées sur la plateforme Innowell et seules vos données anonymisées seront utilisées à des fins de recherche. Les données anonymisées seront extraites, utilisées pour des analyses statistiques et pour améliorer l'efficacité globale de la plateforme Innowell.",
      your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_this_information_is_used_to_improve_the_delivery_of_person_centred_care_contributing_your_deidentified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care_read_our:
        "Votre service participe aux programmes de recherche suivants pour développer une compréhension plus approfondie des besoins des gens. Ces informations sont utilisées pour améliorer la prestation de soins centrés sur la personne. La contribution de vos données anonymisées est entièrement volontaire et n'affectera pas vos soins cliniques. Lisez notre",
      your_session_has_expired_please_sign_in_again_to_continue:
        'Votre session a expiré. Veuillez vous connecter à nouveau pour continuer.',
      your_support_person: 'Votre personne de soutien',
      version: 'Version fr',
      resource_library: 'Bibliothèque de ressources',
      escalation_emails: "E-mails d'escalade",
      contact_email: 'E-mail de contact',
      contact_phone_number: 'Numéro de téléphone du contact',
      welcome: 'Bienvenu',
      welcome_back: 'Content de te revoir',
      insights: 'Connaissances',
      view_insights: 'Afficher les informations',
      client_management: 'Gestion des clients',
      invite_individuals: 'Inviter des individus',
      staff_management: 'Gestion du personnel',
    },
    staff: {
      actioned_by: 'Actionné par',
      ago: 'il y a',
      all: 'Tout',
      an_invitation_to: 'une invitation à',
      and: 'et',
      are_you_sure_you_want_to_continue: 'Êtes-vous sûr de vouloir continuer?',
      assign_clinician: 'Assigner les clinicien',
      assign_clinicians: 'Assigner les cliniciens',
      assign_individuals: 'Assigner les individus',
      assign: 'Assigner',
      assign_no_one: '-- Ne pas assigner --',
      assigned_please_allow_around_5_minutes_for_assignments_to_complete:
        'assigné. Veuillez compter environ 5 minutes pour que les assignations se terminent.',
      assigned: 'Assigné',
      care_option_actioned: 'Option de soin actionnée',
      clinician: 'clinicien',
      clinicians: 'cliniciens',
      continue: 'Continuer',
      copied_to_clipboard: 'Copié dans le presse-papiers.',
      copy_failed: 'Échec de la copie.',
      could_not_be_assigned: "n'a pas pu être assigné",
      date_of_birth: 'Date de naissance',
      date: 'Date',
      discharge: 'Décharger',
      done: 'Terminé',
      email: 'e-mail',
      email_address: 'Adresse e-mail',
      email_must_be_a_valid_email: "L'e-mail doit être valide",
      employee_name: "Nom de l'employé",
      employee: 'employé',
      employees: 'employés',
      failed: 'échoué',
      faqs: 'FAQ',
      following_invitations_may_have_failed_because_one_or_more_clinicians_may_not_yet_be_active:
        'Les invitations suivantes ont pu échouer car un ou plusieurs cliniciens peuvent ne pas être encore actifs',
      following_invitations_may_have_failed_because_they_are_currently_active_in_another_tenant:
        'Les invitations suivantes ont pu échouer car elles sont actuellement actives dans un autre tenant.',
      for: 'pour',
      from: 'de',
      health_card: 'Carte de santé',
      help_requested: 'Aide demandée',
      including_those_with_unresolved_notifications:
        'y compris ceux avec des notifications non résolues.',
      individual: 'individu',
      individuals: 'individus',
      individuals_will_receive_an_sms_from_innowell_reminding_them_to_complete_their_onboarding_individuals_can_update_or_remove_their_mobile_number_on_their_profile_page_via_the_profile_settings_menu_at_any_time:
        'Les individus recevront un SMS d’Innowell leur rappelant de terminer leur intégration. Ils peuvent mettre à jour ou supprimer leur numéro de mobile sur leur page de profil via le menu des paramètres du profil à tout moment.',
      initial_questionnaire: 'Questionnaire initial',
      invite: 'Inviter',
      invite_invite_type: 'Inviter {{invite_type}}',
      invitation: 'invitation',
      invitations_sent_please_allow_around_5_minutes_for_reinvites_to_reach_individuals:
        'Invitations envoyées. Veuillez compter environ 5 minutes pour que les réinvitations atteignent les individus.',
      invitations_sent: 'Invitations envoyées',
      invitations_to: 'invitations à',
      invitations: 'invitations',
      invite_inidividual: 'Inviter un Individu',
      last_active: 'Dernière activité',
      loading: 'Chargement',
      manager: 'Directeur',
      more: 'plus...',
      mobile_phone: 'Téléphone portable',
      no_common_actions_ensure_selected_rows_have_a_common_role:
        'Aucune action commune. Assurez-vous que les lignes sélectionnées ont un rôle commun.',
      no: 'Non',
      or_role_type_cannot_be_of_a_global_tenant:
        ' ou {{role_type}} ne peut pas être d’un locataire global',
      patient_name: 'Nom du patient',
      please_note_all_individuals_will_be_discharged:
        'Veuillez noter que tous les individus seront déchargés',
      preferred_name: 'Nom préféré',
      primary_clinician: 'Clinicien principal',
      reason: 'Raison',
      reinvite: 'Réinviter',
      reinvites_sent_please_allow_around_5_minutes_for_reinvites_to_reach_individuals:
        'Réinvitations envoyées. Veuillez compter environ 5 minutes pour que les réinvitations atteignent les individus.',
      reinvites_sent: 'Réinvitations envoyées',
      remove_role: 'Supprimer le rôle',
      remove: 'Supprimer',
      request_assessments: 'Demander des évaluations',
      resend_invitation: "Renvoyer l'invitation",
      resend_invitations: 'Renvoyer les invitations',
      resend_role_invitations: 'Renvoyer les invitations de rôle',
      resend: 'Renvoyer',
      role: 'rôle',
      roles: 'Rôle(s)',
      role_type: 'Type de Rôle',
      search_for_questionnaire: 'Rechercher un questionnaire...',
      select_questionnaires_to_request:
        'Sélectionner les questionnaires à demander',
      send_requests: 'Envoyer la demande',
      sent: 'envoyé',
      service: 'Service Fr',
      service_group: 'Groupe de Services',
      status: 'Statut',
      to_the_selected_clinicians: 'aux cliniciens sélectionnés',
      unassigned: 'Non attribué',
      yes_assign: 'Oui, assigner',
      yes_discharge: 'Oui, décharger',
      yes_reinvite: 'Oui, réinviter',
      yes_remove_role: 'Oui, supprimer le rôle',
      yes_remove: 'Oui, supprimer',
      yes_resend_invitations: 'Oui, renvoyer les invitations',
      yes_resend: 'Oui, renvoyer',
      you_are_about_to_assign: "Vous êtes sur le point d'assigner",
      you_are_about_to_discharge: 'Vous êtes sur le point de décharger',
      you_are_about_to_reinvite: 'Vous êtes sur le point de réinviter',
      you_are_about_to_remove: 'Vous êtes sur le point de supprimer',
      you_are_about_to_resend: 'Vous êtes sur le point de renvoyer',
    },
    supporting: {
      age_range: "Tranche d'âge",
      alberta_health_services: 'Services de santé Alberta',
      call: 'Appeler',
      chat: 'Discussion',
      chats_for_life: 'Discussions pour la vie',
      checkin: 'Enregistrement',
      close: 'Fermer',
      description: 'Description',
      for: 'pour',
      hours_a_day_7_days_a_week: '24 heures sur 24, 7 jours sur 7',
      how_often_should_you_answer_this_questionnaire:
        'À quelle fréquence devriez-vous répondre à ce questionnaire ?',
      if_you_or_someone_you_know_needs_immediate_help_call:
        "Si vous ou quelqu'un que vous connaissez a besoin d'aide immédiate, appelez",
      important_information: 'Informations importantes',
      kids_help_phone: "Jeunesse, J'écoute",
      need_help_now: "Besoin d'aide maintenant ?",
      now: 'maintenant.',
      or_call_5196466000_ext_65196: ', ou appelez le 519-646-6000, poste 65196',
      or: 'ou',
      phone_hours: "Heures d'appel",
      ready_to_submit: 'Prêt à soumettre',
      remove_yourself_as_support_person:
        'Retirez-vous en tant que personne de soutien',
      remove: 'Supprimer',
      start_questionnaire: 'Commencer le questionnaire',
      submit: 'Soumettre',
      submitted: 'Soumis',
      submitting: 'Soumission en cours...',
      summary_questionnaire: 'Questionnaire récapitulatif',
      talk_to_someone: "Parlez à quelqu'un",
      text: 'Texte',
      thank_you_for_successfully_completing_this_questionnaire:
        "Merci d'avoir terminé ce questionnaire avec succès.",
      theres_no_right_or_wrong_way_to_go_about_supporting_someone_often_the_first_step_is_having_an_open_conversation_while_this_can_be_difficult_to_do_check_out_th:
        "Il n'y a pas de bonne ou mauvaise façon de soutenir quelqu'un, souvent la première étape consiste à avoir une conversation ouverte. Bien que cela puisse être difficile à faire, consultez le site",
      this_questionnaire_will_help_provide_an_additional_perspective_on_the_health_and_well_being_of_the_person_you_are_supporting:
        'Ce questionnaire vous aidera à fournir une perspective supplémentaire sur la santé et le bien-être de la personne que vous soutenez.',
      website_to_help_you_plan_better_for_these_conversations:
        'site web pour mieux planifier ces conversations.',
      website: 'site web',
      welcome_name_and_thank_you_for_your_time_today:
        "Bienvenue {{name}} et merci pour votre temps aujourd'hui !",
      what_else_can_i_do_now: "Que puis-je faire d'autre maintenant ?",
      you_are_not_supporting_anyone: 'Vous ne soutenez personne',
      you_can_reanswer_the_questionnaire_at_anytime_by_logging_into_the_innowell_platform_the_person_you_are_caring_for_can_also_send_an_email_request_for_you_to_update_the_questionnaire:
        'Vous pouvez répondre à nouveau au questionnaire à tout moment en vous connectant à la plateforme Innowell. La personne dont vous vous occupez peut également envoyer une demande par e-mail pour que vous mettiez à jour le questionnaire.',
      your_answers_will_only_be_seen_by_the_person_you_are_supporting_and_their_connected_clinicians:
        'Vos réponses ne seront vues que par la personne que vous soutenez et ses cliniciens associés.',
      youve_been_requested_to_answer_the_following_questionnaires_for:
        'Vous êtes invité à répondre aux questionnaires suivants pour...',
    },
    settings: {
      add_your_mobile_number: 'Ajouter votre numéro de téléphone mobile',
      back: 'Retour',
      clinicians_and_healthcare_providers:
        'Cliniciens et prestataires de soins de santé',
      contact_us: 'Contactez-nous',
      eg_0426664444: 'ex. 0426664444',
      email_address: 'Adresse e-mail',
      email_change_requested_awaiting_confirmation:
        "Changement d'email demandé, en attente de confirmation.",
      email: 'E-mail',
      for_further_details: 'pour plus de détails.',
      health_history: 'Historique médical',
      innowell_will_never_share_your_mobile_number_if_you_want_more_information_on_your_data_please_see_our:
        "Innowell ne partagera jamais votre numéro de téléphone mobile. Si vous souhaitez plus d'informations sur vos données, veuillez consulter notre",
      legal_name_must_contain_at_least_a_first_name_and_a_family_name:
        'Le nom légal doit contenir au moins un prénom et un nom de famille.',
      legal_name: 'Nom légal',
      manage_your_settings: 'Gérer vos paramètres',
      mobile_number_is_10_digits_and_starts_with_04_eg_0426664444:
        'Le numéro de téléphone mobile comporte 10 chiffres et commence par 04, par exemple 0426664444',
      mobile_number: 'Numéro de téléphone mobile',
      notifications_settings: 'Paramètres des notifications',
      on_this_page_you_can_manage_your_information_including_your_contact_details:
        'Sur cette page, vous pouvez gérer vos informations, y compris vos coordonnées.',
      or_call_519_646_6000_ext_65196: 'ou appelez le 519-646-6000, poste 65196',
      password: 'Mot de passe',
      please_refer_to_the: 'Veuillez vous référer à la',
      preferred_language: 'Langue préférée',
      preferred_name: 'Nom préféré',
      privacy_policy: 'Politique de confidentialité',
      profile_settings: 'Paramètres du profil',
      profile: 'Profil',
      research_and_data_sharing: 'Recherche et partage de données',
      sms_notifications: 'Notifications SMS',
      sorry_this_username_has_already_been_taken:
        "Désolé, ce nom d'utilisateur est déjà pris",
      to_be_removed_from_the_innowell_platform_email_your_request_to:
        'Pour être retiré de la plateforme Innowell, envoyez votre demande à :',
      update_email: "Mettre à jour l'email",
      update_name: 'Mettre à jour le nom',
      update_phone_number: 'Mettre à jour le numéro de téléphone',
      update_preferred_language: 'Mettre à jour la langue préférée',
      update_username: "Mettre à jour le nom d'utilisateur",
      update: 'Mettre à jour',
      username: "Nom d'utilisateur",
      what_is_your_email: 'Quel est votre email ?',
      what_is_your_legal_name: 'Quel est votre nom légal ?',
      what_is_your_preferred_language: 'Quelle est votre langue préférée ?',
      what_is_your_preferred_name: 'Quel est votre nom préféré ?',
      what_is_your_username: "Quel est votre nom d'utilisateur ?",
      your_mobile_number_will_be_used_to_send_you_reminders_and_notifications_you_can_turn_notifications_on_or_off_at_any_time_on_your_notifications_page:
        'Votre numéro de téléphone mobile sera utilisé pour vous envoyer des rappels et des notifications. Vous pouvez activer ou désactiver les notifications à tout moment sur votre page `notifications`.',
      your_mobile_number_will_be_used_to_send_you_reminders_and_notifications_you_can_turn_sms_notifications_on_or_off_anytime:
        'Votre numéro de téléphone mobile sera utilisé pour vous envoyer des rappels et des notifications. Vous pouvez activer ou désactiver les notifications SMS à tout moment.',
    },
    validations: {
      field_is_required: '{{field}} est requis',
      mobile_number_must_follow_04:
        'Le numéro de mobile doit commencer par 04********',
      or_role_type_cannot_be_of_a_global_tenant:
        'ou {{role_type}} ne peut pas être un locataire global',
      phone_number_has_already_been_taken:
        'Le numéro de téléphone est déjà pris',
      phone_validation_error: 'Erreur de validation du téléphone',
      service_group_is_required_for_the_value_role_type:
        'Un groupe de services est requis pour le type de rôle {{value}}',
      this_individual_email_address_cannot_be_used_for_a_staff_role_for_support_on_how_to_invite_an_individual_to_a_staff_role_see_the:
        "Cette adresse e-mail d'individu ne peut pas être utilisée pour un rôle de personnel. Pour obtenir de l'aide sur la manière d'inviter un individu à un rôle de personnel, consultez le",
      this_individual_email_address_is_active_in_another_service_and_cannot_be_invited:
        "Cette adresse e-mail d'individu est active dans un autre service et ne peut pas être invitée",
      this_individual_email_address_is_already_active_and_cannot_be_invited:
        "Cette adresse e-mail d'individu est déjà active et ne peut pas être invitée",
      this_owner_email_address_cannot_be_used_for_a_manager_role_for_support_on_how_to_invite_an_owner_to_a_manager_role_see_the:
        "Cette adresse e-mail de propriétaire ne peut pas être utilisée pour un rôle de gestionnaire. Pour obtenir de l'aide sur la manière d'inviter un propriétaire à un rôle de gestionnaire, consultez le",
      this_staff_member_email_address_cannot_be_used_for_an_individual_role_for_support_on_how_to_invite_a_staff_member_to_an_individual_role_see_the:
        "Cette adresse e-mail de membre du personnel ne peut pas être utilisée pour un rôle individuel. Pour obtenir de l'aide sur la manière d'inviter un membre du personnel à un rôle individuel, consultez le",
      this_support_person_email_address_cannot_be_used_for_a_staff_role_for_support_on_how_to_invite_a_support_person_to_a_staff_role_see_the:
        "Cette adresse e-mail de personne de soutien ne peut pas être utilisée pour un rôle de personnel. Pour obtenir de l'aide sur la manière d'inviter une personne de soutien à un rôle de personnel, consultez le",
    },
  },
}

export default resources
