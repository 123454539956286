// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

import ArrowRight from 'react-ui/assets/icons/triangular-arrow-right.svg'
import Icon from 'react-ui/components/Icon'

type PropsType = {
  children: Node,
  header: string,
  onClick?: () => void,
}

const containerStyle = () => ({
  display: 'flex',
  flexWrap: 'wrap',
  position: 'relative',
  padding: '9px 20px',
  cursor: 'pointer',
  height: '25%',
  flexShrink: 0,
})

const headerStyle = () => ({
  display: 'flex',
  alignItems: 'flex-end',
  margin: 0,
  fontSize: '1rem',
  width: '85%',
})

const contentStyle = () => ({
  margin: 0,
  fontSize: '10px',
  width: '85%',
})

const iconStyle = () => ({
  position: 'absolute',
  top: '50%',
  right: '20px',
  transform: 'translateY(-50%)',
})

const IndividualTasksItem = (props: PropsType) => {
  const { header, children, onClick } = props

  const { css } = useFela()

  return (
    <div
      tabIndex={0}
      role="button"
      className={css(containerStyle)}
      onClick={onClick}
      onKeyPress={onClick}
      aria-label={header}
    >
      <p className={css(headerStyle)}>{header}</p>
      <p className={css(contentStyle)}>{children}</p>
      <Icon as={ArrowRight} extend={iconStyle()} scale={0.4} />
    </div>
  )
}

export default IndividualTasksItem
