// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query ActivityLogCardContainerQuery($id: ID!, $count: Int!, $cursor: String) {
    node(id: $id) {
      ... on User {
        ...ActivityLogCardContainer_user
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

export const paginationFragment = graphql`
  fragment ActivityLogCardContainer_user on User
    @relay(mask: false)
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "ActivityLogCardContainerPaginationQuery") {
    id
    linked_individual {
      activity_logs(first: $count, after: $cursor)
        @connection(key: "ActivityLogCardContainer_activity_logs") {
        edges {
          node {
            ...ActivityLogCard_activityLog
          }
        }
      }
    }
  }
`
