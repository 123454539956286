/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPreviousAnswerSet_user_trackable$ref: FragmentReference;
declare export opaque type ScoreCardsPreviousAnswerSet_user_trackable$fragmentType: ScoreCardsPreviousAnswerSet_user_trackable$ref;
export type ScoreCardsPreviousAnswerSet_user_trackable = {|
  +trackable: {|
    +formula: {|
      +formula_ranges: ?$ReadOnlyArray<{|
        +label: string,
        +value: ?number,
      |}>
    |}
  |},
  +$refType: ScoreCardsPreviousAnswerSet_user_trackable$ref,
|};
export type ScoreCardsPreviousAnswerSet_user_trackable$data = ScoreCardsPreviousAnswerSet_user_trackable;
export type ScoreCardsPreviousAnswerSet_user_trackable$key = {
  +$data?: ScoreCardsPreviousAnswerSet_user_trackable$data,
  +$fragmentRefs: ScoreCardsPreviousAnswerSet_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPreviousAnswerSet_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Formula",
          "kind": "LinkedField",
          "name": "formula",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FormulaRange",
              "kind": "LinkedField",
              "name": "formula_ranges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '1e7bce1a32ffd8d55d76e74f23f02f9b';

module.exports = node;
