// @flow

import { useEffect, useState } from 'react'

import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
} from 'react-ui/themes/synergy-base-theme/breakpoints'

function getSize() {
  const isClient = typeof window === 'object'

  const width = isClient ? window.innerWidth : 0
  const height = isClient ? window.innerHeight : 0

  const xs = width < BREAKPOINT_SM
  const sm = width >= BREAKPOINT_SM
  const md = width >= BREAKPOINT_MD
  const lg = width >= BREAKPOINT_LG
  const smOnly = width >= BREAKPOINT_SM && width <= BREAKPOINT_MD - 1
  const mdOnly = width >= BREAKPOINT_MD && width <= BREAKPOINT_LG - 1

  return {
    width,
    height,
    xs,
    sm,
    md,
    lg,
    smOnly,
    mdOnly,
  }
}

/**
 * This hook is used to get the screen size and the standard breakpoints
 */
export function useWindowSize() {
  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    function handleResize() {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  return windowSize
}
