// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query DashboardPreferencesQuery {
    viewer {
      currentUser {
        extensiveUserTrackables: user_trackables(
          kinds: [text, additional_assessment]
        ) {
          ...HealthPreferences_user_trackables
        }
        homepage_path
      }
    }
  }
`
