/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserUpdateInput = {|
  clientMutationId?: ?string,
  user: UserUpdateInputObject,
|};
export type UserUpdateInputObject = {|
  completed_onboarding?: ?boolean,
  email?: ?string,
  legal_name?: ?string,
  phone_number?: ?string,
  preferred_language?: ?string,
  preferred_name?: ?string,
  selected_role_id?: ?string,
  tenant_id?: ?string,
  username?: ?string,
|};
export type SwitchTenantPageMutationVariables = {|
  input: UserUpdateInput
|};
export type SwitchTenantPageMutationResponse = {|
  +userUpdate: ?{|
    +user: {|
      +homepage_path: string
    |}
  |}
|};
export type SwitchTenantPageMutation = {|
  variables: SwitchTenantPageMutationVariables,
  response: SwitchTenantPageMutationResponse,
|};
*/


/*
mutation SwitchTenantPageMutation(
  $input: UserUpdateInput!
) {
  userUpdate(input: $input) {
    user {
      homepage_path
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homepage_path",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitchTenantPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwitchTenantPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6e1c9712be3f2bceab4f2f5bb8d6b71c",
    "id": null,
    "metadata": {},
    "name": "SwitchTenantPageMutation",
    "operationKind": "mutation",
    "text": "mutation SwitchTenantPageMutation(\n  $input: UserUpdateInput!\n) {\n  userUpdate(input: $input) {\n    user {\n      homepage_path\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ed4e207986ef0765aa8b1b77846e3bd1';

module.exports = node;
