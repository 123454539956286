// @flow

import { graphql } from 'react-relay'

export const multiFactorAuthenticationQuery = {
  user: graphql`
    fragment MultiFactorAuthentication_user on User {
      mfa_qr_code
      mfa_last_verified_at
    }
  `,
}
