/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardsPage_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPageComponent_viewer$ref: FragmentReference;
declare export opaque type ScoreCardsPageComponent_viewer$fragmentType: ScoreCardsPageComponent_viewer$ref;
export type ScoreCardsPageComponent_viewer = {|
  +$fragmentRefs: ScoreCardsPage_viewer$ref,
  +$refType: ScoreCardsPageComponent_viewer$ref,
|};
export type ScoreCardsPageComponent_viewer$data = ScoreCardsPageComponent_viewer;
export type ScoreCardsPageComponent_viewer$key = {
  +$data?: ScoreCardsPageComponent_viewer$data,
  +$fragmentRefs: ScoreCardsPageComponent_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPageComponent_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardsPage_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c0699cca01956218eb773d1184caffb1';

module.exports = node;
