// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const CloseMenuIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M20.5,19.5857864 L27.1853732,12.9004132 C27.5936486,12.4921378 28.2412037,12.4777477 28.631728,12.868272 C29.0222523,13.2587963 29.0078622,13.9063514 28.5995868,14.3146268 L21.9142136,21 L28.5995868,27.6853732 C29.0078622,28.0936486 29.0222523,28.7412037 28.631728,29.131728 C28.2412037,29.5222523 27.5936486,29.5078622 27.1853732,29.0995868 L20.5,22.4142136 L13.8146268,29.0995868 C13.4063514,29.5078622 12.7587963,29.5222523 12.368272,29.131728 C11.9777477,28.7412037 11.9921378,28.0936486 12.4004132,27.6853732 L19.0857864,21 L12.4004132,14.3146268 C11.9921378,13.9063514 11.9777477,13.2587963 12.368272,12.868272 C12.7587963,12.4777477 13.4063514,12.4921378 13.8146268,12.9004132 L20.5,19.5857864 Z M21,40 C31.4934102,40 40,31.4934102 40,21 C40,10.5065898 31.4934102,2 21,2 C10.5065898,2 2,10.5065898 2,21 C2,31.4934102 10.5065898,40 21,40 Z M21,42 C9.40202025,42 0,32.5979797 0,21 C0,9.40202025 9.40202025,0 21,0 C32.5979797,0 42,9.40202025 42,21 C42,32.5979797 32.5979797,42 21,42 Z"
            id="close-menu-path-1"
          />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth={1} fillRule="evenodd">
          <g id="icon-/-close">
            <use
              id="Rectangle-13-Copy"
              fillRule="evenodd"
              xlinkHref="#close-menu-path-1"
            />
            <g
              id="→-colour-/-_Light-/-white"
              mask="url(#mask-2)"
              fillRule="evenodd"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default CloseMenuIcon
