// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import {
  SimpleTextButtonTrigger,
  StaffActionButtonTrigger,
} from 'react-ui/components/PopOver'

import { IndividualAssignmentsPopover } from './IndividualAssignmentsPopover'

import type { StaffIndividualsList_clinician } from './__generated__/StaffIndividualsList_clinician.graphql'

export type StaffIndividualsListClinicianType = StaffIndividualsList_clinician

type PropsType = {
  clinician: StaffIndividualsList_clinician,
  targetToggle?: () => void,
  triggerLabel?: string,
}

const StaffIndividualsList = (props: PropsType) => {
  const { clinician, triggerLabel, targetToggle } = props

  // Localization
  const { t: translation } = useTranslation('dataTable')

  return (
    <IndividualAssignmentsPopover
      clinician={clinician}
      renderTrigger={({ isOpen, onToggleOpen }) => {
        return triggerLabel ? (
          <SimpleTextButtonTrigger
            onToggleOpen={onToggleOpen}
            label={triggerLabel}
            disabled={!clinician.assignable}
          />
        ) : (
          <StaffActionButtonTrigger
            onToggleOpen={onToggleOpen}
            targetToggle={targetToggle}
            label={translation('assign_individual')}
            assignable={clinician.assignable}
            isOpen={isOpen}
          />
        )
      }}
    />
  )
}

export const StaffIndividualsListLoader = createFragmentContainer(
  StaffIndividualsList,
  {
    clinician: graphql`
      fragment StaffIndividualsList_clinician on ClinicianRole {
        id
        assignable
        clinician_assignments_as_clinician {
          id
        }
      }
    `,
  },
)
