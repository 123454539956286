// @flow

const checkLengthRegex = /(^.{8,20}$)/

const checkCaseRegex = /(?=.*[A-Z])(?=.*[a-z])/

const checkIntRegex = /(?=.*[0-9])/

export const checkLength = (password: string) => checkLengthRegex.test(password)
export const checkCase = (password: string) => checkCaseRegex.test(password)
export const checkInt = (password: string) => checkIntRegex.test(password)

const entered = (password: string, passwordCheck: string) =>
  !!passwordCheck &&
  passwordCheck.length > 0 &&
  !!password &&
  password.length > 0

export const enteredMatched = (password: string, passwordCheck: string) =>
  entered(password, passwordCheck) && password === passwordCheck

export const enteredNotMatched = (password: string, passwordCheck: string) =>
  entered(password, passwordCheck) && password !== passwordCheck

export const checkPasswordPolicy = (
  password: string = '',
  passwordCheck: string = '',
) => ({
  password_length_valid: checkLength(password),
  password_inc_upper_lower: checkCase(password),
  password_inc_int: checkInt(password),
  entered_matched: enteredMatched(password, passwordCheck),
  entered_not_matched: enteredNotMatched(password, passwordCheck),
})
