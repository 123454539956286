/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ResourceList_resourcesConnectionInterface$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResourceLibraryPage_viewer$ref: FragmentReference;
declare export opaque type ResourceLibraryPage_viewer$fragmentType: ResourceLibraryPage_viewer$ref;
export type ResourceLibraryPage_viewer = {|
  +id: string,
  +$fragmentRefs: ResourceList_resourcesConnectionInterface$ref,
  +$refType: ResourceLibraryPage_viewer$ref,
|};
export type ResourceLibraryPage_viewer$data = ResourceLibraryPage_viewer;
export type ResourceLibraryPage_viewer$key = {
  +$data?: ResourceLibraryPage_viewer$data,
  +$fragmentRefs: ResourceLibraryPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ResourceLibraryPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ResourceList_resourcesConnectionInterface"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '57fe3e43d5ce7c19e0be96eb238ee5c6';

module.exports = node;
