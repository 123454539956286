// @flow

import React, { type Node } from 'react'
import cx from 'classnames'

import createComponentId from 'shared/services/id'

import s from './Checkbox.scss'

type PropsType = {
  checked?: boolean,
  children: Node,
  componentId?: string,
  disabled?: boolean,
  name: string,
  onBlur?: Function,
  onChange?: Function,
  uiStyle?: 'primary' | 'plain' | 'danger',
  value: string | number | boolean,
}

const defaultId = createComponentId(__filename)

const Checkbox = ({
  checked,
  children,
  componentId = defaultId,
  disabled = false,
  name,
  onBlur,
  onChange,
  uiStyle = 'primary',
  value,
}: PropsType) => (
  <label
    className={cx(s.container, componentId, {
      [s.disabled]: disabled,
      [s.checked]: checked,
      [s[uiStyle]]: uiStyle,
    })}
  >
    <input
      checked={checked}
      name={name}
      onBlur={onBlur}
      onClick={onBlur}
      onChange={onChange}
      type="checkbox"
      value={value}
      disabled={disabled}
    />
    <span className={s.title}>{children}</span>
  </label>
)

export default Checkbox
