// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useFragment } from 'react-relay'
import parser from 'html-react-parser'

import { longDateTimeFormat } from 'services/dateTime'
import { Text } from 'care-ui'

import ActivityLogChangeSet from '../ActivityLogChangeSet'

import { cardStyle, dateStyle, infoStyle } from './ActivityLogCard.style'
import { fragment } from './query/ActivityLogCard'

import type { ActivityLogCard_activityLog$key } from './query/__generated__/ActivityLogCard_activityLog.graphql'

type PropsType = {
  activityLog: ActivityLogCard_activityLog$key,
}

const ActivityLogCard = (props: PropsType) => {
  const { activityLog: fragmentRef } = props

  const activityLog = useFragment(fragment, fragmentRef)
  const { display_text, occured_at } = activityLog
  const { css } = useFela()

  const parsedDisplayText = parser(display_text)
  const parsedOccuredAt = longDateTimeFormat(new Date(occured_at))

  return (
    <div className={css(cardStyle)} data-testid="activityContainer">
      <Text size="sm" color="title" extend={dateStyle} dataTestId="date">
        {parsedOccuredAt}
      </Text>

      <Text
        size="lg"
        color="title"
        extend={infoStyle}
        dataTestId="activityText"
      >
        {parsedDisplayText}
      </Text>

      <ActivityLogChangeSet activityLog={activityLog} />
    </div>
  )
}

export default ActivityLogCard
