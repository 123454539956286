// @flow

export const styleRules = () => ({
  container: {
    className: 'OnboardingQuestionsForm',
    marginBottom: '32px',
    width: '80%',
  },

  questionTitle: {
    className: 'OnboardingQuestionsForm__Title',
    marginBottom: '8px',
  },

  answersContainer: {
    className: 'OnboardingQuestionsForm__answersContainer',
    marginBottom: '32px',
  },

  buttonLabel: {
    marginRight: '0.6rem',
    marginBottom: '16px',
    fontSize: '14px',
  },
  buttonRight: {
    float: 'right',
    fontSize: '14px',
    marginBottom: '16px',
  },
})
