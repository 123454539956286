// @flow

import React from 'react'

import QuarterPicker from 'platform_web/components/QuarterPicker'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { Box, FlexContainer } from 'care-ui'

import { type OutcomeReportingQueryVariableType } from '../tabs/Outcomes/hooks/useOutcomeReporting'
import { type OverviewQueryVariableType } from '../tabs/Overview/hooks/useOverviewInsights'

import TenantSwitcher from './TenantSwitcher'

type OutcomesHeaderActionsProps = {
  outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
  overviewQueryVariables: OverviewQueryVariableType,
  setOutcomeReportQueryVariables: (overviewQueryVariables: {
    [key: string]: string,
  }) => void,
  setOverviewQueryVariables: (overviewQueryVariables: {
    [key: string]: string,
  }) => void,
}

const OutcomesHeaderActions = (props: OutcomesHeaderActionsProps) => {
  const {
    setOverviewQueryVariables,
    overviewQueryVariables,
    setOutcomeReportQueryVariables,
    outcomeReportQueryVariables,
  } = props

  const {
    ownerTenants,
    hasOwnerRole,
    tenantCreatedDate,
  } = useCurrentUserRoles()

  const tenant = ownerTenants?.find(
    option => option.id === outcomeReportQueryVariables?.tenant_id,
  )

  const ownerDefaultTenantCreatedDate = ownerTenants?.[0]?.created_at

  const createdDate = ownerDefaultTenantCreatedDate || tenantCreatedDate

  const startDate =
    (!hasOwnerRole ? createdDate : tenant?.created_at) ||
    new Date().toISOString()

  return (
    <FlexContainer alignItems="center" gap="xs" wrap="nowrap">
      <TenantSwitcher
        overviewQueryVariables={overviewQueryVariables}
        setOverviewQueryVariables={setOverviewQueryVariables}
        setOutcomeReportQueryVariables={setOutcomeReportQueryVariables}
        outcomeReportQueryVariables={outcomeReportQueryVariables}
      />
      <Box width="220px">
        <QuarterPicker
          startDate={startDate}
          onChange={setOutcomeReportQueryVariables}
        />
      </Box>
    </FlexContainer>
  )
}

export default OutcomesHeaderActions
