// @flow

import { graphql } from 'react-relay'

export const UserSettingsPersonalDetailsEditPageQuery = graphql`
  query UserSettingsPersonalDetailsEditPage_Query {
    viewer {
      currentUser {
        profileQuestionnaire {
          ...UserSettingsPersonalDetailsEditPage_profileQuestionnaire
        }
      }
    }
  }
`

export const query = {
  profileQuestionnaire: graphql`
    fragment UserSettingsPersonalDetailsEditPage_profileQuestionnaire on SimpleQuestionnaire {
      questions {
        name
        label
        title
        fieldType
        value
        other
        required
        options {
          label
          value
        }
        otherEnabled
        validationName
        dependOn
        profile_type
      }
      orders
    }
  `,
}
