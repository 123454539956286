// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserQuestionnaireSubmitMutationResponse,
  UserQuestionnaireSubmitMutationVariables,
} from './__generated__/UserQuestionnaireSubmitMutation.graphql'

const mutation = graphql`
  mutation UserQuestionnaireSubmitMutation(
    $input: UserQuestionnaireSubmitInput!
  ) {
    userQuestionnaireSubmit(input: $input) {
      viewer {
        id
        currentUser {
          ...SupportingSupporteeQuestionnairesList_user
          ...SupportingSupporteeQuestionnaireSubmissionComponent_user
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: UserQuestionnaireSubmitMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: UserQuestionnaireSubmitMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
