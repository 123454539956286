// @flow

import * as React from 'react'
import Route from 'found/Route'
import { curry, get } from 'lodash'

import Page from 'react-ui/components/Page'
import EditPasswordPage from 'pages/authentication/EditPassword'
import NewPasswordPage from 'pages/authentication/NewPassword'
import RequestInvitation from 'pages/authentication/RequestInvitation'
import SignInPage from 'pages/authentication/SignInPage'
import { ConnectedSetPasswordPage } from 'platform_web/pages/Onboarding'

import AuthActions from './authActions'

const appParams = window.APP_PARAMS || {}
const getRouteData = route => get(appParams, ['route_data', route])

const renderWithData = curry((Component, data, props) => (
  <Component data={data} {...(props: any)} />
))

const AuthPage = ({ children }) => (
  <Page actions={<AuthActions />} noMenu>
    {children}
  </Page>
)

const routes = (
  <Route Component={AuthPage}>
    <Route name="sign_in" path="/sign_in" Component={SignInPage} />
    <Route
      name="new_password"
      path="/password/new"
      render={renderWithData(
        NewPasswordPage,
        getRouteData('/users/password/new'),
      )}
    />
    <Route
      name="edit_password"
      path="/password/edit"
      render={renderWithData(
        EditPasswordPage,
        getRouteData('/users/password/edit'),
      )}
    />
    <Route
      name="sign_up"
      path="/invitation/accept"
      Component={ConnectedSetPasswordPage}
    />
    <Route
      name="request_invitation"
      path="/invitation/request"
      Component={RequestInvitation}
    />
  </Route>
)

export default routes
