// @flow

import React, { type Node } from 'react'
import {
  createPaginationContainer,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { type GraphQLTaggedNode } from 'relay-runtime'

import { QueryRendererLoadingIndicator } from 'platform_web/components/QueryRendererLoadingIndicator'

type ChildrenType = Node | ((props: any) => Node)

type PaginationQueryLoaderType<T> = {
  children: ChildrenType,
  fragments: { [string]: GraphQLTaggedNode },
  getFragments: any => any,
  query: GraphQLTaggedNode,
  queryVariables: T,
}

const render = (children: ChildrenType, props: any) =>
  typeof children === 'function' ? children(props) : children

const PaginationFragmentComponent = ({ relay, children, ...fragments }) => {
  return render(children, {
    relay,
    ...fragments,
  })
}

const TableDataLoader = ({
  children,
  query,
  fragments,
  getFragments,
  queryVariables,
}: PaginationQueryLoaderType<any>) => {
  const environment = useRelayEnvironment()

  const PaginationFragmentContainer = createPaginationContainer(
    PaginationFragmentComponent,
    fragments,
    {
      query,
      direction: 'forward',
      getVariables: ({ variables }, paginationInfo, fragmentVariables) => ({
        ...variables,
        ...fragmentVariables,
        ...paginationInfo,
      }),
    },
  )

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={queryVariables}
      render={response => (
        <QueryRendererLoadingIndicator response={response}>
          {originResponse => (
            <PaginationFragmentContainer
              {...queryVariables}
              {...getFragments(originResponse)}
            >
              {props => render(children, props)}
            </PaginationFragmentContainer>
          )}
        </QueryRendererLoadingIndicator>
      )}
    />
  )
}

export default TableDataLoader
