// @flow

import { graphql } from 'react-relay'

export const query = {
  user: graphql`
    fragment UserTrackableChartControls_user on User {
      fitbit {
        connected
      }
    }
  `,
  formulaRanges: graphql`
    fragment UserTrackableChartControls_formulaRanges on FormulaRange
      @relay(plural: true) {
      ...ScoreCardChartContext_formulaRanges
    }
  `,
}
