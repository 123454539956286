/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ConnectedCliniciansList_clinician_assignments$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualRolesList_individuals$ref: FragmentReference;
declare export opaque type IndividualRolesList_individuals$fragmentType: IndividualRolesList_individuals$ref;
export type IndividualRolesList_individuals = $ReadOnlyArray<{|
  +clinician_assignments: $ReadOnlyArray<{|
    +$fragmentRefs: ConnectedCliniciansList_clinician_assignments$ref
  |}>,
  +id?: string,
  +tenant?: ?{|
    +name: string
  |},
  +$refType: IndividualRolesList_individuals$ref,
|}>;
export type IndividualRolesList_individuals$data = IndividualRolesList_individuals;
export type IndividualRolesList_individuals$key = $ReadOnlyArray<{
  +$data?: IndividualRolesList_individuals$data,
  +$fragmentRefs: IndividualRolesList_individuals$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "IndividualRolesList_individuals",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianAssignment",
      "kind": "LinkedField",
      "name": "clinician_assignments",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConnectedCliniciansList_clinician_assignments"
        }
      ],
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "RoleInterface",
      "abstractKey": "__isRoleInterface"
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0fafaff01668490627da531d97335c7b';

module.exports = node;
