// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, FlexContainer, TextArea } from 'care-ui'

import useCreateClinicianNote from '../useCreateClinicianNote'

import { textAreaStyle } from './ClinicianNoteForm.style'

type PropsType = {
  onToggleOpen: () => void,
  userId: string,
}

const ClinicianNoteForm = (props: PropsType) => {
  const { userId, onToggleOpen } = props
  const [clinicianNote, setClinicianNote] = useState('')
  const { commit, isInFlight } = useCreateClinicianNote()

  const onChange = e => {
    setClinicianNote(e.target.value)
  }

  const onSubmit = () => {
    commit({
      variables: {
        input: {
          clinician_note: {
            about_id: userId,
            text: clinicianNote,
          },
        },
      },
      onCompleted: () => {
        onToggleOpen()
        setClinicianNote('')
      },
    })
  }

  const { t: translation } = useTranslation('shared')

  return (
    <FlexContainer justifyContent="end" gap="xs">
      <TextArea
        placeholder={translation('enter_note')}
        onChange={onChange}
        value={clinicianNote}
        extend={textAreaStyle}
        rows={13}
        dataTestId="textBox"
      />
      <Button
        onClick={onSubmit}
        variant="primary"
        disabled={isInFlight || !clinicianNote}
        dataTestId="save"
        ariaLabel={translation('save')}
      >
        {translation('save')}
      </Button>
    </FlexContainer>
  )
}

export default ClinicianNoteForm
