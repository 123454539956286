// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const EfficiencyIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs />
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M24,12 C24,18.6272847 18.6272847,24 12,24 C5.37271525,24 0,18.6272847 0,12 C0,5.37271525 5.37271525,0 12,0 C18.6272847,0 24,5.37271525 24,12 Z M22,12 C22,6.47728475 17.5227153,2 12,2 C6.47728475,2 2,6.47728475 2,12 C2,17.5227153 6.47728475,22 12,22 C17.5227153,22 22,17.5227153 22,12 Z M13,11 L18,11 C18.5522847,11 19,11.4477153 19,12 C19,12.5522847 18.5522847,13 18,13 L12,13 C11.4477153,13 11,12.5522847 11,12 L11,5 C11,4.44771525 11.4477153,4 12,4 C12.5522847,4 13,4.44771525 13,5 L13,11 Z" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default EfficiencyIcon
