// @flow

import * as React from 'react'
import numeral from 'numeral'

import HeroNumber from 'react-ui/components/HeroNumber'
import SegmentationTable from 'react-ui/components/SegmentationTable'
import { type TraceType } from 'services/plotly'

import DonutChart from './Donut'

type FaCType = { donut: React.Node, table: React.Node }

type PropsType = {
  children?: (nodes: FaCType) => React.Node,
  title: string,
  traces: $ReadOnlyArray<TraceType>,
}

const defaultChildren = ({ donut, table }: FaCType) => (
  <React.Fragment>
    {donut}
    {table}
  </React.Fragment>
)

const DonutSummaryChart = ({
  children = defaultChildren,
  traces,
  title,
}: PropsType) =>
  children({
    donut: <DonutChart traces={traces} title={title} />,
    table: (
      <SegmentationTable
        formatNull={{
          value0: true,
          value1: true,
        }}
        traces={traces}
        columnConfig={{
          value0: {
            format: value => numeral(value).format('0,0'),
          },
          value1: {
            format: value => (
              <HeroNumber format="0.00%" value={value / 100 || 0} />
            ),
            formatNull: true,
          },
        }}
        noHeaders
      />
    ),
  })

export default DonutSummaryChart
