/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserCheckBoxLoader_user$ref = any;
export type ReportSettingsTenantFetchQueryVariables = {|
  tenants: $ReadOnlyArray<string>,
  clinicians: $ReadOnlyArray<string>,
|};
export type ReportSettingsTenantFetchQueryResponse = {|
  +viewer: {|
    +reports: {|
      +start_date: any
    |},
    +tenants_with_id: $ReadOnlyArray<{|
      +id: string,
      +name: string,
      +metrics_clinicians: $ReadOnlyArray<{|
        +id: string,
        +name: string,
        +user: {|
          +$fragmentRefs: UserCheckBoxLoader_user$ref
        |},
      |}>,
    |}>,
  |}
|};
export type ReportSettingsTenantFetchQuery = {|
  variables: ReportSettingsTenantFetchQueryVariables,
  response: ReportSettingsTenantFetchQueryResponse,
|};
*/


/*
query ReportSettingsTenantFetchQuery(
  $tenants: [ID!]!
  $clinicians: [ID!]!
) {
  viewer {
    reports {
      start_date(tenants: $tenants, clinicians: $clinicians)
      id
    }
    tenants_with_id(ids: $tenants) {
      id
      name
      metrics_clinicians {
        id
        name
        user {
          ...UserCheckBoxLoader_user
          id
        }
      }
    }
    id
  }
}

fragment AvatarLoader_user on User {
  id
  email
  legal_name
}

fragment UserCardLoader_user on User {
  ...AvatarLoader_user
  username
  id
  legal_name
  preferred_name
}

fragment UserCheckBoxLoader_user on User {
  ...UserCardLoader_user
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinicians"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenants"
},
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "clinicians",
      "variableName": "clinicians"
    },
    {
      "kind": "Variable",
      "name": "tenants",
      "variableName": "tenants"
    }
  ],
  "kind": "ScalarField",
  "name": "start_date",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "ids",
    "variableName": "tenants"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ReportSettingsTenantFetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenants_with_id",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClinicianRole",
                "kind": "LinkedField",
                "name": "metrics_clinicians",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "UserCheckBoxLoader_user"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "ReportSettingsTenantFetchQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenants_with_id",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "ClinicianRole",
                "kind": "LinkedField",
                "name": "metrics_clinicians",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "email",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "legal_name",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "username",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "preferred_name",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5682816c4e26f7b2cc321767a1eac00a",
    "id": null,
    "metadata": {},
    "name": "ReportSettingsTenantFetchQuery",
    "operationKind": "query",
    "text": "query ReportSettingsTenantFetchQuery(\n  $tenants: [ID!]!\n  $clinicians: [ID!]!\n) {\n  viewer {\n    reports {\n      start_date(tenants: $tenants, clinicians: $clinicians)\n      id\n    }\n    tenants_with_id(ids: $tenants) {\n      id\n      name\n      metrics_clinicians {\n        id\n        name\n        user {\n          ...UserCheckBoxLoader_user\n          id\n        }\n      }\n    }\n    id\n  }\n}\n\nfragment AvatarLoader_user on User {\n  id\n  email\n  legal_name\n}\n\nfragment UserCardLoader_user on User {\n  ...AvatarLoader_user\n  username\n  id\n  legal_name\n  preferred_name\n}\n\nfragment UserCheckBoxLoader_user on User {\n  ...UserCardLoader_user\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3893c75344f81cbcf26e34d80f371e2f';

module.exports = node;
