// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import AlertIcon from 'react-ui/assets/icons/alert-circle.svg'
import Card from 'react-ui/components/Card'
import { H2 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import { Button } from 'care-ui'

import { styledRules } from './PageError.style'

const generateErrorMessage = (errorCode, translation) => {
  if (errorCode === 404) {
    return {
      title: translation('we_cant_find_this_page'),
      description: '',
    }
  }

  if (errorCode === 500) {
    return {
      title: translation('oops'),
      description: (
        <p>
          {translation(
            'something_went_wrong_please_try_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email',
          )}
        </p>
      ),
    }
  }

  if (errorCode === 502) {
    return {
      title: translation('oops'),
      description: (
        <p>
          {translation(
            'somethings_gone_wrong_but_its_likely_temporary_please_wait_a_few_moments_and_try_refreshing_the_page_or_come_back_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email',
          )}
        </p>
      ),
    }
  }

  if (errorCode === 504) {
    return {
      title: translation('oops'),
      description: (
        <p>
          {translation(
            'sorry_this_page_is_taking_way_too_long_to_load_please_wait_a_few_moments_and_try_refreshing_the_page_or_come_back_again_later_if_you_need_immediate_help_please_contact_your_service_or_send_us_a_support_email',
          )}
        </p>
      ),
    }
  }

  return {
    title: translation('error'),
    description: '',
  }
}

const PageError = props => {
  const { errorCode, rules, styles } = props

  const { t: translation } = useTranslation('shared')

  const errorPayload = generateErrorMessage(errorCode, translation)
  const { description, title } = errorPayload

  return (
    <div className={styles.page}>
      <Card extend={rules.card}>
        <Icon as={AlertIcon} scale={4} />

        <H2 extend={rules.cardHeader}>{title}</H2>

        {description || (
          <Button variant="primary" to="/">
            {translation('back_to_home')}
          </Button>
        )}

        {errorCode && (
          <small className={styles.errorCode}>
            {translation('error_code_errorcode', { errorCode })}
          </small>
        )}
      </Card>
    </div>
  )
}

export default connect(styledRules)(PageError)
