/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type IndividualTasksCard_individual$ref = any;
type UserTrackable_user_trackable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserTrackableList_user_trackables$ref: FragmentReference;
declare export opaque type UserTrackableList_user_trackables$fragmentType: UserTrackableList_user_trackables$ref;
export type UserTrackableList_user_trackables = $ReadOnlyArray<{|
  +user: {|
    +linked_individual: ?{|
      +$fragmentRefs: IndividualTasksCard_individual$ref
    |}
  |},
  +trackable: {|
    +id: string
  |},
  +$fragmentRefs: UserTrackable_user_trackable$ref,
  +$refType: UserTrackableList_user_trackables$ref,
|}>;
export type UserTrackableList_user_trackables$data = UserTrackableList_user_trackables;
export type UserTrackableList_user_trackables$key = $ReadOnlyArray<{
  +$data?: UserTrackableList_user_trackables$data,
  +$fragmentRefs: UserTrackableList_user_trackables$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserTrackableList_user_trackables",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualRole",
          "kind": "LinkedField",
          "name": "linked_individual",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "IndividualTasksCard_individual"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserTrackable_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f6a5c7ac26502fe146adde1fd4949c2a';

module.exports = node;
