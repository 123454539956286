/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSettingsPersonalDetailsEditPage_profileQuestionnaire$ref = any;
export type UserSettingsPersonalDetailsEditPage_QueryVariables = {||};
export type UserSettingsPersonalDetailsEditPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +profileQuestionnaire: ?{|
        +$fragmentRefs: UserSettingsPersonalDetailsEditPage_profileQuestionnaire$ref
      |}
    |}
  |}
|};
export type UserSettingsPersonalDetailsEditPage_Query = {|
  variables: UserSettingsPersonalDetailsEditPage_QueryVariables,
  response: UserSettingsPersonalDetailsEditPage_QueryResponse,
|};
*/


/*
query UserSettingsPersonalDetailsEditPage_Query {
  viewer {
    currentUser {
      profileQuestionnaire {
        ...UserSettingsPersonalDetailsEditPage_profileQuestionnaire
      }
      id
    }
    id
  }
}

fragment UserSettingsPersonalDetailsEditPage_profileQuestionnaire on SimpleQuestionnaire {
  questions {
    name
    label
    title
    fieldType
    value
    other
    required
    options {
      label
      value
    }
    otherEnabled
    validationName
    dependOn
    profile_type
  }
  orders
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsPersonalDetailsEditPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SimpleQuestionnaire",
                "kind": "LinkedField",
                "name": "profileQuestionnaire",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserSettingsPersonalDetailsEditPage_profileQuestionnaire"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSettingsPersonalDetailsEditPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SimpleQuestionnaire",
                "kind": "LinkedField",
                "name": "profileQuestionnaire",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SimpleQuestion",
                    "kind": "LinkedField",
                    "name": "questions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "title",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "fieldType",
                        "storageKey": null
                      },
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "other",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "required",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "SimpleQuestionOption",
                        "kind": "LinkedField",
                        "name": "options",
                        "plural": true,
                        "selections": [
                          (v0/*: any*/),
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "otherEnabled",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "validationName",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "dependOn",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "profile_type",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orders",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1424b2aa830d148c7811fb69bc8aa89e",
    "id": null,
    "metadata": {},
    "name": "UserSettingsPersonalDetailsEditPage_Query",
    "operationKind": "query",
    "text": "query UserSettingsPersonalDetailsEditPage_Query {\n  viewer {\n    currentUser {\n      profileQuestionnaire {\n        ...UserSettingsPersonalDetailsEditPage_profileQuestionnaire\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment UserSettingsPersonalDetailsEditPage_profileQuestionnaire on SimpleQuestionnaire {\n  questions {\n    name\n    label\n    title\n    fieldType\n    value\n    other\n    required\n    options {\n      label\n      value\n    }\n    otherEnabled\n    validationName\n    dependOn\n    profile_type\n  }\n  orders\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7f9481ed10c88a7442085df9c6c45fd0';

module.exports = node;
