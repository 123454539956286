/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ClinicianNotesListView_clinician_note$ref = any;
export type ClinicianNotesListViewQueryVariables = {|
  userId: string
|};
export type ClinicianNotesListViewQueryResponse = {|
  +user: {|
    +clinician_notes: ?{|
      +$fragmentRefs: ClinicianNotesListView_clinician_note$ref
    |}
  |}
|};
export type ClinicianNotesListViewQuery = {|
  variables: ClinicianNotesListViewQueryVariables,
  response: ClinicianNotesListViewQueryResponse,
|};
*/


/*
query ClinicianNotesListViewQuery(
  $userId: ID!
) {
  user(id: $userId) {
    clinician_notes {
      ...ClinicianNotesListView_clinician_note
    }
    id
  }
}

fragment ClinicianNotesListView_clinician_note on ClinicianNoteConnection {
  nodes {
    id
    created_at
    text
    author {
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "userId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClinicianNotesListViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianNoteConnection",
            "kind": "LinkedField",
            "name": "clinician_notes",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ClinicianNotesListView_clinician_note"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClinicianNotesListViewQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianNoteConnection",
            "kind": "LinkedField",
            "name": "clinician_notes",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ClinicianNote",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "author",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "768a282c74cb0c41e9c67cf0a79355e2",
    "id": null,
    "metadata": {},
    "name": "ClinicianNotesListViewQuery",
    "operationKind": "query",
    "text": "query ClinicianNotesListViewQuery(\n  $userId: ID!\n) {\n  user(id: $userId) {\n    clinician_notes {\n      ...ClinicianNotesListView_clinician_note\n    }\n    id\n  }\n}\n\nfragment ClinicianNotesListView_clinician_note on ClinicianNoteConnection {\n  nodes {\n    id\n    created_at\n    text\n    author {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '8c647272b8c7d0052ef4c17793188c23';

module.exports = node;
