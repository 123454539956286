// @flow

import { useState } from 'react'
import { useSetState } from 'react-use'

import {
  COUNT,
  DEFAULT_TABLE_CONFIGURATIONS,
  TABLE_CATEGORIES,
} from '../../constants/TableConstants'
import type { IndividualsPageUser } from '../IndividualsPage'

export type UpdateQueryVariablesType = ({ [string]: string | boolean }) => void

export type QueryVariablesType = {
  [string]: string | boolean,
}

export type UseIndividualTableReturnType = {
  count: number,
  currentUser?: IndividualsPageUser,
  defaultQueryVariables: QueryVariablesType,
  queryVariables: QueryVariablesType,
  setTableType: (tableType: string) => void,
  tableCategory: string,
  tableType: string,
  updateQueryVariables: UpdateQueryVariablesType,
}

type UseIndividualTableProps = {
  currentUser?: IndividualsPageUser,
}

const useIndividualTable = (
  props: UseIndividualTableProps,
): UseIndividualTableReturnType => {
  const { currentUser } = props
  const { initial_state: defaultTableType } = currentUser || {}

  const [tableType, setTableType] = useState(defaultTableType)
  const defaultQueryVariables: QueryVariablesType = {
    ...DEFAULT_TABLE_CONFIGURATIONS[tableType],
    count: COUNT,
  }

  // Logic to handle filter, sort, and search
  // Local state for Individual Table to handle filter, sort, and search query variables
  const [state, setState] = useSetState(defaultQueryVariables)

  const updateQueryVariables: UpdateQueryVariablesType = (
    updatedQueryVariables: QueryVariablesType,
  ) => {
    setState(updatedQueryVariables)
  }

  return {
    count: COUNT,
    currentUser,
    defaultQueryVariables,
    tableCategory: TABLE_CATEGORIES[tableType],
    tableType,
    setTableType,
    updateQueryVariables,
    queryVariables: state,
  }
}

export default useIndividualTable
