// @flow

export type TrackableColorfromColorCodeType = {
  colorCode: string,
  theme: any,
}

export const trackableColorfromColorCode = (
  params: TrackableColorfromColorCodeType,
) => {
  const { colorCode, theme } = params

  switch (colorCode) {
    case '#5ABF95':
    case 'green':
      return theme.care.palette.surface.successLight
    case '#FEF440':
    case 'yellow':
      return theme.care.palette.surface.warningLight
    case '#FFCD6B':
    case 'orange':
      return theme.care.palette.surface.warning
    case '#C62727':
    case 'red':
      return theme.care.palette.surface.dangerLighter
    default:
      return theme.care.palette.surface.default
  }
}
