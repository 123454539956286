// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import withRouter from 'found/withRouter'
import { flowRight } from 'lodash/fp'

import Spacer from 'react-ui/components/Spacer'
import { commit as commitFeedbackCreate } from 'mutations/Feedback/SubmitFeedback'
import { Heading, Text } from 'care-ui'

import { FeedbackFormLoader } from '../FeedbackForm'

import { feedbackPageViewer } from './query/FeedbackPage'

import type { FeedbackPage_viewer } from './query/__generated__/FeedbackPage_viewer.graphql'

type PropsType = {
  relay: Object,
  viewer: FeedbackPage_viewer,
}

const emptyFeedback = {
  topic: '',
  subject: '',
  description: '',
}

const FeedbackPage = (props: PropsType) => {
  const { viewer, relay: { environment } } = props

  const onFormSubmit = (values: Object, resetForm: () => void) => {
    commitFeedbackCreate({
      environment,
      viewer,
      onCompleted: resetForm,
      variables: {
        input: {
          feedback: {
            topic: values.topic,
            subject: values.subject,
            description: values.description,
          },
        },
      },
    })
  }

  const { t: translation } = useTranslation('feedback')

  return (
    <Fragment>
      <Heading level={3} margin="xs">
        {translation('contact_us')}
      </Heading>

      <Text>
        {translation(
          'your_feedback_is_important_to_us_it_helps_us_to_continue_improving_how_the_innowell_platform_works_best_for_you',
        )}
      </Text>

      <Spacer units={1} />

      <Text>
        <strong>
          {translation('what_if_i_am_reporting_on_behalf_of_an_individual')}
        </strong>
        <Spacer units={0.5} />
        {translation(
          'for_privacy_purposes_if_reporting_on_behalf_of_an_individual_please_do_not_include_any_sensitive_and_personal_information_of_the_individual_user_eg_email_address_or_user_name_innowell_will_be_responsible_for_responding_to_your_query_any_followup_with_the_individual_thereafter_should_be_managed_through_you_and_your_service',
        )}
      </Text>

      <Spacer units={1} />

      <FeedbackFormLoader
        id="contactUsForm"
        onSubmit={onFormSubmit}
        feedback={emptyFeedback}
        viewer={viewer}
      />
    </Fragment>
  )
}

const withRelay = component =>
  createFragmentContainer(component, feedbackPageViewer)

export default flowRight([withRelay, withRouter])(FeedbackPage)
