// @flow

import { get } from 'lodash/fp'

import { SentryUserConfig } from 'shared/services/sentry'

import type { ActionsType } from './actions'
import { CHANGE_CURRENT_USER_ROLE, LOGGED_OUT } from './actionTypes'

export type StateType = {
  loggedIn: boolean,
  ownerTenants: ?$ReadOnlyArray<$ReadOnly<{ [key: string]: any }>>,
  roleType: ?string,
  roles: ?$ReadOnlyArray<string>,
  tenant: ?$ReadOnly<{ [key: string]: any }>,
  userId: ?string,
}

const defaultState = {
  loggedIn: false,
  userId: null,
  tenant: null,
  ownerTenants: null,
  roleType: null,
  roles: null,
}

export default (
  state: StateType = defaultState,
  action: ActionsType,
): StateType => {
  switch (action.type) {
    case LOGGED_OUT: {
      const data = {
        userId: null,
        tenant: null,
        ownerTenants: null,
        roleType: null,
        roles: null,
      }
      SentryUserConfig(data)
      return {
        ...state,
        ...defaultState,
      }
    }
    case CHANGE_CURRENT_USER_ROLE: {
      const { userId, tenant, ownerTenants, roleType, roles } = action

      const data = { userId, tenant: get('label', tenant), roleType, roles }
      SentryUserConfig(data)
      return {
        ...state,
        loggedIn: true,
        userId,
        tenant,
        ownerTenants,
        roleType,
        roles,
      }
    }
    default: {
      return state
    }
  }
}
