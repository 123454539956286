// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import AnchorTab from 'react-ui/components/Tabs/AnchorTab'

import type { FelaOptionalPropsType, FelaPropsType } from 'react-ui/typing'

export type TabData = {
  +id: string,
  +label: string,
}

export type BaseTabsProps = {
  componentId?: string,
  possibleTabs: $ReadOnlyArray<TabData>,
  tabButtons?: boolean,
}

type TabsPropsType = BaseTabsProps & {
  currentTabId: string,
  onChange: (tabId: string) => void,
}

export type ExternalTabsPropsType = TabsPropsType & FelaOptionalPropsType

const styleRules = () => ({
  ul: {
    display: 'inline-flex',
    alignItems: 'flex-end',
    listStyle: 'none',
    paddingLeft: 0,
  },
  tabElem: {
    display: 'inline',
    paddingRight: '10px',
  },
})

const AnchorTabs = ({
  possibleTabs,
  currentTabId,
  styles = {},
  componentId = 'AnchorTab_Tabs',
  onChange,
}: TabsPropsType & FelaPropsType) => (
  <div role="tablist" className={styles.ul} data-component-id={componentId}>
    {possibleTabs.map(({ id, label }) => (
      <AnchorTab
        role="tab"
        key={id}
        id={id}
        aria-selected={id === currentTabId}
        aria-controls={`${id}-tabs`}
        isSelected={id === currentTabId}
        onClick={() => {
          onChange(id)
        }}
      >
        {label}
      </AnchorTab>
    ))}
  </div>
)

const StyledTabs: React.StatelessFunctionalComponent<
  ExternalTabsPropsType,
> = connect(styleRules)(AnchorTabs)

export default StyledTabs
