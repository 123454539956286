// @flow

import { createComponentWithProxy } from 'react-fela'

const Tab = props => {
  const { theme, isSelected } = props
  const { palette } = theme

  const selectionStatusStyles = isSelected
    ? {
        backgroundColor: palette.bodyBackgroundColor,
        color: palette.link.linkColor,
        borderColor: palette.link.linkColor,
        borderStyle: 'inset inset none',
      }
    : {
        backgroundColor: palette.tabs.backgroundColor,
        color: 'white',
        '&:hover': {
          backgroundColor: 'rgba(255, 255, 255, 0.25)',
          color: palette.tabs.backgroundColor,
          borderColor: palette.tabs.backgroundColor,
          borderStyle: 'inset inset none inset',
        },
      }

  const fontSize = 18
  const verticalPadding = fontSize / 2

  return {
    className: 'Tabs__tab',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.component.horizontalPadding,
    paddingRight: theme.component.horizontalPadding,
    paddingTop: `${verticalPadding}px`,
    paddingBottom: `${verticalPadding}px`,
    margin: `0 ${theme.component.horizontalPadding}px 0 0`,
    fontSize: `${fontSize}px`,
    borderStyle: 'inset inset none inset',
    borderColor: palette.tabs.backgroundColor,
    borderTopLeftRadius: theme.component.borderRadius,
    borderTopRightRadius: theme.component.borderRadius,
    cursor: 'pointer',
    outlineColor: theme.Input.focus.accent,
    backgroundColor: 'white',

    ...selectionStatusStyles,
  }
}

export default createComponentWithProxy(Tab, 'button')
