/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserQuestionSetTable_user_question_sets$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnaireDetailsPage_user_questionnaire$ref: FragmentReference;
declare export opaque type UserQuestionnaireDetailsPage_user_questionnaire$fragmentType: UserQuestionnaireDetailsPage_user_questionnaire$ref;
export type UserQuestionnaireDetailsPage_user_questionnaire = {|
  +id: string,
  +questionnaire: ?{|
    +label: string
  |},
  +ensure_questionnaire: {|
    +label: string
  |},
  +user_question_sets: $ReadOnlyArray<{|
    +for_role_type: string,
    +$fragmentRefs: UserQuestionSetTable_user_question_sets$ref,
  |}>,
  +$refType: UserQuestionnaireDetailsPage_user_questionnaire$ref,
|};
export type UserQuestionnaireDetailsPage_user_questionnaire$data = UserQuestionnaireDetailsPage_user_questionnaire;
export type UserQuestionnaireDetailsPage_user_questionnaire$key = {
  +$data?: UserQuestionnaireDetailsPage_user_questionnaire$data,
  +$fragmentRefs: UserQuestionnaireDetailsPage_user_questionnaire$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnaireDetailsPage_user_questionnaire",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "questionnaire",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "ensure_questionnaire",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionSet",
      "kind": "LinkedField",
      "name": "user_question_sets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "for_role_type",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserQuestionSetTable_user_question_sets"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionnaire",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'cc0de87039adb6cfe6b1e913702372d2';

module.exports = node;
