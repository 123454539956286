// @flow

import { flow, keyBy, mapValues } from 'lodash/fp'

// turns a list of policies into a hash with the format
// { policy['name']: policy['enabled'] }
export const buildPolicyHash = (
  policies: $ReadOnlyArray<{ +enabled: boolean, +name: string }>,
): { [key: string]: boolean } =>
  flow(keyBy('name'), mapValues('enabled'))(policies)
