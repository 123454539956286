// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { labelStyles, sizeStyle, wrapperStyle } from './RadioButton.style'

type PropsType = {
  +ariaLabel?: string,
  +dataTestId?: string,
  +inputAttributes: {
    +checked?: boolean,
    +disabled?: boolean,
    +id: string,
    +label: string,
    +name: string,
    +onChange?: (e: SyntheticInputEvent<>) => void,
    +type?: 'radio' | 'checkbox',
    +value?: string,
  },
  size?: 'lg' | 'md',
}

const RadioButton = (props: PropsType) => {
  const { inputAttributes, dataTestId, ariaLabel, size = "md" } = props
  const {
    id,
    name,
    label,
    value,
    checked = false,
    onChange,
    type = 'radio',
    disabled,
  } = inputAttributes

  const { css } = useFela({ ...inputAttributes, size })

  return (
    <div
      data-testid={dataTestId}
      aria-label={ariaLabel}
      className={css(sizeStyle, wrapperStyle)}
    >
      <input
        type={type}
        id={id}
        name={name}
        value={value}
        checked={checked}
        onChange={onChange}
        disabled={disabled}
        role={type}
      />

      <label htmlFor={id} className={css(labelStyles)} aria-labelledby={id}>
        {label}
      </label>
    </div>
  )
}

export default RadioButton
