// @flow

// Once Internationalization is implemented,
// these language text need to be localized,
// this list of languages match with the available labguage supported by platfom
const SUPPORTED_LANGUAGES = {
  en: 'English',
  fr: 'French',
}

export const getLanguageFromLocale = (locale: string = 'en') => {
  return SUPPORTED_LANGUAGES[locale]
}
