//  @flow

import { useSelector } from 'react-redux'
import useRouter from 'found/useRouter'

import exactlyOne from 'shared/services/exactlyOne'

type PropTypes = {
  answereeUserId?: string,
  answererRoleType?: string,
  assessmentRequestId?: string,
}

export type GoToQuestionnaireInputType = {
  questionSetId?: string,
  questionnaireId?: string,
}

export function useRouteQuestionnaire({
  answereeUserId: answeree_id,
  assessmentRequestId,
}: PropTypes) {
  const { router: { push } } = useRouter()
  const { roleType } = useSelector(state => state.currentUser)

  return ({ questionnaireId, questionSetId }: GoToQuestionnaireInputType) => {
    let route

    if (!exactlyOne(questionSetId, questionnaireId)) {
      throw new Error(
        'Only one of questionSetId OR questionnaireId must be included',
      )
    }

    // TODO: fix up the params in the route config so they are all named the same thing

    if (questionSetId) {
      switch (roleType) {
        case 'CLINICIAN':
          route = {
            name: 'user_questionnaires_question_set_answer_by_clinician',
            params: {
              id: answeree_id,
              questionnaires_question_set_id: questionSetId,
              answeree_id,
            },
          }
          break
        case 'SUPPORT_PERSON':
          route = {
            name: 'user_questionnaires_question_set_answer',
            params: {
              id: answeree_id,
              questionnaires_question_set_id: questionSetId,
              answeree_id,
            },
          }
          break
        case 'INDIVIDUAL':
        default:
          route = {
            name: 'user_questionnaires_question_set_answer',
            params: {
              questionnaires_question_set_id: questionSetId,
              answeree_id,
            },
          }
      }
    }

    if (questionnaireId) {
      switch (roleType) {
        case 'CLINICIAN':
          route = {
            name: 'specificQuestionnaireShow',
            params: {
              id: answeree_id,
              questionnaire_id: questionnaireId,
              answeree_id,
            },
          }
          break
        case 'SUPPORT_PERSON':
          route = {
            name: 'supportPersonQuestionnaireShow',
            params: {
              id: answeree_id,
              questionnaire_id: questionnaireId,
              answeree_id,
            },
          }
          break
        case 'INDIVIDUAL':
        default:
          route = {
            name: 'questionnaireShow',
            params: { id: questionnaireId, answeree_id, assessmentRequestId },
          }
      }
    }

    push(route)
  }
}
