// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const ChatIconIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        id="chatIcon"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <path
            d="M20.3636364,15.4097661 L25.4545455,18.8037055 L25.4545455,8.28720358 L20.3636364,11.681143 L20.3636364,15.4097661 Z M20.3636364,8.62188733 L26.0212907,4.8501178 C26.8670876,4.28625319 28,4.89256949 28,5.90909091 L28,21.1818182 C28,22.1983396 26.8670876,22.8046559 26.0212907,22.2407913 L20.3636364,18.4690218 L20.3636364,19.9040445 C20.3636364,21.6616368 18.9368709,23.0909091 17.1840515,23.0909091 L3.17958485,23.0909091 C1.42191256,23.0909091 0,21.6647392 0,19.9040445 L0,7.18686461 C0,5.42927228 1.42676545,4 3.17958485,4 L17.1840515,4 C18.9417238,4 20.3636364,5.42616988 20.3636364,7.18686461 L20.3636364,8.62188733 Z M2.54545455,7.18686461 L2.54545455,19.9040445 C2.54545455,20.2606242 2.82943463,20.5454545 3.17958485,20.5454545 L17.1840515,20.5454545 C17.5300539,20.5454545 17.8181818,20.2568204 17.8181818,19.9040445 L17.8181818,7.18686461 C17.8181818,6.83028486 17.5342017,6.54545455 17.1840515,6.54545455 L3.17958485,6.54545455 C2.83358244,6.54545455 2.54545455,6.83408866 2.54545455,7.18686461 Z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </BaseIcon>
  )
}
export default ChatIconIcon
