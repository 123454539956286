// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { capitalize } from 'lodash'

import InviteModal from 'components/Role/RoleInviteModal'
import { UserInviteFormLoader } from 'components/UserInviteForm/UserInviteForm'

import type { StaffInvite_staff_admin } from './__generated__/StaffInvite_staff_admin.graphql'

type PropsType = {
  queryType: string,
  relay: Object,
  staff_admin: StaffInvite_staff_admin,
}

const StaffInvite = ({
  queryType,
  relay: { environment },
  staff_admin: { role_type_options, tenants },
}: PropsType) => {
  // Localization
  const { t: translation } = useTranslation('staff')

  const translatedInviteType =
    queryType === 'employee' ? translation('employee') : translation('manager')

  if (role_type_options?.length === 0) return null

  return (
    <InviteModal
      heading={translation('invite_invite_type', {
        invite_type: capitalize(translatedInviteType),
      })}
      title={translation('invite_invite_type', {
        invite_type: capitalize(translatedInviteType),
      })}
      queryType={queryType}
    >
      {({ onFormSubmit }) => (
        <UserInviteFormLoader
          id="staffInviteForm"
          invitableRoleTypes={role_type_options}
          invitableUser={{
            email: '',
            roleType: role_type_options?.[0],
            tenantId: tenants?.[0].id,
            phone_number: '',
          }}
          onSubmit={onFormSubmit(environment)}
          tenants={tenants}
        />
      )}
    </InviteModal>
  )
}

export const StaffInviteLoader = createFragmentContainer(StaffInvite, {
  staff_admin: graphql`
    fragment StaffInvite_staff_admin on StaffAdmin {
      role_type_options(query_type: $query_type)
      tenants {
        id
        ...UserInviteForm_tenants
      }
    }
  `,
})
