// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { StaffIndividualsListLoader } from 'components/Staff/StaffIndividualsList'
import { ActionsDropDown } from 'react-ui/components/DropDown'

import type { StaffClinicianActions_clinician } from './__generated__/StaffClinicianActions_clinician.graphql'

type PropsType = {
  clinician: StaffClinicianActions_clinician,
  userId: ?string,
}

const StaffClinicianActions = (props: PropsType) => {
  const { userId, clinician } = props

  return (
    <ActionsDropDown componentId={userId} simple>
      <StaffIndividualsListLoader clinician={clinician} />
    </ActionsDropDown>
  )
}

export const StaffClinicianActionsLoader = createFragmentContainer(
  StaffClinicianActions,
  {
    clinician: graphql`
      fragment StaffClinicianActions_clinician on ClinicianRole {
        ...StaffIndividualsList_clinician
      }
    `,
  },
)
