// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const containerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '40px',
    paddingLeft: theme.care.spacing.sm,
    paddingRight: theme.care.spacing.sm,
    backgroundColor: theme.care.palette.surface.subtle,
    position: 'relative',
  }
}

export const linkStyle = (props: StyleProps) => {
  const { theme: { care } } = props

  return {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: care.spacing.sm,
    ...care.typography.desktop.bodySmBold,
    [care.breakpoints.queries.md]: {
      marginRight: care.spacing.xxs,
    },
  }
}

export const ctaStyle = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
