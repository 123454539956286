// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const CogIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style={{ enableBackground: 'new 0 0 42 42' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g>
          <g transform="translate(-898.000000, -365.000000)">
            <g transform="translate(436.000000, 196.000000)">
              <g transform="translate(430.000000, 148.000000)">
                <g transform="translate(32.000000, 21.000000)">
                  <g>
                    <g>
                      <g>
                        <path
                          d="M39.8,15.9c1.3,0.3,2.2,1.4,2.2,2.6l0,5c0,1.3-0.9,2.4-2.2,2.7l-2.1,0.4c-0.3,0.9-0.6,1.7-1,2.5
                          l1.1,1.6c0.9,1.1,0.8,2.7-0.3,3.6l-3.4,3.3c-1,1.1-2.5,1.2-3.6,0.4L29,36.7c-0.8,0.4-1.6,0.8-2.5,1l-0.4,2.1
                          c-0.3,1.3-1.4,2.1-2.6,2.1l-5,0c-1.3,0-2.4-0.9-2.7-2.2l-0.4-2.1c-0.9-0.3-1.7-0.6-2.5-1l-1.6,1.1c-1.1,0.9-2.6,0.7-3.5-0.3
                          l-3.4-3.4c-1.1-1-1.2-2.5-0.3-3.5L5.3,29c-0.4-0.8-0.8-1.6-1-2.5l-2.1-0.4C0.9,25.9,0,24.8,0,23.5l0-5
                          c0-1.3,0.9-2.4,2.2-2.7l2.1-0.4c0.3-0.9,0.6-1.7,1-2.5l-1.1-1.6c-0.9-1.1-0.7-2.6,0.3-3.5l3.4-3.3c1-1.1,2.5-1.2,3.6-0.4
                          L13,5.3c0.8-0.4,1.6-0.8,2.5-1l0.4-2.1C16.1,0.9,17.2,0,18.5,0l5,0c1.3,0,2.4,0.9,2.7,2.2l0.4,2.1c0.9,0.3,1.7,0.6,2.5,1
                          l1.6-1.1c1.1-0.9,2.6-0.7,3.5,0.3l3.4,3.4c1,0.9,1.1,2.5,0.3,3.5L36.7,13c0.4,0.8,0.8,1.6,1,2.5L39.8,15.9z M30.5,4.2
                          C30.5,4.2,30.5,4.2,30.5,4.2C30.5,4.2,30.5,4.2,30.5,4.2z M38.5,18.4C38.5,18.5,38.5,18.5,38.5,18.4L38.5,18.4z M34.9,18.6
                          l-0.3-1.1c-0.3-1.3-0.8-2.5-1.5-3.6l-0.6-1l2.1-3l-2.5-2.5l-3,2.1l-1-0.6c-1.1-0.7-2.3-1.2-3.6-1.5l-1.1-0.3l-0.2-1.1
                          l-0.4-2.4l-3.6,0l-0.6,3.6l-1.1,0.3c-1.3,0.3-2.5,0.8-3.6,1.5l-1,0.6l-3-2.1L7.4,9.9l2.1,3l-0.6,1c-0.7,1.1-1.2,2.3-1.5,3.6
                          l-0.3,1.1l-1.1,0.2l-2.4,0.4l0,3.6l3.6,0.6l0.3,1.1c0.3,1.3,0.8,2.5,1.5,3.6l0.6,1l-2.1,3l2.5,2.5l3-2.1l1,0.6
                          c1.1,0.7,2.3,1.2,3.6,1.5l1.1,0.3l0.2,1.1l0.4,2.4l3.6,0l0.6-3.6l1.1-0.3c1.3-0.3,2.5-0.8,3.6-1.5l1-0.6l3,2.1l2.5-2.5
                          l-2.1-3l0.6-1c0.7-1.1,1.2-2.3,1.5-3.6l0.3-1.1l1.1-0.2l2.4-0.4l0-3.6L34.9,18.6z M38.5,23.5L38.5,23.5
                          C38.5,23.5,38.5,23.5,38.5,23.5z M35.1,32.8C35.2,32.9,35.2,32.9,35.1,32.8C35.1,32.9,35.1,32.8,35.1,32.8
                          C35.1,32.8,35.1,32.8,35.1,32.8z M35.2,32.9C35.2,32.9,35.2,32.9,35.2,32.9L35.2,32.9z M23.6,38.5
                          C23.5,38.5,23.5,38.5,23.6,38.5L23.6,38.5z M9.2,35.1c-0.1,0.1-0.1,0.1-0.1,0.1L9.2,35.1C9.3,35.1,9.3,35.1,9.2,35.1z
                           M10.5,35.3C10.5,35.2,10.4,35.2,10.5,35.3L10.5,35.3z M6.9,32.8L6.9,32.8C6.9,32.8,6.9,32.8,6.9,32.8z M3.5,18.5L3.5,18.5
                          C3.5,18.5,3.5,18.5,3.5,18.5z M6.7,10.5C6.7,10.5,6.8,10.4,6.7,10.5L6.7,10.5z M10.4,6.8C10.5,6.7,10.5,6.7,10.4,6.8
                          C10.5,6.7,10.5,6.7,10.4,6.8C10.4,6.8,10.4,6.8,10.4,6.8z M9.1,6.8C9.2,6.9,9.2,6.9,9.1,6.8L9.1,6.8z M23.5,3.5L23.5,3.5
                          C23.5,3.5,23.5,3.5,23.5,3.5z M32.8,6.9c0.1-0.1,0.1-0.1,0.1-0.1C32.9,6.8,32.9,6.8,32.8,6.9L32.8,6.9L32.8,6.9
                          C32.8,6.9,32.8,6.9,32.8,6.9z M32.9,6.8C32.9,6.8,32.9,6.8,32.9,6.8C32.9,6.8,32.9,6.8,32.9,6.8L32.9,6.8z M31.6,6.8
                          L31.6,6.8C31.5,6.7,31.6,6.8,31.6,6.8z M35.2,10.4C35.2,10.5,35.2,10.5,35.2,10.4L35.2,10.4C35.2,10.4,35.2,10.4,35.2,10.4z
                          "
                        />
                      </g>
                    </g>
                    <g>
                      <path
                        d="M24.5,21c0-1.9-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5c0,1.9,1.6,3.5,3.5,3.5C22.9,24.5,24.5,22.9,24.5,21
                        z M21,28c-3.9,0-7-3.1-7-7s3.1-7,7-7s7,3.1,7,7C27.9,24.9,24.9,27.9,21,28z"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default CogIcon
