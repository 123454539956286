/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DataTableType = "clinician" | "employee" | "individual" | "%future added value";
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RolesReintroduceInput = {|
  clientMutationId?: ?string,
  roles: IndividualsSelectionInput,
|};
export type IndividualsSelectionInput = {|
  dataTableType: DataTableType,
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?IndividualsFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type IndividualsFilterInput = {|
  assignedToMe?: ?boolean,
  filterEscalation?: ?boolean,
  helpRequested?: ?boolean,
  prioritiseEscalations?: ?boolean,
  roleStatus?: ?$ReadOnlyArray<string>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type ReinviteRolesMutationVariables = {|
  input: RolesReintroduceInput
|};
export type ReinviteRolesMutationResponse = {|
  +rolesReintroduce: ?{|
    +success: boolean,
    +active_individuals_in_another_tenant: ?$ReadOnlyArray<string>,
  |}
|};
export type ReinviteRolesMutation = {|
  variables: ReinviteRolesMutationVariables,
  response: ReinviteRolesMutationResponse,
|};
*/


/*
mutation ReinviteRolesMutation(
  $input: RolesReintroduceInput!
) {
  rolesReintroduce(input: $input) {
    success
    active_individuals_in_another_tenant
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RolesReintroducePayload",
    "kind": "LinkedField",
    "name": "rolesReintroduce",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "active_individuals_in_another_tenant",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ReinviteRolesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ReinviteRolesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "6c6c1afc7f90528038f431f0b3ef241f",
    "id": null,
    "metadata": {},
    "name": "ReinviteRolesMutation",
    "operationKind": "mutation",
    "text": "mutation ReinviteRolesMutation(\n  $input: RolesReintroduceInput!\n) {\n  rolesReintroduce(input: $input) {\n    success\n    active_individuals_in_another_tenant\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '81be1f060767a6cae7d5ee4822d419bc';

module.exports = node;
