// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const markdownStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    marginLeft: '1rem',
    lineHeight: '1rem',
    ...theme.care.typography.desktop.bodyLg,
  }
}

export const accordionItemStyle = (theme: ThemeType, isOpen: boolean) => ({
  AccordionItem: {
    backgroundColor: 'white',
  },
  header: {
    backgroundColor: theme.palette.lightBackground,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    paddingBottom: '1rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    ...theme.care.typography.desktop.bodyLgBold,
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.lightBackground,
    paddingTop: '0.1rem',
    paddingBottom: '1rem',
    borderRadius: '0 0 4px 4px',
  },
})
