// @flow

import React from 'react'
import { connect } from 'react-fela'
import { createFragmentContainer, graphql } from 'react-relay'

import { SmallNotification } from 'react-ui/components/Notification'
import { QuestionnaireRefreshLoader } from 'platform_web/components/QuestionnaireRefresh'
import { usePolicies } from 'platform_web/hooks/usePolicies'

import type { FelaPropsType } from 'react-ui/typing'
import type { TrackableResultCardAlerts_user_trackable } from './__generated__/TrackableResultCardAlerts_user_trackable.graphql'

const styleRules = () => ({
  EmptyAlertBox: {
    width: '100%',
    height: '21px',
  },
})

type TrackableResultCardAlertsType = FelaPropsType & {
  color: string,
  title: string,
  user_trackable: TrackableResultCardAlerts_user_trackable,
}
const TrackableResultCardAlerts = ({
  user_trackable,
  styles,
}: TrackableResultCardAlertsType) => {
  const policies = usePolicies()
  if (!policies.CAN_CREATE_USER_QUESTIONNAIRE) return null

  const { primary_user_questionnaire, refresh_required } = user_trackable || {}
  const { questionnaire } = primary_user_questionnaire || {}

  return refresh_required && questionnaire ? (
    <QuestionnaireRefreshLoader
      questionnaire={questionnaire}
      cta={
        <SmallNotification
          dataComponentId="card_update"
          description="Update answers"
          small
        />
      }
    />
  ) : (
    <div className={styles.EmptyAlertBox} />
  )
}

export default createFragmentContainer(
  connect(styleRules)(TrackableResultCardAlerts),
  {
    user_trackable: graphql`
      fragment TrackableResultCardAlerts_user_trackable on UserTrackable {
        primary_user_questionnaire {
          questionnaire {
            id
            ...QuestionnaireRefresh_questionnaire
          }
        }
        refresh_required
      }
    `,
  },
)
