/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserSettingsPersonalDetailsPage_profileQuestionnaire$ref = any;
type UserSettingsPersonalDetailsPage_userFields$ref = any;
export type UserSettingsPersonalDetailsPage_QueryVariables = {||};
export type UserSettingsPersonalDetailsPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +profileQuestionnaire: ?{|
        +$fragmentRefs: UserSettingsPersonalDetailsPage_profileQuestionnaire$ref
      |},
      +$fragmentRefs: UserSettingsPersonalDetailsPage_userFields$ref,
    |}
  |}
|};
export type UserSettingsPersonalDetailsPage_Query = {|
  variables: UserSettingsPersonalDetailsPage_QueryVariables,
  response: UserSettingsPersonalDetailsPage_QueryResponse,
|};
*/


/*
query UserSettingsPersonalDetailsPage_Query {
  viewer {
    currentUser {
      ...UserSettingsPersonalDetailsPage_userFields
      profileQuestionnaire {
        ...UserSettingsPersonalDetailsPage_profileQuestionnaire
      }
      id
    }
    id
  }
}

fragment UserSettingsPersonalDetailsPage_profileQuestionnaire on SimpleQuestionnaire {
  questions {
    name
    label
    displayValue
  }
  orders
}

fragment UserSettingsPersonalDetailsPage_userFields on User {
  current_role {
    __typename
    ... on RoleInterface {
      __isRoleInterface: __typename
      id
      role_type
    }
    ... on AdminRole {
      id
    }
    ... on ClinicianRole {
      id
    }
    ... on IndividualRole {
      id
    }
    ... on LeadClinicianRole {
      id
    }
    ... on ManagerRole {
      id
    }
    ... on OwnerRole {
      id
    }
    ... on ResearcherRole {
      id
    }
    ... on SuperRole {
      id
    }
    ... on SupportPersonRole {
      id
    }
  }
  tenant {
    contract
    privacyUrl
    id
  }
  multi_roles_enabled
  profileFields {
    id
    label
    value
    noLink
    placeholder
  }
  unconfirmed_email
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsPersonalDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SimpleQuestionnaire",
                "kind": "LinkedField",
                "name": "profileQuestionnaire",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "UserSettingsPersonalDetailsPage_profileQuestionnaire"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserSettingsPersonalDetailsPage_userFields"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserSettingsPersonalDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role_type",
                        "storageKey": null
                      }
                    ],
                    "type": "RoleInterface",
                    "abstractKey": "__isRoleInterface"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "AdminRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "ClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "LeadClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "ManagerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "OwnerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "ResearcherRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "SuperRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v1/*: any*/),
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "contract",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "privacyUrl",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "multi_roles_enabled",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserField",
                "kind": "LinkedField",
                "name": "profileFields",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "noLink",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "placeholder",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "unconfirmed_email",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SimpleQuestionnaire",
                "kind": "LinkedField",
                "name": "profileQuestionnaire",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SimpleQuestion",
                    "kind": "LinkedField",
                    "name": "questions",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "name",
                        "storageKey": null
                      },
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "displayValue",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "orders",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8bc25ccf78d64ebc25fbe9dfcc4efe7e",
    "id": null,
    "metadata": {},
    "name": "UserSettingsPersonalDetailsPage_Query",
    "operationKind": "query",
    "text": "query UserSettingsPersonalDetailsPage_Query {\n  viewer {\n    currentUser {\n      ...UserSettingsPersonalDetailsPage_userFields\n      profileQuestionnaire {\n        ...UserSettingsPersonalDetailsPage_profileQuestionnaire\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment UserSettingsPersonalDetailsPage_profileQuestionnaire on SimpleQuestionnaire {\n  questions {\n    name\n    label\n    displayValue\n  }\n  orders\n}\n\nfragment UserSettingsPersonalDetailsPage_userFields on User {\n  current_role {\n    __typename\n    ... on RoleInterface {\n      __isRoleInterface: __typename\n      id\n      role_type\n    }\n    ... on AdminRole {\n      id\n    }\n    ... on ClinicianRole {\n      id\n    }\n    ... on IndividualRole {\n      id\n    }\n    ... on LeadClinicianRole {\n      id\n    }\n    ... on ManagerRole {\n      id\n    }\n    ... on OwnerRole {\n      id\n    }\n    ... on ResearcherRole {\n      id\n    }\n    ... on SuperRole {\n      id\n    }\n    ... on SupportPersonRole {\n      id\n    }\n  }\n  tenant {\n    contract\n    privacyUrl\n    id\n  }\n  multi_roles_enabled\n  profileFields {\n    id\n    label\n    value\n    noLink\n    placeholder\n  }\n  unconfirmed_email\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa92fa9e2c2a5234ac9b276d4696c1e5';

module.exports = node;
