// @flow

import * as React from 'react'
import createPlotlyComponent from 'react-plotly.js/factory'

import { loadPlotly } from 'shared/services/plotly'

type PropsType = {}

type StateType = {
  loaded: boolean,
}

export const LoadPlotlyWrapper = (WrappedComponent: React.ComponentType<any>) =>
  class extends React.Component<PropsType, StateType> {
    Plot: Object

    constructor() {
      super()
      this.state = this.getCleanState()
    }

    componentWillMount() {
      if (!window.Plotly) {
        loadPlotly(Plotly => {
          window.Plotly = Plotly
          this.Plot = createPlotlyComponent(window.Plotly)
          this.setState({ loaded: true })
        })
      } else {
        this.Plot = createPlotlyComponent(window.Plotly)
        this.setState({ loaded: true })
      }
    }

    getCleanState(): StateType {
      return {
        loaded: false,
      }
    }

    render(): React.Node {
      const { state: { loaded } } = this

      if (!loaded) {
        return 'Loading charting library...'
      }

      return <WrappedComponent {...this.props} Plot={this.Plot} />
    }
  }
