// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  ClinicianIndividualAssignmentCreateMutationResponse,
  ClinicianIndividualAssignmentCreateMutationVariables,
} from './__generated__/ClinicianIndividualAssignmentCreateMutation.graphql'

const mutation = graphql`
  mutation ClinicianIndividualAssignmentCreateMutation(
    $individual_id: ID!
    $clinician_id: ID!
    $input: ClinicianIndividualAssignmentCreateInput!
  ) {
    clinicianIndividualAssignmentCreate(input: $input) {
      clinician_assignment {
        individual {
          assigned_to_clinician(clinician_id: $clinician_id)
          duty_of_care_clinicians {
            ...AvatarListLoader_data
          }
          clinicians {
            ...AvatarListLoader_data
          }
        }
        clinician {
          id
          assigned_to_individual(individual_id: $individual_id)
          clinician_assignments_as_clinician {
            id
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
  variables: { input: { clinician_assignment: { individual_id } } },
}: {
  environment: Object,
  onCompleted?: (
    result: ClinicianIndividualAssignmentCreateMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: ClinicianIndividualAssignmentCreateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables: {
      ...variables,
      individual_id,
    },
  })
}
