//  @flow

import { map, merge } from 'lodash/fp'

function roleToRouteName(role) {
  switch (role) {
    case 'INDIVIDUAL':
      return 'settings_personal_details_field'
    case 'CLINICIAN':
    case 'LEAD_CLINICIAN':
      return 'settings_clinician_detail_field'
    default:
      return 'settings_personal_details_field'
  }
}

export const fieldLinkGenerator = (
  name: string,
  requiredPolicy: string,
  fields: $ReadOnlyArray<{ +id: string, +noLink: ?boolean }>,
) =>
  map(field => {
    if (field.noLink) return field

    const fieldTo = {
      requiredPolicy,
      name,
      params: {
        field: field.id,
      },
    }

    // If a specific role or policy was specified for the field via GraphQL, use them instead of the defaults.
    if (field.role) {
      fieldTo.name = roleToRouteName(field.role)
    }

    if (field.requiredPolicy) {
      fieldTo.requiredPolicy = field.requiredPolicy
    }

    return merge({
      to: fieldTo,
    })(field)
  })(fields)
