// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavLink__root____diIl{display:block;width:100%;font-size:1.125rem;font-weight:500;color:#0f77d9}@media(min-width: 809px){.NavLink__root____diIl.NavLink__active___GEQol{color:#024490;font-weight:700}.NavLink__root____diIl.NavLink__active___GEQol::after{content:" ";width:1.5rem;height:1.5rem;background-color:#e7e9ee;display:inline;position:absolute;right:-1.9rem;transform:rotate(-45deg);box-shadow:inset .3rem .3rem .2rem -0.2rem #a3adbb;padding:.1rem}}.NavLink__caret___Ukxaw{margin-right:.2rem;margin-top:.3rem;float:right}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `NavLink__root____diIl`,
	"active": `NavLink__active___GEQol`,
	"caret": `NavLink__caret___Ukxaw`
};
export default ___CSS_LOADER_EXPORT___;
