/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AssignableIndividualsPagination_tenant$ref = any;
export type DataTableType = "clinician" | "employee" | "individual" | "%future added value";
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
export type RolesSelectionInput = {|
  dataTableType?: ?DataTableType,
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?RolesFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type RolesFilterInput = {|
  assignmentStatus?: ?boolean,
  excludeDischarged?: ?boolean,
  roleTypes?: ?$ReadOnlyArray<RoleTypes>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
  status?: ?$ReadOnlyArray<string>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type AssignableIndividualsPaginationQueryVariables = {|
  clinicians: RolesSelectionInput,
  tenant_id: string,
  count: number,
  cursor?: ?string,
  search?: ?string,
|};
export type AssignableIndividualsPaginationQueryResponse = {|
  +viewer: {|
    +$fragmentRefs: AssignableIndividualsPagination_tenant$ref
  |}
|};
export type AssignableIndividualsPaginationQuery = {|
  variables: AssignableIndividualsPaginationQueryVariables,
  response: AssignableIndividualsPaginationQueryResponse,
|};
*/


/*
query AssignableIndividualsPaginationQuery(
  $clinicians: RolesSelectionInput!
  $tenant_id: ID!
  $count: Int!
  $cursor: String
  $search: String
) {
  viewer {
    ...AssignableIndividualsPagination_tenant
    id
  }
}

fragment AssignableIndividualsPagination_tenant on Viewer {
  tenant(id: $tenant_id) {
    multi_select_assignable_individuals(search: $search, clinicians: $clinicians, first: $count, after: $cursor) {
      edges {
        node {
          email
          id
          user {
            id
            name
          }
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinicians"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenant_id"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "clinicians",
    "variableName": "clinicians"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AssignableIndividualsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "AssignableIndividualsPagination_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v4/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AssignableIndividualsPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "id",
                "variableName": "tenant_id"
              }
            ],
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v5/*: any*/),
                "concreteType": "IndividualRoleConnection",
                "kind": "LinkedField",
                "name": "multi_select_assignable_individuals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "IndividualRoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IndividualRole",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v5/*: any*/),
                "filters": [
                  "search",
                  "clinicians"
                ],
                "handle": "connection",
                "key": "AssignableIndividualsPagination_multi_select_assignable_individuals",
                "kind": "LinkedHandle",
                "name": "multi_select_assignable_individuals"
              },
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "775aeb48dc28cdae125edfe96a4f202d",
    "id": null,
    "metadata": {},
    "name": "AssignableIndividualsPaginationQuery",
    "operationKind": "query",
    "text": "query AssignableIndividualsPaginationQuery(\n  $clinicians: RolesSelectionInput!\n  $tenant_id: ID!\n  $count: Int!\n  $cursor: String\n  $search: String\n) {\n  viewer {\n    ...AssignableIndividualsPagination_tenant\n    id\n  }\n}\n\nfragment AssignableIndividualsPagination_tenant on Viewer {\n  tenant(id: $tenant_id) {\n    multi_select_assignable_individuals(search: $search, clinicians: $clinicians, first: $count, after: $cursor) {\n      edges {\n        node {\n          email\n          id\n          user {\n            id\n            name\n          }\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5184c4fd157fe696dcb6a551634c482c';

module.exports = node;
