// @flow

import { graphql } from 'react-relay'

export const cliniciansTablePaginatedQuery = graphql`
  query CliniciansTablePaginationQuery(
    $count: Int!
    $cursor: String
    $status: [String!]
    $search: String
    $sort: [RoleSortable!]
  ) {
    viewer {
      currentUser {
        ...CliniciansTable_user
      }
    }
  }
`

export const cliniciansTableFragment = {
  user: graphql`
    fragment CliniciansTable_user on User
      @refetchable(queryName: "CliniciansTablePaginationRefetchQuery") {
      current_role {
        ... on RoleInterface {
          id
          tenant {
            roles(
              roleTypes: [CLINICIAN]
              first: $count
              after: $cursor
              status: $status
              search: $search
              sort: $sort
            ) @connection(key: "CliniciansTable_roles") {
              edges {
                node {
                  ... on ClinicianRole {
                    id
                    legal_name
                    preferred_name
                    clinician_assignments_as_clinician {
                      id
                    }
                    user {
                      id
                      name
                      ...AvatarLoader_user
                      ...UserCardLoader_user
                      ...DataGridRowFooterContentLoader_user
                    }
                    ...StaffClinicianActions_clinician
                    ...StaffIndividualsList_clinician
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}
