// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { dividerStyle } from './Divider.style'

const Divider = () => {
  const { css } = useFela()

  return <div className={css(dividerStyle)} />
}

export default Divider
