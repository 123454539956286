// @flow

import React from 'react'

import { FlexContainer } from 'care-ui'

import TableSearchControl from '../components/TableSearchControl/TableSearchControl'
import TableSortControl from '../components/TableSortControl/TableSortControl'
import { type UseCliniciansTableReturnType } from '../hooks/useCliniciansTable'

const SORT_OPTIONS = [
  { header: 'name', label: 'Clinician name' },
  { header: 'individualAssignmentCount', label: 'Assigned' },
]

type PartialIndividualTableReturnType = $Shape<UseCliniciansTableReturnType>

type CliniciansTableControlsProps = {
  useCliniciansTablePayload: PartialIndividualTableReturnType,
}

const CliniciansTableControl = (props: CliniciansTableControlsProps) => {
  const { useCliniciansTablePayload } = props
  const { queryVariables, updateQueryVariables } = useCliniciansTablePayload

  return (
    <FlexContainer justifyContent="space-between" paddingX="xxs">
      <TableSortControl
        sortOptions={SORT_OPTIONS}
        queryVariables={queryVariables}
        updateQueryVariables={updateQueryVariables}
      />

      <TableSearchControl
        queryVariables={queryVariables}
        updateQueryVariables={updateQueryVariables}
      />
    </FlexContainer>
  )
}

export default CliniciansTableControl
