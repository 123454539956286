// @flow

import React from 'react'
import { connect } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import Search from 'react-ui/components/Search'

type PropsType = {
  children: ({ [searchResult: string]: Object }) => any,
  data: Array<Object>,
  searchFields: string[],
  styles?: { [className: string]: string },
}

const styleRules = () => ({
  searchResultContainer: {
    className: 'Search',
    marginTop: '14px',
    marginBottom: '14px',
    width: '100%',
    height: '200px',
    overflow: 'auto',

    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
})

const PopOverSearchContents = ({
  styles = {},
  data,
  searchFields,
  children,
}: PropsType) => (
  <Container>
    <Search data={data} fields={searchFields}>
      {({ data: searchResult }: Object) => (
        <div className={styles.searchResultContainer}>
          {children({ searchResult })}
        </div>
      )}
    </Search>
  </Container>
)

export default connect(styleRules)(PopOverSearchContents)
