/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SupportPersonAssignmentRelationship = "ALLIED_HEALTH_PROFESSIONAL" | "CARER" | "FRIEND" | "GRANDPARENT" | "GUARDIAN" | "OTHER" | "PARENT" | "PARTNER" | "RELATIVE" | "SIBLING" | "TEACHER" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ViewerEnumProvider_viewer$ref: FragmentReference;
declare export opaque type ViewerEnumProvider_viewer$fragmentType: ViewerEnumProvider_viewer$ref;
export type ViewerEnumProvider_viewer = {|
  +support_person_assignment_relationships: $ReadOnlyArray<SupportPersonAssignmentRelationship>,
  +enums: {|
    +answer_set: {|
      +skip_reason_list: $ReadOnlyArray<{|
        +key: string,
        +value: string,
      |}>
    |}
  |},
  +$refType: ViewerEnumProvider_viewer$ref,
|};
export type ViewerEnumProvider_viewer$data = ViewerEnumProvider_viewer;
export type ViewerEnumProvider_viewer$key = {
  +$data?: ViewerEnumProvider_viewer$data,
  +$fragmentRefs: ViewerEnumProvider_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ViewerEnumProvider_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "support_person_assignment_relationships",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Enums",
      "kind": "LinkedField",
      "name": "enums",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerSetSkipReasonEnumType",
          "kind": "LinkedField",
          "name": "answer_set",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectOption",
              "kind": "LinkedField",
              "name": "skip_reason_list",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '49e9c80e026e30006ef35ba4ee5589ad';

module.exports = node;
