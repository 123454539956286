// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Table__tableScroll___LID2D{overflow-x:scroll}.Table__table___Ze2Fn{width:100%;border-spacing:0;color:#5b6c84}.Table__table___Ze2Fn.Table__primary___mvZm_ .Table__thead___NUfpK tr{color:#0f77d9}.Table__table___Ze2Fn.Table__secondary___l3ya2 .Table__thead___NUfpK tr{color:#008479}.Table__thead___NUfpK tr th{position:relative;padding:.2rem 0;text-align:left}.Table__thead___NUfpK tr th::after{content:" ";display:block;width:90%;position:absolute;bottom:.2rem;height:.17111rem;background-color:#a3adbb}.Table__tbody___hyP6J tr{height:4rem;vertical-align:middle;background-color:#fff}.Table__tbody___hyP6J tr.Table__warning___Iyecm{border-left-style:solid;border-left-width:.25rem}.Table__tbody___hyP6J tr td{border-top:1px solid #d0d6de;padding:0 .6rem}.Table__tfoot___I9sFz tr{background:#fff}.Table__tfoot___I9sFz tr td{padding:.75rem;text-align:center}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tableScroll": `Table__tableScroll___LID2D`,
	"table": `Table__table___Ze2Fn`,
	"primary": `Table__primary___mvZm_`,
	"thead": `Table__thead___NUfpK`,
	"secondary": `Table__secondary___l3ya2`,
	"tbody": `Table__tbody___hyP6J`,
	"warning": `Table__warning___Iyecm`,
	"tfoot": `Table__tfoot___I9sFz`
};
export default ___CSS_LOADER_EXPORT___;
