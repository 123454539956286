// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const styleRules = (props: StyleProps) => {
  const { theme } = props

  return {
    displayInline: {
      display: 'flex',
      justifyContent: 'center',
      flexWrap: 'wrap',
      maxWidth: '67rem',
      margin: '1rem auto',
      [theme.breakpoints.queries.sm]: {
        flexWrap: 'nowrap',
        justifyContent: 'space-between',
      },
    },
  }
}
