/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserResource_viewer$ref: FragmentReference;
declare export opaque type UserResource_viewer$fragmentType: UserResource_viewer$ref;
export type UserResource_viewer = {|
  +currentUser: ?{|
    +id: string
  |},
  +$refType: UserResource_viewer$ref,
|};
export type UserResource_viewer$data = UserResource_viewer;
export type UserResource_viewer$key = {
  +$data?: UserResource_viewer$data,
  +$fragmentRefs: UserResource_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserResource_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e040a19d533ece99b1c35b3ed1f32290';

module.exports = node;
