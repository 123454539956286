/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ChartHeader_individual$ref = any;
type HealthCardsTab_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardPage_user$ref: FragmentReference;
declare export opaque type DashboardPage_user$fragmentType: DashboardPage_user$ref;
export type DashboardPage_user = {|
  +id: string,
  +tenant: ?{|
    +privacyUrl: string
  |},
  +unanswered_tenant_research_programs: $ReadOnlyArray<{|
    +id: string,
    +research_program: {|
      +id: string,
      +title: string,
      +description: string,
    |},
  |}>,
  +linked_individual: ?{|
    +assessment_requested: {|
      +nodes: ?$ReadOnlyArray<?{|
        +id: string,
        +questionnaire_answer_sets: $ReadOnlyArray<{|
          +id: string
        |}>,
        +acknowledged: boolean,
      |}>
    |},
    +initial_questionnaire_status: string,
    +has_incomplete_summary_questionnaire: boolean,
    +individual_detail: ?{|
      +read_only: boolean
    |},
    +tenant: ?{|
      +summary_questionnaire: ?{|
        +id: string
      |},
      +initial_questionnaire: {|
        +id: string
      |},
    |},
    +$fragmentRefs: ChartHeader_individual$ref,
  |},
  +$fragmentRefs: HealthCardsTab_user$ref,
  +$refType: DashboardPage_user$ref,
|};
export type DashboardPage_user$data = DashboardPage_user;
export type DashboardPage_user$key = {
  +$data?: DashboardPage_user$data,
  +$fragmentRefs: DashboardPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DashboardPage_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantResearchProgram",
      "kind": "LinkedField",
      "name": "unanswered_tenant_research_programs",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ResearchProgram",
          "kind": "LinkedField",
          "name": "research_program",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "linked_individual",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "first",
              "value": 1
            }
          ],
          "concreteType": "AssessmentRequestConnection",
          "kind": "LinkedField",
          "name": "assessment_requested",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "AssessmentRequest",
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "QuestionnaireAnswerSet",
                  "kind": "LinkedField",
                  "name": "questionnaire_answer_sets",
                  "plural": true,
                  "selections": (v1/*: any*/),
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "acknowledged",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": "assessment_requested(first:1)"
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "initial_questionnaire_status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "has_incomplete_summary_questionnaire",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualDetail",
          "kind": "LinkedField",
          "name": "individual_detail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "read_only",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Questionnaire",
              "kind": "LinkedField",
              "name": "summary_questionnaire",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Questionnaire",
              "kind": "LinkedField",
              "name": "initial_questionnaire",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChartHeader_individual"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthCardsTab_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '2c1d41ea8de922bc1d1e80e527b7110c';

module.exports = node;
