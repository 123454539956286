// @flow

import { graphql } from 'react-relay'

export const assessmentRequestsTableBaseAnswers = {
  answers: graphql`
    fragment AssessmentRequestsAnswerTableBase_answers on AnswerInterface
      @relay(plural: true) {
      id
      display_value
      question {
        label
      }
    }
  `,
}
