/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ResearchDataSharingPage_user_research_programs_responses$ref = any;
export type ResearchDataSharingPage_QueryVariables = {||};
export type ResearchDataSharingPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +user_research_programs_responses: $ReadOnlyArray<{|
        +$fragmentRefs: ResearchDataSharingPage_user_research_programs_responses$ref
      |}>
    |}
  |}
|};
export type ResearchDataSharingPage_Query = {|
  variables: ResearchDataSharingPage_QueryVariables,
  response: ResearchDataSharingPage_QueryResponse,
|};
*/


/*
query ResearchDataSharingPage_Query {
  viewer {
    currentUser {
      user_research_programs_responses {
        ...ResearchDataSharingPage_user_research_programs_responses
        id
      }
      id
    }
    id
  }
}

fragment ResearchDataSharingPage_user_research_programs_responses on UserResearchProgramsResponse {
  id
  user_id
  tenant_research_program_id
  accepted
  tenant_research_program {
    research_program {
      title
      description
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResearchDataSharingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserResearchProgramsResponse",
                "kind": "LinkedField",
                "name": "user_research_programs_responses",
                "plural": true,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ResearchDataSharingPage_user_research_programs_responses"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ResearchDataSharingPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserResearchProgramsResponse",
                "kind": "LinkedField",
                "name": "user_research_programs_responses",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "tenant_research_program_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accepted",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TenantResearchProgram",
                    "kind": "LinkedField",
                    "name": "tenant_research_program",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ResearchProgram",
                        "kind": "LinkedField",
                        "name": "research_program",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v0/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "3e6b388c166793007c323580c7dc268d",
    "id": null,
    "metadata": {},
    "name": "ResearchDataSharingPage_Query",
    "operationKind": "query",
    "text": "query ResearchDataSharingPage_Query {\n  viewer {\n    currentUser {\n      user_research_programs_responses {\n        ...ResearchDataSharingPage_user_research_programs_responses\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment ResearchDataSharingPage_user_research_programs_responses on UserResearchProgramsResponse {\n  id\n  user_id\n  tenant_research_program_id\n  accepted\n  tenant_research_program {\n    research_program {\n      title\n      description\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '16ecfb901931df9cb7cbc10afd263d14';

module.exports = node;
