// @flow

import React from 'react'
import Helmet from 'react-helmet'

const AppMetadata = () => (
  <Helmet>
    <link rel="manifest" href="/manifest.json" />
    <meta name="theme-color" content="#0075c9" />
    <meta name="msapplication-TileColor" content="#ffffff" />
    <meta
      name="msapplication-TileImage"
      content="/images/icons/ms-icon-144x144.png"
    />
    <link
      rel="apple-touch-icon"
      sizes="180x180"
      href="/images/icons/apple-icon-180x180.png"
    />

    <link rel="icon" type="image/png" href="/images/icons/favicon.ico" />
  </Helmet>
)

export default AppMetadata
