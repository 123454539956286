// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const pageStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    backgroundColor: theme.palette.bodyBackgroundBlue,
    height: 'calc(100vh - 4.5rem)',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
}

export const cardStyle = () => ({
  textAlign: 'center',
  display: 'flex',
  alignItems: 'center',
  width: 'auto',
  maxWidth: '480px',
  minHeight: '400px',
  justifyContent: 'center',
})

export const cardHeaderStyle = () => ({
  maxWidth: '400px',
})

export const messageStyle = () => ({
  fontSize: '1.5rem',
  marginTop: '0',
  marginBottom: '2rem',
  padding: '1rem',
})
