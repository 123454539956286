// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import Markdown from 'react-ui/components/Markdown'
import { BasicTable } from 'shared/ui/Tables'

import { headingStyle, rowStyle, tableStyle } from './AnswerTable.style'
import { answerTableAnswers } from './query/AnswerTable'

import type { AnswerTable_answers } from './query/__generated__/AnswerTable_answers.graphql'

type PropsType = {
  answers: AnswerTable_answers,
}

const displayValueFormatter = value => {
  try {
    const arrayValue = JSON.parse(value)
    return arrayValue.join(', ')
  } catch {
    return value
  }
}

const AnswerTableBase = (props: PropsType) => {
  const { answers } = props
  const { css } = useFela()

  const { t: translation } = useTranslation('shared')

  return (
    <BasicTable
      className={css(tableStyle)}
      data={answers}
      renderHeaders={() => (
        <tr className={css(headingStyle)}>
          <th colSpan={2}>{translation('question')}</th>

          <th>{translation('answer')}</th>
        </tr>
      )}
    >
      {(answer, k) => (
        <tr className={css(rowStyle)} key={answer.id}>
          <td>{k + 1}</td>

          <td data-testid="Question">
            <Markdown source={answer.question.label} />
          </td>

          <td data-testid="Answer">
            <Markdown source={displayValueFormatter(answer.display_value)} />
          </td>
        </tr>
      )}
    </BasicTable>
  )
}

export const AnswerTableLoader = createFragmentContainer(
  AnswerTableBase,
  answerTableAnswers,
)
