// @flow

type ThemeType = { [string]: any }

type SizeStyleProps = {
  size?: 'lg' | 'md',
  theme: ThemeType,
}

export const sizeStyle = (props: SizeStyleProps) => {
  const { size, theme } = props

  // large size
  if (size === 'lg') {
    return {
      ...theme.care.typography.desktop.bodyLg,
    }
  }

  // medium size | Default
  return {
    ...theme.care.typography.desktop.bodyMd,
  }
}

export const wrapperStyle = ({
  theme,
}: {
  disabled?: boolean,
  theme: ThemeType,
}) => {
  return {
    cursor: 'pointer',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    display: 'flex',
    alignItems: 'center',

    '& input': {
      appearance: 'none',
      cursor: 'pointer',
      height: theme.care.spacing.sm,
      width: theme.care.spacing.sm,
      borderRadius: theme.care.radius.pill,
      border: `1px solid ${theme.care.palette.border.default}`,
      padding: '2px',
      alignSelf: 'baseline',
    },

    '& input:checked': {
      backgroundColor: theme.care.palette.surface.accent,
      backgroundClip: 'content-box',
    },

    '& input:indeterminate': {
      backgroundColor: 'transparent',
    },

    '& input:disabled': {
      borderColor: theme.care.palette.text.disabled,
      cursor: 'default',
    },
  }
}

export const labelStyles = ({
  disabled,
  theme,
}: {
  disabled?: boolean,
  theme: ThemeType,
}) => {
  const disabledStyle = {}

  if (disabled) {
    disabledStyle.color = theme.care.palette.text.disabled
    disabledStyle.cursor = 'default'
  }

  return {
    cursor: 'pointer',
    color: theme.care.palette.text.positive,
    marginLeft: theme.care.spacing.xxs,
    ...disabledStyle,
  }
}
