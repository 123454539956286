/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSettingsPersonalDetailsPage_userFields$ref: FragmentReference;
declare export opaque type UserSettingsPersonalDetailsPage_userFields$fragmentType: UserSettingsPersonalDetailsPage_userFields$ref;
export type UserSettingsPersonalDetailsPage_userFields = {|
  +current_role: {|
    +id?: string,
    +role_type?: RoleTypes,
  |},
  +tenant: ?{|
    +contract: ?string,
    +privacyUrl: string,
  |},
  +multi_roles_enabled: ?boolean,
  +profileFields: $ReadOnlyArray<{|
    +id: string,
    +label: string,
    +value: ?string,
    +noLink: ?boolean,
    +placeholder: ?string,
  |}>,
  +unconfirmed_email: ?string,
  +$refType: UserSettingsPersonalDetailsPage_userFields$ref,
|};
export type UserSettingsPersonalDetailsPage_userFields$data = UserSettingsPersonalDetailsPage_userFields;
export type UserSettingsPersonalDetailsPage_userFields$key = {
  +$data?: UserSettingsPersonalDetailsPage_userFields$data,
  +$fragmentRefs: UserSettingsPersonalDetailsPage_userFields$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingsPersonalDetailsPage_userFields",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "role_type",
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "contract",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "privacyUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "multi_roles_enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserField",
      "kind": "LinkedField",
      "name": "profileFields",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "noLink",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "placeholder",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "unconfirmed_email",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '46ea57237739757475a981e9e3a6c9cc';

module.exports = node;
