// @flow

import React, { type Node, useState } from 'react'
import { useFela } from 'react-fela'

import createComponentId from 'shared/services/id'
import { type TraceType } from 'platform_web/services/plotly'
import { Button } from 'care-ui'

type PropsType = {
  as?: string,
  children: (data: $ReadOnlyArray<any>) => Node,
  componentId?: string,
  itemsPerPage?: number,
  onRequestNextPage?: () => void,
  +traces: $ReadOnlyArray<TraceType>,
}

const moreButtonStyles = ({ theme }) => ({
  marginTop: theme.spacing(0.5),
})

const defaultId = createComponentId(__filename)

const TruncatedTraces = ({
  children,
  componentId = defaultId,
  traces,
  onRequestNextPage,
}: PropsType) => {
  const { css } = useFela()

  return (
    <React.Fragment>
      {children(traces)}
      {!!onRequestNextPage && (
        <center className={css(moreButtonStyles)}>
          <Button
            variant="secondary"
            componentId={`${componentId}Button`}
            onClick={onRequestNextPage}
            dataTestId="show-more"
          >
            Show more
          </Button>
        </center>
      )}
    </React.Fragment>
  )
}

const EnhancedTruncatedTraces = ({
  traces,
  itemsPerPage = 10,
  ...props
}: PropsType) => {
  const [page, setPage] = useState(1)
  const onRequestNextPage = () => setPage(prev => prev + 1)

  const traceCount: number = traces.reduce(
    (memo, { x }: TraceType) => Math.max(memo, x.length),
    0,
  )
  const numberOfItems = Math.min(itemsPerPage * page, traceCount)
  const truncatedData = traces.map(({ x, y, ...trace }) => ({
    ...trace,
    y: y.slice(0, numberOfItems),
    x: x.slice(0, numberOfItems),
  }))

  return (
    <TruncatedTraces
      {...props}
      onRequestNextPage={
        numberOfItems < traceCount ? onRequestNextPage : undefined
      }
      traces={truncatedData}
    />
  )
}

export default EnhancedTruncatedTraces
