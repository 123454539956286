// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RemoveIndividualMutationResponse,
  RemoveIndividualMutationVariables,
} from './__generated__/RemoveIndividualMutation.graphql'

const mutation = graphql`
  mutation RemoveIndividualMutation($input: RoleRemoveInput!) {
    roleRemove(input: $input) {
      role {
        ... on IndividualRole {
          ...IndividualsTable_individual
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: RemoveIndividualMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: RemoveIndividualMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
