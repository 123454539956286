// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import HeroNumber from 'react-ui/components/HeroNumber'
import type { TraceType } from 'services/plotly'
import { Text } from 'care-ui'

import type { FelaOptionalPropsType } from 'react-ui/typing'

export type PropsType = FelaOptionalPropsType & {
  columnFormat?: {
    [key: string]: ?string,
  },
  +traces: $ReadOnlyArray<TraceType>,
  valueFormat?: {
    [key: string]: ?(value: number) => number | void,
  },
}

const styleRules = ({ theme, traces }) => {
  const numberOfColumns = traces.reduce(
    (memo, { x }) => Math.max(memo, x.length),
    0,
  )
  return {
    HeroTable: {
      boxSizing: 'border-box',
      className: 'HeroTable',
      color: theme.palette.reports.valueColor,
      marginBottom: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      marginTop: 0,
      width: '100%',
    },

    cell: {
      align: 'left',
      className: 'HeroTable__cell',
      textAlign: 'left',
      paddingBottom: 0,
      paddingLeft: 0,
      paddingRight: theme.Grid.gutter,
      paddingTop: 0,
      verticalAlign: 'baseline',
      width: `${100 / numberOfColumns}%`,
    },
  }
}

function formatValue(value, format) {
  if (format && value) {
    return format(value)
  }
  return value
}

const HeroTable = ({
  columnFormat = {},
  styles = {},
  traces,
  valueFormat = {},
}: PropsType) => (
  <table className={styles.HeroTable}>
    <tbody>
      {traces.map((trace, rowIndex) => (
        /* eslint-disable react/no-array-index-key */
        <tr key={rowIndex}>
          {trace.y.map((value, valueIndex) => (
            <td key={valueIndex} className={styles.cell}>
              <HeroNumber
                value={formatValue(value, valueFormat[String(valueIndex)])}
                variant="grande"
                format={columnFormat[String(valueIndex)]}
              />
            </td>
          ))}
        </tr>
        /* eslint-enable react/no-array-index-key */
      ))}
    </tbody>
    <tfoot>
      <tr>
        {traces[0].x.map(label => (
          <Text as="th" key={label} className={styles.cell}>
            {label}
          </Text>
        ))}
      </tr>
    </tfoot>
  </table>
)

export default connect(styleRules)(HeroTable)
