// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const User = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        width="70px"
        height="74px"
        viewBox="0 0 70 74"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g
            id="Desktop-HD"
            transform="translate(-151.000000, -152.000000)"
            fill="#FFFFFF"
          >
            <g id="Group-4" transform="translate(102.000000, 102.000000)">
              <g id="user" transform="translate(49.000000, 50.000000)">
                <path d="M53.2110818,17.4598862 C53.2110818,27.3538217 45.6451311,34.9197724 35.7511956,34.9197724 C25.85726,34.9197724 18.2913094,27.3538217 18.2913094,17.4598862 C18.2913094,7.56595069 25.85726,0 35.7511956,0 C45.6451311,0 53.2110818,7.56595069 53.2110818,17.4598862 L53.2110818,17.4598862 Z M34.9197724,43.2340039 C46.5596965,43.2340039 69.8395448,49.9259464 69.8395448,62.0931145 L69.8395448,64.1697238 C69.8395448,66.3827148 68.0429814,68.1766985 65.8338254,68.1766985 L4.0057194,68.1766985 C1.79342166,68.1766985 0,66.3843267 0,64.1697238 L0,62.0931145 C0,49.9259464 23.2798483,43.2340039 34.9197724,43.2340039 Z" />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default User
