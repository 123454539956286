// @flow

import * as React from 'react'
import ReactMarkdown from 'react-markdown'

import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'

type RenderersType = {
  text: (input: string) => React.Node,
  [key: string]: (*) => React.Node,
}

type LinkProps = {
  children: React.Node,
  href: string,
  textDecorationStyle: string,
}

type EnhancedProps = {
  renderers?: RenderersType,
  source: string,
  textDecorationStyle?: string,
}

const LinkRenderer = ({ children, href, ...linkProps }: LinkProps) => (
  <Link to={href} {...(linkProps: any)}>
    {children}
  </Link>
)

const Markdown = ({
  renderers,
  textDecorationStyle = '',
  ...props
}: EnhancedProps) => (
  <ReactMarkdown
    renderers={{
      heading: o => React.createElement(Heading, o),
      link: o => LinkRenderer({ textDecorationStyle, ...o }),
      linkReference: o => LinkRenderer({ textDecorationStyle, ...o }),
      ...(renderers: any),
    }}
    {...(props: any)}
  />
)

export default Markdown
