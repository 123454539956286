// @flow

import React, { type Node } from 'react'

import { ResponsiveLayout } from 'react-ui/components/Layout'
import Section from 'react-ui/components/Section'

export type QuestionnaireCompleteInfoType = {
  completedList?: Node,
  header?: Node,
  skippedList?: Node,
}

type PropsType = {
  info: QuestionnaireCompleteInfoType,
}

const IndividualQuestionnaireComplete = ({
  info: { header, completedList, skippedList },
}: PropsType) => {
  return (
    <ResponsiveLayout customHeader={header} hasEmptyHeader>
      <Section center color="white" noPadding>
        {skippedList}
        {completedList}
      </Section>
    </ResponsiveLayout>
  )
}

export default IndividualQuestionnaireComplete
