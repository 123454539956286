// @flow

import React from 'react'
import { connect } from 'react-fela'

import AlertCircle from 'react-ui/assets/icons/minor-alert-circle.svg'
import Icon from 'react-ui/components/Icon'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  dataComponentId: string,
  description: string,
  handleClick?: () => void,
  small: boolean,
  title: string,
}

const styleRules = ({ handleClick, small, theme }) => ({
  notification: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.component.primary.base,
    cursor: handleClick ? 'pointer' : 'inherit',
    lineHeight: '12px',
  },
  textWrapper: {
    float: 'none',
    fontSize: small ? theme.Small.fontSize : theme.Font.fontSize,
  },
  iconWrapper: {
    height: `${small ? theme.Small.lineHeight : theme.Font.lineHeight}em`,
    alignItems: 'center',
    display: 'flex',
    float: 'left',
  },
  icon: {
    height: small ? theme.Small.fontSize : theme.Font.fontSize,
    className: 'SmallNotification__icon',
  },
})

const notification = ({
  dataComponentId = 'SmallNotification',
  description,
  handleClick,
  rules,
  styles,
  title,
}: PropsType) => (
  <div
    className={styles.notification}
    data-component-id={dataComponentId}
    title={title}
    onClick={handleClick}
    onKeyPress={handleClick}
    role={handleClick ? 'button' : null}
  >
    <span className={styles.iconWrapper}>
      <Icon as={AlertCircle} extend={rules.icon} />
    </span>
    <span className={styles.textWrapper}>{description}</span>
  </div>
)

export default connect(styleRules)(notification)
