/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EmployeesTable_role$ref: FragmentReference;
declare export opaque type EmployeesTable_role$fragmentType: EmployeesTable_role$ref;
export type EmployeesTable_role = {|
  +id: string,
  +role_type: RoleTypes,
  +role_status: string,
  +description: string,
  +tenant: ?{|
    +name: string,
    +requires_mfa: boolean,
  |},
  +$refType: EmployeesTable_role$ref,
|};
export type EmployeesTable_role$data = EmployeesTable_role;
export type EmployeesTable_role$key = {
  +$data?: EmployeesTable_role$data,
  +$fragmentRefs: EmployeesTable_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EmployeesTable_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role_status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requires_mfa",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RoleInterface",
  "abstractKey": "__isRoleInterface"
};
// prettier-ignore
(node/*: any*/).hash = 'd08c01461f698d7424b56de87bf9e45b';

module.exports = node;
