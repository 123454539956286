/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserQuestionnaireQuestionSetFooter_answer_set$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnairesQuestionSet_answer_set$ref: FragmentReference;
declare export opaque type UserQuestionnairesQuestionSet_answer_set$fragmentType: UserQuestionnairesQuestionSet_answer_set$ref;
export type UserQuestionnairesQuestionSet_answer_set = {|
  +$fragmentRefs: UserQuestionnaireQuestionSetFooter_answer_set$ref,
  +$refType: UserQuestionnairesQuestionSet_answer_set$ref,
|};
export type UserQuestionnairesQuestionSet_answer_set$data = UserQuestionnairesQuestionSet_answer_set;
export type UserQuestionnairesQuestionSet_answer_set$key = {
  +$data?: UserQuestionnairesQuestionSet_answer_set$data,
  +$fragmentRefs: UserQuestionnairesQuestionSet_answer_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnairesQuestionSet_answer_set",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionnaireQuestionSetFooter_answer_set"
    }
  ],
  "type": "AnswerSetSkipReasonEnumType",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '114778388667857f994b088d7ba91f28';

module.exports = node;
