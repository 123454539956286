// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'
import { head, isEmpty } from 'lodash/fp'

import AssessmentRequestBanner from 'components/Dashboard/AssessmentRequestBanner'
import HealthCardsTabLoader from 'components/Dashboard/HealthCardsTab'
import QuestionnaireWarningBanner from 'components/Dashboard/QuestionnaireWarningBanner'
import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import { ChartHeader } from 'components/SummaryGraph'
import Modal from 'react-ui/components/Modal'
import Section from 'react-ui/components/Section'
import { AssessmentNotificationContextProvider } from 'react-ui/contexts/AssessmentNotificationContext'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { markDashboardQuestionnaireModalSeen } from 'redux/generic_ui/actions'
import createComponentId from 'shared/services/id'
import ResearchAndDataSharing from 'platform_web/components/ResearchAndDataSharing'
import useChartToggle from 'platform_web/hooks/useChartToggle'
import {
  notCompletedInitialQsPopUp,
  notCompletedSummaryPopUp,
  resumeInitialQsPopUpModalTracker,
  resumeSummaryQsPopUpModalTracker,
} from 'platform_web/services/analytics/events'
import { Container } from 'care-ui'

import { type FelaPropsType } from 'react-ui/typing'
import { type DashboardPage_user } from './__generated__/DashboardPage_user.graphql'
import { type DashboardPage_viewer } from './__generated__/DashboardPage_viewer.graphql'

type PropsType = FelaPropsType & {
  +user: DashboardPage_user,
  +viewer: DashboardPage_viewer,
}

const sectionStyle = ({ theme }) => ({
  Section: {
    backgroundColor: theme.palette.summaryGraphs.highlightColor,
  },
})

const containerStyle = ({ theme }) => ({
  paddingBottom: theme.care.spacing.lg,
})

const componentId = createComponentId(__filename)

const DashboardPageBase = ({ user, viewer: { currentUser } }: PropsType) => {
  const dispatch = useDispatch()
  const { theme } = useFela()

  const { t: translation } = useTranslation('dashboard')

  const handleModalClose = () => dispatch(markDashboardQuestionnaireModalSeen())
  const [hasSeenModal, isIndividual] = useSelector(
    ({
      genericUi: { hasSeenDashboardQuestionnaireModal },
      currentUser: { roleType },
    }) => [hasSeenDashboardQuestionnaireModal, roleType === 'INDIVIDUAL'],
  )

  const { linked_individual, unanswered_tenant_research_programs } = user || {}
  if (!linked_individual) {
    throw new Error('No linked_individual found for user')
  }
  const {
    individual_detail,
    initial_questionnaire_status,
    tenant,
    has_incomplete_summary_questionnaire,
    assessment_requested,
  } = linked_individual

  const unanswered = !isEmpty(unanswered_tenant_research_programs)
  const summaryQuestionnaire = tenant?.summary_questionnaire
  const initialQuestionnaire = tenant?.initial_questionnaire
  const isReadOnlyIndividual = individual_detail?.read_only
  const isCurrentUserDashboard = currentUser?.id === user.id

  const displayModal =
    isCurrentUserDashboard && !isReadOnlyIndividual && !hasSeenModal

  const showInitialQuestionnaireModal =
    Boolean(initialQuestionnaire) &&
    displayModal &&
    initial_questionnaire_status !== 'Completed'

  const showSummaryQuestionnaireModal =
    Boolean(summaryQuestionnaire) &&
    displayModal &&
    !showInitialQuestionnaireModal &&
    has_incomplete_summary_questionnaire

  const [modalOpen, setModalOpen] = useState(unanswered)
  const [assessmentNotificationOpen, setAssessmentNotificationOpen] = useState(
    true,
  )
  const { isSummaryGraph } = useChartToggle()

  const { id: assessmentRequestId, acknowledged, questionnaire_answer_sets } =
    head(assessment_requested.nodes) || {}

  const notificationState = Boolean(
    isIndividual &&
      assessmentNotificationOpen &&
      assessmentRequestId &&
      !acknowledged &&
      !questionnaire_answer_sets?.length,
  )

  return (
    <AssessmentNotificationContextProvider
      notificationState={notificationState}
      assessmentRequestId={assessmentRequestId}
    >
      <div className={componentId}>
        {isIndividual && (
          <Section color="white" noPadding>
            <Modal
              componentId="ResearchProgramsModal"
              isOpen={modalOpen}
              showClose={false}
              showCross={false}
              shrinkwrap
            >
              <ResearchAndDataSharing
                user={user}
                setModalOpen={setModalOpen}
                programs={unanswered_tenant_research_programs}
                modalOpen={modalOpen}
              />
            </Modal>
          </Section>
        )}

        <Section
          extend={isSummaryGraph && sectionStyle({ theme })}
          color="white"
          noPadding
        >
          <Container>
            <ChartHeader individual={linked_individual} user={currentUser} />
          </Container>
          <Container extend={containerStyle}>
            {showInitialQuestionnaireModal &&
              initialQuestionnaire && (
                <QuestionnaireWarningBanner
                  componentId="InitialQuestionnaireUnfinishedModal"
                  questionnaire={initialQuestionnaire}
                  onRequestClose={handleModalClose}
                  title={translation(
                    'it_appears_we_dont_have_all_your_answers',
                  )}
                  description={translation(
                    'finish_the_questionnaire_to_create_a_baseline_of_your_health_and_well_being',
                  )}
                  buttonTitle={translation('finish_questionnaire')}
                  analytics={{
                    onClickEvent: resumeInitialQsPopUpModalTracker,
                    onLoadEvent: notCompletedInitialQsPopUp,
                  }}
                />
              )}
            {showSummaryQuestionnaireModal &&
              summaryQuestionnaire && (
                <QuestionnaireWarningBanner
                  componentId="SummaryQuestionnaireUnfinishedModal"
                  questionnaire={summaryQuestionnaire}
                  onRequestClose={handleModalClose}
                  title={translation(
                    'it_appears_we_dont_have_all_your_answers',
                  )}
                  description={translation(
                    'finish_the_questionnaire_to_create_a_baseline_of_your_health_and_well_being',
                  )}
                  buttonTitle={translation('finish_questionnaire')}
                  analytics={{
                    onClickEvent: resumeSummaryQsPopUpModalTracker,
                    onLoadEvent: notCompletedSummaryPopUp,
                  }}
                />
              )}
            <AssessmentRequestBanner
              setAssessmentNotificationOpen={setAssessmentNotificationOpen}
              title={translation(
                'your_clinician_has_requested_you_to_update_some_questionnaires',
              )}
              description={translation(
                'you_can_find_these_in_the_requested_assessments_under_the_common_tasks_section_on_your_dashboard',
              )}
              buttonTitle={translation('click_here_to_start')}
            />
          </Container>
        </Section>
        <HealthCardsTabLoader user={user} />
      </div>
    </AssessmentNotificationContextProvider>
  )
}

const DashboardPageQuery = graphql`
  query DashboardPage_Query {
    viewer {
      ...DashboardPage_viewer
      currentUser {
        generateSummaryPdf
        ...DashboardPage_user
      }
    }
  }
`

export const DashboardPageLoader = createFragmentContainer(DashboardPageBase, {
  viewer: graphql`
    fragment DashboardPage_viewer on Viewer {
      currentUser {
        id
        ...ChartHeader_user
      }
    }
  `,
  user: graphql`
    fragment DashboardPage_user on User {
      ...HealthCardsTab_user

      id
      tenant {
        privacyUrl
      }
      unanswered_tenant_research_programs {
        id
        research_program {
          id
          title
          description
        }
      }
      linked_individual {
        ...DashboardPage_user_assessment_request @relay(mask: false)
        ...ChartHeader_individual
        initial_questionnaire_status
        has_incomplete_summary_questionnaire
        individual_detail {
          read_only
        }
        tenant {
          summary_questionnaire {
            id
          }
          initial_questionnaire {
            id
          }
        }
      }
    }
  `,
})

graphql`
  fragment DashboardPage_user_assessment_request on IndividualRole
    @relay(mask: false) {
    assessment_requested(first: 1) {
      nodes {
        id
        questionnaire_answer_sets {
          id
        }
        acknowledged
      }
    }
  }
`

export const DashboardPageQueryLoader = () => {
  const currentUser = useCurrentUser()
  const environment = useRelayEnvironment()
  const { router } = useRouter()
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])

  const { homepage_path } = currentUser

  useEffect(
    () => {
      if (!isIndividual) {
        const redirectRoute = homepage_path || '/'
        router.replace(redirectRoute)
      }
    },
    [isIndividual],
  )

  return (
    <QueryRenderer
      environment={environment}
      query={DashboardPageQuery}
      variables={{}}
      render={response => {
        return (
          <QueryRendererLoadingIndicator response={response}>
            {props => {
              return (
                <DashboardPageLoader
                  {...props}
                  user={props.viewer.currentUser}
                />
              )
            }}
          </QueryRendererLoadingIndicator>
        )
      }}
    />
  )
}
