/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionSetDetailsView_user_question_set$ref: FragmentReference;
declare export opaque type UserQuestionSetDetailsView_user_question_set$fragmentType: UserQuestionSetDetailsView_user_question_set$ref;
export type UserQuestionSetDetailsView_user_question_set = {|
  +id: string,
  +user: {|
    +id: string
  |},
  +question_set: {|
    +id: string
  |},
  +$refType: UserQuestionSetDetailsView_user_question_set$ref,
|};
export type UserQuestionSetDetailsView_user_question_set$data = UserQuestionSetDetailsView_user_question_set;
export type UserQuestionSetDetailsView_user_question_set$key = {
  +$data?: UserQuestionSetDetailsView_user_question_set$data,
  +$fragmentRefs: UserQuestionSetDetailsView_user_question_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionSetDetailsView_user_question_set",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserQuestionSet",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '2cf86709627a85750599f90e73773805';

module.exports = node;
