// @flow

import { graphql } from 'react-relay'

export const UserQuestionnaire = graphql`
  fragment UserQuestionnaireDetailsPage_user_questionnaire on UserQuestionnaire {
    id
    questionnaire {
      label
    }
    ensure_questionnaire {
      label
    }
    user_question_sets {
      for_role_type
      ...UserQuestionSetTable_user_question_sets
    }
  }
`

export const UserQuestionnaireDetailsPageQuery = graphql`
  query UserQuestionnaireDetailsPage_Query($id: ID!, $questionnaire_id: ID!) {
    user(id: $id, source: "root") {
      user_questionnaire(questionnaire_id: $questionnaire_id) {
        ...UserQuestionnaireDetailsPage_user_questionnaire
      }
    }
  }
`
