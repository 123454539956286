// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const CaretIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 10 18"
        style={{ enableBackground: 'new 0 0 256 256' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <defs>
          <path
            d="M16.9025742,4.36685993 C17.1536255,4.1158086 17.5606602,4.1158086 17.8117115,4.36685993 C18.0627628,4.61791126 18.0627628,5.02494589 17.8117115,5.27599722 L9.45456865,13.6331401 C9.20351731,13.8841914 8.79648269,13.8841914 8.54543135,13.6331401 L0.188288498,5.27599722 C-0.0627628326,5.02494589 -0.0627628326,4.61791126 0.188288498,4.36685993 C0.439339828,4.1158086 0.846374457,4.1158086 1.09742579,4.36685993 L9,12.2694341 L16.9025742,4.36685993 Z"
            id="caret-path-1"
          />
        </defs>
        <g id="Page-1" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g
            id="icon-/-14x14-/-chevron-/-thin-/-right"
            transform="translate(-4.000000, 0.000000)"
          >
            <mask id="mask-2" fill="white">
              <use xlinkHref="#caret-h-path-1" />
            </mask>
            <use
              id="Stroke-50-Copy"
              fillRule="nonzero"
              transform="translate(9.000000, 9.000000) scale(-1, -1) rotate(90.000000) translate(-9.000000, -9.000000) "
              xlinkHref="#caret-path-1"
            />
            <g
              id="→-colour-/-_Light-/-white"
              mask="url(#mask-2)"
              fillRule="evenodd"
            >
              <rect id="Rectangle-2" x="0" y="0" width="18" height="18" />
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default CaretIcon
