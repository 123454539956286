// @flow

import React from 'react'
import { useFela } from 'react-fela'
import numeral from 'numeral'

import { LoadPlotlyWrapper } from 'components/hoc/LoadPlotlyWrapper'
import type { TraceType } from 'services/plotly'

type PropsType = {
  Plot: Object,
  data: Object,
  layout: Object,
  +traces: $ReadOnlyArray<TraceType>,
}

type DonutPropsType = {
  Plot: Object,
  title?: string,
  traces: Array<TraceType>,
}

const DonutChart = ({ Plot, data, layout }: PropsType) => (
  <Plot data={data} layout={layout} config={{ displayModeBar: false }} />
)

function buildData({
  traces,
  theme,
}: {
  theme: Object,
  traces: Array<TraceType>,
}) {
  const trace = traces[0]

  return [
    {
      hole: 0.75,
      labels: trace.x,
      textinfo: 'none',
      type: 'pie',
      values: trace.y,
      marker: {
        colors: Array.from({ length: trace.x.length }, (_, index) =>
          theme.getReportColour(index),
        ),
        line: {
          color: '#fff',
          width: 2,
        },
      },
    },
  ]
}

function buildLayout({
  traces,
  theme,
  title: text,
}: {
  theme: Object,
  title: string,
  traces: Array<TraceType>,
}) {
  const trace = traces[0]
  const { total } = trace

  return {
    annotations: [
      {
        font: {
          size: 16,
          color: theme.care.palette.text.positive,
        },
        showarrow: false,
        text,
        x: 0.5,
        y: 0.6,
      },
      {
        font: {
          size: 32,
          color: theme.care.palette.text.positive,
        },
        showarrow: false,
        text: numeral(total).format('0,0'),
        x: 0.5,
        y: 0.48,
      },
    ],
    hovermode: false,
    margin: {
      r: 0,
      t: 0,
      b: 0,
      l: 0,
    },
    showlegend: false,
    width: 200,
    height: 200,
  }
}

const Donut = ({
  traces,
  title = 'Total individuals',
  ...props
}: DonutPropsType) => {
  const { theme } = useFela()

  const data = buildData({ traces, theme })
  const layout = buildLayout({ traces, theme, title })

  return <DonutChart data={data} layout={layout} {...(props: any)} />
}

export default LoadPlotlyWrapper(Donut)
