// @flow

import React, { type ComponentType, type Node } from 'react'
import { useFela } from 'react-fela'
import classNames from 'classnames'

const styleRules = ({
  inherit = false,
  level,
  theme,
  weight = 'bold',
  extend,
}) => ({
  className: classNames('Heading', `--weight-${weight}`, {
    [`--level-${level}`]: level && level < 4,
    '--inherit': inherit,
  }).replace(/\s/g, ''),
  color: inherit ? 'inherit' : theme.Heading.color,
  fontStyle: 'normal',
  fontWeight:
    {
      light: 300,
      regular: 500,
      heavy: 600,
      bold: 700,
      black: 900,
    }[weight] || 700,
  width: '100%',
  '-webkit-font-smoothing': 'antialiased',
  '-moz-osx-font-smoothing': 'grayscale',

  ...(() => {
    switch (level) {
      case 1: {
        return {
          fontSize: '2.667em',
          lineHeight: '1.05',
          marginBottom: '0px',
          marginTop: '28px',
          [theme.breakpoints.queries.mdOnly]: {
            fontSize: '2.22em',
            lineHeight: '1.25',
          },
          [theme.breakpoints.queries.smOnly]: {
            fontSize: '1.778em',
            lineHeight: '1.25',
          },
          [theme.breakpoints.queries.xsOnly]: {
            fontSize: '1.778em',
            lineHeight: '1.25',
          },
        }
      }
      case 2: {
        return {
          fontSize: '2em',
          lineHeight: '1.25',
          marginBottom: '28px',
          marginTop: '28px',
          [theme.breakpoints.queries.mdOnly]: {
            fontSize: '1.788em',
            lineHeight: '1.25',
          },
          [theme.breakpoints.queries.smOnly]: {
            fontSize: '1.44em',
            lineHeight: '1.15',
          },
          [theme.breakpoints.queries.xsOnly]: {
            fontSize: '1.44em',
            lineHeight: '1.15',
          },
        }
      }
      case 3: {
        return {
          fontSize: '1.55em',
          lineHeight: '1.25',
          marginTop: '28px',
          marginBottom: '0px',
          [theme.breakpoints.queries.mdOnly]: {
            fontSize: '1.33em',
            lineHeight: '1.25',
          },
          [theme.breakpoints.queries.smOnly]: {
            fontSize: '1.222em',
            lineHeight: '1.1263',
          },
          [theme.breakpoints.queries.xsOnly]: {
            fontSize: '1.222em',
            lineHeight: '1.1263',
          },
        }
      }
      case 4: {
        return {
          fontSize: '1em',
          lineHeight: '1.22',
          marginTop: '28px',
          marginBottom: '0px',
          [theme.breakpoints.queries.smOnly]: {
            fontSize: '1em',
            lineHeight: '1.1263',
          },
          [theme.breakpoints.queries.xsOnly]: {
            fontSize: '1em',
            lineHeight: '1.1263',
          },
        }
      }
      default: {
        return {
          fontSize: '1em',
          lineHeight: '1.37',
          marginTop: '28px',
          marginBottom: '0px',
        }
      }
    }
  })(),
  ...extend({ theme }),
})

export type HeadingProps = {
  as?: string | ComponentType<any>,
  children: ?Node,
  extend?: any,
  level: number,
}

const Heading = ({ as, level, children, extend }: HeadingProps) => {
  const extendStyles = typeof extend === 'function' ? extend : () => extend
  const { css } = useFela({ extend: extendStyles, level })

  const Component = as || `h${level}`
  return <Component className={css(styleRules)}>{children}</Component>
}

export default Heading
