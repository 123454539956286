// @flow

import { createLogger } from 'shared/services/logging'

const log = createLogger(__filename)

const versionParams = []

if (process.env.RELEASE) versionParams.push(`Release: ${process.env.RELEASE}`)
if (process.env.NODE_ENV)
  versionParams.push(`Environment: ${process.env.NODE_ENV}`)
if (window.GLOBALS.appEnvironment)
  versionParams.push(
    `Application Environment: ${window.GLOBALS.appEnvironment}`,
  )

log(versionParams.join(', '))
