// @flow

import React from 'react'
import { useFela } from 'react-fela'

type PropsType = {
  extend?: any,
  type?: 'dashed' | 'solid',
  units?: string,
}
// Rendering in chrome is buggy which displays the hr with different width and heights
const styleRules = ({ type = 'dashed', theme, units = '3', extend }) => ({
  className: `Divider--${type}-width-${units}`,
  border: 'none',
  borderBottom: `${units}px ${type} ${theme.palette.dividerColor}`,
  width: '100%',
  ...extend({ theme }),
})

const Divider = ({ type, units, extend }: PropsType) => {
  const extendStyles = typeof extend === 'function' ? extend : () => extend
  const { css } = useFela({ type, units, extend: extendStyles })

  return <hr className={css(styleRules)} />
}

export default Divider
