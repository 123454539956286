// @flow

import React from 'react'

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { Box, FlexContainer, FlexItem } from 'care-ui'

import ClinicianLoginsInsightsGraph from './components/ClinicianLoginsInsightsGraph'
import IndividualLoginsInsightsGraph from './components/IndividualLoginsInsightsGraph'
import IndividualOnboardingInsightsGraph from './components/IndividualOnboardingInsightsGraph'
import StbEscalationsInsightsGraph from './components/StbEscalationsGraph'
import SummaryQuestionnairesInsightsGraph from './components/SummaryQuestionnairesInsightsGraph'
import { type OverviewQueryVariableType } from './hooks/useOverviewInsights'

type OverviewProps = {
  overviewQueryVariables: OverviewQueryVariableType,
}

const Overview = (props: OverviewProps) => {
  const { overviewQueryVariables } = props

  const { hasOwnerRole, currentUser } = useCurrentUserRoles()

  // TODO - Investigate why this route can be visited before user is logged in
  // return if the owner role does not have tenant_id
  if (!currentUser.loggedIn || hasOwnerRole === undefined) return null

  if (hasOwnerRole && !overviewQueryVariables.owner_tenant_id) return null

  return (
    <>
      <Box marginY="sm" height="500px">
        <FlexContainer gap="xs" wrap="nowrap">
          <FlexItem flex={1}>
            <IndividualOnboardingInsightsGraph
              overviewQueryVariables={overviewQueryVariables}
            />
          </FlexItem>

          <FlexItem flex={1}>
            <IndividualLoginsInsightsGraph
              overviewQueryVariables={overviewQueryVariables}
            />
          </FlexItem>
        </FlexContainer>
      </Box>

      <Box marginY="sm" height="500px">
        <SummaryQuestionnairesInsightsGraph
          overviewQueryVariables={overviewQueryVariables}
        />
      </Box>

      <Box marginY="sm" minHeight="500px">
        <FlexContainer gap="xs" wrap="nowrap">
          <FlexItem flex={1}>
            <ClinicianLoginsInsightsGraph
              overviewQueryVariables={overviewQueryVariables}
            />
          </FlexItem>

          <FlexItem flex={1}>
            <StbEscalationsInsightsGraph
              overviewQueryVariables={overviewQueryVariables}
            />
          </FlexItem>
        </FlexContainer>
      </Box>
    </>
  )
}

export default Overview
