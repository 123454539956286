/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserQuestionnaire_user_questionnaire$ref = any;
export type UserQuestionnaireSkipInput = {|
  answereeId?: ?string,
  assessmentRequestId?: ?string,
  clientMutationId?: ?string,
  questionnaireId: string,
  questionnaireQuestionSetId?: ?string,
  workflowForSkipReason?: ?string,
|};
export type UserQuestionnaireSkipAndDoAgainMutationVariables = {|
  input: UserQuestionnaireSkipInput
|};
export type UserQuestionnaireSkipAndDoAgainMutationResponse = {|
  +userQuestionnaireSkip: ?{|
    +user_questionnaire: {|
      +first_user_questionnaires_question_set: ?{|
        +finished: boolean,
        +questionnaires_question_set: {|
          +id: string
        |},
      |},
      +questionnaire: ?{|
        +id: string
      |},
      +id: string,
      +ready_to_complete: boolean,
      +$fragmentRefs: UserQuestionnaire_user_questionnaire$ref,
    |}
  |}
|};
export type UserQuestionnaireSkipAndDoAgainMutation = {|
  variables: UserQuestionnaireSkipAndDoAgainMutationVariables,
  response: UserQuestionnaireSkipAndDoAgainMutationResponse,
|};
*/


/*
mutation UserQuestionnaireSkipAndDoAgainMutation(
  $input: UserQuestionnaireSkipInput!
) {
  userQuestionnaireSkip(input: $input) {
    user_questionnaire {
      first_user_questionnaires_question_set {
        finished
        questionnaires_question_set {
          id
        }
        id
      }
      questionnaire {
        id
      }
      id
      ready_to_complete
      ...UserQuestionnaire_user_questionnaire
    }
  }
}

fragment UserQuestionnaire_user_questionnaire on UserQuestionnaire {
  label
  description
  progress {
    decimal
    done
    total
    step
  }
  first_user_questionnaires_question_set {
    finished
    questionnaires_question_set {
      id
    }
    id
  }
  user {
    id
  }
  questionnaire {
    id
  }
  ensure_questionnaire {
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionnairesQuestionSet",
  "kind": "LinkedField",
  "name": "questionnaires_question_set",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Questionnaire",
  "kind": "LinkedField",
  "name": "questionnaire",
  "plural": false,
  "selections": (v4/*: any*/),
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ready_to_complete",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserQuestionnaireSkipAndDoAgainMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserQuestionnaireSkipPayload",
        "kind": "LinkedField",
        "name": "userQuestionnaireSkip",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserQuestionnaire",
            "kind": "LinkedField",
            "name": "user_questionnaire",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserQuestionnairesQuestionSet",
                "kind": "LinkedField",
                "name": "first_user_questionnaires_question_set",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserQuestionnaire_user_questionnaire"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserQuestionnaireSkipAndDoAgainMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserQuestionnaireSkipPayload",
        "kind": "LinkedField",
        "name": "userQuestionnaireSkip",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserQuestionnaire",
            "kind": "LinkedField",
            "name": "user_questionnaire",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserQuestionnairesQuestionSet",
                "kind": "LinkedField",
                "name": "first_user_questionnaires_question_set",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v6/*: any*/),
              (v3/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "label",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "description",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Progress",
                "kind": "LinkedField",
                "name": "progress",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "decimal",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "done",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "total",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "step",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "user",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Questionnaire",
                "kind": "LinkedField",
                "name": "ensure_questionnaire",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "be3bc68e151a40e29e62329a4bd3715d",
    "id": null,
    "metadata": {},
    "name": "UserQuestionnaireSkipAndDoAgainMutation",
    "operationKind": "mutation",
    "text": "mutation UserQuestionnaireSkipAndDoAgainMutation(\n  $input: UserQuestionnaireSkipInput!\n) {\n  userQuestionnaireSkip(input: $input) {\n    user_questionnaire {\n      first_user_questionnaires_question_set {\n        finished\n        questionnaires_question_set {\n          id\n        }\n        id\n      }\n      questionnaire {\n        id\n      }\n      id\n      ready_to_complete\n      ...UserQuestionnaire_user_questionnaire\n    }\n  }\n}\n\nfragment UserQuestionnaire_user_questionnaire on UserQuestionnaire {\n  label\n  description\n  progress {\n    decimal\n    done\n    total\n    step\n  }\n  first_user_questionnaires_question_set {\n    finished\n    questionnaires_question_set {\n      id\n    }\n    id\n  }\n  user {\n    id\n  }\n  questionnaire {\n    id\n  }\n  ensure_questionnaire {\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '61f6191cc3cd5d3c5d060744b25daa43';

module.exports = node;
