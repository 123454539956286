// @flow

import { graphql } from 'react-relay'

export const currentResultCardUserTrackable = {
  user_trackable: graphql`
    fragment CurrentResultCard_user_trackable on UserTrackable {
      escalated
      matches_user_age
      assigned_to_me
      suggested_user_resources {
        ...UserResource_user_resource
        status
        id
        resource {
          clinical
        }
      }
      ...CurrentResultCardFormula_user_trackable
      primary_user_questionnaire {
        id
        user {
          id
        }
        questionnaire {
          label
          id
        }
        ensure_questionnaire {
          label
          id
        }
        user_question_sets {
          for_role_type
          ...UserQuestionSetTable_user_question_sets
        }
      }
    }
  `,
}
