/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardChartContext_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type GraphWithFilters_user$ref: FragmentReference;
declare export opaque type GraphWithFilters_user$fragmentType: GraphWithFilters_user$ref;
export type GraphWithFilters_user = {|
  +$fragmentRefs: ScoreCardChartContext_user$ref,
  +$refType: GraphWithFilters_user$ref,
|};
export type GraphWithFilters_user$data = GraphWithFilters_user;
export type GraphWithFilters_user$key = {
  +$data?: GraphWithFilters_user$data,
  +$fragmentRefs: GraphWithFilters_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "GraphWithFilters_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardChartContext_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e3e14cd24d4861607215eb75b8396031';

module.exports = node;
