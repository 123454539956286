// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import StickyQuestionnaireHeader from './StickyQuestionnaireHeader'

const StickyQuestionnaireHeaderLoader = ({
  userQuestionnairesQuestionSet,
  ...props
}) => {
  return (
    <StickyQuestionnaireHeader
      {...props}
      userQuestionnairesQuestionSet={userQuestionnairesQuestionSet}
    />
  )
}

export default createFragmentContainer(StickyQuestionnaireHeaderLoader, {
  userQuestionnairesQuestionSet: graphql`
    fragment StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet on UserQuestionnairesQuestionSet {
      progress {
        step
        total
      }
      question_set {
        description
        label
      }
    }
  `,
})
