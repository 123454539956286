/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type REPORT_DATE_PRESET = "custom" | "last_seven_days" | "last_thirty_days" | "last_three_days" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ReportSettingsTenantFetch_settings$ref: FragmentReference;
declare export opaque type ReportSettingsTenantFetch_settings$fragmentType: ReportSettingsTenantFetch_settings$ref;
export type ReportSettingsTenantFetch_settings = {|
  +clinicians: $ReadOnlyArray<string>,
  +tenants: $ReadOnlyArray<string>,
  +from: any,
  +to: any,
  +selectedDateType: ?REPORT_DATE_PRESET,
  +$refType: ReportSettingsTenantFetch_settings$ref,
|};
export type ReportSettingsTenantFetch_settings$data = ReportSettingsTenantFetch_settings;
export type ReportSettingsTenantFetch_settings$key = {
  +$data?: ReportSettingsTenantFetch_settings$data,
  +$fragmentRefs: ReportSettingsTenantFetch_settings$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ReportSettingsTenantFetch_settings",
  "selections": [
    {
      "kind": "ClientExtension",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clinicians",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tenants",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "from",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "to",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "selectedDateType",
          "storageKey": null
        }
      ]
    }
  ],
  "type": "ReportSettings",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd0c0c06cce8bead221395fa06058fe38';

module.exports = node;
