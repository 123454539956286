// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'
import cx from 'classnames'

import createComponentId from 'shared/services/id'

import s from './Radio.scss'

type PropsType = {
  checked?: boolean,
  children: Node,
  componentId?: string,
  disabled?: boolean,
  kind?: string,
  name: string,
  onBlur?: Function,
  onChange?: Function,
  onClick?: Function,
  uiStyle?: 'primary' | 'secondary' | 'plain' | 'danger',
  value: string | number | boolean,
}

const radioStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const defaultId = createComponentId(__filename)

const Radio = ({
  checked,
  children,
  componentId = defaultId,
  disabled = false,
  kind,
  name,
  onBlur,
  onChange,
  onClick,
  uiStyle = 'primary',
  value,
}: PropsType) => {
  const { css } = useFela()
  return (
    <label
      className={cx(
        s.container,
        componentId,
        {
          [s.disabled]: disabled,
          [s.checked]: checked,
          [s[uiStyle]]: uiStyle,
        },
        css(radioStyle),
      )}
      data-testid="AnswerOption"
    >
      <input
        checked={checked}
        kind={kind}
        name={name}
        onBlur={onBlur}
        /* Only way to preserve onBlur functionality in a radio */
        onClick={onClick}
        onChange={onChange}
        type="radio"
        value={value}
        disabled={disabled}
      />
      <span className={s.title}>{children}</span>
      <span className={s.checkmark} />
    </label>
  )
}

export default Radio
