// @flow

import { setTokens } from './helpers'
import type { CallbacksType } from './request'
import makeRequest from './request'

export type SignInParamsType = {
  email?: string,
  password: string,
  rememberMe: boolean,
  username?: string,
}

export function signInRequest(payload: SignInParamsType): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(
      '/users/sign_in',
      'POST',
      {
        user: {
          // SMS_INVITE clean up email when the feature toggle is on
          email: payload.email,
          username: payload.username,
          password: payload.password,
          remember_me: payload.rememberMe ? '1' : '0',
        },
      },
      {
        onSuccess: res => {
          setTokens(res, () => {
            if (resolve) resolve(res)
          })
        },
        onError: reject,
      },
    )
  })
}

export const signOutRequest = (cbs?: CallbacksType) => {
  function onSuccess(res) {
    setTokens(res, () => {
      if (cbs && cbs.onSuccess) cbs.onSuccess(res)
    })
  }

  const callback = {
    ...cbs,
    onSuccess,
  }

  return makeRequest('/users/sign_out', 'DELETE', null, callback)
}
