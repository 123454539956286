// @flow

import { graphql, useMutation } from 'react-relay'

import { type useHelpNowVisitLinkMutation } from './__generated__/useHelpNowVisitLinkMutation.graphql'

const mutation = graphql`
  mutation useHelpNowVisitLinkMutation($input: HelpNowVisitLinkInput!) {
    helpNowVisitLink(input: $input) {
      clientMutationId
    }
  }
`

const useHelpNowVisitLink = () => {
  // prettier-ignore
  const [ commit, isInFlight ] = useMutation<useHelpNowVisitLinkMutation>(mutation)

  return { commit, isInFlight }
}

export default useHelpNowVisitLink
