// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { find, flow, getOr, includes } from 'lodash/fp'
import * as yup from 'yup'

import Link from 'react-ui/components/Link'
import {
  checkActiveIndividualStatus,
  checkAvailableRoleInTenant,
  checkPhoneNumberUniqueness,
} from 'shared/services/api/info'
import { requiredTextField } from 'shared/services/formik'

import { type FormikProps } from 'services/flow'
import type { UserInviteForm_tenants } from './__generated__/UserInviteForm_tenants.graphql'

export const tenantValidationText = (
  roleType: string,
  globalRoleTypes: $ReadOnlyArray<string>,
  translation: TFunction,
) => {
  const globalNotAllowed = includes(roleType)(globalRoleTypes)
    ? ''
    : translation('or_role_type_cannot_be_of_a_global_tenant', {
        role_type: roleType,
      })

  return `${translation('tenant_is_required')} ${globalNotAllowed}`
}

export const tenantEmrRequirements = ({
  roleType,
  tenantId,
  tenants,
}: {
  roleType: string,
  tenantId: string,
  tenants: UserInviteForm_tenants,
}) => {
  if (roleType === 'INDIVIDUAL') {
    return flow(
      find({ id: tenantId }),
      getOr(false, 'emr_integration.required'),
    )(tenants)
  }
  return false
}

export const buildEmrMessage = ({
  tenantId,
  tenants,
  translation,
}: {
  tenantId: string,
  tenants: UserInviteForm_tenants,
  translation: TFunction,
}) => {
  const patient_field_name = flow(
    find({ id: tenantId }),
    getOr('EMR number', 'emr_integration.emr_provider.user_id_field_name'),
  )(tenants)

  return translation('field_is_required', { field: patient_field_name })
}

const getUserInviteFormValidations = (
  props: FormikProps,
  translation: TFunction,
) => {
  const validations = {
    inviteType: yup.string().when(
      'roleType',
      (value, schema) =>
        value === 'INDIVIDUAL'
          ? schema.required(
              translation('field_is_required', {
                field: translation('invite_type'),
              }),
            )
          : schema,
    ),
    phone_number: yup
      .string()
      .trim()
      .matches(/^04\d{8}$/, translation('mobile_number_must_follow_04'))
      .when(
        'inviteType',
        (value, schema) =>
          value === 'SMS'
            ? schema.required(
                translation('field_is_required', {
                  field: translation('phone_number'),
                }),
              )
            : schema,
      )
      .test(
        'unique-phone',
        translation('phone_validation_error'),
        async function(value) {
          if (!value) return true

          const uniqueErr = translation('phone_number_has_already_been_taken')
          const { email } = this.parent
          const response = await checkPhoneNumberUniqueness(value, email)
          const { unique } = await response.json()

          if (!unique) return this.createError({ message: uniqueErr })

          return true
        },
      ),
    email: yup
      .string()
      .email(translation('email_must_be_a_valid_email', { ns: 'staff' }))
      .trim()
      .when('inviteType', (value, schema) => {
        return value === 'EMAIL'
          ? schema.required(
              translation('field_is_required', { field: translation('email') }),
            )
          : schema
      })
      .test('unique-email', 'This is unique email', async function(value) {
        if (!value) return true

        const { invitableUser: { roleType } } = props
        const isIndividualInvite = roleType === 'INDIVIDUAL'

        const uniqueErr = translation(
          'this_individual_email_address_is_active_in_another_service_and_cannot_be_invited',
        )
        const uniqueInTenantErr = translation(
          'this_individual_email_address_is_already_active_and_cannot_be_invited',
        )

        const response = await checkActiveIndividualStatus(value)
        const { unique, unique_in_tenant } = await response.json()

        if (!unique_in_tenant && isIndividualInvite)
          return this.createError({ message: uniqueInTenantErr })
        if (!unique && isIndividualInvite)
          return this.createError({ message: uniqueErr })

        return true
      })
      .test('available-email', 'This is available email', async function(
        value,
      ) {
        if (!value) return true

        const { roleType, tenantId } = this.parent
        const staffExists = (
          <>
            {translation(
              'this_staff_member_email_address_cannot_be_used_for_an_individual_role_for_support_on_how_to_invite_a_staff_member_to_an_individual_role_see_the',
            )}{' '}
            <Link to="/resources/faq">{translation('faqs')}</Link>.
          </>
        )
        const individualExists = (
          <>
            {translation(
              'this_individual_email_address_cannot_be_used_for_a_staff_role_for_support_on_how_to_invite_an_individual_to_a_staff_role_see_the',
            )}{' '}
            <Link to="/resources/faq">{translation('faqs')}</Link>.
          </>
        )
        const supportPersonExists = (
          <>
            {translation(
              'this_support_person_email_address_cannot_be_used_for_a_staff_role_for_support_on_how_to_invite_a_support_person_to_a_staff_role_see_the',
            )}{' '}
            <Link to="/resources/faq">{translation('faqs')}</Link>.
          </>
        )

        const ownerExists = (
          <>
            {translation(
              'this_owner_email_address_cannot_be_used_for_a_manager_role_for_support_on_how_to_invite_an_owner_to_a_manager_role_see_the',
            )}{' '}
            <Link to="/resources/faq">{translation('faqs')}</Link>.
          </>
        )

        const isIndividualInvite = roleType === 'INDIVIDUAL'
        const isManagerInvite = roleType === 'MANAGER'

        const currentTenantId = isManagerInvite ? tenantId : undefined

        const response = await checkAvailableRoleInTenant(
          value,
          currentTenantId,
        )

        const {
          staff_exists,
          individual_exists,
          support_person_exists,
          owner_exists,
        } = await response.json()

        if (staff_exists && isIndividualInvite)
          return this.createError({ message: staffExists })
        if (individual_exists && !isIndividualInvite)
          return this.createError({ message: individualExists })
        if (support_person_exists && !isIndividualInvite)
          return this.createError({ message: supportPersonExists })
        if (owner_exists && isManagerInvite)
          return this.createError({ message: ownerExists })

        return true
      }),
    roleType: requiredTextField('Role type'),
    clinicianAssignmentId: yup.string(),
    tenantId: yup
      .string()
      .trim()
      .when(
        'roleType',
        (value, schema) =>
          includes(value)(props.globalInvitableRoles) &&
          !includes(value)(props.invitableMultiTenantRoleTypes)
            ? schema
            : schema.required(
                tenantValidationText(
                  value,
                  props.globalInvitableRoles,
                  translation,
                ),
              ),
      ),
    emrUserId: yup
      .string()
      .when(['tenantId', 'roleType'], (tenantId, roleType, schema) => {
        if (
          tenantEmrRequirements({
            roleType,
            tenantId,
            tenants: props.tenants,
          })
        ) {
          return schema.required(
            buildEmrMessage({ tenantId, tenants: props.tenants, translation }),
          )
        }
        return schema
      }),
    tenantGroupId: yup
      .string()
      .trim()
      .when(
        'roleType',
        (value, schema) =>
          includes(value)(props.invitableMultiTenantRoleTypes)
            ? schema.required(
                translation(
                  'service_group_is_required_for_the_value_role_type',
                  { value },
                ),
              )
            : schema,
      ),
  }

  return validations
}

export default getUserInviteFormValidations
