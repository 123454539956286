/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DashboardPage_user_assessment_request$ref: FragmentReference;
declare export opaque type DashboardPage_user_assessment_request$fragmentType: DashboardPage_user_assessment_request$ref;
export type DashboardPage_user_assessment_request = {
  +assessment_requested: {
    +nodes: ?$ReadOnlyArray<?{
      +id: string,
      +questionnaire_answer_sets: $ReadOnlyArray<{
        +id: string,
        ...
      }>,
      +acknowledged: boolean,
      ...
    }>,
    ...
  },
  ...
};
export type DashboardPage_user_assessment_request$data = DashboardPage_user_assessment_request;
export type DashboardPage_user_assessment_request$key = {
  +$data?: DashboardPage_user_assessment_request$data,
  +$fragmentRefs: DashboardPage_user_assessment_request$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "DashboardPage_user_assessment_request",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 1
        }
      ],
      "concreteType": "AssessmentRequestConnection",
      "kind": "LinkedField",
      "name": "assessment_requested",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "AssessmentRequest",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionnaireAnswerSet",
              "kind": "LinkedField",
              "name": "questionnaire_answer_sets",
              "plural": true,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "acknowledged",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "assessment_requested(first:1)"
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '2e397b37a5dbaf858dfc26926c5d7428';

module.exports = node;
