// @flow

import { type ThemeType } from 'care-ui/atoms'

type BoxContainerStyleProps = {
  alignContent?: string,
  backgroundColor?: string,
  border?: string,
  borderBottom?: string,
  borderRadius?: string,
  borderTop?: string,
  boxShadow?: string,
  children?: Node,
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse',
  display?: string,
  height?: string,
  marginX?: string,
  marginY?: string,
  maxHeight?: string,
  maxWidth?: string,
  minHeight?: string,
  minWidth?: string,
  overflow?: 'auto' | 'hidden' | 'none' | 'scroll',
  paddingX?: string,
  paddingY?: string,
  position?: string,
  theme: ThemeType,
  top?: number,
  width?: string,
  zIndex?: number,
}

export const boxContainer = (props: BoxContainerStyleProps) => {
  const {
    theme,
    marginX = 0,
    marginY = 0,
    paddingX = 0,
    paddingY = 0,
    alignContent,
    minHeight,
    minWidth,
    maxHeight,
    maxWidth,
    overflow,
    position,
    top,
    zIndex,
    display,
    direction,
    backgroundColor,
    borderRadius,
    height,
    width,
    boxShadow,
    border,
    borderTop,
    borderBottom,
  } = props

  const borderStyle = theme.care.palette.border?.[border]
    ? `1px solid ${theme.care.palette.border?.[border]}`
    : border

  const borderTopStyle = theme.care.palette.border?.[borderTop]
    ? `1px solid ${theme.care.palette.border?.[borderTop]}`
    : borderTop

  const borderBottomStyle = theme.care.palette.border?.[borderBottom]
    ? `1px solid ${theme.care.palette.border?.[borderBottom]}`
    : borderBottom

  const backgroundColorStyle =
    theme.care.palette.surface?.[backgroundColor] || backgroundColor

  return {
    margin: `${theme.care.spacing?.[marginY] || marginY} ${theme.care.spacing?.[
      marginX
    ] || marginX} `,
    padding: `${theme.care.spacing?.[paddingX] || paddingX} ${theme.care
      .spacing?.[paddingY] || paddingY}`,
    height,
    width,
    borderRadius: theme.care.radius?.[borderRadius] || borderRadius,
    boxShadow: theme.care.elevation?.[boxShadow] || boxShadow,
    border: borderStyle,
    borderTop: borderTopStyle,
    borderBottom: borderBottomStyle,
    overflow,
    position,
    alignContent,
    maxHeight,
    maxWidth,
    minHeight,
    minWidth,
    top,
    zIndex,
    display,
    direction,
    backgroundColor: backgroundColorStyle,
  }
}
