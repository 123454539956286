// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import cx from 'classnames'

import CareOption from 'react-ui/components/CareOption'
import createComponentId from 'shared/services/id'

import s from './Resource.scss'

import type { Resource_resource } from './__generated__/Resource_resource.graphql'

type PropsType = {
  actions?: ?Object,
  componentId?: string,
  hideExternalLink?: boolean,
  resource: Resource_resource,
  +status?: React.Node,
}

const defaultId = createComponentId(__filename)

export const ResourceBase = (props: PropsType) => {
  const {
    actions,
    componentId = defaultId,
    hideExternalLink = false,
    resource,
    status,
  } = props

  return (
    <div className={cx(s.root, componentId)}>
      <CareOption
        actions={actions}
        status={status}
        resource={resource}
        hideExternalLink={hideExternalLink}
      />
    </div>
  )
}

export const ResourceLoader = createFragmentContainer(ResourceBase, {
  resource: graphql`
    fragment Resource_resource on Resource {
      ...CareOption_resource
    }
  `,
})
