// @flow

import { graphql } from 'react-relay'

export const supporteeFragment = graphql`
  fragment SupportingSupporteeQuestionnairesList_supportees on IndividualRole
    @relay(plural: true) {
    user {
      id
      legal_name
    }
    supporting_user_questionnaire {
      ensure_questionnaire {
        id
        label
      }
    }
  }
`

export const paginationFragment = graphql`
  fragment SupportingSupporteeQuestionnairesList_user on User
    @argumentDefinitions(
      supporteeUserId: { type: "ID" }
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(
      queryName: "SupportingSupporteeQuestionnairesListPaginationQuery"
    ) {
    id
    current_role {
      ... on SupportPersonRole {
        assessment_request_questionnaires(
          supportee_user_id: $supporteeUserId
          first: $count
          after: $cursor
        )
          @connection(
            key: "SupportingSupporteeQuestionnairesList_assessment_request_questionnaires"
          ) {
          edges {
            node {
              questionnaire {
                id
                label
              }
              assessment_request {
                id
                individual {
                  id
                  legal_name
                  user {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
