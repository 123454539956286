// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const listStyle = () => ({
  padding: 0,
  listStyle: 'none',
  position: 'relative',
})

export const mainContentStyle = (props: StyleProps) => {
  const { theme: { breakpoints } } = props

  return {
    padding: 0,
    maxWidth: '100%',
    [breakpoints.queries.md]: {
      paddingLeft: '4rem',
    },
    [breakpoints.queries.xs]: {
      padding: 0,
    },
  }
}

export const sectionOuterStyle = () => ({
  flex: 1,
})

export const sectionStyle = (props: StyleProps) => {
  const { theme: { palette, breakpoints } } = props

  return {
    height: '100%',
    [breakpoints.queries.md]: {
      // Mimic the white left column + grey right column on wider screens
      backgroundImage: `linear-gradient(to right, ${
        palette.input.default.base
      } 30%, transparent 0)`,
      borderBottom: `1px solid ${palette.dividerColor}`,
    },
  }
}

export const wrapperStyle = () => ({
  minHeight: '100vh',
})

export const rowStyle = () => ({
  minHeight: '100vh',
  marginLeft: 0,
  marginRight: 0,
})

export const navStyle = (props: StyleProps) => {
  const { theme: { palette, breakpoints } } = props

  return {
    backgroundColor: palette.input.default.base,
    boxShadow: `0 0.05rem 0.1rem ${palette.input.label.accent}`,
    paddingTop: '2rem',
    height: 'auto',
    paddingLeft: 0,

    [breakpoints.queries.md]: {
      boxShadow: `0.1rem -0.05rem 0.1rem ${palette.input.label.accent}`,
    },
  }
}

export const mainStyle = (props: StyleProps) => {
  const { theme: { breakpoints } } = props

  return {
    [breakpoints.queries.xs]: {
      padding: '0 8px',
    },
  }
}
