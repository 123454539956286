// @flow

import React, { type Node } from 'react'
import { ThemeProvider, useFela } from 'react-fela'
import { merge } from 'lodash/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Button from 'react-ui/components/Button'
import Icon from 'react-ui/components/Icon'
import PopOver from 'react-ui/components/PopOver'
import getDeterministicId from 'react-ui/utils/getDeterministicId'

type PropsType = {
  attachment: string,
  children: Node,
  targetAttachment: string,
}

const triggerStyle = () => ({
  display: 'flex',
  color: 'inherit',
})

const containerStyle = ({ theme }) => ({
  backgroundColor: theme.palette.toolTip.backgroundColor,
  borderColor: theme.palette.toolTip.backgroundColor,
  color: theme.palette.toolTip.color,
  ':before': {
    backgroundColor: '#fff',
  },
  fontSize: '14px',
  lineHeight: '21px',
  paddingBottom: '10px',
  paddingTop: '10px',
  // TODO: Remove p selector when Global.scss no longer sets <p> to a fixed size.
  '& p': {
    fontSize: 'inherit',
  },
})

const scrollPaneStyle = ({ theme }) => ({
  maxHeight: 'auto',
  paddingLeft: theme.Grid.gutter,
  paddingRight: theme.Grid.gutter,
})

const toolTipId = getDeterministicId('tooltip')

const ToolTip = ({ children, ...props }: PropsType) => {
  const { theme } = useFela()

  const {
    color: textColor = theme.palette.textColor,
    headingTextColor = theme.palette.headingTextColor,
    link = {},
    backgroundColor,
  } = theme.palette.toolTip

  const themelette = merge(theme, {
    Heading: {
      color: headingTextColor,
    },
    palette: {
      headingTextColor,
      link: {
        ...theme.palette.link,
        ...link,
      },
      textColor,
      bodyBackgroundColor: backgroundColor,
    },
  })

  const renderTrigger = ({ onToggleOpen }) => (
    <Button
      extend={triggerStyle}
      icon={<Icon as={InfoCircle} scale={0.6} />}
      invert
      naked
      onBlur={onToggleOpen}
      onClick={onToggleOpen}
      onFocus={onToggleOpen}
      aria-describedby={toolTipId}
    />
  )

  const extend = (...args) => ({
    container: containerStyle(...args),
    scrollPane: scrollPaneStyle(...args),
  })

  return (
    <PopOver renderTrigger={renderTrigger} extend={extend} {...(props: any)}>
      <ThemeProvider theme={themelette}>
        <div role="tooltip" id={toolTipId}>
          {children}
        </div>
      </ThemeProvider>
    </PopOver>
  )
}

export default ToolTip
