// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import type { FelaPropsType } from '../../typing'

export type KeyedAvatarType = {
  id: string,
  node: React.Node,
}

type PropsType = {
  avatars: Array<KeyedAvatarType>,
}

const styleRules = () => ({
  container: {
    className: 'AvatarGroup__container',
    display: 'inline-flex',
    alignItems: 'center',
  },
  avatarWrapper: {
    className: 'AvatarGroup__avatarWrapper',
    display: 'inline-flex',
    position: 'relative',

    ':after': {
      border: '2px solid white',
      borderRadius: 200,
      content: '""',
      height: '100%',
      position: 'absolute',
      width: '100%',
      left: '0rem',
    },

    ':not(:first-child)': {
      marginLeft: '-15px',
    },
  },
})

const AvatarGroup = ({ avatars, styles }: PropsType & FelaPropsType) => (
  <span className={styles.container}>
    {avatars.map(({ id, node }) => (
      <span className={styles.avatarWrapper} key={id}>
        {node}
      </span>
    ))}
  </span>
)

const Connected: React.StatelessFunctionalComponent<PropsType> = connect(
  styleRules,
)(AvatarGroup)

export default Connected
