// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  isChild?: boolean,
  theme: ThemeType,
}

export const accordionItemStyle = (props: StyleProps) => {
  const { theme, isChild } = props
  const headerLeftPadding = isChild ? '1.7rem' : '0.3rem'

  return {
    AccordionItem: {
      marginBottom: '0',
      backgroundColor: 'white',
      borderBottom: `1px solid ${theme.palette.dividerColor}`,
      '& table': {
        overflow: 'hidden',
        marginTop: '0',
        marginBottom: '0',
        '& th': {
          '&:last-child': {
            paddingLeft: '0',
            width: '27.6rem',
            maxWidth: '100%',
          },
        },
        '& tbody': {
          backgroundColor: 'unset',
          '& tr': {
            minHeight: '2rem',
            '&:last-child': {
              '& td': {
                borderBottom: 'none',
              },
            },
          },
          '& td': {
            borderBottom: `1px solid ${theme.palette.dividerColor}`,
            padding: '0.5rem 0',
            '&:first-child': {
              paddingLeft: '0',
              borderLeft: '2.8rem solid transparent',
              paddingRight: '1rem',
              height: '3.3rem',
            },
            '&:nth-child(2)': {
              paddingRight: '1rem',
            },
            '&:last-child': {
              paddingRight: '0',
              borderRight: '2.8rem solid transparent',
            },
          },
        },
      },
    },
    header: {
      backgroundColor: 'white',
      flexDirection: 'row-reverse',
      height: '3.7rem',
      lineHeight: '2rem',
      paddingLeft: headerLeftPadding,
    },
    headerContainer: {
      backgroundColor: 'white',
    },
    heading: {
      fontSize: '1rem',
    },
    icon: {
      marginLeft: '0.3rem',
      margin: '0.7rem 0.9rem 0 0.3rem',
      width: '0.5rem',
      height: '0.5rem',
    },
    children: {
      backgroundColor: theme.palette.lightBackground,
      paddingTop: '0',
      paddingBottom: '0',
      paddingRight: '0',
      paddingLeft: '0',
    },
  }
}

export const headerStyle = () => {
  return {
    display: 'flex',
    '& div': {
      textAlign: 'center',
      ':first-child': {
        width: '20rem',
        textAlign: 'left',
      },
      ':nth-child(2)': {
        width: '10rem',
      },
      ':nth-child(3)': {
        width: '10rem',
      },
      ':nth-child(4)': {
        width: '20rem',
      },
      ':nth-child(5)': {
        whiteSpace: 'no-wrap',
      },
    },
  }
}

export const noResultsStyle = () => ({
  paddingTop: '10',
  paddingBottom: '10',
  paddingRight: '10',
  paddingLeft: '20',
})
