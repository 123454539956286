// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const updatedStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    ...theme.care.typography.desktop.bodyMd,
    color: theme.palette.component.muted.mutedBase,
  }
}

export const descriptionStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    ...theme.care.typography.desktop.bodyLg,
  }
}

export const rawScoreStyle = () => ({
  RawScore: {
    background: 'white',
    margin: '0',
    minHeight: '1.9rem',
    fontWeight: 'lighter',
    justifyContent: 'flex-start',
    '> div:last-child': {
      marginLeft: '1rem',
    },
  },
})
