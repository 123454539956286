/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserMfaAuthenticateInput = {|
  clientMutationId?: ?string,
  code: string,
|};
export type UserMfaAuthenticateMutationVariables = {|
  input: UserMfaAuthenticateInput
|};
export type UserMfaAuthenticateMutationResponse = {|
  +userMfaAuthenticate: ?{|
    +currentUser: {|
      +mfa_code_required: boolean,
      +mfa_last_verified_at: ?any,
    |}
  |}
|};
export type UserMfaAuthenticateMutation = {|
  variables: UserMfaAuthenticateMutationVariables,
  response: UserMfaAuthenticateMutationResponse,
|};
*/


/*
mutation UserMfaAuthenticateMutation(
  $input: UserMfaAuthenticateInput!
) {
  userMfaAuthenticate(input: $input) {
    currentUser {
      mfa_code_required
      mfa_last_verified_at
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mfa_code_required",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "mfa_last_verified_at",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserMfaAuthenticateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMfaAuthenticatePayload",
        "kind": "LinkedField",
        "name": "userMfaAuthenticate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserMfaAuthenticateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserMfaAuthenticatePayload",
        "kind": "LinkedField",
        "name": "userMfaAuthenticate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a3aea9657c8fb5e78c944d6eb6a1cbe2",
    "id": null,
    "metadata": {},
    "name": "UserMfaAuthenticateMutation",
    "operationKind": "mutation",
    "text": "mutation UserMfaAuthenticateMutation(\n  $input: UserMfaAuthenticateInput!\n) {\n  userMfaAuthenticate(input: $input) {\n    currentUser {\n      mfa_code_required\n      mfa_last_verified_at\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '17b748fcc79cd62687c5e8979eec645c';

module.exports = node;
