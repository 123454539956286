// @flow

import { useEffect, useState } from 'react'
import { useRouter } from 'found'
import { last } from 'lodash/fp'

const useRouteParams = () => {
  const { match } = useRouter()
  const { params, routes } = match || {}
  const currentRoute = last(routes)

  const [id, setId] = useState(params.id)
  const [currentRouteName, setCurrentRouteName] = useState(currentRoute.name)

  useEffect(
    () => {
      if (params.id) {
        setId(params.id)
      }
    },
    [params.id],
  )

  useEffect(
    () => {
      if (currentRoute.name) {
        setCurrentRouteName(currentRoute.name)
      }
    },
    [currentRouteName],
  )

  return { id, currentRouteName }
}

export default useRouteParams
