// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'

import { withoutFelaProps } from 'shared/services/fela'

import Triangle from './Triangle'

import type { FelaPropsType } from 'react-ui/typing'

export type SortDirection = -1 | 1

export type SortableHeaderLabelProps = FelaPropsType & {
  as?: string,
  children: React.Node,
  sortDirection?: SortDirection,
}

const styleRules = ({ sortDirection }: { sortDirection?: SortDirection }) => ({
  SortableHeaderLabel: {
    alignItems: 'baseline',
    className: classNames('SortableHeaderLabel', {
      [`--sort-${String(sortDirection)}`]: typeof sortDirection !== 'undefined',
    }),
    cursor: sortDirection === 1 ? 'n-resize' : 's-resize',
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
  },
  children: {
    className: 'SortableHeaderLabel__children',
    display: 'inline-block',
    flex: '0 1 100%',
    '-ms-flex': '0 1 auto',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  triangle: {
    className: 'SortableHeaderLabel__triangle',
    flex: '0 0 auto',
    marginLeft: '1em',
  },
})

const SortableHeaderLabel = ({
  as: Component = 'div',
  children,
  sortDirection,
  styles,
  ...props
}: SortableHeaderLabelProps) => (
  <Component
    {...withoutFelaProps(props)}
    className={styles.SortableHeaderLabel}
  >
    <span className={styles.children}>{children}</span>
    {sortDirection !== undefined && (
      <Triangle
        className={styles.triangle}
        color="inherit"
        orientation={sortDirection === -1 ? 'bottom' : 'top'}
        size="0.5em"
      />
    )}
  </Component>
)

export default connect(styleRules)(SortableHeaderLabel)
