// @flow

import React from 'react'
import { connect } from 'react-fela'
import { Field as FormikField } from 'formik'

import Field from 'react-ui/components/Form/Field'
import Radio, { type RadioProps } from 'react-ui/components/Form/Radio'
import { withoutFelaProps } from 'shared/services/fela'

import { OtherField } from './OtherField'

import type { FelaPropsType } from 'react-ui/typing'

export type MultipleChoiceFieldProps = FelaPropsType &
  RadioProps & {
    data?: { otherEnabled?: boolean },
    id: string,
    name: string,
    options: Array<{
      label?: Node | string,
      title?: Node | string,
      value: any,
    }>,
    title: string,
  }

const MultipleChoiceFieldComponent = ({
  id,
  options,
  rules,
  title,
  data,
  ...props
}: MultipleChoiceFieldProps) => (
  <>
    <FormikField name={id} multiple={false}>
      {({
        field: { name, value: selectedValue, ...field },
        form: { touched, errors, isSubmitting },
      }) => (
        <Field
          error={touched[name] && errors[name]}
          id={name}
          label={title}
          input={
            <>
              {options.map(({ label, value }) => (
                <Radio
                  {...field}
                  {...withoutFelaProps(
                    props,
                    'fieldType',
                    'handleSubmit',
                    'initialValue',
                    'optional',
                    'required',
                    'stepNumber',
                    'validationSchema',
                  )}
                  checked={selectedValue === `${value}`}
                  disabled={isSubmitting}
                  id={`${name}--${value}`}
                  key={`${name}--${value}`}
                  label={label}
                  name={name}
                  type="radio"
                  value={value}
                  extend={(...args) => ({
                    activeIcon: rules.activeIcon(...args),
                    Input: rules.Input(...args),
                    input: rules.input(...args),
                    label: rules.label(...args),
                  })}
                />
              ))}
            </>
          }
        />
      )}
    </FormikField>
    {data?.otherEnabled && <OtherField id={`other.${id}`} dependOn={id} />}
  </>
)

const styleRules = ({ theme }) => ({
  Input: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    maxWidth: '30rem',
  },
  input: {
    ':disabled + label': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
    ':checked + label': {
      paddingLeft: `calc(${theme.Grid.gutter} * 2.2)`,
      transition: 'padding .2s ease',
    },
    ':checked:not(:disabled) + label': {
      backgroundColor: theme.care.palette.surface.accent,
      borderColor: theme.care.palette.surface.accent,
      color: theme.care.palette.text.default,
      paddingRight: theme.Grid.gutter,
    },
    ':focus + label': {
      borderColor: theme.Input.focus.accent,
      boxShadow: `0 0 12px 0 ${theme.Input.focus.accent}`,
    },
  },
  label: {
    backgroundColor: theme.care.palette.surface.accentLighter,
    borderColor: theme.care.palette.border.lighter,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    boxSizing: 'border-box',
    color: theme.care.palette.text.positive,
    display: 'block',
    paddingBottom: `calc(${theme.spacing(0.4)} - 2px)`,
    paddingLeft: theme.Grid.gutter,
    paddingRight: `calc(${theme.Grid.gutter} * 2.2)`,
    paddingTop: `calc(${theme.spacing(0.4)} - 2px)`,
    transition: 'padding .2s ease',

    ':before': {
      display: 'none',
    },
  },
  activeIcon: {
    fill: 'white',
    top: '50%',
    marginTop: '-4px',
    left: '16px',
  },
})

export const MultipleChoiceField = connect(styleRules)(
  MultipleChoiceFieldComponent,
)
