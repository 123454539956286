// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Heading from 'react-ui/components/Heading'
import Card from 'shared/ui/Layout/Card/Card'
import { Button } from 'care-ui'

import styleRules from '../../styles'
import OnboardingTemplate from '../OnboardingTemplate'

import { type FelaRulesType } from 'react-ui/typing'

type PropsType = {
  handleWizardStart: () => void,
  pageContent?: string | Node,
  pageTitle?: string | Node,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
}

const OnboardingIntro = (props: PropsType) => {
  const {
    handleWizardStart,
    pageContent,
    pageTitle,
    rules,
    styles = {},
  } = props

  const { t: translation } = useTranslation('onboarding')

  return (
    <OnboardingTemplate pageTitle={pageTitle} pageContent={pageContent}>
      <Card className={styles.cardContainer}>
        <Heading level={3} extend={rules.cardTitleIntro}>
          {translation('about_you')}
        </Heading>

        <Button variant="primary" onClick={handleWizardStart}>
          {translation('start')}
        </Button>
      </Card>
    </OnboardingTemplate>
  )
}

export default connect(styleRules)(OnboardingIntro)
