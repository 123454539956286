// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  CreateMutationResponse,
  CreateMutationVariables,
} from './__generated__/CreateMutation.graphql'

type CommitArgs = {
  environment: Object,
  onCompleted?: CreateMutationResponse => void,
  onError?: (result: Object) => void,
  variables: CreateMutationVariables,
}

const mutation = graphql`
  mutation CreateMutation($input: SupportPersonAssignmentCreateInput!) {
    supportPersonAssignmentCreate(input: $input) {
      viewer {
        id
        currentUser {
          id
          current_role {
            ... on IndividualRole {
              id
              ...IndividualTasksCard_individual
            }
            ... on RoleInterface {
              id
              parent {
                ...TenantContext_parent
              }
              tenant {
                ...NotificationDisplay_tenant
              }
            }
          }
        }
      }
    }
  }
`

const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: CommitArgs) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}

export default commit
