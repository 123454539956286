// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import { withoutFelaProps } from 'shared/services/fela'

import type { FelaOptionalPropsType, FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => ({
  UserTrackable: {
    className: 'UserTrackable',
    color: theme.care.palette.text.positive,
    ...theme.care.typography.desktop.bodyLg,
  },
  formulaRange: {
    className: 'UserTrackable__formula-range',
    maxWidth: '100%',
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  trending: {
    className: 'UserTrackable__trending',
  },
  formulaRangeLabel: {
    ...theme.care.typography.desktop.bodyLgBold,
    marginBottom: theme.care.spacing.xs,
  },
})

type UserTrackableProps = {
  as?: string,
  formulaRange: {
    label: string,
    value: number,
  },
  staff?: boolean,
  timeSinceUpdated?: ?string,
  trending: {
    label: string,
  },
}

const UserTrackable = ({
  as: Component = 'div',
  formulaRange,
  trending,
  styles,
  timeSinceUpdated,
  staff,
  ...props
}: UserTrackableProps & FelaPropsType) => {
  const formulaRangeLabel = staff ? styles.formulaRangeLabel : ''

  return (
    <Component className={styles.UserTrackable} {...withoutFelaProps(props)}>
      <div className={styles.formulaRange}>
        <span className={formulaRangeLabel}>{formulaRange.label}</span>
        {!!timeSinceUpdated && <span> ({timeSinceUpdated})</span>}
      </div>
      {!!trending && <div className={styles.trending}>{trending.label}</div>}
    </Component>
  )
}

const Connected: React.StatelessFunctionalComponent<
  UserTrackableProps & FelaOptionalPropsType,
> = connect(styleRules)(UserTrackable)
export default Connected
