// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const SpeechBubbleIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 42 42"
        style={{ enableBackground: 'new 0 0 42 42' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g>
          <g transform="translate(-499.000000, -365.000000)">
            <g transform="translate(436.000000, 196.000000)">
              <g transform="translate(37.000000, 148.000000)">
                <g transform="translate(26.000000, 21.000000)">
                  <g>
                    <path
                      d="M13.5,15c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5S29.3,15,28.5,15H13.5z
							 M13.5,21c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5S29.3,21,28.5,21H13.5z M13.5,27
							c-0.8,0-1.5-0.7-1.5-1.5s0.7-1.5,1.5-1.5h15c0.8,0,1.5,0.7,1.5,1.5S29.3,27,28.5,27H13.5z M1.7,20.2C1.7,9,10.7,0,21.9,0
							S42,9,42,20.2s-9,20.2-20.2,20.2c-3.5,0-6.9-0.9-9.9-2.6l-9.8,4.2c-1.2,0.5-2.5-0.7-2-2l4.2-9.8C2.6,27.1,1.7,23.7,1.7,20.2z
							 M4.4,37.6l7.1-3c0.5-0.2,1-0.2,1.4,0.1c2.7,1.7,5.8,2.6,9,2.6c9.5,0,17.2-7.7,17.2-17.2S31.3,3,21.9,3S4.7,10.7,4.7,20.2
							c0,3.2,0.9,6.3,2.6,9c0.3,0.4,0.3,0.9,0.1,1.4L4.4,37.6z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default SpeechBubbleIcon
