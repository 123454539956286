/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SystemNotificationPopUp_notifications$ref = any;
export type UserNotificationAcknowledgeInput = {|
  clientMutationId?: ?string,
  ids: $ReadOnlyArray<string>,
|};
export type UserNotificationAcknowledgeMutationVariables = {|
  input: UserNotificationAcknowledgeInput
|};
export type UserNotificationAcknowledgeMutationResponse = {|
  +userNotificationAcknowledge: ?{|
    +viewer: {|
      +currentUser: ?{|
        +unacknowledged_pop_ups: $ReadOnlyArray<{|
          +$fragmentRefs: SystemNotificationPopUp_notifications$ref
        |}>
      |}
    |}
  |}
|};
export type UserNotificationAcknowledgeMutation = {|
  variables: UserNotificationAcknowledgeMutationVariables,
  response: UserNotificationAcknowledgeMutationResponse,
|};
*/


/*
mutation UserNotificationAcknowledgeMutation(
  $input: UserNotificationAcknowledgeInput!
) {
  userNotificationAcknowledge(input: $input) {
    viewer {
      currentUser {
        unacknowledged_pop_ups {
          ...SystemNotificationPopUp_notifications
          id
        }
        id
      }
      id
    }
  }
}

fragment SystemNotificationPopUp_notifications on PopUpNotification {
  id
  description
  image_url
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationAcknowledgeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationAcknowledgePayload",
        "kind": "LinkedField",
        "name": "userNotificationAcknowledge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopUpNotification",
                    "kind": "LinkedField",
                    "name": "unacknowledged_pop_ups",
                    "plural": true,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SystemNotificationPopUp_notifications"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNotificationAcknowledgeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationAcknowledgePayload",
        "kind": "LinkedField",
        "name": "userNotificationAcknowledge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PopUpNotification",
                    "kind": "LinkedField",
                    "name": "unacknowledged_pop_ups",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "description",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "image_url",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "deb5b46f1f2f7336a7b51f7ac64bc279",
    "id": null,
    "metadata": {},
    "name": "UserNotificationAcknowledgeMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotificationAcknowledgeMutation(\n  $input: UserNotificationAcknowledgeInput!\n) {\n  userNotificationAcknowledge(input: $input) {\n    viewer {\n      currentUser {\n        unacknowledged_pop_ups {\n          ...SystemNotificationPopUp_notifications\n          id\n        }\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment SystemNotificationPopUp_notifications on PopUpNotification {\n  id\n  description\n  image_url\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b21709769cc2254e52f1ea36c8423bf3';

module.exports = node;
