/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserResourceChangeState_user_resources$ref = any;
export type UserResourceEvents = "cancel_support_request" | "request_support" | "start_supporting" | "start_using_resource" | "stop_supporting" | "stop_using_resource" | "%future added value";
export type UserResourceChangeStateInput = {|
  clientMutationId?: ?string,
  event?: ?UserResourceEvents,
  resource_id: string,
  trackable_id?: ?string,
  user_id: string,
|};
export type UserResourceChangeStateMutationVariables = {|
  input: UserResourceChangeStateInput
|};
export type UserResourceChangeStateMutationResponse = {|
  +userResourceChangeState: ?{|
    +user_resource: {|
      +id: string,
      +$fragmentRefs: UserResourceChangeState_user_resources$ref,
    |}
  |}
|};
export type UserResourceChangeStateMutation = {|
  variables: UserResourceChangeStateMutationVariables,
  response: UserResourceChangeStateMutationResponse,
|};
*/


/*
mutation UserResourceChangeStateMutation(
  $input: UserResourceChangeStateInput!
) {
  userResourceChangeState(input: $input) {
    user_resource {
      ...UserResourceChangeState_user_resources
      id
    }
  }
}

fragment AvatarLoader_user on User {
  id
  email
  legal_name
}

fragment CareOption_resource on Resource {
  description
  id
  label
  url
}

fragment Resource_resource on Resource {
  ...CareOption_resource
}

fragment UserResourceChangeState_user_resources on UserResource {
  ...UserResource_user_resource
  status
  id
  resource {
    clinical
    id
  }
}

fragment UserResourceStatusUpdate_user_resource on UserResource {
  id
  resource {
    id
  }
  user {
    id
  }
  allowed_events {
    event_name
    disabled
  }
}

fragment UserResource_user_resource on UserResource {
  ...UserResourceStatusUpdate_user_resource
  id
  resource {
    ...Resource_resource
    clinical
    id
  }
  status
  user {
    ...AvatarLoader_user
    id
    name
  }
  supporting_clinician {
    user {
      ...AvatarLoader_user
      id
      name
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legal_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserResourceChangeStateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserResourceChangeStatePayload",
        "kind": "LinkedField",
        "name": "userResourceChangeState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserResource",
            "kind": "LinkedField",
            "name": "user_resource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserResourceChangeState_user_resources"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserResourceChangeStateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserResourceChangeStatePayload",
        "kind": "LinkedField",
        "name": "userResourceChangeState",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserResource",
            "kind": "LinkedField",
            "name": "user_resource",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Resource",
                "kind": "LinkedField",
                "name": "resource",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "clinical",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserResourceAllowedEvent",
                "kind": "LinkedField",
                "name": "allowed_events",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "event_name",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "disabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "status",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ClinicianRole",
                "kind": "LinkedField",
                "name": "supporting_clinician",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "652964e3291ac60f8b8f9c3d2c9bd1dc",
    "id": null,
    "metadata": {},
    "name": "UserResourceChangeStateMutation",
    "operationKind": "mutation",
    "text": "mutation UserResourceChangeStateMutation(\n  $input: UserResourceChangeStateInput!\n) {\n  userResourceChangeState(input: $input) {\n    user_resource {\n      ...UserResourceChangeState_user_resources\n      id\n    }\n  }\n}\n\nfragment AvatarLoader_user on User {\n  id\n  email\n  legal_name\n}\n\nfragment CareOption_resource on Resource {\n  description\n  id\n  label\n  url\n}\n\nfragment Resource_resource on Resource {\n  ...CareOption_resource\n}\n\nfragment UserResourceChangeState_user_resources on UserResource {\n  ...UserResource_user_resource\n  status\n  id\n  resource {\n    clinical\n    id\n  }\n}\n\nfragment UserResourceStatusUpdate_user_resource on UserResource {\n  id\n  resource {\n    id\n  }\n  user {\n    id\n  }\n  allowed_events {\n    event_name\n    disabled\n  }\n}\n\nfragment UserResource_user_resource on UserResource {\n  ...UserResourceStatusUpdate_user_resource\n  id\n  resource {\n    ...Resource_resource\n    clinical\n    id\n  }\n  status\n  user {\n    ...AvatarLoader_user\n    id\n    name\n  }\n  supporting_clinician {\n    user {\n      ...AvatarLoader_user\n      id\n      name\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '715cf548fa9a78fa92282c04ac623ade';

module.exports = node;
