/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AboutMeTab_user$ref = any;
type ChartHeader_individual$ref = any;
type HealthCardsTab_user$ref = any;
type HealthHistoryTab_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserDashboardPage_user$ref: FragmentReference;
declare export opaque type UserDashboardPage_user$fragmentType: UserDashboardPage_user$ref;
export type UserDashboardPage_user = {|
  +id: string,
  +linked_individual: ?{|
    +$fragmentRefs: ChartHeader_individual$ref
  |},
  +$fragmentRefs: AboutMeTab_user$ref & HealthHistoryTab_user$ref & HealthCardsTab_user$ref,
  +$refType: UserDashboardPage_user$ref,
|};
export type UserDashboardPage_user$data = UserDashboardPage_user;
export type UserDashboardPage_user$key = {
  +$data?: UserDashboardPage_user$data,
  +$fragmentRefs: UserDashboardPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "aboutMe"
    },
    {
      "kind": "RootArgument",
      "name": "healthHistory"
    },
    {
      "kind": "RootArgument",
      "name": "trackables"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDashboardPage_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "linked_individual",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChartHeader_individual"
        }
      ],
      "storageKey": null
    },
    {
      "condition": "aboutMe",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AboutMeTab_user"
        }
      ]
    },
    {
      "condition": "healthHistory",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HealthHistoryTab_user"
        }
      ]
    },
    {
      "condition": "trackables",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HealthCardsTab_user"
        }
      ]
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '216d2953d114d8d2133efb8b36a78f43';

module.exports = node;
