/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthHistory_user$ref = any;
type MyEscalationsTable_escalations$ref = any;
type StratifiedScoringResultHistory_stratified_scoring_result$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthHistoryTab_user$ref: FragmentReference;
declare export opaque type HealthHistoryTab_user$fragmentType: HealthHistoryTab_user$ref;
export type HealthHistoryTab_user = {|
  +escalations: $ReadOnlyArray<{|
    +$fragmentRefs: MyEscalationsTable_escalations$ref
  |}>,
  +stratified_scoring_result: ?{|
    +$fragmentRefs: StratifiedScoringResultHistory_stratified_scoring_result$ref
  |},
  +tenant: ?{|
    +stratified_scoring: boolean
  |},
  +$fragmentRefs: HealthHistory_user$ref,
  +$refType: HealthHistoryTab_user$ref,
|};
export type HealthHistoryTab_user$data = HealthHistoryTab_user;
export type HealthHistoryTab_user$key = {
  +$data?: HealthHistoryTab_user$data,
  +$fragmentRefs: HealthHistoryTab_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthHistoryTab_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Escalation",
      "kind": "LinkedField",
      "name": "escalations",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MyEscalationsTable_escalations"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "StratifiedScoringResult",
      "kind": "LinkedField",
      "name": "stratified_scoring_result",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StratifiedScoringResultHistory_stratified_scoring_result"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stratified_scoring",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthHistory_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e834eafd6b294d2f6f136644ea6f55f0';

module.exports = node;
