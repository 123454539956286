// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserQuestionnaireSkipAndDoAgainMutationResponse,
  UserQuestionnaireSkipAndDoAgainMutationVariables,
} from './__generated__/UserQuestionnaireSkipAndDoAgainMutation.graphql'

const mutation = graphql`
  mutation UserQuestionnaireSkipAndDoAgainMutation(
    $input: UserQuestionnaireSkipInput!
  ) {
    userQuestionnaireSkip(input: $input) {
      user_questionnaire {
        first_user_questionnaires_question_set {
          finished
          questionnaires_question_set {
            id
          }
        }
        questionnaire {
          id
        }
        id
        ready_to_complete
        ...UserQuestionnaire_user_questionnaire
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (
    result: UserQuestionnaireSkipAndDoAgainMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: UserQuestionnaireSkipAndDoAgainMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
