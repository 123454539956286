// @flow

import { createFragmentContainer, graphql } from 'react-relay'

import PureUserActivityCard from './UserActivityCard'

const UserActivityCardLoader = createFragmentContainer(PureUserActivityCard, {
  user: graphql`
    fragment UserActivityCardLoader_user on User {
      ...UserCardLoader_user
      ...ClinicianNoteActivity_user
    }
  `,
})

export default UserActivityCardLoader
