// @flow

import { useState } from 'react'

export function useLoading(initialValue: boolean = false) {
  const [isLoading, setLoading] = useState(initialValue)
  const startLoading = () => setLoading(true)
  const endLoading = () => setLoading(false)
  return { isLoading, startLoading, endLoading }
}
