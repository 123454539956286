/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type MultiFactorAuthentication_user$ref: FragmentReference;
declare export opaque type MultiFactorAuthentication_user$fragmentType: MultiFactorAuthentication_user$ref;
export type MultiFactorAuthentication_user = {|
  +mfa_qr_code: string,
  +mfa_last_verified_at: ?any,
  +$refType: MultiFactorAuthentication_user$ref,
|};
export type MultiFactorAuthentication_user$data = MultiFactorAuthentication_user;
export type MultiFactorAuthentication_user$key = {
  +$data?: MultiFactorAuthentication_user$data,
  +$fragmentRefs: MultiFactorAuthentication_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MultiFactorAuthentication_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mfa_qr_code",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "mfa_last_verified_at",
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd782952376d87965fc477ff374956a94';

module.exports = node;
