// @flow

import React, { type Node, useState } from 'react'
import { connect, useFela } from 'react-fela'

import Markdown from 'react-ui/components/Markdown'
import * as colors from 'react-ui/themes/synergy-base-theme/colors'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = {
  children?: Node,
  notifications: any,
}

type NotificationNavType = {
  index: number,
  onClick: (i: number) => void,
} & PropsType &
  FelaPropsType

type NotificationListType = {
  index: number,
  onClick: (i: number) => void,
} & PropsType &
  FelaPropsType

const containerRule = () => ({
  width: '600px',
  overflow: 'hidden',
})

const notificationListRules = () => ({
  List: {
    display: 'flex',
    flexDirection: 'row',
    margin: 0,
    padding: 0,
    transition: '0.5s',
  },
  Item: {
    display: 'inline-block',
    flex: '1 1 1',
    width: '100%',
  },
  ItemImage: {
    display: 'block',
    height: '337px',
    width: '100%',
    boxShadow: '0px 9px 15px -12px #000000',
  },
  ItemBody: {
    display: 'block',
    textAlign: 'center',
    margin: '40px 0',
    '> h3': {
      marginBottom: '40px',
      marginTop: 0,
    },
    '> p': {
      marginTop: 0,
    },
  },
})

const notificationNavRules = ({ theme }) => ({
  Navs: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    margin: 0,
    padding: theme.Grid.gutter,
  },
  Nav: {
    margin: `0 calc(${theme.Grid.gutter} / 4)`,
    display: 'inline-block',
    '> button': {
      cursor: 'pointer',
      height: '24px',
      width: '24px',
      backgroundColor: 'none',
      border: 0,
    },
    '> button:after': {
      backgroundColor: colors.greyscale['200'],
      borderRadius: '10px',
      content: '""',
      display: 'inline-block',
      margin: '8px',
      padding: '4px',
    },
  },
  ActiveNav: {
    '> button:after': {
      backgroundColor: colors.black,
    },
  },
})

const NotificationNav = connect(notificationNavRules)(
  ({ notifications, styles, index, onClick }: NotificationNavType) => (
    <ul className={styles.Navs}>
      {notifications.map((notification, i) => (
        <li
          className={`${styles.Nav} ${(i === index && styles.ActiveNav) || ''}`}
          key={notification.id}
        >
          <button type="button" onClick={onClick(i)} />
        </li>
      ))}
    </ul>
  ),
)

const NotificationList = connect(notificationListRules)(
  ({ notifications, styles }: NotificationListType) => {
    return (
      <ul className={styles.List}>
        {notifications.map(notification => (
          <li className={styles.Item} key={notification.id}>
            {notification.image_url && (
              <img
                alt={notification.image_url}
                src={notification.image_url}
                className={styles.ItemImage}
              />
            )}
            <div className={styles.ItemBody}>
              <Markdown source={notification.description} />
            </div>
          </li>
        ))}
      </ul>
    )
  },
)

export const NotificationCarousel = ({ notifications }: PropsType) => {
  const [index, setIndex] = useState(0)
  const { css } = useFela()

  if (notifications.length < 1) return null

  const width = `${notifications.length * 100}%`
  const marginLeft = `${-index * 100}%`

  return (
    <div className={css(containerRule)}>
      <NotificationList
        notifications={notifications}
        extend={{ List: { width, marginLeft } }}
      />
      {notifications.length > 1 && (
        <NotificationNav
          notifications={notifications}
          index={index}
          onClick={i => () => setIndex(i)}
        />
      )}
    </div>
  )
}
