// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import IndividualTasksItem from './IndividualTasksItem'

import type { ClinicianInputTask_individual } from './__generated__/ClinicianInputTask_individual.graphql'

type PropsType = {
  individual: ClinicianInputTask_individual,
}

const ClinicianInputTaskComponent = (props: PropsType) => {
  const { individual: { tenant, user } } = props

  const { t: translation } = useTranslation('dashboard')
  const questionnaireId = tenant?.summary_questionnaire?.id || ''
  const answereeUserId = user.id

  const { start } = useQuestionnaireSession({
    answereeUserId,
    answererRoleType: 'CLINICIAN',
    questionnaireId,
  })

  if (
    !tenant ||
    !tenant.summary_questionnaire ||
    !tenant.summary_questionnaire.answerable
  )
    return null

  return (
    <IndividualTasksItem header={translation('give_input')} onClick={start}>
      {translation('provide_feedback_to_mark_progress_of_your_service')}
    </IndividualTasksItem>
  )
}

export default createFragmentContainer(ClinicianInputTaskComponent, {
  individual: graphql`
    fragment ClinicianInputTask_individual on IndividualRole {
      user {
        id
      }
      tenant {
        summary_questionnaire {
          id
          answerable
        }
      }
    }
  `,
})
