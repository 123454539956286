// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query AssessmentRequestsLibraryQuery($id: ID!, $requesteeRoleId: ID) {
    user(id: $id) {
      linked_individual {
        id
        requestableQuestionnaires(requesteeRoleId: $requesteeRoleId) {
          id
          label
          library_description
          category
        }
      }
    }
  }
`
