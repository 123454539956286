// @flow

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'

type RoleType = 'MANAGER'

type TableType = 'Active' | 'Invited' | 'Removed'

type ACTIONS_FOR_CLINICIAN_TYPE = Array<{
  actionName: string,
  roles: Array<RoleType>,
  tableTypes: Array<TableType>,
}>

const ACTIONS_FOR_CLINICIAN: ACTIONS_FOR_CLINICIAN_TYPE = [
  {
    actionName: 'removeRole',
    tableTypes: ['Active', 'Invited'],
    roles: ['MANAGER'],
  },
  {
    actionName: 'resendRoleInvitation',
    tableTypes: ['Invited', 'Removed'],
    roles: ['MANAGER'],
  },
]

type UseEmployessActionProps = {
  tableType: TableType | string,
}
const useEmployeesActions = (props: UseEmployessActionProps) => {
  const { tableType } = props

  const {
    isMultiRolesEnabled,
    roleType: singleRoleType,
    currentUserRoles,
  } = useCurrentUserRoles()

  const roleTypes = isMultiRolesEnabled ? currentUserRoles : [singleRoleType]

  const getAllowedActions = () => {
    // Step 1: Filter actions based on table type
    const tableTypeFilteredActions = ACTIONS_FOR_CLINICIAN.filter(action =>
      action.tableTypes.includes(tableType),
    )

    // Step 2: Further filter actions based on role types
    const roleFilteredActions = tableTypeFilteredActions.filter(action =>
      roleTypes.some(role => action.roles.includes(role)),
    )

    // Step 3: Map the filtered actions to extract action names
    const allowedActions: Array<string> = roleFilteredActions.map(
      action => action.actionName,
    )

    return allowedActions
  }

  return {
    actions: getAllowedActions(),
  }
}

export default useEmployeesActions
