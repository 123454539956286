/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type QuestionSetCard_questionSet$ref = any;
type StaticQuestionnaireInformation_questionSet$ref = any;
type StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$ref = any;
type UserQuestionnaireQuestionSetFooter_userQuestionnaire$ref = any;
type UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$ref = any;
export type QuestionKinds = "DATE" | "IMPERIAL_LENGTH" | "INTEGER" | "MULTICHOICE" | "MULTISELECT" | "NUMBER" | "STRING" | "SUBURB_AUTOCOMPLETE" | "TEXT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnairesQuestionSet_user_questionnaires_question_set$ref: FragmentReference;
declare export opaque type UserQuestionnairesQuestionSet_user_questionnaires_question_set$fragmentType: UserQuestionnairesQuestionSet_user_questionnaires_question_set$ref;
export type UserQuestionnairesQuestionSet_user_questionnaires_question_set = {|
  +id: string,
  +all_questions_answered: boolean,
  +finished: boolean,
  +skippable: boolean,
  +user_questionnaire: {|
    +finished: boolean,
    +assessment_request: ?{|
      +id: string
    |},
    +questionnaire: ?{|
      +id: string
    |},
    +$fragmentRefs: UserQuestionnaireQuestionSetFooter_userQuestionnaire$ref,
  |},
  +next_user_questionnaires_question_set: ?{|
    +questionnaires_question_set: {|
      +id: string
    |}
  |},
  +questionnaires_question_set: {|
    +id: string,
    +questionnaire: {|
      +id: string
    |},
  |},
  +question_set: {|
    +description: ?string,
    +label: string,
    +$fragmentRefs: StaticQuestionnaireInformation_questionSet$ref,
  |},
  +questions: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +answer: ?{|
        +id?: string,
        +value?: number,
      |},
      +node: ?{|
        +id: string,
        +conditionals: $ReadOnlyArray<{|
          +question_option: {|
            +id: string,
            +question: {|
              +id: string
            |},
          |}
        |}>,
        +condition_scenario: ?{|
          +equation: string
        |},
        +jsonSchema: ?{|
          +enum?: ?$ReadOnlyArray<string>,
          +enumNames?: ?$ReadOnlyArray<string>,
          +enumValues?: ?$ReadOnlyArray<number>,
          +maximum?: ?number,
          +minimum?: ?number,
          +feet_max_value?: ?number,
          +feet_min_value?: ?number,
          +inches_max_value?: ?number,
          +inches_min_value?: ?number,
          +minLength?: ?string,
          +maxLength?: ?string,
        |},
        +kind: QuestionKinds,
        +label: ?string,
        +image: ?{|
          +url: string,
          +alt_txt: string,
        |},
        +required: boolean,
        +uiSchema: ?{|
          +uiWidget: ?string
        |},
      |},
    |}>
  |},
  +user: {|
    +id: string
  |},
  +visible_questions: ?{|
    +edges: ?$ReadOnlyArray<?{|
      +answer: ?{|
        +id?: string,
        +value?: number,
      |},
      +node: ?{|
        +id: string,
        +conditionals: $ReadOnlyArray<{|
          +question_option: {|
            +id: string,
            +question: {|
              +id: string
            |},
          |}
        |}>,
        +condition_scenario: ?{|
          +equation: string
        |},
        +jsonSchema: ?{|
          +enum?: ?$ReadOnlyArray<string>,
          +enumNames?: ?$ReadOnlyArray<string>,
          +enumValues?: ?$ReadOnlyArray<number>,
          +maximum?: ?number,
          +minimum?: ?number,
          +feet_max_value?: ?number,
          +feet_min_value?: ?number,
          +inches_max_value?: ?number,
          +inches_min_value?: ?number,
          +minLength?: ?string,
          +maxLength?: ?string,
        |},
        +kind: QuestionKinds,
        +label: ?string,
        +image: ?{|
          +url: string,
          +alt_txt: string,
        |},
        +required: boolean,
        +uiSchema: ?{|
          +uiWidget: ?string
        |},
      |},
    |}>
  |},
  +$fragmentRefs: QuestionSetCard_questionSet$ref & UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$ref & StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$ref,
  +$refType: UserQuestionnairesQuestionSet_user_questionnaires_question_set$ref,
|};
export type UserQuestionnairesQuestionSet_user_questionnaires_question_set$data = UserQuestionnairesQuestionSet_user_questionnaires_question_set;
export type UserQuestionnairesQuestionSet_user_questionnaires_question_set$key = {
  +$data?: UserQuestionnairesQuestionSet_user_questionnaires_question_set$data,
  +$fragmentRefs: UserQuestionnairesQuestionSet_user_questionnaires_question_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Questionnaire",
  "kind": "LinkedField",
  "name": "questionnaire",
  "plural": false,
  "selections": (v2/*: any*/),
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enum",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enumNames",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enumValues",
    "storageKey": null
  }
],
v7 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "UserQuestionnairesQuestionSetQuestionEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": null,
        "kind": "LinkedField",
        "name": "answer",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "AnswerInteger",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "AnswerImperialLength",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "AnswerString",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "AnswerMultichoice",
            "abstractKey": null
          },
          {
            "kind": "InlineFragment",
            "selections": (v5/*: any*/),
            "type": "AnswerMultiselect",
            "abstractKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "Question",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Conditional",
            "kind": "LinkedField",
            "name": "conditionals",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionOption",
                "kind": "LinkedField",
                "name": "question_option",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Question",
                    "kind": "LinkedField",
                    "name": "question",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "ConditionScenario",
            "kind": "LinkedField",
            "name": "condition_scenario",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "equation",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "jsonSchema",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "JsonSchemaMultichoice",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v6/*: any*/),
                "type": "JsonSchemaMultiselect",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maximum",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minimum",
                    "storageKey": null
                  }
                ],
                "type": "JsonSchemaInteger",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feet_max_value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "feet_min_value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inches_max_value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "inches_min_value",
                    "storageKey": null
                  }
                ],
                "type": "JsonSchemaImperialLength",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "minLength",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "maxLength",
                    "storageKey": null
                  }
                ],
                "type": "JsonSchemaString",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "kind",
            "storageKey": null
          },
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Image",
            "kind": "LinkedField",
            "name": "image",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "url",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "alt_txt",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "required",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "UiSchema",
            "kind": "LinkedField",
            "name": "uiSchema",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "uiWidget",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnairesQuestionSet_user_questionnaires_question_set",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "all_questions_answered",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skippable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "user_questionnaire",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AssessmentRequest",
          "kind": "LinkedField",
          "name": "assessment_request",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserQuestionnaireQuestionSetFooter_userQuestionnaire"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "next_user_questionnaires_question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionnairesQuestionSet",
          "kind": "LinkedField",
          "name": "questionnaires_question_set",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "questionnaires_question_set",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        (v4/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "StaticQuestionnaireInformation_questionSet"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSetQuestionConnection",
      "kind": "LinkedField",
      "name": "questions",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v2/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSetQuestionConnection",
      "kind": "LinkedField",
      "name": "visible_questions",
      "plural": false,
      "selections": (v7/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionSetCard_questionSet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet"
    }
  ],
  "type": "UserQuestionnairesQuestionSet",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f5e6192a8f97e7a6f88753b56b4089e3';

module.exports = node;
