// @flow

import { graphql } from 'react-relay'

export const query = {
  user_resource: graphql`
    fragment UserResourceStatusUpdate_user_resource on UserResource {
      id
      resource {
        id
      }
      user {
        id
      }
      allowed_events {
        event_name
        disabled
      }
    }
  `,
}
