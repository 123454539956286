// @flow

import React from 'react'

import SimpleSelect from 'care-ui/organisms/SimpleSelect/SimpleSelect'

type TableTypeSelectControlPropsType = {
  onChange: (tableType: string) => void,
  options: Array<any>,
  tableType: string,
}

const TableTypeSelectControl = (props: TableTypeSelectControlPropsType) => {
  const { options, tableType, onChange } = props

  return (
    <SimpleSelect
      options={options}
      onChange={value => onChange(value)}
      defaultOption={options.find(option => option.value === tableType)}
      dataTestIdForDropdown="statusDropdown"
      dataTestIdForDropdownOptions="status"
    />
  )
}

export default TableTypeSelectControl
