// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import SimpleTab from 'react-ui/components/Tabs/SimpleTab'
import Tab from 'react-ui/components/Tabs/Tab'

import type { FelaOptionalPropsType, FelaPropsType } from 'react-ui/typing'

export type TabData = {
  +id: string,
  +label: string,
}

export type BaseTabsProps = {
  componentId?: string,
  possibleTabs: $ReadOnlyArray<TabData>,
  simple?: boolean,
}

type TabsPropsType = BaseTabsProps & {
  currentTabId: string,
  onChange: (tabId: string) => void,
}

export type ExternalTabsPropsType = TabsPropsType & FelaOptionalPropsType

const styleRules = ({ theme }) => ({
  Tabs: {
    className: 'Tabs',
    alignItems: 'flex-end',
    display: 'flex',
    listStyleType: 'none',
    marginTop: 0,
    paddingLeft: 0,
    position: 'absolute',
    top: 0,
    transform: 'translateY(-100%)',
    [theme.breakpoints.queries.xsOnly]: {
      transform: 'translateY(-100%)',
    },
  },
})

const Tabs = ({
  possibleTabs,
  currentTabId,
  styles = {},
  componentId = 'Tabs',
  onChange,
  simple,
}: TabsPropsType & FelaPropsType) => {
  const Component = simple ? SimpleTab : Tab

  return (
    <Container>
      <ul className={styles.Tabs} data-testid={componentId}>
        {possibleTabs.map(item => (
          <li key={item.id}>
            <Component
              isSelected={item.id === currentTabId}
              onClick={() => onChange(item.id)}
            >
              {item.label}
            </Component>
          </li>
        ))}
      </ul>
    </Container>
  )
}

const StyledTabs: React.StatelessFunctionalComponent<
  ExternalTabsPropsType,
> = connect(styleRules)(Tabs)

export default StyledTabs
