// @flow

import { graphql } from 'react-relay'

export const answerSetsAnswerSets = {
  answer_sets: graphql`
    fragment AnswerSets_answer_sets on AnswerSet @relay(plural: true) {
      ...AnswerSet_answer_set
      id
      submitted_at
    }
  `,
}
