// @flow

export const styleRules = () => ({
  container: {
    padding: '30px',
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: '10rem',
    margin: 'auto',
    height: 'auto',
  },
  list: {
    margin: '20px 30px',
    '>li': {
      padding: '5px 0 ',
    },
  },
  note: {
    textAlign: 'center',
    margin: '20px 0',
    padding: '0 15%',
    lineHeight: '20px',
  },
  buttonGroup: {
    marginTop: '20px',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '>a': {
      margin: '10px',
    },
    '>button': {
      margin: '10px',
    },
  },
})
