/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffLeadIndividualSelectionList_tenant$ref = any;
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type StaffLeadIndividualSelectionList_PaginatedQueryVariables = {|
  count: number,
  cursor?: ?string,
  parentId: string,
  search?: ?string,
  assignmentStatus?: ?boolean,
  sort?: ?$ReadOnlyArray<RoleSortable>,
|};
export type StaffLeadIndividualSelectionList_PaginatedQueryResponse = {|
  +viewer: {|
    +tenant: {|
      +$fragmentRefs: StaffLeadIndividualSelectionList_tenant$ref
    |}
  |}
|};
export type StaffLeadIndividualSelectionList_PaginatedQuery = {|
  variables: StaffLeadIndividualSelectionList_PaginatedQueryVariables,
  response: StaffLeadIndividualSelectionList_PaginatedQueryResponse,
|};
*/


/*
query StaffLeadIndividualSelectionList_PaginatedQuery(
  $count: Int!
  $cursor: String
  $parentId: ID!
  $search: String
  $assignmentStatus: Boolean
  $sort: [RoleSortable!]
) {
  viewer {
    tenant(id: $parentId) {
      ...StaffLeadIndividualSelectionList_tenant
      id
    }
    id
  }
}

fragment StaffLeadIndividualSelectionList_tenant on Tenant {
  id
  roles(roleTypes: [INDIVIDUAL], assignmentStatus: $assignmentStatus, first: $count, after: $cursor, search: $search, sort: $sort, status: "active", excludeDischarged: true) {
    edges {
      node {
        __typename
        ... on IndividualRole {
          id
          legal_name
          user {
            id
            username
          }
        }
        ... on AdminRole {
          id
        }
        ... on ClinicianRole {
          id
        }
        ... on LeadClinicianRole {
          id
        }
        ... on ManagerRole {
          id
        }
        ... on OwnerRole {
          id
        }
        ... on ResearcherRole {
          id
        }
        ... on SuperRole {
          id
        }
        ... on SupportPersonRole {
          id
        }
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assignmentStatus"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "parentId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v6 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "parentId"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "assignmentStatus",
    "variableName": "assignmentStatus"
  },
  {
    "kind": "Literal",
    "name": "excludeDischarged",
    "value": true
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "roleTypes",
    "value": [
      "INDIVIDUAL"
    ]
  },
  {
    "kind": "Variable",
    "name": "search",
    "variableName": "search"
  },
  {
    "kind": "Variable",
    "name": "sort",
    "variableName": "sort"
  },
  {
    "kind": "Literal",
    "name": "status",
    "value": "active"
  }
],
v9 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffLeadIndividualSelectionList_PaginatedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StaffLeadIndividualSelectionList_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "StaffLeadIndividualSelectionList_PaginatedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v6/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "RoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "legal_name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  (v7/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "username",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "IndividualRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "AdminRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "ClinicianRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "LeadClinicianRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "ManagerRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "OwnerRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "ResearcherRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "SuperRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v9/*: any*/),
                            "type": "SupportPersonRole",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "searchTerm"
                ],
                "handle": "connection",
                "key": "tenantIndividuals_roles",
                "kind": "LinkedHandle",
                "name": "roles"
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0d1ff9d0d2b4cb0ccb34a274d08e7526",
    "id": null,
    "metadata": {},
    "name": "StaffLeadIndividualSelectionList_PaginatedQuery",
    "operationKind": "query",
    "text": "query StaffLeadIndividualSelectionList_PaginatedQuery(\n  $count: Int!\n  $cursor: String\n  $parentId: ID!\n  $search: String\n  $assignmentStatus: Boolean\n  $sort: [RoleSortable!]\n) {\n  viewer {\n    tenant(id: $parentId) {\n      ...StaffLeadIndividualSelectionList_tenant\n      id\n    }\n    id\n  }\n}\n\nfragment StaffLeadIndividualSelectionList_tenant on Tenant {\n  id\n  roles(roleTypes: [INDIVIDUAL], assignmentStatus: $assignmentStatus, first: $count, after: $cursor, search: $search, sort: $sort, status: \"active\", excludeDischarged: true) {\n    edges {\n      node {\n        __typename\n        ... on IndividualRole {\n          id\n          legal_name\n          user {\n            id\n            username\n          }\n        }\n        ... on AdminRole {\n          id\n        }\n        ... on ClinicianRole {\n          id\n        }\n        ... on LeadClinicianRole {\n          id\n        }\n        ... on ManagerRole {\n          id\n        }\n        ... on OwnerRole {\n          id\n        }\n        ... on ResearcherRole {\n          id\n        }\n        ... on SuperRole {\n          id\n        }\n        ... on SupportPersonRole {\n          id\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '89d98e7d8cfc3de7f3f5c84a68aec9c5';

module.exports = node;
