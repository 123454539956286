// @flow

import React, { type Node } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import styles from './QuestionnaireRefresh.scss'

import type { QuestionnaireRefresh_questionnaire } from './__generated__/QuestionnaireRefresh_questionnaire.graphql'

type PropsType = {
  cta: Node,
  questionnaire: QuestionnaireRefresh_questionnaire,
}

export const QuestionnaireRefreshBase = (props: PropsType) => {
  const { cta, questionnaire: { id: questionnaireId } } = props

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  return (
    <div
      className={styles.buttonContainer}
      role="button"
      tabIndex="0"
      onKeyPress={start}
      onClick={start}
      data-testid="QuestionnaireRefresh"
    >
      {cta}
    </div>
  )
}

export const QuestionnaireRefreshLoader = createFragmentContainer(
  QuestionnaireRefreshBase,
  {
    questionnaire: graphql`
      fragment QuestionnaireRefresh_questionnaire on Questionnaire {
        id
      }
    `,
  },
)
