/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AssessmentRequestsComponent_assessment_request$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssessmentRequestsList_individual_user$ref: FragmentReference;
declare export opaque type AssessmentRequestsList_individual_user$fragmentType: AssessmentRequestsList_individual_user$ref;
export type AssessmentRequestsList_individual_user = {|
  +id: string,
  +current_role: {|
    +assessment_requested?: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +$fragmentRefs: AssessmentRequestsComponent_assessment_request$ref
        |}
      |}>
    |}
  |},
  +$refType: AssessmentRequestsList_individual_user$ref,
|};
export type AssessmentRequestsList_individual_user$data = AssessmentRequestsList_individual_user;
export type AssessmentRequestsList_individual_user$key = {
  +$data?: AssessmentRequestsList_individual_user$data,
  +$fragmentRefs: AssessmentRequestsList_individual_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "current_role",
  "assessment_requested"
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": 2,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./UserAssessmentRequestsListPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "AssessmentRequestsList_individual_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "assessment_requested",
              "args": null,
              "concreteType": "AssessmentRequestConnection",
              "kind": "LinkedField",
              "name": "__AssessmentRequestsList_individual_assessment_requested_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssessmentRequestEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssessmentRequest",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "AssessmentRequestsComponent_assessment_request"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "IndividualRole",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '8fd8ede9e92460cea231f163bfb57c1b';

module.exports = node;
