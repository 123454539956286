// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'
import cx from 'classnames'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  children: Node,
  className: string,
  componentId?: string,
  handleClose?: Function,
  onClick?: Function,
}

const styleRules = ({ theme, onClick }) => ({
  container: {
    alignItems: 'center',
    backgroundColor: theme.palette.headingTextColor,
    border: '0',
    color: theme.palette.bodyBackgroundColor,
    cursor: onClick && 'pointer',
    display: 'flex',
    fontSize: 'inherit',
    justifyContent: 'center',
    minHeight: '98px',
    padding: '15px 0 ',
    flexDirection: 'column',
    ':hover': {
      opacity: '98%',
    },
  },
})

const Banner = (props: PropsType) => {
  const { className, componentId, styles, children, onClick } = props
  const Component = onClick ? 'button' : 'div'
  const role = onClick ? 'button' : 'banner'
  return (
    <Component
      data-component-id={componentId}
      data-testid={componentId}
      type={onClick && 'button'}
      onClick={onClick}
      className={cx(styles.container, className)}
      role={role}
    >
      {children}
    </Component>
  )
}

export default connect(styleRules)(Banner)
