// @flow

import { jsonLocalStorage } from 'services/browserStorage'

export const addSessionTimeoutToLocalStorage = (response: Response) => {
  const timeoutAt = parseInt(response.headers.get('X-Session-Timeout'), 10)
  if (timeoutAt) {
    jsonLocalStorage.setItem('sessionTimeoutAt', timeoutAt)
  }

  return response
}
