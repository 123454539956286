/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserResource_user_resource$ref = any;
type UserTrackableRawScore_user_trackable$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CurrentResultCardFormula_user_trackable$ref: FragmentReference;
declare export opaque type CurrentResultCardFormula_user_trackable$fragmentType: CurrentResultCardFormula_user_trackable$ref;
export type CurrentResultCardFormula_user_trackable = {|
  +id: string,
  +escalated: boolean,
  +assigned_to_me: boolean,
  +time_since_updated: ?string,
  +suggested_user_resources: $ReadOnlyArray<{|
    +status: UserResourceStatuses,
    +id: string,
    +resource: {|
      +clinical: boolean,
      +id: string,
      +label: string,
    |},
    +$fragmentRefs: UserResource_user_resource$ref,
  |}>,
  +resolution_option_list: $ReadOnlyArray<{|
    +key: string,
    +value: string,
    +enabled: boolean,
  |}>,
  +formula_range: ?{|
    +color?: string,
    +label?: string,
    +description?: ?string,
    +id?: string,
  |},
  +$fragmentRefs: UserTrackableRawScore_user_trackable$ref,
  +$refType: CurrentResultCardFormula_user_trackable$ref,
|};
export type CurrentResultCardFormula_user_trackable$data = CurrentResultCardFormula_user_trackable;
export type CurrentResultCardFormula_user_trackable$key = {
  +$data?: CurrentResultCardFormula_user_trackable$data,
  +$fragmentRefs: CurrentResultCardFormula_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentResultCardFormula_user_trackable",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "escalated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assigned_to_me",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time_since_updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResource",
      "kind": "LinkedField",
      "name": "suggested_user_resources",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clinical",
              "storageKey": null
            },
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserResource_user_resource"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SelectOption",
      "kind": "LinkedField",
      "name": "resolution_option_list",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "formula_range",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "FormulaRange",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "type": "FormulaRangeInterface",
          "abstractKey": "__isFormulaRangeInterface"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserTrackableRawScore_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '093e5bc9fa64b38233c7c74960ab5416';

module.exports = node;
