// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query UserNotificationsPage_Query {
    viewer {
      currentUser {
        ...UserNotificationsPage_user
      }
    }
  }
`
export const fragment = graphql`
  fragment UserNotificationsPage_user on User {
    notification_preference {
      sms
      id
    }
  }
`
