// @flow

import React, { Fragment } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { AnswerSetsLoader } from 'components/AnswerSets'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'

import type { FelaStylesType } from 'react-ui/typing'
import type { UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set } from './__generated__/UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set.graphql'

type PropsType = {
  +relay: Object,
  styles: FelaStylesType,
  +user_questionnaires_question_set: UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set,
}

const UserQuestionnairesQuestionSetDetailsPage = (props: PropsType) => {
  const {
    user_questionnaires_question_set: {
      submitted_answer_sets,
      questionnaires_question_set,
    },
  } = props

  return (
    <Fragment>
      <Section color="white">
        <Container>
          <Heading level={4}>
            {questionnaires_question_set.questionnaire.label}
          </Heading>
          <Heading level={2}>
            {questionnaires_question_set.question_set.label}
          </Heading>
        </Container>
      </Section>
      <Section color="default">
        <Container>
          <AnswerSetsLoader answer_sets={submitted_answer_sets} />
        </Container>
      </Section>
    </Fragment>
  )
}

export const UserQuestionnairesQuestionSetDetailsPageQuery = graphql`
  query UserQuestionnairesQuestionSetDetailsPage_Query(
    $id: ID!
    $questionnaires_question_set_id: ID!
  ) {
    user(id: $id, source: "questionnaire") {
      user_questionnaires_question_set(
        questionnaires_question_set_id: $questionnaires_question_set_id
      ) {
        ...UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set
      }
    }
  }
`

export const UserQuestionnairesQuestionSetDetailsPageLoader = createFragmentContainer(
  UserQuestionnairesQuestionSetDetailsPage,
  {
    user_questionnaires_question_set: graphql`
      fragment UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set on UserQuestionnairesQuestionSet {
        id
        submitted_answer_sets {
          ...AnswerSets_answer_sets
        }
        questionnaires_question_set {
          question_set {
            label
          }
          questionnaire {
            label
          }
        }
      }
    `,
  },
)
