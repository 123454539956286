// @flow

import { type ThemeType } from 'care-ui/atoms'

export const buttonWrapperStyle = () => ({
  position: 'relative',
  display: 'inline-block',
  marginRight: '1rem',
  padding: 0,
})

export const notificationStyle = ({ theme }: { theme: ThemeType }) => ({
  backgroundColor: theme.palette.summaryGraphs.notificationColor,
  width: '15px',
  height: '15px',
  borderRadius: theme.care.radius.pill,
  position: 'absolute',
  top: '-10',
  right: '-10',
})
