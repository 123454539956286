// @flow

import * as React from 'react'

import AnchorTabs from './AnchorTabs'
import Tabs, { type BaseTabsProps } from './Tabs'

import type { FelaOptionalPropsType } from 'react-ui/typing'

type ChildFunc = (args: {
  currentTabId: string,
  onChange: (tabId: string) => void,
  tabs: React.Node,
}) => React.Node

type FinalTabsProps = BaseTabsProps & FelaOptionalPropsType

type WithChildren = {
  children: ChildFunc,
}

type WithCurrentStateProps = {
  initialTabId: string,
  onChange?: (tabId: string) => void,
  withAnchorTabs?: boolean,
}

const TabsWithChildren = ({
  children,
  initialTabId,
  onChange,
  withAnchorTabs,
  ...props
}: FinalTabsProps & WithChildren & WithCurrentStateProps) => {
  const [currentTabId, setCurrentTabId] = React.useState(initialTabId)

  const handleChange = newTabId => {
    if (onChange) onChange(newTabId)
    setCurrentTabId(newTabId)
  }

  const tabs = withAnchorTabs ? (
    <AnchorTabs
      onChange={handleChange}
      currentTabId={currentTabId}
      {...(props: any)}
    />
  ) : (
    <Tabs
      onChange={handleChange}
      currentTabId={currentTabId}
      {...(props: any)}
    />
  )
  return (
    <React.Fragment>
      {children
        ? children({ tabs, currentTabId, onChange: handleChange })
        : tabs}
    </React.Fragment>
  )
}

export default TabsWithChildren
