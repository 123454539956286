// @flow

/**
 * Questionnaire completed page for an individual
 */

import React from 'react'
import type { TFunction } from 'react-i18next'

import { OnboardingHeader } from 'components/OnboardingHeader'
import { QuestionSetsList } from 'components/QuestionnaireComplete'
import { initialQuestionnaireSubmit } from 'platform_web/services/analytics/events'

import type { BuilderPropsType } from '../QuestionnaireComplete'

const submitButton = ({
  router,
  onSubmit,
  submitting,
  translation,
}: {
  onSubmit: Function,
  router: { push: Function },
  submitting: boolean,
  translation: TFunction,
}) => ({
  disabled: submitting,
  onClick: onSubmit({
    onCompleted: () => {
      router.push({ name: 'dashboard' })
      initialQuestionnaireSubmit()
    },
  }),
  label: submitting ? `${translation('submitting')}...` : translation('submit'),
})

export const Individual = ({
  user,
  userQuestionnaire,
  router,
  onSubmit,
  submitting,
  setSubmitting,
  translation,
}: BuilderPropsType) => {
  const name = user.preferred_name || user.name

  const { completeness } = userQuestionnaire
  const percent = completeness.percent ? completeness.percent : 0
  const completedList = userQuestionnaire.completedQuestionSets
  const skippedList = userQuestionnaire.skippedQuestionSets
  const hasCompletedList = completedList && completedList.length > 0
  const hasSkippedList = skippedList && skippedList.length > 0

  const incompleteTitle = `${translation('well_done')} ${name}. ${translation(
    'you_are_almost_done',
  )}.`
  const incompleteMessage = (
    <>
      <p>{`${percent}% ${translation(
        'of_the_initial_questionnaire_completed',
      )}`}</p>
      <p>
        {translation(
          'you_can_still_submit_your_answers_and_return_to_the_skipped_incomplete_questions_anytime_or_if_you_prefer_you_can_choose_to_do_them_with_a_support_person_or_your_clinician',
        )}
      </p>
    </>
  )

  const completeTitle = `${translation('well_done')} ${name}.`
  const completeMessage = (
    <>
      <p>
        {translation(
          'your_dashboard_has_sorted_your_results_into_health_cards_so_you_can_focus_on_whats_important_and_explore_a_range_of_care_options_that_help_you_meet_your_health_goals',
        )}
      </p>
      <p>
        {translation(
          'you_can_discuss_your_results_with_your_clinician_your_clinician_will_work_with_you_through_the_dashboard_to_tailor_treatment_to_your_needs',
        )}
      </p>
    </>
  )

  const commonButtonProps = {
    router,
    onSubmit,
    submitting,
    setSubmitting,
    translation,
  }

  return {
    header: (
      <OnboardingHeader
        title={hasSkippedList ? incompleteTitle : completeTitle}
        infoText={hasSkippedList ? incompleteMessage : completeMessage}
        button={submitButton(commonButtonProps)}
      />
    ),
    completedList: hasCompletedList && (
      <QuestionSetsList
        list={completedList}
        title={translation('completed_sections')}
        user_questionnaire={userQuestionnaire}
        completed
      />
    ),
    skippedList: hasSkippedList && (
      <QuestionSetsList
        list={skippedList}
        title={translation('skipped_and_incomplete_sections')}
        user_questionnaire={userQuestionnaire}
      />
    ),
  }
}
