// @flow

import { useWindowSize } from 'react-use'

import {
  BREAKPOINT_LG,
  BREAKPOINT_MD,
  BREAKPOINT_SM,
  BREAKPOINT_XL,
  BREAKPOINT_XS,
} from 'care-ui/atoms/tokens/breakpoints'

const getBreakpoints = ({ width, height }) => {
  const xs = width < BREAKPOINT_XS
  const sm = width >= BREAKPOINT_SM
  const md = width >= BREAKPOINT_MD
  const lg = width >= BREAKPOINT_LG
  const xl = width >= BREAKPOINT_XL

  return {
    width,
    height,
    xs,
    sm,
    md,
    lg,
    xl,
  }
}
const useBreakpoints = () => {
  const { width, height } = useWindowSize()
  const breakpoints = getBreakpoints({ width, height })

  return breakpoints
}

export default useBreakpoints
