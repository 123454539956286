/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianNotesListView_clinician_note$ref: FragmentReference;
declare export opaque type ClinicianNotesListView_clinician_note$fragmentType: ClinicianNotesListView_clinician_note$ref;
export type ClinicianNotesListView_clinician_note = {|
  +nodes: ?$ReadOnlyArray<?{|
    +id: string,
    +created_at: any,
    +text: string,
    +author: {|
      +name: string
    |},
  |}>,
  +$refType: ClinicianNotesListView_clinician_note$ref,
|};
export type ClinicianNotesListView_clinician_note$data = ClinicianNotesListView_clinician_note;
export type ClinicianNotesListView_clinician_note$key = {
  +$data?: ClinicianNotesListView_clinician_note$data,
  +$fragmentRefs: ClinicianNotesListView_clinician_note$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianNotesListView_clinician_note",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianNote",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianRole",
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClinicianNoteConnection",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '50c02f207dda62b97742e6e4930dcbec';

module.exports = node;
