// @flow

import * as React from 'react'
import Route from 'found/Route'

import Page from 'react-ui/components/Page'
import AcknowledgementsPage from 'pages/public/Acknowledgements'
import FrequentlyAskedQuestionsPage from 'pages/public/FrequentlyAskedQuestionsPage'
import PublicHelpNowPage from 'pages/public/HelpNow'
import LegalPage from 'pages/public/LegalPage'
import ResearchDataSharingPage from 'pages/public/ResearchDataSharing/ResearchDataSharing'

const routes = (
  <Route>
    <Route Component={LegalPage}>
      <Route
        Component={AcknowledgementsPage}
        name="acknowledgements"
        path="/acknowledgements"
      />
      <Route
        Component={FrequentlyAskedQuestionsPage}
        name="frequently_asked_questions"
        path="/faq"
      />
      <Route
        Component={ResearchDataSharingPage}
        name="research_data_sharing"
        path="/research_data_sharing"
      />
    </Route>
    <Route
      name="help"
      path="/help"
      Component={() => (
        <Page>
          <PublicHelpNowPage />
        </Page>
      )}
    />
  </Route>
)

export default routes
