// @flow

import React, { type Node } from 'react'

import Heading from './Heading'

type CreateHeadingType = {
  children: Node,
  extend?: any,
}

const createHeading = level => ({ children, ...props }: CreateHeadingType) =>
  React.createElement(
    Heading,
    {
      ...props,
      level,
    },
    children,
  )

export const H2 = createHeading(2)
export const H3 = createHeading(3)
export const H4 = createHeading(4)
export const H5 = createHeading(5)

export default Heading
