// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import ArrowDown from 'react-ui/assets/icons/arrow-down.svg'
import Cross from 'react-ui/assets/icons/cross.svg'
import Button from 'react-ui/components/Button'
import Icon from 'react-ui/components/Icon'

import type { FelaPropsType } from 'react-ui/typing'

type TrackableResultCardButtonsType = FelaPropsType & {
  content: Node,
  isOpen: boolean,
  toggleMenu: () => void,
}
const styleRules = ({ theme }) => ({
  Button: {
    display: 'flex',
    width: '100%',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '3rem',
    paddingLeft: '0.6rem',
    paddingRight: '0.6rem',
    color: theme.palette.textColor,
    backgroundColor: '#fafafa',
    borderWidth: '1px 0 0 0', // Override the rule in <Button />
    borderColor: 'transparent',
    borderBottomLeftRadius: '0.225rem',
    borderBottomRightRadius: '0.225rem',
    borderTop: `1px solid ${theme.palette.dividerColor}`,
    fontSize: '0.72222rem',
    ':hover': {
      borderTop: `1px solid ${theme.palette.dividerColor}`,
      backgroundColor: '#fafafa',
      color: theme.palette.textColor,
    },
    ':active': {
      backgroundColor: '#fafafa',
      color: theme.palette.textColor,
    },
    ':focus': {
      outline: 'none',
    },
  },

  IconWrapper: {
    display: 'flex',
  },
  Icon: {
    display: 'flex',
    flexDirection: 'row',
    height: '100%',
    alignItems: 'center',
  },

  ParticipantGroup: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const TrackableResultCardButtons = ({
  styles,
  rules,
  content,
  toggleMenu,
  isOpen,
}: TrackableResultCardButtonsType) => (
  <Button
    naked
    componentId="trackable-result-buttons"
    extend={rules.Button}
    onClick={toggleMenu}
  >
    {content && <div className={styles.ParticipantGroup}>{content}</div>}
    <div className={styles.IconWrapper}>
      <div className={styles.Icon}>
        <Icon as={isOpen ? Cross : ArrowDown} scale={0.7} />
      </div>
    </div>
  </Button>
)

export default connect(styleRules)(TrackableResultCardButtons)
