// @flow

import { type ThemeType } from 'care-ui/atoms'

export const accordianContainer = () => ({
  position: 'absolute',
  top: '10px',
  left: '0',
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
  width: '60px',
  height: '60px',
})

export const extendedRowArrow = () => ({
  background: 'white',
  outline: 'none',
  border: '0',
})

export const contentStyle = ({ theme }: { theme: ThemeType }) => ({
  backgroundColor: theme.DataGrid.footer.accent,
  paddingBottom: theme.spacing(0.5),
  minHeight: '80px',
})

export const hrStyle = ({ theme }: { theme: ThemeType }) => ({
  borderTop: '0',
  backgroundColor: theme.DataGrid.cell.accent,
  height: '2px',
  margin: '0',
})
