// @flow

import { useMutation } from 'react-relay'
import { type GraphQLTaggedNode } from 'relay-runtime'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'

type UseEmployeesActionsModalType = {
  actionedRoleType: string,
  closeModal: () => void,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: GraphQLTaggedNode,
  queryVariables: QueryVariablesType,
}

const useEmployeesActionsModal = (props: UseEmployeesActionsModalType) => {
  const {
    actionedRoleType,
    closeModal,
    multiSelectPayload,
    queryVariables,
    mutationQuery,
  } = props

  const { search } = queryVariables

  const filters = {
    roleTypes: [actionedRoleType],
    search: String(search),
  }

  const {
    allSelected,
    resetMultiSelectState,
    totalCount,
    selectedUsers,
    deselectedUsers,
  } = multiSelectPayload

  const allSelectedMinusDeselectedCount = totalCount - deselectedUsers?.length
  const selectedUsersCount = allSelected
    ? allSelectedMinusDeselectedCount
    : selectedUsers?.length

  // Commit mutation to perform action
  const [commit] = useMutation(mutationQuery)

  const selectedRoleIds = selectedUsers.reduce((roleIds, user) => {
    // Find a role by the actionedRoleType
    const actionedRole = user.roles.find(
      role => role.role_type === actionedRoleType,
    )

    // If a matching role is found, add its ID to the roleIds array
    if (actionedRole) roleIds.push(actionedRole.id)

    return roleIds
  }, [])

  const deselectedRoleIds = deselectedUsers.reduce((roleIds, user) => {
    // Find a role by the actionedRoleType
    const actionedRole = user.roles.find(
      role => role.role_type === actionedRoleType,
    )

    // If a matching role is found, add its ID to the roleIds array
    if (actionedRole) roleIds.push(actionedRole.id)

    return roleIds
  }, [])

  const onConfirmation = () => {
    commit({
      variables: {
        input: {
          roles: {
            dataTableType: 'employee',
            selectedRoleIds: allSelected ? [] : selectedRoleIds,
            deselectedRoleIds,
            ...(allSelected && { filters }),
          },
        },
      },
    })

    closeModal()
    resetMultiSelectState()
  }

  return {
    filters,
    allSelectedMinusDeselectedCount,
    selectedUsersCount,
    onConfirmation,
  }
}

export default useEmployeesActionsModal
