/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssessmentRequestsLibraryQueryVariables = {|
  id: string,
  requesteeRoleId?: ?string,
|};
export type AssessmentRequestsLibraryQueryResponse = {|
  +user: {|
    +linked_individual: ?{|
      +id: string,
      +requestableQuestionnaires: $ReadOnlyArray<{|
        +id: string,
        +label: string,
        +library_description: ?string,
        +category: ?string,
      |}>,
    |}
  |}
|};
export type AssessmentRequestsLibraryQuery = {|
  variables: AssessmentRequestsLibraryQueryVariables,
  response: AssessmentRequestsLibraryQueryResponse,
|};
*/


/*
query AssessmentRequestsLibraryQuery(
  $id: ID!
  $requesteeRoleId: ID
) {
  user(id: $id) {
    linked_individual {
      id
      requestableQuestionnaires(requesteeRoleId: $requesteeRoleId) {
        id
        label
        library_description
        category
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "requesteeRoleId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "IndividualRole",
  "kind": "LinkedField",
  "name": "linked_individual",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "requesteeRoleId",
          "variableName": "requesteeRoleId"
        }
      ],
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "requestableQuestionnaires",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "library_description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssessmentRequestsLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssessmentRequestsLibraryQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "266acb8cceae25fd8ceddb01c5dc9e1a",
    "id": null,
    "metadata": {},
    "name": "AssessmentRequestsLibraryQuery",
    "operationKind": "query",
    "text": "query AssessmentRequestsLibraryQuery(\n  $id: ID!\n  $requesteeRoleId: ID\n) {\n  user(id: $id) {\n    linked_individual {\n      id\n      requestableQuestionnaires(requesteeRoleId: $requesteeRoleId) {\n        id\n        label\n        library_description\n        category\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '7dca0578e800f0c84a6aeec0395f935d';

module.exports = node;
