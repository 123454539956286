// @flow

/**
 * Given a phone number string, returns a link that opens a message to the provided
 * number, as per https://tools.ietf.org/html/rfc5724.
 *
 * @param {string} phoneNumber
 */
export const smsLink = (phoneNumber: string) =>
  `sms:${phoneNumber.replace(/\s/g, '-')}`
