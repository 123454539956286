// @flow

import { type ThemeType } from 'care-ui/atoms'

export const formStyle = () => ({
  maxWidth: '600px',
  textAlign: 'left',
  width: '100%',
})

export const submitButtonStyle = ({ theme }: { theme: ThemeType }) => ({
  marginTop: theme.Grid.gutter,
})

export const inputStyle = () => ({
  Input: {
    width: '100%',
  },
  input: {
    width: '100%',
  },
})
