/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserQuestionnaire_role$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireShowPage_role$ref: FragmentReference;
declare export opaque type QuestionnaireShowPage_role$fragmentType: QuestionnaireShowPage_role$ref;
export type QuestionnaireShowPage_role = {|
  +__typename: string,
  +id?: string,
  +role_type?: RoleTypes,
  +$fragmentRefs: UserQuestionnaire_role$ref,
  +$refType: QuestionnaireShowPage_role$ref,
|};
export type QuestionnaireShowPage_role$data = QuestionnaireShowPage_role;
export type QuestionnaireShowPage_role$key = {
  +$data?: QuestionnaireShowPage_role$data,
  +$fragmentRefs: QuestionnaireShowPage_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireShowPage_role",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionnaire_role"
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_type",
          "storageKey": null
        }
      ],
      "type": "RoleInterface",
      "abstractKey": "__isRoleInterface"
    }
  ],
  "type": "Role",
  "abstractKey": "__isRole"
};
// prettier-ignore
(node/*: any*/).hash = '610c76d20217e934c6c3241776413736';

module.exports = node;
