// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, useRelayEnvironment } from 'react-relay'
import useRouter from 'found/useRouter'

import { commit as commitIndividualPersonalDetailCreate } from 'mutations/IndividualPersonalDetailCreate'
import { normalizeClinicianValues } from 'shared/services/normalization'
import Sentry from 'shared/services/sentry'

import { clinicianOnboardingUser } from '../../queries/ClinicianOnboarding'
import OnboardingPages from '../OnboardingPages'

const ClinicianOnboardingPages = props => {
  const { user } = props
  const { router } = useRouter()
  const environment = useRelayEnvironment()

  const { preferred_name, onboardingQuestionnaire, homepage_path } = user

  const handleSubmit = values => {
    commitIndividualPersonalDetailCreate({
      environment,
      onCompleted: () => {
        router.push(homepage_path)
      },
      onError: error => {
        Sentry.captureMessage(
          'Onboarding could not be completed due to error sending mutation',
          {
            extra: {
              individual_personal_detail: normalizeClinicianValues(values),
            },
          },
        )
        Sentry.captureException(error)
      },
      variables: {
        input: {
          individual_personal_detail: normalizeClinicianValues(values),
        },
      },
    })
  }

  const { t: translation } = useTranslation('onboarding')

  return (
    <OnboardingPages
      hasHeader={false}
      pageTitle={translation('tell_us_a_bit_about_yourself')}
      userName={preferred_name}
      questionnaire={onboardingQuestionnaire}
      uiStyle="secondary"
      handleSubmit={handleSubmit}
    />
  )
}

export default createFragmentContainer(
  ClinicianOnboardingPages,
  clinicianOnboardingUser,
)
