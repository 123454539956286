// @flow

const CANADA_PRIVACY = 'https://www.innowell.org/privacy-policy-canada/'
const CANADA_TCS_CS = 'https://www.innowell.org/terms-and-conditions-canada/'
const AUS_PRIVACY = 'https://www.innowell.org/privacy-policy'
const AUS_TCS_CS = 'https://www.innowell.org/terms-of-use'

/*
 * External URLS (i.e. non-logged in pages) that rely on Cloudflare Geo headers
 * to geographically target
 */
export const privacyPolicyUrl = () => {
  switch (window.GLOBALS.geoLocationAlpha2) {
    case 'CA':
      return CANADA_PRIVACY
    default:
      return AUS_PRIVACY
  }
}

export const termsAndConditionsUrl = () => {
  switch (window.GLOBALS.geoLocationAlpha2) {
    case 'CA':
      return CANADA_TCS_CS
    default:
      return AUS_TCS_CS
  }
}
