// @flow

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { usePolicies } from 'platform_web/hooks/usePolicies'

type RoleType = 'LEAD_CLINICIAN' | 'CLINICIAN' | 'ADMIN' | 'MANAGER'
type TableType =
  | 'assigned'
  | 'active'
  | 'clinician_active'
  | 'invited'
  | 'removed'
  | 'discharged'

type ACTIONS_FOR_INDIVIDUAL_TYPE = Array<{
  actionName: string,
  roles: Array<RoleType>,
  tableTypes: Array<TableType>,
}>

const ACTIONS_FOR_INDIVIDUAL: ACTIONS_FOR_INDIVIDUAL_TYPE = [
  {
    actionName: 'requestAssessments',
    tableTypes: ['assigned', 'active', 'clinician_active'],
    roles: ['LEAD_CLINICIAN', 'CLINICIAN'],
  },
  {
    actionName: 'assignClinicians',
    tableTypes: ['assigned', 'invited', 'active', 'clinician_active'],
    roles: ['LEAD_CLINICIAN', 'ADMIN', 'MANAGER'],
  },
  {
    actionName: 'resendInvitations',
    tableTypes: ['invited', 'removed'],
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    actionName: 'resendInvitations',
    tableTypes: ['invited'],
    roles: ['LEAD_CLINICIAN'],
  },
  {
    actionName: 'discharge',
    tableTypes: ['assigned', 'active', 'clinician_active'],
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    actionName: 'remove',
    tableTypes: ['invited'],
    roles: ['ADMIN', 'MANAGER'],
  },
  {
    actionName: 'reinvite',
    tableTypes: ['discharged'],
    roles: ['ADMIN', 'MANAGER'],
  },
]

type UseIndividualActionsProps = {
  tableType: 'assigned' | 'active' | 'clinician_active' | string,
}

const useIndividualActions = (props: UseIndividualActionsProps) => {
  const { tableType } = props
  const {
    isMultiRolesEnabled,
    roleType: singleRoleType,
    currentUserRoles,
    tenant: { hide_all_unassigned_individuals_for_clinician },
  } = useCurrentUserRoles()

  const roleTypes = isMultiRolesEnabled ? currentUserRoles : [singleRoleType]

  const policies = usePolicies()

  const getAllowedActions = () => {
    // Step 1: Filter actions based on table type
    const tableTypeFilteredActions = ACTIONS_FOR_INDIVIDUAL.filter(action =>
      action.tableTypes.includes(tableType),
    )

    // Step 2: Further filter actions based on role types
    const roleFilteredActions = tableTypeFilteredActions.filter(action =>
      roleTypes.some(role => action.roles.includes(role)),
    )

    // Step 3: Map the filtered actions to extract action names
    const allowedActions: Array<string> = roleFilteredActions.map(
      action => action.actionName,
    )

    // Policy at tenant level: CAN_ASSIGN_CLINICIANS
    if (
      !policies.CAN_ASSIGN_CLINICIANS &&
      allowedActions.includes('assignClinicians')
    ) {
      const allowedActionsWithPolicies: Array<string> = allowedActions.filter(
        actionName => actionName !== 'assignClinicians',
      )

      return allowedActionsWithPolicies
    }

    // Policy at tenant level: hide_all_unassigned_individuals_for_clinician
    const shouldAllowRequestAssessment =
      hide_all_unassigned_individuals_for_clinician &&
      tableType !== 'assigned' &&
      tableType !== 'clinician_active'

    if (
      shouldAllowRequestAssessment &&
      allowedActions.includes('requestAssessments')
    ) {
      const allowedActionsWithPolicies: Array<string> = allowedActions.filter(
        actionName => actionName !== 'requestAssessments',
      )

      return allowedActionsWithPolicies
    }

    return allowedActions
  }

  return {
    actions: getAllowedActions(),
  }
}

export default useIndividualActions
