// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserResourceChangeStateMutationResponse,
  UserResourceChangeStateMutationVariables,
} from './__generated__/UserResourceChangeStateMutation.graphql'

const mutation = graphql`
  mutation UserResourceChangeStateMutation(
    $input: UserResourceChangeStateInput!
  ) {
    userResourceChangeState(input: $input) {
      user_resource {
        ...UserResourceChangeState_user_resources
        id
      }
    }
  }
`

graphql`
  fragment UserResourceChangeState_user_resources on UserResource
    @relay(plural: true) {
    ...UserResourceChangeState_user_resource @relay(mask: false)
  }
`

graphql`
  fragment UserResourceChangeState_user_resource on UserResource {
    ...UserResource_user_resource
    status
    id
    resource {
      clinical
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: UserResourceChangeStateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: UserResourceChangeStateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
