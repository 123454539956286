// @flow

import { graphql } from 'react-relay'

export const clinicianOnboardingUser = {
  user: graphql`
    fragment ClinicianOnboarding_user on User {
      preferred_name
      homepage_path
      id
      onboardingQuestionnaire {
        questions {
          name
          fieldType
          title
          required
          options {
            label
            value
          }
          otherEnabled
          dependOn
        }
        orders
      }
    }
  `,
}
