// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const VideoDisabledIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="videoDisabledIcon"
        version="1.1"
        viewBox="0 0 31 27"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
      >
        <g>
          <path
            id="path-3_3_"
            className="st0"
            d="M1.7,24.3c-0.4,0.4-1,0.4-1.4,0s-0.4-1,0-1.4L22.9,0.3c0.4-0.4,1-0.4,1.4,0s0.4,1,0,1.4L1.7,24.3z"
          />
        </g>
        <g>
          <path
            className="st0"
            d="M4.5,17.2V6.2c0-0.4,0.3-0.6,0.6-0.6h11.1L18.8,3H5.2C3.4,3,2,4.4,2,6.2v12.7c0,0.3,0,0.5,0.1,0.8L4.5,17.2z"
          />
          <path
            className="st0"
            d="M28,3.8l-5.7,3.8V6.2c0-0.3-0.1-0.6-0.2-0.9l-2.4,2.4v11.3c0,0.4-0.3,0.6-0.6,0.6H7.9l-2.5,2.5h13.8c1.8,0,3.2-1.4,3.2-3.2v-1.4l5.7,3.8c0.8,0.6,2,0,2-1.1V4.9C30,3.9,28.9,3.3,28,3.8z M27.4,17.8l-5.1-3.4v-3.7l5.1-3.4V17.8z"
          />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default VideoDisabledIcon
