// @flow

import { type ThemeType } from 'care-ui/atoms'

export const footerStyles = () => ({
  left: 0,
  bottom: 0,
  position: 'fixed',
  width: '100vw',
  zIndex: '2',
})

export const childrenWrapperStyles = ({ theme }: { theme: ThemeType }) => ({
  boxShadow: `0 4px 8px .25rem ${theme.palette.themelet.lightGrey.color}`,
  padding: 0,
})

export const footerContentStyles = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: `calc(${theme.Grid.gutter}/2)`,
  [theme.breakpoints.queries.md]: {
    maxWidth: '680px',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    paddingBottom: theme.Grid.gutter,
  },
})

export const footerElementStyles = ({ theme }: { theme: ThemeType }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  height: 'auto',
  padding: `calc(${theme.Grid.gutter}/2) calc(${theme.Grid.gutter}/2)  0`,
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.queries.md]: {
    padding: `${theme.Grid.gutter} calc(${theme.Grid.gutter}/2) 0`,
  },
})

export const footerButtonsStyles = ({ theme }: { theme: ThemeType }) => ({
  alignItems: 'center',
  display: 'flex',
  height: 'auto',
  padding: `calc(${theme.Grid.gutter}/2) calc(${theme.Grid.gutter}/2) 0`,
  textAlign: 'center',
  width: 'auto',
  gap: theme.care.spacing.xs,
  [theme.breakpoints.queries.md]: {
    padding: `${theme.Grid.gutter} calc(${theme.Grid.gutter}/2) 0`,
  },
})

export const columnStyles = () => ({
  flexDirection: 'column',
})

export const iconStyles = ({ theme }: { theme: ThemeType }) => ({
  color: theme.palette.component.primary.accent,
  display: 'inline-block',
  paddingRight: theme.Grid.gutter,
})

export const progressPercentStyles = () => ({
  paddingTop: '5px',
})

export const responsiveIconStyles = ({ theme }: { theme: ThemeType }) => ({
  display: 'block',
  [theme.breakpoints.queries.md]: {
    display: 'flex',
    alignSelf: 'flex-start',
    textAlign: 'left',
  },
})
