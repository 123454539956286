// @flow

import React, { type Node, useContext, useState } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { sortBy } from 'lodash/fp'

import type { DateTimeAxisType } from 'react-ui/components/Charts/HighChartOptions/defaultOptions'
import { formatDataSeries } from 'react-ui/components/Charts/HighChartOptions/helpers'

import {
  getFilterDataSets,
  getNewAdditionalDataSets,
  getNewDataSets,
  sortAdditionalFormulas,
} from './helpers'

import type { ScoreCardChartContext_user } from './__generated__/ScoreCardChartContext_user.graphql'

export type ActivityType = $PropertyType<ScoreCardChartContext_user, 'activity'>
export type SleepType = $PropertyType<ScoreCardChartContext_user, 'sleep'>

type PropsType = {
  broadcastPointData?: () => any,
  children: Node,
  dateRange: DateTimeAxisType,
  displayActivity?: boolean,
  displaySleep?: boolean,
  formulaRanges: any,
  user: ScoreCardChartContext_user,
}

const ScoreCardChartContext: any = React.createContext({})
export const ScoreCardChartContextConsumer = ScoreCardChartContext.Consumer

export const useScoreCardChart = () => {
  return useContext(ScoreCardChartContext)
}

const ScoreCardChartContextProviderComponent = ({
  children,
  dateRange,
  formulaRanges,
  displayActivity,
  displaySleep,
  broadcastPointData,
  user,
}: PropsType) => {
  const [cliIndex, setCliIndex] = useState()
  const [spIndex, setSpIndex] = useState()

  const { user_trackable, activity, sleep } = user || {}
  const {
    data_sets: userTrackableDataSets,
    additional_plot_data_sets: additionalDataSets,
    change_log_entries: changeLogEntries,
    trackable,
  } =
    user_trackable || {}

  const {
    additional_plot_formula,
    label: mainLabel,
    formula_range_group,
    plot_raw_score: plotRawScore,
  } =
    trackable || {}

  const { inversed_range: isInversedRange } = formula_range_group || {}
  const {
    formula_ranges: additionalFormulaRanges,
    display_name: secondaryLabel,
  } =
    additional_plot_formula || {}

  const sortedFormulas = sortBy('value')(formulaRanges)
  const sortedAdditionalFormulas = sortAdditionalFormulas(
    additionalFormulaRanges,
  )
  const sortedFormulasRaw = sortAdditionalFormulas(formulaRanges)

  const isFitbitTab = Boolean(displayActivity || displaySleep)

  const newDataParams = {
    userTrackableDataSets,
    cliIndex,
    spIndex,
    isFitbitTab,
  }

  const filterDataSets = getFilterDataSets(userTrackableDataSets)
  const newDataSets = getNewDataSets(newDataParams)
  const newAdditionalDataSets = getNewAdditionalDataSets(additionalDataSets)

  const mainSeries = formatDataSeries(newDataSets, { plotRawScore })
  const additionalSeries = formatDataSeries(newAdditionalDataSets, {
    plotRawScore,
  })

  const scoresRange = [
    { label: '1', value: 1 },
    { label: '0.5', value: 0.5 },
    { label: '0', value: 0 },
  ]

  const value = {
    activity,
    additionalSeries,
    broadcastPointData,
    dateRange,
    displayActivity,
    displaySleep,
    filterDataSets,
    isFitbitTab,
    isInversedRange,
    mainLabel,
    mainSeries,
    scoresRange,
    secondaryLabel,
    setCliIndex,
    setSpIndex,
    sleep,
    sortedAdditionalFormulas,
    sortedFormulas,
    sortedFormulasRaw,
    trackable,
    changeLogEntries,
  }

  return (
    <ScoreCardChartContext.Provider value={value}>
      {children}
    </ScoreCardChartContext.Provider>
  )
}

export const ScoreCardChartContextProvider = createFragmentContainer(
  ScoreCardChartContextProviderComponent,
  {
    user: graphql`
      fragment ScoreCardChartContext_user on User {
        id
        activity(start_date: $fitbit_start_date, end_date: $fitbit_end_date) {
          steps
          date
          goal
        }
        sleep(start_date: $fitbit_start_date, end_date: $fitbit_end_date) {
          date
          asleep {
            startTime
            endTime
            hours
            minutes
          }
        }
        user_trackable(trackable_id: $trackable_id) {
          data_sets(
            start_time: $trackable_start_time
            end_time: $trackable_end_time
          ) {
            answered_by {
              ... on RoleInterface {
                user {
                  name
                }
                role_type
              }
            }
            points {
              id
              date
              scaled_value
              value
              raw_score
              trajectory_data_points @include(if: $is_clinician) {
                id
                date
                value
                upper
                lower
              }
            }
          }
          additional_plot_data_sets(
            start_time: $trackable_start_time
            end_time: $trackable_end_time
          ) {
            answered_by {
              ... on RoleInterface {
                user {
                  name
                }
                role_type
              }
            }
            points {
              id
              date
              raw_score
              trajectory_data_points @include(if: $is_clinician) {
                id
                date
                value
                upper
                lower
              }
            }
          }
          trackable {
            id
            label
            plot_raw_score
            formula_range_group {
              id
              inversed_range
            }
            additional_plot_formula {
              id
              display_name
              formula_ranges {
                id
                boundLower
                boundUpper
              }
            }
          }
          change_log_entries(
            start_time: $trackable_start_time
            end_time: $trackable_end_time
          ) {
            id
            created_at
            reason
          }
        }
      }
    `,
    formulaRanges: graphql`
      fragment ScoreCardChartContext_formulaRanges on FormulaRange
        @relay(plural: true) {
        id
        label
        value
        boundLower
        boundUpper
      }
    `,
  },
)

export default ScoreCardChartContext
