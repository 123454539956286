/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianInputTask_individual$ref: FragmentReference;
declare export opaque type ClinicianInputTask_individual$fragmentType: ClinicianInputTask_individual$ref;
export type ClinicianInputTask_individual = {|
  +user: {|
    +id: string
  |},
  +tenant: ?{|
    +summary_questionnaire: ?{|
      +id: string,
      +answerable: ?boolean,
    |}
  |},
  +$refType: ClinicianInputTask_individual$ref,
|};
export type ClinicianInputTask_individual$data = ClinicianInputTask_individual;
export type ClinicianInputTask_individual$key = {
  +$data?: ClinicianInputTask_individual$data,
  +$fragmentRefs: ClinicianInputTask_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianInputTask_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "summary_questionnaire",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answerable",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '594bc9366cb49f3f83c84e6fcc569bc4';

module.exports = node;
