// @flow

import React from 'react'
import { connect } from 'react-fela'

import Button from 'react-ui/components/Button'
import Card from 'react-ui/components/Card'
import Heading from 'react-ui/components/Heading'
import Steps, { type StepType } from 'react-ui/components/Steps'

import type { FelaPropsType } from 'react-ui/typing'

type PropType = FelaPropsType & {
  activeIndex: number,
  button?: {
    label: string,
    linkTo: string | Object,
    onClick: () => void,
  },
  steps: [StepType],
  title: string,
}
const styleRules = ({ theme }) => ({
  heading: { textAlign: 'center', marginBottom: '0' },
  Card: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    [theme.breakpoints.queries.md]: {
      padding: '10px',
    },
  },
  Button: {
    width: '100%',
  },
})

const WizardSteps = ({
  rules,
  activeIndex,
  steps,
  title,
  button,
}: PropType) => {
  const activeStepData = activeIndex >= 1 ? steps[activeIndex - 1] : null
  let activeStep = null
  if (activeStepData) {
    activeStep = {
      activeIndex,
      isCompleted: activeIndex === steps.length - 1,
    }
  }

  return (
    <Card extend={rules.Card}>
      <Heading level={2} extend={rules.heading}>
        {title}
      </Heading>
      <Steps steps={steps} activeStep={activeStep} />
      {button && (
        <Button
          data-component-id="step-wizard-button"
          onClick={button.onClick}
          to={button.linkTo}
          shape="pill"
          extend={rules.Button}
        >
          {button.label}
        </Button>
      )}
    </Card>
  )
}

export default connect(styleRules)(WizardSteps)
