// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  graphql,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'
import { get } from 'lodash/fp'

import { IndividualTasksCardLoader } from 'components/IndividualTasksCard'
import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import { UserTrackableLoader } from 'components/UserTrackable'
import { Button, FlexContainer } from 'care-ui'
import useBreakpoints from 'care-ui/molecules/hooks/useBreakpoints'

import { type UserTrackableList_user_trackables } from './__generated__/UserTrackableList_user_trackables.graphql'

type LoaderPropsType = {
  componentId?: string,
  showUtility?: boolean,
  viewOnly?: boolean,
}

type PropsType = LoaderPropsType & {
  isExtensive?: boolean,
  isHealthPriorities?: boolean,
  isPlannedSelection?: boolean,
  user_trackables: UserTrackableList_user_trackables,
}

const healthButtonGroupStyle = () => ({
  width: '16rem',
  display: 'flex',
  justifyContent: 'left',
  margin: '1.5rem 1rem 0',
})

const buttonGroupStyle = () => ({
  display: 'flex',
  justifyContent: 'center',
  margin: '1.5rem 0 2.5rem',
})

const emptyListStyle = ({ theme }) => ({
  padding: '.5rem 1rem',
  fontStyle: 'italic',
  textAlign: 'center',
  color: theme.palette.component.disabled.text,
  backgroundColor: theme.palette.component.disabled.base,
})

const UserTrackableListBase = ({
  user_trackables,
  showUtility = false,
  isPlannedSelection,
  isHealthPriorities,
  viewOnly,
}: PropsType) => {
  const { css } = useFela()

  const { t: translation } = useTranslation('dashboard')

  const linked_individual = get('[0].user.linked_individual', user_trackables)

  const { width } = useBreakpoints()

  const trackables = user_trackables.map(user_trackable => (
    <UserTrackableLoader
      key={user_trackable.trackable.id}
      user_trackable={user_trackable}
      viewOnly={viewOnly}
    />
  ))

  const commonTasksContainer = (
    <IndividualTasksCardLoader
      key="individualCommonTasks"
      individual={linked_individual}
    />
  )

  const getTrackablesAndCommonTaskCards = () => {
    if (!showUtility && !linked_individual) return trackables

    if (width > 2884) {
      return [
        ...trackables.slice(0, 5),
        commonTasksContainer,
        ...trackables.slice(5),
      ]
    }

    if (width >= 2400 && width <= 2884) {
      return [
        ...trackables.slice(0, 4),
        commonTasksContainer,
        ...trackables.slice(4),
      ]
    }

    if (width > 1914 && width < 2400) {
      return [
        ...trackables.slice(0, 3),
        commonTasksContainer,
        ...trackables.slice(3),
      ]
    }

    if (width > 1430 && width < 1914) {
      return [
        ...trackables.slice(0, 2),
        commonTasksContainer,
        ...trackables.slice(2),
      ]
    }

    if (width > 824 && width < 1430) {
      return [
        ...trackables.slice(0, 1),
        commonTasksContainer,
        ...trackables.slice(1),
      ]
    }

    return [commonTasksContainer, ...trackables]
  }

  const trackablesAndCommonTaskCards = getTrackablesAndCommonTaskCards()

  const trackableGrid = () => (
    <FlexContainer gap="md">
      {trackablesAndCommonTaskCards?.map(commonTask => commonTask)}
    </FlexContainer>
  )

  const hasTrackables = trackables.length > 0

  if (isHealthPriorities) {
    return (
      <>
        {!viewOnly && (
          <div className={css(healthButtonGroupStyle)}>
            <Button
              variant="primary"
              dataTestId="restart-dashboard-setup-btn"
              to={{ name: 'dashboard_preferences' }}
            >
              {translation('select_change_focus_areas')}
            </Button>
          </div>
        )}

        {hasTrackables && trackableGrid()}
      </>
    )
  }

  if (isPlannedSelection) {
    return (
      <>
        {hasTrackables && trackableGrid()}

        {!viewOnly && (
          <div className={css(buttonGroupStyle)}>
            <Button
              shape="pill"
              componentId="restart-dashboard-setup-btn"
              to={{ name: 'dashboard_preferences' }}
            >
              {translation('select_change_focus_areas')}
            </Button>
          </div>
        )}
      </>
    )
  }

  return hasTrackables ? (
    trackableGrid()
  ) : (
    <div className={css(emptyListStyle)}>
      <p>{translation('no_health_cards_found')}</p>
    </div>
  )
}

export const UserTrackableList = createFragmentContainer(
  UserTrackableListBase,
  {
    user_trackables: graphql`
      fragment UserTrackableList_user_trackables on UserTrackable
        @relay(plural: true) {
        ...UserTrackable_user_trackable
        user {
          linked_individual {
            ...IndividualTasksCard_individual
          }
        }
        trackable {
          id
        }
      }
    `,
  },
)

const UserTrackableListQuery = graphql`
  query UserTrackableList_Query {
    viewer {
      currentUser {
        summaryUserTrackables: user_trackables(kinds: [gauge]) {
          nodes {
            ...UserTrackableList_user_trackables
          }
        }
      }
    }
  }
`

const UserTrackableListSpecificQuery = graphql`
  query UserTrackableListSpecific_Query($id: ID!) {
    user(id: $id) {
      summaryUserTrackables: user_trackables(kinds: [gauge]) {
        nodes {
          ...UserTrackableList_user_trackables
        }
      }
    }
  }
`

export const UserTrackableListLoader = (loaderProps: LoaderPropsType) => {
  const environment = useRelayEnvironment()
  const { match: { params } } = useRouter()

  const query = params?.id
    ? UserTrackableListSpecificQuery
    : UserTrackableListQuery

  return (
    <QueryRenderer
      environment={environment}
      query={query}
      variables={{ id: params?.id }}
      render={response => {
        return (
          <QueryRendererLoadingIndicator response={response}>
            {props => {
              const dashboardUser = props.viewer?.currentUser || props.user
              return (
                <UserTrackableList
                  {...loaderProps}
                  user_trackables={dashboardUser.summaryUserTrackables.nodes}
                />
              )
            }}
          </QueryRendererLoadingIndicator>
        )
      }}
    />
  )
}
