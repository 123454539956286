// @flow

import { commitMutation, graphql } from 'react-relay'

import { temporarilySetUserInvitationDelta } from './ReinviteGeneric'

import type {
  ReinviteStaffMutationResponse,
  ReinviteStaffMutationVariables,
} from './__generated__/ReinviteStaffMutation.graphql'

const mutation = graphql`
  mutation ReinviteStaffMutation($input: RoleReinviteInput!) {
    roleReinvite(input: $input) {
      role {
        ... on RoleInterface {
          id
          ...EmployeesTable_role
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: ReinviteStaffMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: ReinviteStaffMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
    optimisticUpdater: temporarilySetUserInvitationDelta(
      variables.input.roleId,
    ),
  })
}
