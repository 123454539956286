// @flow

import React from 'react'
import { useFela } from 'react-fela'

type PropsType = {
  cols?: number,
  dataTestId?: string,
  extend?: () => { [string]: string },
  onBlur?: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  onChange: (event: SyntheticInputEvent<HTMLInputElement>) => void,
  placeholder?: string,
  rows?: number,
  value: string,
}

const textAreaStyle = ({ theme, extend }) => ({
  resize: 'none',
  borderRadius: theme.care.radius.sm,
  padding: `${theme.care.spacing.xxs} ${theme.care.spacing.xs}`,
  border: `1px solid ${theme.care.palette.border.lighter}`,
  '&::placeholder': {
    color: theme.care.palette.text.subtitle,
  },
  ...theme.care.typography.desktop.bodyLg,
  ...(extend && extend()),
})

const TextArea = ({
  rows,
  cols,
  placeholder,
  onBlur,
  onChange,
  value,
  extend,
  dataTestId,
}: PropsType) => {
  const { css } = useFela({ extend })

  return (
    <textarea
      rows={rows}
      cols={cols}
      placeholder={placeholder}
      onBlur={onBlur}
      onChange={onChange}
      value={value}
      className={css(textAreaStyle)}
      data-testid={dataTestId}
    />
  )
}

export default TextArea
