// @flow

import React, { type ComponentType, type Node } from 'react'

type PropType = {
  [key: string]: any,
}
type ProviderObjectType = {
  props: PropType,
  provider: ComponentType<any>,
}

type ProviderWrapperPropType = {
  children: Node,
  providers: Array<ProviderObjectType>,
}

const ProviderWrapper = ({ providers, children }: ProviderWrapperPropType) => {
  const combinedProviders = providers.reduce(
    (childrenAccumulator, { provider: NextProvider, props }) => (
      <NextProvider {...(props: any)}>{childrenAccumulator}</NextProvider>
    ),
    children,
  )

  return <>{combinedProviders}</>
}

export default ProviderWrapper
