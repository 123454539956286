// @flow

// Custom extra breakpoint to handle small devices
export const BREAKPOINT_XXS = 576 // mobile portrait and landscape
export const BREAKPOINT_SM = 768 // tablets
export const BREAKPOINT_MD = 912 // laptops
export const BREAKPOINT_LG = 1200 // big screens

export type BreakpointsType = { [key: string]: string }

const queries = {
  xs: '@media screen and (min-width: 0px)',
  sm: `@media screen and (min-width: ${BREAKPOINT_SM}px)`,
  md: `@media screen and (min-width: ${BREAKPOINT_MD}px)`,
  lg: `@media screen and (min-width: ${BREAKPOINT_LG}px)`,
  xxsOnly: `@media screen and (max-width: ${BREAKPOINT_XXS - 1}px)`,
  xsOnly: `@media screen and (max-width: ${BREAKPOINT_SM - 1}px)`,
  smOnly: `@media screen and (min-width: ${BREAKPOINT_SM}px) and (max-width: ${BREAKPOINT_MD -
    1}px)`,
  mdOnly: `@media screen and (min-width: ${BREAKPOINT_MD}px) and (max-width: ${BREAKPOINT_LG -
    1}px)`,
}

// Explicitly state the order that media queries should be added.
// Fela writes these as embedded styles (EG. <style media="...">),
// so we must strip "@media " from the beginning of each query.
const queryOrder: Array<string> = [
  queries.xs,
  queries.sm,
  queries.md,
  queries.lg,
  queries.xxsOnly,
  queries.xsOnly,
  queries.smOnly,
  queries.mdOnly,
].map((mq: string): string => mq.replace(/^@media /, ''))

export { queries, queryOrder }
