// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Accordion, { AccordionItem } from 'react-ui/components/Accordion'
import { Text } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => ({
  AccordionItem: {
    backgroundColor: 'white',
  },

  children: {
    padding: `0 ${theme.spacing(1.5)} ${theme.spacing(1.5)} ${theme.spacing(
      1.5,
    )}`,
  },

  headerContainer: {
    backgroundColor: theme.palette.section.white.backgroundColor,
  },

  header: {
    color: theme.Heading.color,
  },

  icon: {
    color: theme.care.palette.text.positive,
  },

  definitionDescription: {
    margin: `${theme.care.spacing.xs} 0 ${theme.care.spacing.sm}`,
    maxWidth: '70ch',
  },
})

type Props = FelaPropsType

const ServiceMetricsDefinitions = (props: Props) => {
  const { rules, styles } = props

  // Localization
  const { t: translation } = useTranslation('reporting')

  return (
    <>
      <Text size="lg" className={styles.definitionDescription}>
        {translation(
          'metrics_are_an_important_starting_point_for_discussion_around_a_services_strengths_and_weaknesses_these_metrics_document_the_performance_of_your_service_after_implementing_the_innowell_platform_into_its_pathways_as_usual_practice_when_sample_sizes_are_small_descriptive_statistics_may_be_incorrect_care_should_be_taken_with_interpretation_and_extrapolation',
        )}
      </Text>
      <Accordion>
        <AccordionItem
          extend={(...args) => ({
            AccordionItem: rules.AccordionItem(...args),
            children: rules.children(...args),
            headerContainer: rules.headerContainer(...args),
            header: rules.header(...args),
            icon: rules.icon(...args),
          })}
          heading={translation('definitions')}
        >
          <Text size="lg">
            <Text size="lg" as="span" bold>
              {translation('time_period')}
            </Text>
            &nbsp;{translation(
              'unless_otherwise_stated_all_metrics_reference_activities_or_times_recorded_during_the_selected_time_period_for_example_the_total_number_of_individuals_who_responded_to_a_questionnaire_would_only_display_the_individuals_who_responded_to_the_questionnaire_during_that_selected_time_period',
            )}
          </Text>
          <Text size="lg">
            <Text size="lg" as="span" bold>
              {translation('active_user')}
            </Text>
            &nbsp;{translation(
              'a_user_account_status_becomes_active_under_insights_if_they_have_received_a_platform_invitation_regardless_if_they_have_accepted_the_invitation_or_not_as_long_as_the_invitation_was_not_removed_and_never_reinvited',
            )}
          </Text>
          <Text size="lg">
            {translation(
              'active_users_include_all_individuals_with_an_active_account_status_at_any_time_during_the_selected_time_period_this_includes_a_user_that_may_have_initially_been_active_and_later_switched_to_a_read_only_or_discharged_status_during_the_selected_time_period',
            )}
          </Text>
          <Text size="lg">
            {translation(
              'conversely_if_a_user_had_a_read_only_or_discharged_status_and_was_reactivated_during_the_selected_time_period_they_would_also_be_counted_as_active',
            )}
          </Text>
          <Text size="lg">
            <Text size="lg" as="span" bold>
              {translation('innowell_platform_email_invitation')}
            </Text>
            &nbsp;{translation(
              'the_time_of_innowell_platform_email_invitation_refers_to_the_latest_time_between_the_time_of_first_invitation_sent_and_the_time_of_invitation_that_was_accepted_by_the_user',
            )}
          </Text>
        </AccordionItem>
      </Accordion>
    </>
  )
}

export default connect(styleRules)(ServiceMetricsDefinitions)
