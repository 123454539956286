// @flow

import React, { Fragment } from 'react'

import Link from 'react-ui/components/Link'

const InnowellPeopleQuestions = [
  {
    key: 'whoAreInnowellBoardMembers',
    question: <Fragment> Who are the Innowell board members? </Fragment>,
    answer: (
      <Fragment>
        To see our current Board Members and find out more about our Executive
        Team please visit{' '}
        <Link to="https://www.innowell.org/about">
          https://www.innowell.org/about{' '}
        </Link>
      </Fragment>
    ),
  },
  {
    key: 'mediaInterviewRequest',
    question: (
      <Fragment> Who do I contact about a media / interview request? </Fragment>
    ),
    answer: (
      <Fragment>
        Please submit your interview request including your publication and
        deadline to{' '}
        <Link to="mailto: info@innowell.org">info@innowell.org </Link>
      </Fragment>
    ),
  },
]

export default InnowellPeopleQuestions
