// @flow

import React from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { Container } from 'react-ui/components/Grid'
import SimpleHeading from 'react-ui/components/Heading/SimpleHeading'
import { ResponsiveLayout } from 'react-ui/components/Layout'
import Link from 'react-ui/components/Link'
import Section from 'react-ui/components/Section'
import Can from 'react-ui/utils/Can'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => {
  const vPadding = theme.spacing(2)
  const { backgroundColor } = theme.palette.section.default

  return {
    wrapper: {
      backgroundColor: `${backgroundColor}`,
      display: 'flex',
      flexDirection: 'column',
      height: 'auto',
      flexShrink: 1,
      flexGrow: 'inherit',
    },
    container: {
      backgroundColor: '#ffffff',
      padding: '1em',
      display: 'flex',
      flexFlow: 'row wrap',
    },
    headerContainer: {
      backgroundColor: theme.Header.backgroundColor,
    },
    mainContainer: {
      paddingTop: vPadding,
    },
    grid: {
      width: '33%',
    },
    title: {
      margin: '0',
    },
    list: {
      marginTop: '2em',
    },
    link: {
      color: 'rgb(0 132 121)',
      display: 'block',
      margin: '1em auto',
    },
  }
}

type propsType = FelaPropsType & {
  user: any,
}

const StaffWelcomePageWrapper = (props: propsType) => {
  const { t: translation } = useTranslation('shared')
  const { user, styles, rules } = props
  const {
    sign_in_count,
    staff_role,
    multi_roles_enabled,
    current_role: { parent },
  } = user

  const welcomeMsg =
    sign_in_count > 1 ? translation('welcome_back') : translation('welcome')
  const title = `${welcomeMsg} ${user.casual_name}`

  return (
    <ResponsiveLayout
      extend={{ ResponsiveLayout: rules.wrapper }}
      hasEmptyHeader
    >
      <Section noPadding>
        <div className={styles.headerContainer}>
          <SimpleHeading text={title} />
        </div>
      </Section>
      <Section color="default">
        <Container className={styles.mainContainer}>
          {multi_roles_enabled &&
            staff_role && (
              <Can>
                {policies => {
                  const canViewInsight = policies.CAN_VIEW_INSIGHTS
                  const canViewClientList = policies.CAN_VIEW_CLINICIAN_LINKS
                  const canViewInviteIndividual =
                    policies.CAN_LIST_TENANT_INDIVIDUALS
                  const canViewManageIndividuals =
                    policies.CAN_LIST_TENANT_INDIVIDUALS
                  const canViewNotifications = policies.CAN_VIEW_NOTIFICATIONS

                  const canViewClientManagement =
                    canViewClientList ||
                    canViewInviteIndividual ||
                    canViewManageIndividuals ||
                    canViewNotifications

                  const canViewClinicians =
                    policies.CAN_VIEW_LEAD_CLINICIAN_LINKS
                  const canViewEmployees =
                    policies.CAN_LIST_TENANT_EMPLOYEES &&
                    parent &&
                    parent.__typename === 'Tenant'
                  const canViewManagers =
                    policies.CAN_LIST_TENANT_MANAGERS &&
                    parent &&
                    parent.__typename === 'TenantGroup'

                  const canViewStaffManagement =
                    canViewClinicians || canViewEmployees || canViewManagers

                  return canViewInsight ||
                    canViewClientManagement ||
                    canViewStaffManagement ? (
                    <div className={styles.container}>
                      {canViewInsight && (
                        <div className={styles.grid}>
                          <h4 className={styles.title}>
                            {translation('insights')}
                          </h4>
                          <div className={styles.list}>
                            <Link to="insights" className={styles.link}>
                              {translation('view_insights')}
                            </Link>
                          </div>
                        </div>
                      )}

                      {canViewClientManagement && (
                        <div className={styles.grid}>
                          <h4 className={styles.title}>
                            {translation('client_management')}
                          </h4>

                          <div className={styles.list}>
                            {(canViewManageIndividuals ||
                              canViewClientList) && (
                              <Link
                                to="staff_individuals"
                                className={styles.link}
                              >
                                {translation('individuals')}
                              </Link>
                            )}
                            {canViewInviteIndividual && (
                              <Link
                                to="individualsInvite"
                                className={styles.link}
                              >
                                {translation('invite_individuals')}
                              </Link>
                            )}
                            {canViewNotifications && (
                              <Link
                                to="staff_notifications"
                                className={styles.link}
                              >
                                {translation('notifications')}
                              </Link>
                            )}
                          </div>
                        </div>
                      )}

                      {canViewStaffManagement && (
                        <div className={styles.grid}>
                          <h4 className={styles.title}>
                            {translation('staff_management')}
                          </h4>

                          <div className={styles.list}>
                            {canViewClinicians && (
                              <Link
                                to="staff_clinicians"
                                className={styles.link}
                              >
                                {translation('clinicians')}
                              </Link>
                            )}
                            {canViewEmployees &&
                              parent &&
                              parent.id && (
                                <Link
                                  className={styles.link}
                                  to={{
                                    name: 'staff_employees',
                                    params: { id: parent.id },
                                  }}
                                >
                                  {translation('employees')}
                                </Link>
                              )}
                            {canViewManagers &&
                              parent &&
                              parent.id && (
                                <Link
                                  className={styles.link}
                                  to={{
                                    name: 'staff_managers',
                                    params: { id: parent.id },
                                  }}
                                >
                                  {translation('managers')}
                                </Link>
                              )}
                          </div>
                        </div>
                      )}
                    </div>
                  ) : (
                    ''
                  )
                }}
              </Can>
            )}
        </Container>
      </Section>
    </ResponsiveLayout>
  )
}

export default connect(styleRules)(StaffWelcomePageWrapper)
