// @flow

import { type ThemeType } from 'care-ui/atoms'

type PropsType = {
  theme: ThemeType,
}

export const descriptionStyle = ({ theme }: PropsType) => ({
  marginTop: theme.care.spacing.sm,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  '-webkit-line-clamp': 6,
  '-webkit-box-orient': 'vertical',
})
