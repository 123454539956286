// @flow

import type { WelcomePageContainer_user } from 'containers/WelcomePage/__generated__/WelcomePageContainer_user.graphql'

type QuestCardDescType = {
  isInitialQuestionnaireCompleted: boolean,
  summaryQuestionnaireId?: string,
}
type WelcomeCardLinkToType = {
  initialQuestionnaireId: string,
  isInitialQuestionnaireCompleted: boolean,
  summaryQuestionnaireId?: string,
}
type ActiveStateProps = {
  needsDemographicsQuestionnaire: boolean,
  needsInitialQuestionnaire: boolean,
}
type ButtonActionProps = {
  activeIndex: number,
  initialQuestionnaireId: string,
}
export type OnboardingWizardWrapperProps = {
  initialQuestionnaireId: string,
  isInitialQuestionnaireCompleted: boolean,
  user: WelcomePageContainer_user,
}

const hasSummary = (summaryQuestionnaireId?: string) => {
  return !!summaryQuestionnaireId && summaryQuestionnaireId !== ''
}
export const questionnaireCardDesc = ({
  isInitialQuestionnaireCompleted,
  summaryQuestionnaireId,
}: QuestCardDescType) => {
  let desc = 'Complete your health assessment'
  if (isInitialQuestionnaireCompleted) {
    desc = hasSummary(summaryQuestionnaireId)
      ? 'Update your health cards'
      : 'To refresh your results, update your summary dashboard and health cards anytime'
  }
  return desc
}
export const questionnarieCardRoute = ({
  isInitialQuestionnaireCompleted: initialCompleted,
  summaryQuestionnaireId: summaryId,
  initialQuestionnaireId: initialId,
}: WelcomeCardLinkToType) => {
  let questionnaireId
  if (initialCompleted && hasSummary(summaryId)) {
    // summaryQuestionnaire is ready to start
    questionnaireId = summaryId
  } else if (!initialCompleted) {
    // initialQuestionnaire needs finishing
    questionnaireId = initialId
  } else {
    // no questionnaire to go to
    return undefined
  }
  return {
    name: 'loadQuestionnaire',
    params: { questionnaireId },
  }
}
export const steps = [
  { label: 'Create account', display: 1 },
  { label: 'Setup your profile', display: 2 },
  { label: 'Complete a health assessment', display: 3 },
]
export const getActiveState = ({
  needsDemographicsQuestionnaire,
  needsInitialQuestionnaire,
}: ActiveStateProps): number => {
  if (needsDemographicsQuestionnaire) {
    return 1
  }
  if (needsInitialQuestionnaire) {
    return 2
  }
  return 3
}
export const getLabel = (activeIndex: number) => {
  if (activeIndex === 1) {
    return 'Start'
  } else if (activeIndex === 2) {
    return 'Continue'
  }
  return undefined
}
export const getButtonAction = ({
  activeIndex,
  initialQuestionnaireId,
}: ButtonActionProps) => {
  let linkTo
  if (activeIndex === 1) {
    /* TODO: Creating this route and separating onboarding questionnaire is part of another task */
    /* {name: 'onboarding'} */
    linkTo = null
  }
  if (activeIndex === 2) {
    linkTo = questionnarieCardRoute({
      isInitialQuestionnaireCompleted: false,
      initialQuestionnaireId,
    })
  }
  return {
    label: getLabel(activeIndex),
    linkTo,
  }
}
/** ** Allow user to see homepage without first filling in the on-boarding *** */
/** ** The Homepage without completed on-boarding will display a Grid with Steps *** */
/* Step 1 - Create Account  */
/* Step 2 - Setup your profile */
/* Step 3 - Complete a health assessment */
/*
{
  title: <<TITLE OF STEPS COMPONENT>>,
  activeState: <<CURRENT ACTIVE STEP>>,
  steps: <<ARRAY WITH ALL THE STEPS>>,
  button: {
    label: <<BUTTON LABEL>>,
    link: <<OPTIONAL BUTTON LINK>>,
    onClick: <<OPTIONAL ONCLICK EVENT FUNCTION>>,
  }
}
*/
export const showSteps = ({
  user,
  initialQuestionnaireId,
  isInitialQuestionnaireCompleted,
}: OnboardingWizardWrapperProps) => {
  const needsDemographicsQuestionnaire =
    user.show_individual_onboarding === true
  const needsInitialQuestionnaire = isInitialQuestionnaireCompleted === false
  const activeIndex = getActiveState({
    needsDemographicsQuestionnaire,
    needsInitialQuestionnaire,
  })
  const button = getButtonAction({
    activeIndex,
    initialQuestionnaireId,
  })

  return {
    activeIndex,
    button,
    steps,
    title: 'Start tailoring your dashboard',
  }
}
