// @flow

import React from 'react'

import Heading from 'react-ui/components/Heading'

import { type FelaStylesType } from 'react-ui/typing'
import { type AboutMeTab_user } from '../../queries/__generated__/AboutMeTab_user.graphql'

type AvatarType = {
  styles: FelaStylesType,
  +user: AboutMeTab_user,
}

const AvatarSide = (props: AvatarType) => {
  const { user, styles } = props

  return (
    <div className={styles.avatar}>
      <Heading level={3} extend={() => ({ marginTop: 0, paddingLeft: '10px' })}>
        {user.legal_name}
      </Heading>
    </div>
  )
}

export default AvatarSide
