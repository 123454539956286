// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'
import { map } from 'lodash/fp'

import type { StaffIndividualSelectionListUserType } from 'components/Staff/StaffIndividualSelectionList'
import type { StaffLeadIndividualSelectionListTenantType } from 'components/Staff/StaffLeadIndividualSelectionList'
import type { PageControlsType } from 'react-ui/components/DataGrid'
import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import { DropDownMenuItem } from 'care-ui'

type StaffIndividualSelectionUserType = {
  count?: number,
  relay: PageControlsType,
  tenant?: StaffLeadIndividualSelectionListTenantType,
  user?: StaffIndividualSelectionListUserType,
}

const StaffIndividualSelectionComponent = ({
  user,
  tenant,
  relay,
  count = 25,
}: StaffIndividualSelectionUserType) => {
  // Localization
  const { t: translation } = useTranslation('shared')
  const { match: { params } } = useRouter()

  const { hasMore, loadMore } = relay

  const edges = user ? user.individuals.edges : tenant?.roles.edges

  const renderList = map(edge => {
    const { node: { id, legal_name, user: individualUser } } = edge

    if (individualUser.id === params.id) return null

    const displayName = legal_name || individualUser.username

    return (
      <DropDownMenuItem key={id} href={`/users/${individualUser.id}/dashboard`}>
        {displayName}
      </DropDownMenuItem>
    )
  })(edges)

  return renderList.length ? (
    <Fragment>
      {renderList}
      {hasMore && hasMore() && <LoadMore handleClick={() => loadMore(count)} />}
    </Fragment>
  ) : (
    <p>{translation('no_results_found')}</p>
  )
}

export default StaffIndividualSelectionComponent
