/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type Resource_resource$ref = any;
type UserResourceStatusUpdate_user_resource$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserResource_user_resource$ref: FragmentReference;
declare export opaque type UserResource_user_resource$fragmentType: UserResource_user_resource$ref;
export type UserResource_user_resource = {|
  +id: string,
  +resource: {|
    +clinical: boolean,
    +id: string,
    +$fragmentRefs: Resource_resource$ref,
  |},
  +status: UserResourceStatuses,
  +user: {|
    +id: string,
    +name: string,
    +$fragmentRefs: AvatarLoader_user$ref,
  |},
  +supporting_clinician: ?{|
    +user: {|
      +id: string,
      +name: string,
      +$fragmentRefs: AvatarLoader_user$ref,
    |}
  |},
  +$fragmentRefs: UserResourceStatusUpdate_user_resource$ref,
  +$refType: UserResource_user_resource$ref,
|};
export type UserResource_user_resource$data = UserResource_user_resource;
export type UserResource_user_resource$key = {
  +$data?: UserResource_user_resource$data,
  +$fragmentRefs: UserResource_user_resource$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarLoader_user"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserResource_user_resource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clinical",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "Resource_resource"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianRole",
      "kind": "LinkedField",
      "name": "supporting_clinician",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserResourceStatusUpdate_user_resource"
    }
  ],
  "type": "UserResource",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6dcf3f7dfe2665b2d91980d78e779dcd';

module.exports = node;
