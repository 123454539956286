// @flow

import type { ActionsType } from './actions'
import { ADD_POLICIES } from './actionTypes'

export type StateType = { [key: string]: boolean }

const defaultState = {}

export default function(
  state: StateType = defaultState,
  action: ActionsType,
): StateType {
  switch (action.type) {
    case ADD_POLICIES: {
      return action.policies
    }

    default:
      return state
  }
}
