/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TenantContext_parent$ref: FragmentReference;
declare export opaque type TenantContext_parent$fragmentType: TenantContext_parent$ref;
export type TenantContext_parent = {|
  +id: string,
  +name: string,
  +supported_languages: $ReadOnlyArray<string>,
  +__typename: string,
  +$refType: TenantContext_parent$ref,
|};
export type TenantContext_parent$data = TenantContext_parent;
export type TenantContext_parent$key = {
  +$data?: TenantContext_parent$data,
  +$fragmentRefs: TenantContext_parent$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantContext_parent",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "supported_languages",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    }
  ],
  "type": "RoleParentInterface",
  "abstractKey": "__isRoleParentInterface"
};
// prettier-ignore
(node/*: any*/).hash = '861dae8ab439cc2cdd996176cfbcbe7b';

module.exports = node;
