// @flow

import { createComponentWithProxy } from 'react-fela'

const Tab = props => {
  const { theme, isSelected } = props
  const { palette } = theme

  const selectionStatusStyles = isSelected
    ? {
        ...theme.NavigationTabSelected,
        pointerEvents: 'none',
      }
    : {
        ...theme.NavigationTab,
        color: palette.link.linkColor,
        '&:hover': {
          color: theme.NavigationTabSelected.color,
        },
      }

  return {
    className: 'Tabs__tab',
    cursor: 'pointer',
    paddingLeft: 0,
    ...selectionStatusStyles,
    paddingRight: 0,
  }
}

export default createComponentWithProxy(Tab, 'button')
