// @flow

import merge from 'lodash/fp/merge'

import { createTheme } from './synergy-base-theme'
import * as colors from './synergy-base-theme/colors'
import defaultPalette from './synergy-base-theme/palette'

const palette = merge(defaultPalette, {
  component: {
    primary: {
      accent: colors.synGreen['600'],
      active: colors.synGreen['800'],
      base: colors.synGreen['500'],
      mutedBase: colors.synGreen['50'],
      mutedActive: colors.synGreen['100'],
      text: colors.white,
    },

    secondary: {
      accent: colors.synGreen.a300,
      active: colors.synGreen['500'],
      base: colors.synGreen.a200,
      mutedBase: colors.synBlue['50'],
      mutedActive: colors.synGreen['100'],
      text: colors.white,
    },
  },

  input: {
    focus: {
      accent: colors.synGreen['500'],
    },
    icon: {
      base: colors.synGreen['500'],
    },
  },

  link: {
    linkColor: colors.synGreen['500'],
    visitedColor: colors.synGreen['500'],
    focusColor: colors.synGreen['500'],
    hoverColor: colors.synGreen['500'],
    activeColor: colors.synGreen['500'],
  },

  section: {
    primary: {
      backgroundColor: colors.synGreen['600'],
      backgroundImage: `linear-gradient(
        180deg,
        ${colors.synGreen['500']} 0%,
        ${colors.synGreen['600']} 100%
      )`,
    },
  },

  tabs: {
    backgroundColor: colors.synGreen['700'],
  },

  themelet: {
    grey: {
      link: {
        linkColor: colors.synGreen['200'],
        visitedColor: colors.synGreen['200'],
        focusColor: colors.synGreen['200'],
        hoverColor: colors.synGreen['200'],
        activeColor: colors.synGreen['200'],
      },
    },
    primary: {
      backgroundColor: colors.synGreen['600'],
    },
  },
})

export default createTheme({
  name: 'Synergy Clinician theme',
  palette,
})
