/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConnectedCliniciansList_clinician_assignments$ref: FragmentReference;
declare export opaque type ConnectedCliniciansList_clinician_assignments$fragmentType: ConnectedCliniciansList_clinician_assignments$ref;
export type ConnectedCliniciansList_clinician_assignments = $ReadOnlyArray<{|
  +clinician: {|
    +id: string,
    +name: string,
  |},
  +$refType: ConnectedCliniciansList_clinician_assignments$ref,
|}>;
export type ConnectedCliniciansList_clinician_assignments$data = ConnectedCliniciansList_clinician_assignments;
export type ConnectedCliniciansList_clinician_assignments$key = $ReadOnlyArray<{
  +$data?: ConnectedCliniciansList_clinician_assignments$data,
  +$fragmentRefs: ConnectedCliniciansList_clinician_assignments$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ConnectedCliniciansList_clinician_assignments",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianRole",
      "kind": "LinkedField",
      "name": "clinician",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "ClinicianAssignment",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b13ba0461f159c9ff5ac7aa24da9945a';

module.exports = node;
