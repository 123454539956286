// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { Portal } from 'react-portal'
import Tether from 'react-tether'

import { withoutFelaProps } from 'shared/services/fela'
import createComponentId from 'shared/services/id'

import type { TetherAttachmentType, TetherOptionsType } from 'react-ui/typing'

export type PropsType = TetherOptionsType & {
  children: React.Node,
  componentId?: string,
  externalToggle?: () => void,
  overlay?: boolean,
  renderFooter: ({}) => React.Node | null,
  renderTrigger: ({}) => any,
  styles?: { [className: string]: string },
}

const borderSize = 2
const triangleSize = 10
const triangleOffset = triangleSize - borderSize * 2
const triangleVOffset = triangleSize + triangleOffset

/**
 * Translate the container to provide enough space for the triangle
 */
export function getTranslateForAttachment(
  xAttachment: string = 'center',
  targetAttachment: TetherAttachmentType,
) {
  if (targetAttachment === 'middle left') {
    return { x: `-${triangleOffset}px`, y: `-${triangleVOffset}px` }
  }

  if (targetAttachment === 'middle right') {
    return { x: `${triangleOffset}px`, y: `-${triangleVOffset}px` }
  }

  if (xAttachment === 'right' && targetAttachment.indexOf('bottom') === 0) {
    return { x: '40px', y: `${triangleSize}px` }
  }

  return { x: '-40px', y: `${triangleSize}px` }
}

/**
 * Calculate horizontal position of the triangle based on container attachment
 */
function getTrianglePositionForXAttachment(xAttachment: string) {
  switch (xAttachment) {
    case 'right': {
      return {
        left: 'auto',
        right: '35px',
      }
    }
    default: {
      return {
        left: '35px',
        right: 'auto',
      }
    }
  }
}

/**
 * Position/rotate the triangle based on target attachment
 */
export function drawTriangleForAttachment(
  targetAttachment: TetherAttachmentType,
) {
  if (targetAttachment === 'middle left') {
    return {
      right: `-${triangleOffset}px`,
      top: `${triangleSize}px`,
      transform: 'rotate(135deg)',
    }
  }

  if (targetAttachment === 'middle right') {
    return {
      left: `-${triangleOffset}px`,
      top: `${triangleSize}px`,
      transform: 'rotate(-45deg)',
    }
  }

  if (targetAttachment.startsWith('top')) {
    return {
      bottom: `-${triangleOffset}px`,
      transform: 'rotate(-135deg)',
    }
  }

  return {
    top: `-${triangleOffset}px`,
    transform: 'rotate(45deg)',
  }
}

const styleRules = ({
  theme,
  attachment = 'top right',
  targetAttachment = 'bottom center',
  width,
}) => {
  const xAttach = attachment.split(' ')[1]
  const { x: translateX, y: translateY } = getTranslateForAttachment(
    xAttach,
    targetAttachment,
  )

  return {
    container: {
      backgroundColor: theme.care.palette.surface.default,
      borderRadius: theme.care.radius.sm,
      border: `${borderSize}px solid transparent`,
      boxShadow: theme.care.elevation[2],
      boxSizing: 'border-box',
      className: `Popover__container--x-attachment-${xAttach}`,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: `${theme.care.spacing.sm} ${theme.care.spacing.xxs}`,
      position: 'relative',
      width: width ? `${width}px` : '332px',
      [theme.breakpoints.queries.lg]: {
        transform: `translate(${translateX}, ${translateY})`,
      },

      '@supports(filter: drop-shadow(0 0 black))': {
        boxShadow: 'none',
        filter: 'drop-shadow(0 4px 6px #ACACAC)',
      },

      ':before': {
        backgroundColor: 'white',
        borderRadius: `${borderSize}px`,
        borderLeftColor: 'inherit',
        borderLeftStyle: 'inherit',
        borderLeftWidth: 'inherit',
        borderTopColor: 'inherit',
        borderTopStyle: 'inherit',
        borderTopWidth: 'inherit',
        content: '""',
        height: `${triangleSize}px`,
        position: 'absolute',
        width: `${triangleSize}px`,
        zIndex: 1,
        ...getTrianglePositionForXAttachment(xAttach),
        ...drawTriangleForAttachment(targetAttachment),
      },
    },

    tether: {
      className: 'PopOver__tether',
      zIndex: 102,

      /**
       * Depending on config, Tether can flip the container to the other side of the trigger to fit
       * inside the viewport. When a PopOver is flipped from bottom to top, we need to adjust the
       * triangle to point down (and move the container slightly to match).
       *
       * Ideally these styles would be applied directly to the container styles above, but Fela
       * doesn't currently allow that:
       * https://github.com/rofrischmann/fela/issues/702
       */
      '&.tether-target-attached-top.tether-element-attached-bottom > [data-component-id]': {
        transform: `translate(${translateX}, -${translateY})`,

        ':before': {
          top: 'auto',
          ...drawTriangleForAttachment('top right'),
        },
      },
    },

    overlay: {
      className: 'PopOver__overlay',
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: 'none',
      backgroundColor: 'transparent',
    },

    greyOverlay: {
      className: 'PopOver__overlay',
      position: 'fixed',
      top: 0,
      left: 0,
      height: '100%',
      width: '100%',
      border: 'none',
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
      zIndex: 101,
    },

    scrollPane: {
      className: 'PopOver__scrollPane',
      maxHeight: '346px',
      overflow: 'auto',
      overflowX: 'hidden',
      overflowY: 'auto',
      zIndex: 2,
    },
  }
}

const defaultId = createComponentId(__filename)

const PopOverBase = ({
  componentId = defaultId,
  renderTrigger,
  styles = {},
  children,
  overlay = false,
  renderFooter,
  externalToggle,
  ...props
}: PropsType) => {
  const [isOpen, setIsOpen] = React.useState(Boolean(externalToggle))

  const onToggleOpen = () => {
    if (externalToggle) {
      externalToggle()
    } else {
      setIsOpen(prev => !prev)
    }
  }

  return (
    <React.Fragment>
      <Tether
        classes={{ element: styles.tether }}
        renderTarget={ref => (
          <div
            // Although the targetWrapper style is not in the styleRules of this component, this allows for extending by parent components
            className={styles.targetWrapper}
            ref={ref}
          >
            {renderTrigger && renderTrigger({ isOpen, onToggleOpen })}
          </div>
        )}
        renderElement={ref =>
          isOpen && (
            <div
              ref={ref}
              className={`${styles.container} ${componentId}`}
              data-component-id={componentId}
              data-testid={componentId}
            >
              <div className={styles.scrollPane}>
                {typeof children === 'function'
                  ? children({ onToggleOpen })
                  : children}
              </div>
              {renderFooter && renderFooter({ onToggleOpen })}
            </div>
          )
        }
        {...withoutFelaProps(props)}
      />
      {isOpen && (
        <Portal>
          <button
            type="button"
            className={overlay ? styles.greyOverlay : styles.overlay}
            onClick={onToggleOpen}
          />
        </Portal>
      )}
    </React.Fragment>
  )
}

export default connect(styleRules)(PopOverBase)
