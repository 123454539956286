// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const NewWindowIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        id="chatIcon"
        viewBox="0 0 28 28"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <path
            d="M25.4545455,4.34536271 L13.8900796,15.9098285 C13.3921915,16.4077166 12.58794,16.4061218 12.0909091,15.9090909 C11.590413,15.4085948 11.5935479,14.6065439 12.0901715,14.1099204 L23.6546373,2.54545455 L17.1766536,2.54545455 C16.4776165,2.54545455 15.9090909,1.97563514 15.9090909,1.27272727 C15.9090909,0.564918862 16.4781758,0 17.1801776,0 L26.7289133,0 C27.4330475,0 28,0.569084886 28,1.27108667 L28,10.8198224 C28,11.5239566 27.4301806,12.0909091 26.7272727,12.0909091 C26.0194643,12.0909091 25.4545455,11.523402 25.4545455,10.8233464 L25.4545455,4.34536271 Z M3.18064326,25.4545455 L21.0011749,25.4545455 C21.3516767,25.4545455 21.6363636,25.1696471 21.6363636,24.8182071 L21.6363636,15.9091585 C21.6363636,15.2027644 22.206183,14.6363636 22.9090909,14.6363636 C23.6168993,14.6363636 24.1818182,15.2046336 24.1818182,15.90563 L24.1818182,25.4549469 C24.1818182,26.8606239 23.0436484,28 21.6396449,28 L2.54217333,28 C1.13390505,28 0,26.8618302 0,25.4578267 L0,6.36035515 C0,4.95208687 1.1394591,3.81818182 2.54505314,3.81818182 L12.09437,3.81818182 C12.7894176,3.81818182 13.3636364,4.38800123 13.3636364,5.09090909 C13.3636364,5.79871753 12.7937867,6.36363636 12.0908415,6.36363636 L3.18179285,6.36363636 C2.83481477,6.36363636 2.54545455,6.64802002 2.54545455,6.99882508 L2.54545455,24.8193567 C2.54545455,25.1698585 2.82983822,25.4545455 3.18064326,25.4545455 Z"
            fillRule="nonzero"
          />
        </g>
      </svg>
    </BaseIcon>
  )
}
export default NewWindowIcon
