// @flow

import React, { useState } from 'react'
import { graphql } from 'react-relay'

import StaffIndividualSelectionComponent from 'components/Staff/StaffIndividualSelectionComponent'
import { type PageControlsType } from 'react-ui/components/DataGrid'
import { PaginationQueryLoader } from 'react-ui/components/Pagination/PaginationQueryLoader'
import { PaginationSearchControl } from 'react-ui/components/Pagination/PaginationSearchControl'
import { Divider, FlexContainer, RadioButton, ScrollableBox } from 'care-ui'

import type { StaffIndividualSelectionList_user } from './__generated__/StaffIndividualSelectionList_user.graphql'

export type StaffIndividualSelectionListUserType = StaffIndividualSelectionList_user

type PropsType = {
  count?: number,
  filters: { [key: string]: string }[],
  queryType: string,
}

const staffIndividualQuery = graphql`
  query StaffIndividualSelectionList_PaginatedQuery(
    $count: Int!
    $cursor: String
    $search: String
    $sort: [RoleSortable!]
    $assigned: Boolean
    $prioritise: Boolean
    $status: [String!]
  ) {
    viewer {
      currentUser {
        ...StaffIndividualSelectionList_user
      }
    }
  }
`

const staffIndividualQueryFragments = {
  user: graphql`
    fragment StaffIndividualSelectionList_user on User {
      id
      individuals(
        first: $count
        after: $cursor
        filters: {
          roleStatus: $status
          search: $search
          sort: $sort
          assignedToMe: $assigned
          prioritiseEscalations: $prioritise
        }
      ) @connection(key: "Individuals_currentUser_individuals") {
        edges {
          node {
            ...StaffIndividualSelectionList_individual @relay(mask: false)
          }
        }
      }
    }
  `,
}

graphql`
  fragment StaffIndividualSelectionList_individual on IndividualRole {
    id
    legal_name
    user {
      id
      username
    }
  }
`

const sort = { column: 'recentActivity', direction: 'ASC' }

const queryVariables = {
  assigned: {
    assigned: true,
    prioritise: true,
    status: [],
    sort,
  },
  clinician_active: {
    assigned: null,
    prioritise: null,
    status: 'Active',
    sort,
  },
}

type IndividualsVariablesType = {
  assigned: true | null,
  prioritise: true | null,
  sort: { column: string, direction: string, id?: string }[],
  status: string | null,
}

const StaffIndividualSelectionList = ({
  queryType: initialQueryType,
  filters,
  count = 25,
}: PropsType) => {
  const [queryType, setQueryType] = useState(initialQueryType)
  const [selected, setSelected] = useState(filters[0]?.value)

  const tmpVariables = queryVariables[queryType]

  const initVariables: IndividualsVariablesType = {
    ...tmpVariables,
    count,
  }

  const handleUpdate = (e: SyntheticInputEvent<>, updateVariables) => {
    const selectedValue = e.target.value

    setSelected(selectedValue)
    setQueryType(selectedValue)
    updateVariables(queryVariables[selectedValue])
  }

  return (
    <PaginationQueryLoader
      query={staffIndividualQuery}
      fragments={staffIndividualQueryFragments}
      variables={initVariables}
      controls={({ updateVariables }) => (
        <FlexContainer direction="column" wrap="wrap" gap="xs">
          <FlexContainer gap="sm">
            {filters.map(filter => (
              <RadioButton
                inputAttributes={{
                  id: filter.value,
                  name: 'status',
                  value: filter.value,
                  label: filter.label,
                  checked: selected === filter.value,
                  onChange: (e: SyntheticInputEvent<>) =>
                    handleUpdate(e, updateVariables),
                }}
                dataTestId="status"
                ariaLabel={filter.value}
              />
            ))}
          </FlexContainer>

          <PaginationSearchControl fullWidth />

          <Divider />
        </FlexContainer>
      )}
      getFragments={data => ({ user: data.viewer.currentUser })}
    >
      {({
        relay,
        user,
      }: {
        relay: PageControlsType,
        user: StaffIndividualSelectionList_user,
      }) => {
        return (
          <ScrollableBox maxHeight="250px">
            <StaffIndividualSelectionComponent
              user={user}
              relay={relay}
              count={count}
            />
          </ScrollableBox>
        )
      }}
    </PaginationQueryLoader>
  )
}

export default StaffIndividualSelectionList
