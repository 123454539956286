// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query IndividualTrackablesListQuery(
    $id: ID!
    $requesteeRoleId: ID
    $loadList: Boolean!
  ) {
    user(id: $id) {
      linked_individual {
        id
        requestableQuestionnaires(requesteeRoleId: $requesteeRoleId)
          @include(if: $loadList) {
          id
          label
          category
          library_description
        }
        ...AssessmentRequesteesList_individual
      }
    }
  }
`
