// @flow

import { useState } from 'react'
import { useMutation } from 'react-relay'
import { type GraphQLTaggedNode } from 'relay-runtime'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'

type UseIndividualActionsModalType = {
  closeModal: () => void,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: GraphQLTaggedNode,
  queryVariables: QueryVariablesType,
}

const useIndividualActionsModal = (props: UseIndividualActionsModalType) => {
  const {
    closeModal,
    multiSelectPayload,
    queryVariables,
    mutationQuery,
  } = props

  const {
    assigned,
    filterEscalation,
    helpRequested,
    prioritise,
    status,
    search,
  } = queryVariables

  const filters = {
    assignedToMe: !!assigned,
    filterEscalation: !!filterEscalation,
    helpRequested: !!helpRequested,
    prioritiseEscalations: !!prioritise,
    roleStatus: [String(status)],
    search: String(search),
  }

  const {
    allSelected,
    selectedRoleIds,
    deselectedRoleIds,
    resetMultiSelectState,
    totalCount,
  } = multiSelectPayload

  const allSelectedMinusDeselectedCount = totalCount - deselectedRoleIds?.length
  const selectedRoleIdsCount = allSelected
    ? allSelectedMinusDeselectedCount
    : selectedRoleIds?.length

  // Commit mutation to perform action
  const [commit, isInFlight] = useMutation(mutationQuery)
  // prettier-ignore
  const [summaryState, setSummaryState] = useState<boolean>(false)
  // prettier-ignore
  const [failedList, setFailedList] = useState()

  const handleSummaryCloseModal = () => {
    resetMultiSelectState()
    closeModal()
  }

  const onConfirmation = (hasSummaryState: boolean = false) => {
    commit({
      variables: {
        input: {
          roles: {
            dataTableType: 'individual',
            selectedRoleIds: allSelected ? [] : selectedRoleIds,
            deselectedRoleIds,
            ...(allSelected && { filters }),
          },
        },
      },
      onCompleted: data => {
        setFailedList(data)
      },
    })

    if (hasSummaryState) {
      setSummaryState(true)
    } else {
      closeModal()
      resetMultiSelectState()
    }
  }

  const handleDone = () => {
    setSummaryState(false)
    resetMultiSelectState()
    closeModal()
  }

  return {
    filters,
    allSelectedMinusDeselectedCount,
    selectedRoleIdsCount,
    onConfirmation,
    handleDone,
    handleSummaryCloseModal,
    isInFlight,
    summaryState,
    failedList,
  }
}

export default useIndividualActionsModal
