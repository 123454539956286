// @flow

import { graphql, useMutation } from 'react-relay'

import { type useSaveUserTrackableMutation } from './__generated__/useSaveUserTrackableMutation.graphql'

const mutation = graphql`
  mutation useSaveUserTrackableMutation($input: UserPlanSetInput!) {
    userPlanSet(input: $input) {
      userTrackables {
        id
      }
    }
  }
`

const useSaveUserTrackable = () => {
  // prettier-ignore
  const [ commit, isInFlight ] = useMutation<useSaveUserTrackableMutation>(mutation)

  return { commit, isInFlight }
}

export default useSaveUserTrackable
