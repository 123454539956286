/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HelpNowPage_tenant$ref = any;
export type HelpNowPageTenant_QueryVariables = {|
  id: string
|};
export type HelpNowPageTenant_QueryResponse = {|
  +viewer: {|
    +tenant: {|
      +id: string,
      +$fragmentRefs: HelpNowPage_tenant$ref,
    |}
  |}
|};
export type HelpNowPageTenant_Query = {|
  variables: HelpNowPageTenant_QueryVariables,
  response: HelpNowPageTenant_QueryResponse,
|};
*/


/*
query HelpNowPageTenant_Query(
  $id: ID!
) {
  viewer {
    tenant(id: $id) {
      id
      ...HelpNowPage_tenant
    }
    id
  }
}

fragment HelpNowPage_tenant on Tenant {
  help_now_providers {
    chat_url
    description
    hours
    id
    slug
    logo_url
    phone_number
    sequence
    sms
    title
    url
  }
  emergencyNumber
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpNowPageTenant_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HelpNowPage_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HelpNowPageTenant_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "HelpNowProvider",
                "kind": "LinkedField",
                "name": "help_now_providers",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "chat_url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "description",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hours",
                    "storageKey": null
                  },
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "slug",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "logo_url",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "phone_number",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sequence",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sms",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "title",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "emergencyNumber",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "1c84de7d70e5b44d1661138ee2d819aa",
    "id": null,
    "metadata": {},
    "name": "HelpNowPageTenant_Query",
    "operationKind": "query",
    "text": "query HelpNowPageTenant_Query(\n  $id: ID!\n) {\n  viewer {\n    tenant(id: $id) {\n      id\n      ...HelpNowPage_tenant\n    }\n    id\n  }\n}\n\nfragment HelpNowPage_tenant on Tenant {\n  help_now_providers {\n    chat_url\n    description\n    hours\n    id\n    slug\n    logo_url\n    phone_number\n    sequence\n    sms\n    title\n    url\n  }\n  emergencyNumber\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c0cb699db77462993649fdc4e298be80';

module.exports = node;
