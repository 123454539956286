// @flow

import { graphql } from 'react-relay'

export const query = {
  user_question_set: graphql`
    fragment UserQuestionSetDetailsView_user_question_set on UserQuestionSet {
      id
      user {
        id
      }
      question_set {
        id
      }
    }
  `,
}
