/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserQuestionSetDetailsView_user_question_set$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionSetTable_user_question_sets$ref: FragmentReference;
declare export opaque type UserQuestionSetTable_user_question_sets$fragmentType: UserQuestionSetTable_user_question_sets$ref;
export type UserQuestionSetTable_user_question_sets = $ReadOnlyArray<{|
  +id: string,
  +submitted_answer_sets: $ReadOnlyArray<{|
    +submitted_at: ?any,
    +skip_reason_value: ?string,
    +skipped: boolean,
    +answered_by: {|
      +id?: string,
      +user?: {|
        +name: string
      |},
    |},
  |}>,
  +question_set: {|
    +id: string,
    +label: string,
  |},
  +$fragmentRefs: UserQuestionSetDetailsView_user_question_set$ref,
  +$refType: UserQuestionSetTable_user_question_sets$ref,
|}>;
export type UserQuestionSetTable_user_question_sets$data = UserQuestionSetTable_user_question_sets;
export type UserQuestionSetTable_user_question_sets$key = $ReadOnlyArray<{
  +$data?: UserQuestionSetTable_user_question_sets$data,
  +$fragmentRefs: UserQuestionSetTable_user_question_sets$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserQuestionSetTable_user_question_sets",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerSet",
      "kind": "LinkedField",
      "name": "submitted_answer_sets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submitted_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skip_reason_value",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skipped",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "answered_by",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "RoleInterface",
              "abstractKey": "__isRoleInterface"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionSetDetailsView_user_question_set"
    }
  ],
  "type": "UserQuestionSet",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b90861acdf36e0a45c9f7230149a8d45';

module.exports = node;
