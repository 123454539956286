// @flow

import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { endOfMonth, format, startOfMonth, subMonths } from 'date-fns'
import useRouter from 'found/useRouter'

import { PageHeader } from 'platform_web/components'
import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import useTabs, { type Tab } from 'platform_web/hooks/useTabs'
import { Box, Callout, Container, Tabs } from 'care-ui'
import alertInfo from 'care-ui/atoms/icons/alert-info.svg'

import OutcomesHeaderActions from './components/OutcomesHeaderActions'
import InsightsPageHeaderActions from './components/PageHeaderActions'
import Outcomes from './tabs/Outcomes'
import useOutcomeReporting from './tabs/Outcomes/hooks/useOutcomeReporting'
import Overview from './tabs/Overview'
import useOverviewInsights from './tabs/Overview/hooks/useOverviewInsights'

const TABS = {
  OVERVIEW: 'Overview',
  OUTCOMES: 'Outcomes',
}

const tabOptions: Array<Tab> = [
  { label: TABS.OUTCOMES, value: TABS.OUTCOMES },
  { label: TABS.OVERVIEW, value: TABS.OVERVIEW },
]

const defaultOverviewQueryVariables = {
  interval: 'monthly',
  start_date: format(startOfMonth(subMonths(new Date(), 5)), 'yyyy-MM-dd'),
  end_date: format(endOfMonth(new Date()), 'yyyy-MM-dd'),
}

const defaultOutcomeReportingQueryVariables = {
  quarter_end_date: format(new Date(), 'yyyy-MM-dd'),
}

const Insights = () => {
  // For owner role, we need to set default tenant_id filter
  const { currentUser, ownerTenants, tenantCreatedDate } = useCurrentUserRoles()
  const displayOutcomeReporting = window.GLOBALS?.features?.OUTCOME_REPORTING

  const ownerDefaultTenantId = ownerTenants?.[0]?.id

  const tenantId = currentUser?.tenant?.id

  const ownerDefaultTenantCreatedDate = ownerTenants?.[0]?.created_at

  const useInsightsPayload = useOverviewInsights({
    defaultOverviewQueryVariables,
  })

  const useOutcomeReportingPayload = useOutcomeReporting({
    defaultOutcomeReportingQueryVariables,
  })

  const {
    overviewQueryVariables,
    setOverviewQueryVariables,
  } = useInsightsPayload

  const {
    outcomeReportQueryVariables,
    setOutcomeReportQueryVariables,
  } = useOutcomeReportingPayload

  const { selectedTab, setSelectedTab } = useTabs(
    displayOutcomeReporting ? TABS.OUTCOMES : TABS.OVERVIEW,
    tabOptions,
  )

  const createdDate = ownerDefaultTenantCreatedDate || tenantCreatedDate
  const { interval = 'monthly' } = overviewQueryVariables

  const { router } = useRouter()
  // Feature Toggle
  const displayNewInsights = window.GLOBALS?.features?.NEW_INSIGHTS

  useEffect(() => {
    if (!displayNewInsights) {
      router.replace({
        name: 'insights',
      })
    }
  }, [])

  // Preload all the query on insights page load
  // Require current user redux state to be loaded for getting all the ownerTenants for owner roles
  useEffect(
    () => {
      if (!currentUser.loggedIn) return
      setOverviewQueryVariables({ owner_tenant_id: ownerDefaultTenantId })

      setOutcomeReportQueryVariables({
        tenant_id: ownerDefaultTenantId || tenantId,
        quarter_start_date: createdDate,
      })
    },
    [currentUser],
  )

  const { t: translation } = useTranslation('insights')

  const calloutText = (
    <span>
      {translation(
        'welcome_to_your_insights_page_we_ll_be_updating_it_regularly_for_you_with_new_features_so_if_there_are_metrics_you_d_like_to_see_or_you_have_questions_let_us_know_at',
      )}{' '}
      &nbsp;
      <a href="mailto:feedback@innowell.org">feedback@innowell.org</a>.
    </span>
  )

  const tabActions = {
    [TABS.OUTCOMES]: (
      <OutcomesHeaderActions
        setOverviewQueryVariables={setOverviewQueryVariables}
        overviewQueryVariables={overviewQueryVariables}
        outcomeReportQueryVariables={outcomeReportQueryVariables}
        setOutcomeReportQueryVariables={setOutcomeReportQueryVariables}
      />
    ),
    [TABS.OVERVIEW]: (
      <InsightsPageHeaderActions
        interval={interval}
        setOutcomeReportQueryVariables={setOutcomeReportQueryVariables}
        setOverviewQueryVariables={setOverviewQueryVariables}
        overviewQueryVariables={overviewQueryVariables}
      />
    ),
  }

  const tabs = [
    {
      label: TABS.OUTCOMES,
      content: (
        <Outcomes outcomeReportQueryVariables={outcomeReportQueryVariables} />
      ),
    },
    {
      label: TABS.OVERVIEW,
      content: <Overview overviewQueryVariables={overviewQueryVariables} />,
    },
  ]

  const renderPageAction = () => {
    return tabActions[selectedTab]
  }

  const handleTabChange = index => {
    setSelectedTab(tabs[index].label)
  }

  const currentTabContent = tabs.find(tab => tab.label === selectedTab)?.content

  return (
    <>
      <PageHeader
        pageTitle={translation('insights')}
        pageAction={renderPageAction()}
      />

      <Container>
        <Box marginY="sm">
          <Callout
            calloutText={calloutText}
            variant="warning"
            calloutIcon={alertInfo}
          />
        </Box>
        {displayOutcomeReporting && (
          <Tabs tabs={tabs} defaultTab={0} onChange={handleTabChange} />
        )}
        {currentTabContent}
      </Container>
    </>
  )
}

export default Insights
