// @flow

import React, { type Element, type Node } from 'react'
import { connect } from 'react-fela'

import { Text } from 'care-ui'

import FormError from './FormError'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ label, theme }) => ({
  Field: {
    boxSizing: 'border-box',
    display: 'block',
    className: 'Field',
    lineHeight: '28px',
    marginBottom: theme.spacing(0.75),
    paddingTop: theme.spacing(!label ? 0.75 : 0),
    width: '100%',
  },
  label: {
    className: 'Field__label',
    display: 'block',
    marginBottom: theme.spacing(0.25),
  },
  inputWrapper: {
    className: 'Field__inputWrapper',
    marginBottom: theme.spacing(0.25),
  },
  message: {
    className: 'Field__message',
    color: '#5B6C84',
    fontSize: '16px',
    lineHeight: 'inherit',
  },
})

type FieldProps = FelaPropsType & {
  as?: string,
  error?: Node,
  id?: string,
  input: Element<*>,
  label?: Node,
  message?: Node,
  name: string,
}

const Field = ({
  as: Component = 'div',
  id,
  error,
  input,
  label,
  message,
  name,
  styles,
}: FieldProps) => (
  <Component className={styles.Field}>
    {!!label && (
      <Text as="label" size="lg" htmlFor={id || name} className={styles.label}>
        <span dangerouslySetInnerHTML={{ __html: label }} />
      </Text>
    )}
    <div className={styles.inputWrapper}>
      {React.cloneElement(input, {
        error: !!error,
        id: id || name,
      })}
    </div>
    {!!error && <FormError>{error}</FormError>}
    {!!message && <div className={styles.message}>{message}</div>}
  </Component>
)

export default connect(styleRules)(Field)
