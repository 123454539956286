// @flow

import {
  first,
  flow,
  head,
  join,
  last,
  map,
  split,
  thru,
  words,
} from 'lodash/fp'

const extractInitials = flow(
  words,
  map(name => name.substr(0, 1).toUpperCase()),
  thru(names => {
    if (names.length > 1) {
      return [head(names), last(names)]
    }
    return names
  }),
  join(''),
)

const extractEmailInitials = flow(split('@'), first, extractInitials)

export const generateInitials = (name: string, email?: string): string =>
  name ? extractInitials(name) : extractEmailInitials(email)
