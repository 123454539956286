// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const exitFullscreenIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="exitFullScreenIcon"
        viewBox="0 0 29 30"
        x="0px"
        xmlSpace="preserve"
        y="0px"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="fs" fill="#637590" fillRule="nonzero">
            <path
              d="M8.575,7.775 L2.175,1.375 C1.675,0.875 0.875,0.875 0.375,1.375 C-0.125,1.875 -0.125,2.675 0.375,3.175 L6.775,9.575 L2.875,9.575 C2.175,9.575 1.675,10.175 1.675,10.875 C1.675,11.475 2.275,12.075 2.975,12.075 L9.875,12.075 C10.475,12.075 11.075,11.475 11.075,10.875 L11.075,3.975 C11.075,3.275 10.475,2.775 9.775,2.775 C9.075,2.775 8.475,3.375 8.475,3.975 L8.475,7.775 L8.575,7.775 Z M8.575,22.325 L2.175,28.725 C1.675,29.225 0.875,29.225 0.375,28.725 C-0.125,28.225 -0.125,27.425 0.375,26.925 L6.775,20.525 L2.875,20.525 C2.175,20.525 1.675,19.925 1.675,19.225 C1.675,18.525 2.275,17.925 2.875,17.925 L9.875,17.925 C10.475,18.025 11.075,18.625 11.075,19.225 L11.075,26.125 C11.075,26.825 10.475,27.325 9.775,27.325 C9.075,27.325 8.475,26.725 8.475,26.125 L8.475,22.325 L8.575,22.325 Z M19.425,7.675 L25.825,1.275 C26.325,0.775 27.125,0.775 27.625,1.275 C28.125,1.775 28.125,2.575 27.625,3.075 L21.225,9.475 L25.125,9.475 C25.825,9.475 26.325,10.075 26.325,10.775 C26.325,11.475 25.725,12.075 25.125,12.075 L18.225,12.075 C17.525,11.975 16.925,11.375 16.925,10.775 L16.925,3.875 C16.925,3.175 17.525,2.675 18.225,2.675 C18.925,2.675 19.525,3.275 19.525,3.875 L19.525,7.675 L19.425,7.675 Z M19.725,21.925 L19.725,25.825 C19.725,26.525 19.125,27.025 18.425,27.025 C17.725,27.025 17.125,26.425 17.125,25.825 L17.125,18.925 C17.125,18.225 17.725,17.725 18.325,17.725 L25.225,17.725 C25.925,17.725 26.425,18.325 26.425,19.025 C26.425,19.725 25.825,20.325 25.225,20.325 L21.325,20.325 L27.725,26.725 C28.225,27.225 28.225,28.025 27.725,28.525 C27.225,29.025 26.425,29.025 25.925,28.525 L19.725,21.925 Z"
              id="path-1_1_"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default exitFullscreenIcon
