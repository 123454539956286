// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { map } from 'lodash/fp'

import DefinitionList, {
  type DetailType,
} from 'react-ui/components/DefinitionList'
import createComponentId from 'shared/services/id'

import type { ConnectedTenantsList_tenants } from './__generated__/ConnectedTenantsList_tenants.graphql'

type PropsType = {
  componentId?: string,
  tenants: ConnectedTenantsList_tenants,
}

const defaultId = createComponentId(__filename)

const ConnectedTenantsListBase = (props: PropsType) => {
  const { componentId = defaultId, tenants } = props

  const details: Array<DetailType> = map(tenant => ({
    id: tenant.name,
    label: tenant.name,
    value: tenant.label,
  }))(tenants)

  return (
    <div className={componentId}>
      <DefinitionList details={details} />
    </div>
  )
}

export const ConnectedTenantsListLoader = createFragmentContainer(
  ConnectedTenantsListBase,
  {
    tenants: graphql`
      fragment ConnectedTenantsList_tenants on Tenant @relay(plural: true) {
        label
        name
      }
    `,
  },
)
