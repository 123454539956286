// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { type GraphQLTaggedNode } from 'relay-runtime'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'
import { Box, Button, FlexContainer, Modal, Text } from 'care-ui'

import useIndividualActionsModal from '../../hooks/useIndividualActionsModal'

type DischargeModalPropsType = {
  closeModal: () => void,
  isOpen: boolean,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: GraphQLTaggedNode,
  queryVariables: QueryVariablesType,
}

const DischargeModal = (props: DischargeModalPropsType) => {
  const {
    closeModal,
    mutationQuery,
    isOpen,
    multiSelectPayload,
    queryVariables,
  } = props

  const useIndividualActionsModalPayload = useIndividualActionsModal({
    closeModal,
    queryVariables,
    mutationQuery,
    multiSelectPayload,
  })
  const {
    allSelectedMinusDeselectedCount,
    selectedRoleIdsCount,
    onConfirmation,
  } = useIndividualActionsModalPayload

  const { t: translation } = useTranslation('staff')

  const formattedText = translation('individual', {
    count: selectedRoleIdsCount || allSelectedMinusDeselectedCount,
  })

  return (
    <Modal
      width="400px"
      isOpen={isOpen}
      closeModal={closeModal}
      heading={`${translation('discharge')} ${formattedText}`}
      dataTestId="DischargeModal"
    >
      <FlexContainer>
        <Text dataTestId="ModalBodyMainText">
          {translation('you_are_about_to_discharge')}&nbsp;
          {selectedRoleIdsCount || allSelectedMinusDeselectedCount}&nbsp;
          {formattedText}.{' '}
          {translation('please_note_all_individuals_will_be_discharged')}&nbsp;
          <Text as="span" dataTestId="ModalBodyWarningText" bold>
            {translation('including_those_with_unresolved_notifications')}
          </Text>
        </Text>

        <Box paddingX="sm">
          <Text dataTestId="ModalConfirmationText">
            {translation('are_you_sure_you_want_to_continue')}
          </Text>
        </Box>
      </FlexContainer>

      <FlexContainer paddingX="sm" justifyContent="space-between">
        <Button
          dataTestId="CloseModalButton"
          onClick={closeModal}
          size="md"
          type="button"
          variant="text"
        >
          {translation('no')}
        </Button>

        <Button
          onClick={() => onConfirmation()}
          size="md"
          type="button"
          variant="primary"
          dataTestId="ConfirmDischargeButton"
        >
          {translation('yes_discharge')}&nbsp;
          {formattedText}
        </Button>
      </FlexContainer>
    </Modal>
  )
}

export default DischargeModal
