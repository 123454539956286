// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query ClinicianNoteCardContainerQuery(
    $id: ID!
    $count: Int!
    $cursor: String
  ) {
    node(id: $id) {
      ... on User {
        ...ClinicianNoteCardContainer_user
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

export const paginationFragment = graphql`
  fragment ClinicianNoteCardContainer_user on User
    @relay(mask: false)
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 10 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "ClinicianNoteCardContainerPaginationQuery") {
    id
    clinician_notes(first: $count, after: $cursor)
      @connection(key: "ClinicianNoteCardContainer_clinician_notes") {
      edges {
        node {
          ...ClinicianNoteCard_clinician_note
        }
      }
    }
  }
`
