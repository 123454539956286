// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const FullscreenIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="fullscreenIcon"
        version="1.1"
        viewBox="0 0 28 28"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
      >
        <g id="INDIVIDUAL">
          <g
            id="_x36_.8.8-Exit_x2F_end-meeting"
            transform="translate(-905.000000, -931.000000)"
          >
            <g id="VIDEO-CALLs" transform="translate(0.000000, 896.000000)">
              <g
                id="Video-Controls-inactive"
                transform="translate(486.000000, 14.000000)"
              >
                <g
                  id="button-_x2F_-Fullscreen"
                  transform="translate(399.000000, 1.000000)"
                >
                  <g id="Page-1" transform="translate(20.000000, 20.000000)">
                    <g id="icon-_x2F_-28x28-_x2F_-full-screen">
                      <g id="Path">
                        <path
                          id="path-1_1_"
                          className="st0"
                          d="M2.5,4.3l6.4,6.4c0.5,0.5,1.3,0.5,1.8,0c0.5-0.5,0.5-1.3,0-1.8L4.3,2.5h3.9
                        c0.7,0,1.2-0.6,1.2-1.3C9.4,0.6,8.8,0,8.1,0H1.2C0.6,0,0,0.6,0,1.2v6.9c0,0.7,0.6,1.2,1.3,1.2c0.7,0,1.3-0.6,1.3-1.2V4.3z
                        M2.5,23.7l6.4-6.4c0.5-0.5,1.3-0.5,1.8,0c0.5,0.5,0.5,1.3,0,1.8l-6.4,6.4h3.9c0.7,0,1.2,0.6,1.2,1.3c0,0.7-0.6,1.3-1.2,1.3
                        H1.2C0.6,28,0,27.4,0,26.8v-6.9c0-0.7,0.6-1.2,1.3-1.2c0.7,0,1.3,0.6,1.3,1.2V23.7z M25.5,4.3l-6.4,6.4
                        c-0.5,0.5-1.3,0.5-1.8,0c-0.5-0.5-0.5-1.3,0-1.8l6.4-6.4h-3.9c-0.7,0-1.2-0.6-1.2-1.3c0-0.7,0.6-1.3,1.2-1.3h6.9
                        C27.4,0,28,0.6,28,1.2v6.9c0,0.7-0.6,1.2-1.3,1.2c-0.7,0-1.3-0.6-1.3-1.2V4.3z M25.5,23.7v-3.9c0-0.7,0.6-1.2,1.3-1.2
                        c0.7,0,1.3,0.6,1.3,1.2v6.9c0,0.7-0.6,1.2-1.2,1.2h-6.9c-0.7,0-1.2-0.6-1.2-1.3c0-0.7,0.6-1.3,1.2-1.3h3.9l-6.4-6.4
                        c-0.5-0.5-0.5-1.3,0-1.8c0.5-0.5,1.3-0.5,1.8,0L25.5,23.7z"
                        />
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default FullscreenIcon
