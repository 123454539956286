// @flow

import { graphql } from 'react-relay'

export const assessmentRequestsQuestionnaireAnswers = {
  answers: graphql`
    fragment AssessmentRequestsQuestionnaire_answers on AnswerInterface
      @relay(plural: true) {
      ...AssessmentRequestsAnswerTableBase_answers
    }
  `,
}
