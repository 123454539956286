/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardsCareOptions_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPage_viewer$ref: FragmentReference;
declare export opaque type ScoreCardsPage_viewer$fragmentType: ScoreCardsPage_viewer$ref;
export type ScoreCardsPage_viewer = {|
  +currentUser: ?{|
    +id: string
  |},
  +$fragmentRefs: ScoreCardsCareOptions_viewer$ref,
  +$refType: ScoreCardsPage_viewer$ref,
|};
export type ScoreCardsPage_viewer$data = ScoreCardsPage_viewer;
export type ScoreCardsPage_viewer$key = {
  +$data?: ScoreCardsPage_viewer$data,
  +$fragmentRefs: ScoreCardsPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardsCareOptions_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cc5fe833ccc87a991427e9b931aecac7';

module.exports = node;
