/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HelpNowModalBodyTenant_QueryVariables = {|
  id: string,
  skip: boolean,
|};
export type HelpNowModalBodyTenant_QueryResponse = {|
  +viewer?: {|
    +tenant: {|
      +emergencyNumber: string,
      +help_now_providers: $ReadOnlyArray<{|
        +chat_url: ?string,
        +description: ?string,
        +id: string,
        +slug: string,
        +logo_url: ?string,
        +phone_number: ?string,
        +sms: ?string,
        +title: string,
        +url: ?string,
      |}>,
    |}
  |}
|};
export type HelpNowModalBodyTenant_Query = {|
  variables: HelpNowModalBodyTenant_QueryVariables,
  response: HelpNowModalBodyTenant_QueryResponse,
|};
*/


/*
query HelpNowModalBodyTenant_Query(
  $id: ID!
  $skip: Boolean!
) {
  viewer @skip(if: $skip) {
    tenant(id: $id) {
      emergencyNumber
      help_now_providers {
        chat_url
        description
        id
        slug
        logo_url
        phone_number
        sms
        title
        url
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "skip"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emergencyNumber",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "HelpNowProvider",
  "kind": "LinkedField",
  "name": "help_now_providers",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "chat_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "logo_url",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "phone_number",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sms",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "HelpNowModalBodyTenant_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "HelpNowModalBodyTenant_Query",
    "selections": [
      {
        "condition": "skip",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v4/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "a72f80d7b87861b8b2a4ba3ec38cc1be",
    "id": null,
    "metadata": {},
    "name": "HelpNowModalBodyTenant_Query",
    "operationKind": "query",
    "text": "query HelpNowModalBodyTenant_Query(\n  $id: ID!\n  $skip: Boolean!\n) {\n  viewer @skip(if: $skip) {\n    tenant(id: $id) {\n      emergencyNumber\n      help_now_providers {\n        chat_url\n        description\n        id\n        slug\n        logo_url\n        phone_number\n        sms\n        title\n        url\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c42956a957beb561c5db14902dbe3746';

module.exports = node;
