// @flow

import React from 'react'
import { connect } from 'react-fela'
import { uniqueId } from 'lodash/fp'

import { type FelaPropsType } from 'react-ui/typing'

type Identity = {
  id: string,
}

type Clinicians = [
  Identity & {
    user: Identity & { name: string },
  },
]

type ArrayLike = {
  length: number,
}

const style = ({ data: { clinicians } }) => {
  const longList = clinicians.length > 3

  return {
    list: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: longList ? 'inherit' : 'center',
      textTransform: 'capitalize',
      alignSelf: longList ? 'flex-start' : 'center',
    },
  }
}

type dataType = {
  canProvideFeedback: boolean,
  clinicians: Clinicians,
  hasProvidedFeedback: boolean,
  questionnaireDueAt: string,
  questionnaireId: string,
  questionnaireLastAnswered: string,
  userId: string,
}

type CellProps = FelaPropsType & {
  data: dataType,
}

const Cell = ({ data: { clinicians }, styles: { list } }: CellProps) => {
  const clinicianNames = clinicians.map(({ user: { name } }) => name)

  const ClinicianNames = ({ names }) => {
    return names
      .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
      .map(name => <div key={uniqueId(name)}>{name}</div>)
  }

  const cliniciansNameList = (
    <div className={list}>
      <ClinicianNames names={clinicianNames} />
    </div>
  )

  return cliniciansNameList
}

const ConnectedCell = connect(style)(Cell)

export default {
  format: (data: dataType) => <ConnectedCell data={data} />,
  label: 'Clinicians',
  sort: ({ length: a }: ArrayLike, { length: b }: ArrayLike) => {
    if (a > b) {
      return -1
    }
    if (b > a) {
      return 1
    }
    return 0
  },
  width: '12rem',
}
