// @flow

import { type ThemeType } from 'care-ui/atoms'

export const styleRules = ({ theme }: { theme: ThemeType }) => {
  return {
    triggerLabel: {
      marginRight: theme.care.spacing.lg,
    },
  }
}
