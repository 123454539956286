// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import HelpNowButton from 'platform_web/features/Header/HelpNowButton'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType
const styleRules = () => ({
  actionsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
})

const AuthActions = ({ styles }: PropsType) => {
  return (
    <div className={styles.actionsWrapper}>
      <HelpNowButton />
    </div>
  )
}

export default connect(styleRules)(AuthActions)
