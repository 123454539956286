// @flow

import React from 'react'

import Heading from 'react-ui/components/Heading'
import { Button } from 'care-ui'

import { type BuilderPropsType } from '../QuestionnaireComplete'

export const Clinician = ({
  user,
  onSubmit,
  router,
  submitting,
  translation,
}: BuilderPropsType) => ({
  title: translation('thank_you'),
  cta: (
    <center>
      <p>{translation('click_submit_to_enter_your_clinical_input')}</p>

      <div>
        <Button
          disabled={submitting}
          onClick={onSubmit({
            onCompleted: () => {
              router.push({
                name: 'user_dashboard',
                params: {
                  id: user.id,
                },
              })
            },
          })}
          variant="secondary"
          ariaLabel={
            submitting
              ? `${translation('submitting')}...`
              : translation('submit')
          }
          dataTestId="submit"
        >
          {submitting
            ? `${translation('submitting')}...`
            : translation('submit')}
        </Button>
      </div>
    </center>
  ),
  text: (
    <>
      <div>
        <Heading level={3}>{translation('your_input')}</Heading>
        <small>
          {translation(
            'routinely_update_your_responses_to_keep_your_clinical_input_current_in_this_individuals_dashboard_it_will_help_you_monitor_progress_over_time_and_support_clinical_decision_making',
          )}
        </small>
      </div>

      <div>
        <Heading level={3}>{translation('view_input')}</Heading>
        <small>
          {translation(
            'your_answers_can_be_viewed_by_yourself_and_other_connected_innowell_clinicians',
          )}
        </small>
      </div>
    </>
  ),
})
