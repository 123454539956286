/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SummaryGraph_user$ref = any;
export type SummaryGraphSpecific_QueryVariables = {|
  id: string,
  start_time: string,
  end_time: string,
|};
export type SummaryGraphSpecific_QueryResponse = {|
  +user: {|
    +$fragmentRefs: SummaryGraph_user$ref
  |}
|};
export type SummaryGraphSpecific_Query = {|
  variables: SummaryGraphSpecific_QueryVariables,
  response: SummaryGraphSpecific_QueryResponse,
|};
*/


/*
query SummaryGraphSpecific_Query(
  $id: ID!
  $start_time: String!
  $end_time: String!
) {
  user(id: $id) {
    ...SummaryGraph_user
    id
  }
}

fragment SummaryGraph_user on User {
  ...TrackableDropdown_user
  id
  summary_data_set(start_time: $start_time, end_time: $end_time) {
    trackable {
      id
      label
    }
    answered_by {
      __typename
      ... on RoleInterface {
        __isRoleInterface: __typename
        role_type
      }
      ... on ClinicianRole {
        id
      }
      ... on IndividualRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
    points {
      date
      scaled_value
      raw_score
      formula_range {
        boundLower
        boundUpper
        label
        id
      }
      id
    }
  }
}

fragment TrackableDropdown_user on User {
  id
  summaryUserTrackables: user_trackables(kinds: [gauge]) {
    nodes {
      trackable {
        id
        label
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_time"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_time"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Trackable",
  "kind": "LinkedField",
  "name": "trackable",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    (v5/*: any*/)
  ],
  "storageKey": null
},
v7 = [
  (v4/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SummaryGraphSpecific_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "SummaryGraph_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SummaryGraphSpecific_Query",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": "summaryUserTrackables",
            "args": [
              {
                "kind": "Literal",
                "name": "kinds",
                "value": [
                  "gauge"
                ]
              }
            ],
            "concreteType": "UserTrackableConnection",
            "kind": "LinkedField",
            "name": "user_trackables",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserTrackable",
                "kind": "LinkedField",
                "name": "nodes",
                "plural": true,
                "selections": [
                  (v6/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": "user_trackables(kinds:[\"gauge\"])"
          },
          {
            "alias": null,
            "args": [
              {
                "kind": "Variable",
                "name": "end_time",
                "variableName": "end_time"
              },
              {
                "kind": "Variable",
                "name": "start_time",
                "variableName": "start_time"
              }
            ],
            "concreteType": "SummaryDataSetType",
            "kind": "LinkedField",
            "name": "summary_data_set",
            "plural": true,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "answered_by",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role_type",
                        "storageKey": null
                      }
                    ],
                    "type": "RoleInterface",
                    "abstractKey": "__isRoleInterface"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "ClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v7/*: any*/),
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserTrackableData",
                "kind": "LinkedField",
                "name": "points",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "date",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "scaled_value",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "raw_score",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FormulaRange",
                    "kind": "LinkedField",
                    "name": "formula_range",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "boundLower",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "boundUpper",
                        "storageKey": null
                      },
                      (v5/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v4/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e2d39bdc526d4a34a906e0c2ebde35cb",
    "id": null,
    "metadata": {},
    "name": "SummaryGraphSpecific_Query",
    "operationKind": "query",
    "text": "query SummaryGraphSpecific_Query(\n  $id: ID!\n  $start_time: String!\n  $end_time: String!\n) {\n  user(id: $id) {\n    ...SummaryGraph_user\n    id\n  }\n}\n\nfragment SummaryGraph_user on User {\n  ...TrackableDropdown_user\n  id\n  summary_data_set(start_time: $start_time, end_time: $end_time) {\n    trackable {\n      id\n      label\n    }\n    answered_by {\n      __typename\n      ... on RoleInterface {\n        __isRoleInterface: __typename\n        role_type\n      }\n      ... on ClinicianRole {\n        id\n      }\n      ... on IndividualRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n    points {\n      date\n      scaled_value\n      raw_score\n      formula_range {\n        boundLower\n        boundUpper\n        label\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment TrackableDropdown_user on User {\n  id\n  summaryUserTrackables: user_trackables(kinds: [gauge]) {\n    nodes {\n      trackable {\n        id\n        label\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f970825f5b906ac46d02bd0351674e7b';

module.exports = node;
