// @flow

import React, { type Node, useState } from 'react'
import { useFela } from 'react-fela'

import { Button, Heading, Text } from 'care-ui'

import s from './FieldUpdater.scss'

import { type stateUpdater } from 'react-ui/typing'

type PropsType = {
  description?: Node | string,
  editComponent: ({
    setValid: stateUpdater<boolean>,
    setWaiting: stateUpdater<boolean>,
  }) => Node,
  handleSubmit: (event: SyntheticEvent<HTMLFormElement>) => void,
  submit: string,
  title: string,
}

const formStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const FieldUpdater = ({
  title,
  description,
  handleSubmit,
  editComponent,
  submit,
}: PropsType) => {
  const [valid, setValid] = useState(true)
  const [waiting, setWaiting] = useState(false)
  const { css } = useFela()
  return (
    <div className={s.container}>
      <div className={s.buttonWrapper}>
        <Button
          variant="secondary"
          componentId="back_button"
          to="settings_personal_details"
          ariaLabel="Back"
          dataTestId="back"
        >
          Back
        </Button>
      </div>
      <Heading level={3} margin="xs">
        {title}
      </Heading>
      {description && (
        <Text size="lg" margin="xs">
          {description}
        </Text>
      )}
      <form onSubmit={handleSubmit} className={css(formStyle)}>
        {editComponent({ setValid, setWaiting })}
        <Button
          componentId="submit_button"
          disabled={!valid || waiting}
          type="submit"
          variant="primary"
          ariaLabel={submit}
          dataTestId="submit"
        >
          {submit}
        </Button>
      </form>
    </div>
  )
}

export default FieldUpdater
