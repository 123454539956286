// @flow

import * as React from 'react'
import Redirect from 'found/Redirect'
import Route from 'found/Route'

import {
  IndividualsAdminInvitePageLoader,
  IndividualsAdminInvitePageQuery,
} from 'pages/Individual/IndividualsAdminInvitePage'
import {
  TenantAdminPageLoader,
  TenantAdminPageQuery,
} from 'pages/TenantAdminPage'
import { loadingRender } from 'services/routeConfig/services'

const routes = (
  <Route>
    <Route path="/tenant_groups">
      <Redirect from="*" to="/staff/managers" />
    </Route>
    <Route path="/tenants">
      <Route path="/:id">
        <Route
          Component={TenantAdminPageLoader}
          query={TenantAdminPageQuery}
          name="tenant"
          render={loadingRender({ message: 'Tenant' })}
        />
      </Route>
      <Redirect from="*" to="/staff/employees" />
    </Route>
    <Route path="/individuals">
      <Redirect from="/" to="/staff/individuals" />
      <Route
        Component={IndividualsAdminInvitePageLoader}
        name="individualsInvite"
        path="/invite"
        query={IndividualsAdminInvitePageQuery}
        render={loadingRender({ message: 'Individuals Invite' })}
      />
    </Route>
  </Route>
)

export default routes
