// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { withFormik } from 'formik'
import * as yup from 'yup'

import { Form } from 'shared/ui/Forms'
import { EnterPassword, Input } from 'shared/ui/Forms/Fields'
import { Button } from 'care-ui'

import s from './PasswordForms.scss'

import type { FormikProps } from 'services/flow'

export type EditRegistrationFormValues = {
  current_password: string,
  password: string,
  password_confirmation: string,
}

type EditRegistrationFormPropsType = FormikProps<EditRegistrationFormValues> & {
  onSubmit: (e: Event) => void,
  status: {
    error?: string,
  },
  translation: TFunction,
  values: EditRegistrationFormValues,
}

const EditRegistrationForm = (props: EditRegistrationFormPropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    status = {},
    touched,
    values,
    translation,
  } = props

  return (
    <Form onSubmit={handleSubmit}>
      {status.error && <p className={s.mainError}>{status.error}</p>}

      <Input
        errors={errors}
        fullWidth
        label={translation('current_password')}
        name="current_password"
        onBlur={handleBlur}
        onChange={handleChange}
        placeholder={translation('enter_old_password')}
        required
        touched={touched}
        type="password"
        value={values.current_password}
        style={{ maxWidth: '25rem' }}
      />

      <EnterPassword
        errors={errors}
        fullWidth
        hasConfirm
        lightGrayLabel
        onBlur={handleBlur}
        onChange={handleChange}
        touched={touched}
        userPasswordConfirm={values.password_confirmation}
        value={values.password}
      />

      <p>
        <Button variant="primary" type="submit">
          {translation('save')}
        </Button>
      </p>
    </Form>
  )
}

const FormikEnhancedEditRegistrationForm = withFormik({
  // Transform outer props into form values
  mapPropsToValues: () => ({
    current_password: '',
    password: '',
    password_confirmation: '',
  }),

  // Submission handler
  handleSubmit: (values, { props, setSubmitting, setStatus }) =>
    props.onSubmit(values, setSubmitting, setStatus),

  validationSchema: props => {
    const { translation } = props

    return yup.object().shape({
      current_password: yup
        .string()
        .label('current_password')
        .required(translation('current_password_is_required')),
      password: yup
        .string()
        .label('password')
        .min(6, translation('password_must_be_at_least_6_characters'))
        .required(translation('password_is_required')),
      password_confirmation: yup
        .string()
        .label('password_confirmation')
        .oneOf(
          [yup.ref('password'), null],
          translation('new_passwords_must_match'),
        )
        .required(translation('password_confirmation_is_required')),
    })
  },
})(EditRegistrationForm)

const EditRegistrationFormWithTranslation = (props: any) => {
  const { t: translation } = useTranslation('auth')

  return (
    <FormikEnhancedEditRegistrationForm {...props} translation={translation} />
  )
}

export default EditRegistrationFormWithTranslation
