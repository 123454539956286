// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const accordionItemStyle = (
  theme: ThemeType,
  isOpen: boolean,
  lg: boolean,
) => ({
  AccordionItem: {
    marginTop: '1.6rem',
    marginBottom: '0',
    backgroundColor: 'white',
    width: lg ? '100%' : 'fit-content',
  },
  header: {
    backgroundColor: theme.palette.dividerColor,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    ...theme.care.typography.desktop.bodyLg,
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.dividerColor,
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    borderRadius: '0 0 4px 4px',
  },
})

export const headerStyle = () => ({
  display: 'flex',
  padding: '0 4rem 0 2rem',
  position: 'relative',

  '& h4': {
    ':first-child': {
      width: '23rem',
    },
    ':nth-child(2)': {
      width: '13rem',
    },
    ':nth-child(3)': {
      width: '12.3rem',
    },
    ':nth-child(4)': {
      width: '14.5rem',
    },
    ':last-child': {
      width: 'max-content',
    },
  },
})

export const wrapperStyle = () => ({
  overflowX: 'auto',
  width: '100%',
})

export const resultCountContainerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    backgroundColor: 'white',
    padding: '1rem 2rem',
    display: 'flex',
    color: theme.palette.subTextColor,
    '& > div': {
      marginRight: '0.5rem',
    },
    '& button': {
      backgroundColor: 'transparent',
      border: 0,
      cursor: 'pointer',
      padding: 0,
      color: theme.palette.link.linkColor,
      marginLeft: '0.5rem',
    },
  }
}
