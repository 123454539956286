/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserCheckBoxLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualCliniciansList_tenant$ref: FragmentReference;
declare export opaque type IndividualCliniciansList_tenant$fragmentType: IndividualCliniciansList_tenant$ref;
export type IndividualCliniciansList_tenant = {|
  +assignable_clinicians: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +email: string,
        +id: string,
        +assigned_to_individual: boolean,
        +user: {|
          +id: string,
          +name: string,
          +$fragmentRefs: UserCheckBoxLoader_user$ref,
        |},
      |}
    |}>
  |},
  +$refType: IndividualCliniciansList_tenant$ref,
|};
export type IndividualCliniciansList_tenant$data = IndividualCliniciansList_tenant;
export type IndividualCliniciansList_tenant$key = {
  +$data?: IndividualCliniciansList_tenant$data,
  +$fragmentRefs: IndividualCliniciansList_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "individual_id"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "assignable_clinicians"
        ]
      }
    ]
  },
  "name": "IndividualCliniciansList_tenant",
  "selections": [
    {
      "alias": "assignable_clinicians",
      "args": null,
      "concreteType": "ClinicianRoleConnection",
      "kind": "LinkedField",
      "name": "__ClinicianAssignmentsPopover_assignable_clinicians_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClinicianRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "email",
                  "storageKey": null
                },
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "individual_id",
                      "variableName": "individual_id"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "assigned_to_individual",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "name",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserCheckBoxLoader_user"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '0f8dfea368cc646378359e1bccc33306';

module.exports = node;
