// @flow

import React, { type Node } from 'react'
import cx from 'classnames'
import Link from 'found/Link'

import ToggleButton from 'react-ui/components/Form/ToggleButton'
import Can from 'react-ui/utils/Can'
import { exactlyNoneOrOne } from 'shared/services/exactlyOne'
import { Chevron, Lock } from 'shared/ui/Typography/Icons'
import { getLanguageFromLocale } from 'shared/utils/Internationalization'
import { Text } from 'care-ui'

/**
 * TODO: Ideally this component should be converted to use Fela.
 * Unfortunately the multiple render functions make passing down compiled Fela
 * styles quite difficult, unless we make each different type of row item a new
 * Fela-connected component. This would introduce many more Fela context consumers
 * and possibly have a detrimental effect on rendering performance.
 * So we're leaving it Sass-based for now.
 */
import s from './DefinitionList.scss'

export type DetailType = {
  display?: Node,
  hideItem?: ?boolean,
  id: string,
  label: ?string,
  placeholder?: ?string,
  to?: {
    name: string,
    params?: { [string]: any },
    requiredPolicy: string,
  },
  toggle?: {
    disable?: boolean,
    onChange: (event: SyntheticEvent<HTMLInputElement>) => void,
    onToggleModal?: Node,
  },
  value?: ?string | ?number | ?boolean,
  warning?: string,
}

type PropsType = {
  +details: $ReadOnlyArray<DetailType>,
}

const plainText = detail => <dd>{detail.value || detail.display}</dd>

const withLink = detail => {
  const detailValue =
    detail.id === 'preferred_language'
      ? getLanguageFromLocale(String(detail.value))
      : detail.value

  return (
    <Can>
      {policies =>
        detail.to && policies[detail.to.requiredPolicy] ? (
          <Link to={detail.to} role="link">
            <dd id={detail.id}>
              {detail.value ? (
                detailValue
              ) : (
                <span className={s.placeholder}>{detail.placeholder} </span>
              )}
              <Chevron
                addedClassName={s.caret}
                size="m"
                color="secondary"
                rotation="left"
              />
              {detail.warning && (
                <span className={s.warning}>{detail.warning}</span>
              )}
            </dd>
          </Link>
        ) : (
          plainText(detail)
        )
      }
    </Can>
  )
}

const withToggle = detail => {
  if (!detail.toggle) return null
  return (
    <dd>
      <span
        className={s.toggle}
        data-component-id="DefinitionList_ToggleButton"
      >
        {detail.toggle.disable ? (
          <Lock addedClassName={s.lockIcon} />
        ) : (
          <ToggleButton
            id={`${detail.id}_toggle`}
            checked={detail.value}
            onChange={detail.toggle.onChange}
          />
        )}
        {detail.toggle.onToggleModal ? detail.toggle.onToggleModal : null}
      </span>
    </dd>
  )
}

const displayDetail = (detail: DetailType) => {
  const { to, toggle } = detail
  if (to) return withLink(detail)
  if (toggle) return withToggle(detail)
  return plainText(detail)
}

const renderRow = (props, detail) => {
  const { hideItem, id, label, display, to, toggle } = detail

  if (!exactlyNoneOrOne(to, toggle, display)) {
    throw new Error(
      'DefinitionList: An item can only contain one of "to" or "toggle" or "display" props.',
    )
  }

  if (hideItem) {
    return null
  }

  return (
    <div
      key={id}
      className={cx({ [s.row]: true, [s.display]: detail.display })}
    >
      <Text as="dt" size="lg" bold>
        {label}
      </Text>
      <Text size="lg" className={s.dd_wrapper}>
        {displayDetail(detail)}
      </Text>
    </div>
  )
}

const DefinitionList = (props: PropsType) => {
  const { details } = props

  return (
    <dl className={s.details}>
      {details.map(detail => renderRow(props, detail))}
    </dl>
  )
}

export default DefinitionList
