// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'

import Card from 'react-ui/components/Card/Card'
import Markdown from 'react-ui/components/Markdown'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { commit as commitUserResourceVisitLink } from 'mutations/UserResourceVisitLink'
import { Button, Heading, Text } from 'care-ui'

import {
  buttonAreaStyle,
  buttonContainerStyle,
  careOptionTextStyle,
  contentAreaStyle,
  statusContainerStyle,
  titleStyle,
  wrapperStyle,
} from './CareOption.style'

import type { CareOption_resource } from './__generated__/CareOption_resource.graphql'

type PropsType = {
  actions?: ?Node,
  className?: string,
  hideExternalLink?: boolean,
  linkLabel?: string,
  resource: CareOption_resource,
  status?: Node,
}

const CareOptionBase = ({
  actions,
  status,
  resource,
  hideExternalLink = false,
  linkLabel,
}: PropsType) => {
  const { t: translation } = useTranslation('scoreCard')
  const translatedLinkLabel = linkLabel || translation('visit')
  const { css } = useFela()
  const { id: user_id } = useCurrentUser()
  const [isIndividual] = useSelector(({ currentUser }) => {
    const { roleType } = currentUser || {}
    return [roleType === 'INDIVIDUAL']
  })
  const environment = useRelayEnvironment()

  const { id: resource_id, label: title, description, url: link } =
    resource || {}

  const handleButtonClick = () => {
    if (!isIndividual) return
    commitUserResourceVisitLink({
      environment,
      variables: {
        input: {
          resource_id,
          user_id,
        },
      },
    })
  }

  const ButtonAction = () => (
    <Button
      variant="secondary"
      href={link || ''}
      onClick={handleButtonClick}
      target="_blank"
      ariaLabel={`${translatedLinkLabel} Link`}
      dataTestId="link"
    >
      {linkLabel} {translation('link')}
    </Button>
  )

  return (
    <Card
      justifyContent="space-between"
      minHeight="20rem"
      padding="1rem 1.5rem"
    >
      <div className={css(wrapperStyle)}>
        <div className={css(contentAreaStyle)}>
          <Heading level={3} extend={titleStyle}>
            {title}
          </Heading>
          {description && (
            <Text>
              <Markdown
                source={description}
                className={css(careOptionTextStyle)}
              />
            </Text>
          )}
        </div>
      </div>
      <div className={css(buttonContainerStyle)}>
        <div className={css(buttonAreaStyle)}>
          {link && !hideExternalLink && <ButtonAction />}
          {actions}
        </div>
        {status && <div className={css(statusContainerStyle)}>{status}</div>}
      </div>
    </Card>
  )
}

const CareOptionLoader = createFragmentContainer(CareOptionBase, {
  resource: graphql`
    fragment CareOption_resource on Resource {
      description
      id
      label
      url
    }
  `,
})

export default CareOptionLoader
