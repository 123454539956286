// @flow

const determineInitialValue = (initialValue) => {
  if (!initialValue) return ''

  return initialValue
}

export const getFormIntialValues = (formFields: Array<{ [key: string] : string | any }>) => {
  const initialValues = {}

  formFields.forEach(formField => {
    const { id, initialValue } = formField

    initialValues[id] = determineInitialValue(initialValue)
  })

  return initialValues
}