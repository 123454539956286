// @flow

import React, { Fragment } from 'react'
import { connect } from 'react-fela'

import Step, { type StepType } from './Step'

import type { FelaPropsType } from 'react-ui/typing'

type stepStateType = {
  activeIndex: number,
  isCompleted: boolean,
}

type PropsType = FelaPropsType & {
  activeStep: stepStateType,
  steps: [StepType],
}
const styleRules = ({ theme }) => ({
  stepsWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '260px',
    marginTop: '-30px',
    marginBottom: '20px',
    width: '100%',
    [theme.breakpoints.queries.md]: {
      padding: '20px',
    },
  },
  stepContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    zIndex: 1,
  },
  line: {
    display: 'flex',
    alignSelf: 'center',
    width: '100%',
    height: '10px',
    flex: '1 1 100%',
    background: '#08B2CC',
    opacity: '0.25',
  },
})

const Steps = ({ styles, steps, activeStep }: PropsType) => {
  let stepActiveIndex
  if (activeStep) {
    stepActiveIndex = activeStep.activeIndex
  }
  return (
    <div className={styles.stepsWrapper}>
      {steps.map((step, index) => {
        const isActive = stepActiveIndex > index
        const isLastStep = index === steps.length - 1
        const stepKey = `index-${index}`
        return (
          <Fragment key={stepKey}>
            <Step step={step} isActive={isActive} activeStep={activeStep} />
            {!isLastStep && <div className={styles.line} />}
          </Fragment>
        )
      })}
    </div>
  )
}

export default connect(styleRules)(Steps)
