// @flow

import { type ThemeType } from 'care-ui/atoms'

export const graphStyle = ({ theme }: { theme: ThemeType }) => ({
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
  padding: '0',
  marginTop: '2rem',
  justifyContent: 'center',
  [theme.breakpoints.queries.md]: {
    width: '70%',
    paddingLeft: '2rem',
    marginTop: 0,
    justifyContent: 'flex-start',
  },
})
