// @flow

import React, { type ComponentType, type Node, Fragment } from 'react'
import { connect } from 'react-redux'
import { every, getOr, isEmpty } from 'lodash/fp'

type PropsType = {
  children: Node,
  requiredPolicies: Array<string>,
}

type StatePropsType = PropsType & {
  policyStates: Object,
}

const mapStateToProps = (state): * => ({
  policyStates: state.policies,
})

export const WithAuthBase = (props: StatePropsType) => {
  const { children, policyStates, requiredPolicies } = props

  const isCompliant = () =>
    every(policy => getOr(false, policy, policyStates) === true)(
      requiredPolicies,
    )

  return (
    <Fragment>{!isEmpty(policyStates) && isCompliant() && children}</Fragment>
  )
}

const withAuth: ComponentType<PropsType> = connect(mapStateToProps)(
  WithAuthBase,
)
export default withAuth
