// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import cx from 'classnames'
import useRouter from 'found/useRouter'
import { omit } from 'lodash/fp'

import Themelet, { type VariantType } from 'react-ui/components/Themelet'
import { felaProps } from 'shared/services/fela'

import { type FelaPropsType } from 'react-ui/typing'

const styleRules = ({
  center,
  color = 'default',
  short,
  theme,
  noPadding,
  useDefaultStyle,
}) => {
  const { backgroundColor } = theme.palette.section[color]

  const vPadding = short ? theme.spacing(1) : theme.spacing(2)

  const padding = {
    paddingBottom: noPadding ? 0 : vPadding,
    paddingLeft: noPadding ? 0 : theme.Grid.gutter,
    paddingRight: noPadding ? 0 : theme.Grid.gutter,
    paddingTop: 0,
  }

  return {
    Section: {
      backgroundColor,
      boxSizing: 'border-box',
      className: cx('Section', `--${color}`).replace(/\s/g, ''),
      display: 'block',
      position: 'relative',
      textAlign: center ? 'center' : 'left',
      width: '100%',
      height: '100%',
      ...padding,
      [theme.breakpoints.queries.xs]: {
        paddingLeft: noPadding ? '0' : theme.Grid.gutter,
      },
    },
    arrow: {
      borderBottomStyle: 'none',
      borderColor: 'transparent',
      borderStyle: 'solid',
      borderTopColor: backgroundColor,
      borderWidth: '24px',
      display: 'inline-block',
      pointerEvents: 'none',
      width: 'auto',
    },
    SectionWrapperDefault: {
      backgroundColor: useDefaultStyle ? backgroundColor : 'none',
    },
    buttonWrapper: {
      position: 'absolute',
      top: '30px',
      '@media (min-width: 1251px)': {
        right: `calc((100vw - ${theme.Grid.containerMaxWidth}px)/2)`,
        marginRight: theme.Grid.gutter,
      },
      '@media (max-width: 1250px)': {
        marginRight: theme.Grid.gutter,
        right: theme.Grid.gutter,
        paddingRight: `calc(${theme.Grid.gutter}/2)`,
      },
    },
    staffButtonWrapper: {
      position: 'absolute',
      top: '30px',
      '@media (min-width: 1491px)': {
        right: `calc((100vw - 1440px)/2)`,
        marginRight: theme.Grid.gutter,
      },
      '@media (max-width: 1490px)': {
        marginRight: theme.Grid.gutter,
        right: theme.Grid.gutter,
        paddingRight: `calc(${theme.Grid.gutter}/2)`,
      },
    },
  }
}

type SectionProps = FelaPropsType & {
  as: string,
  button?: any,
  center?: boolean,
  children: React.Node,
  className?: string,
  color?: VariantType,
  noPadding?: boolean,
  short?: boolean,
  useDefaultStyle?: boolean,
}

const Section = ({
  as: Component = 'section',
  children,
  className = '',
  color = 'default',
  rules,
  styles,
  button,
  useDefaultStyle: _useDefaultStyle,
  ...props
}: SectionProps) => {
  const { routes } = useRouter().match
  const staffPage = routes ? routes[2].path === '/staff' : false
  const buttonWrapperStyle = staffPage
    ? styles.staffButtonWrapper
    : styles.buttonWrapper

  return (
    <div className={styles.SectionWrapperDefault}>
      <Component
        {...omit(
          ['center', 'color', 'short', 'noPadding', ...felaProps],
          props,
        )}
        className={className}
      >
        <Themelet variant={color} extend={rules.Section}>
          <React.Fragment>
            <div className={buttonWrapperStyle}>{button}</div>
            {children}
          </React.Fragment>
        </Themelet>
      </Component>
    </div>
  )
}

export default connect(styleRules)(Section)
