// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const AccessibilityIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 21 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs />
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M7.7466789,8.11557699 C7.73991622,8.09714056 7.73368095,8.07844963 7.72799137,8.05952248 L6.96863042,5.78176891 C6.79395992,5.25783317 7.07709535,4.69150091 7.60103109,4.51683042 C8.12496683,4.34215992 8.69129909,4.62529535 8.86596958,5.14923109 L9.40670405,6.7712 L15.9868,6.7712 C16.5390847,6.7712 16.9868,7.21891525 16.9868,7.7712 C16.9868,8.32348475 16.5390847,8.7712 15.9868,8.7712 L10.0734671,8.7712 L10.9440263,11.3825 L17.1403,11.3825 C17.5707623,11.3825 17.952922,11.6579703 18.0890107,12.0663545 L20.3940107,18.9833545 C20.568613,19.507313 20.2854039,20.0736084 19.7614455,20.2482107 C19.237487,20.422813 18.6711916,20.1396039 18.4965893,19.6156455 L16.4194749,13.3825 L10.2233,13.3825 C9.79288556,13.3825 9.41075713,13.10709 9.27463042,12.6987689 L7.74667904,8.11557736 Z M13.5010096,18.0460849 C13.6838794,17.5249543 14.2545845,17.2507398 14.7757151,17.4336096 C15.2968457,17.6164794 15.5710602,18.1871845 15.3881904,18.7083151 C14.2847536,21.8528181 11.3047,24.0002 7.9176,24.0002 C3.54449224,24.0002 -0.00039999999,20.4556617 -0.00039999999,16.0832 C-0.00039999999,13.2122785 1.54274034,10.6096686 3.98992268,9.20926472 C4.4692701,8.93495742 5.08022797,9.10117527 5.35453528,9.58052268 C5.62884258,10.0598701 5.46262473,10.670828 4.98327732,10.9451353 C3.15233676,11.9928939 1.9996,13.9370618 1.9996,16.0832 C1.9996,19.3510475 4.64901703,22.0002 7.9176,22.0002 C10.4491206,22.0002 12.6767515,20.3950026 13.5010096,18.0460849 Z M8.7708,2.007 C8.7708,3.11509327 7.87227658,4.013 6.7648,4.013 C5.65663137,4.013 4.7578,3.1154016 4.7578,2.007 C4.7578,0.89871525 5.65651525,0 6.7648,0 C7.87239306,0 8.7708,0.899023552 8.7708,2.007 Z" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default AccessibilityIcon
