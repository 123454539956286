// @flow

import React from 'react'
import merge from 'lodash/fp/merge'

import { HeaderCellRenderer } from 'react-ui/components/DataGrid'

type PropsType = {
  extend: () => Object,
}

const SegmentationTableHeaderCellRenderer = ({
  extend = () => ({}),
  ...props
}: PropsType) => {
  const enhancedExtend = (...args) => {
    return merge(
      {
        className: 'SegmentationTableHeaderCellRenderer',
        textAlign: 'right',
        ':after': {
          borderColor: '#D0D6DE',
          left: '0 !important',
          right: '0 !important',
        },
      },
      extend(...args),
    )
  }

  return <HeaderCellRenderer extend={enhancedExtend} {...(props: any)} />
}

export default SegmentationTableHeaderCellRenderer
