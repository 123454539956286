// @flow

import { graphql } from 'react-relay'

export const assignmentFragment = graphql`
  fragment SupportingSupporteeInfoWrapper_individual_assignments on SupportPersonAssignment
    @relay(plural: true) {
    id
    individual {
      id
      legal_name
      tenant {
        support_person_help
      }
    }
    ...SupportingSupporteeRemoveModal_individual_assignments
  }
`
