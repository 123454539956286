// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const SafetyIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 20 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M10.3142018,23.9495562 C10.1101758,24.0170813 9.88982416,24.0170813 9.68579825,23.9495562 C4.90789542,22.3682474 9.77706804e-12,18.248971 9.77706804e-12,14.3912 L9.77706804e-12,3.8692 C9.77706804e-12,3.43392182 0.281564454,3.04864077 0.69628078,2.91643842 L9.69628078,0.0474384164 C9.89386217,-0.0155461388 10.1061378,-0.0155461388 10.3037192,0.0474384164 L19.3037192,2.91643842 C19.7184355,3.04864077 20,3.43392182 20,3.8692 L20,14.3912 C20,18.248971 15.0921046,22.3682474 10.3142018,23.9495562 Z M18,14.3912 L18,4.60000274 L10,2.04978052 L2,4.60000274 L2,14.3912 C2,17.0594837 6.0487892,20.52666 10,21.942536 C13.9512108,20.52666 18,17.0594837 18,14.3912 Z M10,6.04998052 L1.30371922,8.82216158 C0.777523528,8.98990108 0.21497791,8.69931491 0.0472384164,8.17311922 C-0.120501077,7.64692353 0.170085087,7.08437791 0.69628078,6.91663842 L9.69628078,4.04763842 C9.89386217,3.98465386 10.1061378,3.98465386 10.3037192,4.04763842 L19.3037192,6.91663842 C19.8299149,7.08437791 20.1205011,7.64692353 19.9527616,8.17311922 C19.7850221,8.69931491 19.2224765,8.98990108 18.6962808,8.82216158 L10,6.04998052 Z M1.30371922,12.8221616 C0.777523528,12.9899011 0.21497791,12.6993149 0.0472384164,12.1731192 C-0.120501077,11.6469235 0.170085087,11.0843779 0.69628078,10.9166384 L9.69628078,8.04763842 C9.89386217,7.98465386 10.1061378,7.98465386 10.3037192,8.04763842 L19.3037192,10.9166384 C19.8299149,11.0843779 20.1205011,11.6469235 19.9527616,12.1731192 C19.7850221,12.6993149 19.2224765,12.9899011 18.6962808,12.8221616 L10,10.0499805 L1.30371922,12.8221616 Z" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default SafetyIcon
