// @flow

import { graphql } from 'react-relay'

export const chartHeaderQuery = {
  individual: graphql`
    fragment ChartHeader_individual on IndividualRole {
      ...CommonTasksButtons_individual
    }
  `,
  user: graphql`
    fragment ChartHeader_user on User {
      ...CommonTasksButtons_user
    }
  `,
}
