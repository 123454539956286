// @flow

import React from 'react'

import { TextCell } from 'react-ui/components/DataGrid'
import { dateTimeFormat } from 'services/dateTime'
import { sortByDate } from 'shared/services/sorters'

export default (inTextCell: boolean) => ({
  format: inTextCell
    ? (date: Date) => <TextCell>{dateTimeFormat(date)}</TextCell>
    : (date: Date) => dateTimeFormat(date),
  label: 'Time',
  sort: sortByDate,
  width: '120px',
})
