/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnaireQuestionSetFooter_userQuestionnaire$ref: FragmentReference;
declare export opaque type UserQuestionnaireQuestionSetFooter_userQuestionnaire$fragmentType: UserQuestionnaireQuestionSetFooter_userQuestionnaire$ref;
export type UserQuestionnaireQuestionSetFooter_userQuestionnaire = {|
  +thank_you_page_relevant: boolean,
  +allQuestionSets: $ReadOnlyArray<{|
    +question_set: {|
      +id: string
    |}
  |}>,
  +skippedQuestionSets: $ReadOnlyArray<{|
    +question_set: {|
      +id: string
    |}
  |}>,
  +$refType: UserQuestionnaireQuestionSetFooter_userQuestionnaire$ref,
|};
export type UserQuestionnaireQuestionSetFooter_userQuestionnaire$data = UserQuestionnaireQuestionSetFooter_userQuestionnaire;
export type UserQuestionnaireQuestionSetFooter_userQuestionnaire$key = {
  +$data?: UserQuestionnaireQuestionSetFooter_userQuestionnaire$data,
  +$fragmentRefs: UserQuestionnaireQuestionSetFooter_userQuestionnaire$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "QuestionSet",
    "kind": "LinkedField",
    "name": "question_set",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnaireQuestionSetFooter_userQuestionnaire",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thank_you_page_relevant",
      "storageKey": null
    },
    {
      "alias": "allQuestionSets",
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "user_questionnaires_question_sets",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": "skippedQuestionSets",
      "args": [
        {
          "kind": "Literal",
          "name": "scope",
          "value": "skipped"
        }
      ],
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "user_questionnaires_question_sets",
      "plural": true,
      "selections": (v0/*: any*/),
      "storageKey": "user_questionnaires_question_sets(scope:\"skipped\")"
    }
  ],
  "type": "UserQuestionnaire",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb592d0fa5f7311773301e983f402a44';

module.exports = node;
