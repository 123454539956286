// @flow

import { graphql } from 'react-relay'

const ResendRolesMutation = graphql`
  mutation ResendRolesMutation($input: RolesReinviteInput!) {
    rolesReinvite(input: $input) {
      success
      active_individuals_in_another_tenant
    }
  }
`

export default ResendRolesMutation
