// @flow

import { useSelector } from 'react-redux'

export function useCurrentUserRoles() {
  const currentUser = useSelector(state => state.currentUser)
  const { roles, roleType, tenant, ownerTenants } = currentUser

  return {
    roleType,
    currentUser,
    tenant,
    tenantCreatedDate: tenant?.created_at,
    ownerTenants,
    currentUserRoles: roles,
    isMultiRolesEnabled: tenant?.multi_roles_enabled,
    hasClinicianRole: roles?.includes('CLINICIAN'),
    hasLeadClinicianRole: roles?.includes('LEAD_CLINICIAN'),
    hasManagerRole: roles?.includes('MANAGER'),
    hasAdminRole: roles?.includes('ADMIN'),
    hasIndividualRole: roles?.includes('INDIVIDUAL'),
    hasSupportPersonRole: roles?.includes('SUPPORT_PERSON'),
    hasOwnerRole: roles?.includes('OWNER'),
  }
}
