// @flow

import { commitMutation, graphql } from 'react-relay'

import { prepareUpdatePayload } from 'services/mutations'

import type {
  NotificationUpdateMutationResponse,
  NotificationUpdateMutationVariables,
} from './__generated__/NotificationUpdateMutation.graphql'

const mutation = graphql`
  mutation NotificationUpdateMutation($input: NotificationUpdateInput!) {
    notificationUpdate(input: $input) {
      user {
        id
        ...NotificationDisplay_user
      }
    }
  }
`

type CommitPropsType = {
  environment: Object,
  onCompleted?: (result: NotificationUpdateMutationResponse) => void,
  onError?: (result: Object) => void,
  user: {
    +notifications: $ReadOnlyArray<Object>,
  },
  variables: NotificationUpdateMutationVariables,
}

export const commit = (props: CommitPropsType) => {
  const { environment, onCompleted, onError, variables, user } = props

  const { input } = variables

  const optimisticNotifications = user.notifications.filter(
    (notification: { id: string }) => notification.id !== input.id,
  )

  const optimisticResponse = {
    notificationUpdate: {
      user: {
        ...user,
        notifications: optimisticNotifications,
      },
    },
  }

  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    optimisticResponse,
    variables: {
      input: prepareUpdatePayload('notification', input),
    },
  })
}
