/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssignableIndividualsPagination_tenant$ref: FragmentReference;
declare export opaque type AssignableIndividualsPagination_tenant$fragmentType: AssignableIndividualsPagination_tenant$ref;
export type AssignableIndividualsPagination_tenant = {|
  +tenant: {|
    +multi_select_assignable_individuals: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: string,
          +id: string,
          +user: {|
            +id: string,
            +name: string,
          |},
        |}
      |}>
    |}
  |},
  +$refType: AssignableIndividualsPagination_tenant$ref,
|};
export type AssignableIndividualsPagination_tenant$data = AssignableIndividualsPagination_tenant;
export type AssignableIndividualsPagination_tenant$key = {
  +$data?: AssignableIndividualsPagination_tenant$data,
  +$fragmentRefs: AssignableIndividualsPagination_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "tenant",
  "multi_select_assignable_individuals"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "clinicians"
    },
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "tenant_id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./AssignableIndividualsPaginationRefetchQuery.graphql')
    }
  },
  "name": "AssignableIndividualsPagination_tenant",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "tenant_id"
        }
      ],
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": "multi_select_assignable_individuals",
          "args": [
            {
              "kind": "Variable",
              "name": "clinicians",
              "variableName": "clinicians"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            }
          ],
          "concreteType": "IndividualRoleConnection",
          "kind": "LinkedField",
          "name": "__AssignableIndividualsPagination_multi_select_assignable_individuals_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IndividualRoleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "IndividualRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '4bc498ba4415a610db34ca946995a35a';

module.exports = node;
