// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query StbEscalationsInsightsQuery(
    $interval: InsightsIntervalEnum!
    $start_date: DateTime!
    $end_date: DateTime!
    $owner_tenant_id: ID
  ) {
    viewer {
      insights {
        stb_escalations(
          interval: $interval
          start_date: $start_date
          end_date: $end_date
          owner_tenant_id: $owner_tenant_id
        ) {
          series_categories
          series {
            name
            data
          }
        }
      }
    }
  }
`
