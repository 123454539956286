// @flow

import React from 'react'
import Loadable from 'react-loadable'

import { TinyLoader } from 'react-ui/components/LoadingSpinner'

export default Loadable({
  loader() {
    return import('./WelcomePageContainer').then(
      cjsModule => cjsModule.WelcomePageContainerLoader,
    )
  },
  loading() {
    return <TinyLoader />
  },
  render: React.createElement,
})
