// @flow

import { createContext, useContext } from 'react'

export type VariablesType<T = any> = {
  setVariables: T => void,
  updateVariables: ($Shape<T>) => void,
  variables: T,
}

// prettier-ignore
const PaginationQueryVariablesContext = createContext<VariablesType<any>>({})

export const PaginationQueryVariablesContextProvider =
  PaginationQueryVariablesContext.Provider
export const PaginationQueryVariablesContextConsumer =
  PaginationQueryVariablesContext.Consumer
export const usePaginationQuery = () =>
  useContext(PaginationQueryVariablesContext)
