/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type DataTableType = "clinician" | "employee" | "individual" | "%future added value";
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
export type RolesRemoveInput = {|
  clientMutationId?: ?string,
  roles: RolesSelectionInput,
|};
export type RolesSelectionInput = {|
  dataTableType?: ?DataTableType,
  deselectedRoleIds?: ?$ReadOnlyArray<string>,
  filters?: ?RolesFilterInput,
  selectedRoleIds?: ?$ReadOnlyArray<string>,
|};
export type RolesFilterInput = {|
  assignmentStatus?: ?boolean,
  excludeDischarged?: ?boolean,
  roleTypes?: ?$ReadOnlyArray<RoleTypes>,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
  status?: ?$ReadOnlyArray<string>,
|};
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type RemoveRolesMutationVariables = {|
  input: RolesRemoveInput
|};
export type RemoveRolesMutationResponse = {|
  +rolesRemove: ?{|
    +success: boolean
  |}
|};
export type RemoveRolesMutation = {|
  variables: RemoveRolesMutationVariables,
  response: RemoveRolesMutationResponse,
|};
*/


/*
mutation RemoveRolesMutation(
  $input: RolesRemoveInput!
) {
  rolesRemove(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "RolesRemovePayload",
    "kind": "LinkedField",
    "name": "rolesRemove",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "RemoveRolesMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "RemoveRolesMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "acc7acfd44b6b996622e9a82e58a46dc",
    "id": null,
    "metadata": {},
    "name": "RemoveRolesMutation",
    "operationKind": "mutation",
    "text": "mutation RemoveRolesMutation(\n  $input: RolesRemoveInput!\n) {\n  rolesRemove(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b0ae771207970705721ff0f321fa65ad';

module.exports = node;
