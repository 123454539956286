// @flow

import React from 'react'
import { connect } from 'react-fela'

import TickCircleIcon from 'react-ui/assets/icons/tick-circle.svg'
import Icon from 'react-ui/components/Icon'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  colorCode: string,
}

const styleRules = ({ theme, colorCode }) => ({
  icon: {
    marginTop: theme.Grid.gutter,
  },
  message: {
    color: colorCode || theme.palette.component.primary.base,
    fontSize: theme.Font.fontSize,
    margin: 'auto',
    textAlign: 'center',
    width: '100%',
  },
})

const ThankYouMessage = ({ styles, rules }: PropsType) => (
  <div className={styles.message}>
    <Icon as={TickCircleIcon} scale={2} extend={rules.icon} />
    <br />
    <p> Thank you, your input was recorded</p>
  </div>
)

export default connect(styleRules)(ThankYouMessage)
