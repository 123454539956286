// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserResourceVisitLinkMutationResponse,
  UserResourceVisitLinkMutationVariables,
} from './__generated__/UserResourceVisitLinkMutation.graphql'

const mutation = graphql`
  mutation UserResourceVisitLinkMutation($input: UserResourceVisitLinkInput!) {
    userResourceVisitLink(input: $input) {
      clientMutationId
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: UserResourceVisitLinkMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: UserResourceVisitLinkMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
