// @flow

import React, { type Node } from 'react'
import cx from 'classnames'

import Heading from 'react-ui/components/Heading'
import type { AnswerType, PropertiesType } from 'services/formSchema'

import s from './ObjectFieldTemplate.scss'

type PropsType = {
  formData: {
    [questionId: string]: AnswerType,
  },
  properties: Array<{
    content: Node,
    name: string,
  }>,
  schema: {
    properties: PropertiesType,
  },
}

const headingStyle = ({ theme }) => {
  const { headingTextColor } = theme.palette

  return {
    marginTop: '40px',
    marginBottom: '20px',
    fontSize: '0.9rem',
    color: headingTextColor,
    fontWeight: '300',
  }
}

export function ObjectFieldTemplate() {
  return ({ properties }: PropsType) => (
    <div className={cx(s.formContainer)}>
      {properties.map(({ content, name }, index) => {
        const questionNum = index + 1
        return (
          <div
            className={cx(s.questionContainer)}
            key={name}
            data-testid="Question"
          >
            <Heading level={3} extend={headingStyle}>
              Question {questionNum}
            </Heading>
            {content}
          </div>
        )
      })}
    </div>
  )
}
