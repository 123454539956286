// @flow

import React from 'react'
import createRender from 'found/createRender'

import PageError from '../pages/PageError'

import createConnectedRouter from './routeConfig/createConnectedRouter'

const rerouteToSignInPageWhen40X = router => {
  router.replace('/users/sign_in')
}

export default createConnectedRouter({
  render: createRender({
    renderError: ({ error, router }) => {
      switch (error.status) {
        case 401: {
          rerouteToSignInPageWhen40X(router)
          return null
        }
        case 422: {
          rerouteToSignInPageWhen40X(router)
          return null
        }
        case 404: {
          return <PageError errorCode={error.status} />
        }
        case 500: {
          return <PageError errorCode={error.status} />
        }
        case 502: {
          return <PageError errorCode={error.status} />
        }
        case 504: {
          return <PageError errorCode={error.status} />
        }
        default: {
          return <PageError errorCode={error.status} />
        }
      }
    },
  }),
})
