// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const CrossIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M6.29289322,7 L0.146446609,0.853553391 C-0.0488155365,0.658291245 -0.0488155365,0.341708755 0.146446609,0.146446609 C0.341708755,-0.0488155365 0.658291245,-0.0488155365 0.853553391,0.146446609 L7,6.29289322 L13.1464466,0.146446609 C13.3417088,-0.0488155365 13.6582912,-0.0488155365 13.8535534,0.146446609 C14.0488155,0.341708755 14.0488155,0.658291245 13.8535534,0.853553391 L7.70710678,7 L13.8535534,13.1464466 C14.0488155,13.3417088 14.0488155,13.6582912 13.8535534,13.8535534 C13.6582912,14.0488155 13.3417088,14.0488155 13.1464466,13.8535534 L7,7.70710678 L0.853553391,13.8535534 C0.658291245,14.0488155 0.341708755,14.0488155 0.146446609,13.8535534 C-0.0488155365,13.6582912 -0.0488155365,13.3417088 0.146446609,13.1464466 L6.29289322,7 Z"
            id="cross-path-1"
          />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="icon-/-14x14-/-x-/-1px">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#cross-path-1" />
            </mask>
            <use
              id="Stroke-6993"
              fillRule="nonzero"
              xlinkHref="#cross-path-1"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default CrossIcon
