// @flow

import { graphql } from 'relay-runtime'

export const recoveryTrajectoriesQuery = graphql`
  query RecoveryTrajectoriesQuery(
    $quarter_start_date: DateTime!
    $quarter_end_date: DateTime!
    $tenant_id: ID!
  ) {
    viewer {
      outcome_report {
        recovery_trajectories(
          quarter_start_date: $quarter_start_date
          quarter_end_date: $quarter_end_date
          tenant_id: $tenant_id
        ) {
          series {
            name
            color
            data
          }
          health_domain_label
          total
        }
      }
    }
  }
`
