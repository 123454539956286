/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserUpdateInput = {|
  clientMutationId?: ?string,
  user: UserUpdateInputObject,
|};
export type UserUpdateInputObject = {|
  completed_onboarding?: ?boolean,
  email?: ?string,
  legal_name?: ?string,
  phone_number?: ?string,
  preferred_language?: ?string,
  preferred_name?: ?string,
  selected_role_id?: ?string,
  tenant_id?: ?string,
  username?: ?string,
|};
export type UserUpdateMutationVariables = {|
  input: UserUpdateInput
|};
export type UserUpdateMutationResponse = {|
  +userUpdate: ?{|
    +user: {|
      +completed_onboarding: boolean,
      +email: string,
      +preferred_name: ?string,
      +preferred_language: ?string,
      +policies: $ReadOnlyArray<{|
        +name: string,
        +enabled: boolean,
      |}>,
      +current_role: {|
        +id?: string,
        +policies?: $ReadOnlyArray<{|
          +name: string,
          +enabled: boolean,
        |}>,
      |},
      +homepage_path: string,
    |}
  |}
|};
export type UserUpdateMutation = {|
  variables: UserUpdateMutationVariables,
  response: UserUpdateMutationResponse,
|};
*/


/*
mutation UserUpdateMutation(
  $input: UserUpdateInput!
) {
  userUpdate(input: $input) {
    user {
      completed_onboarding
      email
      preferred_name
      preferred_language
      policies {
        name
        enabled
      }
      current_role {
        __typename
        ... on RoleInterface {
          __isRoleInterface: __typename
          id
          policies {
            name
            enabled
          }
        }
        ... on AdminRole {
          id
        }
        ... on ClinicianRole {
          id
        }
        ... on IndividualRole {
          id
        }
        ... on LeadClinicianRole {
          id
        }
        ... on ManagerRole {
          id
        }
        ... on OwnerRole {
          id
        }
        ... on ResearcherRole {
          id
        }
        ... on SuperRole {
          id
        }
        ... on SupportPersonRole {
          id
        }
      }
      homepage_path
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "completed_onboarding",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferred_name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "preferred_language",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "concreteType": "Policy",
  "kind": "LinkedField",
  "name": "policies",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/),
    (v6/*: any*/)
  ],
  "type": "RoleInterface",
  "abstractKey": "__isRoleInterface"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homepage_path",
  "storageKey": null
},
v10 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  (v8/*: any*/)
                ],
                "storageKey": null
              },
              (v9/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  },
                  (v8/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "AdminRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "ClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "LeadClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "ManagerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "OwnerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "ResearcherRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "SuperRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v10/*: any*/),
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v9/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "0b930b9dbbb536a08fb03d2ccaacef7f",
    "id": null,
    "metadata": {},
    "name": "UserUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UserUpdateMutation(\n  $input: UserUpdateInput!\n) {\n  userUpdate(input: $input) {\n    user {\n      completed_onboarding\n      email\n      preferred_name\n      preferred_language\n      policies {\n        name\n        enabled\n      }\n      current_role {\n        __typename\n        ... on RoleInterface {\n          __isRoleInterface: __typename\n          id\n          policies {\n            name\n            enabled\n          }\n        }\n        ... on AdminRole {\n          id\n        }\n        ... on ClinicianRole {\n          id\n        }\n        ... on IndividualRole {\n          id\n        }\n        ... on LeadClinicianRole {\n          id\n        }\n        ... on ManagerRole {\n          id\n        }\n        ... on OwnerRole {\n          id\n        }\n        ... on ResearcherRole {\n          id\n        }\n        ... on SuperRole {\n          id\n        }\n        ... on SupportPersonRole {\n          id\n        }\n      }\n      homepage_path\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '06b248c5e3182f45d13220163867a957';

module.exports = node;
