// @flow

import React, { useEffect } from 'react'
import { Provider as ReduxProvider } from 'react-redux'
import { RelayEnvironmentProvider } from 'react-relay'
import FarceActions from 'farce/Actions'
import WebFont from 'webfontloader'

import AppMetadata from 'react-ui/utils/AppMetadata'
import ScrollProvider from 'react-ui/utils/scroll/ScrollProvider'
import FeatureToggleProvider from 'containers/FeatureToggle'
import FelaProvider from 'containers/FelaProvider'
import { ConnectedFlashContainer, setFlashStore } from 'containers/Flash'
import createStore from 'services/createStore'
import { buildEnvironment, buildNetwork, fetchQuery } from 'services/relay'
import routeConfig from 'services/routeConfig'
import Router from 'services/routeConfig/Router'
import { handlePageLoadFlashes } from 'shared/services/flashes'

export default function() {
  const store = createStore({ routeConfig })
  store.dispatch(FarceActions.init())
  const relayNetwork = buildNetwork(fetchQuery)
  const relayEnvironment = buildEnvironment(relayNetwork)

  setFlashStore(store)
  // Only display server-provided flash messages after all the Providers have initialised
  setTimeout(() => {
    handlePageLoadFlashes(window.FLASH_MESSAGES || {})
  }, 0)

  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Raleway:400,600'],
      },
    })
  }, [])

  return (
    <FeatureToggleProvider>
      <ReduxProvider store={store}>
        <RelayEnvironmentProvider environment={relayEnvironment}>
          <ScrollProvider>
            <FelaProvider>
              <React.Fragment>
                <AppMetadata />
                <Router environment={relayEnvironment} />
                <ConnectedFlashContainer />
              </React.Fragment>
            </FelaProvider>
          </ScrollProvider>
        </RelayEnvironmentProvider>
      </ReduxProvider>
    </FeatureToggleProvider>
  )
}
