// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const NoticeIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 28 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <desc>Created with Sketch.</desc>
        <defs>
          <path
            d="M17.5,20 C17.7758576,20 18,19.7758576 18,19.5 C18,19.2241424 17.7758576,19 17.5,19 L16.5,19 C16.2238576,19 16,18.7761424 16,18.5 L16,13.5 C16,13.2241424 15.7758576,13 15.5,13 L11.5,13 C11.2241424,13 11,13.2241424 11,13.5 C11,13.7758576 11.2241424,14 11.5,14 L12.5,14 C12.7761424,14 13,14.2238576 13,14.5 L13,18.5 C13,18.7761424 12.7761424,19 12.5,19 L11.5,19 C11.2241424,19 11,19.2241424 11,19.5 C11,19.7758576 11.2241424,20 11.5,20 L17.5,20 Z M17,18 L17.5,18 C18.3281424,18 19,18.6718576 19,19.5 C19,20.3281424 18.3281424,21 17.5,21 L11.5,21 C10.6718576,21 10,20.3281424 10,19.5 C10,18.6718576 10.6718576,18 11.5,18 L12,18 L12,15 L11.5,15 C10.6718576,15 10,14.3281424 10,13.5 C10,12.6718576 10.6718576,12 11.5,12 L15.5,12 C16.3281424,12 17,12.6718576 17,13.5 L17,18 Z M12,8 C12,9.10485763 12.8951424,10 14,10 C15.1048576,10 16,9.10485763 16,8 C16,6.89514237 15.1048576,6 14,6 C12.8951424,6 12,6.89514237 12,8 Z M11,8 C11,6.34285763 12.3428576,5 14,5 C15.6571424,5 17,6.34285763 17,8 C17,9.65714237 15.6571424,11 14,11 C12.3428576,11 11,9.65714237 11,8 Z M14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 C2,20.627417 7.372583,26 14,26 Z M14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 C21.7319865,0 28,6.2680135 28,14 C28,21.7319865 21.7319865,28 14,28 Z"
            id="notice-path-1"
          />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="icon-/-28x28-/-information">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#notice-path-1" />
            </mask>
            <use
              id="Stroke-2991"
              fillRule="nonzero"
              xlinkHref="#notice-path-1"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default NoticeIcon
