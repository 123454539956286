// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  DisconnectFitbitMutationResponse,
  DisconnectFitbitMutationVariables,
} from './__generated__/DisconnectFitbitMutation.graphql'

type CommitArgs = {
  environment: Object,
  onCompleted?: DisconnectFitbitMutationResponse => void,
  onError?: (result: Object) => void,
  variables: DisconnectFitbitMutationVariables,
}

const mutation = graphql`
  mutation DisconnectFitbitMutation($input: FitbitDisconnectInput!) {
    fitbitDisconnect(input: $input) {
      user {
        fitbit {
          connected
          connectionUrl
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: CommitArgs) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
