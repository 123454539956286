// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import Loading from 'react-ui/assets/images/loading.svg'
import MatchMedia from 'react-ui/components/MatchMedia'
import createComponentId from 'shared/services/id'

import TrackableResultCardBody from './TrackableResultCardBody'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  bottomNav: Node,
  color: string,
  componentId?: string,
  footer?: Node,
  handleClick: () => void,
  inPlan?: boolean,
  pending?: boolean,
  rating: string,
  timeSinceUpdate: string,
  title: string,
  trendIndicator?: Node,
  updateRequired?: Node,
}

const supportGrid = {
  '@supports (display: grid)': {
    minHeight: '0',
    margin: '0',
    flexGrow: '1',
    maxWidth: 'none',
  },
}
/**
 * Function that define minHeight and handle grid support
 */

const minHeightHandler = (val: number): Object => ({
  minHeight: `${val}px`,
  ...supportGrid,
})

/**
 * flexGenerator function that handle Responsive GridSystem in a browser that only support flex
 */

const flexGenerator = (cols: number) => {
  const distance = 11
  const flexPercentage = 100 / cols - 1
  const widthBase = 100 * (1 / cols)
  const minHeight = cols >= 3 ? 190 : 0

  return {
    flex: `1 0 ${flexPercentage}%`,
    maxWidth: `calc( ${widthBase}% - ${distance}px)`,
    ...minHeightHandler(minHeight),
  }
}

const styleRules = ({ theme, handleClick, color, inPlan = false }) => ({
  GridElement: {
    display: 'flex',
    position: 'relative',
    flex: '1 0 20%',
    flexDirection: 'column',
    maxWidth: 'calc(100% * (1/2))',
    ...supportGrid,
    '@media screen and (min-width: 0px) and (max-width: 360px)': {
      margin: '10px 5px',
      ...flexGenerator(2),
    },
    '@media screen and (min-width: 361px) and (max-width: 608px)': {
      margin: '10px 5px',
      ...flexGenerator(3),
    },
    [theme.breakpoints.queries.sm]: {
      margin: '20px 5px',
      ...flexGenerator(4),
    },
    [theme.breakpoints.queries.lg]: {
      margin: '20px 5px',
      ...flexGenerator(5),
    },
  },
  Box: {
    className: 'HeathCard_Box',
    width: '100%',
    backgroundColor: inPlan
      ? theme.palette.component.primary.mutedBase
      : '#fff',
    borderRadius: '5px',
    boxShadow: '0 3px 6px 2px rgba(7, 7, 8, 0.14)',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    padding: '0px',
    borderTop: `8px solid ${color}`,
    cursor: handleClick ? 'pointer' : 'auto',
    ...minHeightHandler(160),
    '@media screen and (min-width: 0px) and (max-width: 420px)': {
      ...minHeightHandler(180),
    },
    [theme.breakpoints.queries.md]: {
      ...minHeightHandler(190),
    },
  },
  FlexWrapper: {
    display: 'flex',
    flexGrow: '1',
    flexDirection: 'column',
    outline: 'none',
  },
  Rating: {
    fontSize: '1.1rem',
    lineHeight: '1.1rem',
    display: 'flex',
    flexShrink: '0',
    padding: '10px',
    color,
    ...minHeightHandler(45),
    [theme.breakpoints.queries.xs]: {
      fontSize: '.9rem',
    },
  },
  Loading: {
    paddingLeft: '8px',
    width: '35px',
  },
})

const defaultId = createComponentId(__filename)

const PendingIcon = ({ styles }) => <Loading className={styles.Loading} />

const TrackableResultCard = (props: PropsType) => {
  const {
    pending = false,
    rating,
    updateRequired,
    componentId = defaultId,
    styles,
    handleClick,
    title,
    trendIndicator,
    timeSinceUpdate,
    bottomNav,
    footer,
  } = props

  return (
    <div className={styles.GridElement}>
      {updateRequired && (
        <MatchMedia>{({ md }) => md && updateRequired}</MatchMedia>
      )}
      <div className={styles.Box} data-component-id={componentId}>
        <div
          className={styles.FlexWrapper}
          data-component-id="card-body-wrapper"
          role="button"
          tabIndex={0}
          onClick={handleClick}
          onKeyPress={handleClick}
        >
          <div data-component-id="card-header" className={styles.Rating}>
            {rating} {pending && <PendingIcon styles={styles} />}
          </div>
          <TrackableResultCardBody
            title={title}
            trendIndicator={trendIndicator}
            timeSinceUpdate={timeSinceUpdate}
          />
          {footer}
        </div>
        {bottomNav && <MatchMedia>{({ lg }) => lg && bottomNav}</MatchMedia>}
      </div>
    </div>
  )
}

export default connect(styleRules)(TrackableResultCard)
