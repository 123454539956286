/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StratifiedScoringResultAnswerTable_answers$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StratifiedScoringResultAnswers_answers$ref: FragmentReference;
declare export opaque type StratifiedScoringResultAnswers_answers$fragmentType: StratifiedScoringResultAnswers_answers$ref;
export type StratifiedScoringResultAnswers_answers = $ReadOnlyArray<{|
  +$fragmentRefs: StratifiedScoringResultAnswerTable_answers$ref,
  +$refType: StratifiedScoringResultAnswers_answers$ref,
|}>;
export type StratifiedScoringResultAnswers_answers$data = StratifiedScoringResultAnswers_answers;
export type StratifiedScoringResultAnswers_answers$key = $ReadOnlyArray<{
  +$data?: StratifiedScoringResultAnswers_answers$data,
  +$fragmentRefs: StratifiedScoringResultAnswers_answers$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StratifiedScoringResultAnswers_answers",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StratifiedScoringResultAnswerTable_answers"
    }
  ],
  "type": "AnswerInterface",
  "abstractKey": "__isAnswerInterface"
};
// prettier-ignore
(node/*: any*/).hash = '8e9b26db3fe38885937be7c8d46936b4';

module.exports = node;
