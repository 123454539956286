// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import { GaugeChart } from 'react-ui/components/Charts'
import { type GaugeChartType } from 'react-ui/components/Charts/GaugeChart'
import { H4 } from 'react-ui/components/Heading'

import FancyContainer from './FancyContainer'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  color: string,
  componentId?: string,
  hasData: boolean,
  lastUpdatedLabel: string,
  pending?: boolean,
  renderNoData: Node,
  renderPending?: Node,
  renderSkipped?: Node,
  routeToBOCByTrackableId: () => void,
  skipped?: boolean,
  title: string,
  trendIndicator: string,
} & GaugeChartType

const styleRules = ({ theme, trendIndicator = '' }) => ({
  footer: {
    className: 'FancyHealthCard__footer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '14px',
    color: theme.palette.component.muted.mutedActive,
  },

  indicatorHolder: {
    display: 'flex',
    width: '100%',
  },

  trendIndicator: {
    width: '50%',
    textAlign: 'right',
    color: theme.TrendIndicator.colors[trendIndicator.toLowerCase()],
  },

  updatedAt: {
    width: '50%',
  },

  textSeparator: {
    marginLeft: '14px',
    marginRight: '14px',
  },

  buttonStylesOverride: {
    marginTop: theme.spacing(0.5),
    height: 'auto',
  },

  headingStylesOverride: {
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center',
  },
})

const FancyHealthCard = ({
  color,
  activeIndex,
  componentId,
  labels,
  lastUpdatedLabel,
  hasData,
  rules,
  styles,
  title,
  trendIndicator,
  routeToBOCByTrackableId,
  renderNoData,
  renderSkipped,
  renderPending,
  skipped = false,
  pending = false,
}: PropsType) => {
  if (!hasData) {
    return renderNoData
  }

  if (skipped) {
    return renderSkipped || <></>
  }

  if (pending) {
    return renderPending || <></>
  }

  return (
    <FancyContainer
      componentId={componentId}
      handleClick={routeToBOCByTrackableId}
    >
      <H4 extend={rules.headingStylesOverride}>{title}</H4>
      <GaugeChart
        activeColor={color}
        activeIndex={activeIndex}
        labels={labels}
      />

      <div className={styles.footer}>
        <div className={styles.indicatorHolder}>
          <span className={styles.trendIndicator}>{trendIndicator}</span>
          <span className={styles.textSeparator}>|</span>
          <span className={styles.updatedAt}>
            Updated {lastUpdatedLabel} ago
          </span>
        </div>
      </div>
    </FancyContainer>
  )
}

export default connect(styleRules)(FancyHealthCard)
