// @flow

import * as React from 'react'
import { createFragmentContainer } from 'react-relay'
import { curry, flow, map, reverse, sortBy } from 'lodash/fp'

import { AnswerSetLoader } from 'components/AnswerSet'
import createComponentId from 'shared/services/id'

import { answerSetsAnswerSets } from './query/AnswerSets'

import type { AnswerSets_answer_sets } from './query/__generated__/AnswerSets_answer_sets.graphql'

type PropsType = {
  answer_sets: AnswerSets_answer_sets,
  componentId?: string,
}

const renderRow = curry((props, answer_set) => {
  const className = `${props.componentId}-${answer_set.id}`

  return (
    <div className={className} key={answer_set.id}>
      <AnswerSetLoader answer_set={answer_set} />
    </div>
  )
})

const renderRows = props =>
  flow(sortBy('submitted_at'), reverse, map(renderRow(props)))

const defaultId = createComponentId(__filename)

export const AnswerSets = (props: PropsType) => {
  const { answer_sets, componentId = defaultId } = props
  const renderedRows = renderRows({ ...props, componentId })(answer_sets)

  return (
    <div className={componentId}>{renderedRows.length > 0 && renderedRows}</div>
  )
}

export const AnswerSetsLoader = createFragmentContainer(AnswerSets, answerSetsAnswerSets)
