// @flow

import { graphql } from 'react-relay'

export const query = {
  user_trackable: graphql`
    fragment ScoreCardsPreviousAnswerSet_user_trackable on UserTrackable {
      trackable {
        formula {
          formula_ranges {
            label
            value
          }
        }
      }
    }
  `,
  answer_set: graphql`
    fragment ScoreCardsPreviousAnswerSet_answer_set on AnswerSet {
      skipped
      answered_by {
        ... on RoleInterface {
          id
          user {
            name
          }
        }
      }
      answers {
        ...AnswerTable_answers
      }
      submitted_at
    }
  `,
}
