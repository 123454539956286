// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'
import { sortBy } from 'lodash'
import { clone, filter, find } from 'lodash/fp'

import { AssignmentsSearchControl } from 'components/Staff/AssignmentsSearchControl'
import UserCheckBox, {
  type UserCheckBoxPropTypes,
} from 'react-ui/components/Form/UserCheckBox'
import { Container } from 'react-ui/components/Grid'
import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import { PaginationQueryLoader } from 'react-ui/components/Pagination/PaginationQueryLoader'
import PopOver, {
  PopOverDoneFooter,
  StaffActionButtonTrigger,
} from 'react-ui/components/PopOver'
import { commit as commitClinicianMultipleAssignmentUpdate } from 'mutations/ClinicianMultipleAssignmentUpdate'

import { usePolicies } from '../hooks/usePolicies'

import type { IndividualCliniciansList_individual } from './__generated__/IndividualCliniciansList_individual.graphql'

export type IndividualCliniciansListIndividualType = IndividualCliniciansList_individual

type PropsType = {
  individual?: IndividualCliniciansList_individual,
  per?: number,
  targetToggle?: () => void,
}

type ClinicianDataType = {
  assigned_to_individual: boolean,
  email?: string,
  id: string,
} & UserCheckBoxPropTypes

const cliniciansQuery = graphql`
  query IndividualCliniciansList_Query(
    $individual_id: ID!
    $tenant_id: ID!
    $count: Int!
    $cursor: String
    $search: String
  ) {
    viewer {
      tenant(id: $tenant_id) {
        id
        ...IndividualCliniciansList_tenant
          @arguments(
            individual_id: $individual_id
            count: $count
            cursor: $cursor
            search: $search
          )
      }
    }
  }
`

const assignableCliniciansFragment = {
  tenant: graphql`
    fragment IndividualCliniciansList_tenant on Tenant
      @argumentDefinitions(
        individual_id: { type: "ID!" }
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        search: { type: "String" }
      ) {
      assignable_clinicians(
        first: $count
        after: $cursor
        search: $search
        individual_id: $individual_id
      )
        @connection(
          key: "ClinicianAssignmentsPopover_assignable_clinicians"
          filters: []
        ) {
        edges {
          node {
            email
            id
            assigned_to_individual(individual_id: $individual_id)
            user {
              id
              ...UserCheckBoxLoader_user
              name
            }
          }
        }
      }
    }
  `,
}

type ClinicianCheckBoxType = {
  clinician: ClinicianDataType,
  clinicianAssignments: any,
  individual?: IndividualCliniciansList_individual,
  isAssigned: boolean,
  setClinicianAssignments: (clinicianAssignments: any) => void,
}

const containerStyle = () => ({
  container: {
    width: '450px',
    ':before': {
      display: 'none',
    },
  },
  tether: {
    minHeight: '400px',
  },
  scrollPane: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
})

const listContainerStyle = () => ({
  maxHeight: '346px',
  overflowY: 'auto',
  marginTop: '5px',
})

const DEFAULT_COUNT = 25

const changedState = (clinician, clinicianAssignments) =>
  find(obj => obj.clinician_id === clinician.id)(clinicianAssignments)

const isChecked = (hasChanged, isAssigned) =>
  (hasChanged && !isAssigned) || (isAssigned && !hasChanged)

export const ClinicianCheckBox = (props: ClinicianCheckBoxType) => {
  const {
    isAssigned,
    clinician,
    individual,
    clinicianAssignments = [],
    setClinicianAssignments,
  } = props

  const hasChanged = changedState(clinician, clinicianAssignments)

  const onChange = () => {
    const clinicianAssignment = {
      clinician_id: clinician.id,
      individual_id: individual?.id,
    }

    let copyAssignments = clone(clinicianAssignments)

    if (hasChanged) {
      copyAssignments = filter(obj => obj.clinician_id !== clinician.id)(
        copyAssignments,
      )
    } else {
      copyAssignments.push(clinicianAssignment)
    }

    setClinicianAssignments(copyAssignments)
  }

  return (
    <UserCheckBox
      block
      checked={isChecked(hasChanged, isAssigned)}
      onChange={onChange}
      user={clinician.user}
      staff
    />
  )
}

const IndividualCliniciansList = (props: PropsType) => {
  const { individual, targetToggle, per = DEFAULT_COUNT } = props

  const { tenant_id } = individual || {}

  if (!tenant_id) {
    throw new Error(
      'cannot populate list of clinicians for global individual without tenant',
    )
  }

  const { css } = useFela()
  const policies = usePolicies()
  const environment = useRelayEnvironment()
  const [clinicianAssignments, setClinicianAssignments] = useState([])

  const renderTrigger = ({ isOpen, onToggleOpen }) => (
    <StaffActionButtonTrigger
      targetToggle={targetToggle}
      isOpen={isOpen}
      onToggleOpen={onToggleOpen}
    />
  )

  const submitUpdate = onToggleOpen => {
    commitClinicianMultipleAssignmentUpdate({
      environment,
      variables: {
        input: {
          clinician_assignments_attributes: clinicianAssignments,
        },
      },
    })

    onToggleOpen()
  }

  const renderFooter = ({ onToggleOpen }) => (
    <PopOverDoneFooter onToggleOpen={() => submitUpdate(onToggleOpen)} />
  )

  if (!policies.CAN_ASSIGN_CLINICIANS) return null

  return (
    <PopOver
      attachment="middle center"
      targetModifier="visible"
      target={document.body}
      extend={containerStyle}
      overlay
      renderTrigger={renderTrigger}
      renderFooter={renderFooter}
    >
      <PaginationQueryLoader
        environment={environment}
        query={cliniciansQuery}
        fragments={assignableCliniciansFragment}
        variables={{
          tenant_id,
          individual_id: individual?.id,
          search: '',
          count: per,
        }}
        controls={AssignmentsSearchControl}
        getFragments={data => data.viewer}
      >
        {({ relay, tenant: { assignable_clinicians: { edges } } }) => {
          const data = sortBy(edges.map(({ node }) => node), clinician => {
            const hasChanged = changedState(clinician, clinicianAssignments)
            const isAssigned = clinician.assigned_to_individual

            return !isChecked(hasChanged, isAssigned)
          })
          return (
            <Container className={css(listContainerStyle)}>
              {individual &&
                data.map(clinician => {
                  return (
                    <ClinicianCheckBox
                      key={`${clinician.id}-${individual.id}`}
                      isAssigned={clinician.assigned_to_individual}
                      clinician={clinician}
                      individual={individual}
                      clinicianAssignments={clinicianAssignments}
                      setClinicianAssignments={setClinicianAssignments}
                    />
                  )
                })}
              {relay.hasMore() && (
                <LoadMore handleClick={() => relay.loadMore(per)} />
              )}
            </Container>
          )
        }}
      </PaginationQueryLoader>
    </PopOver>
  )
}

export const IndividualCliniciansListLoader = createFragmentContainer(
  IndividualCliniciansList,
  {
    individual: graphql`
      fragment IndividualCliniciansList_individual on IndividualRole {
        id
        tenant_id
      }
    `,
  },
)
// @flow
