// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  SubmitFeedbackMutationResponse,
  SubmitFeedbackMutationVariables,
} from './__generated__/SubmitFeedbackMutation.graphql'

type CommitArgs = {
  environment: Object,
  onCompleted?: SubmitFeedbackMutationResponse => void,
  onError?: (result: Object) => void,
  variables: SubmitFeedbackMutationVariables,
}

const mutation = graphql`
  mutation SubmitFeedbackMutation($input: FeedbackCreateInput!) {
    feedbackCreate(input: $input) {
      success
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: CommitArgs) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
