// @flow

import { difference, filter, find, includes, map } from 'lodash/fp'

type QuestionnaireType = {
  category: string,
  id: string,
  label: string,
}

type QuestionnaireFieldPropsType = {
  individualId: string,
  requestableQuestionnaires: Array<QuestionnaireType>,
  requesteeRoleId: ?string,
  selectedQuestionnaires: Array<string>,
}

export const questionnaireFields = ({
  requestableQuestionnaires,
  requesteeRoleId,
  individualId,
  selectedQuestionnaires,
}: QuestionnaireFieldPropsType) => {
  const initialQuestionnaire = find(['category', 'initial'])(
    requestableQuestionnaires,
  )

  const summaryQuestionnaire = find(['category', 'summary'])(
    requestableQuestionnaires,
  )
  const summaryHealthDomains = filter(['category', 'health_domain_summary'])(
    requestableQuestionnaires,
  )

  const allHealthDomains = filter(item => {
    return (item.category !== 'initial' && item.category !== 'summary')
  }, requestableQuestionnaires)

  const summaryIds = map('id')(summaryHealthDomains)

  const supportPersonSelected = requesteeRoleId !== individualId

  const disableFullQuestionnaire = () => {
    const otherSelection = filter(
      selection => selection !== initialQuestionnaire?.id,
    )(selectedQuestionnaires)
    return Boolean(otherSelection.length) || supportPersonSelected
  }

  const disableSummaryQuestionnaire = () => {
    const conflictIds = [...summaryIds, initialQuestionnaire?.id]
    const nonSelectedIds = difference(conflictIds, selectedQuestionnaires)
    const conflictIdSelected = nonSelectedIds.length < conflictIds.length

    return conflictIdSelected
  }

  const disableTrackableQuestionnaire = (questionnaireId: string) => {
    const fullQuestionnaireSelected = includes(initialQuestionnaire?.id)(
      selectedQuestionnaires,
    )
    const summaryQuestionnaireSelected = includes(summaryQuestionnaire?.id)(
      selectedQuestionnaires,
    )
    const isTrackableFromSummary = includes(questionnaireId)(summaryIds)

    return (
      fullQuestionnaireSelected ||
      (isTrackableFromSummary && summaryQuestionnaireSelected)
    )
  }

  return {
    allHealthDomains,
    initialQuestionnaire,
    summaryQuestionnaire,
    disableFullQuestionnaire,
    disableSummaryQuestionnaire,
    disableTrackableQuestionnaire,
  }
}
