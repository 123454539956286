// @flow

import { createFragmentContainer, graphql } from 'react-relay'

import { QuestionnairePage } from './QuestionnairePage'

export const QuestionnaireShowPageQuery = graphql`
  query QuestionnaireShowPage_Query($id: ID!) {
    viewer {
      currentUser {
        user_questionnaire(questionnaire_id: $id) {
          ...QuestionnaireShowPage_user_questionnaire
        }
        current_role {
          ...QuestionnaireShowPage_role
          ... on SupportPersonRole {
            supportees {
              ...QuestionnaireShowPage_supportees
            }
          }
        }
        ...QuestionnaireShowPage_user
      }
    }
  }
`

export const SpecificQuestionnaireShowPageQuery = graphql`
  query QuestionnaireShowPageSpecific_Query($questionnaire_id: ID!, $id: ID!) {
    user(id: $id, source: "questionnaire") {
      ...QuestionnaireShowPage_user
      user_questionnaire(questionnaire_id: $questionnaire_id) {
        ...QuestionnaireShowPage_user_questionnaire
      }
    }
    viewer {
      currentUser {
        current_role {
          ...QuestionnaireShowPage_role
          ... on SupportPersonRole {
            supportees {
              ...QuestionnaireShowPage_supportees
            }
          }
        }
      }
    }
  }
`

export const QuestionnaireShowPageLoader = createFragmentContainer(
  QuestionnairePage,
  {
    user: graphql`
      fragment QuestionnaireShowPage_user on User {
        ...QuestionnaireComplete_user
      }
    `,
    user_questionnaire: graphql`
      fragment QuestionnaireShowPage_user_questionnaire on UserQuestionnaire {
        ...UserQuestionnaire_user_questionnaire
        ...QuestionnaireComplete_userQuestionnaire
        questionnaire {
          id
        }
        first_user_questionnaires_question_set {
          questionnaires_question_set {
            id
          }
        }
        finished
        first_questionnaire_interaction
        ready_to_complete
        progress {
          decimal
          done
          total
          step
        }
        thank_you_page_relevant
      }
    `,
    role: graphql`
      fragment QuestionnaireShowPage_role on Role {
        __typename
        ...UserQuestionnaire_role
        ... on RoleInterface {
          id
          role_type
        }
      }
    `,
    supportees: graphql`
      fragment QuestionnaireShowPage_supportees on IndividualRole
        @relay(plural: true) {
        ...QuestionnaireShowPage_supportee @relay(mask: false)
        ...QuestionnaireComplete_supportees
      }
    `,
    supportee: graphql`
      fragment QuestionnaireShowPage_supportee on IndividualRole
        @relay(mask: false) {
        id
        tenant {
          id
          name
          support_person_help
        }
        user {
          id
          email
          name
        }
        supporting_user_questionnaire {
          id
          questionnaire {
            id
          }
          ensure_questionnaire {
            id
          }
          user {
            id
          }
        }
      }
    `,
  },
)
