// @flow

import { getValidClinicianFilters } from 'platform_web/services/validValues'

import type { IndividualsPageUser } from '../Individuals/IndividualsPage'

const AVAILABLE_TABLE_TYPE = [
  'assigned',
  'clinician_active',
  'invited',
  'removed',
  'discharged',
  'active',
]

type UseIndividualTableSortProps = {
  currentUser?: IndividualsPageUser,
}

const useIndividualTableSelect = (props: UseIndividualTableSortProps) => {
  const { currentUser } = props

  const { tab_options } = currentUser || {}

  const currentUserTableType = getValidClinicianFilters(
    tab_options,
    AVAILABLE_TABLE_TYPE,
  )

  return {
    currentUserTableType,
  }
}

export default useIndividualTableSelect
