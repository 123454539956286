// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { newPasswordRequest } from 'shared/services/api/registration'
import type { NewPasswordFormValues } from 'platform_web/pages/authentication/components/NewPasswordForm'
import NewPasswordForm from 'platform_web/pages/authentication/components/NewPasswordForm'
import NewPasswordWaiting from 'platform_web/pages/authentication/components/NewPasswordWaiting'

import ResponsiveForm from '../ResponsiveForm'

import type {
  FormikSetStatusFunc,
  FormikSetSubmittingFunc,
} from 'services/flow'

const NewPasswordPage = () => {
  const [sentEmail, setSentEmail] = useState(null)

  const { t: translation } = useTranslation('auth')

  const requestNewPassword = (
    values: NewPasswordFormValues,
    setSubmitting: FormikSetSubmittingFunc,
    setStatus: FormikSetStatusFunc,
  ) => {
    setSubmitting(true)
    return newPasswordRequest(values.email, {
      onSuccess: () => {
        setSentEmail(values.email)
      },
      onError: () => {
        setStatus({
          error: translation(
            'sorry_an_error_occured_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again',
          ),
        })
        setSubmitting(false)
      },
    })
  }

  return (
    <ResponsiveForm>
      {sentEmail ? (
        <NewPasswordWaiting onTryAgain={() => setSentEmail(null)} />
      ) : (
        <NewPasswordForm onSubmit={requestNewPassword} />
      )}
    </ResponsiveForm>
  )
}

export default NewPasswordPage
