// @flow

import { graphql } from 'react-relay'

export const SummaryGraphUser = graphql`
  fragment SummaryGraph_user on User {
    ...TrackableDropdown_user
    id
    summary_data_set(start_time: $start_time, end_time: $end_time) {
      trackable {
        id
        label
      }
      answered_by {
        ... on RoleInterface {
          role_type
        }
      }
      points {
        date
        scaled_value
        raw_score
        formula_range {
          boundLower
          boundUpper
          label
        }
      }
    }
  }
`

export const SummaryGraphQuery = graphql`
  query SummaryGraph_Query($start_time: String!, $end_time: String!) {
    viewer {
      currentUser {
        ...SummaryGraph_user
      }
    }
  }
`

export const SummaryGraphSpecificQuery = graphql`
  query SummaryGraphSpecific_Query(
    $id: ID!
    $start_time: String!
    $end_time: String!
  ) {
    user(id: $id) {
      ...SummaryGraph_user
    }
  }
`
