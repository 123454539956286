// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const panelStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    padding: `${theme.care.spacing.lg} ${theme.care.spacing.sm}`,
    background: theme.care.palette.surface.default,
    border: `1px solid ${theme.care.palette.border.subtle}`,
    borderRadius: theme.care.radius.md,
    width: '100%',
  }
}

export const tabContainerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.care.spacing.xs,
  }
}

export const containerStyle = () => ({
  height: '453px',
  overflowX: 'hidden',
})
