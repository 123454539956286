// @flow

// TODO: find out why health cards were implemented with dark text of #3a4656,
// and not #48566a, and if possible, either use (or change) grey700
export const contrastingText = {
  // the keys here refer to the background against which the text will contrast
  light: '#3a4656',
  dark: '#ffffff',
}

const text = {
  light: '#ffffff',
  dark: '#48566a', // grey700
}

const palette = {
  synBlue: {
    '25': {
      bg: '#edf7ff',
      text: text.dark,
    },
    '50': {
      bg: '#daeeff',
      text: text.dark,
    },
    '100': {
      bg: '#a8d7ff',
      text: text.dark,
    },
    '200': {
      bg: '#7ac2ff',
      text: text.dark,
    },
    '300': {
      bg: '#4eabff',
      text: text.dark,
    },
    '400': {
      bg: '#2b97fb',
      text: text.light,
    },
    '500': {
      bg: '#0F77D9',
      text: text.light,
    },
    '600': {
      bg: '#0968C3',
      text: text.light,
    },
    '700': {
      bg: '#0358b1',
      text: text.light,
    },
    '800': {
      bg: '#024490',
      text: text.light,
    },
    '900': {
      bg: '#003272',
      text: text.light,
    },
    a100: {
      bg: '#94c6fa',
      text: text.dark,
    },
    a200: {
      bg: '#59a8f7',
      text: text.dark,
    },
    a300: {
      bg: '#1e8df5',
      text: text.light,
    },
    a400: {
      bg: '#007ef5',
      text: text.light,
    },
    a500: {
      bg: '#0069f0',
      text: text.light,
    },
    a600: {
      bg: '#005fd9',
      text: text.light,
    },
    a700: {
      bg: '#004daa',
      text: text.light,
    },
  },
  synGreen: {
    '50': {
      bg: '#deefee',
      text: text.dark,
    },
    '100': {
      bg: '#bcdfdc',
      text: text.dark,
    },
    '200': {
      bg: '#72bdb7',
      text: text.dark,
    },
    '300': {
      bg: '#3ba69d',
      text: text.dark,
    },
    '400': {
      bg: '#1D9B91',
      text: text.light,
    },
    '500': {
      bg: '#008479',
      text: text.light,
    },
    '600': {
      bg: '#01786f',
      text: text.light,
    },
    '700': {
      bg: '#006059',
      text: text.light,
    },
    '800': {
      bg: '#004e49',
      text: text.light,
    },
    '900': {
      bg: '#003b36',
      text: text.light,
    },
    a50: {
      bg: '#e9f9f5',
      text: text.dark,
    },
    a100: {
      bg: '#b3ebde',
      text: text.dark,
    },
    a150: {
      bg: '#59cfbd',
      text: text.dark,
    },
    a200: {
      bg: '#4dc3b9',
      text: text.dark,
    },
    a300: {
      bg: '#0ea89b',
      text: text.dark,
    },
    a400: {
      bg: '#029388',
      text: text.light,
    },
    a700: {
      bg: '#016a63',
      text: text.light,
    },
  },
  synEarth: {
    '50': {
      bg: '#f6f3ea',
      text: text.dark,
    },
    '100': {
      bg: '#efebdf',
      text: text.dark,
    },
    '200': {
      bg: '#e4ddc6',
      text: text.dark,
    },
    '300': {
      bg: '#cec196',
      text: text.dark,
    },
    '400': {
      bg: '#b4a369',
      text: text.dark,
    },
    '500': {
      bg: '#85743a',
      text: text.light,
    },
    '600': {
      bg: '#74632a',
      text: text.light,
    },
    '700': {
      bg: '#65541a',
      text: text.light,
    },
    '800': {
      bg: '#53430d',
      text: text.light,
    },
    '900': {
      bg: '#3d3108',
      text: text.light,
    },
    a100: {
      bg: '#f3eddb',
      text: text.dark,
    },
    a200: {
      bg: '#ebe1bf',
      text: text.dark,
    },
    a400: {
      bg: '#c6ac57',
      text: text.dark,
    },
    a700: {
      bg: '#745c0b',
      text: text.light,
    },
  },
  synGrey: {
    '50': {
      bg: '#e7e9ee',
      text: text.dark,
    },
    '100': {
      bg: '#d0d6de',
      text: text.dark,
    },
    '200': {
      bg: '#a3adbb',
      text: text.dark,
    },
    '300': {
      bg: '#7f91a7',
      text: text.dark,
    },
    '400': {
      bg: '#71829c',
      text: text.light,
    },
    '500': {
      bg: '#637691',
      text: text.light,
    },
    '600': {
      bg: '#5b6c84',
      text: text.light,
    },
    '700': {
      bg: '#48566a',
      text: text.light,
    },
    '800': {
      bg: '#3a4656',
      text: text.light,
    },
    '900': {
      bg: '#293240',
      text: text.light,
    },
    a100: {
      bg: '#bfd0e9',
      text: text.dark,
    },
    a200: {
      bg: '#a5c0e5',
      text: text.dark,
    },
    a400: {
      bg: '#5674a0',
      text: text.light,
    },
    a700: {
      bg: '#354c6a',
      text: text.light,
    },
  },
  alertGreen: {
    '50': {
      bg: '#e8f5e9',
      text: text.dark,
    },
    '100': {
      bg: '#c8e6c9',
      text: text.dark,
    },
    '200': {
      bg: '#a5d6a7',
      text: text.dark,
    },
    '300': {
      bg: '#81c784',
      text: text.dark,
    },
    '400': {
      bg: '#66bb6a',
      text: text.dark,
    },
    '500': {
      bg: '#4CAF50',
      text: text.light,
    },
    '600': {
      bg: '#007801',
      text: text.light,
    },
    '700': {
      bg: '#388e3c',
      text: text.light,
    },
    '800': {
      bg: '#2e7d32',
      text: text.light,
    },
    '900': {
      bg: '#1b5e20',
      text: text.light,
    },
    a100: {
      bg: '#b9f6ca',
      text: text.dark,
    },
    a200: {
      bg: '#69f0ae',
      text: text.dark,
    },
    a400: {
      bg: '#00e676',
      text: text.dark,
    },
    a700: {
      bg: '#00c853',
      text: text.dark,
    },
  },
  alertOrange: {
    '50': {
      bg: '#fff3e0',
      text: text.dark,
    },
    '100': {
      bg: '#ffe0b2',
      text: text.dark,
    },
    '200': {
      bg: '#ffcc80',
      text: text.dark,
    },
    '300': {
      bg: '#ffb74d',
      text: text.dark,
    },
    '400': {
      bg: '#ffa726',
      text: text.dark,
    },
    '500': {
      bg: '#ff9800',
      text: text.dark,
    },
    '600': {
      bg: '#fb8c00',
      text: text.dark,
    },
    '700': {
      bg: '#f57c00',
      text: text.dark,
    },
    '800': {
      bg: '#c25429',
      text: text.light,
    },
    '900': {
      bg: '#e65100',
      text: text.light,
    },
    a100: {
      bg: '#ffd180',
      text: text.dark,
    },
    a200: {
      bg: '#ffab40',
      text: text.dark,
    },
    a250: {
      bg: '#FCCA00',
      text: text.light,
    },
    a400: {
      bg: '#FF9100',
      text: text.dark,
    },
    a700: {
      bg: '#ff6d00',
      text: text.dark,
    },
  },
  alertRed: {
    '50': {
      bg: '#ffebee',
      text: text.dark,
    },
    '100': {
      bg: '#ffcdd2',
      text: text.dark,
    },
    '200': {
      bg: '#ef9a9a',
      text: text.dark,
    },
    '300': {
      bg: '#e57373',
      text: text.dark,
    },
    '400': {
      bg: '#ef5350',
      text: text.dark,
    },
    '500': {
      bg: '#f44336',
      text: text.light,
    },
    '600': {
      bg: '#e53935',
      text: text.light,
    },
    '700': {
      bg: '#d32f2f',
      text: text.light,
    },
    '800': {
      bg: '#c62828',
      text: text.light,
    },
    '900': {
      bg: '#b71c1c',
      text: text.light,
    },
    a100: {
      bg: '#ff8a80',
      text: text.dark,
    },
    a200: {
      bg: '#ff5252',
      text: text.light,
    },
    a400: {
      bg: '#ff1744',
      text: text.light,
    },
    a700: {
      bg: '#d50000',
      text: text.light,
    },
  },
}

export const healthCardNoRating = palette.synGrey['500'].bg
