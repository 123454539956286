/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type DataGridRowFooterContentLoader_user$ref: FragmentReference;
declare export opaque type DataGridRowFooterContentLoader_user$fragmentType: DataGridRowFooterContentLoader_user$ref;
export type DataGridRowFooterContentLoader_user = {|
  +id: string,
  +email: string,
  +legal_name: ?string,
  +preferred_name: ?string,
  +last_seen_at: ?any,
  +linked_individual: ?{|
    +do_with_clinician: {|
      +question_sets: $ReadOnlyArray<{|
        +label: string
      |}>
    |},
    +individual_detail: ?{|
      +emr_user_id: ?string
    |},
    +initial_questionnaire_status: string,
  |},
  +personal_details: {|
    +date_of_birth: ?any
  |},
  +$refType: DataGridRowFooterContentLoader_user$ref,
|};
export type DataGridRowFooterContentLoader_user$data = DataGridRowFooterContentLoader_user;
export type DataGridRowFooterContentLoader_user$key = {
  +$data?: DataGridRowFooterContentLoader_user$data,
  +$fragmentRefs: DataGridRowFooterContentLoader_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "DataGridRowFooterContentLoader_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legal_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferred_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "last_seen_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "linked_individual",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "DoWithClinician",
          "kind": "LinkedField",
          "name": "do_with_clinician",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionSet",
              "kind": "LinkedField",
              "name": "question_sets",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualDetail",
          "kind": "LinkedField",
          "name": "individual_detail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "emr_user_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "initial_questionnaire_status",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualPersonalDetail",
      "kind": "LinkedField",
      "name": "personal_details",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date_of_birth",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b4d16cca8b44095ed03527b6055e9e28';

module.exports = node;
