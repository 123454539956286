/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClinicianDropdown_individual$ref = any;
type RequestAssessmentButton_individual$ref = any;
type RetakeQuestionnaireDropdown_individual$ref = any;
type StaffDownloadReportButton_individual$ref = any;
type SupportTaskButton_individual$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommonTasksButtons_individual$ref: FragmentReference;
declare export opaque type CommonTasksButtons_individual$fragmentType: CommonTasksButtons_individual$ref;
export type CommonTasksButtons_individual = {|
  +$fragmentRefs: RetakeQuestionnaireDropdown_individual$ref & SupportTaskButton_individual$ref & RequestAssessmentButton_individual$ref & ClinicianDropdown_individual$ref & StaffDownloadReportButton_individual$ref,
  +$refType: CommonTasksButtons_individual$ref,
|};
export type CommonTasksButtons_individual$data = CommonTasksButtons_individual;
export type CommonTasksButtons_individual$key = {
  +$data?: CommonTasksButtons_individual$data,
  +$fragmentRefs: CommonTasksButtons_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommonTasksButtons_individual",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RetakeQuestionnaireDropdown_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupportTaskButton_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "RequestAssessmentButton_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClinicianDropdown_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffDownloadReportButton_individual"
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7ba577f22df6ee4e0d9f470ca0c52a10';

module.exports = node;
