// @flow

import { uniqueId } from 'lodash'

import type { ActionsType, FlashMessageType } from './actions'
import {
  CLEAR_FLASH,
  CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE,
  CLOSE_MAIN_NAV,
  CLOSE_SUB_MENU,
  CREATE_FLASH,
  MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN,
  OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE,
  OPEN_MAIN_NAV,
  OPEN_SUB_MENU,
  REMOVE_FLASH,
  TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS,
} from './actionTypes'

export type StateType = {
  +clinicianIndividualGlobalTableOpen: boolean,
  +flashMessages: $ReadOnlyArray<FlashMessageType>,
  +hasSeenDashboardQuestionnaireModal: boolean,
  +mainNavOpen: boolean,
  +showOnboardingQuestionnaireSuccess: boolean,
  +subMenuOpen: boolean,
}

const defaultState = {
  clinicianIndividualGlobalTableOpen: false,
  flashMessages: [],
  mainNavOpen: false,
  subMenuOpen: false,
  showOnboardingQuestionnaireSuccess: false,
  hasSeenDashboardQuestionnaireModal: false,
}

export default (
  state: StateType = defaultState,
  action: ActionsType,
): StateType => {
  switch (action.type) {
    case OPEN_MAIN_NAV: {
      return {
        ...state,
        mainNavOpen: true,
      }
    }

    case CLOSE_MAIN_NAV: {
      return {
        ...state,
        clinicianIndividualGlobalTableOpen: false,
        mainNavOpen: false,
        subMenuOpen: false,
      }
    }

    case OPEN_SUB_MENU: {
      return {
        ...state,
        subMenuOpen: true,
      }
    }

    case CLOSE_SUB_MENU: {
      return {
        ...state,
        subMenuOpen: false,
      }
    }

    case OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE: {
      return {
        ...state,
        clinicianIndividualGlobalTableOpen: true,
      }
    }

    case CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE: {
      return {
        ...state,
        clinicianIndividualGlobalTableOpen: false,
      }
    }

    case MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN: {
      return {
        ...state,
        hasSeenDashboardQuestionnaireModal: true,
      }
    }

    case TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS: {
      return {
        ...state,
        showOnboardingQuestionnaireSuccess: action.show,
      }
    }

    case CREATE_FLASH: {
      const id: string = uniqueId('flash')
      return {
        ...state,
        flashMessages: state.flashMessages.concat({
          id,
          ...(action.payload: any),
        }),
      }
    }

    case CLEAR_FLASH: {
      return {
        ...state,
        flashMessages: [],
      }
    }

    case REMOVE_FLASH: {
      const flashMessages = state.flashMessages.filter(
        message => message.id !== action.payload.id,
      )
      return {
        ...state,
        flashMessages,
      }
    }

    default:
      return state
  }
}
