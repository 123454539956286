// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { Box, FlexContainer, FlexItem } from 'care-ui'

import TableSearchControl from '../components/TableSearchControl/TableSearchControl'
import TableSortControl from '../components/TableSortControl/TableSortControl'
import TableTypeSelectControl from '../components/TableTypeSelectControl/TableTypeSelectControl'
import {
  ESCALATIONS_TABLE_CONFIGURATIONS,
  PATH_ROUTES,
} from '../constants/EscalationsTableConstants'
import { type UseEscalationsTableReturnType } from '../hooks/useEscalationsTable'

const getActiveSortOptions = translation => [
  { header: 'name', label: translation('patient_name') },
  { header: 'createdAt', label: translation('date') },
]

const getResolvedSortOptions = translation => [
  { header: 'name', label: translation('patient_name') },
  { header: 'resolvedAt', label: translation('date') },
]

type PartialEscalationsTableReturnType = $Shape<UseEscalationsTableReturnType>

type EscalationsTableControlsProps = {
  router: any,
  useEscalationsTablePayload: PartialEscalationsTableReturnType,
}

const EscalationsTableControls = (props: EscalationsTableControlsProps) => {
  const { useEscalationsTablePayload, router } = props

  const {
    setTableType,
    tableType,
    queryVariables,
    updateQueryVariables,
    escalationsTableType,
    showResolvedDetails,
  } = useEscalationsTablePayload

  // Localization
  const { t: translation } = useTranslation('dataTable')

  const ACTIVE_SORT_OPTIONS = getActiveSortOptions(translation)
  const RESOLVED_SORT_OPTIONS = getResolvedSortOptions(translation)

  const sortOptions = showResolvedDetails
    ? RESOLVED_SORT_OPTIONS
    : ACTIVE_SORT_OPTIONS

  const handleOnChange = updatedTableType => {
    setTableType(updatedTableType)

    router.push({
      name: 'staff_notifications',
      params: PATH_ROUTES[updatedTableType],
    })

    updateQueryVariables({
      ...ESCALATIONS_TABLE_CONFIGURATIONS[updatedTableType],
    })
  }

  return (
    <>
      <FlexContainer justifyContent="space-between" paddingX="xxs">
        <FlexContainer gap="xxxs">
          <Box width="300px">
            <TableTypeSelectControl
              options={escalationsTableType}
              onChange={updatedTableType => handleOnChange(updatedTableType)}
              tableType={tableType}
            />
          </Box>

          <FlexItem>
            <TableSortControl
              sortOptions={sortOptions}
              queryVariables={queryVariables}
              updateQueryVariables={updateQueryVariables}
            />
          </FlexItem>
        </FlexContainer>

        <TableSearchControl
          queryVariables={queryVariables}
          updateQueryVariables={updateQueryVariables}
        />
      </FlexContainer>
    </>
  )
}

export default EscalationsTableControls
