// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const styleRules = (props: StyleProps) => {
  const { theme: { palette, breakpoints } } = props

  return {
    // ----------- Background -----------
    container: {
      minHeight: 'auto',
      height: '270px',
      [breakpoints.queries.md]: {
        minHeight: '100vh',
      },
    },

    // ----------- Main Content -----------
    mainContentContainer: {
      className: 'OnboardingWizard__mainContent',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      marginTop: '28px',
      [breakpoints.queries.md]: {
        marginTop: 0,
        height: '100vh',
        overflow: 'scroll',
        alignItems: 'flex-start',
      },
    },

    // ----------- Wizard -----------
    wizardContainer: {
      className: 'OnboardingWizard__wizardContainer',
      display: 'flex',
      flexDirection: 'column',
      [breakpoints.queries.md]: {
        flexDirection: 'row',
      },
    },

    asideContainer: {
      className: 'OnboardingWizard__asideContainer',
      [breakpoints.queries.md]: {
        minWidth: '570px',
      },
    },

    wizardContentContainer: {
      className: 'OnboardingWizard__wizardContentContainer',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      color: palette.component.primary.text,
      [breakpoints.queries.md]: {
        marginTop: '261px',
      },
    },

    wizardCircle: {
      marginBottom: '28px',
      boxShadow: 'inset 0 -3px 0 0 #0968C3, 0 6px 0 0 #0968C3',
      backgroundColor: palette.component.primary.accent,
    },

    wizardCardTitle: {
      marginBottom: '8px',
      fontSize: '29px',
      lineHeight: '56px',
    },

    wizardFooterContainer: {
      className: 'OnboardingWizard__wizardFooterContainer',
      position: 'absolute',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      bottom: '98px',
    },

    wizardLockIcon: {
      width: '18px',
      height: '28px',
      [breakpoints.queries.md]: {
        marginBottom: '14px',
      },
    },

    wizardFooterText: {
      fontSize: '14px',
      color: palette.component.primary.text,
      textAlign: 'center',
    },
    // ----------- end Wizard -----------
  }
}
