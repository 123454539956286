// @flow

type CollectionConnectionType = {
  edges?: ({ node?: any } & any)[],
}

export const nodesOf = (collectionConnection: CollectionConnectionType) => {
  if (!collectionConnection) return []
  const { edges = [] } = collectionConnection
  return edges.map(e => (e && e.node) || e).filter(Boolean)
}
