// @flow

import React from 'react'
import { connect } from 'react-fela'

import MinorArrowDown from 'react-ui/assets/icons/minor-arrow-down.svg'
import Icon from 'react-ui/components/Icon'
import { Button } from 'care-ui'
import KebabVertical from 'care-ui/atoms/icons/kebab-vertical.svg'

import { styleRules } from './DropDownTrigger.style'

import type { FelaStylesType } from 'react-ui/typing'

type PropsType = {
  componentId?: string,
  label: string,
  onToggleOpen?: (event?: SyntheticEvent<*>) => any,
  simple: boolean,
  styles: FelaStylesType,
}

const DropDownTrigger = ({
  componentId,
  label,
  styles = {},
  simple,
  onToggleOpen,
}: PropsType) => (
  <Button
    onClick={onToggleOpen}
    data-component-id={componentId || 'drop-down-trigger'}
    dataTestId={componentId || 'drop-down-trigger'}
    ariaLabel={label}
    variant="text"
    tooltipText={simple ? label : undefined}
  >
    {!!label && !simple && <span className={styles.triggerLabel}>{label}</span>}
    <Icon
      as={simple ? KebabVertical : MinorArrowDown}
      scale={simple ? 0.57 : 0.4}
    />
  </Button>
)

export default connect(styleRules)(DropDownTrigger)
