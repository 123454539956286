/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserQuestionnaireRefreshInput = {|
  answereeId?: ?string,
  assessmentRequestId?: ?string,
  clientMutationId?: ?string,
  questionnaireId?: ?string,
|};
export type UserQuestionnaireRefreshMutationVariables = {|
  input: UserQuestionnaireRefreshInput
|};
export type UserQuestionnaireRefreshMutationResponse = {|
  +userQuestionnaireRefresh: ?{|
    +user_questionnaire: {|
      +user: {|
        +id: string,
        +email: string,
        +preferred_name: ?string,
      |},
      +id: string,
      +ready_to_complete: boolean,
      +label: string,
      +description: ?string,
      +progress: {|
        +decimal: number,
        +done: number,
        +total: number,
        +step: number,
      |},
      +first_user_questionnaires_question_set: ?{|
        +finished: boolean,
        +questionnaires_question_set: {|
          +id: string
        |},
      |},
      +questionnaire: ?{|
        +id: string
      |},
      +ensure_questionnaire: {|
        +id: string
      |},
    |}
  |}
|};
export type UserQuestionnaireRefreshMutation = {|
  variables: UserQuestionnaireRefreshMutationVariables,
  response: UserQuestionnaireRefreshMutationResponse,
|};
*/


/*
mutation UserQuestionnaireRefreshMutation(
  $input: UserQuestionnaireRefreshInput!
) {
  userQuestionnaireRefresh(input: $input) {
    user_questionnaire {
      user {
        id
        email
        preferred_name
      }
      id
      ready_to_complete
      label
      description
      progress {
        decimal
        done
        total
        step
      }
      first_user_questionnaires_question_set {
        finished
        questionnaires_question_set {
          id
        }
        id
      }
      questionnaire {
        id
      }
      ensure_questionnaire {
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "user",
  "plural": false,
  "selections": [
    (v2/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferred_name",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "ready_to_complete",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "description",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "Progress",
  "kind": "LinkedField",
  "name": "progress",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "decimal",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "done",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "total",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "step",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "finished",
  "storageKey": null
},
v9 = [
  (v2/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionnairesQuestionSet",
  "kind": "LinkedField",
  "name": "questionnaires_question_set",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "concreteType": "Questionnaire",
  "kind": "LinkedField",
  "name": "questionnaire",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "concreteType": "Questionnaire",
  "kind": "LinkedField",
  "name": "ensure_questionnaire",
  "plural": false,
  "selections": (v9/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserQuestionnaireRefreshMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserQuestionnaireRefreshPayload",
        "kind": "LinkedField",
        "name": "userQuestionnaireRefresh",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserQuestionnaire",
            "kind": "LinkedField",
            "name": "user_questionnaire",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserQuestionnairesQuestionSet",
                "kind": "LinkedField",
                "name": "first_user_questionnaires_question_set",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserQuestionnaireRefreshMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserQuestionnaireRefreshPayload",
        "kind": "LinkedField",
        "name": "userQuestionnaireRefresh",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "UserQuestionnaire",
            "kind": "LinkedField",
            "name": "user_questionnaire",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "UserQuestionnairesQuestionSet",
                "kind": "LinkedField",
                "name": "first_user_questionnaires_question_set",
                "plural": false,
                "selections": [
                  (v8/*: any*/),
                  (v10/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/),
              (v12/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14698386121a96d915da9eecff816cc0",
    "id": null,
    "metadata": {},
    "name": "UserQuestionnaireRefreshMutation",
    "operationKind": "mutation",
    "text": "mutation UserQuestionnaireRefreshMutation(\n  $input: UserQuestionnaireRefreshInput!\n) {\n  userQuestionnaireRefresh(input: $input) {\n    user_questionnaire {\n      user {\n        id\n        email\n        preferred_name\n      }\n      id\n      ready_to_complete\n      label\n      description\n      progress {\n        decimal\n        done\n        total\n        step\n      }\n      first_user_questionnaires_question_set {\n        finished\n        questionnaires_question_set {\n          id\n        }\n        id\n      }\n      questionnaire {\n        id\n      }\n      ensure_questionnaire {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'a528084bc74fa1eebf5ca116017b0905';

module.exports = node;
