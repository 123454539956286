// @flow

import { graphql } from 'react-relay'

export const fragment = graphql`
  fragment ResearchDataSharingPage_user_research_programs_responses on UserResearchProgramsResponse
    @relay(plural: true) {
    id
    user_id
    tenant_research_program_id
    accepted
    tenant_research_program {
      research_program {
        title
        description
      }
    }
  }
`

export const query = graphql`
  query ResearchDataSharingPage_Query {
    viewer {
      currentUser {
        user_research_programs_responses {
          ...ResearchDataSharingPage_user_research_programs_responses
        }
      }
    }
  }
`
