// @flow

type ThemeType = { [string]: any }

type SizeStyleProps = {
  size?: 'lg' | 'md',
  theme: ThemeType,
}

export const sizeStyle = (props: SizeStyleProps) => {
  const { size = 'md', theme } = props

  // large size
  if (size === 'lg') {
    return {
      ...theme.care.typography.desktop.bodyLg,
    }
  }

  // medium size | Default
  return {
    ...theme.care.typography.desktop.bodyMd,
  }
}

export const searchBarStyle = ({
  theme,
  fullWidth,
}: {
  fullWidth: boolean,
  theme: ThemeType,
}) => ({
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  maxWidth: fullWidth ? '100%' : '300px',
  borderRadius: theme.care.radius.sm,
  border: `1px solid ${theme.care.palette.border.lighter}`,
  backgroundColor: theme.care.palette.text.default,
  padding: `${theme.care.spacing.xxs} ${theme.care.spacing.xs}`,
  color: theme.care.palette.text.subtitle,

  '&:focus-within': {
    outline: 'none',
    border: `1px solid ${theme.care.palette.border.default}`,
    color: theme.care.palette.text.positive,
  },
})

export const inputStyle = ({ theme }: ThemeType) => ({
  flex: '1',
  border: 'none',
  outline: 'none',
  width: '100%',
  marginLeft: theme.care.spacing.xxs,
})
