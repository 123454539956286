/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserResource_user_resource$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserResourceChangeState_user_resources$ref: FragmentReference;
declare export opaque type UserResourceChangeState_user_resources$fragmentType: UserResourceChangeState_user_resources$ref;
export type UserResourceChangeState_user_resources = $ReadOnlyArray<{|
  +status: UserResourceStatuses,
  +id: string,
  +resource: {|
    +clinical: boolean
  |},
  +$fragmentRefs: UserResource_user_resource$ref,
  +$refType: UserResourceChangeState_user_resources$ref,
|}>;
export type UserResourceChangeState_user_resources$data = UserResourceChangeState_user_resources;
export type UserResourceChangeState_user_resources$key = $ReadOnlyArray<{
  +$data?: UserResourceChangeState_user_resources$data,
  +$fragmentRefs: UserResourceChangeState_user_resources$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserResourceChangeState_user_resources",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clinical",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserResource_user_resource"
    }
  ],
  "type": "UserResource",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd35eaaff044ded955829a83fb8d11846';

module.exports = node;
