/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AssessmentRequestsCheckPageQueryVariables = {|
  assessmentRequestId: string
|};
export type AssessmentRequestsCheckPageQueryResponse = {|
  +viewer: {|
    +assessment_request: ?{|
      +questionnaire_answer_sets: $ReadOnlyArray<{|
        +id: string
      |}>
    |}
  |}
|};
export type AssessmentRequestsCheckPageQuery = {|
  variables: AssessmentRequestsCheckPageQueryVariables,
  response: AssessmentRequestsCheckPageQueryResponse,
|};
*/


/*
query AssessmentRequestsCheckPageQuery(
  $assessmentRequestId: ID!
) {
  viewer {
    assessment_request(id: $assessmentRequestId) {
      questionnaire_answer_sets {
        id
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "assessmentRequestId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "assessmentRequestId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "QuestionnaireAnswerSet",
  "kind": "LinkedField",
  "name": "questionnaire_answer_sets",
  "plural": true,
  "selections": [
    (v2/*: any*/)
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssessmentRequestsCheckPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AssessmentRequest",
            "kind": "LinkedField",
            "name": "assessment_request",
            "plural": false,
            "selections": [
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssessmentRequestsCheckPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": "AssessmentRequest",
            "kind": "LinkedField",
            "name": "assessment_request",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba762a197f0700d31beebe50027a06fe",
    "id": null,
    "metadata": {},
    "name": "AssessmentRequestsCheckPageQuery",
    "operationKind": "query",
    "text": "query AssessmentRequestsCheckPageQuery(\n  $assessmentRequestId: ID!\n) {\n  viewer {\n    assessment_request(id: $assessmentRequestId) {\n      questionnaire_answer_sets {\n        id\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f29e009d563b222f783a40ac19b7c5ca';

module.exports = node;
