// @flow

import React from 'react'
import { ThemeProvider } from 'react-fela'
import {
  createFragmentContainer,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { useRouter } from 'found'

import individualTheme from 'react-ui/themes/synergy-individual-theme'

import { ScoreCardsPage } from './components/ScoreCardsPage'
import {
  query,
  ScoreCardsPageComponentQuery,
} from './queries/ScoreCardsPageComponent'

import type { ScoreCardsPageComponent_user_trackable } from './queries/__generated__/ScoreCardsPageComponent_user_trackable.graphql'
import type { ScoreCardsPageComponent_viewer } from './queries/__generated__/ScoreCardsPageComponent_viewer.graphql'

type PropsType = {
  +user_trackable: ScoreCardsPageComponent_user_trackable,
  viewer: ScoreCardsPageComponent_viewer,
}

const ScoreCardsPageBase = (props: PropsType) => {
  const { user_trackable, viewer } = props

  if (!user_trackable || !viewer) return null

  return <ScoreCardsPage user_trackable={user_trackable} viewer={viewer} />
}

const ScoreCardsPageComponent = createFragmentContainer(
  ScoreCardsPageBase,
  query,
)

export const ScoreCardsPageComponentLoader = () => {
  const environment = useRelayEnvironment()
  const { match: { params } } = useRouter()

  const startDate = new Date(2000, 1, 1)
  const today = new Date()
  const queryVariables = {
    ...params,
    trackable_start_time: startDate.toISOString(),
    trackable_end_time: today.toISOString(),
  }

  return (
    <QueryRenderer
      environment={environment}
      query={ScoreCardsPageComponentQuery}
      variables={queryVariables}
      render={response => {
        const { props } = response || {}
        if (!props) return null
        return (
          <ThemeProvider theme={individualTheme}>
            <ScoreCardsPageComponent
              user_trackable={props?.user.user_trackable}
              viewer={props?.viewer}
            />
          </ThemeProvider>
        )
      }}
    />
  )
}
