/*
  @flow
*/

export type PropTypes = {
  answereeUserId?: string,
  answererRoleType?: string,
  assessmentRequestId?: string,
  onCompleted?: () => void,
  questionnaireId?: string,
  router?: Object,
}

const routeQuestionnaire = ({
  answererRoleType,
  answereeUserId: answeree_id,
  questionnaireId,
  router,
  onCompleted,
}: PropTypes) => {
  let route

  switch (answererRoleType) {
    case 'CLINICIAN':
      route = {
        name: 'specificQuestionnaireShow',
        params: {
          id: answeree_id,
          questionnaire_id: questionnaireId,
          answeree_id,
        },
      }
      break
    case 'SUPPORT_PERSON':
      route = {
        name: 'supportPersonQuestionnaireShow',
        params: {
          id: answeree_id,
          questionnaire_id: questionnaireId,
          answeree_id,
        },
      }
      break
    case 'INDIVIDUAL':
    default:
      route = {
        name: 'questionnaireShow',
        params: { id: questionnaireId, answeree_id },
      }
  }
  if (onCompleted) onCompleted()
  if (router) router.push(route)
}

export { routeQuestionnaire }
