// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { useFragment, useLazyLoadQuery } from 'react-relay'
import { map } from 'lodash/fp'

import Accordion from 'react-ui/components/Accordion'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import UserResearchProgramsResponse from 'pages/Role/UserResearchProgramsResponse'
import { SuspenseLoader } from 'care-ui'

import { fragment, query } from '../../queries/ResearchDataSharingPage'

import { linkStyle } from './ResearchDataSharingPage.style'

const ResearchDataSharingPage = () => {
  const { viewer: { currentUser } } = useLazyLoadQuery(query)
  const { user_research_programs_responses: fragmentRef } = currentUser || {}
  const user_research_programs_responses = useFragment(fragment, fragmentRef)

  const { t: translation } = useTranslation('shared')

  return (
    <Fragment>
      <Heading level={2}>{translation('research_and_data_sharing')}</Heading>
      <p>
        {translation(
          'your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_this_information_is_used_to_improve_the_delivery_of_person_centred_care_contributing_your_deidentified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care_read_our',
        )}&nbsp;
        <Link extend={linkStyle} to={{ name: 'research_data_sharing' }}>
          {translation('data_policy')}
        </Link>&nbsp;
        {translation(
          'for_more_information_about_how_innowell_uses_data_please_see',
        )}
      </p>

      <Accordion>
        {map(urpr => (
          <UserResearchProgramsResponse
            key={urpr.id}
            userResponse={urpr}
            researchProgram={urpr.tenant_research_program.research_program}
          />
        ))(user_research_programs_responses)}
      </Accordion>
    </Fragment>
  )
}

export const ResearchDataSharingPageLoader = () => {
  const { t: translation } = useTranslation('shared')

  return (
    <SuspenseLoader message={translation('researchdatasharingpage')}>
      <ResearchDataSharingPage />
    </SuspenseLoader>
  )
}
