// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const ContinuityIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 21 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M15.6444,18.9823558 C13.9580626,18.5389379 12.7105,17.0016529 12.7105,15.1781 C12.7105,13.0091 14.4755,11.2441 16.6445,11.2441 C18.8135,11.2441 20.5775,13.0091 20.5775,15.1781 C20.5775,17.0017005 19.3305792,18.5390181 17.6444,18.9823905 L17.6444,19.089 C17.6444,21.797 15.4414,24 12.7334,24 L9.8004,24 C7.0924,24 4.8894,21.797 4.8894,19.089 L4.8894,13.1893637 C0.000200000009,12.6177434 0.000200000009,7.79299596 0.000200000009,5.4001 C0.000200000009,1.1281 2.2532,0.0001 3.4442,0.0001 C3.9922,0.0001 4.4262,0.4401 4.4322,0.9881 C4.4392,1.5361 3.9932,1.9871 3.4452,2.0001 C3.1392,2.0071 2.0002,2.6051 2.0002,5.4001 C2.0002,9.27086739 2.59957839,11.2397802 5.87589827,11.2450892 C5.88039179,11.2450298 5.88489243,11.245 5.8894,11.245 C9.1764,11.245 9.7774,9.276 9.7774,5.4 C9.7774,2.605 8.6384,2.007 8.3334,2 C7.7854,1.987 7.3384,1.536 7.3454,0.988 C7.3514,0.44 7.7854,0 8.3334,0 C9.5254,0 11.7774,1.128 11.7774,5.4 C11.7774,7.793 11.7774,12.617 6.8894,13.188 L6.8894,19.089 C6.8894,20.694 8.1954,22 9.8004,22 L12.7334,22 C14.3384,22 15.6444,20.694 15.6444,19.089 L15.6444,18.9823558 Z M16.6445,13.2441 C15.5785,13.2441 14.7105,14.1111 14.7105,15.1781 C14.7105,16.2441 15.5785,17.1111 16.6445,17.1111 C17.7105,17.1111 18.5775,16.2441 18.5775,15.1781 C18.5775,14.1111 17.7105,13.2441 16.6445,13.2441 Z M17.6445,15.1777 C17.6445,15.7297 17.1965,16.1777 16.6445,16.1777 C16.0925,16.1777 15.6445,15.7297 15.6445,15.1777 C15.6445,14.6257 16.0925,14.1777 16.6445,14.1777 C17.1965,14.1777 17.6445,14.6257 17.6445,15.1777" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default ContinuityIcon
