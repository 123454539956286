/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserResource_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsCareOptionsList_viewer$ref: FragmentReference;
declare export opaque type ScoreCardsCareOptionsList_viewer$fragmentType: ScoreCardsCareOptionsList_viewer$ref;
export type ScoreCardsCareOptionsList_viewer = {|
  +$fragmentRefs: UserResource_viewer$ref,
  +$refType: ScoreCardsCareOptionsList_viewer$ref,
|};
export type ScoreCardsCareOptionsList_viewer$data = ScoreCardsCareOptionsList_viewer;
export type ScoreCardsCareOptionsList_viewer$key = {
  +$data?: ScoreCardsCareOptionsList_viewer$data,
  +$fragmentRefs: ScoreCardsCareOptionsList_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsCareOptionsList_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserResource_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '648006a19bc070a88939b50721f2bc24';

module.exports = node;
