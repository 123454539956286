// @flow

import React, { type Node, Fragment } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'

import Heading from 'react-ui/components/Heading'
import Card from 'shared/ui/Layout/Card/Card'
import { Circle } from 'shared/ui/Shapes/Circle'
import { Tick } from 'shared/ui/Typography/Icons'
import { Button } from 'care-ui'

import styleRules from '../../styles'
import OnboardingTemplate from '../OnboardingTemplate'

import { type FelaRulesType } from 'react-ui/typing'

type PropsType = {
  handleQuestionsSubmit?: (event?: SyntheticEvent<any>) => any,
  rules: FelaRulesType,
  styles?: { [className: string]: string },
  userName: Node,
}

const OnboardingFinished = (props: PropsType) => {
  const { handleQuestionsSubmit, rules, styles = {}, userName } = props

  const { t: translation } = useTranslation('onboarding')

  return (
    <OnboardingTemplate
      pageTitle={
        <Fragment>
          {translation('great_job')} {userName}!
        </Fragment>
      }
      pageContent={translation(
        'remember_you_can_edit_your_information_at_any_time_from_the_profile_settings_in_your_main_menu',
      )}
    >
      <Card className={styles.cardContainer}>
        <br />

        <Circle
          fill="green"
          alignment="none"
          size="xl"
          className={styles.circleFinished}
        >
          <Tick size="xl" color="white" />
        </Circle>

        <Heading level={3} extend={rules.cardTitleFinished}>
          {translation('your_profile')}
        </Heading>

        <div className={styles.cardContentFinished}>
          <p>{translation('completed')}</p>
        </div>

        <Button variant="primary" onClick={handleQuestionsSubmit}>
          {translation('continue')}
        </Button>
      </Card>
    </OnboardingTemplate>
  )
}

export default connect(styleRules)(OnboardingFinished)
