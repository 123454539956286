// @flow

import React from 'react'
import merge from 'lodash/fp/merge'

import { PureAvatar } from '../Avatar'

import type { FelaOptionalPropsType } from 'react-ui/typing'

const DashedAvatar = ({
  extend = () => ({}),
  ...props
}: FelaOptionalPropsType) => {
  const mergedExtend = (...args) =>
    merge(
      {
        Avatar: {
          backgroundColor: 'white',
          borderColor: '#5B6C84',
          borderStyle: 'dashed',
          borderWidth: '3px',
          color: '#5B6C84',
        },
      },
      extend(...args),
    )

  return <PureAvatar {...props} extend={mergedExtend} />
}

export default DashedAvatar
