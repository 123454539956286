// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import omit from 'lodash/fp/omit'

import MinorArrowRight from 'react-ui/assets/icons/minor-arrow-right.svg'
import Icon from 'react-ui/components/Icon'
import { felaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ active = false, theme }) => {
  const fontSize = active ? 'bodyLgBold' : 'bodyLg'
  return {
    MenuItem: {
      alignItems: 'center',
      backgroundColor: 'transparent',
      border: 'none',
      className: 'MenuItem',
      cursor: 'pointer',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      maxWidth: '100%',
      marginTop: 0,
      marginBottom: theme.spacing(1),
      paddingLeft: 0,
      paddingRight: 0,
      textAlign: 'left',
      textDecoration: 'none',
      color: theme.care.palette.text.positive,
      ...theme.care.typography.desktop[fontSize],

      ':active': {
        color: theme.care.palette.text.positive,
      },
      ':focus': {
        color: theme.care.palette.text.positive,
      },
      ':hover': {
        color: theme.care.palette.text.subtitle,
      },
    },

    icon: {
      className: 'MenuItem__icon',
      color: 'inherit',
      lineHeight: theme.spacing(1),
      marginLeft: '1em',
    },
  }
}

type PropTypes = FelaPropsType & {
  as: string,
  children?: React.Node,
  external?: boolean,
  label: React.Node,
}

const MenuItem = ({
  as = 'button',
  children,
  label,
  styles,
  external,
  ...props
}: PropTypes) => {
  let Component = as
  let additionalProps = {}
  if (external) {
    Component = 'a'
    additionalProps = {
      rel: 'external',
      target: '_blank',
    }
  }
  return (
    <Component
      className={styles.MenuItem}
      {...omit(['active', ...felaProps])(props)}
      {...additionalProps}
    >
      {label}
      {!!React.Children.count(children) && (
        <span className={styles.icon}>
          <Icon as={MinorArrowRight} scale={0.57142857} />
        </span>
      )}
    </Component>
  )
}

const enhance = connect(styleRules)

export default enhance(MenuItem)
