/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthCard_user_trackable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthPriorityList_user_trackables$ref: FragmentReference;
declare export opaque type HealthPriorityList_user_trackables$fragmentType: HealthPriorityList_user_trackables$ref;
export type HealthPriorityList_user_trackables = $ReadOnlyArray<{|
  +$fragmentRefs: HealthCard_user_trackable$ref,
  +$refType: HealthPriorityList_user_trackables$ref,
|}>;
export type HealthPriorityList_user_trackables$data = HealthPriorityList_user_trackables;
export type HealthPriorityList_user_trackables$key = $ReadOnlyArray<{
  +$data?: HealthPriorityList_user_trackables$data,
  +$fragmentRefs: HealthPriorityList_user_trackables$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "HealthPriorityList_user_trackables",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthCard_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8cd958d59f90d8de3e426db1ea024d26';

module.exports = node;
