// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import Section from 'react-ui/components/Section'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  center?: boolean,
  children: React.Node,
  color?: string,
  noPadding?: boolean,
}

const styleRules = ({ theme }) => ({
  Footer: {
    width: '100%',
  },
  ChildrenWrapper: {
    [theme.breakpoints.queries.xsOnly]: {
      flexDirection: 'column',
    },
    display: 'flex',
    padding: `calc(${theme.Grid.gutter}) 0`,
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    maxWidth: 'unset',
    width: '100%',
  },
})

const Footer = ({ color, children, styles, center, noPadding }: PropsType) => (
  <footer className={styles.Footer}>
    <Section color={color} center={center} noPadding={noPadding}>
      <div className={styles.ChildrenWrapper}>{children}</div>
    </Section>
  </footer>
)

export default connect(styleRules)(Footer)
