// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
  viewOnlyMode?: boolean,
}

export const widgetStyle = (props: StyleProps) => {
  const { theme, viewOnlyMode } = props

  return {
    cardDefaults: {
      margin: `${theme.care.spacing.lg} 0 0 0`,
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      width: '100%',
      boxShadow: 'none',
      border: '1px solid #DDD',
      [theme.breakpoints.queries.xsOnly]: {
        flexWrap: 'wrap',
      },
    },
    logoContainer: {
      flex: '25%',
      lineHeight: '0',
      [theme.breakpoints.queries.xsOnly]: {
        marginBottom: '1rem',
        flex: '30%',
        textAlign: 'center',
      },
    },
    logo: {
      height: 'auto',
      width: '11rem',
      [theme.breakpoints.queries.xsOnly]: {
        width: '100%',
        maxWidth: '7rem',
      },
    },
    connectButton: {
      flex: '25%',
      [theme.breakpoints.queries.xsOnly]: {
        marginBottom: '1rem',
        flex: '50%',
        textAlign: 'center',
      },
    },
    clickable: {
      margin: 'auto',
      color: viewOnlyMode && 'black',
      pointerEvents: viewOnlyMode && 'none',
    },
    text: {
      flex: '50%',
      ...theme.care.typography.desktop.bodyMd,
      [theme.breakpoints.queries.xsOnly]: {
        textAlign: 'center',
      },
    },
    modal: {
      maxWidth: '800px',
    },
  }
}
