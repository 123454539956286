// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./images/puff.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./images/loadingText.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_2___ = new URL("./images/contented_cloud.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_3___ = new URL("./images/innowell_logo_colour.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_4___ = new URL("./images/innowell_logo_navy.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_5___ = new URL("./images/innowell_swirl.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_6___ = new URL("./images/innowell_icon.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_7___ = new URL("./images/onboarding_video.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
var ___CSS_LOADER_URL_REPLACEMENT_6___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_6___);
var ___CSS_LOADER_URL_REPLACEMENT_7___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_7___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `*,*::before,*::after{box-sizing:border-box;scrollbar-width:thin}html,.Global__root___IILdI{height:100%;font-size:14px;line-height:1.25}body{height:100%;margin:0 auto;font-family:Raleway,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";color:#3a4656}body a{text-decoration:none}body button{font-family:inherit}.Global__puff-spinner___ZR7Bh{background:url(${___CSS_LOADER_URL_REPLACEMENT_0___})}.Global__loading-text___Z2Sia{background:url(${___CSS_LOADER_URL_REPLACEMENT_1___})}.Global__contented_cloud___Nr3bS{background:url(${___CSS_LOADER_URL_REPLACEMENT_2___})}.Global__innowell_logo_colour___iRzJ7{background:url(${___CSS_LOADER_URL_REPLACEMENT_3___})}.Global__innowell_logo_navy___mpWMP{background:url(${___CSS_LOADER_URL_REPLACEMENT_4___})}.Global__innowell_swirl___RyJqp{background:url(${___CSS_LOADER_URL_REPLACEMENT_5___})}.Global__innowell_icon___ri5xr{background:url(${___CSS_LOADER_URL_REPLACEMENT_6___})}.Global__onboarding_video___oAp3c{background:url(${___CSS_LOADER_URL_REPLACEMENT_7___})}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `Global__root___IILdI`,
	"puff-spinner": `Global__puff-spinner___ZR7Bh`,
	"loading-text": `Global__loading-text___Z2Sia`,
	"contented_cloud": `Global__contented_cloud___Nr3bS`,
	"innowell_logo_colour": `Global__innowell_logo_colour___iRzJ7`,
	"innowell_logo_navy": `Global__innowell_logo_navy___mpWMP`,
	"innowell_swirl": `Global__innowell_swirl___RyJqp`,
	"innowell_icon": `Global__innowell_icon___ri5xr`,
	"onboarding_video": `Global__onboarding_video___oAp3c`
};
export default ___CSS_LOADER_EXPORT___;
