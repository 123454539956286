// @flow

import { graphql } from 'react-relay'

export const TrackableDropdownUser = graphql`
  fragment TrackableDropdown_user on User {
    id
    summaryUserTrackables: user_trackables(kinds: [gauge]) {
      nodes {
        trackable {
          id
          label
        }
      }
    }
  }
`
