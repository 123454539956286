// @flow

import React, { type Node } from 'react'

import { getServerReleaseId } from 'shared/services/api/info'
import Sentry from 'shared/services/sentry'

type WarningFunctionAsChildPropsType = {
  outOfSync: boolean,
}

type PropsType = {
  children: WarningFunctionAsChildPropsType => Node,
  localVersion: ?string,
}

type StateType = {
  serverVersion: ?string,
}

class ReleaseWarningWrapper extends React.Component<PropsType, StateType> {
  refresh: IntervalID

  constructor(props: PropsType) {
    super()
    this.state = this.getCleanState(props)
  }

  componentDidMount() {
    this.refresh = setInterval(() => {
      this.checkServerVersion()
    }, 1000 * 60 * 5 /* 5 mins between checks */)
  }

  componentWillUnmount() {
    clearInterval(this.refresh)
  }

  getCleanState(props: PropsType) {
    return { serverVersion: props.localVersion }
  }

  checkServerVersion() {
    getServerReleaseId({
      onSuccess: response => {
        response
          .json()
          .then(body => {
            this.setState({ serverVersion: body.release })
            return body
          })
          .catch(Sentry.captureException)
      },
    })
  }

  render() {
    const { props: { children, localVersion }, state: { serverVersion } } = this

    return children({
      outOfSync: !!localVersion && localVersion !== serverVersion,
    })
  }
}

export default ReleaseWarningWrapper
