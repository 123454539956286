// @flow

import { graphql } from 'react-relay'

export const staffEscalationsTableDataGridQuery = {
  escalations: graphql`
    fragment StaffEscalationsTableDataGrid_escalations on Escalation
      @relay(plural: true) {
      created_at
      user {
        ...UserActivityCardLoader_user
        ...DataGridRowFooterContentLoader_user
        legal_name
        linked_individual {
          ...IndividualUserAdditionalInformationCardLoader_individual
        }
      }
      label
      resolving_role {
        ... on RoleInterface {
          id
          user {
            name
          }
        }
      }
      resolving_resource {
        label
      }
      resolved_at
      role {
        ... on IndividualRole {
          clinicians {
            id
            user {
              id
              legal_name
            }
          }
        }
      }
      resolution_option
      resolution_text
    }
  `,
}
