// @flow

import React, { useEffect } from 'react'
import { connect } from 'react-fela'
import useRouter from 'found/useRouter'

import Cross from 'react-ui/assets/icons/cross.svg'
import Heading from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import Spacer from 'react-ui/components/Spacer'
import { Button } from 'care-ui'

type PropsType = {
  analytics: {
    onClickEvent: () => void,
    onLoadEvent: () => void,
  },
  buttonTitle: string,
  componentId?: string,
  description: string,
  onRequestClose?: () => void,
  questionnaire: { +id: string },
  styles: any,
  title: string,
}

const styleRules = ({ theme }) => ({
  BannerWrapper: {
    backgroundColor: theme.bodyBackgroundColor,
    borderColor: theme.palette.dividerColor,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderRadius: '5px',
    paddingLeft: theme.care.spacing.lg,
    paddingRight: theme.care.spacing.lg,
  },
  description: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 0,
    className: 'Modal__closeButton',
    cursor: 'pointer',
  },
})
const WarningBannerModal = ({
  analytics: { onClickEvent, onLoadEvent },
  onRequestClose,
  questionnaire: { id: questionnaireId },
  title,
  description,
  buttonTitle,
  styles,
}: PropsType) => {
  useEffect(onLoadEvent, [])

  const { router: { push } } = useRouter()
  return (
    <div
      className={styles.BannerWrapper}
      data-testid="QuestionnaireWarningBanner"
    >
      <div className={styles.description}>
        <Heading level={3}>{title}</Heading>
        <button
          type="button"
          className={styles.closeButton}
          onClick={onRequestClose}
          componentid="Button_close"
          data-testid="CloseButton"
        >
          <Icon as={Cross} scale={1} />
        </button>
      </div>
      <div className={styles.description}>
        <div>
          <p>{description}</p>
        </div>
        <Button
          variant="primary"
          dataTestId="ContinueButton"
          onClick={() => {
            onClickEvent()
            push({
              name: 'loadQuestionnaire',
              params: { questionnaireId },
            })
          }}
        >
          {buttonTitle}
        </Button>
      </div>
      <Spacer axis="vertical" units={0.5} />
    </div>
  )
}

export default connect(styleRules)(WarningBannerModal)
