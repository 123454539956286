/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StratifiedScoringResultAnswers_answers$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StratifiedScoringResultHistory_stratified_scoring_result$ref: FragmentReference;
declare export opaque type StratifiedScoringResultHistory_stratified_scoring_result$fragmentType: StratifiedScoringResultHistory_stratified_scoring_result$ref;
export type StratifiedScoringResultHistory_stratified_scoring_result = {|
  +status: string,
  +completed_at: ?any,
  +answer_sets: $ReadOnlyArray<{|
    +id: string,
    +rating: ?string,
    +health_domain: ?{|
      +label: string
    |},
    +question_set: {|
      +id: string,
      +label: string,
      +name: string,
    |},
    +answers: $ReadOnlyArray<{|
      +$fragmentRefs: StratifiedScoringResultAnswers_answers$ref
    |}>,
  |}>,
  +$refType: StratifiedScoringResultHistory_stratified_scoring_result$ref,
|};
export type StratifiedScoringResultHistory_stratified_scoring_result$data = StratifiedScoringResultHistory_stratified_scoring_result;
export type StratifiedScoringResultHistory_stratified_scoring_result$key = {
  +$data?: StratifiedScoringResultHistory_stratified_scoring_result$data,
  +$fragmentRefs: StratifiedScoringResultHistory_stratified_scoring_result$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StratifiedScoringResultHistory_stratified_scoring_result",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerSet",
      "kind": "LinkedField",
      "name": "answer_sets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "HealthDomainType",
          "kind": "LinkedField",
          "name": "health_domain",
          "plural": false,
          "selections": [
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionSet",
          "kind": "LinkedField",
          "name": "question_set",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "answers",
          "plural": true,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "StratifiedScoringResultAnswers_answers"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StratifiedScoringResult",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '94520f301730a6c6676a79a9aace8663';

module.exports = node;
