// @flow

import { type ThemeType } from 'care-ui/atoms'

type PropsType = {
  theme: ThemeType,
}

export const wrapperStyle = () => ({
  margin: '2rem auto',
})

export const alertStyle = () => ({
  margin: '0 auto',
  display: 'flex',
})

export const iconStyle = (props: PropsType) => {
  const { theme } = props

  return {
    marginRight: theme.care.spacing.xxs,
    display: 'flex',
    alignItems: 'center',
  }
} 