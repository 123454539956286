// @flow

import { graphql } from 'react-relay'

export const assessmentRequestsComponentAssessmentRequest = {
  assessment_request: graphql`
    fragment AssessmentRequestsComponent_assessment_request on AssessmentRequest {
      id
      sent_at
      requestee {
        legal_name
      }
      questionnaire_answer_sets {
        id
        status
        completed_at
        rating
        questionnaire {
          id
          label
        }
        answers {
          ...AssessmentRequestsQuestionnaire_answers
        }
        answer_sets {
          id
          question_set {
            id
            label
            name
          }
          answers {
            ...AssessmentRequestsQuestionnaire_answers
          }
        }
      }
      questionnaires {
        id
        label
      }
    }
  `,
}
