// @flow

export const styleRules = ({ theme }: any) => {
  const responsivePadding = {
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    [theme.breakpoints.queries.md]: {
      paddingLeft: '100px',
      paddingRight: '100px',
    },
  }

  return {
    Modal: {
      maxWidth: '100%',
      width: '600px',
    },
    FormRow: {
      justifyContent: 'center',
      ...responsivePadding,
    },
    Heading: {
      maxWidth: '700px',
      textAlign: 'left',
      ...responsivePadding,
    },
  }
}
