// @flow

import React, { type Node, useEffect, useRef, useState } from 'react'
import { useFela } from 'react-fela'

type PropsType = {
  children: Node,
  left?: number,
  stickyComponent?: Node,
  top?: number,
  topOffset?: number,
}

const stickyWrapper = () => ({
  position: 'relative',
})

const stickyInner = ({ top, left }) => ({
  position: 'fixed',
  transform: 'translateZ(0)',
  zIndex: '1',
  top,
  left,
})

const stickyClone = isSticky => ({
  display: 'none',
  ...(isSticky ? { display: 'block' } : {}),
})

const Sticky = (props: PropsType) => {
  const { children, left = 0, stickyComponent, top = 0, topOffset = 0 } = props

  const [isSticky, setSticky] = useState(false)
  const ref = useRef(null)

  const { css } = useFela({ top, left })

  const handleScroll = () => {
    if (ref && ref.current && ref.current.getBoundingClientRect()) {
      setSticky(ref.current.getBoundingClientRect().top <= top - topOffset)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', () => handleScroll)
    }
  }, [])

  const renderChildren = () => {
    if (stickyComponent) {
      return isSticky ? stickyComponent : children
    }

    return children
  }

  return (
    <div className={css(stickyWrapper)} ref={ref}>
      <div className={isSticky ? css(stickyInner) : ''}>{renderChildren()}</div>
      <div className={css(stickyClone(isSticky))}>{children}</div>
    </div>
  )
}

export default Sticky
