// @flow

/**
 * Wraps a browser Storage API with exception handling to avoid blowing up when
 * the browser's storage quota is full.
 * Also uses JSON to serialize/deserialize data to maintain data types.
 */
function wrapStorage(storageType) {
  let realStorage
  try {
    // Safari doesn't allow even testing the existence of localStorage when all
    // cookies are blocked, instead it throws a SecurityError.
    realStorage = window[storageType]
  } catch (e) {
    return {
      getItem(_key: string, defaultValue: any = null) {
        return defaultValue
      },
      // eslint-disable-next-line lodash-fp/prefer-constant
      setItem(_key: string, _value: any) {
        return false
      },
      // eslint-disable-next-line lodash-fp/prefer-constant
      removeItem(_key: string) {
        return false
      },
    }
  }

  return {
    getItem(key: string, defaultValue: any = null) {
      const value = realStorage.getItem(key)
      if (value == null) {
        return defaultValue
      }
      try {
        return JSON.parse(value)
      } catch (e) {
        return defaultValue
      }
    },

    setItem(key: string, value: any) {
      const storageValue = JSON.stringify(value)
      try {
        realStorage.setItem(key, storageValue)
        return true
      } catch (e) {
        return false
      }
    },

    removeItem(key: string) {
      try {
        realStorage.removeItem(key)
        return true
      } catch (e) {
        return false
      }
    },
  }
}

export const jsonLocalStorage = wrapStorage('localStorage')
export const jsonSessionStorage = wrapStorage('sessionStorage')
