// @flow

export const containerStyle = () => ({
  container: {
    width: '450px',
    ':before': {
      display: 'none',
    },
  },
  tether: {
    minHeight: '400px',
  },
  scrollPane: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
})

export const listContainerStyle = () => ({
  maxHeight: '346px',
  overflowY: 'auto',
  marginTop: '5px',
  overflowX: 'hidden',
})
