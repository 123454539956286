// @flow

const AboriginalAndTorresStraitParagraphs = [
  {
    id: '1',
    text: `
  Innowell acknowledges the Traditional Custodians of the land throughout
  Australia, and recognises their continuing connection to land, water and
  community. We pay our respects to all Aboriginal and Torres Strait
  Islander Peoples, their culture, diversity, history and knowledge. We pay
  our respects to the Elders past, present and emerging.
  `,
  },
]

export default AboriginalAndTorresStraitParagraphs
