// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const AccessibilityIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M2,22.0001628 L23,22.0001628 C23.5522847,22.0001628 24,22.447878 24,23.0001628 C24,23.5524475 23.5522847,24.0001628 23,24.0001628 L1,24.0001628 C0.44771525,24.0001628 0,23.5524475 0,23.0001628 L0,1.00016278 C0,0.447878034 0.44771525,0.000162784149 1,0.000162784149 C1.55228475,0.000162784149 2,0.447878034 2,1.00016278 L2,22.0001628 Z M7.32504417,17.1668883 L5.8479983,19.5301617 C5.55528797,19.9984983 4.93833759,20.1408714 4.47000106,19.8481611 C4.00166453,19.5554508 3.85929136,18.9385004 4.1520017,18.4701638 L6.4020017,14.8701638 C6.77428742,14.2745067 7.62854362,14.2382209 8.05,14.8001628 L8.50767201,15.4103921 L13.1425071,7.68566703 C13.5078957,7.07668606 14.3738874,7.03201258 14.8,7.60016278 L16.5956667,9.99438503 L22.1362211,0.496291759 C22.4145014,0.0192398444 23.0268191,-0.1418964 23.503871,0.136383883 C23.9809229,0.414664167 24.1420592,1.0269819 23.8637789,1.50403381 L17.5637789,12.3040338 C17.2032698,12.9220494 16.3292874,12.9725459 15.9,12.4001628 L14.092328,9.98993344 L9.45749293,17.7146585 C9.09210434,18.3236395 8.22611265,18.368313 7.8,17.8001628 L7.32504417,17.1668883 Z" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default AccessibilityIcon
