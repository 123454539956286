// @flow

import React, { type Node, useContext } from 'react'

type PropsType = {
  assessmentRequestId: string,
  children: Node,
  notificationState: boolean,
}

const AssessmentNotificationContext: any = React.createContext({})
export const AssessmentNotificationContextConsumer =
  AssessmentNotificationContext.Consumer

export const useAssessmentNotification = () => {
  const notificationState = useContext(AssessmentNotificationContext)

  return notificationState
}

export const AssessmentNotificationContextProvider = ({
  children,
  notificationState: notify,
  assessmentRequestId,
}: PropsType) => (
  <AssessmentNotificationContext.Provider
    value={{
      notify,
      assessmentRequestId,
    }}
  >
    {children}
  </AssessmentNotificationContext.Provider>
)

export default AssessmentNotificationContext
