// @flow

import React, { type Node } from 'react'

import { WithAuthWrapper } from 'components/hoc/WithAuthWrapper'
import { VerticalFlex } from 'shared/ui/Layout'

type PropsType = {
  children: Node,
}

const RootAdminPageBase = (props: PropsType): Node => {
  const { children } = props

  return <VerticalFlex>{children}</VerticalFlex>
}

export const RootAdminPage = WithAuthWrapper(RootAdminPageBase, {
  requiredPolicies: ['CAN_VIEW_ADMIN_NAMESPACE'],
})
