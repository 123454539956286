// @flow

import React from 'react'

import PopOver from 'react-ui/components/PopOver'

import PopOverDoneFooter from './PopOverDoneFooter'
import PopOverSearchContents from './PopOverSearchContents'

type PropsType = {
  children: ({ [searchResult: string]: Object }) => any,
  data: Array<Object> | $ReadOnlyArray<Object>,
  searchFields: string[],
  styles?: { [className: string]: string },
}

const defaultAttachmentProps = {
  attachment: 'top left',
  targetAttachment: 'bottom right',
  offset: '-10px 45px',
}

const PopOverWithSearch = ({
  data,
  searchFields,
  children,
  ...props
}: PropsType) => (
  <PopOver
    renderFooter={({ onToggleOpen }) => (
      <PopOverDoneFooter onToggleOpen={onToggleOpen} />
    )}
    {...defaultAttachmentProps}
    {...(props: any)}
  >
    <PopOverSearchContents data={data} fields={searchFields}>
      {children}
    </PopOverSearchContents>
  </PopOver>
)

export default PopOverWithSearch
