/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserInviteForm_tenants$ref: FragmentReference;
declare export opaque type UserInviteForm_tenants$fragmentType: UserInviteForm_tenants$ref;
export type UserInviteForm_tenants = $ReadOnlyArray<{|
  +id: string,
  +name: string,
  +deactivated: boolean,
  +emr_provider_id: ?string,
  +emr_integration: ?{|
    +emr_provider: {|
      +name: string,
      +user_id_field_name: string,
    |},
    +required: boolean,
  |},
  +allowAdminClinicalAssignments: boolean,
  +$refType: UserInviteForm_tenants$ref,
|}>;
export type UserInviteForm_tenants$data = UserInviteForm_tenants;
export type UserInviteForm_tenants$key = $ReadOnlyArray<{
  +$data?: UserInviteForm_tenants$data,
  +$fragmentRefs: UserInviteForm_tenants$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "UserInviteForm_tenants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "deactivated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emr_provider_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EmrIntegrationType",
      "kind": "LinkedField",
      "name": "emr_integration",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmrProvider",
          "kind": "LinkedField",
          "name": "emr_provider",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "user_id_field_name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "allowAdminClinicalAssignments",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '329228891b4feb93a9f76b0a8dd46ab0';

module.exports = node;
