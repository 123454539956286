// @flow

import React, { type Node } from 'react'

import Banner from 'react-ui/components/Banner'
import pageReload from 'services/pageReload'

type PropsType = {
  children: Node,
}

const SessionTimeoutWarningBanner = ({ children }: PropsType) => (
  <Banner onClick={pageReload}>{children}</Banner>
)

export default SessionTimeoutWarningBanner
