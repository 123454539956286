/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HealthHistoryPage_user$ref = any;
export type HealthHistoryPage_QueryVariables = {||};
export type HealthHistoryPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +$fragmentRefs: HealthHistoryPage_user$ref
    |}
  |}
|};
export type HealthHistoryPage_Query = {|
  variables: HealthHistoryPage_QueryVariables,
  response: HealthHistoryPage_QueryResponse,
|};
*/


/*
query HealthHistoryPage_Query {
  viewer {
    currentUser {
      ...HealthHistoryPage_user
      id
    }
    id
  }
}

fragment AnswerSet_answer_set on AnswerSet {
  answers {
    __typename
    ...AnswerTable_answers
    ... on AnswerImperialLength {
      id
    }
    ... on AnswerInteger {
      id
    }
    ... on AnswerMultichoice {
      id
    }
    ... on AnswerMultiselect {
      id
    }
    ... on AnswerString {
      id
    }
  }
  id
  submitted_at
}

fragment AnswerTable_answers on AnswerInterface {
  __isAnswerInterface: __typename
  id
  display_value
  question {
    label
    id
  }
}

fragment HealthHistoryPage_user on User {
  ...HealthHistory_user
  escalations {
    ...MyEscalationsTable_escalations
    id
  }
}

fragment HealthHistory_user on User {
  health_history_user_questionnaires_question_sets {
    id
    latest_submitted_non_skipped_answer_set {
      ...AnswerSet_answer_set
      id
    }
    question_set {
      label
      id
    }
  }
}

fragment MyEscalationsTable_escalations on Escalation {
  created_at
  label
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = [
  (v1/*: any*/),
  (v0/*: any*/)
],
v3 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "HealthHistoryPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "HealthHistoryPage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "HealthHistoryPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "UserQuestionnairesQuestionSet",
                "kind": "LinkedField",
                "name": "health_history_user_questionnaires_question_sets",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnswerSet",
                    "kind": "LinkedField",
                    "name": "latest_submitted_non_skipped_answer_set",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "answers",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v0/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "display_value",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Question",
                                "kind": "LinkedField",
                                "name": "question",
                                "plural": false,
                                "selections": (v2/*: any*/),
                                "storageKey": null
                              }
                            ],
                            "type": "AnswerInterface",
                            "abstractKey": "__isAnswerInterface"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "AnswerImperialLength",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "AnswerInteger",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "AnswerMultichoice",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "AnswerMultiselect",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "AnswerString",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v0/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "submitted_at",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuestionSet",
                    "kind": "LinkedField",
                    "name": "question_set",
                    "plural": false,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Escalation",
                "kind": "LinkedField",
                "name": "escalations",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "created_at",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "26e10a9591a836ee4e057626b86386e9",
    "id": null,
    "metadata": {},
    "name": "HealthHistoryPage_Query",
    "operationKind": "query",
    "text": "query HealthHistoryPage_Query {\n  viewer {\n    currentUser {\n      ...HealthHistoryPage_user\n      id\n    }\n    id\n  }\n}\n\nfragment AnswerSet_answer_set on AnswerSet {\n  answers {\n    __typename\n    ...AnswerTable_answers\n    ... on AnswerImperialLength {\n      id\n    }\n    ... on AnswerInteger {\n      id\n    }\n    ... on AnswerMultichoice {\n      id\n    }\n    ... on AnswerMultiselect {\n      id\n    }\n    ... on AnswerString {\n      id\n    }\n  }\n  id\n  submitted_at\n}\n\nfragment AnswerTable_answers on AnswerInterface {\n  __isAnswerInterface: __typename\n  id\n  display_value\n  question {\n    label\n    id\n  }\n}\n\nfragment HealthHistoryPage_user on User {\n  ...HealthHistory_user\n  escalations {\n    ...MyEscalationsTable_escalations\n    id\n  }\n}\n\nfragment HealthHistory_user on User {\n  health_history_user_questionnaires_question_sets {\n    id\n    latest_submitted_non_skipped_answer_set {\n      ...AnswerSet_answer_set\n      id\n    }\n    question_set {\n      label\n      id\n    }\n  }\n}\n\nfragment MyEscalationsTable_escalations on Escalation {\n  created_at\n  label\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3f986197c1b7766b90a33f34fe2da184';

module.exports = node;
