/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserSettingsUserFieldPage_usernameUniqueCheck_QueryVariables = {|
  testUsername: string
|};
export type UserSettingsUserFieldPage_usernameUniqueCheck_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +usernameUniqueness: boolean
    |}
  |}
|};
export type UserSettingsUserFieldPage_usernameUniqueCheck_Query = {|
  variables: UserSettingsUserFieldPage_usernameUniqueCheck_QueryVariables,
  response: UserSettingsUserFieldPage_usernameUniqueCheck_QueryResponse,
|};
*/


/*
query UserSettingsUserFieldPage_usernameUniqueCheck_Query(
  $testUsername: String!
) {
  viewer {
    currentUser {
      usernameUniqueness(testUsername: $testUsername)
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "testUsername"
  }
],
v1 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "testUsername",
      "variableName": "testUsername"
    }
  ],
  "kind": "ScalarField",
  "name": "usernameUniqueness",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserSettingsUserFieldPage_usernameUniqueCheck_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserSettingsUserFieldPage_usernameUniqueCheck_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "e6c45a2e04986c54ed8201993944bb18",
    "id": null,
    "metadata": {},
    "name": "UserSettingsUserFieldPage_usernameUniqueCheck_Query",
    "operationKind": "query",
    "text": "query UserSettingsUserFieldPage_usernameUniqueCheck_Query(\n  $testUsername: String!\n) {\n  viewer {\n    currentUser {\n      usernameUniqueness(testUsername: $testUsername)\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '24464bddf9e1f7695bbe8cea8573b7f1';

module.exports = node;
