// @flow

/**
 * @function exactlyOne
 * @params{any} args - a list of arguments of any type to be passed in and
 * @return{boolean} - ensures that exactly one not null or undefined value is present in args
 */
export default function exactlyOne(...args: Array<?any>): boolean {
  return args.filter(v => v != null).length === 1
}

/**
 * @function exactlyNoneOrOne
 * @params{any} args - a list of arguments of any type to be passed in and
 * @return{boolean} - ensures that at most one not null or undefined value is present in args
 */
export function exactlyNoneOrOne(...args: Array<?any>): boolean {
  return args.filter(v => v != null).length <= 1
}
