// @flow

import { graphql } from 'react-relay'

export const answerSetAnswerSet = {
  answer_set: graphql`
    fragment AnswerSet_answer_set on AnswerSet {
      answers {
        ...AnswerTable_answers
      }
      id
      submitted_at
    }
  `,
}
