// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import { createFragmentContainer } from 'react-relay'

import { UserResourceLoader } from 'components/UserResource'

import { scoreCardsCareOptionsListUserResources } from '../../queries/ScoreCardsCareOptionsList'

import {
  careOptionsListStyle,
  userResourceStyle,
} from './ScoreCardsCareOptionsList.style'

import type { ScoreCardsCareOptionsList_user_resources } from '../../queries/__generated__/ScoreCardsCareOptionsList_user_resources.graphql'
import type { ScoreCardsCareOptionsList_viewer } from '../../queries/__generated__/ScoreCardsCareOptionsList_viewer.graphql'

type PropsType = {
  componentId?: string,
  user_resources: ScoreCardsCareOptionsList_user_resources,
  viewer: ScoreCardsCareOptionsList_viewer,
}

const ScoreCardsCareOptionsComponent = (props: PropsType) => {
  const { user_resources, viewer } = props
  const { css } = useFela()

  return (
    <div
      className={css(careOptionsListStyle)}
      data-testid="ScoreCardsCareOptionsList"
    >
      {user_resources.map(user_resource => (
        <div className={css(userResourceStyle)} key={user_resource.id}>
          <UserResourceLoader user_resource={user_resource} viewer={viewer} />
        </div>
      ))}
    </div>
  )
}

export const ScoreCardsCareOptionsList = createFragmentContainer(
  ScoreCardsCareOptionsComponent,
  scoreCardsCareOptionsListUserResources,
)
