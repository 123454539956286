// @flow

import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'

import Flash, { FlashContainer } from 'react-ui/components/Flash'
import { type FlashMessageType, removeFlash } from 'redux/generic_ui/actions'
import { obtainContainerElement } from 'shared/services/elements'

type StatePropsType = {|
  messages: $ReadOnlyArray<FlashMessageType>,
|}

type DispatchPropsType = {|
  closeFlash: (id: string) => void,
|}

type PropsType = {| ...StatePropsType, ...DispatchPropsType |}

class FlashMessageContainerBase extends Component<PropsType, {}> {
  node: HTMLElement

  constructor(props: PropsType) {
    super(props)
    this.node = obtainContainerElement('flashes')
  }

  render() {
    const filtered = this.props.messages.filter(
      (value, index, self) =>
        self.findIndex(m => m && value && m.name === value.name) === index,
    )

    return ReactDOM.createPortal(
      <FlashContainer>
        {filtered.map(message => (
          <Flash
            key={message.id}
            id={message.id}
            {...(message: any)}
            onClose={this.props.closeFlash}
          />
        ))}
      </FlashContainer>,
      this.node,
    )
  }
}

const mapStateToProps = ({ genericUi }) => ({
  messages: genericUi.flashMessages,
})

const mapDispatchToProps = dispatch => ({
  closeFlash: id => dispatch(removeFlash(id)),
})

// const FlashMessageContainer = connect<PropsType, {||}, _, _, _, _>(
// $DisableFlow we can't use proper react-redux types (shown above) until we upgrade prettier: https://prettier.io/blog/2019/01/20/1.16.0.html#flow
const FlashMessageContainer = connect(mapStateToProps, mapDispatchToProps)(
  FlashMessageContainerBase,
)

export default FlashMessageContainer
