/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserInviteForm_tenants$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffInvite_staff_admin$ref: FragmentReference;
declare export opaque type StaffInvite_staff_admin$fragmentType: StaffInvite_staff_admin$ref;
export type StaffInvite_staff_admin = {|
  +role_type_options: $ReadOnlyArray<string>,
  +tenants: $ReadOnlyArray<{|
    +id: string,
    +$fragmentRefs: UserInviteForm_tenants$ref,
  |}>,
  +$refType: StaffInvite_staff_admin$ref,
|};
export type StaffInvite_staff_admin$data = StaffInvite_staff_admin;
export type StaffInvite_staff_admin$key = {
  +$data?: StaffInvite_staff_admin$data,
  +$fragmentRefs: StaffInvite_staff_admin$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "query_type"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaffInvite_staff_admin",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "query_type",
          "variableName": "query_type"
        }
      ],
      "kind": "ScalarField",
      "name": "role_type_options",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserInviteForm_tenants"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "StaffAdmin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c5ea406eb82a4529d388e15ccca90d23';

module.exports = node;
