// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import get from 'lodash/get'

import { generateInitials } from 'shared/services/user'

import PureAvatar from './Avatar'

const MappedAvatar = ({ user, ...props }) => {
  const name = get(user, 'legal_name')

  return (
    <PureAvatar
      {...props}
      initials={generateInitials(name, get(user, 'email'))}
      name={name}
      aria-label={name}
    />
  )
}

const AvatarLoader = createFragmentContainer(MappedAvatar, {
  user: graphql`
    fragment AvatarLoader_user on User {
      id
      email
      legal_name
    }
  `,
})

export default AvatarLoader
