// @flow

export const rootElement = (): HTMLElement => {
  const existingApp = document.getElementById('app')

  // This inline style is needed to clean the style used to center the Innowell loading spinner
  const overrideInlineStyle = 'min-height:100%'

  if (existingApp) {
    existingApp.style.cssText = overrideInlineStyle
    return existingApp
  }
  const newApp = document.createElement('div')
  newApp.id = 'app'
  newApp.style.cssText = overrideInlineStyle

  if (!document.body) {
    throw new Error('document.body is required')
  }

  document.body.appendChild(newApp)

  return newApp
}

export const obtainContainerElement = (
  containerID: string,
  className?: string,
): HTMLElement => {
  let elementContainer = document.getElementById(containerID)
  if (!elementContainer) {
    elementContainer = document.createElement('div')
    elementContainer.id = containerID
    if (className) {
      elementContainer.className = className
    }

    if (!document.body) {
      throw new Error('document.body is required')
    }
    document.body.appendChild(elementContainer)
  }

  return elementContainer
}
