// @flow

import React from 'react'
import { connect } from 'react-fela'
import PlacesAutocomplete from 'react-places-autocomplete'

import { formatSuggestionToExtractSuburbAndState } from 'shared/services/google-api-utils'
import { Input } from 'shared/ui/Forms/Fields'

import type { FelaStylesType } from 'react-ui/typing'

type PropsType = {
  handleBlur: Function,
  handleChange?: Function,
  handleSelect?: Function,
  id: string,
  pattern?: string,
  styles: FelaStylesType,
  values: Object,
}

type StateType = {
  value: string,
}

class LocationAutocompleteField extends React.Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)

    this.state = {
      value: props.values.suburb || '',
    }
  }

  /*
    Step 1: Update the state with the component location
    Step 2: Propagate value to parent
   */
  handleChange = (value: string) => {
    const { handleChange, handleSelect } = this.props
    this.setState({
      value,
    })
    if (typeof handleChange === 'function') {
      handleChange(value)
    }
    if (typeof handleSelect === 'function') {
      handleSelect(value)
    }
  }

  /*
    Step 1: Update the state with the component location
    Step 2: Propagate value to parent
   */
  handleSelect = (suggestion: string) => {
    const { handleSelect } = this.props
    const value = formatSuggestionToExtractSuburbAndState(suggestion)
    this.setState({
      value,
    })
    if (typeof handleSelect === 'function') {
      handleSelect(value)
    }
  }

  render() {
    const { value } = this.state
    const { pattern, styles, handleBlur } = this.props
    return (
      <PlacesAutocomplete
        searchOptions={{
          componentRestrictions: { country: 'au' },
          types: ['(cities)'],
        }}
        value={value || ''}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        shouldFetchSuggestions={value.indexOf(',') <= 0 && value.length > 1}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps }) => (
          <div className={styles.LocSearchBarContainer}>
            <div className={styles.LocSearchInputContainer}>
              <Input
                name="suburb"
                {...getInputProps({
                  placeholder: 'Type in a suburb ...',
                })}
                className={styles.LocSearchInput}
                classNameContainer={styles.AutoCompleteInputContainer}
                pattern={pattern || '^\\D{1,}, \\D{2,3}$'}
                title="Location must follow the pattern, e.g Sydney, NSW"
                onBlur={handleBlur}
              />
            </div>
            {suggestions.length > 0 && (
              <div
                className={styles.LocAutocompleteContainer}
                data-component-id="autocomplete"
                data-testid="autocomplete"
              >
                {suggestions.map(suggestion => {
                  const className = suggestion.active
                    ? styles.LocAutoCompleteSuggestedItemActive
                    : styles.LocAutoCompleteSuggestedItem
                  return (
                    <div
                      data-component-id="suggested-locations"
                      data-testid="suggested-locations"
                      {...getSuggestionItemProps(suggestion, {
                        className,
                      })}
                    >
                      <span>{`${suggestion.terms[0].value}, ${
                        suggestion.terms[1].value
                      }`}</span>
                    </div>
                  )
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

const styleRules = ({ theme }) => ({
  LocSearchBarContainer: {
    width: '90%',
    maxWidth: '500px',
  },
  LocSearchInputContainer: {
    position: 'relative',
  },
  LocSearchInput: {
    width: '100%',
    ':focus': {
      display: 'block',
      width: '100%',
      padding: '1rem',
      outline: 'none',
    },
    ':active': {
      display: 'block',
      width: '100%',
      padding: '15px',
      outline: 'none',
    },
  },
  LocAutocompleteContainer: {
    position: 'absolute',
    width: 'inherit',
    border: '1px solid #e7e9ee',
    maxWidth: '500px',
    zIndex: 1,
  },
  LocAutoCompleteSuggestedItem: {
    backgroundColor: '#ffffff',
    cursor: 'pointer',
    padding: '10px 19px',
  },
  LocAutoCompleteSuggestedItemActive: {
    backgroundColor: theme.palette.component.primary.base,
    borderColor: theme.palette.component.primary.base,
    color: theme.palette.component.primary.text,
    cursor: 'pointer',
    padding: '10px 19px',
  },
  AutoCompleteInputContainer: {
    marginBottom: '0px',
  },
})

export type LocationAutocompleteInputProps = PropsType
export default connect(styleRules)(LocationAutocompleteField)
