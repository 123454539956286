// @flow

import React from 'react'

import CheckBox, { type CheckBoxProps as RadioProps } from './CheckBox'

const Icon = props => (
  <svg
    width="60"
    height="60"
    viewBox="0 0 60 60"
    xmlns="http://www.w3.org/2000/svg"
    {...(props: any)}
  >
    <g>
      <ellipse ry="30" rx="30" cy="30" cx="30" />
    </g>
  </svg>
)

export type { RadioProps }

export default (props: RadioProps) => (
  <CheckBox
    activeIcon={Icon}
    extend={() => ({
      activeIcon: {
        className: 'Radio__activeIcon',
        height: '9px',
        left: '6px',
        top: '8px',
        width: '9px',
      },
      label: {
        className: 'Radio__label',
        textAlign: 'initial',
        ':before': { borderRadius: '50%' },
      },
    })}
    {...(props: any)}
  />
)
