/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserNotifications_user$ref = any;
export type UserNotificationsMarkAsReadInput = {|
  clientMutationId?: ?string,
  userId: string,
|};
export type UserNotificationsMarkAsReadMutationVariables = {|
  input: UserNotificationsMarkAsReadInput
|};
export type UserNotificationsMarkAsReadMutationResponse = {|
  +userNotificationsMarkAsRead: ?{|
    +currentUser: {|
      +$fragmentRefs: UserNotifications_user$ref
    |}
  |}
|};
export type UserNotificationsMarkAsReadMutation = {|
  variables: UserNotificationsMarkAsReadMutationVariables,
  response: UserNotificationsMarkAsReadMutationResponse,
|};
*/


/*
mutation UserNotificationsMarkAsReadMutation(
  $input: UserNotificationsMarkAsReadInput!
) {
  userNotificationsMarkAsRead(input: $input) {
    currentUser {
      ...UserNotifications_user
      id
    }
  }
}

fragment UserNotifications_user on User {
  id
  user_notifications(read: false) {
    id
    user_id
    text
    read
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationsMarkAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationsMarkAsReadPayload",
        "kind": "LinkedField",
        "name": "userNotificationsMarkAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserNotifications_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserNotificationsMarkAsReadMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserNotificationsMarkAsReadPayload",
        "kind": "LinkedField",
        "name": "userNotificationsMarkAsRead",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "read",
                    "value": false
                  }
                ],
                "concreteType": "UserNotification",
                "kind": "LinkedField",
                "name": "user_notifications",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "user_id",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "text",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "read",
                    "storageKey": null
                  }
                ],
                "storageKey": "user_notifications(read:false)"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f672c7ddc6982468db75881dbdf6e295",
    "id": null,
    "metadata": {},
    "name": "UserNotificationsMarkAsReadMutation",
    "operationKind": "mutation",
    "text": "mutation UserNotificationsMarkAsReadMutation(\n  $input: UserNotificationsMarkAsReadInput!\n) {\n  userNotificationsMarkAsRead(input: $input) {\n    currentUser {\n      ...UserNotifications_user\n      id\n    }\n  }\n}\n\nfragment UserNotifications_user on User {\n  id\n  user_notifications(read: false) {\n    id\n    user_id\n    text\n    read\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '21d689f83fd8138edbee875ebb40b4fd';

module.exports = node;
