// @flow

import React from 'react'
import {
  createFragmentContainer,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import { useAuthPolicy } from 'platform_web/features/Policies'
import StaffNavigationTabs from 'platform_web/features/StaffNavigationTabs'
import { IndividualsAdminInviteLoader } from 'platform_web/pages/Individual/IndividualsAdminInvite'
import { Container, Divider } from 'care-ui'

import { IndividualsTable } from './components/IndividualsTable'
import {
  IndividualsPageCurrentRoleFragmentQuery,
  IndividualsPageQuery,
  IndividualsPageUserFragmentQuery,
} from './query/IndividualsPage'

import { type IndividualsPage_current_role } from './query/__generated__/IndividualsPage_current_role.graphql'
import type { IndividualsPage_tenant } from './query/__generated__/IndividualsPage_tenant.graphql'
import { type IndividualsPage_user } from './query/__generated__/IndividualsPage_user.graphql'

export type IndividualsPageUser = IndividualsPage_user
export type IndividualsPageTenant = IndividualsPage_tenant

type PropsType = {
  current_role: IndividualsPage_current_role,
  user: IndividualsPage_user,
}

const IndividualsPageBase = ({ current_role, user }: PropsType) => {
  const managerRoles = ['MANAGER', 'ADMIN']
  const { role_type } = current_role

  const inviteButton = managerRoles.includes(role_type) ? (
    <IndividualsAdminInviteLoader current_role={current_role} />
  ) : null

  return (
    <>
      <nav>
        <Container>
          <StaffNavigationTabs inviteButton={inviteButton} />
        </Container>

        <Divider />
      </nav>

      <main>
        <Container>
          <IndividualsTable user={user} />
        </Container>
      </main>
    </>
  )
}

const IndividualsPage = createFragmentContainer(IndividualsPageBase, {
  current_role: IndividualsPageCurrentRoleFragmentQuery,
  user: IndividualsPageUserFragmentQuery,
})

const IndividualsPageLoader = () => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={IndividualsPageQuery}
      variables={{}}
      render={response => {
        return (
          <QueryRendererLoadingIndicator response={response}>
            {({ viewer }) => {
              const { currentUser } = viewer || {}
              const { current_role } = currentUser || {}
              return (
                <IndividualsPage
                  user={currentUser}
                  current_role={current_role}
                />
              )
            }}
          </QueryRendererLoadingIndicator>
        )
      }}
    />
  )
}

export const IndividualsPageWithPolicies = () => {
  const { isAuthorized, isLoading } = useAuthPolicy(['CAN_LIST_INDIVIDUALS'])

  if (isLoading) return null

  return isAuthorized ? <IndividualsPageLoader /> : <UnauthorizedPage />
}
