// @flow

import { useEffect, useRef } from 'react'

export function useDependentToggle(condition: boolean, toggle?: () => void) {
  const didMountRef = useRef(false)

  useEffect(
    () => {
      if (didMountRef.current) {
        if (condition && toggle) {
          toggle()
        }
      } else didMountRef.current = true
    },
    [condition],
  )
}
