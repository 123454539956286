// @flow

/**
 * This is an improved version on the old <ModelSelect /> component, it does not require passing the viewer field to it for use.
 *
 * Preference using this component over the old <ModelSelect />
 */

import React from 'react'
import { QueryRenderer } from 'react-relay'
import { get, omit } from 'lodash/fp'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import Select from 'react-ui/components/Select'

type ModelSelectAdvancedPropsType = {
  blockOptions?: string,
  +environment: Object,
  +field: string,
  +itemsType?: string,
  +pipeline: (models: Array<Object>) => Array<Object>,
  +query: Object,
  valueName?: string,
  +variables?: {
    [key: string]: number | string,
  },
  +viewer?: Object,
}

/**
 * In most cases you'll want to use the ModelSelect2 component which will automatically handle loading the models from relationships on the viewer.
 *
 * For more advanced uses you can use this component and pass it a query and variables to use.
 */
export const ModelSelect2AdvancedLoader = (
  props: ModelSelectAdvancedPropsType,
) => {
  const {
    environment,
    field,
    itemsType,
    query,
    pipeline,
    valueName = 'label',
    variables = {},
    blockOptions,
    ...other
  } = props

  const emptySelector = [{ key: 'None', label: 'None' }]
  return (
    <QueryRenderer
      environment={environment}
      query={query}
      render={response => (
        <QueryRendererLoadingIndicator response={response}>
          {innerProps => {
            const results = pipeline(get(field)(innerProps))
            const cleanedProps = omit(['viewer'])(innerProps)

            return (
              <Select
                items={blockOptions ? emptySelector : pipeline(results)}
                keyName="id"
                valueName={valueName}
                itemsType={itemsType}
                {...(other: any)}
                {...(cleanedProps: any)}
              />
            )
          }}
        </QueryRendererLoadingIndicator>
      )}
      variables={variables}
    />
  )
}
