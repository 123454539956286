// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import DataGrid from 'react-ui/components/DataGrid'
import { timeColumnConfig } from 'react-ui/components/Tables/columns'

import { customStyles } from './MyEscalationsTable.style'
import { query } from './queries/MyEscalationsTable'

import type { MyEscalationsTable_escalations } from './queries/__generated__/MyEscalationsTable_escalations.graphql'

type PropsType = {
  escalations: MyEscalationsTable_escalations,
}

const MyEscalationsTable = (props: PropsType) => {
  const { escalations } = props

  const { t: translation } = useTranslation('shared')

  return (
    <DataGrid
      data={escalations}
      defaultSort={{ key: 'created_at', direction: 1 }}
      columns={['label', 'created_at']}
      extend={customStyles}
      columnConfig={{
        label: {
          label: translation('health_card'),
        },
        created_at: {
          ...timeColumnConfig(false),
          label: translation('date_time'),
        },
      }}
    />
  )
}

export const MyEscalationsTableLoader = createFragmentContainer(
  MyEscalationsTable,
  query,
)
