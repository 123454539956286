// @flow

import { filter, includes, map, reject } from 'lodash/fp'

export const filterRolesTo = (
  individual_assignments: $ReadOnlyArray<{
    +individual: { +id?: string },
  }>,
  individuals: $ReadOnlyArray<?{ +id?: string }>,
  showAssigned: boolean,
) => {
  const assignedIds = map('individual.id')(individual_assignments)

  if (showAssigned) {
    return filter(o => includes(o.id, assignedIds))(individuals)
  }
  return reject(o => includes(o.id, assignedIds))(individuals)
}

export const roleStatusWithUserInviteDelta = (
  role_status: string,
  invitation_delta: ?string,
) =>
  `${role_status}${
    role_status.toLowerCase() === 'invited' && !!invitation_delta
      ? ` (${invitation_delta})`
      : ''
  }`

export const roleStatusWithUserInvitationCreatedAt = (
  role_status: string,
  invitation_created_at: ?Date,
) => {
  const inviteDate = invitation_created_at && new Date(invitation_created_at)
  const day = inviteDate && inviteDate.getDate()
  const month = inviteDate && inviteDate.getMonth() + 1
  const year = inviteDate && inviteDate.getFullYear()

  return role_status.toLowerCase() === 'invited' && !!day && !!month && !!year
    ? `(${day}/${month}/${year})`
    : ''
}
