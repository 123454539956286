// @flow

import get from 'lodash/fp/get'

export type SortFunctionType = (a: {}, b: {}) => number

export const sortByBoolean = (a: boolean, b: boolean): number => {
  if (a === b) {
    return 0
  }
  return a ? -1 : 1
}

const sortByString = (a: string, b: string) => a.localeCompare(b)

const sortBySize = (a: number, b: number) => b - a

export const sortByNumber = sortBySize

export const sortByDate = (a: Date, b: Date) => {
  if (a > b) return -1
  if (b > a) return 1
  return 0
}

export const createSortByString = (path?: string = '') => (a: {}, b: {}) => {
  const valA = get(path, a)
  const valB = get(path, b)

  const valueA = valA ? valA.toString() : ''
  const valueB = valB ? valB.toString() : ''
  return sortByString(valueA, valueB)
}

export const createSortByBoolean = (path: string) => (a: {}, b: {}) =>
  sortByBoolean(get(path, a), get(path, b))

export const buildCombinedSorter = (
  sorters: Array<SortFunctionType>,
): SortFunctionType => (a: {}, b: {}) => {
  for (const sorter of sorters) {
    const result: number = sorter(a, b)
    if (result !== 0) return result
  }
  return 0
}
