/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserNotificationsPage_user$ref: FragmentReference;
declare export opaque type UserNotificationsPage_user$fragmentType: UserNotificationsPage_user$ref;
export type UserNotificationsPage_user = {|
  +notification_preference: {|
    +sms: boolean,
    +id: string,
  |},
  +$refType: UserNotificationsPage_user$ref,
|};
export type UserNotificationsPage_user$data = UserNotificationsPage_user;
export type UserNotificationsPage_user$key = {
  +$data?: UserNotificationsPage_user$data,
  +$fragmentRefs: UserNotificationsPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserNotificationsPage_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "NotificationPreference",
      "kind": "LinkedField",
      "name": "notification_preference",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '00eabb472bce043046222c2cf01f91db';

module.exports = node;
