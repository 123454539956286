// @flow

import { graphql } from 'react-relay'

export const staffDownloadReportButtonIndividual = {
  individual: graphql`
    fragment StaffDownloadReportButton_individual on IndividualRole {
      user {
        id
      }
    }
  `,
}
