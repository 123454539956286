// @flow

import React, { type Node } from 'react'
import cx from 'classnames'

import s from './BaseIcon.scss'

export type IconColorType =
  | 'black'
  | 'danger'
  | 'green'
  | 'darkGreen'
  | 'lightBlue'
  | 'lightGrey'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'white'

export type IconPropsType = {
  addedClassName?: string,
  color?: IconColorType,
  rotation?: 'none' | 'right' | 'down' | 'left',
  shadow?: 'none' | 'primary' | 'secondary',
  size?: 's' | 'ms' | 'm' | 'ml' | 'l' | 'xl',
  translucent?: boolean,
}

export type PropsType = IconPropsType & {
  children: Node,
}

const BaseIcon = ({
  children,
  color = '',
  rotation = 'none',
  size = '',
  addedClassName,
  translucent,
  shadow = 'none',
}: PropsType) => {
  const translucenceClass = translucent ? s.translucent : ''
  return (
    <span
      className={cx(
        s.root,
        s[color],
        s[size],
        s[rotation],
        translucenceClass,
        addedClassName,
        s[`shadow--${shadow}`],
      )}
    >
      {children}
    </span>
  )
}

export default BaseIcon
