// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { graphql } from 'react-relay'

import StaffIndividualSelectionComponent from 'components/Staff/StaffIndividualSelectionComponent'
import { type PageControlsType } from 'react-ui/components/DataGrid'
import { PaginationQueryLoader } from 'react-ui/components/Pagination/PaginationQueryLoader'
import { PaginationSearchControl } from 'react-ui/components/Pagination/PaginationSearchControl'
import { Divider, FlexContainer, RadioButton, ScrollableBox } from 'care-ui'

import type { StaffLeadIndividualSelectionList_tenant } from './__generated__/StaffLeadIndividualSelectionList_tenant.graphql'

export type StaffLeadIndividualSelectionListTenantType = StaffLeadIndividualSelectionList_tenant

type StaffLeadIndividualSelectionListType = {
  count?: number,
  parentId: ?string,
  queryType?: string,
}

const staffLeadIndividualQuery = graphql`
  query StaffLeadIndividualSelectionList_PaginatedQuery(
    $count: Int!
    $cursor: String
    $parentId: ID!
    $search: String
    $assignmentStatus: Boolean
    $sort: [RoleSortable!]
  ) {
    viewer {
      tenant(id: $parentId) {
        ...StaffLeadIndividualSelectionList_tenant
      }
    }
  }
`

const staffLeadIndividualQueryFragments = {
  tenant: graphql`
    fragment StaffLeadIndividualSelectionList_tenant on Tenant {
      id
      roles(
        roleTypes: [INDIVIDUAL]
        assignmentStatus: $assignmentStatus
        first: $count
        after: $cursor
        search: $search
        sort: $sort
        status: "active"
        excludeDischarged: true
      ) @connection(key: "tenantIndividuals_roles", filters: ["searchTerm"]) {
        edges {
          node {
            ...StaffIndividualSelectionList_individual @relay(mask: false)
          }
        }
      }
    }
  `,
}

const sort = { column: 'name', direction: 'ASC' }

const queryVariables = {
  all: {
    assignmentStatus: null,
    status: [],
    sort,
  },
  assigned: {
    assignmentStatus: true,
    status: [],
    sort,
  },
  unassigned: {
    assignmentStatus: false,
    status: [],
    sort,
  },
}

type IndividualsVariablesType = {
  assignmentStatus: true | null,
  sort: { column: string, direction: string, id?: string }[],
  status: string | null,
}

const StaffLeadIndividualSelectionList = ({
  parentId,
  queryType: initialQueryType = 'all',
  count = 25,
}: StaffLeadIndividualSelectionListType) => {
  // Localization
  const { t: translation } = useTranslation('staff')
  const filters: { [key: string]: string }[] = [
    {
      label: translation('all'),
      value: 'all',
    },
    {
      label: translation('assigned'),
      value: 'assigned',
    },
    {
      label: translation('unassigned'),
      value: 'unassigned',
    },
  ]

  const [queryType, setQueryType] = useState(initialQueryType)
  const [selected, setSelected] = useState(filters[0]?.value)

  const tmpVariables = queryVariables[queryType]

  const initVariables: IndividualsVariablesType = {
    ...tmpVariables,
    count,
    parentId,
  }

  const handleUpdate = (e: SyntheticInputEvent<>, updateVariables) => {
    const selectedValue = e.target.value

    setSelected(selectedValue)
    setQueryType(selectedValue)
    updateVariables(queryVariables[selectedValue])
  }

  return (
    <PaginationQueryLoader
      query={staffLeadIndividualQuery}
      fragments={staffLeadIndividualQueryFragments}
      variables={initVariables}
      controls={({ updateVariables }) => (
        <FlexContainer direction="column" wrap="wrap" gap="xs">
          <FlexContainer gap="sm">
            {filters.map(filter => (
              <RadioButton
                inputAttributes={{
                  id: filter.value,
                  name: 'status',
                  value: filter.value,
                  label: filter.label,
                  checked: selected === filter.value,
                  onChange: (e: SyntheticInputEvent<>) =>
                    handleUpdate(e, updateVariables),
                }}
                dataTestId="status"
                ariaLabel={filter.value}
              />
            ))}
          </FlexContainer>

          <PaginationSearchControl fullWidth />

          <Divider />
        </FlexContainer>
      )}
      getFragments={data => ({ tenant: data.viewer.tenant })}
    >
      {({
        relay,
        tenant,
      }: {
        relay: PageControlsType,
        tenant: StaffLeadIndividualSelectionList_tenant,
      }) => {
        return (
          <ScrollableBox maxHeight="250px">
            <StaffIndividualSelectionComponent
              tenant={tenant}
              relay={relay}
              count={count}
            />
          </ScrollableBox>
        )
      }}
    </PaginationQueryLoader>
  )
}

export default StaffLeadIndividualSelectionList
