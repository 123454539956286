/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type StaffEscalationsPage_QueryVariables = {||};
export type StaffEscalationsPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +id: string,
      +tenant: ?{|
        +stage_1_escalation_timeout: ?number,
        +stage_2_escalation_timeout: ?number,
      |},
    |}
  |}
|};
export type StaffEscalationsPage_Query = {|
  variables: StaffEscalationsPage_QueryVariables,
  response: StaffEscalationsPage_QueryResponse,
|};
*/


/*
query StaffEscalationsPage_Query {
  viewer {
    currentUser {
      id
      tenant {
        stage_1_escalation_timeout
        stage_2_escalation_timeout
        id
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage_1_escalation_timeout",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "stage_2_escalation_timeout",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffEscalationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "StaffEscalationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  (v2/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "74c503d3420a87f0f3da3a3f47e4e079",
    "id": null,
    "metadata": {},
    "name": "StaffEscalationsPage_Query",
    "operationKind": "query",
    "text": "query StaffEscalationsPage_Query {\n  viewer {\n    currentUser {\n      id\n      tenant {\n        stage_1_escalation_timeout\n        stage_2_escalation_timeout\n        id\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ecd1ad19bc9063d8b1a5d6abfe77cbba';

module.exports = node;
