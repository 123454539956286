// @flow

import React, { useEffect } from 'react'

import HelpNowCard from 'platform_web/features/Header/HelpNowButton/HelpNowCard'
import { Callout, FlexContainer, Modal, ScrollableBox } from 'care-ui'
import warningIcon from 'care-ui/atoms/icons/alert-info.svg'
import useModal from 'care-ui/molecules/hooks/useModal'

type PropsType = {
  contentLabel: string,
  description: string,
  helpNowProviders: any,
  updateNotification: () => void,
}

const EscalationModal = (props: PropsType) => {
  const {
    contentLabel,
    description,
    helpNowProviders,
    updateNotification,
  } = props

  const { isOpen, openModal, closeModal } = useModal()

  const handleCloseModal = () => {
    closeModal()
    updateNotification()
  }

  useEffect(() => {
    openModal()
  }, [])

  return (
    <Modal
      isOpen={isOpen}
      closeModal={handleCloseModal}
      heading={contentLabel}
      width="780px"
    >
      <ScrollableBox maxHeight="60vh" maxWidth="100%">
        <FlexContainer direction="column" gap="xs">
          <Callout
            calloutText={description}
            variant="danger"
            calloutIcon={warningIcon}
          />

          {helpNowProviders?.map(provider => (
            <HelpNowCard provider={provider} key={provider.slug} />
          ))}
        </FlexContainer>
      </ScrollableBox>
    </Modal>
  )
}

export default EscalationModal
