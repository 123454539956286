/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type DashboardPage_user_assessment_request$ref = any;
export type AssessmentRequestAcknowledgeInput = {|
  assessmentRequestId: string,
  clientMutationId?: ?string,
|};
export type AssessmentRequestAcknowledgeMutationVariables = {|
  input: AssessmentRequestAcknowledgeInput
|};
export type AssessmentRequestAcknowledgeMutationResponse = {|
  +assessmentRequestAcknowledge: ?{|
    +assessment_request: {|
      +id: string
    |},
    +user: {|
      +linked_individual: ?{|
        +$fragmentRefs: DashboardPage_user_assessment_request$ref
      |}
    |},
  |}
|};
export type AssessmentRequestAcknowledgeMutation = {|
  variables: AssessmentRequestAcknowledgeMutationVariables,
  response: AssessmentRequestAcknowledgeMutationResponse,
|};
*/


/*
mutation AssessmentRequestAcknowledgeMutation(
  $input: AssessmentRequestAcknowledgeInput!
) {
  assessmentRequestAcknowledge(input: $input) {
    assessment_request {
      id
    }
    user {
      linked_individual {
        ...DashboardPage_user_assessment_request
        id
      }
      id
    }
  }
}

fragment DashboardPage_user_assessment_request on IndividualRole {
  assessment_requested(first: 1) {
    nodes {
      id
      questionnaire_answer_sets {
        id
      }
      acknowledged
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  (v2/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "AssessmentRequest",
  "kind": "LinkedField",
  "name": "assessment_request",
  "plural": false,
  "selections": (v3/*: any*/),
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AssessmentRequestAcknowledgeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssessmentRequestAcknowledgePayload",
        "kind": "LinkedField",
        "name": "assessmentRequestAcknowledge",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "linked_individual",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "DashboardPage_user_assessment_request"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AssessmentRequestAcknowledgeMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "AssessmentRequestAcknowledgePayload",
        "kind": "LinkedField",
        "name": "assessmentRequestAcknowledge",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "linked_individual",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "first",
                        "value": 1
                      }
                    ],
                    "concreteType": "AssessmentRequestConnection",
                    "kind": "LinkedField",
                    "name": "assessment_requested",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "AssessmentRequest",
                        "kind": "LinkedField",
                        "name": "nodes",
                        "plural": true,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "QuestionnaireAnswerSet",
                            "kind": "LinkedField",
                            "name": "questionnaire_answer_sets",
                            "plural": true,
                            "selections": (v3/*: any*/),
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "acknowledged",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "assessment_requested(first:1)"
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d029cb5faae2ce65df5cceff27ff059d",
    "id": null,
    "metadata": {},
    "name": "AssessmentRequestAcknowledgeMutation",
    "operationKind": "mutation",
    "text": "mutation AssessmentRequestAcknowledgeMutation(\n  $input: AssessmentRequestAcknowledgeInput!\n) {\n  assessmentRequestAcknowledge(input: $input) {\n    assessment_request {\n      id\n    }\n    user {\n      linked_individual {\n        ...DashboardPage_user_assessment_request\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment DashboardPage_user_assessment_request on IndividualRole {\n  assessment_requested(first: 1) {\n    nodes {\n      id\n      questionnaire_answer_sets {\n        id\n      }\n      acknowledged\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '903d9f203529ec65acea07c5f070df5e';

module.exports = node;
