// @flow

import React, { Fragment } from 'react'

const GeneralQuestions = [
  {
    key: 'howSupportOngoingCare',
    question: <Fragment> How does Innowell support ongoing care? </Fragment>,
    answer: (
      <Fragment>
        Innowell is an interactive clinical tool that brings individuals, their
        health data and health professionals together in one place.
        <br />
        <br />
        Innowell works with a health service, using an initial health assessment
        which populates an individual’s dashboard. This provides clinicians with
        up to date client results and health information which forms the basis
        of treatment. Information is updated regularly to inform ongoing care,
        track progress, engage with care options and assure treatment is helping
        the person reach their wellness goals.
      </Fragment>
    ),
  },
  {
    key: 'minAge',
    question: <Fragment>Is there a minimum age for using Innowell?</Fragment>,
    answer: (
      <Fragment>
        Innowell can be used by anyone, anywhere. Clients are connected to
        Innowell through a health provider, and can be any age. They can join by
        themselves, or with a parent, guardian or carer. Clients may also invite
        a support person.
      </Fragment>
    ),
  },
  {
    key: 'research',
    question: <Fragment>Why is Innowell interested in research?</Fragment>,
    answer: (
      <Fragment>
        Innowell is partnered with the Brain and Mind Centre of the University
        of Sydney. The Brain and Mind Centre combines research and practice to
        inform better health services and person centred care for everyday
        people.
        <br />
        <br />
        By using data for research, Innowell is constantly investing and
        learning about mental health in Australia.
        <br />
        If you have provided permission, your de-identified information is
        shared with the Brain and Mind Centre.
      </Fragment>
    ),
  },
]

export default GeneralQuestions
