/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IndividualsPage_current_role$ref = any;
type IndividualsPage_user$ref = any;
export type IndividualsPage_QueryVariables = {||};
export type IndividualsPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +current_role: {|
        +$fragmentRefs: IndividualsPage_current_role$ref
      |},
      +$fragmentRefs: IndividualsPage_user$ref,
    |}
  |}
|};
export type IndividualsPage_Query = {|
  variables: IndividualsPage_QueryVariables,
  response: IndividualsPage_QueryResponse,
|};
*/


/*
query IndividualsPage_Query {
  viewer {
    currentUser {
      current_role {
        __typename
        ...IndividualsPage_current_role
        ... on AdminRole {
          id
        }
        ... on ClinicianRole {
          id
        }
        ... on IndividualRole {
          id
        }
        ... on LeadClinicianRole {
          id
        }
        ... on ManagerRole {
          id
        }
        ... on OwnerRole {
          id
        }
        ... on ResearcherRole {
          id
        }
        ... on SuperRole {
          id
        }
        ... on SupportPersonRole {
          id
        }
      }
      ...IndividualsPage_user
      id
    }
    id
  }
}

fragment IndividualsAdminInvite_current_role on RoleInterface {
  __isRoleInterface: __typename
  tenant {
    hide_mobile_on_invite
    id
  }
  tenants {
    ...UserInviteForm_tenants
    id
  }
}

fragment IndividualsPage_current_role on RoleInterface {
  __isRoleInterface: __typename
  id
  role_type
  ...IndividualsAdminInvite_current_role
  parent {
    __typename
    ... on RoleParentInterface {
      __isRoleParentInterface: __typename
      id
    }
    ... on Tenant {
      id
    }
    ... on TenantGroup {
      id
    }
  }
}

fragment IndividualsPage_user on User {
  id
  tab_options {
    id
    label
    value
  }
  initial_state
  tenant {
    id
    important_formulas(kind: individual) {
      label
      formula {
        id
      }
      id
    }
  }
}

fragment UserInviteForm_tenants on Tenant {
  id
  name
  deactivated
  emr_provider_id
  emr_integration {
    emr_provider {
      name
      user_id_field_name
    }
    required
  }
  allowAdminClinicalAssignments
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  (v1/*: any*/)
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "IndividualsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "IndividualsPage_current_role"
                  }
                ],
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "IndividualsPage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "IndividualsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      (v1/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "role_type",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tenant",
                        "kind": "LinkedField",
                        "name": "tenant",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hide_mobile_on_invite",
                            "storageKey": null
                          },
                          (v1/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tenant",
                        "kind": "LinkedField",
                        "name": "tenants",
                        "plural": true,
                        "selections": [
                          (v1/*: any*/),
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "deactivated",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "emr_provider_id",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmrIntegrationType",
                            "kind": "LinkedField",
                            "name": "emr_integration",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "EmrProvider",
                                "kind": "LinkedField",
                                "name": "emr_provider",
                                "plural": false,
                                "selections": [
                                  (v2/*: any*/),
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "user_id_field_name",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "required",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "allowAdminClinicalAssignments",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "parent",
                        "plural": false,
                        "selections": [
                          (v0/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "RoleParentInterface",
                            "abstractKey": "__isRoleParentInterface"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "Tenant",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v3/*: any*/),
                            "type": "TenantGroup",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "type": "RoleInterface",
                    "abstractKey": "__isRoleInterface"
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "AdminRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "ClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "LeadClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "ManagerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "OwnerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "ResearcherRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "SuperRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v3/*: any*/),
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              },
              (v1/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "TabOption",
                "kind": "LinkedField",
                "name": "tab_options",
                "plural": true,
                "selections": [
                  (v1/*: any*/),
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "value",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "initial_state",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v1/*: any*/),
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "kind",
                        "value": "individual"
                      }
                    ],
                    "concreteType": "ImportantFormula",
                    "kind": "LinkedField",
                    "name": "important_formulas",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Formula",
                        "kind": "LinkedField",
                        "name": "formula",
                        "plural": false,
                        "selections": (v3/*: any*/),
                        "storageKey": null
                      },
                      (v1/*: any*/)
                    ],
                    "storageKey": "important_formulas(kind:\"individual\")"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67a23886cdaf941e36993a8b9bdb24c5",
    "id": null,
    "metadata": {},
    "name": "IndividualsPage_Query",
    "operationKind": "query",
    "text": "query IndividualsPage_Query {\n  viewer {\n    currentUser {\n      current_role {\n        __typename\n        ...IndividualsPage_current_role\n        ... on AdminRole {\n          id\n        }\n        ... on ClinicianRole {\n          id\n        }\n        ... on IndividualRole {\n          id\n        }\n        ... on LeadClinicianRole {\n          id\n        }\n        ... on ManagerRole {\n          id\n        }\n        ... on OwnerRole {\n          id\n        }\n        ... on ResearcherRole {\n          id\n        }\n        ... on SuperRole {\n          id\n        }\n        ... on SupportPersonRole {\n          id\n        }\n      }\n      ...IndividualsPage_user\n      id\n    }\n    id\n  }\n}\n\nfragment IndividualsAdminInvite_current_role on RoleInterface {\n  __isRoleInterface: __typename\n  tenant {\n    hide_mobile_on_invite\n    id\n  }\n  tenants {\n    ...UserInviteForm_tenants\n    id\n  }\n}\n\nfragment IndividualsPage_current_role on RoleInterface {\n  __isRoleInterface: __typename\n  id\n  role_type\n  ...IndividualsAdminInvite_current_role\n  parent {\n    __typename\n    ... on RoleParentInterface {\n      __isRoleParentInterface: __typename\n      id\n    }\n    ... on Tenant {\n      id\n    }\n    ... on TenantGroup {\n      id\n    }\n  }\n}\n\nfragment IndividualsPage_user on User {\n  id\n  tab_options {\n    id\n    label\n    value\n  }\n  initial_state\n  tenant {\n    id\n    important_formulas(kind: individual) {\n      label\n      formula {\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment UserInviteForm_tenants on Tenant {\n  id\n  name\n  deactivated\n  emr_provider_id\n  emr_integration {\n    emr_provider {\n      name\n      user_id_field_name\n    }\n    required\n  }\n  allowAdminClinicalAssignments\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c3a2e9f3e41262692403b47d5dbc0e6';

module.exports = node;
