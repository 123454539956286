/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ConnectedTenantsList_tenants$ref = any;
type IndividualRolesList_individuals$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConnectedTenantsPage_user$ref: FragmentReference;
declare export opaque type ConnectedTenantsPage_user$fragmentType: ConnectedTenantsPage_user$ref;
export type ConnectedTenantsPage_user = {|
  +roles: ?{|
    +nodes: ?$ReadOnlyArray<?{|
      +$fragmentRefs: IndividualRolesList_individuals$ref
    |}>
  |},
  +individual_tenants: $ReadOnlyArray<{|
    +$fragmentRefs: ConnectedTenantsList_tenants$ref
  |}>,
  +$refType: ConnectedTenantsPage_user$ref,
|};
export type ConnectedTenantsPage_user$data = ConnectedTenantsPage_user;
export type ConnectedTenantsPage_user$key = {
  +$data?: ConnectedTenantsPage_user$data,
  +$fragmentRefs: ConnectedTenantsPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ConnectedTenantsPage_user",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "roleTypes",
          "value": [
            "INDIVIDUAL"
          ]
        }
      ],
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "IndividualRolesList_individuals"
                }
              ],
              "type": "IndividualRole",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "roles(roleTypes:[\"INDIVIDUAL\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "individual_tenants",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ConnectedTenantsList_tenants"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '5c4d1af249ca35fc7ba08c3fd1d08f30';

module.exports = node;
