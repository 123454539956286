/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type InsightsIntervalEnum = "custom" | "monthly" | "weekly" | "yearly" | "%future added value";
export type StbEscalationsInsightsQueryVariables = {|
  interval: InsightsIntervalEnum,
  start_date: any,
  end_date: any,
  owner_tenant_id?: ?string,
|};
export type StbEscalationsInsightsQueryResponse = {|
  +viewer: {|
    +insights: {|
      +stb_escalations: {|
        +series_categories: $ReadOnlyArray<string>,
        +series: $ReadOnlyArray<{|
          +name: string,
          +data: $ReadOnlyArray<number>,
        |}>,
      |}
    |}
  |}
|};
export type StbEscalationsInsightsQuery = {|
  variables: StbEscalationsInsightsQueryVariables,
  response: StbEscalationsInsightsQueryResponse,
|};
*/


/*
query StbEscalationsInsightsQuery(
  $interval: InsightsIntervalEnum!
  $start_date: DateTime!
  $end_date: DateTime!
  $owner_tenant_id: ID
) {
  viewer {
    insights {
      stb_escalations(interval: $interval, start_date: $start_date, end_date: $end_date, owner_tenant_id: $owner_tenant_id) {
        series_categories
        series {
          name
          data
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "interval"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "owner_tenant_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "start_date"
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "InsightsType",
  "kind": "LinkedField",
  "name": "insights",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "end_date",
          "variableName": "end_date"
        },
        {
          "kind": "Variable",
          "name": "interval",
          "variableName": "interval"
        },
        {
          "kind": "Variable",
          "name": "owner_tenant_id",
          "variableName": "owner_tenant_id"
        },
        {
          "kind": "Variable",
          "name": "start_date",
          "variableName": "start_date"
        }
      ],
      "concreteType": "InsightsDatasetType",
      "kind": "LinkedField",
      "name": "stb_escalations",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "series_categories",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "InsightsSeriesType",
          "kind": "LinkedField",
          "name": "series",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "data",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StbEscalationsInsightsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "StbEscalationsInsightsQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "38329143f7b77d7b2a0eab8ba79a29f1",
    "id": null,
    "metadata": {},
    "name": "StbEscalationsInsightsQuery",
    "operationKind": "query",
    "text": "query StbEscalationsInsightsQuery(\n  $interval: InsightsIntervalEnum!\n  $start_date: DateTime!\n  $end_date: DateTime!\n  $owner_tenant_id: ID\n) {\n  viewer {\n    insights {\n      stb_escalations(interval: $interval, start_date: $start_date, end_date: $end_date, owner_tenant_id: $owner_tenant_id) {\n        series_categories\n        series {\n          name\n          data\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e8d5205d246139772c1e00f1d7bb3321';

module.exports = node;
