/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianNoteActivity_user$ref: FragmentReference;
declare export opaque type ClinicianNoteActivity_user$fragmentType: ClinicianNoteActivity_user$ref;
export type ClinicianNoteActivity_user = {|
  +id: string,
  +clinician_notes: ?{|
    +totalCount: number
  |},
  +$refType: ClinicianNoteActivity_user$ref,
|};
export type ClinicianNoteActivity_user$data = ClinicianNoteActivity_user;
export type ClinicianNoteActivity_user$key = {
  +$data?: ClinicianNoteActivity_user$data,
  +$fragmentRefs: ClinicianNoteActivity_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianNoteActivity_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianNoteConnection",
      "kind": "LinkedField",
      "name": "clinician_notes",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '983f4399b0f11c7dda81841dc704eeb5';

module.exports = node;
