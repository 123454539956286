// @flow

import React, { Fragment } from 'react'

const IndividualQuestions = [
  {
    key: 'platformBenefits',
    question: <Fragment> How does Innowell benefit you? </Fragment>,
    answer: (
      <Fragment>
        Innowell is a personalised, interactive health dashboard that brings
        you, your health data and your health professional together.
        <br />
        Innowell leads you through an intake process with your health service
        which includes an initial health assessment.
        <br />
        This provides the data for your dashboard giving you access to results,
        health information and care options. Through your Innowell dashboard,
        you can share your information with your health professional, work
        together to prioritise and tailor care around your needs and achieve
        your wellness goals.
      </Fragment>
    ),
  },
  {
    key: 'whoCanUse',
    question: <Fragment>Who can use Innowell?</Fragment>,
    answer: (
      <Fragment>
        Innowell is used by you, your health service and your health
        professional. Your health service helps coordinate care so that you and
        your health professional can focus on your personal health and
        wellbeing. Your health professional sees your dashboard, reviews your
        data and monitors your progress. This assures they can tailor care and
        provide you with the best treatment options.
      </Fragment>
    ),
  },
  {
    key: 'whatDevices',
    question: <Fragment>What devices can you use?</Fragment>,
    answer: (
      <Fragment>
        Innowell works on any device. Login to your dashboard from anywhere and
        put your mental health front and centre.
      </Fragment>
    ),
  },
  {
    key: 'createAccount',
    question: <Fragment> How do you create your Innowell account? </Fragment>,
    answer: (
      <Fragment>
        Your health provider will send an email invitation with a link. The link
        will take you to Innowell where you create your account and set up your
        dashboard. There are three steps to get started:
        <ol>
          <li>set up your user name and password</li>
          <li>read and accept the Terms of Use</li>
          <li>
            complete the onboarding questions and the initial questionnaire
          </li>
        </ol>
      </Fragment>
    ),
  },
  {
    key: 'initialQuestionnaireWhat',
    question: <Fragment>What is the Initial Questionnaire?</Fragment>,
    answer: (
      <Fragment>
        The initial questionnaire is a comprehensive health assessment completed
        as part of an intake process. Each set of questions is a specific
        collection of validated psychometric tools that are used to provide real
        time data that populates into your personal dashboard. This initial data
        set helps set the stage for you to make decisions about your health
        needs and priorities and to work together with your clinician in ongoing
        care.
      </Fragment>
    ),
  },
  {
    key: 'whyUpdateDashboard',
    question: <Fragment> Why should you update your Dashboard? </Fragment>,
    answer: (
      <Fragment>
        As your story and circumstances change so too should your dashboard.
        Your Summary Dashboard and Health Card responses can be regularly
        updated to reflect where you are at today. You can view all your
        previous responses in summary and graph form, and you can monitor your
        progress over time. When you’re speaking with your health professional,
        you can discuss your updates, review your responses and make care
        decisions together.
      </Fragment>
    ),
  },
  {
    key: 'whoContribuesToDasjboard',
    question: (
      <Fragment> Who can contribute to your Summary Dashboard? </Fragment>
    ),
    answer: (
      <Fragment>
        Your health professional can contribute to your Summary Dashboard. When
        you invite a support person they can also contribute. They answer a
        series of questions linked to your Summary Dashboard adding to the
        overall impression of your health and wellbeing.
      </Fragment>
    ),
  },
  {
    key: 'supportPerson',
    question: <Fragment> Who is a Support Person? </Fragment>,
    answer: (
      <Fragment>
        A support person can be a friend, partner, mentor or family member. They
        are someone who
        <ul>
          <li>you trust</li>
          <li>
            can provide an honest update on your overall health and wellbeing
          </li>
          <li>is able to provide you with their perspective</li>
        </ul>
        You can invite a support person and you can remove a support person from
        your dashboard at anytime. A support person can answer a series of
        questions on areas of health linked to your Summary Dashboard.
        <br />
        <strong>
          They cannot see your dashboard unless you choose to show it to them
          from your own device.{' '}
        </strong>
      </Fragment>
    ),
  },
]

export default IndividualQuestions
