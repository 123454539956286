// @flow

import { type ThemeType } from 'care-ui/atoms'

export const containerStyle = ({
  theme,
  empty,
}: {
  empty?: boolean,
  theme: ThemeType,
}) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.care.radius.md,
  border: `1px solid ${theme.care.palette.border.subtle}`,
  color: theme.care.palette.text.positive,
  padding: `${theme.care.spacing.lg} ${theme.care.spacing.sm} `,
  [theme.care.breakpoints.queries.md]: {
    padding: theme.care.spacing.xl,
  },
  ...(empty
    ? {
        alignItems: 'center',
        justifyContent: 'center',
        minHeight: '370px',
      }
    : {}),
})

export const headerStyle = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginBottom: theme.care.spacing.lg,
})

export const subHeaderStyle = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  marginBottom: theme.care.spacing.xxs,
})

export const subtitleStyle = ({
  theme,
  empty,
}: {
  empty?: boolean,
  theme: ThemeType,
}) => ({
  ...(empty ? { marginBottom: theme.care.spacing.sm } : {}),
})

export const cardContainerStyle = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.care.spacing.sm,
  justifyContent: 'center',
  [theme.care.breakpoints.queries.md]: {
    justifyContent: 'flex-start',
  },
})
