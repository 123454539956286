// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import omit from 'lodash/fp/omit'

import Cross from 'react-ui/assets/icons/cross.svg'
import { felaProps } from 'shared/services/fela'
import { Button, FlexContainer } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  as?: string,
  children: React.Node,
  header?: React.Node,
  onRequestClose: () => void,
}

const styleRules = ({ theme }) => ({
  NavigationPanel: {
    className: 'NavigationPanel',
  },
  closeButton: {
    backgroundColor: 'transparent',
    border: 0,
    className: 'NavigationPanel__closeButton',
    color: 'inherit',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'flex-end',
    width: '100%',
  },
  panelHeader: {
    alignItems: 'flex-start',
    backgroundColor: 'transparent',
    className: 'NavigationPanel__panelHeader',
    color: theme.care.palette.text.positive,
    display: 'flex',
    flexFlow: 'row nowrap',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    paddingTop: theme.spacing(0.5),
    marginBottom: '1rem',
  },
  headerLabel: {
    alignItems: 'flex-start',
    className: 'NavigationPanel__headerLabel',
    display: 'flex',
    flexFlow: 'row no-wrap',
    justifyContent: 'center',
  },
  panelContent: {
    className: 'NavigationPanel__panelContent',
    paddingBottom: theme.spacing(1),
    paddingTop: theme.spacing(1),
    position: 'relative',
    zIndex: 0,
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    [theme.breakpoints.queries.md]: {
      paddingLeft: `calc(${theme.Grid.gutter} * 2)`,
      paddingRight: `calc(${theme.Grid.gutter} * 2)`,
    },
  },
})

const NavigationPanel = ({
  as: Component = 'div',
  children,
  header,
  onRequestClose,
  styles,
  ...props
}: PropsType) => (
  <Component
    className={styles.NavigationPanel}
    data-component-id="MainNavigation"
    {...omit([
      'disableOnClickOutside',
      'enableOnClickOutside',
      'handleClickOutside',
      'eventTypes',
      'outsideClickIgnoreClass',
      'preventDefault',
      'stopPropagation',
      ...felaProps,
    ])(props)}
  >
    <div className={styles.panelContent}>
      <FlexContainer
        wrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
      >
        <div>{header}</div>

        <Button
          variant="text"
          size="md"
          dataTestId="CloseNavigationPanel"
          onClick={onRequestClose}
          icon={Cross}
        />
      </FlexContainer>
      {children}
    </div>
  </Component>
)

export default connect(styleRules)(NavigationPanel)
