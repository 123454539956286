// @flow

import React from 'react'
import { connect } from 'react-fela'
import { Field as FormikField } from 'formik'

import Field from 'react-ui/components/Form/Field'
import Input, { type InputProps } from 'react-ui/components/Form/Input'
import { withoutFelaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

type InputControlProps = FelaPropsType & InputProps

export type TextFieldType = InputControlProps & {
  id: string,
  label: string,
  name: string,
  title: string,
  type?: string,
}

const TextFieldComponent = ({
  id,
  label,
  rules,
  title,
  type,
  ...props
}: TextFieldType) => (
  <FormikField name={id} multiple={false}>
    {({
      field: { name, value, ...field },
      form: { touched, errors, isSubmitting },
    }) => (
      <Field
        error={touched[name] && errors[name]}
        id={name}
        label={title}
        input={
          <Input
            {...field}
            {...withoutFelaProps(
              props,
              'fieldType',
              'handleSubmit',
              'initialValue',
              'optional',
              'required',
              'stepNumber',
              'validationSchema',
            )}
            disabled={isSubmitting}
            id={`textInput--${name}`}
            key={`textInput--${name}`}
            label={label}
            name={name}
            type={type || 'text'}
            value={value || ''}
            extend={(...args) => ({
              Input: rules.Input(...args),
              input: rules.input(...args),
            })}
          />
        }
      />
    )}
  </FormikField>
)

const styleRules = ({ theme }) => ({
  Input: {
    display: 'block',
    marginBottom: theme.spacing(0.5),
    marginTop: theme.spacing(0.5),
    maxWidth: '30rem',
  },
  input: {
    ':disabled + label': {
      cursor: 'not-allowed',
      opacity: 0.5,
    },
    ':checked:not(:disabled) + label': {
      backgroundColor: theme.palette.component.primary.accent,
      borderColor: theme.palette.component.primary.accent,
      color: theme.palette.component.primary.text,
      paddingLeft: `calc(${theme.Grid.gutter} * 2.2)`,
      paddingRight: theme.Grid.gutter,
      transition: 'padding .2s ease',
    },
    ':focus + label': {
      borderColor: theme.Input.focus.accent,
      boxShadow: `0 0 12px 0 ${theme.Input.focus.accent}`,
    },
  },
  label: {
    backgroundColor: theme.palette.component.primary.mutedBase,
    borderColor: theme.palette.component.primary.accent,
    borderRadius: '5px',
    borderStyle: 'solid',
    borderWidth: '2px',
    boxSizing: 'border-box',
    color: theme.palette.component.primary.accent,
    display: 'block',
    paddingBottom: `calc(${theme.spacing(0.4)} - 2px)`,
    paddingLeft: theme.Grid.gutter,
    paddingRight: `calc(${theme.Grid.gutter} * 2.2)`,
    paddingTop: `calc(${theme.spacing(0.4)} - 2px)`,
    transition: 'padding .2s ease',

    ':before': {
      display: 'none',
    },
  },
  activeIcon: {
    fill: 'white',
    top: '50%',
    marginTop: '-4px',
    left: '16px',
  },
})

export const TextField = connect(styleRules)(TextFieldComponent)
