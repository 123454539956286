// @flow

import { graphql } from 'react-relay'

export const fragment = graphql`
  fragment ClinicianNoteCard_clinician_note on ClinicianNote {
    text
    created_at
  }
`
