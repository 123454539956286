// @flow

import { type ThemeType } from 'care-ui/atoms'

export const containerStyle = ({ theme }: { theme: ThemeType }) => ({
  marginBottom: theme.care.spacing.xl,
})

export const wrapperStyle = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.queries.xsOnly]: {
    justifyContent: 'center',
    flexDirection: 'column',
    '> a': {
      margin: 'auto',
      marginBottom: '20px',
    },
  },
})

export const tabContainerStyle = () => ({
  display: 'flex',
  margin: '1rem 0',
})
