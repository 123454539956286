// @flow

import { flow, get, map } from 'lodash/fp'
import { Environment, Network, RecordSource, Store } from 'relay-runtime'

import { jsonLocalStorage, jsonSessionStorage } from 'services/browserStorage'
import { handleResponseFlash } from 'shared/services/flashes'
import { addSessionTimeoutToLocalStorage } from 'shared/services/timeoutWarning'
import { createFlash } from 'platform_web/containers/Flash'

type JSONType = {
  data: ?Object,
  +errors?: Object,
}

export class ServerError extends Error {
  response: Response

  constructor(response: Response) {
    super()
    this.response = response
  }
}

// Server errors raised here are trapped and passed to the `loadingRender` function in routeConfig/services.js
export const handleResponseError = (response: Response) => {
  if (response.status === 500) {
    createFlash({
      header: '500 Internal Server Error',
      message:
        'Oh no! Something bad happened. Please come back later when we have fixed that problem. Thanks.',
      type: 'error',
    })
  }

  if (response.status >= 400) {
    throw new ServerError(response)
  }

  return response
}

const handleResponseUnpack = (response: Response) => response.json()

// Handles displaying errors thrown and caught in the GraphQL controller
export const handleGraphqlErrors = (json: JSONType) => {
  const { errors } = json

  if (errors) {
    const { message, extensions } = errors[0]

    // If an error code exists, the relevant component will manage the error state
    if (extensions?.error_code) {
      return json
    }

    // eslint-disable-next-line no-param-reassign
    json.data = null // Triggers error callback in Relay mutations, error details in [error] key
    createFlash({
      type: 'error',
      header: 'This action is not possible.',
      message,
    })
  }

  return json
}

export const fetchQuery = (
  operation: Object,
  variables: Object,
  _cacheConfig: Object,
  _uploadables: Object,
): Promise<Object> =>
  fetch('/graphql', {
    body: JSON.stringify({
      query: operation.text,
      variables,
    }),
    credentials: 'same-origin',
    headers: {
      Authorization: `Bearer ${jsonLocalStorage.getItem('token_id')}`,
      'X-CSRF-TOKEN': jsonSessionStorage.getItem('csrfToken'),
      'X-Requested-With': 'XMLHttpRequest',
      'content-type': 'application/json',
      'debug-app-url': window.location.href,
    },
    method: 'POST',
  })
    .then(handleResponseFlash)
    .then(addSessionTimeoutToLocalStorage)
    .then(handleResponseError)
    .then(handleResponseUnpack)
    .then(handleGraphqlErrors)

export const buildNetwork = (
  fetchQueryGiven: Object,
  subscriptionHandlerGiven: Object,
): Object => Network.create(fetchQueryGiven, subscriptionHandlerGiven)

export const buildEnvironment = (network: Object): Object =>
  new Environment({
    network,
    store: new Store(new RecordSource()),
  })

export const unpackConnection = flow(get('edges'), map('node'))
