/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireComplete_userQuestionnaire$ref: FragmentReference;
declare export opaque type QuestionnaireComplete_userQuestionnaire$fragmentType: QuestionnaireComplete_userQuestionnaire$ref;
export type QuestionnaireComplete_userQuestionnaire = {|
  +completeness: {|
    +percent: ?number
  |},
  +completedQuestionSets: $ReadOnlyArray<{|
    +latest_submitted_answer_set: ?{|
      +skip_reason_value: ?string
    |},
    +question_set: {|
      +label: string
    |},
    +questionnaires_question_set: {|
      +id: string
    |},
  |}>,
  +skippedQuestionSets: $ReadOnlyArray<{|
    +latest_submitted_answer_set: ?{|
      +skip_reason_value: ?string
    |},
    +question_set: {|
      +label: string
    |},
    +questionnaires_question_set: {|
      +id: string
    |},
  |}>,
  +user: {|
    +id: string
  |},
  +questionnaire: ?{|
    +id: string
  |},
  +ensure_questionnaire: {|
    +id: string
  |},
  +$refType: QuestionnaireComplete_userQuestionnaire$ref,
|};
export type QuestionnaireComplete_userQuestionnaire$data = QuestionnaireComplete_userQuestionnaire;
export type QuestionnaireComplete_userQuestionnaire$key = {
  +$data?: QuestionnaireComplete_userQuestionnaire$data,
  +$fragmentRefs: QuestionnaireComplete_userQuestionnaire$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "AnswerSet",
    "kind": "LinkedField",
    "name": "latest_submitted_answer_set",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "skip_reason_value",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "QuestionSet",
    "kind": "LinkedField",
    "name": "question_set",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "label",
        "storageKey": null
      }
    ],
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "QuestionnairesQuestionSet",
    "kind": "LinkedField",
    "name": "questionnaires_question_set",
    "plural": false,
    "selections": (v0/*: any*/),
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireComplete_userQuestionnaire",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Progress",
      "kind": "LinkedField",
      "name": "completeness",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percent",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "completedQuestionSets",
      "args": [
        {
          "kind": "Literal",
          "name": "scope",
          "value": "completed"
        }
      ],
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "user_questionnaires_question_sets",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "user_questionnaires_question_sets(scope:\"completed\")"
    },
    {
      "alias": "skippedQuestionSets",
      "args": [
        {
          "kind": "Literal",
          "name": "scope",
          "value": "skipped"
        }
      ],
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "user_questionnaires_question_sets",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": "user_questionnaires_question_sets(scope:\"skipped\")"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "questionnaire",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "ensure_questionnaire",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserQuestionnaire",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f6bc9f03fba6db3a4108ef6372fef0ee';

module.exports = node;
