/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AssessmentRequestsQuestionnaire_answers$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssessmentRequestsComponent_assessment_request$ref: FragmentReference;
declare export opaque type AssessmentRequestsComponent_assessment_request$fragmentType: AssessmentRequestsComponent_assessment_request$ref;
export type AssessmentRequestsComponent_assessment_request = {|
  +id: string,
  +sent_at: any,
  +requestee: {|
    +legal_name: ?string
  |},
  +questionnaire_answer_sets: $ReadOnlyArray<{|
    +id: string,
    +status: string,
    +completed_at: ?any,
    +rating: ?string,
    +questionnaire: {|
      +id: string,
      +label: string,
    |},
    +answers: $ReadOnlyArray<{|
      +$fragmentRefs: AssessmentRequestsQuestionnaire_answers$ref
    |}>,
    +answer_sets: $ReadOnlyArray<{|
      +id: string,
      +question_set: {|
        +id: string,
        +label: string,
        +name: string,
      |},
      +answers: $ReadOnlyArray<{|
        +$fragmentRefs: AssessmentRequestsQuestionnaire_answers$ref
      |}>,
    |}>,
  |}>,
  +questionnaires: $ReadOnlyArray<{|
    +id: string,
    +label: string,
  |}>,
  +$refType: AssessmentRequestsComponent_assessment_request$ref,
|};
export type AssessmentRequestsComponent_assessment_request$data = AssessmentRequestsComponent_assessment_request;
export type AssessmentRequestsComponent_assessment_request$key = {
  +$data?: AssessmentRequestsComponent_assessment_request$data,
  +$fragmentRefs: AssessmentRequestsComponent_assessment_request$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = [
  (v0/*: any*/),
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "answers",
  "plural": true,
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssessmentRequestsQuestionnaire_answers"
    }
  ],
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AssessmentRequestsComponent_assessment_request",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sent_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "requestee",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "legal_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionnaireAnswerSet",
      "kind": "LinkedField",
      "name": "questionnaire_answer_sets",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "completed_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "rating",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerSet",
          "kind": "LinkedField",
          "name": "answer_sets",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "QuestionSet",
              "kind": "LinkedField",
              "name": "question_set",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            (v3/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "questionnaires",
      "plural": true,
      "selections": (v2/*: any*/),
      "storageKey": null
    }
  ],
  "type": "AssessmentRequest",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'abac51d185b0386afcd5df1ab824a6a4';

module.exports = node;
