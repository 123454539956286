// @flow

import React, { type Element } from 'react'
import { useFela } from 'react-fela'
import Link from 'found/Link'

import { menutItemStyle, sizeStyle } from './DropDownMenuItem.style'

type ToPropType = {|
  name?: string,
  params?: { [string]: any },
|}
type PropTypes = {
  ariaLabel?: string,
  children: string | Element<any>,
  dataTestId?: string,
  href?: string,
  isActive?: boolean,
  onClick?: () => void,
  size?: 'lg' | 'md',
  to?: string | ToPropType,
}

const DropDownMenuItem = (props: PropTypes) => {
  const { children, onClick, to, href, dataTestId, ariaLabel } = props

  const { css } = useFela({ ...props })

  const LinkElement = to ? Link : 'a'
  const MenuItemElement = href || to ? LinkElement : 'div'

  return (
    <MenuItemElement
      className={css(menutItemStyle, sizeStyle)}
      onClick={onClick}
      href={href}
      to={to}
      data-testid={dataTestId}
      aria-label={ariaLabel}
      role="button"
    >
      {children}
    </MenuItemElement>
  )
}

export default DropDownMenuItem
