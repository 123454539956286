// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  ClinicianMultipleAssignmentUpdateMutationResponse,
  ClinicianMultipleAssignmentUpdateMutationVariables,
} from './__generated__/ClinicianMultipleAssignmentUpdateMutation.graphql'

const mutation = graphql`
  mutation ClinicianMultipleAssignmentUpdateMutation(
    $input: ClinicianMultipleAssignmentUpdateInput!
  ) {
    clinicianMultipleAssignmentUpdate(input: $input) {
      clinician_assignments {
        individual {
          duty_of_care_clinicians {
            ...AvatarListLoader_data
          }
          clinicians {
            ...AvatarListLoader_data
          }
        }
        clinician {
          id
          clinician_assignments_as_clinician {
            id
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (
    result: ClinicianMultipleAssignmentUpdateMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: ClinicianMultipleAssignmentUpdateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
