// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { Button } from 'care-ui'

type Props = {
  button: Object,
  infoText?: Node | string,
  title: string,
}

const informationStyle = ({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  maxWidth: '65rem',
  margin: '0 auto',
  padding: '2rem 0',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  [theme.breakpoints.queries.sm]: {
    flexWrap: 'nowrap',
    textAlign: 'left',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
})

const titleStyle = ({ theme }) => ({
  marginBottom: '1rem',
  [theme.breakpoints.queries.sm]: {
    marginBottom: 0,
    paddingRight: '2rem',
  },
})

const infoTextStyle = () => ({
  margin: '1rem 0',
})

const OnboardingHeader = ({ title, infoText, button }: Props) => {
  const { css } = useFela()
  return (
    <Container>
      <div className={css(informationStyle)}>
        <div className={css(titleStyle)}>
          <Heading level={3} extend={{ marginTop: 0 }}>
            {title}
          </Heading>
          <div className={css(infoTextStyle)}>{infoText}</div>
        </div>
        <Button
          variant="text"
          onClick={button.onClick}
          disabled={button.disabled}
        >
          {button.label}
        </Button>
      </div>
    </Container>
  )
}

export default OnboardingHeader
