// @flow

import type { OptionsType as FlashOptionsType } from 'react-ui/components/Flash'

import {
  CLEAR_FLASH,
  CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE,
  CLOSE_MAIN_NAV,
  CLOSE_SUB_MENU,
  CREATE_FLASH,
  MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN,
  OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE,
  OPEN_MAIN_NAV,
  OPEN_SUB_MENU,
  REMOVE_FLASH,
  TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS,
} from './actionTypes'

export type FlashMessageType = FlashOptionsType & {
  id: string,
  name?: string,
}

type OpenClinicianIndividualGlobalTableType = {
  type: 'OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE',
}

type CloseClinicianIndividualGlobalTableType = {
  type: 'CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE',
}

type OpenMainNavActionType = {
  type: 'OPEN_MAIN_NAV',
}

type CloseMainNavActionType = {
  type: 'CLOSE_MAIN_NAV',
}

type OpenSubMenuActionType = {
  type: 'OPEN_SUB_MENU',
}

type CloseSubMenuActionType = {
  type: 'CLOSE_SUB_MENU',
}

type MarkDashboardQuestionnaireModalSeenType = {
  type: 'MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN',
}

type ToggleOnboardingQuestionnaireSuccessType = {
  show: boolean,
  type: 'TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS',
}

type CreateFlashType = {
  payload: FlashOptionsType,
  type: 'CREATE_FLASH',
}

type RemoveFlashType = {
  payload: { id: string },
  type: 'REMOVE_FLASH',
}

type ClearFlashType = {
  type: 'CLEAR_FLASH',
}

export type ActionsType =
  | OpenClinicianIndividualGlobalTableType
  | CloseClinicianIndividualGlobalTableType
  | OpenMainNavActionType
  | CloseMainNavActionType
  | OpenSubMenuActionType
  | CloseSubMenuActionType
  | MarkDashboardQuestionnaireModalSeenType
  | ToggleOnboardingQuestionnaireSuccessType
  | CreateFlashType
  | RemoveFlashType
  | ClearFlashType

export function openMainNav(): OpenMainNavActionType {
  return {
    type: OPEN_MAIN_NAV,
  }
}

export function closeMainNav(): CloseMainNavActionType {
  return {
    type: CLOSE_MAIN_NAV,
  }
}

export function openSubMenu(): OpenSubMenuActionType {
  return {
    type: OPEN_SUB_MENU,
  }
}

export function closeSubMenu(): CloseSubMenuActionType {
  return {
    type: CLOSE_SUB_MENU,
  }
}

export function openClinicianIndividualGlobalTable(): OpenClinicianIndividualGlobalTableType {
  return {
    type: OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE,
  }
}

export function closeClinicianIndividualGlobalTable(): CloseClinicianIndividualGlobalTableType {
  return {
    type: CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE,
  }
}

export function markDashboardQuestionnaireModalSeen(): MarkDashboardQuestionnaireModalSeenType {
  return {
    type: MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN,
  }
}

export function toggleOnboardingQuestionnaireSuccess(
  show: boolean,
): ToggleOnboardingQuestionnaireSuccessType {
  return {
    show,
    type: TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS,
  }
}

export function createFlash(options: FlashOptionsType): CreateFlashType {
  return {
    type: CREATE_FLASH,
    payload: options,
  }
}

export function removeFlash(id: string): RemoveFlashType {
  return {
    type: REMOVE_FLASH,
    payload: { id },
  }
}

export function clearFlash(): ClearFlashType {
  return {
    type: CLEAR_FLASH,
  }
}
