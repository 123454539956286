// @flow

import * as React from 'react'
import {
  type FormikHelpers,
  Field as FormikField,
  Formik,
  FormikProps,
} from 'formik'
import { capitalize, omit } from 'lodash/fp'
import * as yup from 'yup'

import {
  CheckBox,
  DateInput,
  Field,
  FormError,
  Input,
  LocationAutocompleteField,
} from 'react-ui/components/Form'
import { handleFieldChange } from 'shared/services/formik'
import { RadioSet } from 'shared/ui/Forms/Fields'
import { Button } from 'care-ui'

export type FieldDefinition = {
  fieldType:
    | 'text'
    | 'checkbox'
    | 'email'
    | 'hidden'
    | 'number'
    | 'password'
    | 'date'
    | (FieldDefinition => React.Node),
  handleSubmit?: () => void,
  id: string,
  initialValue?: any,
  options?: Object,
  title?: ?string,
  validationSchema?: Object,
}

function renderInput(fieldType, setFieldValue, props) {
  switch (fieldType) {
    case 'radio':
    case 'multichoice':
      return <RadioSet {...(props: any)} />
    case 'checkbox':
      return <CheckBox {...omit(['value'])(props)} checked={props.value} />
    case 'autocomplete':
    case 'suburb_autocomplete':
    case 'postcode_autocomplete':
      return (
        <LocationAutocompleteField
          {...(props: any)}
          handleSelect={handleFieldChange(setFieldValue, props.name)}
        />
      )
    case 'date':
      return (
        <DateInput
          {...(props: any)}
          id={props.name}
          onChange={handleFieldChange(setFieldValue, props.name)}
        />
      )
    default:
      return <Input {...(props: any)} type={fieldType} />
  }
}

function createField(fieldDefinition: FieldDefinition, values, handleSubmit) {
  if (typeof fieldDefinition.fieldType === 'function') {
    const submittableFieldDefinition = fieldDefinition
    submittableFieldDefinition.handleSubmit = handleSubmit
    return fieldDefinition.fieldType(submittableFieldDefinition)
  }
  const { id, fieldType, title, validationSchema, ...props } = fieldDefinition
  return (
    <FormikField name={id} validationSchema={validationSchema}>
      {({ field, form: { touched, errors, isSubmitting, setFieldValue } }) => {
        const error = touched[id]
          ? errors[id] || errors[capitalize(id)]
          : undefined
        return (
          <Field
            error={error}
            id={id}
            label={title}
            hidden={fieldType === 'hidden'}
            input={renderInput(fieldType, setFieldValue, {
              disabled: isSubmitting,
              name: id,
              ...field,
              ...omit(['initialValue', 'stepNumber', 'optional'])(props),
              values,
            })}
          />
        )
      }}
    </FormikField>
  )
}

type renderProps = {
  errorMessage: React.Node,
  fields: {
    [key: string]: React.Element<any>,
  },
  formik: FormikProps,
  handleSubmit?: () => void,
  renderedFields: React.Node,
  submitButton: React.Element<typeof Button>,
}

const renderFields = fields =>
  Object.keys(fields).map(key => React.cloneElement(fields[key], { key }))

const defaultFormRenderCallback = ({
  errorMessage,
  renderedFields,
  submitButton,
}: renderProps) => (
  <React.Fragment>
    {errorMessage}
    {renderedFields}
    {submitButton}
  </React.Fragment>
)

function renderSubmitButton(disabled) {
  return (
    <Button
      type="submit"
      disabled={disabled}
      variant="primary"
      ariaLabel="sign in"
      dataTestId="sign-in"
    >
      Next
    </Button>
  )
}

function renderErrorMessage(error) {
  if (!error) return null
  return <FormError>{error}</FormError>
}

type formProps = FormikProps & {
  handleSubmit?: () => void,
}

function buildFormElements(fieldDefinitions, values, onSubmit) {
  const schema = {}
  const initialValues = {}
  const fields = {}

  function determineInitialValue(initialValue) {
    if (initialValue === undefined || typeof initialValue === 'undefined')
      return ''
    return initialValue
  }

  fieldDefinitions.forEach(fieldDefinition => {
    const { initialValue, id, validationSchema } = fieldDefinition
    fields[id] = createField(fieldDefinition, values, onSubmit)
    initialValues[id] = determineInitialValue(initialValue)
    if (validationSchema) schema[id] = validationSchema
  })

  return {
    fields,
    initialValues,
    schema,
  }
}

export type PropsType = {
  children?: renderProps => React.Node,
  onSubmit?: (any, FormikHelpers) => void,
}

function createForm(
  fieldDefinitions: Array<FieldDefinition> = [],
  values: ?Object,
) {
  return function form({
    children = defaultFormRenderCallback,
    onSubmit,
    ...props
  }: PropsType) {
    const { fields, initialValues, schema } = buildFormElements(
      fieldDefinitions,
      values,
      onSubmit,
    )

    return (
      <Formik
        {...(props: any)}
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={yup.object().shape(schema)}
      >
        {({ handleSubmit, ...formik }: formProps) => {
          return (
            <form onSubmit={handleSubmit}>
              {children({
                errorMessage: renderErrorMessage(
                  formik.status && formik.status.error,
                ),
                fields,
                formik,
                renderedFields: renderFields(fields),
                handleSubmit,
                submitButton: renderSubmitButton(formik.isSubmitting),
              })}
            </form>
          )
        }}
      </Formik>
    )
  }
}

export default createForm
