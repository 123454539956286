// @flow

import React from 'react'

import { FlexContainer, GraphIntervalSelector } from 'care-ui'

import { type OverviewQueryVariableType } from '../tabs/Overview/hooks/useOverviewInsights'

import TenantSwitcher from './TenantSwitcher'

type InsightsPageHeaderActionsProps = {
  interval: string,
  overviewQueryVariables: OverviewQueryVariableType,
  setOutcomeReportQueryVariables: (overviewQueryVariables: {
    [key: string]: string,
  }) => void,
  setOverviewQueryVariables: (overviewQueryVariables: {
    [key: string]: string,
  }) => void,
}

const InsightsPageHeaderActions = (props: InsightsPageHeaderActionsProps) => {
  const {
    interval,
    setOverviewQueryVariables,
    overviewQueryVariables,
    setOutcomeReportQueryVariables,
  } = props

  return (
    <FlexContainer alignItems="center" gap="xs" wrap="nowrap">
      <TenantSwitcher
        setOutcomeReportQueryVariables={setOutcomeReportQueryVariables}
        overviewQueryVariables={overviewQueryVariables}
        setOverviewQueryVariables={setOverviewQueryVariables}
      />

      <GraphIntervalSelector
        interval={interval}
        setQueryVariables={setOverviewQueryVariables}
      />
    </FlexContainer>
  )
}

export default InsightsPageHeaderActions
