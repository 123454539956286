/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AnswerSets_answer_sets$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionSetDetailsPage_user_question_set$ref: FragmentReference;
declare export opaque type UserQuestionSetDetailsPage_user_question_set$fragmentType: UserQuestionSetDetailsPage_user_question_set$ref;
export type UserQuestionSetDetailsPage_user_question_set = {|
  +id: string,
  +submitted_answer_sets: $ReadOnlyArray<{|
    +$fragmentRefs: AnswerSets_answer_sets$ref
  |}>,
  +question_set: {|
    +label: string
  |},
  +$refType: UserQuestionSetDetailsPage_user_question_set$ref,
|};
export type UserQuestionSetDetailsPage_user_question_set$data = UserQuestionSetDetailsPage_user_question_set;
export type UserQuestionSetDetailsPage_user_question_set$key = {
  +$data?: UserQuestionSetDetailsPage_user_question_set$data,
  +$fragmentRefs: UserQuestionSetDetailsPage_user_question_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionSetDetailsPage_user_question_set",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerSet",
      "kind": "LinkedField",
      "name": "submitted_answer_sets",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AnswerSets_answer_sets"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8e4e6b86625801498c57efbbea00942c';

module.exports = node;
