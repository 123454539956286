/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssignableCliniciansPagination_tenant$ref: FragmentReference;
declare export opaque type AssignableCliniciansPagination_tenant$fragmentType: AssignableCliniciansPagination_tenant$ref;
export type AssignableCliniciansPagination_tenant = {|
  +tenant: {|
    +multi_select_assignable_clinicians: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +email: string,
          +id: string,
          +user: {|
            +id: string,
            +name: string,
          |},
        |}
      |}>
    |}
  |},
  +$refType: AssignableCliniciansPagination_tenant$ref,
|};
export type AssignableCliniciansPagination_tenant$data = AssignableCliniciansPagination_tenant;
export type AssignableCliniciansPagination_tenant$key = {
  +$data?: AssignableCliniciansPagination_tenant$data,
  +$fragmentRefs: AssignableCliniciansPagination_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "tenant",
  "multi_select_assignable_clinicians"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "individuals"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "tenant_id"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "viewer"
      ],
      "operation": require('./AssignableCliniciansPaginationRefetchQuery.graphql')
    }
  },
  "name": "AssignableCliniciansPagination_tenant",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "tenant_id"
        }
      ],
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": "multi_select_assignable_clinicians",
          "args": [
            {
              "kind": "Variable",
              "name": "individuals",
              "variableName": "individuals"
            },
            {
              "kind": "Variable",
              "name": "search",
              "variableName": "search"
            }
          ],
          "concreteType": "ClinicianRoleConnection",
          "kind": "LinkedField",
          "name": "__AssignableCliniciansPagination_multi_select_assignable_clinicians_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ClinicianRoleEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "ClinicianRole",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "email",
                      "storageKey": null
                    },
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "name",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '25bd65a9558e0b9a3332133fd4e52cb7';

module.exports = node;
