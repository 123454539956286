// @flow

const AcknowledgementParagraphs = [
  {
    id: '1',
    text: `
  Project Synergy (2017-20) is an Australian Government Department of
  Health-funded initiative that is administered by Innowell Pty Ltd (a joint
  venture between The University of Sydney and PwC). Innowell is also the
  manufacturer of the Innowell Platform (including its database) and is
  considered to be the custodian of all data collected within that database.
  `,
  },
  {
    id: '2',
    text: `
  The platform is a customisable digital toolkit to assist assessment,
  monitoring and management of mental ill health and maintenance of
  wellbeing. It does this by collecting, storing, analysing and reporting
  personal and health information back to individuals and their clinicians
  to promote collaborative care partnerships.
  `,
  },
  {
    id: '3',
    text: `
  The clinical content is determined by service providers. The platform
  operates through existing service providers to promote 24/7 access to
  high-quality and cost-effective mental health services. Highly-protected,
  personally-controlled data capture and transfer systems are utilised to
  move the centre of care from service providers to the person, and family,
  seeking care.
  `,
  },
  {
    id: '4',
    text: `
  Through a number of research studies, the platform is being embedded
  within traditional face-to-face and online Australian mental health
  services and offered to consumers presenting to those services as part of
  standard clinical care/ practice. It allows individuals to: complete
  online clinical self-assessments to understand their needs; explore their
  dashboard of results including current symptoms, functioning and health
  history; select recommended care options (fact sheets, apps, etools and
  other resources) to support their mental health and wellbeing; track their
  progress (real-time) to see how they are going; and, share their dashboard
  and plan with their treating clinician(s) to support care.
  `,
  },
  {
    id: '5',
    text: `
  Innowell is committed to actively supporting Aboriginal and Torres Strait
  Islander people, people with disability, and the LGBTIQA+ community. As an
  organisation, we celebrate diversity regardless of age, gender,
  disability, lifestyle, race, ethnicity, Aboriginal or Torres Strait
  Islander status, cultural background, religion or other beliefs, sexual
  orientation, and gender identity or expression.
  `,
  },
]

export default AcknowledgementParagraphs
