/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportView = "CLIENT_INSIGHT" | "SERVICE_INSIGHT" | "%future added value";
export type ReportsAccessibilityBreakdownDimensionEnum = "age_group" | "gender" | "has_disability" | "indigenous_status" | "language" | "%future added value";
export type AccessibilityBreakdownChartQueryVariables = {|
  from: any,
  to: any,
  tenants: $ReadOnlyArray<string>,
  clinicians: $ReadOnlyArray<string>,
  dimension: ReportsAccessibilityBreakdownDimensionEnum,
  report_view?: ?ReportView,
|};
export type AccessibilityBreakdownChartQueryResponse = {|
  +viewer: {|
    +reports: {|
      +accessibility_breakdown: {|
        +traces: $ReadOnlyArray<?{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<string>,
          +y: $ReadOnlyArray<?number>,
          +total: number,
        |}>
      |}
    |}
  |}
|};
export type AccessibilityBreakdownChartQuery = {|
  variables: AccessibilityBreakdownChartQueryVariables,
  response: AccessibilityBreakdownChartQueryResponse,
|};
*/


/*
query AccessibilityBreakdownChartQuery(
  $from: DateTime!
  $to: DateTime!
  $tenants: [ID!]!
  $clinicians: [ID!]!
  $dimension: ReportsAccessibilityBreakdownDimensionEnum!
  $report_view: ReportView
) {
  viewer {
    reports {
      accessibility_breakdown(dimension: $dimension, from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {
        traces {
          name
          type
          x
          y
          total
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinicians"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dimension"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "report_view"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenants"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "clinicians",
      "variableName": "clinicians"
    },
    {
      "kind": "Variable",
      "name": "dimension",
      "variableName": "dimension"
    },
    {
      "kind": "Variable",
      "name": "from",
      "variableName": "from"
    },
    {
      "kind": "Variable",
      "name": "report_view",
      "variableName": "report_view"
    },
    {
      "kind": "Variable",
      "name": "tenants",
      "variableName": "tenants"
    },
    {
      "kind": "Variable",
      "name": "to",
      "variableName": "to"
    }
  ],
  "concreteType": "ReportsAccessibilityBreakdown",
  "kind": "LinkedField",
  "name": "accessibility_breakdown",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsAccessibilityBreakdownTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "x",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "y",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "AccessibilityBreakdownChartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "AccessibilityBreakdownChartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ce42586fb3c3c39cadcd6a29e8060a5b",
    "id": null,
    "metadata": {},
    "name": "AccessibilityBreakdownChartQuery",
    "operationKind": "query",
    "text": "query AccessibilityBreakdownChartQuery(\n  $from: DateTime!\n  $to: DateTime!\n  $tenants: [ID!]!\n  $clinicians: [ID!]!\n  $dimension: ReportsAccessibilityBreakdownDimensionEnum!\n  $report_view: ReportView\n) {\n  viewer {\n    reports {\n      accessibility_breakdown(dimension: $dimension, from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n          total\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '51c395bdd471a458c08f83349f2bceed';

module.exports = node;
