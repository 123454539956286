// @flow

import type { Store } from 'redux'

import type { OptionsType } from 'react-ui/components/Flash'
import { createFlash as createFlashRedux } from 'redux/generic_ui/actions'

let reduxStore: Store<any, any>

/**
 * This is a one-time method to set a reference to the global Redux store, so it
 * can be accessed in helpers outside React components.
 *
 * NOTE: This won't work if we ever use SSR (server-side rendering) as there
 * should be a separate store per request:
 * https://daveceddia.com/access-redux-store-outside-react/
 */
export function setFlashStore(store: Store<any, any>) {
  reduxStore = store
}

export default function createFlash(options: OptionsType) {
  reduxStore.dispatch(createFlashRedux(options))
}
