// @flow

import { graphql } from 'react-relay'

export const SupportTaskButtonIndividual = graphql`
  fragment SupportTaskButton_individual on IndividualRole {
    has_support_person_attached_to_individual
    individual_detail {
      read_only
    }
    support_person_assignments {
      id
      nickname
      relationship
      support_person {
        email
      }
      last_requested_at
      accepted_invitation
      last_completed
    }
  }
`
