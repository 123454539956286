// @flow

import React, { type Element, type Node } from 'react'
import { useRef, useState } from 'react'
import { useFela } from 'react-fela'
import { useClickAway } from 'react-use'

import { dropdown, dropdownContent } from './DropDownMenu.style'

type PropsType = {
  +children?: Node,
  +closeDropDown?: boolean,
  +dropDownTrigger: Element<any>,
  +fullWidth?: boolean,
  +position?: 'start' | 'end' | 'left' | 'right' | 'reverse',
  +setCloseDropDown?: (arg: boolean) => void,
}

const DropDownMenu = (props: PropsType) => {
  const {
    dropDownTrigger,
    children,
    closeDropDown = true,
    setCloseDropDown,
  } = props

  const ref = useRef(null)

  const [isOpen, setIsOpen] = useState(false)

  const { css } = useFela({ ...props })

  const toggleDropdownMenu = () => {
    setIsOpen(!isOpen)
  }

  // CLICK OUTSIDE HANDLER
  useClickAway(ref, () => {
    setIsOpen(false)
    if (setCloseDropDown) {
      setCloseDropDown(true)
    }
  })

  const isFilterOrSortMenu = !!setCloseDropDown

  return (
    <div className={css(dropdown)} ref={ref}>
      {/* For FilterAndSort variance, the apply button also toggles dropdown
      Hence the logic to toggle dropdown is handled in parent component */}
      {/* For Navigation variance, toggle dropdown logic is handled here */}

      {isFilterOrSortMenu
        ? dropDownTrigger
        : React.cloneElement(dropDownTrigger, {
            onClick: toggleDropdownMenu,
            name: 'dropdownTrigger',
          })}

      {(isOpen || !closeDropDown) && (
        <div className={css(dropdownContent)}>{children}</div>
      )}
    </div>
  )
}

export default DropDownMenu
