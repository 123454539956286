// @flow

import { useSetState } from 'react-use'

export type OverviewQueryVariableType = {
  end_date?: string,
  interval?: string,
  owner_tenant_id?: string,
  start_date?: string,
}
type UseOverviewParams = {
  defaultOverviewQueryVariables: OverviewQueryVariableType,
}

export type UseInsightsReturnType = {
  overviewQueryVariables: OverviewQueryVariableType,
  setOverviewQueryVariables: (
    overviewQueryVariables: OverviewQueryVariableType,
  ) => void,
}

const useOverviewInsights = (
  params: UseOverviewParams,
): UseInsightsReturnType => {
  const { defaultOverviewQueryVariables } = params

  const [overviewQueryVariables, setOverviewQueryVariables] = useSetState(
    defaultOverviewQueryVariables,
  )

  return {
    overviewQueryVariables,
    setOverviewQueryVariables,
  }
}

export default useOverviewInsights
