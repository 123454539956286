// @flow

import { graphql } from 'react-relay'

export const scoreCardsChangeLogChangeLog = {
  changeLog: graphql`
    fragment ScoreCardsChangeLog_changeLog on ChangeLogEntry {
      id
      created_at
      reason
    }
  `,
}
