/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type WelcomePageContainer_user$ref: FragmentReference;
declare export opaque type WelcomePageContainer_user$fragmentType: WelcomePageContainer_user$ref;
export type WelcomePageContainer_user = {|
  +id: string,
  +casual_name: string,
  +sign_in_count: number,
  +has_unfinished_initial_user_questionnaires: boolean,
  +multi_roles_enabled: ?boolean,
  +staff_role: ?boolean,
  +show_individual_onboarding: boolean,
  +policies: $ReadOnlyArray<{|
    +name: string,
    +enabled: boolean,
  |}>,
  +current_role: {|
    +parent?: ?{|
      +name?: string,
      +id?: string,
      +__typename: string,
    |}
  |},
  +$refType: WelcomePageContainer_user$ref,
|};
export type WelcomePageContainer_user$data = WelcomePageContainer_user;
export type WelcomePageContainer_user$key = {
  +$data?: WelcomePageContainer_user$data,
  +$fragmentRefs: WelcomePageContainer_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WelcomePageContainer_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "casual_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "sign_in_count",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "has_unfinished_initial_user_questionnaires",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "multi_roles_enabled",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "staff_role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "show_individual_onboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Policy",
      "kind": "LinkedField",
      "name": "policies",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "enabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "parent",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v1/*: any*/),
                    (v0/*: any*/)
                  ],
                  "type": "RoleParentInterface",
                  "abstractKey": "__isRoleParentInterface"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'be885835924bfe430de20776025acdc1';

module.exports = node;
