// @flow

import * as React from 'react'

import Avatar from 'react-ui/components/Avatar'
import type { PersonProps } from 'react-ui/components/CareOption'

type UserType = {
  +id: string,
  +name: string,
}

export const personDetailsFromUser = (user: UserType): PersonProps => ({
  id: user.id,
  name: user.name,
  avatar: { id: user.id, node: <Avatar user={user} key={user.id} /> },
})
