// @flow

import React, { type Node } from 'react'
import { useRouter } from 'found'

import {
  Box,
  Button,
  Container,
  FlexContainer,
  FlexItem,
  Heading,
  Icon,
} from 'care-ui'
import ChevronLeft from 'care-ui/atoms/icons/chevron-left.svg'

type PageHeaderProps = {
  pageAction?: Node,
  pageTitle: string,
}

const PageHeader = (props: PageHeaderProps) => {
  const { pageTitle, pageAction } = props

  const { router } = useRouter()

  const handleGoBack = () => {
    router.go(-1)
  }

  return (
    <Box borderBottom="subtle">
      <Container>
        <FlexContainer
          justifyContent="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <FlexContainer alignItems="center" gap="xxs">
            <Button variant="text" onClick={handleGoBack}>
              <Icon as={ChevronLeft} />
            </Button>

            <Heading level={1}>{pageTitle}</Heading>
          </FlexContainer>

          {pageAction && <FlexItem>{pageAction}</FlexItem>}
        </FlexContainer>
      </Container>
    </Box>
  )
}

export default PageHeader
