// @flow

import type { CallbacksType } from './request'
import makeRequest from './request'

export const getServerReleaseId = (cbs?: CallbacksType) =>
  makeRequest('/api/v1/releases', 'GET', null, cbs)

export function checkActiveIndividualStatus(check: string): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(
      '/api/v1/users/check_active_individual_status',
      'GET',
      null,
      {
        onSuccess: resolve,
        onError: reject,
      },
      { check },
    )
  })
}

export function checkAvailableRoleInTenant(
  check: string,
  tenantId: ?string,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(
      '/api/v1/users/check_available_role_in_tenant',
      'GET',
      null,
      {
        onSuccess: resolve,
        onError: reject,
      },
      { check, tenant_id: tenantId },
    )
  })
}

export function checkUsernameUniqueness(check: string): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(
      '/api/v1/uniqueness/username',
      'GET',
      null,
      {
        onSuccess: resolve,
        onError: reject,
      },
      { check },
    )
  })
}

export function checkPhoneNumberUniqueness(
  phone: string,
  email: string,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(
      '/api/v1/users/check_phone_number_uniqueness',
      'GET',
      null,
      {
        onSuccess: resolve,
        onError: reject,
      },
      { phone, email },
    )
  })
}
