// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RequestSupportPersonFeedbackMutationResponse,
  RequestSupportPersonFeedbackMutationVariables,
} from './__generated__/RequestSupportPersonFeedbackMutation.graphql'

type CommitArgs = {
  environment: Object,
  onCompleted?: RequestSupportPersonFeedbackMutationResponse => void,
  onError?: (result: Object) => void,
  variables: RequestSupportPersonFeedbackMutationVariables,
}

const mutation = graphql`
  mutation RequestSupportPersonFeedbackMutation(
    $input: SupportPersonFeedbackRequestInput!
  ) {
    supportPersonFeedbackRequest(input: $input) {
      success
      support_person_assignment {
        id
        last_requested_at
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: CommitArgs) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
