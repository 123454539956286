// @flow

import React from 'react'
import { connect } from 'react-fela'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import isNil from 'lodash/fp/isNil'

import Can from 'react-ui/utils/Can'

import { type FelaStylesType } from 'react-ui/typing'
import type { UserTrackableRawScore_user_trackable } from './__generated__/UserTrackableRawScore_user_trackable.graphql'

type PropsType = {
  styles: FelaStylesType,
  user_trackable: UserTrackableRawScore_user_trackable,
}

const styleRules = ({ theme }) => ({
  RawScore: {
    background: theme.palette.section.default.backgroundColor,
    color: theme.palette.themelet.white.headingTextColor,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: '60px',
  },
})

export const rawScoreRange = ({
  lower_bound,
  upper_bound,
  translation,
}: {
  lower_bound: ?number,
  translation: TFunction,
  upper_bound?: ?number,
}) => {
  if (isNil(lower_bound) && isNil(upper_bound)) {
    return translation('na')
  }
  if (isNil(lower_bound) && upper_bound) {
    return `${translation('up_to')} ${upper_bound}`
  }

  // lower_bound and upper_bound could be null || defined and those cannot be co-erced
  return isNil(upper_bound) // $DisableFlow
    ? `${lower_bound}+` // $DisableFlow
    : `${lower_bound}-${upper_bound}`
}

const UserTrackableRawScore = ({
  user_trackable: { result },
  styles,
}: PropsType) => {
  const { t: translation } = useTranslation('assessment')
  if (!result) {
    return null
  }

  const { value, lower_bound, upper_bound } = result

  if (!value) {
    return null
  }

  return (
    <Can>
      {policies =>
        !policies.CAN_VIEW_CLINICIAN_LINKS ? (
          <div className={styles.RawScore} data-testid="UserTrackableRawScore">
            <div>
              <b>
                {translation('score')}: {value}
              </b>
            </div>
            <div>
              {translation('range')}:{' '}
              {rawScoreRange({ lower_bound, upper_bound, translation })}
            </div>
          </div>
        ) : null
      }
    </Can>
  )
}

export const UserTrackableRawScoreLoader = createFragmentContainer(
  connect(styleRules)(UserTrackableRawScore),
  {
    user_trackable: graphql`
      fragment UserTrackableRawScore_user_trackable on UserTrackable {
        result {
          value
          lower_bound
          upper_bound
        }
      }
    `,
  },
)
