// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const ClockIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 22 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M15.8181818,9.75 C16.2323954,9.75 16.5681818,10.0857864 16.5681818,10.5 C16.5681818,10.9142136 16.2323954,11.25 15.8181818,11.25 L10.5,11.25 C10.0857864,11.25 9.75,10.9142136 9.75,10.5 L9.75,3.40909091 C9.75,2.99487735 10.0857864,2.65909091 10.5,2.65909091 C10.9142136,2.65909091 11.25,2.99487735 11.25,3.40909091 L11.25,9.75 L15.8181818,9.75 Z M19.5,10.5 C19.5,5.52955447 15.4704455,1.5 10.5,1.5 C5.52955447,1.5 1.5,5.52955447 1.5,10.5 C1.5,15.4704455 5.52955447,19.5 10.5,19.5 C15.4704455,19.5 19.5,15.4704455 19.5,10.5 Z M21,10.5 C21,16.2988727 16.2988727,21 10.5,21 C4.70112735,21 0,16.2988727 0,10.5 C0,4.70112735 4.70112735,0 10.5,0 C16.2988727,0 21,4.70112735 21,10.5 Z"
            id="clockIcon-path-1"
          />
        </defs>
        <g id="clockIcon" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g
            id="6.1.1.1-View-all-question-sets-in-questionaire"
            transform="translate(-1143.000000, -316.000000)"
          >
            <g
              id="Quiz-set---idle"
              transform="translate(636.000000, 267.000000)"
            >
              <g id="Group" transform="translate(468.000000, 0.000000)">
                <g id="Complete" transform="translate(10.000000, 49.000000)">
                  <g
                    id="icon-/-14x14-/-clock"
                    transform="translate(29.500000, 0.000000)"
                  >
                    <mask id="clockIcon-mask-2" fill="white">
                      <use xlinkHref="#clockIcon-path-1" />
                    </mask>
                    <use
                      id="Stroke-25"
                      fillRule="nonzero"
                      xlinkHref="#clockIcon-path-1"
                    />
                    <g
                      id="→-colour-/-SynBlue-/-800"
                      mask="url(#clockIcon-mask-2)"
                      fillRule="evenodd"
                    >
                      <rect
                        id="Colour-/-SynBlue-500"
                        x="0"
                        y="0"
                        width="21"
                        height="21"
                      />
                    </g>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}
export default ClockIcon
