// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ disabled, theme }) => ({
  background: 'none',
  border: 'none',
  boxSizing: 'border-box',
  className: 'IconButton',
  color: 'inherit',
  cursor: disabled ? 'not-allowed' : 'pointer',
  fontSize: '16px',
  height: theme.spacing(1.5),
  lineHeight: theme.spacing(1.5),
  marginTop: theme.spacing(-0.25),
  opacity: disabled ? 0.5 : 1,
  paddingBottom: theme.spacing(0.25),
  paddingTop: theme.spacing(0.25),
  textAlign: 'center',
  width: theme.spacing(1.5),
})

export default createComponentWithProxy(styleRules, 'button', ['disabled'])
