// @flow

const LGBTQIParagraphs = [
  {
    id: '1',
    text: `
  Innowell acknowledges the lesbian, gay, bisexual, trans, queer and
  intersex communities who have worked to improve and develop the health and
  wellbeing of their peers, children, families, friends and country. We
  celebrate and embrace the diversity of people and community, and honour
  the work that has been done by those before us, and those who continue to
  positively shape the future.
  `,
  },
]

export default LGBTQIParagraphs
