// @flow

export const containerStyle = () => ({
  padding: '1rem',
  fontSize: '1rem',
  display: 'flex',
  alignItems: 'center',
})

export const iconStyle = () => ({
  marginRight: '0.5rem',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})

export const toolTipStyle = () => ({
  marginLeft: '5px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
})
