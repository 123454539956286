// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useFragment } from 'react-relay'
import parser from 'html-react-parser'

import { longDateFormat } from 'services/dateTime'
import { Text } from 'care-ui'

import {cardStyle, dateStyle, infoStyle} from  './ClinicianNoteCard.style'
import { fragment } from './query/ClinicianNoteCard'

import type { ClinicianNoteCard_clinician_note$key } from './query/__generated__/ClinicianNoteCard_clinician_note.graphql'

type PropsType = {
  clinicianNote: ClinicianNoteCard_clinician_note$key,
}

const ClinicianNoteCard = (props: PropsType) => {
  const { clinicianNote: fragmentRef } = props

  const clinicianNote = useFragment(fragment, fragmentRef)
  const { text, created_at } = clinicianNote
  const { css } = useFela()

  const parsedDisplayText = parser(text)
  const parsedOccuredAt = longDateFormat(new Date(created_at))

  return (
    <div className={css(cardStyle)}>
      <Text size="sm" color="title" extend={dateStyle}>
        {parsedOccuredAt}
      </Text>

      <Text size="lg" color="title" extend={infoStyle}>
        {parsedDisplayText}
      </Text>
    </div>
  )
}

export default ClinicianNoteCard
