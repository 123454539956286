/*
 * @flow
 */

import * as React from 'react'

type Props = {
  action: ({
    action: 'Hover' | 'Click',
    duration: number,
    totalDuration: number,
  }) => void,
  children: ({|
    onClick: () => void,
    onMouseEnter: () => void,
    onMouseLeave: () => void,
  |}) => React.Node,
  stepSize: number,
  threshold: number,
}

type State = {
  progress: number,
  totalHoverTime: number,
}

class HoverTracker extends React.Component<Props, State> {
  interval: IntervalID

  static defaultProps = {
    threshold: 100,
    stepSize: 1,
  }

  constructor() {
    super()
    this.state = {
      totalHoverTime: 0,
      progress: 0,
    }
  }

  componentWillUnmount() {
    clearInterval(this.interval)
  }

  incrementProgress = () => {
    const { stepSize } = this.props
    const { progress, totalHoverTime } = this.state
    this.setState(() => ({
      progress: progress + stepSize,
      totalHoverTime: totalHoverTime + stepSize,
    }))
  }

  decrementProgress = () => {
    const { stepSize } = this.props
    const progress = this.state.progress - stepSize
    if (progress <= 0) {
      clearInterval(this.interval)
      return
    }
    this.setState(() => ({ progress }))
  }

  recordAction = (type: 'Hover' | 'Click') => {
    const { progress, totalHoverTime } = this.state
    const { threshold, action } = this.props
    const passedThreshold = progress > threshold
    if (!passedThreshold) return

    this.setState({ progress: 0 })
    action({
      action: type,
      duration: progress,
      totalDuration: totalHoverTime,
    })
  }

  onMouseEnter = () => {
    const { stepSize } = this.props
    clearInterval(this.interval)
    this.interval = setInterval(this.incrementProgress, stepSize)
  }

  onMouseLeave = () => {
    const { stepSize } = this.props
    clearInterval(this.interval)
    this.recordAction('Hover')
    this.interval = setInterval(this.decrementProgress, stepSize)
  }

  onClick = () => {
    clearInterval(this.interval)
    this.recordAction('Click')
  }

  render() {
    const { onMouseEnter, onMouseLeave, onClick } = this
    return this.props.children({ onMouseEnter, onMouseLeave, onClick })
  }
}

export default HoverTracker
