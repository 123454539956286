// @flow

import { createFragmentContainer, graphql } from 'react-relay'

import { PopUpNotificationPreview } from './PopUpNotificationPreview'

export const PopUpNotificationShowPreviewQuery = graphql`
  query PopUpNotificationShowPreview_Query($id: ID!) {
    viewer {
      currentUser {
        notification_preview(notification_id: $id) {
          ...PopUpNotificationShowPreview_notification_preview
        }
      }
    }
  }
`

export const PopUpNotificationShowPreviewLoader = createFragmentContainer(
  PopUpNotificationPreview,
  {
    notification_preview: graphql`
      fragment PopUpNotificationShowPreview_notification_preview on PopUpNotification {
        id
        description
        image_url
      }
    `,
  },
)
