// @flow

import { type ThemeType } from 'care-ui/atoms'

export const styleRules = ({
  theme,
  shrinkwrap,
}: {
  shrinkwrap?: boolean,
  theme: ThemeType,
}) => {
  const smallSpacing = theme.spacing(0.5)
  const smallPadding = theme.Grid.gutter
  const lgSpacing = theme.spacing(1.5)
  const lgPadding = `calc(${theme.Grid.gutter} * 2)`

  return {
    Modal: {
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 3px 6px 2px rgba(7, 7, 8, 0.14)',
      className: 'Modal',
      maxHeight: '100%',
      maxWidth: '100%',
      outline: 'none',
      overflow: 'auto',
      padding: smallPadding,
      position: 'relative',
      width: shrinkwrap ? 'auto' : '100%',
      WebkitOverflowScrolling: 'touch',
      [theme.mq.MULTI_COLUMN]: {
        padding: lgPadding,
      },
    },

    overlay: {
      className: 'Modal__overlay',
      position: 'fixed',
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.65)',
      display: 'flex',
      alignItems: shrinkwrap ? 'center' : 'stretch',
      justifyContent: 'center',
      padding: smallSpacing,
      [theme.mq.MULTI_COLUMN]: {
        padding: lgSpacing,
      },
      zIndex: 101,
    },

    closeButton: {
      backgroundColor: 'transparent',
      border: 0,
      className: 'Modal__closeButton',
      cursor: 'pointer',
      paddingTop: '24px',
      paddingBottom: '24px',
      paddingLeft: lgPadding,
      paddingRight: lgPadding,
      position: 'absolute',
      top: 0,
      right: 0,
    },
  }
}
