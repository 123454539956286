// @flow

import React, { type Node } from 'react'

import {
  Flag,
  FlagAboriginal,
  FlagAustralia,
  FlagPride,
  FlagTorresStrait,
  FlagTrans,
} from 'react-ui/components/Flag'
import { Heading, Text } from 'care-ui'

import {
  ParagraphsAboriginalAndTorresStrait,
  ParagraphsAcknowledgement,
  ParagraphsLGBTQI,
} from './Paragraphs'

type SectionPropsType = {
  flags?: $ReadOnlyArray<{ id: string, image: Node }>,
  heading: string,
  paragraphs: $ReadOnlyArray<{ id: string, text: string }>,
}

const Section = ({ heading, paragraphs, flags = [] }: SectionPropsType) => (
  <>
    <Heading level={2} margin="xs">
      {heading}
    </Heading>
    <Text size="lg">
      {paragraphs.map(({ id, text }) => <p key={id}>{text}</p>)}
    </Text>
    <div>{flags.map(({ id, image }) => <Flag key={id} as={image} />)}</div>
  </>
)

const AcknowledgementsPage = () => (
  <div style={{ marginBottom: '5rem' }}>
    <Heading level={1} margin="sm" style={{ wordBreak: 'break-all' }}>
      Acknowledgements
    </Heading>

    <Section heading="Project Synergy" paragraphs={ParagraphsAcknowledgement} />

    <Section
      heading="Aboriginal and Torres Strait Islanders"
      flags={[
        { id: 'AustraliaFlag', image: FlagAustralia },
        { id: 'FlagAboriginal', image: FlagAboriginal },
        { id: 'FlagTorresStrait', image: FlagTorresStrait },
      ]}
      paragraphs={ParagraphsAboriginalAndTorresStrait}
    />

    <Section
      heading="The LGBTQI community"
      flags={[
        { id: 'FlagPride', image: FlagPride },
        { id: 'FlagTrans', image: FlagTrans },
      ]}
      paragraphs={ParagraphsLGBTQI}
    />
  </div>
)

export default AcknowledgementsPage
