// @flow

import React from 'react'

import { Box, DropDownMenuItem, FlexContainer, Text } from 'care-ui'

type AssessmentRequesteesListItemProps = {
  listItem?: boolean,
  onClick?: () => void,
  subtitle: string,
  title: string,
}

const AssessmentRequesteesListItem = (
  props: AssessmentRequesteesListItemProps,
) => {
  const { title, subtitle, onClick, listItem = true } = props

  const WrapperComponent = listItem ? DropDownMenuItem : 'span'

  return (
    <WrapperComponent onClick={onClick}>
      <FlexContainer direction="column">
        <Box width="31em">
          <Text size="lg" wrap="nowrap">
            {title}
          </Text>
        </Box>

        <Box width="31em">
          <Text size="sm" wrap="nowrap">
            {subtitle}
          </Text>
        </Box>
      </FlexContainer>
    </WrapperComponent>
  )
}

export default AssessmentRequesteesListItem
