/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CareOption_resource$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type Resource_resource$ref: FragmentReference;
declare export opaque type Resource_resource$fragmentType: Resource_resource$ref;
export type Resource_resource = {|
  +$fragmentRefs: CareOption_resource$ref,
  +$refType: Resource_resource$ref,
|};
export type Resource_resource$data = Resource_resource;
export type Resource_resource$key = {
  +$data?: Resource_resource$data,
  +$fragmentRefs: Resource_resource$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "Resource_resource",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CareOption_resource"
    }
  ],
  "type": "Resource",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '0dedb8c92d9be93f6f2aac05ad9da770';

module.exports = node;
