// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

import type { SpacingOptions } from 'care-ui/atoms/themeHelpers'

import { boxContainer } from './Box.style'

type PropTypes = {
  alignContent?: string,
  backgroundColor?: string,
  border?: string,
  borderBottom?: string,
  borderRadius?: string,
  borderTop?: string,
  boxShadow?: 1 | 2 | 3 | 4 | 5,
  children?: Node,
  dataTestId?: string,
  direction?: 'row' | 'column' | 'row-reverse' | 'column-reverse',
  display?: string,
  height?: string,
  marginX?: SpacingOptions | string,
  marginY?: SpacingOptions | string,
  maxHeight?: string,
  maxWidth?: string,
  minHeight?: string,
  minWidth?: string,
  overflow?: 'auto' | 'hidden' | 'none' | 'scroll',
  paddingX?: SpacingOptions | string,
  paddingY?: SpacingOptions | string,
  position?: 'sticky' | 'fixed' | 'absolute' | 'relative',
  top?: number,
  width?: string,
  zIndex?: number,
}

const Box = (props: PropTypes) => {
  const { children, dataTestId } = props
  const { css } = useFela({ ...props })

  return (
    <div className={css(boxContainer)} data-testid={dataTestId}>
      {children}
    </div>
  )
}

export default Box
