// @flow

import React, { type ComponentType, type Node, Component } from 'react'
import { connect } from 'react-fela'

import Button from 'react-ui/components/Button'
import { H4 } from 'react-ui/components/Heading'
import VisuallyHidden from 'react-ui/components/VisuallyHidden'
import { Alert, Cross, Error, Notice, Tick } from 'shared/ui/Typography/Icons'

import type { FelaPropsType } from 'react-ui/typing'

export type FlashType = 'success' | 'notice' | 'alert' | 'error'

export type OptionsType = {
  header?: Node,
  message: Node,
  timeout?: number,
  type: FlashType,
}

type PropsType = OptionsType & {
  id: string,
  onClose?: (id: string) => void,
}

type InternalPropsType = FelaPropsType & PropsType

const themeColour = {
  alert: 'warning',
  error: 'danger',
  notice: 'primary',
  success: 'success',
}

const icons = {
  alert: Alert,
  error: Error,
  notice: Notice,
  success: Tick,
}

const styleRules = ({ type, theme: { palette } }) => {
  const colours = palette.component[themeColour[type]]
  return {
    flash: {
      display: 'flex',
      position: 'relative',
      padding: '1rem',
      marginTop: '1rem',
      borderStyle: 'solid',
      borderWidth: '2px',
      borderRadius: '4px',
      borderColor: colours.base,
      backgroundColor: colours.mutedBase,
    },
    icon: {
      paddingRight: '1rem',
    },
    content: {
      flex: 1,
      paddingTop: '0.5rem',
    },
    header: {
      fontSize: '1.55em',
      lineHeight: '1.25',
      marginTop: '-0.5rem',
      marginBottom: '0',
    },
    close: {
      marginLeft: '15px',
      // Expand the button's click area to invisibly cover the whole Flash component
      ':after': {
        content: '""',
        display: 'block',
        position: 'absolute',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
    },
  }
}

class Flash extends Component<InternalPropsType> {
  timeout: TimeoutID

  componentDidMount() {
    const { timeout = 5000 } = this.props
    if (timeout !== 0) {
      this.timeout = window.setTimeout(this.close, timeout)
    }
  }

  componentWillUnmount() {
    if (this.timeout) window.clearTimeout(this.timeout)
  }

  close = () => {
    const { onClose, id } = this.props
    if (onClose) {
      onClose(id)
    }
  }

  render() {
    const { styles, rules, type, message, header } = this.props
    const Icon = icons[type]
    return (
      <div className={styles.flash}>
        <VisuallyHidden>{type}</VisuallyHidden>
        <div className={styles.icon}>
          <Icon size="l" color={themeColour[type]} />
        </div>
        <div className={styles.content}>
          {header && <H4 extend={rules.header}>{header}</H4>}
          {message}
        </div>
        <Button
          icon={<Cross size="m" />}
          naked
          ghost
          extend={rules.close}
          onClick={this.close}
        />
      </div>
    )
  }
}

export default (connect(styleRules)(Flash): ComponentType<PropsType>)
