// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useRelayEnvironment } from 'react-relay'
import { find, map, reject, size } from 'lodash/fp'

import { OnboardingQuestionnaireFooter } from 'components/OnboardingQuestionnaire'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import { commit } from 'mutations/UserResearchProgramsResponseCreate'
import { createFlash } from 'platform_web/containers/Flash'
import { Button } from 'care-ui'

import { type DashboardPage_user } from 'pages/__generated__/DashboardPage_user.graphql'

type ProgramsType = {
  programs: $ReadOnlyArray<{|
    +id: string,
    +research_program: {|
      +description: string,
      +id: string,
      +title: string,
    |},
  |}>,
}

type PropsType = ProgramsType & {
  modalOpen?: boolean,
  parentHandleSubmit?: () => void,
  setModalOpen?: (modalOpen: boolean) => void,
  user: DashboardPage_user,
}

const programListStyle = () => ({
  marginBottom: '10rem',
})

const programStyle = () => ({
  '& > div': {
    borderBottom: '1px solid rgb(231, 233, 238)',
  },
  '& > div:last-child': {
    borderBottom: 'unset',
  },
})

const linkStyle = () => ({ LinkClass: { lineHeight: 'unset' } })

const headingStyle = () => ({
  display: 'flex',
  '& > h3': {
    width: 'unset',
  },
  '& > span': {
    marginTop: '2.5rem',
    marginLeft: '1rem',
  },
})

const buttonStyle = () => ({
  marginTop: '-5rem',
})

const ResearchAndDataSharing = ({
  programs,
  setModalOpen,
  user,
  parentHandleSubmit,
  modalOpen = false,
}: PropsType) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { t: translation } = useTranslation('dashboard')
  const { css } = useFela()
  const environment = useRelayEnvironment()
  const newAnswers = map(program => ({
    accepted: undefined,
    user_id: user.id,
    tenant_research_program_id: program.id,
  }))(programs)
  const [isDisabled, setIsDisabled] = useState(true)
  const [answers, setAnswers] = useState(newAnswers)

  const handleClick = (value, tenant_research_program_id) => {
    const answer = find(
      obj => obj.tenant_research_program_id === tenant_research_program_id,
    )(answers)
    answer.accepted = value
    setAnswers(answers)
    const answeredPrograms = reject(obj => obj.accepted === undefined)(answers)
    setIsDisabled(size(answeredPrograms) !== size(programs))
  }

  const completeSubmit = () => {
    createFlash({
      message: translation('thank_you_your_selection_was_recorded'),
      type: 'notice',
      timeout: 2000,
    })

    if (parentHandleSubmit) {
      parentHandleSubmit()
    }
  }

  const handleSubmit = () => {
    if (setModalOpen) setModalOpen(false)
    commit({
      onCompleted: () => completeSubmit(),
      environment,
      variables: {
        input: {
          user_research_programs_response_attributes: answers,
        },
      },
    })
  }

  return (
    <>
      <Container extend={{ maxWidth: '40rem' }}>
        <div className={css(programListStyle)}>
          <Heading extend={{ marginTop: '1rem' }} level={1}>
            {translation('research_and_data_sharing')}
          </Heading>
          <p>
            {translation(
              'your_service_participates_in_the_following_research_programs_to_develop_a_greater_depth_of_understanding_of_peoples_needs_your_data_is_anonymous_to_everyone_other_than_you_and_your_service_provider_contributing_your_identified_data_is_completely_voluntary_and_will_not_affect_your_clinical_care',
            )}
          </p>
          <p>
            {translation('for_more_information_on_the_use_of_your_data')}&nbsp;
            <a
              extend={linkStyle}
              rel="noopener noreferrer"
              target="_blank"
              href={user?.tenant?.privacyUrl || false}
            >
              {translation('click_here')}
            </a>.
          </p>

          <div className={css(programStyle)}>
            {map(program => (
              <div key={program.research_program.title}>
                <div className={css(headingStyle)}>
                  <Heading level={3}>{program.research_program.title}</Heading>
                  <span>
                    ({programs.indexOf(program) + 1} {translation('out_of')}{' '}
                    {size(programs)})
                  </span>
                </div>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: program.research_program.description,
                  }}
                />
                <div>
                  <input
                    type="radio"
                    id={`${program.id}-false`}
                    name={program.id}
                    value={false}
                    onClick={() => handleClick(false, program.id)}
                  />
                  <label htmlFor={`${program.id}-false`}>
                    {translation('i_decline')}
                  </label>
                </div>
                <br />
                <div>
                  <input
                    type="radio"
                    id={`${program.id}-true`}
                    name={program.id}
                    value
                    onClick={() => handleClick(true, program.id)}
                  />
                  <label htmlFor={`${program.id}-true`}>
                    {translation('i_accept')}
                  </label>
                </div>
                <br />
              </div>
            ))(programs)}
          </div>
        </div>
        {modalOpen && (
          <center className={css(buttonStyle)}>
            <Button
              disabled={isDisabled}
              onClick={handleSubmit}
              variant="primary"
            >
              {translation('save')}
            </Button>
          </center>
        )}
      </Container>
      {!modalOpen && (
        <OnboardingQuestionnaireFooter
          disabled={isDisabled}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  )
}

export default ResearchAndDataSharing
