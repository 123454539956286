// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { IconNew } from 'care-ui'
import { CloseIcon, SearchIcon } from 'care-ui/atoms/icons-new'

import { inputStyle, searchBarStyle, sizeStyle } from './SearchBar.style'

type PropsType = {
  ariaLabel?: string,
  clearSearch: () => void,
  dataTestId?: string,
  fullWidth?: boolean,
  inputAttributes: {
    id?: string,
    name?: string,
    onChange: (e: SyntheticInputEvent<>) => void,
    placeholder?: string,
    value?: string,
  },
  size?: 'lg' | 'md',
}

const SearchBar = (props: PropsType) => {
  const {
    size = 'md',
    inputAttributes,
    dataTestId,
    ariaLabel,
    clearSearch,
  } = props
  const { id, value, onChange, placeholder } = inputAttributes

  const { css } = useFela({ ...props })

  return (
    <div
      className={css(searchBarStyle, sizeStyle)}
      data-testid={dataTestId}
      aria-label={ariaLabel}
    >
      <IconNew
        as={SearchIcon}
        onClick={clearSearch}
        color="positive"
        size={size}
      />

      <input
        id={id}
        type="text"
        value={value}
        onChange={e => onChange(e)}
        className={css(inputStyle, sizeStyle)}
        placeholder={placeholder || 'Search'}
      />

      {value && (
        <IconNew
          as={CloseIcon}
          onClick={clearSearch}
          color="positive"
          size={size}
        />
      )}
    </div>
  )
}

export default SearchBar
