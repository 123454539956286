// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import { ResourceListLoader } from 'components/Resource/ResourceList'
import Heading from 'react-ui/components/Heading'

import type { ResourceLibraryPage_viewer } from './__generated__/ResourceLibraryPage_viewer.graphql'

type PropsType = {
  +relay: Object,
  +viewer: ResourceLibraryPage_viewer,
}

const ResourceLibraryPage = (props: PropsType) => {
  const { t: translation } = useTranslation('shared')
  const { viewer } = props

  return (
    <div>
      <Heading level={3}>{translation('resource_library')}</Heading>

      <ResourceListLoader resourcesConnectionInterface={viewer} />
    </div>
  )
}

export const ResourceLibraryPageQuery = graphql`
  query ResourceLibraryPage_Query {
    viewer {
      ...ResourceLibraryPage_viewer
    }
  }
`

export const ResourceLibraryPageLoader = createFragmentContainer(
  ResourceLibraryPage,
  {
    viewer: graphql`
      fragment ResourceLibraryPage_viewer on Viewer {
        id
        ...ResourceList_resourcesConnectionInterface
      }
    `,
  },
)
