// @flow

import Sentry from 'shared/services/sentry'

export default function(state: any, action: any) {
  switch (action.type) {
    case '@@found/RESOLVE_MATCH': {
      const { payload: { location: { pathname } } } = action
      Sentry.addBreadcrumb(pathname)
      break
    }
    default:
      break
  }

  return state || null
}
