/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClinicianNoteActivity_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianDropdown_individual$ref: FragmentReference;
declare export opaque type ClinicianDropdown_individual$fragmentType: ClinicianDropdown_individual$ref;
export type ClinicianDropdown_individual = {|
  +tenant: ?{|
    +summary_questionnaire: ?{|
      +id: string,
      +answerable: ?boolean,
    |}
  |},
  +user: {|
    +id: string,
    +clinician_notes: ?{|
      +totalCount: number
    |},
    +$fragmentRefs: ClinicianNoteActivity_user$ref,
  |},
  +duty_of_care_clinicians: $ReadOnlyArray<{|
    +user: {|
      +id: string
    |}
  |}>,
  +$refType: ClinicianDropdown_individual$ref,
|};
export type ClinicianDropdown_individual$data = ClinicianDropdown_individual;
export type ClinicianDropdown_individual$key = {
  +$data?: ClinicianDropdown_individual$data,
  +$fragmentRefs: ClinicianDropdown_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianDropdown_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "summary_questionnaire",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answerable",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianNoteConnection",
          "kind": "LinkedField",
          "name": "clinician_notes",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ClinicianNoteActivity_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianRole",
      "kind": "LinkedField",
      "name": "duty_of_care_clinicians",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '55b98075ff0015b9b28dae00d56b6d45';

module.exports = node;
