// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'
import { omit } from 'lodash/fp'

import { TrackableChartToggle } from 'components/SummaryGraph'
import HamburgerIcon from 'react-ui/assets/icons/hamburger.svg'
import Logo from 'react-ui/assets/images/innowell-logo-202111.svg'
import { Column, Row } from 'react-ui/components/Grid'
import Icon from 'react-ui/components/Icon'
import { OptionalLink } from 'react-ui/components/Link'
import Themelet from 'react-ui/components/Themelet'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { withoutFelaProps } from 'shared/services/fela'
import createComponentId from 'shared/services/id'
import { useWindowSize } from 'platform_web/hooks/useWindowSize'
import { Container } from 'care-ui'

import IconButton from './IconButton'
import NavigationContext from './Navigation/NavigationContext'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  actions?: Node,
  as: string,
  componentId?: string,
  hideLogoHeader: boolean,
  menuIsOpen: boolean,
  navId: string,
  noMenu?: boolean,
  onToggleMenu: () => void,
  triggerId: string,
}

const styleRules = ({ theme, noMenu, hideLogoHeader }) => {
  return {
    PageHeader: {
      top: '0',
      className: 'PageHeader',
      width: '100%',
      transitionDelay: '40ms',
      transitionDuration: '300ms',
      transitionProperty: 'transform',
      transitionTimingFunction: 'ease-in-out',
      userSelect: 'none',
      height: hideLogoHeader ? '63px' : '103px',
      position: 'fixed',
      zIndex: 20,
      [theme.breakpoints.queries.md]: {
        height: '63px',
      },
    },
    row: {
      justifyContent: 'space-between',
      alignItems: 'center',
      marginLeft: '0',
      marginRight: '0',
      [theme.breakpoints.queries.xxsOnly]: {
        margin: '0',
      },
    },
    logo: {
      className: 'PageHeader__logo',
      height: '30px',
      width: '140px',
    },
    actionsColumn: {
      display: 'flex',
      className: 'PageHeader__actionsColumn',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      width: '100%',
      minWidth: '180px',
      minHeight: '52px',
      marginTop: hideLogoHeader ? '0px' : '10px',
      [theme.breakpoints.queries.xs]: {
        justifyContent: noMenu ? 'flex-end' : 'space-between',
        padding: '0px 10px',
      },
      [theme.breakpoints.queries.sm]: {
        marginTop: '0',
        justifyContent: 'flex-end',
        width: 'auto',
      },

      // custom media queries to handle flags overlap on resize
      '@media screen and  (min-width: 809px) and (max-width: 869px)': {
        padding: '0',
      },
    },

    logoColumn: {
      display: 'flex',
      className: 'PageHeader__logoColumn',
      flexDirection: 'row',
      padding: '0 10px',
      width: '100%',
      justifyContent: noMenu ? 'center' : 'space-around',
      alignItems: 'baseline',
      paddingLeft: '0',
      paddingRight: '0',
      [theme.breakpoints.queries.sm]: {
        flexShrink: '0',
        flexGrow: '0',
        justifyContent: 'flex-start',
        width: 'auto',
        alignItems: 'center',
      },
      // custom media queries to handle flags overlap on resize
      '@media screen and  (min-width: 809px) and (max-width: 869px)': {
        padding: '0',
      },
    },

    flexHeaderBurgerMenu: {
      display: 'flex',
      alignSelf: 'flex-end',
    },
    flexHeaderLogoGroup: {
      display: 'flex',
      [theme.breakpoints.queries.xxsOnly]: {
        margin: '0 auto',
      },
    },

    menuTrigger: {
      className: 'PageHeader__menuTrigger',
      marginLeft: '-5px',
      marginRight: '5px',
    },

    mainContentRow: {
      className: 'PageHeader__mainContentRow',
      display: 'flex',
      padding: '10px 0',
      width: ' 100%',
      margin: '0',
      justifyContent: 'space-between',
      borderBottomColor: theme.palette.dividerColor,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
      [theme.breakpoints.queries.xxsOnly]: {
        padding: '10px 0',
      },
    },
  }
}

const LinkedLogo = ({ link, styles }) => (
  <OptionalLink link={link}>
    <Logo className={styles.logo} />
  </OptionalLink>
)

const defaultId = createComponentId(__filename)

const PageHeader = ({
  actions,
  as: Component = 'div',
  componentId = defaultId,
  hideLogoHeader,
  menuIsOpen,
  onToggleMenu,
  navId,
  triggerId,
  rules,
  styles,
  ...props
}: PropsType) => {
  const size = useWindowSize()
  const { homepage_path } = useCurrentUser()
  const hamBurgerMenu = () => {
    return (
      <NavigationContext.Consumer>
        {({ menu }) => {
          const variableAriaAttributes = menuIsOpen
            ? {
                'aria-controls': navId,
                'aria-owns': navId,
              }
            : {}
          return (
            <React.Fragment>
              {!!menu &&
                !!React.Children.toArray(menu).filter(child => !!child)
                  .length && (
                  <div className={styles.flexHeaderBurgerMenu}>
                    <IconButton
                      extend={rules.menuTrigger}
                      onClick={evt => {
                        evt.preventDefault()
                        onToggleMenu()
                      }}
                      innerRef={el => {
                        if (el && menuIsOpen) {
                          el.blur()
                        }
                      }}
                      aria-expanded={menuIsOpen ? 'true' : 'false'}
                      aria-haspopup="true"
                      id={triggerId}
                      {...variableAriaAttributes}
                    >
                      <Icon as={HamburgerIcon} title="Navigation" />
                    </IconButton>
                  </div>
                )}
            </React.Fragment>
          )
        }}
      </NavigationContext.Consumer>
    )
  }

  const logoAndButtonsHeader = () => {
    if (size.xs) {
      if (!hideLogoHeader)
        return (
          <div className={styles.flexHeaderLogoGroup}>
            <LinkedLogo link={homepage_path} styles={styles} />
          </div>
        )
    } else {
      return (
        <>
          {hamBurgerMenu()}
          <div className={styles.flexHeaderLogoGroup}>
            <LinkedLogo link={homepage_path} styles={styles} />
          </div>
        </>
      )
    }
    return false
  }

  return (
    <Component
      {...withoutFelaProps(omit(['noMenu'])(props))}
      className={classNames(styles.PageHeader, componentId)}
      style={{
        display: 'block',
      }}
    >
      {/* Default Header -- Burger Menu and Actions */}

      <Themelet variant="white" extend={rules.mainContentRow}>
        <Container>
          <Row extend={rules.row}>
            <Column extend={rules.logoColumn}>{logoAndButtonsHeader()}</Column>
            <Column extend={rules.logoColumn}>
              <TrackableChartToggle />
            </Column>
            <Column extend={rules.actionsColumn}>
              {size.xs && hamBurgerMenu()}
              {actions}
            </Column>
          </Row>
        </Container>
      </Themelet>
    </Component>
  )
}

export default connect(styleRules)(PageHeader)
