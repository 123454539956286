// @flow

import * as React from 'react'
import { capitalize, flow, startCase } from 'lodash/fp'

import ReadMore from 'react-ui/components/ReadMore'
import { dateTimeFormat } from 'services/dateTime'

export type PropsType = {
  reason: {
    resolution_option: string,
    resolution_text: string,
    resolved_at: string,
  },
}

const capitalizeUnderscoredInput = flow(startCase, capitalize)

const EscalationReasonCard = ({
  reason: { resolution_option, resolution_text, resolved_at },
}: PropsType) => {
  if (resolution_option !== 'other') {
    return capitalizeUnderscoredInput(resolution_option)
  }

  return (
    <ReadMore
      heading={`Time: ${dateTimeFormat(resolved_at)}`}
      subHeading="Other:"
      text={resolution_text}
      characterLimit={20}
    />
  )
}

export default EscalationReasonCard
