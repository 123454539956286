/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardsChangeLog_changeLog$ref = any;
type ScoreCardsPreviousAnswerSet_user_trackable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPreviousAnswers_user_trackable$ref: FragmentReference;
declare export opaque type ScoreCardsPreviousAnswers_user_trackable$fragmentType: ScoreCardsPreviousAnswers_user_trackable$ref;
export type ScoreCardsPreviousAnswers_user_trackable = {|
  +all_data_sets: $ReadOnlyArray<{|
    +points: $ReadOnlyArray<{|
      +id: string,
      +date: string,
      +value: ?number,
      +scaled_value: ?number,
      +questionnaire_answer_set_id: ?string,
    |}>
  |}>,
  +change_log_entries: ?$ReadOnlyArray<{|
    +id: string,
    +created_at: any,
    +$fragmentRefs: ScoreCardsChangeLog_changeLog$ref,
  |}>,
  +$fragmentRefs: ScoreCardsPreviousAnswerSet_user_trackable$ref,
  +$refType: ScoreCardsPreviousAnswers_user_trackable$ref,
|};
export type ScoreCardsPreviousAnswers_user_trackable$data = ScoreCardsPreviousAnswers_user_trackable;
export type ScoreCardsPreviousAnswers_user_trackable$key = {
  +$data?: ScoreCardsPreviousAnswers_user_trackable$data,
  +$fragmentRefs: ScoreCardsPreviousAnswers_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "trackable_end_time"
    },
    {
      "kind": "RootArgument",
      "name": "trackable_start_time"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPreviousAnswers_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrackableDataSet",
      "kind": "LinkedField",
      "name": "all_data_sets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserTrackableData",
          "kind": "LinkedField",
          "name": "points",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scaled_value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "questionnaire_answer_set_id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "end_time",
          "variableName": "trackable_end_time"
        },
        {
          "kind": "Variable",
          "name": "start_time",
          "variableName": "trackable_start_time"
        }
      ],
      "concreteType": "ChangeLogEntry",
      "kind": "LinkedField",
      "name": "change_log_entries",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ScoreCardsChangeLog_changeLog"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardsPreviousAnswerSet_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b3d881ca24a2e09669bfa2120639124';

module.exports = node;
