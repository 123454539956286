/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type SupportingSupporteeRemoveModal_individual_assignments$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type SupportingSupporteeInfoWrapper_individual_assignments$ref: FragmentReference;
declare export opaque type SupportingSupporteeInfoWrapper_individual_assignments$fragmentType: SupportingSupporteeInfoWrapper_individual_assignments$ref;
export type SupportingSupporteeInfoWrapper_individual_assignments = $ReadOnlyArray<{|
  +id: string,
  +individual: {|
    +id: string,
    +legal_name: ?string,
    +tenant: ?{|
      +support_person_help: ?string
    |},
  |},
  +$fragmentRefs: SupportingSupporteeRemoveModal_individual_assignments$ref,
  +$refType: SupportingSupporteeInfoWrapper_individual_assignments$ref,
|}>;
export type SupportingSupporteeInfoWrapper_individual_assignments$data = SupportingSupporteeInfoWrapper_individual_assignments;
export type SupportingSupporteeInfoWrapper_individual_assignments$key = $ReadOnlyArray<{
  +$data?: SupportingSupporteeInfoWrapper_individual_assignments$data,
  +$fragmentRefs: SupportingSupporteeInfoWrapper_individual_assignments$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SupportingSupporteeInfoWrapper_individual_assignments",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "individual",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "legal_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "support_person_help",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupportingSupporteeRemoveModal_individual_assignments"
    }
  ],
  "type": "SupportPersonAssignment",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '2935ad19413fa93ef8452f97cc3200c1';

module.exports = node;
