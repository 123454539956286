// @flow

import { graphql, useMutation } from 'react-relay'

import { type useCreateClinicianNoteMutation } from './__generated__/useCreateClinicianNoteMutation.graphql'

const mutation = graphql`
  mutation useCreateClinicianNoteMutation($input: ClinicianNoteCreateInput!) {
    clinicianNoteCreate(input: $input) {
      clinician_note {
        about {
          ...ClinicianNoteCardContainer_user
        }
      }
    }
  }
`

const useCreateClinicianNote = () => {
  // prettier-ignore
  const [ commit, isInFlight ] = useMutation<useCreateClinicianNoteMutation>(mutation)

  return { commit, isInFlight }
}

export default useCreateClinicianNote
