// @flow

import React from 'react'
import { filter, includes } from 'lodash/fp'

import UserCheckBox from 'react-ui/components/Form/UserCheckBox'

type SupportingSupporteeCheckBoxType = {
  assignmentId: string,
  label: string,
  selectedAssignments: Array<string>,
  setSelectedAssignments: (result: any) => void,
}

const SupportingSupporteeCheckBox = (
  props: SupportingSupporteeCheckBoxType,
) => {
  const {
    label,
    assignmentId,
    selectedAssignments,
    setSelectedAssignments,
  } = props

  const onChange = () => {
    if (includes(assignmentId)(selectedAssignments)) {
      setSelectedAssignments(prev => {
        const updated = filter(q => q !== assignmentId)(prev)
        return updated
      })
    } else {
      setSelectedAssignments(prev => [...prev, assignmentId])
    }
  }

  const isChecked = includes(assignmentId)(selectedAssignments)

  return (
    <UserCheckBox
      block
      checked={isChecked}
      label={label}
      user={null}
      onChange={onChange}
    />
  )
}

export default SupportingSupporteeCheckBox
