// @flow

import { createFragmentContainer, graphql } from 'react-relay'

import UserCheckBox from './UserCheckBox'

export default createFragmentContainer(UserCheckBox, {
  user: graphql`
    fragment UserCheckBoxLoader_user on User {
      ...UserCardLoader_user
      id
    }
  `,
})
