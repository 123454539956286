// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const AlertIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 28 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M14.0012902,24 C13.4490055,24 13.0012902,23.5522847 13.0012902,23 C13.0012902,22.4477153 13.4490055,22 14.0012902,22 C14.553575,22 15.0012902,22.4477153 15.0012902,23 C15.0012902,23.5522847 14.553575,24 14.0012902,24 Z M14.0012902,9 C14.553575,9 15.0012902,9.44771525 15.0012902,10 L15.0012902,20 C15.0012902,20.5522847 14.553575,21 14.0012902,21 C13.4490055,21 13.0012902,20.5522847 13.0012902,20 L13.0012902,10 C13.0012902,9.44771525 13.4490055,9 14.0012902,9 Z M13.106863,0.552786405 C13.4753873,-0.184262135 14.5271931,-0.184262135 14.8957174,0.552786405 L27.8957174,26.5527864 C28.2281679,27.2176875 27.7446722,28 27.0012902,28 L1.00129021,28 C0.25790819,28 -0.225587526,27.2176875 0.10686302,26.5527864 L13.106863,0.552786405 Z M2.6193242,26 L25.3832562,26 L14.0012902,3.23606798 L2.6193242,26 Z"
            id="alert-path-1"
          />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="icon-/-28x28-/-alert">
            <use
              id="Stroke-2991"
              fillRule="nonzero"
              xlinkHref="#alert-path-1"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default AlertIcon
