// @flow

import BrowserProtocol from 'farce/BrowserProtocol'
import createHistoryEnhancer from 'farce/createHistoryEnhancer'
import queryMiddleware from 'farce/queryMiddleware'
import { type Route } from 'found'
import { createNamedRoutesMiddleware } from 'found-named-routes'
import createMatchEnhancer from 'found/createMatchEnhancer'
import Matcher from 'found/Matcher'
import { type Store, applyMiddleware, createStore } from 'redux'
// this module is only used in develop
// eslint-disable-next-line import/no-extraneous-dependencies
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import thunk from 'redux-thunk'

import reducers from '../redux/reducers'

let store = null

export default ({ routeConfig }: { routeConfig: [Route] }) => {
  const namedRoutesMiddleware = createNamedRoutesMiddleware(routeConfig)

  if (store === null) {
    store = (createStore(
      reducers,
      composeWithDevTools(
        createHistoryEnhancer({
          protocol: new BrowserProtocol(),
          middlewares: [namedRoutesMiddleware, queryMiddleware],
        }),
        createMatchEnhancer(new Matcher(routeConfig)),
        applyMiddleware(thunk),
      ),
    ): Store<any, any>)
  }
  return store
}
