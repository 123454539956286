// @flow

import * as yup from 'yup'

import { ago, longDateFormat } from 'services/dateTime'
import type { QuestionType } from 'shared/utils/SimpleQuestionnaireTypes'

import { stateAddressesRegex } from './address'

const getAllValidationSchemas = (label, answerValidationRegex) => {
  const requiredMsg = label ? `${label} is a required field` : null

  const postcodeValidationRegex = new RegExp(answerValidationRegex || '')

  return {
    // common validations
    thisStringIsRequired: yup
      .string()
      .trim()
      .required(requiredMsg),

    thisStringIsEnsured: yup
      .string()
      .trim()
      .ensure(),

    thisArrayIsRequired: yup
      .array()
      .min(1)
      .ensure()
      .compact(),

    thisArrayIsEnsured: yup
      .array()
      .ensure()
      .compact(),

    // field validations
    date_of_birth: yup
      .date()
      .label('Date of birth')
      .required(requiredMsg)
      .min(
        ago(150, 'year'),
        ({ min }) => `Date of birth must be after ${longDateFormat(min)}`,
      )
      .max(
        new Date(),
        ({ max }) => `Date of birth must be before ${longDateFormat(max)}`,
      ),

    suburb: yup
      .string()
      .trim()
      .required(requiredMsg)
      .matches(
        new RegExp(`^\\D{1,}, (${stateAddressesRegex()})$`),
        'Location must follow the pattern, e.g Sydney, NSW',
      ),

    // NOTE: canada postcode
    postcode: yup
      .string()
      .trim()
      .required(requiredMsg)
      .matches(
        postcodeValidationRegex,
        'Postcode must follow the pattern, e.g A1B 2C3',
      ),

    no_of_children: yup
      .number()
      .typeError('Value must be a number')
      .required(requiredMsg)
      .min(1, 'Number must be greater than 0'),

    years_practicing_discipline: yup
      .number()
      .integer('Only enter whole number of years')
      .required('Please enter a valid value')
      .min(0, 'Number must be positive')
      .max(100, 'Number must be less than 100'),

    years_in_current_role: yup
      .number()
      .integer('Only enter whole number of years')
      .required('Please enter a valid value')
      .min(0, 'Number must be positive')
      .max(100, 'Number must be less than 100'),
  }
}

export const validationSchemaForQuestion = (
  {
    id,
    name,
    validationName,
    required,
    label,
    dependOn,
    answerValidationRegex,
  }: QuestionType,
  isMultiple: boolean,
) => {
  const validationSchema = ((): yup => {
    const allValidationSchemas = getAllValidationSchemas(
      label || name,
      answerValidationRegex,
    )
    const forField = allValidationSchemas[id || name]
    if (forField) return forField

    if (validationName === 'conditional' && dependOn) {
      const { thisStringIsEnsured, thisStringIsRequired } = allValidationSchemas
      const fieldName = Object.keys(dependOn)[0]
      const fieldValue = Object.values(dependOn)[0]

      return yup.string().when(fieldName, {
        is: value => value && value === fieldValue,
        then: thisStringIsRequired,
        otherwise: thisStringIsEnsured,
      })
    }

    if (isMultiple) {
      const { thisArrayIsEnsured, thisArrayIsRequired } = allValidationSchemas
      if (!required) return thisArrayIsEnsured
      return thisArrayIsRequired
    }
    const { thisStringIsEnsured, thisStringIsRequired } = allValidationSchemas
    if (!required) return thisStringIsEnsured
    return thisStringIsRequired
  })()
  return validationSchema
}
