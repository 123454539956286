// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { WelcomePage } from 'platform_web/pages/WelcomePage'

export const WelcomePageContainerQuery = graphql`
  query WelcomePageContainer_Query {
    viewer {
      currentUser {
        ...WelcomePageContainer_user
        current_role {
          ...WelcomePageContainer_userCurrentRole
        }
      }
    }
  }
`

const WelcomePageContainer = props => {
  const { user, userCurrentRole, ...restOfProps } = props

  const roleType = userCurrentRole.role_type
  const initialQuestionnaireStatus =
    userCurrentRole.initial_questionnaire_status
  const summaryQuestionnaireId =
    userCurrentRole.tenant && userCurrentRole.tenant.summary_questionnaire
      ? userCurrentRole.tenant.summary_questionnaire.id
      : ''
  const initialQuestionnaireId = userCurrentRole.tenant
    ? userCurrentRole.tenant.initial_questionnaire.id
    : ''

  return (
    <WelcomePage
      {...restOfProps}
      user={user}
      roleType={roleType}
      initialQuestionnaireStatus={initialQuestionnaireStatus}
      summaryQuestionnaireId={summaryQuestionnaireId}
      initialQuestionnaireId={initialQuestionnaireId}
    />
  )
}

export const WelcomePageContainerLoader = createFragmentContainer(
  WelcomePageContainer,
  {
    user: graphql`
      fragment WelcomePageContainer_user on User {
        id
        casual_name
        sign_in_count
        has_unfinished_initial_user_questionnaires
        multi_roles_enabled
        staff_role
        show_individual_onboarding
        policies {
          name
          enabled
        }
        current_role {
          ... on RoleInterface {
            parent {
              ... on RoleParentInterface {
                name
                id
              }
              __typename
            }
          }
        }
      }
    `,
    userCurrentRole: graphql`
      fragment WelcomePageContainer_userCurrentRole on RoleInterface {
        role_type
        ... on IndividualRole {
          id
          initial_questionnaire_status
          tenant {
            summary_questionnaire {
              id
            }
            initial_questionnaire {
              id
            }
          }
        }
      }
    `,
  },
)
