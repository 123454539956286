// @flow

import { type ThemeType } from 'care-ui/atoms'

export const wrapperStyle = () => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
})

export const contentAreaStyle = () => ({
  width: '100%',
  height: '100%',
})

export const buttonAreaStyle = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  paddingTop: '10px',
  justifyContent: 'left',
  flexDirection: 'column',
  width: 'fit-content',
  [theme.breakpoints.queries.md]: {
    width: 'auto',
    flexDirection: 'row',
  },
  '>a': {
    width: 'fit-content',
    marginBottom: '10px',
    [theme.breakpoints.queries.md]: {
      marginRight: '10px',
      width: 'auto',
      marginBottom: 0,
    },
  },
})

export const titleStyle = () => ({
  marginTop: 0,
})

export const statusContainerStyle = () => ({
  marginTop: '1rem',
})

export const careOptionTextStyle = ({ theme }: { theme: ThemeType }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

export const buttonContainerStyle = ({ theme }: { theme: ThemeType }) => ({
  display: 'flex',
  flexDirection: 'column',
  ...theme.care.typography.desktop.bodyLg,
  [theme.care.breakpoints.queries.md]: {
    alignItems: 'flex-end',
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
})
