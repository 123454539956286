// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const containerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.care.radius.md,
    border: `1px solid ${theme.care.palette.border.subtle}`,
    color: theme.care.palette.text.positive,
    padding: `${theme.care.spacing.lg} ${theme.care.spacing.sm} `,
    [theme.care.breakpoints.queries.md]: {
      padding: theme.care.spacing.xl,
    },
  }
}

export const headerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: theme.care.spacing.lg,
  }
}

export const subHeaderStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: theme.care.spacing.xxs,
  }
}

export const cardContainerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'grid',
    gridTemplateColumns: '1fr',
    gridGap: theme.care.spacing.sm,
    justifyItems: 'center',
    [theme.care.breakpoints.queries.md]: {
      justifyItems: 'start',
      gridTemplateColumns: 'repeat(auto-fill, minmax(232px, 1fr))',
    },
  }
}

export const linkStyle = (props: StyleProps) => {
  const { theme: { care } } = props

  return {
    textDecoration: 'underline',
    cursor: 'pointer',
    marginRight: care.spacing.sm,
    ...care.typography.desktop.BodyLgBold,
    [care.breakpoints.queries.md]: {
      marginRight: care.spacing.xxs,
    },
  }
}
