// @flow

import React, { type ComponentType, type Node } from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'

import type { FelaPropsType } from 'react-ui/typing'

type StylesType = {
  buttonsPosition?: 'start' | 'center' | 'end',
  direction?: 'horizontal' | 'vertical',
  gapBeforeItemIndex?: number,
  grow?: boolean,
  wrap?: boolean,
}

type PropsType = StylesType & {
  children?: Node,
  className?: string,
}

const margin = '5px'

const styleRules = ({
  grow,
  direction = 'horizontal',
  buttonsPosition,
  gapBeforeItemIndex,
  wrap,
}: StylesType) => ({
  ButtonGroup: {
    ...(() => {
      switch (buttonsPosition) {
        case 'start':
          return { justifyContent: 'flex-start' }
        case 'center':
          return { justifyContent: 'center' }
        case 'end':
          return { justifyContent: 'flex-end' }
        default:
          return {}
      }
    })(),
    className: classNames('ButtonGroup', `--direction-${direction}`, {
      '--wrap': wrap,
      '--grow': grow,
    }).replace(/\s/g, ''),
    display: 'flex',
    flexDirection: direction === 'vertical' ? 'column' : 'row',
    alignItems: direction === 'vertical' ? 'stretch' : 'center',
    flexWrap: wrap ? 'wrap' : 'nowrap',
    flexGrow: grow ? 1 : 0,
    marginTop: wrap ? `-${margin}` : 0,
    marginLeft: `-${margin}`,
    marginRight: `-${margin}`,
  },
  button: {
    ':empty': {
      display: 'none',
    },
    className: `ButtonGroup__button--direction-${direction}`,
    flexGrow: grow ? 1 : 0,
    ...(() => {
      const o = {
        marginLeft: '0',
        marginRight: '0',
        marginTop: '0',
        marginBottom: '0',
      }
      if (direction === 'horizontal') {
        o.marginLeft = margin
        o.marginRight = margin

        if (gapBeforeItemIndex) {
          o[`:nth-child(${gapBeforeItemIndex})`] = {
            order: 1,
            marginLeft: 'auto',
          }
        }
      }
      if (direction === 'vertical' || wrap) {
        o.marginTop = margin
        o.marginBottom = margin
      }

      return o
    })(),
  },
})

const ButtonGroup = ({
  className,
  children,
  styles,
  rules,
}: PropsType & FelaPropsType) => (
  <div className={classNames(styles.ButtonGroup, className)}>
    {React.Children.map(children, child => {
      if (child === null) {
        return null
      }

      return React.cloneElement(child, {
        extend: (...args) => rules.button(...args),
      })
    })}
  </div>
)

export default (connect(styleRules)(ButtonGroup): ComponentType<PropsType>)
