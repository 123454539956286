// @flow

import React, { type Node, Component } from 'react'
import { connect } from 'react-fela'

import { Chevron } from 'shared/ui/Typography/Icons'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  content?: Node,
}

type StateType = {
  isOpen: boolean,
}

const styleRules = ({ theme }) => ({
  extendedRowArrow: {
    alignItems: 'center',
    background: 'white',
    display: 'flex',
    justifyContent: 'center',
    outline: 'none',
    width: '100%',
    border: '0px',
    padding: '0px',
  },
  content: {
    background: 'white',
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
  },
  hr: {
    borderTop: '0',
    color: theme.DataGrid.cell.accent,
    height: '1px',
    margin: '0',
  },
  thickLineWithBorderBottom: {
    borderBottom: `2px solid ${theme.DataGrid.header.text}`,
    textAlign: 'center',
    width: '100px',
  },
})

class DataGridRowFooterRenderer extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  toggleExtendedRow = () => {
    this.setState(({ isOpen }) => ({
      isOpen: !isOpen,
    }))
  }

  render() {
    const { props: { styles, content }, state: { isOpen } } = this

    const renderedButton = (
      <button
        type="button"
        data-component-id="data-grid-extend-row-btn"
        className={styles.extendedRowArrow}
        onClick={this.toggleExtendedRow}
      >
        <span className={isOpen ? styles.thickLineWithBorderBottom : ''}>
          <Chevron size="m" rotation={isOpen ? 'down' : 'none'} />
        </span>
      </button>
    )

    return content ? (
      <>
        {isOpen && (
          <div className={styles.content}>
            <hr className={styles.hr} />
            {content}
          </div>
        )}
        {renderedButton}
      </>
    ) : null
  }
}

export default connect(styleRules)(DataGridRowFooterRenderer)
