// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { concat, uniqBy } from 'lodash/fp'

import DefinitionList from 'react-ui/components/DefinitionList'
import { Heading, Text } from 'care-ui'

import { PersonalDetailFields } from '../../../PersonalDetailFields'
import { fieldLinkGenerator } from '../../../services'
import { userSettingsPersonalDetailsPageUserFields } from '../../queries/UserSettingsPersonalDetailsPage'
import UserRemoveInstructions from '../UserRemoveInstructions'

import type { UserSettingsPersonalDetailsPage_profileQuestionnaire } from '../../queries/__generated__/UserSettingsPersonalDetailsPage_profileQuestionnaire.graphql'
import type { UserSettingsPersonalDetailsPage_userFields } from '../../queries/__generated__/UserSettingsPersonalDetailsPage_userFields.graphql'

type PropsType = {
  +profileQuestionnaire: UserSettingsPersonalDetailsPage_profileQuestionnaire,
  +userFields: UserSettingsPersonalDetailsPage_userFields,
}

const renderedFields = (
  userFields: UserSettingsPersonalDetailsPage_userFields,
  profileQuestionnaire: UserSettingsPersonalDetailsPage_profileQuestionnaire,
) => {
  return uniqBy(
    'id',
    concat(
      fieldLinkGenerator(
        'settings_user_field',
        'CAN_UPDATE_USERS',
        userFields.profileFields,
      ),
      PersonalDetailFields(
        profileQuestionnaire,
        'CAN_UPDATE_INDIVIDUAL_PERSONAL_DETAIL',
        'settings_clinician_detail_field',
      ),
    ),
  )
}

export const UserSettingsPersonalDetailsPage = (props: PropsType) => {
  const {
    userFields: { tenant, unconfirmed_email },
    userFields: { current_role: { role_type } },
    userFields,
    profileQuestionnaire,
  } = props

  const { t: translation } = useTranslation('settings')

  const details = renderedFields(userFields, profileQuestionnaire)
  const updatedDetails = details.map(detail => {
    if (detail.id === 'email' && unconfirmed_email) {
      return {
        ...detail,
        warning: translation('email_change_requested_awaiting_confirmation'),
      }
    }
    return detail
  })

  const isIndividual = role_type === 'INDIVIDUAL'

  return (
    <>
      <Heading level={3} margin="xxs">
        {translation('profile_settings')}
      </Heading>

      <Text size="lg">
        {translation(
          'on_this_page_you_can_manage_your_information_including_your_contact_details',
        )}
      </Text>

      <DefinitionList details={updatedDetails} />

      {isIndividual && (
        <UserRemoveInstructions
          contract={tenant?.contract}
          privacyUrl={tenant?.privacyUrl}
        />
      )}
    </>
  )
}

export const UserSettingsPersonalDetailsPageLoader = createFragmentContainer(
  UserSettingsPersonalDetailsPage,
  userSettingsPersonalDetailsPageUserFields,
)
