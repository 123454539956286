//  @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { addMonths, subMonths } from 'date-fns'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import addNoDataModule from 'highcharts/modules/no-data-to-display'
import { find } from 'lodash/fp'

import {
  type DateTimeAxisType,
  Basic,
  chartTitle,
  dateTimeAxis,
  responsiveRules,
  yAxisLine,
} from 'react-ui/components/Charts/HighChartOptions/defaultOptions'
import { withinDateRange } from 'react-ui/components/Charts/HighChartOptions/helpers'

addNoDataModule(Highcharts)
Highcharts.seriesTypes.scatter.prototype.getPointSpline =
  Highcharts.seriesTypes.spline.prototype.getPointSpline

type VisibilityStateProps = {
  invisible: Array<string>,
  setInvisible: ((Array<string>) => Array<string>) => void,
}

type PropsType = {
  broadcastPointData?: Function,
  dateRange: DateTimeAxisType,
  isClinician: boolean,
  scoresRange: Object,
  series: Array<Object>,
  translation: TFunction,
  visibilityState: VisibilityStateProps,
}

const moodSeries = (
  line: Object,
  scoresRange: Object,
  dateRange: DateTimeAxisType,
) => {
  const accessor = point => point.x

  const startDate = subMonths(dateRange.localStartDate, 6)
  const endDate = addMonths(dateRange.localEndDate, 6)

  return {
    name: line.name,
    type: 'scatter',
    states: {
      hover: {
        enabled: false,
      },
    },
    data: withinDateRange({
      series: line.data,
      startDate,
      endDate,
      accessor,
    }),
    lineWidth: line.name ? 2 : 0,
    yAxis: 0,
    zIndex: 1,
    marker: {
      enabled: !!line.name,
    },
  }
}

const options = (props: PropsType) => {
  const {
    dateRange,
    isClinician,
    scoresRange,
    series,
    translation,
    visibilityState,
  } = props
  const seriesSet = []
  const { invisible, setInvisible } = visibilityState

  series.map(item => seriesSet.push(moodSeries(item, scoresRange, dateRange)))

  if (seriesSet.length < 1) {
    seriesSet.push(moodSeries({ name: '', data: [] }, scoresRange, dateRange))
  }

  const yAxis = {
    ...yAxisLine(scoresRange),
    crosshair: false,
    lineWidth: 1,
    labels: {
      formatter() {
        return this.value === 1 ? translation('healty') : null
      },
      x: -15,
      style: {
        fontSize: '14px',
        fontFamily: 'Raleway',
      },
    },
  }

  const legendlabelComponent = legend => {
    const containerStyle = `
      display: flex;
      border: 1px solid ${legend.visible ? '#003272' : '#c0c0c0'};
      border-radius: 8px;
      padding: 8px 16px;
      color: ${legend.visible ? '#003272;' : '#C0C0C0'};
      background-color: ${legend.visible ? '#fff' : '#f0f0f0'};
      margin-top: 10px;
      margin-bottom: 10px;
      visibility: ${legend.name ? 'visible' : 'hidden'};
      font-size: 14px;
      font-family: Raleway;
    `
    const symbolStyle = `
      background-color: ${legend.color};
      height: 1rem;
      width: 1rem;
      border-radius: 50%;
      margin-right: 5px;
    `
    return `
      <div style='${containerStyle}' >
        <span style='${symbolStyle}' ></span>
        <span>${legend.name}</span>
      </div>
    `
  }

  const dataLabelComponent = () => {
    const containerStyle = `
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 7px;
    `
    const circleStyle = `
      border: 1px solid black;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      background: white;
    `
    return `
      <div style='${containerStyle}' >
        <span style='${circleStyle}' ></span>
      </div>
    `
  }

  const renderTooltip = seriesTooltips => {
    const containerStyle = `
      display: flex;
      align-items: center;
    `
    const pointStyle = `
      display: inline-block;
      border-radius: 50%;
      width: 8px;
      height: 8px;
      margin-right: 5px;
    `

    const tooltips = seriesTooltips.map(
      ({ color, tooltip, rating }) =>
        `
      <div style='${containerStyle}' >
        <span style='${pointStyle} background-color: ${color};'></span>
        ${isClinician ? tooltip : rating}
      </div>
      `,
    )

    return tooltips.join(' ')
  }

  return {
    ...chartTitle(),
    ...Basic(),
    ...responsiveRules,
    xAxis: {
      ...dateTimeAxis(dateRange),
    },
    chart: {
      spacingRight: 50,
      spacingLeft: 0,
      spacingTop: 15,
      events: {
        load() {
          this.series.forEach(seriesItem => {
            if (invisible?.includes(seriesItem.name))
              seriesItem.update({ visible: false })
            else seriesItem.update({ visible: true })
          })
        },
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
      },
      series: {
        animation: false,
        stickyTracking: false,
        dataLabels: {
          useHTML: true,
          enabled: true,
          crop: false,
          overflow: 'none',
          y: 12,
          formatter() {
            const { point, series: currentSeries } = this

            const { x: currentX, y: currentY } = point

            const currentXToDate = new Date(currentX).toDateString()

            let overlapSeries = []
            const allSeries = currentSeries.chart.series

            allSeries.forEach(chartSeries => {
              const { visible, points } = chartSeries

              const matchingPoints = find(({ x, y }) => {
                const xToDate = new Date(x).toDateString()
                return currentXToDate === xToDate && currentY === y
              })(points)

              if (matchingPoints && visible) {
                overlapSeries = [...overlapSeries, matchingPoints]
              }
            })

            return overlapSeries?.length > 1 && dataLabelComponent()
          },
        },
        marker: {
          symbol: 'circle',
        },
        events: {
          legendItemClick() {
            if (invisible?.includes(this.name)) {
              setTimeout(() => {
                setInvisible(current => {
                  return current.filter(name => name !== this.name)
                })
              })
            } else {
              setTimeout(() => {
                setInvisible(current => {
                  return [...current, this.name]
                })
              })
            }
          },
          // eslint-disable-next-line func-names, object-shorthand
          mouseOut: function() {
            this.chart.update({
              tooltip: {
                enabled: false,
              },
            })
          },
          // eslint-disable-next-line func-names, object-shorthand
          mouseOver: function() {
            this.chart.update({
              tooltip: {
                enabled: true,
              },
            })
          },
        },
      },
    },
    series: seriesSet,
    tooltip: {
      enabled: true,
      useHTML: true,
      backgroundColor: 'rgba(255, 255, 255, 1)',
      formatter() {
        const { point, series: currentSeries } = this

        const { x: currentX, y: currentY } = point

        const currentXToDate = new Date(currentX).toDateString()

        let overlapSeries = []
        const allSeries = currentSeries.chart.series

        allSeries.forEach(chartSeries => {
          const { visible, points } = chartSeries

          const matchingPoints = find(({ x, y }) => {
            const xToDate = new Date(x).toDateString()
            return currentXToDate === xToDate && currentY === y
          })(points)

          if (matchingPoints && visible) {
            overlapSeries = [...overlapSeries, matchingPoints]
          }
        })

        return renderTooltip(overlapSeries)
      },
    },
    legend: {
      useHTML: true,
      labelFormatter() {
        return legendlabelComponent(this)
      },
      symbolWidth: 0,
      alignColumns: false,
    },
    yAxis,
  }
}

const SummaryGraphHighchart = (props: PropsType) => {
  const generatedOptions = options(props)

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={generatedOptions}
      immutable
      constructorType="chart"
    />
  )
}

export default SummaryGraphHighchart
