// @flow

import React, { useContext } from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { type TenantContext_parent } from './__generated__/TenantContext_parent.graphql'

type PropsType = {
  children: Node,
  parent: TenantContext_parent,
}

const TenantContext: any = React.createContext({})
export const TenantContextConsumer = TenantContext.Consumer
export const useTenant = () => {
  const tenantOrGroup = useContext(TenantContext)
  const isTenant = tenantOrGroup?.__typename === 'Tenant'
  const tenant = isTenant ? tenantOrGroup : null
  const isTenantGroup = tenantOrGroup?.__typename === 'TenantGroup'
  const tenantGroup = isTenantGroup ? tenantOrGroup : null
  return { tenant, tenantGroup, isTenant, isTenantGroup }
}

export const TenantContextProviderComponent = ({
  children,
  parent,
}: PropsType) => (
  <TenantContext.Provider value={parent}>{children}</TenantContext.Provider>
)

export const TenantContextProvider = createFragmentContainer(
  TenantContextProviderComponent,
  {
    parent: graphql`
      fragment TenantContext_parent on RoleParentInterface {
        id
        name
        supported_languages
        __typename
      }
    `,
  },
)

export default TenantContext
