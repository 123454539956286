// @flow

import React from 'react'

import { type FelaStylesType } from 'react-ui/typing'

type DetailType = {
  name: string,
  styles: FelaStylesType,
  value: string,
}

const IndividualDetail = (props: DetailType) => {
  const { name, value, styles } = props

  return (
    <div className={styles.row}>
      <dt className={styles.name}>{name}: </dt>

      <dd className={styles.value}>{value}</dd>
    </div>
  )
}

export default IndividualDetail
