// @flow

import * as React from 'react'
import Route from 'found/Route'

import { StaffCliniciansPageWithPolicies } from 'platform_web/pages/Staff/Clinicians/StaffCliniciansPage'
import { StaffEmployeesPageWithPolicies } from 'platform_web/pages/Staff/Employees/StaffEmployeesPage'
import { StaffEscalationsPageWithPolicies } from 'platform_web/pages/Staff/Escalations/StaffEscalationsPage'
import { IndividualsPageWithPolicies } from 'platform_web/pages/Staff/Individuals/IndividualsPage'

const routes = (
  <Route>
    <Route
      Component={StaffEmployeesPageWithPolicies}
      name="staff_managers"
      path="/managers"
    />
    <Route
      Component={StaffEmployeesPageWithPolicies}
      name="staff_employees"
      path="/employees"
    />
    <Route
      Component={IndividualsPageWithPolicies}
      name="staff_individuals"
      path="/individuals"
    />
    <Route
      Component={StaffCliniciansPageWithPolicies}
      name="staff_clinicians"
      path="/clinicians"
    />
    <Route
      Component={StaffEscalationsPageWithPolicies}
      name="staff_notifications"
      path="/notifications/:status?/:stage?"
    />
  </Route>
)

export default routes
