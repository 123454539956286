// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RemoveStaffMutationResponse,
  RemoveStaffMutationVariables,
} from './__generated__/RemoveStaffMutation.graphql'

const mutation = graphql`
  mutation RemoveStaffMutation($input: RoleRemoveInput!) {
    roleRemove(input: $input) {
      role {
        ... on RoleInterface {
          id
          ...EmployeesTable_role
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: RemoveStaffMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: RemoveStaffMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
