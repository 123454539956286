// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const containerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.care.spacing.xl,
  }
}
