// @flow

import { graphql } from 'react-relay'

export const retakeQuestionnaireQuery = {
  individual: graphql`
    fragment RetakeQuestionnaireDropdown_individual on IndividualRole {
      tenant {
        initial_questionnaire {
          id
        }
        summary_questionnaire {
          id
        }
      }
    }
  `,
}
