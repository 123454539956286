// @flow

import type { TFunction } from 'react-i18next'
import { useSetState } from 'react-use'

import { colors } from 'care-ui/atoms/colors/colors'

export const get_baseline_score_legend = (translation: TFunction) => [
  { name: translation('skipped'), color: colors.surface.neutralDark },
  { name: translation('high'), color: colors.surface.danger },
  { name: translation('moderate'), color: colors.surface.warning },
  { name: translation('mild'), color: colors.surface.mild },
  { name: translation('good'), color: colors.surface.success },
]

export const get_recovery_trajectory_legend = (translation: TFunction) => [
  { name: translation('improved'), color: colors.surface.success },
  { name: translation('no_change'), color: colors.surface.neutralDark },
  { name: translation('deteriorate'), color: colors.surface.danger },
]

export type OutcomeReportingQueryVariableType = {
  quarter_end_date?: string,
  quarter_start_date?: string,
  tenant_id?: string,
}

type UseOutcomeReportingParams = {
  defaultOutcomeReportingQueryVariables: OutcomeReportingQueryVariableType,
}

export type UseOutcomeReportingReturnType = {
  outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
  setOutcomeReportQueryVariables: (
    outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
  ) => void,
}

const useOutcomeReporting = (
  params: UseOutcomeReportingParams,
): UseOutcomeReportingReturnType => {
  const { defaultOutcomeReportingQueryVariables } = params

  const [
    outcomeReportQueryVariables,
    setOutcomeReportQueryVariables,
  ] = useSetState(defaultOutcomeReportingQueryVariables)

  return {
    outcomeReportQueryVariables,
    setOutcomeReportQueryVariables,
  }
}

export default useOutcomeReporting
