/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CurrentResultCardFormula_user_trackable$ref = any;
type UserQuestionSetTable_user_question_sets$ref = any;
type UserResource_user_resource$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type CurrentResultCard_user_trackable$ref: FragmentReference;
declare export opaque type CurrentResultCard_user_trackable$fragmentType: CurrentResultCard_user_trackable$ref;
export type CurrentResultCard_user_trackable = {|
  +escalated: boolean,
  +matches_user_age: ?boolean,
  +assigned_to_me: boolean,
  +suggested_user_resources: $ReadOnlyArray<{|
    +status: UserResourceStatuses,
    +id: string,
    +resource: {|
      +clinical: boolean
    |},
    +$fragmentRefs: UserResource_user_resource$ref,
  |}>,
  +primary_user_questionnaire: ?{|
    +id: string,
    +user: {|
      +id: string
    |},
    +questionnaire: ?{|
      +label: string,
      +id: string,
    |},
    +ensure_questionnaire: {|
      +label: string,
      +id: string,
    |},
    +user_question_sets: $ReadOnlyArray<{|
      +for_role_type: string,
      +$fragmentRefs: UserQuestionSetTable_user_question_sets$ref,
    |}>,
  |},
  +$fragmentRefs: CurrentResultCardFormula_user_trackable$ref,
  +$refType: CurrentResultCard_user_trackable$ref,
|};
export type CurrentResultCard_user_trackable$data = CurrentResultCard_user_trackable;
export type CurrentResultCard_user_trackable$key = {
  +$data?: CurrentResultCard_user_trackable$data,
  +$fragmentRefs: CurrentResultCard_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CurrentResultCard_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "escalated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "matches_user_age",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assigned_to_me",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResource",
      "kind": "LinkedField",
      "name": "suggested_user_resources",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clinical",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserResource_user_resource"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "primary_user_questionnaire",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "ensure_questionnaire",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserQuestionSet",
          "kind": "LinkedField",
          "name": "user_question_sets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "for_role_type",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserQuestionSetTable_user_question_sets"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentResultCardFormula_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '4713abd1cdfc4bbd4c3c3689f9b635cd';

module.exports = node;
