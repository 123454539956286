// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import cx from 'classnames'

import Markdown from 'react-ui/components/Markdown'
import type { UserQuestionnairesQuestionSet_Question_question } from 'platform_web/components/UserQuestionnairesQuestionSet/__generated__/UserQuestionnairesQuestionSet_Question_question.graphql'

type PropsType = {
  children: React.Node,
  classNames: string,
  description: React.Node,
  displayLabel: boolean,
  errors: React.Node & { props: { errors: Array<string> } },
  help: React.Node,
  id: string,
  required: boolean,
  schema: {
    image: ?$PropertyType<
      UserQuestionnairesQuestionSet_Question_question,
      'image',
    >,
    title: string,
  },
}

const imageStyle = {
  width: '100%',
}

const formGroupStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

export const FieldTemplate = ({
  children,
  classNames,
  displayLabel,
  help,
  id,
  schema: { title, image },
}: PropsType) => {
  const { css } = useFela()
  return (
    <div
      className={cx(classNames, css(formGroupStyle))}
      data-component-id={`question_${id}`}
    >
      {displayLabel && (
        <label htmlFor={id}>
          <Markdown source={title} />
          {image && (
            <img style={imageStyle} src={image.url} alt={image.alt_txt} />
          )}
        </label>
      )}
      {children}
      {help}
    </div>
  )
}
