/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type QuestionnaireRefresh_questionnaire$ref = any;
type TrackableResultCardAlerts_user_trackable$ref = any;
type UserTrackableView_user_trackable$ref = any;
export type TenantTrackableKindEnum = "additional_assessment" | "gauge" | "text" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserTrackable_user_trackable$ref: FragmentReference;
declare export opaque type UserTrackable_user_trackable$fragmentType: UserTrackable_user_trackable$ref;
export type UserTrackable_user_trackable = {|
  +current_data: {|
    +date: string,
    +value: ?number,
  |},
  +current_formula_range_ratio: {|
    +value: ?number
  |},
  +formula_range: ?{|
    +__typename: string,
    +color?: string,
    +label?: string,
    +description?: ?string,
    +pending?: boolean,
    +id?: string,
  |},
  +answered: boolean,
  +has_activity: boolean,
  +has_supporting_clinician: boolean,
  +id: string,
  +participants: $ReadOnlyArray<{|
    +id: string,
    +name: string,
    +$fragmentRefs: AvatarLoader_user$ref,
  |}>,
  +primary_user_questionnaire: ?{|
    +refresh_required: boolean,
    +questionnaire: ?{|
      +id: string
    |},
    +ensure_questionnaire: {|
      +id: string,
      +$fragmentRefs: QuestionnaireRefresh_questionnaire$ref,
    |},
  |},
  +requires_support: boolean,
  +time_since_updated: ?string,
  +refresh_required: boolean,
  +trackable: {|
    +id: string,
    +kind: TenantTrackableKindEnum,
    +label: string,
    +formula: {|
      +formula_ranges: ?$ReadOnlyArray<{|
        +label: string,
        +value: ?number,
      |}>
    |},
  |},
  +trending: ?{|
    +label: string
  |},
  +user: {|
    +id: string,
    +$fragmentRefs: AvatarLoader_user$ref,
  |},
  +$fragmentRefs: UserTrackableView_user_trackable$ref & TrackableResultCardAlerts_user_trackable$ref,
  +$refType: UserTrackable_user_trackable$ref,
|};
export type UserTrackable_user_trackable$data = UserTrackable_user_trackable;
export type UserTrackable_user_trackable$key = {
  +$data?: UserTrackable_user_trackable$data,
  +$fragmentRefs: UserTrackable_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = [
  (v1/*: any*/)
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "AvatarLoader_user"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "refresh_required",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserTrackable_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrackableData",
      "kind": "LinkedField",
      "name": "current_data",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "date",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrackableData",
      "kind": "LinkedField",
      "name": "current_formula_range_ratio",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "formula_range",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v2/*: any*/),
          "type": "FormulaRange",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "pending",
              "storageKey": null
            }
          ],
          "type": "FormulaRangeInterface",
          "abstractKey": "__isFormulaRangeInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "answered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "has_activity",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "has_supporting_clinician",
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "participants",
      "plural": true,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "primary_user_questionnaire",
      "plural": false,
      "selections": [
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "ensure_questionnaire",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuestionnaireRefresh_questionnaire"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requires_support",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time_since_updated",
      "storageKey": null
    },
    (v5/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        },
        (v3/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Formula",
          "kind": "LinkedField",
          "name": "formula",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FormulaRange",
              "kind": "LinkedField",
              "name": "formula_ranges",
              "plural": true,
              "selections": [
                (v3/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrendType",
      "kind": "LinkedField",
      "name": "trending",
      "plural": false,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v4/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserTrackableView_user_trackable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrackableResultCardAlerts_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '46beb24dcde5b4d377b7bc7e309f210c';

module.exports = node;
