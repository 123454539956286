/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportView = "CLIENT_INSIGHT" | "SERVICE_INSIGHT" | "%future added value";
export type ReportsSatisfactionIndividualSignInDimensionEnum = "age_group" | "gender" | "has_disability" | "indigenous_status" | "language" | "%future added value";
export type SatisfactionIndividualSignInChartQueryVariables = {|
  from: any,
  to: any,
  tenants: $ReadOnlyArray<string>,
  clinicians: $ReadOnlyArray<string>,
  dimension: ReportsSatisfactionIndividualSignInDimensionEnum,
  report_view?: ?ReportView,
|};
export type SatisfactionIndividualSignInChartQueryResponse = {|
  +viewer: {|
    +reports: {|
      +satisfaction_individual_sign_in: {|
        +traces: $ReadOnlyArray<?{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<string>,
          +y: $ReadOnlyArray<?number>,
        |}>
      |},
      +satisfaction_overall_individual_sign_in: {|
        +traces: $ReadOnlyArray<?{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<string>,
          +y: $ReadOnlyArray<?number>,
        |}>
      |},
    |}
  |}
|};
export type SatisfactionIndividualSignInChartQuery = {|
  variables: SatisfactionIndividualSignInChartQueryVariables,
  response: SatisfactionIndividualSignInChartQueryResponse,
|};
*/


/*
query SatisfactionIndividualSignInChartQuery(
  $from: DateTime!
  $to: DateTime!
  $tenants: [ID!]!
  $clinicians: [ID!]!
  $dimension: ReportsSatisfactionIndividualSignInDimensionEnum!
  $report_view: ReportView
) {
  viewer {
    reports {
      satisfaction_individual_sign_in(dimension: $dimension, from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {
        traces {
          name
          type
          x
          y
        }
      }
      satisfaction_overall_individual_sign_in(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {
        traces {
          name
          type
          x
          y
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinicians"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "dimension"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "report_view"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenants"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v6 = {
  "kind": "Variable",
  "name": "clinicians",
  "variableName": "clinicians"
},
v7 = {
  "kind": "Variable",
  "name": "from",
  "variableName": "from"
},
v8 = {
  "kind": "Variable",
  "name": "report_view",
  "variableName": "report_view"
},
v9 = {
  "kind": "Variable",
  "name": "tenants",
  "variableName": "tenants"
},
v10 = {
  "kind": "Variable",
  "name": "to",
  "variableName": "to"
},
v11 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "x",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "y",
    "storageKey": null
  }
],
v12 = {
  "alias": null,
  "args": [
    (v6/*: any*/),
    {
      "kind": "Variable",
      "name": "dimension",
      "variableName": "dimension"
    },
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "ReportsSatisfactionIndividualSignIn",
  "kind": "LinkedField",
  "name": "satisfaction_individual_sign_in",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsSatisfactionIndividualSignInTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v8/*: any*/),
    (v9/*: any*/),
    (v10/*: any*/)
  ],
  "concreteType": "ReportsSatisfactionOverallIndividualSignIn",
  "kind": "LinkedField",
  "name": "satisfaction_overall_individual_sign_in",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsSatisfactionOverallIndividualSignInTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SatisfactionIndividualSignInChartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v5/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "SatisfactionIndividualSignInChartQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v12/*: any*/),
              (v13/*: any*/),
              (v14/*: any*/)
            ],
            "storageKey": null
          },
          (v14/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8e2b26667f89e19e1a450a63725948c7",
    "id": null,
    "metadata": {},
    "name": "SatisfactionIndividualSignInChartQuery",
    "operationKind": "query",
    "text": "query SatisfactionIndividualSignInChartQuery(\n  $from: DateTime!\n  $to: DateTime!\n  $tenants: [ID!]!\n  $clinicians: [ID!]!\n  $dimension: ReportsSatisfactionIndividualSignInDimensionEnum!\n  $report_view: ReportView\n) {\n  viewer {\n    reports {\n      satisfaction_individual_sign_in(dimension: $dimension, from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n        }\n      }\n      satisfaction_overall_individual_sign_in(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fb274082180a6182d046f7271f16f75d';

module.exports = node;
