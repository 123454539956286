// @flow

import React, {
  type Context,
  type Node,
  createContext,
  useContext,
} from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import type {
  AnswerSetSkipReasonEnumsType,
  RelationshipEnumType,
} from './ViewerEnumTypes'

import type { ViewerEnumProvider_viewer } from './__generated__/ViewerEnumProvider_viewer.graphql'

type ContextType = {
  answer_set: AnswerSetSkipReasonEnumsType,
  relationships: RelationshipEnumType,
}

type PropsType = {
  children: Node,
  viewer: ViewerEnumProvider_viewer,
}

export const ViewerEnumsContext: Context<ContextType> = createContext({})

function Provider({ children, viewer }: PropsType) {
  const value = {
    relationships: viewer.support_person_assignment_relationships,
    answer_set: viewer.enums.answer_set,
  }
  return (
    <ViewerEnumsContext.Provider value={value}>
      {children}
    </ViewerEnumsContext.Provider>
  )
}

export function useEnums() {
  return useContext(ViewerEnumsContext)
}

export const ViewerEnumProvider = createFragmentContainer(Provider, {
  viewer: graphql`
    fragment ViewerEnumProvider_viewer on Viewer {
      support_person_assignment_relationships
      enums {
        answer_set {
          skip_reason_list {
            key
            value
          }
        }
      }
    }
  `,
})
