// @flow

import { commitMutation, graphql } from 'react-relay'

import { temporarilySetUserInvitationDelta } from './ReinviteGeneric'

import type {
  ReinviteIndividualMutationResponse,
  ReinviteIndividualMutationVariables,
} from './__generated__/ReinviteIndividualMutation.graphql'

const mutation = graphql`
  mutation ReinviteIndividualMutation($input: RoleReinviteInput!) {
    roleReinvite(input: $input) {
      role {
        ... on RoleInterface {
          id
          ...IndividualsTable_individual
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: ReinviteIndividualMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: ReinviteIndividualMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
    optimisticUpdater: temporarilySetUserInvitationDelta(
      variables.input.roleId,
    ),
  })
}
