// @flow

// We have to move this to template folder

import React from 'react'
import { format } from 'date-fns'

import { useCurrentUserRoles } from 'platform_web/hooks/useCurrentUserRoles'
import { truncateText } from 'platform_web/utility/formatText'
import { Box, SimpleSelect, Text } from 'care-ui'

import { type OverviewQueryVariableType } from '../tabs/Overview/hooks/useOverviewInsights'

type TenantSwitcherProps = {
  overviewQueryVariables: OverviewQueryVariableType,
  setOutcomeReportQueryVariables: (overviewQueryVariables: {
    [key: string]: string,
  }) => void,
  setOverviewQueryVariables: (overviewQueryVariables: {
    [key: string]: string,
  }) => void,
}

const formatDate = date => format(date, 'yyyy-MM-dd')

const TenantSwitcher = (props: TenantSwitcherProps) => {
  const {
    setOverviewQueryVariables,
    overviewQueryVariables = {},
    setOutcomeReportQueryVariables,
  } = props

  const { ownerTenants, hasOwnerRole } = useCurrentUserRoles()
  if (!hasOwnerRole) return null

  const { owner_tenant_id } = overviewQueryVariables

  const tenantSwitcherOptions = ownerTenants.map(tenant => ({
    label: tenant.name,
    value: tenant.id,
    created_at: tenant.created_at,
  }))

  const handleOnChange = value => {
    const tenant = ownerTenants.find(option => option.id === value)
    setOverviewQueryVariables({ owner_tenant_id: value })
    setOutcomeReportQueryVariables({
      tenant_id: value,
      quarter_start_date: tenant.created_at,
      quarter_end_date: formatDate(new Date()),
    })
  }
  const hasMoreThanOneTenant = ownerTenants.length > 1

  return (
    <>
      {hasMoreThanOneTenant && (
        <Box width="220px">
          <SimpleSelect
            dataTestIdForDropdown="tenantSwitcher"
            dataTestIdForDropdownOptions="tenant"
            options={tenantSwitcherOptions}
            onChange={handleOnChange}
            truncateLabel
            {...owner_tenant_id && {
              defaultOption: tenantSwitcherOptions.find(
                option => option.value === owner_tenant_id,
              ),
            }}
          />
        </Box>
      )}

      {!hasMoreThanOneTenant && (
        <Text size="md">{truncateText(ownerTenants?.[0]?.name)}</Text>
      )}
    </>
  )
}

export default TenantSwitcher
