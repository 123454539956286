// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'

type PropsType = {
  children?: Node,
  width?: string | number,
}

const cellStyle = ({ theme, width }) => ({
  ...(width !== 'auto' ? { minWidth: width, width } : {}),
  color: theme.care.palette.text.positive,
  ...theme.care.typography.desktop.bodyLg,
})

/**
 * A simple cell with all styles defined in the root DataGrid component for performance.
 */
const BasicCellRenderer = ({
  children,
  width = 'auto',
  ...props
}: PropsType) => {
  const { css } = useFela({ width })
  return (
    <div className={`${css(cellStyle)} BasicCellRenderer`} {...(props: any)}>
      {children}
    </div>
  )
}

export default BasicCellRenderer
