// @flow

import { graphql } from 'react-relay'

export const feedbackTopicSelectViewer = {
  viewer: graphql`
    fragment FeedbackTopicSelect_viewer on Viewer {
      enums {
        feedback {
          topic_list {
            key
            value
          }
        }
      }
    }
  `,
}
