// @flow

import { compact, filter, find, flow, head } from 'lodash'
import { groupBy, mapValues, sortBy, values } from 'lodash/fp'

type NewDataPropsType = {
  cliIndex: ?number,
  isFitbitTab: boolean,
  spIndex: ?number,
  userTrackableDataSets: ?$ReadOnlyArray<Object>,
}

export const sortAdditionalFormulas = (formulas: ?$ReadOnlyArray<Object>) => {
  const sortedFormulas = sortBy('boundLower')(formulas)
  const newSortedFormulas = sortedFormulas?.map((formula, index) => {
    const { boundLower, boundUpper } = formula || {}
    const label = index === 0 ? boundLower : boundUpper - 1
    const newFormula = { ...formula, label, value: label }
    return newFormula
  })

  return newSortedFormulas
}

export const formulateDataSetsByTabType = ({
  indGraph,
  clinGraph,
  spGraph,
  isFitbitTab,
}: {
  clinGraph?: Object,
  indGraph?: Object,
  isFitbitTab?: boolean,
  spGraph?: Object,
}): Array<Object> =>
  compact(
    isFitbitTab
      ? [indGraph]
      : []
          .concat(indGraph)
          .concat(clinGraph)
          .concat(spGraph),
  )

const transformDataSets = flow([
  groupBy('answered_by.role_type'),
  mapValues(x => ({
    family: head(x).answered_by.role_type,
    dataSet: [...x],
    selectedIndex: 0,
  })),
  values,
])

export const mergePoints = (dataSets: Array<Object>) => {
  if (!dataSets.length) return undefined
  return {
    ...dataSets[0],
    points: dataSets.reduce((memo, dataSet) => memo.concat(dataSet.points), []),
  }
}

export const getNewDataSets = ({
  userTrackableDataSets,
  cliIndex,
  spIndex,
  isFitbitTab,
}: NewDataPropsType) => {
  const mapDataSets =
    userTrackableDataSets && transformDataSets(userTrackableDataSets)

  const indList = find(mapDataSets, { family: 'INDIVIDUAL' })
  const cliList = find(mapDataSets, { family: 'CLINICIAN' })
  const spList = find(mapDataSets, { family: 'SUPPORT_PERSON' })

  const indGraph = indList && mergePoints(indList.dataSet)

  const clinGraph = cliList && cliList.dataSet[cliIndex]
  const spGraph = spList && spList.dataSet[spIndex]
  const newDataSets = formulateDataSetsByTabType({
    indGraph,
    clinGraph,
    spGraph,
    isFitbitTab,
  })

  return newDataSets
}

export const getNewAdditionalDataSets = (
  additionalDataSets: ?$ReadOnlyArray<Object>,
) => {
  const mapAdditionalDataSets =
    additionalDataSets && transformDataSets(additionalDataSets)

  const additionalList = find(mapAdditionalDataSets, {
    family: 'INDIVIDUAL',
  })

  const additionalGraph = additionalList && mergePoints(additionalList.dataSet)

  return compact([additionalGraph])
}

export const getFilterDataSets = (
  userTrackableDataSets: ?$ReadOnlyArray<Object>,
) => {
  const mapDataSets =
    userTrackableDataSets && transformDataSets(userTrackableDataSets)

  const filterDataSets = filter(mapDataSets, x => x.family !== 'INDIVIDUAL')

  return filterDataSets
}
