// @flow

import * as React from 'react'

import Context from './ScrollContext'

type PropsType = {
  children: React.Node,
}

const ScrollProvider = ({ children }: PropsType) => {
  const [scrollX, setScrollX] = React.useState(window.scrollX)
  const [scrollY, setScrollY] = React.useState(window.scrollY)

  const onScroll = () => {
    setScrollX(window.scrollX)
    setScrollY(window.scrollY)
  }

  React.useEffect(() => {
    document.addEventListener('scroll', onScroll)
    return () => {
      document.removeEventListener('scroll', onScroll)
    }
  }, [])

  return (
    <Context.Provider
      value={{
        scrollX,
        scrollY,
      }}
    >
      {children}
    </Context.Provider>
  )
}

export default ScrollProvider
