// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import { Select } from 'care-ui'

import { feedbackTopicSelectViewer } from './query/FeedbackTopicSelect'

import type { FeedbackTopicSelect_viewer } from './query/__generated__/FeedbackTopicSelect_viewer.graphql'

type PropsType = {
  +value: string,
  +viewer: FeedbackTopicSelect_viewer,
}

const FeedbackTopicSelect = (props: PropsType) => {
  const {
    value,
    viewer: { enums: { feedback: { topic_list } } },
    ...other
  } = props

  const { t: translation } = useTranslation('feedback')

  return (
    <Select
      value={value}
      options={[
        { key: '', value: translation('please_select') },
        ...topic_list,
      ]}
      valueKey="key"
      labelKey="value"
      {...(other: any)}
    />
  )
}

export const FeedbackTopicSelectLoader = createFragmentContainer(
  FeedbackTopicSelect,
  feedbackTopicSelectViewer,
)
