// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import type { FelaStylesType } from 'react-ui/typing'

type TrackableResultCardMenuType = {
  isOpen: boolean,
  options: Array<Node>,
  styles: FelaStylesType,
}

const styleRules = ({ theme, isOpen = false }) => ({
  Menu: {
    borderTopLeftRadius: '0.225rem',
    borderTopRightRadius: '0.225rem',
    bottom: '3rem',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    minHeight: 'calc(100% - 75px)', // 75px = "Update answers" height + bottom bar height
    overflow: 'hidden',
    position: 'absolute',
    right: 0,
    transition: 'visibility 0s linear 0.2s',
    transitionDelay: isOpen && '0s',
    visibility: isOpen ? 'visible' : 'hidden',
  },

  MenuInner: {
    backgroundColor: theme.palette.section.grey.backgroundColor,
    flex: 1,
    '-ms-flex': '1 1 auto',
    transform: isOpen ? 'translateY(0)' : 'translateY(100%)',
    transition: 'transform 0.2s cubic-bezier(.455, .03, .515, .955)',
  },

  TrackableResultCardMenuOption: {
    className: 'TrackableResultCardMenu__Option',
    display: 'flex',
    color: 'initial',
    flexDirection: 'column',
    flex: 1,
    '-ms-flex': '1 1 auto',
    justifyContent: 'center',
    padding: ' 0 0.6rem',
    height: '2.5rem',
    borderTop: `1px solid ${theme.palette.component.muted.base}`,
    borderBottom: `1px solid ${theme.palette.component.muted.base}`,
    fontSize: '16px',
    fontWeight: 300,

    '&:hover, &:focus': {
      backgroundColor: theme.palette.component.muted.base,
      color: theme.palette.component.primary.base,
      outline: 'none',
      textDecoration: 'none',
    },
  },
})

const TrackableResultCardMenu = ({
  options,
  styles,
  isOpen,
}: TrackableResultCardMenuType) => {
  const renderedOptions = options.map((option: Node, index) => {
    const itemKey = `${index}-item`
    return (
      <div key={itemKey} className={styles.TrackableResultCardMenuOption}>
        {option}
      </div>
    )
  })

  return (
    <div className={styles.Menu}>
      <div className={styles.MenuInner}>{isOpen && renderedOptions}</div>
    </div>
  )
}

export default connect(styleRules)(TrackableResultCardMenu)
