// @flow

/**
 * @param {string} structured_address attribute from geocode api.
 * suggestion's format is "Suburb State, Australia"
 * e.g "Epping Forest TAS, Australia"
 */
export function formatSuggestionToExtractSuburbAndState(suggestion: string) {
  if (suggestion === undefined) {
    return ''
  }

  if (suggestion.indexOf('Australia') < 0) {
    return suggestion
  }

  const extract_city = suggestion.split(', ')[0]
  const city_state_array = extract_city.split(' ')
  const state = city_state_array.pop()
  const suburb = city_state_array.join(' ')
  return `${suburb}, ${state}`
}
