/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type PopUpNotificationShowPreview_notification_preview$ref: FragmentReference;
declare export opaque type PopUpNotificationShowPreview_notification_preview$fragmentType: PopUpNotificationShowPreview_notification_preview$ref;
export type PopUpNotificationShowPreview_notification_preview = {|
  +id: string,
  +description: string,
  +image_url: ?string,
  +$refType: PopUpNotificationShowPreview_notification_preview$ref,
|};
export type PopUpNotificationShowPreview_notification_preview$data = PopUpNotificationShowPreview_notification_preview;
export type PopUpNotificationShowPreview_notification_preview$key = {
  +$data?: PopUpNotificationShowPreview_notification_preview$data,
  +$fragmentRefs: PopUpNotificationShowPreview_notification_preview$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "PopUpNotificationShowPreview_notification_preview",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image_url",
      "storageKey": null
    }
  ],
  "type": "PopUpNotification",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '86dd1a17e14de333c12bf70800853b0c';

module.exports = node;
