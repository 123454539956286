// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import HelpNowPage from 'pages/HelpNowPage'

export const HelpNowProvidersTenantQuery = graphql`
  query HelpNowPageTenant_Query($id: ID!) {
    viewer {
      tenant(id: $id) {
        id
        ...HelpNowPage_tenant
      }
    }
  }
`

const TenantHelpNowPage = ({
  tenant: { help_now_providers: providers, emergencyNumber },
}: *) => (
  <HelpNowPage
    providers={providers}
    emergencyNumber={emergencyNumber}
    filterByCountry={false}
  />
)

export const TenantHelpNowPageLoader = createFragmentContainer(
  TenantHelpNowPage,
  {
    tenant: graphql`
      fragment HelpNowPage_tenant on Tenant {
        help_now_providers {
          chat_url
          description
          hours
          id
          slug
          logo_url
          phone_number
          sequence
          sms
          title
          url
        }
        emergencyNumber
      }
    `,
  },
)
