/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserInviteForm_tenants$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualsAdminInvitePage_viewer$ref: FragmentReference;
declare export opaque type IndividualsAdminInvitePage_viewer$fragmentType: IndividualsAdminInvitePage_viewer$ref;
export type IndividualsAdminInvitePage_viewer = {|
  +currentUser: ?{|
    +current_role: {|
      +id?: string,
      +tenant?: ?{|
        +hide_mobile_on_invite: boolean,
        +id: string,
      |},
      +tenants?: $ReadOnlyArray<{|
        +$fragmentRefs: UserInviteForm_tenants$ref
      |}>,
    |}
  |},
  +$refType: IndividualsAdminInvitePage_viewer$ref,
|};
export type IndividualsAdminInvitePage_viewer$data = IndividualsAdminInvitePage_viewer;
export type IndividualsAdminInvitePage_viewer$key = {
  +$data?: IndividualsAdminInvitePage_viewer$data,
  +$fragmentRefs: IndividualsAdminInvitePage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndividualsAdminInvitePage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "current_role",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tenant",
                  "kind": "LinkedField",
                  "name": "tenant",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hide_mobile_on_invite",
                      "storageKey": null
                    },
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Tenant",
                  "kind": "LinkedField",
                  "name": "tenants",
                  "plural": true,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserInviteForm_tenants"
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "RoleInterface",
              "abstractKey": "__isRoleInterface"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd06f7b7d4ba504f219b8458e95e00eb1';

module.exports = node;
