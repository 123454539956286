/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportView = "CLIENT_INSIGHT" | "SERVICE_INSIGHT" | "%future added value";
export type WorkforceReportQueryVariables = {|
  from: any,
  to: any,
  tenants: $ReadOnlyArray<string>,
  clinicians: $ReadOnlyArray<string>,
  report_view?: ?ReportView,
|};
export type WorkforceReportQueryResponse = {|
  +viewer: {|
    +reports: {|
      +workforce_clinician_system_use: {|
        +traces: $ReadOnlyArray<?{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<string>,
          +y: $ReadOnlyArray<number>,
        |}>
      |},
      +workforce_staffing_discipline: {|
        +traces: $ReadOnlyArray<?{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<string>,
          +y: $ReadOnlyArray<number>,
        |}>
      |},
    |}
  |}
|};
export type WorkforceReportQuery = {|
  variables: WorkforceReportQueryVariables,
  response: WorkforceReportQueryResponse,
|};
*/


/*
query WorkforceReportQuery(
  $from: DateTime!
  $to: DateTime!
  $tenants: [ID!]!
  $clinicians: [ID!]!
  $report_view: ReportView
) {
  viewer {
    reports {
      workforce_clinician_system_use(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {
        traces {
          name
          type
          x
          y
        }
      }
      workforce_staffing_discipline(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {
        traces {
          name
          type
          x
          y
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinicians"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "report_view"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenants"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v5 = [
  {
    "kind": "Variable",
    "name": "clinicians",
    "variableName": "clinicians"
  },
  {
    "kind": "Variable",
    "name": "from",
    "variableName": "from"
  },
  {
    "kind": "Variable",
    "name": "report_view",
    "variableName": "report_view"
  },
  {
    "kind": "Variable",
    "name": "tenants",
    "variableName": "tenants"
  },
  {
    "kind": "Variable",
    "name": "to",
    "variableName": "to"
  }
],
v6 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "name",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "type",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "x",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "y",
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "ReportsWorkforceClinicianSystemUse",
  "kind": "LinkedField",
  "name": "workforce_clinician_system_use",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsWorkforceClinicianSystemUseTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": (v5/*: any*/),
  "concreteType": "ReportsWorkforceStaffingDiscipline",
  "kind": "LinkedField",
  "name": "workforce_staffing_discipline",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsWorkforceStaffingDisciplineTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": (v6/*: any*/),
      "storageKey": null
    }
  ],
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkforceReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkforceReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/)
            ],
            "storageKey": null
          },
          (v9/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "14d2f8adeaddb7a06b89b4a9ea06e811",
    "id": null,
    "metadata": {},
    "name": "WorkforceReportQuery",
    "operationKind": "query",
    "text": "query WorkforceReportQuery(\n  $from: DateTime!\n  $to: DateTime!\n  $tenants: [ID!]!\n  $clinicians: [ID!]!\n  $report_view: ReportView\n) {\n  viewer {\n    reports {\n      workforce_clinician_system_use(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n        }\n      }\n      workforce_staffing_discipline(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'df40084e72c8bb2604c8349beece9028';

module.exports = node;
