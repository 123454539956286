// @flow

type ThemeType = { [string]: any }

export const dropdownItemStyle = ({ theme }: { theme: ThemeType }) => ({
  color: theme.care.palette.text.positive,
  padding: theme.care.spacing.xs,
  textAlign: 'left',
  cursor: 'pointer',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  border: 'none',
  width: '100%',
  backgroundColor: 'transparent',
  ...theme.care.typography.desktop.bodyLg,

  ':hover': {
    backgroundColor: theme.care.palette.surface.accentLighter,
    borderRadius: theme.care.radius.sm,
  },
})
