// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'

import Cross from 'react-ui/assets/icons/cross.svg'
import Heading from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import Spacer from 'react-ui/components/Spacer'
import { useAssessmentNotification } from 'react-ui/contexts/AssessmentNotificationContext'
import { commit as commitAssessmentRequestCreate } from 'mutations/AssessmentRequestAcknowledge'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import useChartToggle from 'platform_web/hooks/useChartToggle'
import { Button } from 'care-ui'

type PropsType = {
  buttonTitle: string,
  description: string,
  setAssessmentNotificationOpen: (state: boolean) => void,
  title: string,
}

const bannerWrapperStyle = ({ theme }) => ({
  backgroundColor: theme.bodyBackgroundColor,
  borderColor: theme.palette.dividerColor,
  borderStyle: 'solid',
  borderWidth: '1px',
  marginBottom: '30px',
  borderRadius: '5px',
  padding: `0 ${theme.care.spacing.xs}`,
})

const descriptionStyle = () => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
})

const closeButtonStyle = () => ({
  backgroundColor: 'transparent',
  border: 0,
  cursor: 'pointer',
})

const AssessmentRequestBanner = ({
  title,
  description,
  buttonTitle,
  setAssessmentNotificationOpen,
}: PropsType) => {
  const { css } = useFela()
  const environment = useRelayEnvironment()
  const { notify, assessmentRequestId } = useAssessmentNotification()
  const { isSummaryGraph } = useChartToggle()

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    assessmentRequestId,
  })

  const REQUEST_ASSESSMENTS = window.GLOBALS.features?.REQUEST_ASSESSMENTS

  if (!REQUEST_ASSESSMENTS || !notify || isSummaryGraph) return null

  const onRequestClose = () => {
    setAssessmentNotificationOpen(false)
    commitAssessmentRequestCreate({
      environment,
      variables: {
        input: {
          assessmentRequestId,
        },
      },
    })
  }

  return (
    <div
      className={css(bannerWrapperStyle)}
      data-testid="AssessmentRequestBanner"
    >
      <div>
        <div className={css(descriptionStyle)}>
          <Heading level={3}>{title}</Heading>
          <button
            type="button"
            className={css(closeButtonStyle)}
            onClick={onRequestClose}
            componentid="Button_close"
          >
            <Icon as={Cross} scale={1} />
          </button>
        </div>
        <div className={css(descriptionStyle)}>
          <div>
            <p>{description}</p>
          </div>
          <Button variant="primary" onClick={start}>
            {buttonTitle}
          </Button>
        </div>
        <Spacer axis="vertical" units={0.5} />
      </div>
    </div>
  )
}

export default AssessmentRequestBanner
