/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type NotificationDisplay_user$ref = any;
export type NotificationUpdateInput = {|
  clientMutationId?: ?string,
  id: string,
  notification: NotificationUpdateInputObject,
|};
export type NotificationUpdateInputObject = {|
  seen: boolean
|};
export type NotificationUpdateMutationVariables = {|
  input: NotificationUpdateInput
|};
export type NotificationUpdateMutationResponse = {|
  +notificationUpdate: ?{|
    +user: {|
      +id: string,
      +$fragmentRefs: NotificationDisplay_user$ref,
    |}
  |}
|};
export type NotificationUpdateMutation = {|
  variables: NotificationUpdateMutationVariables,
  response: NotificationUpdateMutationResponse,
|};
*/


/*
mutation NotificationUpdateMutation(
  $input: NotificationUpdateInput!
) {
  notificationUpdate(input: $input) {
    user {
      id
      ...NotificationDisplay_user
    }
  }
}

fragment NotificationDisplay_user on User {
  id
  notifications {
    id
    seen
    subject {
      __typename
      ... on Escalation {
        kind
        title
        description
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationUpdatePayload",
        "kind": "LinkedField",
        "name": "notificationUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "NotificationDisplay_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "NotificationUpdatePayload",
        "kind": "LinkedField",
        "name": "notificationUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Notification",
                "kind": "LinkedField",
                "name": "notifications",
                "plural": true,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "seen",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "subject",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "title",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "type": "Escalation",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "b056ff0e8f5e575675487ccb212dbf70",
    "id": null,
    "metadata": {},
    "name": "NotificationUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationUpdateMutation(\n  $input: NotificationUpdateInput!\n) {\n  notificationUpdate(input: $input) {\n    user {\n      id\n      ...NotificationDisplay_user\n    }\n  }\n}\n\nfragment NotificationDisplay_user on User {\n  id\n  notifications {\n    id\n    seen\n    subject {\n      __typename\n      ... on Escalation {\n        kind\n        title\n        description\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fbef6b708c095216c17fe3d5db64b560';

module.exports = node;
