// @flow

import { graphql } from 'react-relay'

export const query = {
  user_trackable: graphql`
    fragment ScoreCardsPreviousAnswers_user_trackable on UserTrackable {
      ...ScoreCardsPreviousAnswerSet_user_trackable
      all_data_sets {
        points {
          id
          date
          value
          scaled_value
          questionnaire_answer_set_id
        }
      }
      change_log_entries(
        start_time: $trackable_start_time
        end_time: $trackable_end_time
      ) {
        ...ScoreCardsChangeLog_changeLog
        id
        created_at
      }
    }
  `,
  user_question_sets: graphql`
    fragment ScoreCardsPreviousAnswers_user_question_sets on UserQuestionSet
      @relay(plural: true) {
      id
      ...UserQuestionSetDetailsView_user_question_set
      submitted_answer_sets {
        ...ScoreCardsPreviousAnswerSet_answer_set
        questionnaire_answer_set_id
        submitted_at
        id
      }
      question_set {
        id
        label
      }
    }
  `,
}
