// @flow

import React from 'react'

import { CellRenderer } from 'react-ui/components/DataGrid'

type PropsType = {
  extend: () => Object,
}
const SegmentationTableCellRenderer = ({
  extend = () => ({}),
  ...props
}: PropsType) => {
  const enhancedExtend = (...args) => ({
    '&:first-of-type': {
      alignItems: 'flex-start',
      paddingLeft: 0,
    },
    '&:not(:first-of-type)': {
      alignItems: 'flex-end',
    },
    borderColor: '#D0D6DE',
    className: 'SegmentationTableCellRenderer',
    color: args[0].theme.care.palette.text.positive,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingTop: '5px',
    paddingBottom: '5px',
    ...args[0].theme.care.typography.desktop.bodyMd,
    ...extend(...args),
  })

  return <CellRenderer extend={enhancedExtend} {...(props: any)} />
}

export default SegmentationTableCellRenderer
