// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const EndCallIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="endCallIcon"
        version="1.1"
        viewBox="0 0 36 36"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
      >
        <g id="INDIVIDUAL_1_">
          <g
            id="_x36_.8.8-Exit_x2F_end-meeting_1_"
            transform="translate(-503.000000, -931.000000)"
          >
            <g id="VIDEO-CALLs_1_" transform="translate(0.000000, 896.000000)">
              <g
                id="button-_x2F_-leave-call"
                transform="translate(486.000000, 14.000000)"
              >
                <g
                  id="icon-_x2F_-28x28-_x2F_-phone"
                  transform="translate(34.000000, 31.000000) rotate(135.000000) translate(-34.000000, -31.000000) translate(20.000000, 17.000000)"
                >
                  <g>
                    <path
                      id="path-1_1_"
                      className="st0"
                      d="M24.8,22.8l-3.6-3c-0.2-0.2-0.7-0.2-1-0.1l-2,1.3c-0.2,0.1-0.5,0.2-0.7,0.2
                      c-0.4,0-1-0.1-1.8-0.3c-1.3-0.3-2.6-0.9-3.7-1.7c-1-0.7-1.9-1.6-2.6-2.6c-0.8-1.1-1.4-2.4-1.7-3.7c-0.2-0.8-0.3-1.4-0.3-1.8
                      c0-0.3,0.1-0.5,0.2-0.7l1.3-2c0.2-0.3,0.1-0.7-0.1-1l-3-3.6c-0.3-0.4-0.7-0.4-1,0l-2,2.4C2.2,7,2,8.3,2.2,9.1v0.1
                      c0,0.1,0.1,0.3,0.2,0.7c0.3,0.8,0.6,1.5,0.9,2.2c1.2,2.4,3,4.9,5.6,7.5s5.1,4.4,7.5,5.6c0.7,0.4,1.4,0.7,2.2,0.9
                      c0.4,0.1,0.6,0.2,0.7,0.2s0.1,0,0.2,0c0.8,0.3,2.1,0,2.8-0.6l2.4-2C25.1,23.5,25.1,23.1,24.8,22.8z M18.7,17.6
                      c1.2-0.8,2.9-0.7,4,0.2l3.6,3c1.6,1.3,1.6,3.6,0,4.9l-2.4,2c-1.3,1.1-3.6,1.5-5.2,1c-0.3-0.1-0.6-0.2-0.9-0.3
                      c-0.9-0.3-1.7-0.6-2.5-1.1c-2.6-1.3-5.4-3.3-8.1-6c-2.8-2.8-4.7-5.5-6-8.1c-0.4-0.8-0.8-1.7-1.1-2.5C0,10.5-0.1,10.1-0.2,9.8
                      c-0.5-1.6,0-3.8,1-5.2l2-2.4c1.3-1.6,3.6-1.6,4.9,0l3,3.6c0.9,1.1,1,2.8,0.2,4l-1.1,1.6c0,0.3,0.1,0.6,0.2,0.9
                      c0.2,1,0.7,2,1.3,2.9c0.5,0.8,1.2,1.5,2,2c0.9,0.6,1.8,1,2.9,1.3c0.3,0.1,0.6,0.1,0.9,0.2L18.7,17.6z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default EndCallIcon
