// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Box, Button, DropDownMenu, FlexContainer, Text } from 'care-ui'
import { ChevronDownIcon } from 'care-ui/atoms/icons-new'

import useQuarterGenerator from './hooks/useQuarterGenerator'
import LifetimeQuarter from './LifeTimeQuarter'
import Quarter from './Quarter'

type QuarterPickerProps = {
  onChange: (queryVariables: {
    [key: string]: string,
  }) => void,
  startDate: string,
}

const QuarterPicker = (props: QuarterPickerProps) => {
  const { t: translation } = useTranslation('insights')
  const { onChange, startDate } = props

  const LIFETIME = translation('lifetime')
  const { quarterGroup, lifetime } = useQuarterGenerator(startDate, true)

  const [closeDropDown, setCloseDropDown] = useState(true)

  const [selectedLabel, setSelectedLabel] = useState(LIFETIME)

  useEffect(
    () => {
      setSelectedLabel(LIFETIME)
    },
    [startDate],
  )
  // Need to set the back to default label on change of startDate. Eg- When switch tenant it should set back to lifetime

  const toggleDropDownMenu = () => {
    setCloseDropDown(!closeDropDown)
  }

  return (
    <DropDownMenu
      position="end"
      closeDropDown={closeDropDown}
      setCloseDropDown={setCloseDropDown}
      dropDownTrigger={
        <Box width="220px">
          <Button
            variant="secondary"
            onClick={toggleDropDownMenu}
            dataTestId="customDateSelector"
            fullWidth
            rightIcon={ChevronDownIcon}
          >
            <FlexContainer
              justifyContent="space-between"
              alignItems="center"
              wrap="nowrap"
              gap="xxxs"
            >
              <Text size="md">{selectedLabel}</Text>
            </FlexContainer>
          </Button>
        </Box>
      }
    >
      <LifetimeQuarter
        onChange={onChange}
        toggleParentDropdown={toggleDropDownMenu}
        quarter={lifetime}
        setSelectedLabel={setSelectedLabel}
      />
      {quarterGroup.map(quarterGroupItem => (
        <Quarter
          key={quarterGroupItem.label}
          onChange={onChange}
          toggleParentDropdown={toggleDropDownMenu}
          quarterGroup={quarterGroupItem}
          setSelectedLabel={setSelectedLabel}
        />
      ))}
    </DropDownMenu>
  )
}

export default QuarterPicker
