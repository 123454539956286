// @flow

import React, { useState } from 'react'
import omit from 'lodash/fp/omit'

import { FormError } from 'react-ui/components/Form'
import Spacer from 'react-ui/components/Spacer'
import createComponentId from 'shared/services/id'
import { Input } from 'shared/ui/Basics/Input/Input'

import { type PropsType } from '.'

const defaultId = createComponentId(__filename)

function BaseInput(props: PropsType) {
  const [touched, setTouched] = useState(false)

  const {
    autofocus = false,
    componentId = defaultId,
    disabled = false,
    onBlur,
    onFocus,
    options,
    readonly = false,
    required = false,
    value,
    rawErrors,
    ...inputProps
  } = props

  const onTouched = () => setTouched(true)

  inputProps.type = options.inputType || inputProps.type || 'text'

  const _onChange = ({ target: { value: localValue } }) => {
    onTouched()
    return props.onChange(localValue === '' ? options.emptyValue : localValue)
  }

  return (
    <div className={componentId}>
      <Input
        autoFocus={autofocus}
        className="form-control"
        disabled={disabled}
        readOnly={readonly}
        value={value == null ? '' : value}
        required={required}
        {...omit(['formContext'])(inputProps)}
        onBlur={onBlur && (event => onBlur(inputProps.id, event.target.value))}
        onChange={_onChange}
        onFocus={
          onFocus && (event => onFocus(inputProps.id, event.target.value))
        }
      />
      {touched &&
        rawErrors && (
          <React.Fragment>
            <Spacer units={1} />
            <FormError>{rawErrors}</FormError>
          </React.Fragment>
        )}
    </div>
  )
}

export default BaseInput
