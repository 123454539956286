// @flow

import { graphql } from 'react-relay'

export const StaffDashboardSelectionUser = graphql`
  fragment StaffDashboardSelection_user on User {
    id
    email
    last_seen_at
    legal_name
    stratified_scoring_result {
      status
    }
    personal_details {
      date_of_birth
      gender
    }
    preferred_name
    username
    linked_individual {
      escalated
      initial_questionnaire_status
    }
  }
`

export const StaffDashboardSelectionClinician = graphql`
  fragment StaffDashboardSelection_clinician on User {
    id
    tab_options {
      id
      label
      value
    }
    initial_state
    tenant {
      stratified_scoring
      id
    }
    current_role {
      ... on RoleInterface {
        parent {
          ... on RoleParentInterface {
            id
          }
        }
      }
    }
  }
`
