/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserResource_user_resource$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsCareOptionsList_user_resource$ref: FragmentReference;
declare export opaque type ScoreCardsCareOptionsList_user_resource$fragmentType: ScoreCardsCareOptionsList_user_resource$ref;
export type ScoreCardsCareOptionsList_user_resource = {|
  +status: UserResourceStatuses,
  +id: string,
  +resource: {|
    +clinical: boolean,
    +id: string,
    +label: string,
  |},
  +$fragmentRefs: UserResource_user_resource$ref,
  +$refType: ScoreCardsCareOptionsList_user_resource$ref,
|};
export type ScoreCardsCareOptionsList_user_resource$data = ScoreCardsCareOptionsList_user_resource;
export type ScoreCardsCareOptionsList_user_resource$key = {
  +$data?: ScoreCardsCareOptionsList_user_resource$data,
  +$fragmentRefs: ScoreCardsCareOptionsList_user_resource$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsCareOptionsList_user_resource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clinical",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserResource_user_resource"
    }
  ],
  "type": "UserResource",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '25000d0f24101d556bb249a5f0f12c4b';

module.exports = node;
