// @flow

import { graphql } from 'react-relay'

export const UserQuestionSetDetailsPageQuery = graphql`
  query UserQuestionSetDetailsPage_Query($id: ID!, $question_set_id: ID!) {
    user(id: $id, source: "questionnaire") {
      ...UserQuestionSetDetailsPage_user
      user_question_set(question_set_id: $question_set_id) {
        ...UserQuestionSetDetailsPage_user_question_set
      }
    }
  }
`

export const query = {
  user_question_set: graphql`
    fragment UserQuestionSetDetailsPage_user_question_set on UserQuestionSet {
      id
      submitted_answer_sets {
        ...AnswerSets_answer_sets
      }
      question_set {
        label
      }
    }
  `,
  user: graphql`
    fragment UserQuestionSetDetailsPage_user on User {
      id
    }
  `,
}
