// @flow

import { graphql } from 'react-relay'

export const UserDashboardPageQuery = graphql`
  query UserDashboardPage_Query(
    $id: ID!
    $aboutMe: Boolean = false
    $healthHistory: Boolean = false
    $trackables: Boolean = false
  ) {
    user(id: $id) {
      ...UserDashboardPage_user
    }
    viewer {
      ...UserDashboardPage_viewer
    }
  }
`

export const UserDashboardPageViewer = graphql`
  fragment UserDashboardPage_viewer on Viewer {
    currentUser {
      id
      ...ChartHeader_user
    }
  }
`

export const UserDashboardPageUser = graphql`
  fragment UserDashboardPage_user on User {
    id
    linked_individual {
      ...ChartHeader_individual
    }
    ...AboutMeTab_user @include(if: $aboutMe)
    ...HealthHistoryTab_user @include(if: $healthHistory)
    ...HealthCardsTab_user @include(if: $trackables)
  }
`
