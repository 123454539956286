// @flow

import * as React from 'react'

import WithAuth from 'components/WithAuth'

type PropsType = {}

type OptionsType = {
  requiredPolicies: Array<string>,
}

export const WithAuthWrapper = (
  WrappedComponent: React.ComponentType<any>,
  options: OptionsType,
) => (props: PropsType) => (
  <WithAuth requiredPolicies={options.requiredPolicies}>
    <WrappedComponent {...(props: any)} />
  </WithAuth>
)
