// @flow

import React from 'react'
import { connect } from 'react-fela'
import { createFragmentContainer } from 'react-relay'

import { styledRules } from './AboutMeTab.style'
import AvatarSide from './components/AvatarSide'
import DetailsSide from './components/DetailsSide'
import { query } from './queries/AboutMeTab'

import { type FelaStylesType } from 'react-ui/typing'
import { type AboutMeTab_user } from './queries/__generated__/AboutMeTab_user.graphql'

type PropsType = {
  styles: FelaStylesType,
  +user: AboutMeTab_user,
}

const AboutMeTab = (props: PropsType) => {
  const { user, styles } = props

  return (
    <div className={styles.container}>
      <AvatarSide user={user} styles={styles} />

      <div className={styles.wrap}>
        <DetailsSide user={user} styles={styles} />
      </div>
    </div>
  )
}

const AboutMeTabLoader = createFragmentContainer(AboutMeTab, query)

export default connect(styledRules)(AboutMeTabLoader)
