// @flow

import found from 'found/foundReducer'
import { type Reducer, combineReducers } from 'redux'

import currentUser from './currentuser/reducer'
import genericUi from './generic_ui/reducer'
import policies from './policies/reducer'
import router from './router/reducer'

export default (combineReducers({
  found,
  genericUi,
  policies,
  router,
  currentUser,
}): Reducer<any, any>)
