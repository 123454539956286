/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type StaffDashboardSelection_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianViewingIndividualBanner_user$ref: FragmentReference;
declare export opaque type ClinicianViewingIndividualBanner_user$fragmentType: ClinicianViewingIndividualBanner_user$ref;
export type ClinicianViewingIndividualBanner_user = {|
  +$fragmentRefs: AvatarLoader_user$ref & StaffDashboardSelection_user$ref,
  +$refType: ClinicianViewingIndividualBanner_user$ref,
|};
export type ClinicianViewingIndividualBanner_user$data = ClinicianViewingIndividualBanner_user;
export type ClinicianViewingIndividualBanner_user$key = {
  +$data?: ClinicianViewingIndividualBanner_user$data,
  +$fragmentRefs: ClinicianViewingIndividualBanner_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianViewingIndividualBanner_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarLoader_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffDashboardSelection_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'baef9a60abccdaf59e38d779c9d3f86f';

module.exports = node;
