/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AvatarListLoader_data$ref = any;
export type ClinicianMultipleAssignmentUpdateInput = {|
  clientMutationId?: ?string,
  clinician_assignments_attributes: $ReadOnlyArray<ClinicianIndividualAssignmentCreateInputObject>,
|};
export type ClinicianIndividualAssignmentCreateInputObject = {|
  clinician_id: string,
  individual_id: string,
|};
export type ClinicianMultipleAssignmentUpdateMutationVariables = {|
  input: ClinicianMultipleAssignmentUpdateInput
|};
export type ClinicianMultipleAssignmentUpdateMutationResponse = {|
  +clinicianMultipleAssignmentUpdate: ?{|
    +clinician_assignments: $ReadOnlyArray<{|
      +individual: {|
        +duty_of_care_clinicians: $ReadOnlyArray<{|
          +$fragmentRefs: AvatarListLoader_data$ref
        |}>,
        +clinicians: $ReadOnlyArray<{|
          +$fragmentRefs: AvatarListLoader_data$ref
        |}>,
      |},
      +clinician: {|
        +id: string,
        +clinician_assignments_as_clinician: $ReadOnlyArray<{|
          +id: string
        |}>,
      |},
    |}>
  |}
|};
export type ClinicianMultipleAssignmentUpdateMutation = {|
  variables: ClinicianMultipleAssignmentUpdateMutationVariables,
  response: ClinicianMultipleAssignmentUpdateMutationResponse,
|};
*/


/*
mutation ClinicianMultipleAssignmentUpdateMutation(
  $input: ClinicianMultipleAssignmentUpdateInput!
) {
  clinicianMultipleAssignmentUpdate(input: $input) {
    clinician_assignments {
      individual {
        duty_of_care_clinicians {
          ...AvatarListLoader_data
          id
        }
        clinicians {
          ...AvatarListLoader_data
          id
        }
        id
      }
      clinician {
        id
        clinician_assignments_as_clinician {
          id
        }
      }
      id
    }
  }
}

fragment AvatarListLoader_data on ClinicianRole {
  id
  user {
    id
    name
    ...AvatarLoader_user
  }
}

fragment AvatarLoader_user on User {
  id
  email
  legal_name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AvatarListLoader_data"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "concreteType": "ClinicianRole",
  "kind": "LinkedField",
  "name": "clinician",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianAssignment",
      "kind": "LinkedField",
      "name": "clinician_assignments_as_clinician",
      "plural": true,
      "selections": [
        (v3/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "legal_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClinicianMultipleAssignmentUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClinicianMultipleAssignmentUpdatePayload",
        "kind": "LinkedField",
        "name": "clinicianMultipleAssignmentUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianAssignment",
            "kind": "LinkedField",
            "name": "clinician_assignments",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "individual",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "duty_of_care_clinicians",
                    "plural": true,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "clinicians",
                    "plural": true,
                    "selections": (v2/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClinicianMultipleAssignmentUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClinicianMultipleAssignmentUpdatePayload",
        "kind": "LinkedField",
        "name": "clinicianMultipleAssignmentUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianAssignment",
            "kind": "LinkedField",
            "name": "clinician_assignments",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "individual",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "duty_of_care_clinicians",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "clinicians",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  (v3/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/),
              (v3/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4a4d6b085f28a899869ad25d862b45e8",
    "id": null,
    "metadata": {},
    "name": "ClinicianMultipleAssignmentUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation ClinicianMultipleAssignmentUpdateMutation(\n  $input: ClinicianMultipleAssignmentUpdateInput!\n) {\n  clinicianMultipleAssignmentUpdate(input: $input) {\n    clinician_assignments {\n      individual {\n        duty_of_care_clinicians {\n          ...AvatarListLoader_data\n          id\n        }\n        clinicians {\n          ...AvatarListLoader_data\n          id\n        }\n        id\n      }\n      clinician {\n        id\n        clinician_assignments_as_clinician {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AvatarListLoader_data on ClinicianRole {\n  id\n  user {\n    id\n    name\n    ...AvatarLoader_user\n  }\n}\n\nfragment AvatarLoader_user on User {\n  id\n  email\n  legal_name\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e81b3107d8a512506b8c7e55cb8327bf';

module.exports = node;
