// @flow

/**
 * Given a full path to a module, will return a sensible 'id' for it.
 *
 * @param {string} filepath - A filepath to the module.
 */
export default function idForModule(filepath: string) {
  const components = filepath.split('/')
  return components[components.length - 1].split('.')[0]
}
