// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const cardStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    padding: `${theme.care.spacing.xxs} ${theme.care.spacing.sm}`,
    background: theme.care.palette.surface.subtle,
    borderRadius: theme.care.radius.sm,
    width: '-webkit-fill-available',
  }
}

export const dateStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    marginBottom: theme.care.spacing.xxs,
  }
}

export const infoStyle = () => ({
  display: 'block',
  whiteSpace: 'pre-wrap',
})
