// @flow

import React from 'react'

import ResetTableFilter from 'platform_web/pages/Staff/components/TableFilterControl/ResetTableFilter'
import TableFilterControl from 'platform_web/pages/Staff/components/TableFilterControl/TableFilterControl'
import TableSearchControl from 'platform_web/pages/Staff/components/TableSearchControl/TableSearchControl'
import TableSortControl from 'platform_web/pages/Staff/components/TableSortControl/TableSortControl'
import TableTypeSelectControl from 'platform_web/pages/Staff/components/TableTypeSelectControl/TableTypeSelectControl'
import { DEFAULT_TABLE_CONFIGURATIONS } from 'platform_web/pages/Staff/constants/TableConstants'
import useIndividualTableSort from 'platform_web/pages/Staff/hooks/useIdividualTableSort'
import useIndividualTableSelect from 'platform_web/pages/Staff/hooks/useIndividualTableSelect'
import { Box, FlexContainer, FlexItem } from 'care-ui'

import { type UseIndividualTableReturnType } from '../hooks/useIndividualTable'

type PartialIndividualTableReturnType = $Shape<UseIndividualTableReturnType>

type IndividualsTableControlsProps = {
  useIndividualTablePayload: PartialIndividualTableReturnType,
}

const IndividualsTableControls = (props: IndividualsTableControlsProps) => {
  const { useIndividualTablePayload } = props
  const {
    currentUser,
    setTableType,
    tableType,
    tableCategory,
    queryVariables,
    updateQueryVariables,
  } = useIndividualTablePayload

  const { sortOptions } = useIndividualTableSort({ currentUser, tableCategory })
  const { currentUserTableType } = useIndividualTableSelect({ currentUser })

  const handleOnChange = updatedTableType => {
    // set the table type and reset the queryVariables
    setTableType(updatedTableType)

    updateQueryVariables(DEFAULT_TABLE_CONFIGURATIONS[updatedTableType])
  }

  return (
    <>
      <FlexContainer justifyContent="space-between" paddingX="xxs">
        <FlexContainer gap="xxxs">
          <Box width="160px">
            <TableTypeSelectControl
              options={currentUserTableType}
              onChange={updatedTableType => handleOnChange(updatedTableType)}
              tableType={tableType}
            />
          </Box>

          {tableCategory === 'scores' && (
            <FlexItem>
              <TableFilterControl
                queryVariables={queryVariables}
                updateQueryVariables={updateQueryVariables}
              />
            </FlexItem>
          )}

          <FlexItem>
            <TableSortControl
              sortOptions={sortOptions}
              queryVariables={queryVariables}
              updateQueryVariables={updateQueryVariables}
            />
          </FlexItem>
        </FlexContainer>

        <TableSearchControl
          queryVariables={queryVariables}
          updateQueryVariables={updateQueryVariables}
        />
      </FlexContainer>

      <ResetTableFilter
        queryVariables={queryVariables}
        updateQueryVariables={updateQueryVariables}
      />
    </>
  )
}

export default IndividualsTableControls
