// @flow

import React from 'react'
import { merge } from 'lodash/fp'
import numeral from 'numeral'

import { type TraceType } from 'platform_web/services/plotly'

import HeroTable, { type HeroTablePropsType } from '.'

type TraceWithStringOrNumberY = $Diff<
  TraceType,
  {
    y: $ReadOnlyArray<?number | ?string>,
  },
>

export function mapTrace({
  x,
  y,
  ...rest
}: TraceType): TraceWithStringOrNumberY {
  if (x.length !== 3 || y.length !== 3) {
    throw new Error('Trace must have exactly 3 items in x and y')
  }

  const hoursLabel = String(x[1])
  const minutesLabel = String(x[2])
  const hoursValue = String(y[1])
  const minutesValue = String(y[2])

  return {
    ...rest,
    x: [x[0], `${hoursLabel} : ${minutesLabel}`],
    y: [y[0], `${hoursValue}:${numeral(minutesValue).format('00')}`],
  }
}

const HeroTableWithTime = ({
  columnFormat = {},
  traces,
  ...props
}: HeroTablePropsType) => (
  <HeroTable
    {...props}
    columnFormat={merge({ '1': null }, columnFormat)}
    traces={traces.map(trace => mapTrace(trace))}
  />
)

export default HeroTableWithTime
