// @flow

import { graphql } from 'react-relay'

export const assignableRequestAssessmentsQuery = graphql`
  query AssignableRequestAssessmentsQuery(
    $individuals: IndividualsSelectionInput!
    $tenant_id: ID!
  ) {
    tenant(id: $tenant_id) {
      multi_select_requestable_questionnares(individuals: $individuals) {
        requestable
        questionnaire {
          id
          label
          category
        }
      }
    }
  }
`
