// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useFragment } from 'react-relay'
import { isEmpty, uniqueId } from 'lodash'

import { FlexContainer, Icon, Text } from 'care-ui'
import ArrowRight from 'care-ui/atoms/icons/arrow-right.svg'

import { trackableColorfromColorCode } from '../helpers'

import {
  labelStyle,
  listContainerStyle,
  listStyle,
  resultStyle,
  trackableContainerStyle,
  trackableStyle,
} from './ActivityLogChangeSet.style'
import { fragment } from './query/ActivityLogChangeSet'

import type { ActivityLogChangeSet_activityLog$key } from './query/__generated__/ActivityLogChangeSet_activityLog.graphql'

type PropsType = {
  activityLog: ActivityLogChangeSet_activityLog$key,
}

const ActivityLogChangeSet = (props: PropsType) => {
  const { activityLog: fragmentRef } = props

  const { action_type: actionType, changeset } = useFragment(
    fragment,
    fragmentRef,
  )

  const { t: translation } = useTranslation('shared')

  const { css, theme } = useFela()
  if (isEmpty(changeset)) return null

  if (actionType === 'user_trackable_questionnaire_answer') {
    const { after, before } = changeset || {}

    const beforeColor = trackableColorfromColorCode({
      colorCode: before?.color,
      theme,
    })
    const afterColor = trackableColorfromColorCode({
      colorCode: after?.color,
      theme,
    })

    return (
      <FlexContainer gap="xxs" alignItems="center" marginTop="xxs">
        <Text
          size="sm"
          extend={[{ background: beforeColor }, resultStyle]}
          dataTestId="oldRating"
        >
          {before?.label}
        </Text>

        {after?.label && (
          <>
            <Icon as={ArrowRight} />

            <Text
              size="sm"
              extend={[{ background: afterColor }, resultStyle]}
              dataTestId="newRating"
            >
              {after?.label}
            </Text>
          </>
        )}
      </FlexContainer>
    )
  }

  if (actionType === 'user_trackable_write_changed') {
    const { added, removed } = changeset || {}
    const showDivider = !isEmpty(added) && !isEmpty(removed)

    const dividerStyle = showDivider && {
      borderTop: `1px solid ${theme.care.palette.border.lighter}`,
    }

    return (
      <div className={css(trackableContainerStyle)}>
        {isEmpty(removed) && (
          <div className={css(listContainerStyle)}>
            <Text size="sm" color="title" extend={labelStyle}>
              {translation('removed')}
            </Text>

            <ul className={css(listStyle)}>
              {removed.map(item => (
                <Text
                  as="li"
                  size="sm"
                  extend={trackableStyle}
                  key={uniqueId('removed-trackable-')}
                >
                  {item}
                </Text>
              ))}
            </ul>
          </div>
        )}

        {!isEmpty(added) && (
          <div className={css(dividerStyle, listContainerStyle)}>
            <Text size="sm" color="title" extend={labelStyle}>
              {translation('added')}
            </Text>

            <ul className={css(listStyle)}>
              {added.map(item => (
                <Text
                  as="li"
                  size="sm"
                  extend={trackableStyle}
                  key={uniqueId('added-trackable-')}
                >
                  {item}
                </Text>
              ))}
            </ul>
          </div>
        )}
      </div>
    )
  }

  return null
}

export default ActivityLogChangeSet
