// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const styledRules = (props: StyleProps) => {
  const { theme } = props

  return {
    page: {
      backgroundColor: theme.palette.bodyBackgroundBlue,
      height: '100vh',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    card: {
      textAlign: 'center',
      display: 'flex',
      alignItems: 'center',
      width: 'auto',
      maxWidth: '480px',
      minHeight: '400px',
      justifyContent: 'center',
    },
    cardHeader: {
      maxWidth: '400px',
    },
    errorCode: {
      color: theme.palette.component.muted.base,
      marginTop: '0.5rem',
      marginBottom: '0.5rem',
    },
  }
}
