// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import { withoutFelaProps } from 'shared/services/fela'
import { Text } from 'care-ui'

import Menu from './Menu'
import MenuItem from './MenuItem'

import type { FelaPropsType } from 'react-ui/typing'

type AllowedChildTypes = typeof MenuItem | typeof Menu

type PropsType = FelaPropsType & {
  as?: string,
  children: $ReadOnlyArray<AllowedChildTypes>,
  label: React.Node,
}

const styleRules = ({ theme }) => ({
  MenuItemGroup: {
    className: 'MenuItemGroup',
    color: 'inherit',
    marginBottom: theme.spacing(1),
  },
  children: {
    boxSizing: 'border-box',
    className: 'MenuItemGroup__children',
    display: 'block',
    paddingLeft: '0',
    width: '100%',
  },
  label: {
    className: 'MenuItemGroup__label',
    marginBottom: theme.spacing(0.5),
  },
})

const MenuItemGroup = ({
  as: Component = 'section',
  children,
  label,
  styles,
  ...props
}: PropsType) => (
  <Component {...withoutFelaProps(props)} className={styles.MenuItemGroup}>
    <Text size="lg" className={styles.label}>
      {label}
    </Text>
    <div className={styles.children}>
      {React.Children.map(children, child => {
        if (child && child.type === Menu) {
          return React.createElement(MenuItem, child.props)
        }
        return child
      })}
    </div>
  </Component>
)

export default connect(styleRules)(MenuItemGroup)
