// @flow

import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  subDays,
  subMonths,
  subWeeks,
  subYears,
} from 'date-fns'

// Function to get the appropriate interval methods
export const getIntervalMethod = (interval?: string) => {
  switch (interval) {
    case 'monthly':
      return { sub: subMonths, add: addMonths }
    case 'weekly':
      return { sub: subWeeks, add: addWeeks }
    case 'yearly':
      return { sub: subYears, add: addYears }
    default:
      return { sub: subDays, add: addDays }
  }
}
