/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ActivityLogChangeSet_activityLog$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ActivityLogCard_activityLog$ref: FragmentReference;
declare export opaque type ActivityLogCard_activityLog$fragmentType: ActivityLogCard_activityLog$ref;
export type ActivityLogCard_activityLog = {|
  +display_text: string,
  +occured_at: any,
  +$fragmentRefs: ActivityLogChangeSet_activityLog$ref,
  +$refType: ActivityLogCard_activityLog$ref,
|};
export type ActivityLogCard_activityLog$data = ActivityLogCard_activityLog;
export type ActivityLogCard_activityLog$key = {
  +$data?: ActivityLogCard_activityLog$data,
  +$fragmentRefs: ActivityLogCard_activityLog$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ActivityLogCard_activityLog",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display_text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "occured_at",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ActivityLogChangeSet_activityLog"
    }
  ],
  "type": "ActivityLog",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '29ea70e4fceb259c37249072c51efb40';

module.exports = node;
