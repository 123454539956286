/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaticQuestionnaireInformation_questionSet$ref: FragmentReference;
declare export opaque type StaticQuestionnaireInformation_questionSet$fragmentType: StaticQuestionnaireInformation_questionSet$ref;
export type StaticQuestionnaireInformation_questionSet = {|
  +time_required: ?string,
  +$refType: StaticQuestionnaireInformation_questionSet$ref,
|};
export type StaticQuestionnaireInformation_questionSet$data = StaticQuestionnaireInformation_questionSet;
export type StaticQuestionnaireInformation_questionSet$key = {
  +$data?: StaticQuestionnaireInformation_questionSet$data,
  +$fragmentRefs: StaticQuestionnaireInformation_questionSet$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaticQuestionnaireInformation_questionSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time_required",
      "storageKey": null
    }
  ],
  "type": "QuestionSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '318407e35e1bc07972e2edee3ed1c283';

module.exports = node;
