// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import { AnswerTableLoader } from 'components/AnswerTable'
import { shortDate } from 'services/dateTime'

import { answerSetAnswerSet } from './query/AnswerSet'

import type { AnswerSet_answer_set } from './query/__generated__/AnswerSet_answer_set.graphql'

export type PropsType = {
  answer_set: AnswerSet_answer_set,
}

export const AnswerSet = (props: PropsType) => {
  const { answer_set: { answers, submitted_at } } = props

  const { t: translation } = useTranslation('shared')

  const submittedAtDate = submitted_at
    ? format(shortDate)(new Date(submitted_at))
    : translation('not_submitted')

  return (
    <Fragment>
      <div>{`${translation('submitted_at')} ${submittedAtDate}`}</div>

      <AnswerTableLoader answers={answers} />
    </Fragment>
  )
}

export const AnswerSetLoader = createFragmentContainer(
  AnswerSet,
  answerSetAnswerSet,
)
