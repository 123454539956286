/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type NotificationDisplay_tenant$ref: FragmentReference;
declare export opaque type NotificationDisplay_tenant$fragmentType: NotificationDisplay_tenant$ref;
export type NotificationDisplay_tenant = {|
  +id: string,
  +help_now_modal_providers: $ReadOnlyArray<{|
    +id: string,
    +phone_number: ?string,
    +slug: string,
    +logo_url: ?string,
    +url: ?string,
    +description: ?string,
    +title: string,
  |}>,
  +$refType: NotificationDisplay_tenant$ref,
|};
export type NotificationDisplay_tenant$data = NotificationDisplay_tenant;
export type NotificationDisplay_tenant$key = {
  +$data?: NotificationDisplay_tenant$data,
  +$fragmentRefs: NotificationDisplay_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NotificationDisplay_tenant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpNowProvider",
      "kind": "LinkedField",
      "name": "help_now_modal_providers",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone_number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bdf0e845082990083cb6701dd83598d6';

module.exports = node;
