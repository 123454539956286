// @flow

import React from 'react'
import merge from 'lodash/fp/merge'

import CellRenderer from './DataGridCellRenderer'

const styleRules = ({ theme }) => ({
  backgroundColor: theme.DataGrid.header.base,
  borderBottomStyle: 'none',
  borderColor: theme.DataGrid.header.accent,
  className: 'DataGridHeaderCellRenderer',
  color: theme.care.palette.text.title,
  ...theme.care.typography.desktop.bodyMd,
  paddingBottom: '7px',
  paddingTop: '7px',
  position: 'relative',

  ':after': {
    backgroundColor: theme.DataGrid.header.base,
    bottom: 0,
    borderBottomColor: 'inherit',
    borderBottomStyle: 'solid',
    borderBottomWidth: '1px',
    content: '""',
    display: 'block',
    position: 'absolute',
    left: 0,
    right: 0,
  },
  ':not(:first-of-type):after': {
    left: theme.Grid.gutter,
  },
  ':not(:last-of-type):after': {
    right: theme.Grid.gutter,
  },
})

type PropsType = {
  extend?: () => Object,
}

const DataGridHeaderCellRenderer = ({
  extend = () => ({}),
  ...props
}: PropsType) => {
  const mergendExtend = (...args) => merge(styleRules(...args), extend(...args))

  return <CellRenderer {...props} extend={mergendExtend} />
}

export default DataGridHeaderCellRenderer
