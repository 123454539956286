// @flow

import { graphql } from 'react-relay'

export const assignmentFragment = graphql`
  fragment SupportingSupporteeRemoveModal_individual_assignments on SupportPersonAssignment
    @relay(plural: true) {
    id
    individual {
      id
      legal_name
    }
  }
`
