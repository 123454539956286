/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type NotificationPreferenceUpdateInput = {|
  clientMutationId?: ?string,
  id: string,
  notification_preference: NotificationPreferenceUpdateInputObject,
|};
export type NotificationPreferenceUpdateInputObject = {|
  sms?: ?boolean
|};
export type NotificationPreferenceUpdateInputMutationVariables = {|
  input: NotificationPreferenceUpdateInput
|};
export type NotificationPreferenceUpdateInputMutationResponse = {|
  +notificationPreferenceUpdate: ?{|
    +notification_preference: {|
      +id: string,
      +sms: boolean,
    |}
  |}
|};
export type NotificationPreferenceUpdateInputMutation = {|
  variables: NotificationPreferenceUpdateInputMutationVariables,
  response: NotificationPreferenceUpdateInputMutationResponse,
|};
*/


/*
mutation NotificationPreferenceUpdateInputMutation(
  $input: NotificationPreferenceUpdateInput!
) {
  notificationPreferenceUpdate(input: $input) {
    notification_preference {
      id
      sms
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "NotificationPreferenceUpdatePayload",
    "kind": "LinkedField",
    "name": "notificationPreferenceUpdate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "NotificationPreference",
        "kind": "LinkedField",
        "name": "notification_preference",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "sms",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "NotificationPreferenceUpdateInputMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "NotificationPreferenceUpdateInputMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "32e0ec6dcaf79dc9f7041cd5071e8ff3",
    "id": null,
    "metadata": {},
    "name": "NotificationPreferenceUpdateInputMutation",
    "operationKind": "mutation",
    "text": "mutation NotificationPreferenceUpdateInputMutation(\n  $input: NotificationPreferenceUpdateInput!\n) {\n  notificationPreferenceUpdate(input: $input) {\n    notification_preference {\n      id\n      sms\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '82512ce47eef11c08b53483e7d685251';

module.exports = node;
