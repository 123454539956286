// @flow

import * as React from 'react'

import AccessibilityBreakdownChart from '../charts/AccessibilityBreakdownChart'

import ReportsView from './ReportsView'

type PropsType = {
  +dimensions: $ReadOnlyArray<{
    +key: string,
    +value: string,
  }>,
  print?: boolean,
  title: React.Node,
  +variables: Object,
}

const AccessibilityEquityReport = ({
  title,
  variables,
  dimensions,
  print,
}: PropsType) => (
  <ReportsView print={print} title={title} className="AccessibilityTotalChart">
    <AccessibilityBreakdownChart
      variables={variables}
      dimensions={dimensions}
    />
  </ReportsView>
)

export default AccessibilityEquityReport
