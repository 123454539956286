/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TrackableDropdown_user$ref: FragmentReference;
declare export opaque type TrackableDropdown_user$fragmentType: TrackableDropdown_user$ref;
export type TrackableDropdown_user = {|
  +id: string,
  +summaryUserTrackables: {|
    +nodes: ?$ReadOnlyArray<?{|
      +trackable: {|
        +id: string,
        +label: string,
      |}
    |}>
  |},
  +$refType: TrackableDropdown_user$ref,
|};
export type TrackableDropdown_user$data = TrackableDropdown_user;
export type TrackableDropdown_user$key = {
  +$data?: TrackableDropdown_user$data,
  +$fragmentRefs: TrackableDropdown_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrackableDropdown_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "summaryUserTrackables",
      "args": [
        {
          "kind": "Literal",
          "name": "kinds",
          "value": [
            "gauge"
          ]
        }
      ],
      "concreteType": "UserTrackableConnection",
      "kind": "LinkedField",
      "name": "user_trackables",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserTrackable",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Trackable",
              "kind": "LinkedField",
              "name": "trackable",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "user_trackables(kinds:[\"gauge\"])"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '35fed369f84de8fc7187737de71e05f3';

module.exports = node;
