/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CommonTasksButtons_individual$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChartHeader_individual$ref: FragmentReference;
declare export opaque type ChartHeader_individual$fragmentType: ChartHeader_individual$ref;
export type ChartHeader_individual = {|
  +$fragmentRefs: CommonTasksButtons_individual$ref,
  +$refType: ChartHeader_individual$ref,
|};
export type ChartHeader_individual$data = ChartHeader_individual;
export type ChartHeader_individual$key = {
  +$data?: ChartHeader_individual$data,
  +$fragmentRefs: ChartHeader_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChartHeader_individual",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommonTasksButtons_individual"
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '61c59f410bd3e5bd3283e6ce17b4e453';

module.exports = node;
