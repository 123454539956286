/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EscalationResolutionOptions = "care_option_assigned" | "other" | "transferred_to_another_service" | "unable_to_contact" | "%future added value";
export type UserTrackableEscalationResolveInput = {|
  clientMutationId?: ?string,
  escalation: EscalationResolveInputObject,
  id: string,
|};
export type EscalationResolveInputObject = {|
  resolutionOption: EscalationResolutionOptions,
  resolutionText?: ?string,
|};
export type EscalationResolveUpdateMutationVariables = {|
  input: UserTrackableEscalationResolveInput
|};
export type EscalationResolveUpdateMutationResponse = {|
  +userTrackableEscalationResolve: ?{|
    +escalations: $ReadOnlyArray<{|
      +id: string,
      +resolving_role: ?({|
        +__typename: "ClinicianRole",
        +id: string,
      |} | {|
        // This will never be '%other', but we need some
        // value in case none of the concrete values match.
        +__typename: "%other"
      |}),
    |}>
  |}
|};
export type EscalationResolveUpdateMutation = {|
  variables: EscalationResolveUpdateMutationVariables,
  response: EscalationResolveUpdateMutationResponse,
|};
*/


/*
mutation EscalationResolveUpdateMutation(
  $input: UserTrackableEscalationResolveInput!
) {
  userTrackableEscalationResolve(input: $input) {
    escalations {
      id
      resolving_role {
        __typename
        ... on ClinicianRole {
          id
        }
        ... on AdminRole {
          id
        }
        ... on IndividualRole {
          id
        }
        ... on LeadClinicianRole {
          id
        }
        ... on ManagerRole {
          id
        }
        ... on OwnerRole {
          id
        }
        ... on ResearcherRole {
          id
        }
        ... on SuperRole {
          id
        }
        ... on SupportPersonRole {
          id
        }
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
],
v5 = {
  "kind": "InlineFragment",
  "selections": (v4/*: any*/),
  "type": "ClinicianRole",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EscalationResolveUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserTrackableEscalationResolvePayload",
        "kind": "LinkedField",
        "name": "userTrackableEscalationResolve",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Escalation",
            "kind": "LinkedField",
            "name": "escalations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "resolving_role",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EscalationResolveUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserTrackableEscalationResolvePayload",
        "kind": "LinkedField",
        "name": "userTrackableEscalationResolve",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Escalation",
            "kind": "LinkedField",
            "name": "escalations",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "resolving_role",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "AdminRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "LeadClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "ManagerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "OwnerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "ResearcherRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "SuperRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v4/*: any*/),
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "eed0c2addd973955ae08083c453695ed",
    "id": null,
    "metadata": {},
    "name": "EscalationResolveUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EscalationResolveUpdateMutation(\n  $input: UserTrackableEscalationResolveInput!\n) {\n  userTrackableEscalationResolve(input: $input) {\n    escalations {\n      id\n      resolving_role {\n        __typename\n        ... on ClinicianRole {\n          id\n        }\n        ... on AdminRole {\n          id\n        }\n        ... on IndividualRole {\n          id\n        }\n        ... on LeadClinicianRole {\n          id\n        }\n        ... on ManagerRole {\n          id\n        }\n        ... on OwnerRole {\n          id\n        }\n        ... on ResearcherRole {\n          id\n        }\n        ... on SuperRole {\n          id\n        }\n        ... on SupportPersonRole {\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '73921772830adaf084e238f2dd6af86d';

module.exports = node;
