/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AnswerTable_answers$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPreviousAnswerSet_answer_set$ref: FragmentReference;
declare export opaque type ScoreCardsPreviousAnswerSet_answer_set$fragmentType: ScoreCardsPreviousAnswerSet_answer_set$ref;
export type ScoreCardsPreviousAnswerSet_answer_set = {|
  +skipped: boolean,
  +answered_by: {|
    +id?: string,
    +user?: {|
      +name: string
    |},
  |},
  +answers: $ReadOnlyArray<{|
    +$fragmentRefs: AnswerTable_answers$ref
  |}>,
  +submitted_at: ?any,
  +$refType: ScoreCardsPreviousAnswerSet_answer_set$ref,
|};
export type ScoreCardsPreviousAnswerSet_answer_set$data = ScoreCardsPreviousAnswerSet_answer_set;
export type ScoreCardsPreviousAnswerSet_answer_set$key = {
  +$data?: ScoreCardsPreviousAnswerSet_answer_set$data,
  +$fragmentRefs: ScoreCardsPreviousAnswerSet_answer_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPreviousAnswerSet_answer_set",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "skipped",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "answered_by",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "answers",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AnswerTable_answers"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitted_at",
      "storageKey": null
    }
  ],
  "type": "AnswerSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '508d4f0b9782ed218481353228fac17a';

module.exports = node;
