// @flow

export const DEFAULT_TABLE_CONFIGURATIONS = {
  Active: {
    status: 'Active',
    sort: {
      column: 'activeStatus',
      direction: 'DESC',
    },
    search: '',
  },
  Invited: {
    status: 'Invited',
    sort: {
      column: 'invitedAt',
      direction: 'DESC',
    },
    search: '',
  },
  Removed: {
    status: 'Removed',
    sort: {
      column: 'removedStatus',
      direction: 'DESC',
    },
    search: '',
  },
}
