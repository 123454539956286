// @flow

import * as React from 'react'

type PropTypes = {
  children: React.Node,
  print: boolean,
  selectedIndex: number,
}

const ViewStack = ({ children, selectedIndex, print = false }: PropTypes) => {
  const newChildren = React.Children.map(children, child =>
    React.cloneElement(child, { print }),
  )
  const arrChildren = React.Children.toArray(newChildren)
  const numChildren = arrChildren.length
  const maxIndex = numChildren - 1
  const minIndex = Math.max(0, selectedIndex)
  const boundIndex = Math.min(maxIndex, Math.max(minIndex, selectedIndex))

  // Otherwise, return the selected child
  const selectedChild = arrChildren[boundIndex]

  const content = print ? newChildren : selectedChild

  return <React.Fragment>{content}</React.Fragment>
}

export default ViewStack
