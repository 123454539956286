// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DefinitionList__details___wc1y5{background-color:#fff;border-radius:.3rem;box-shadow:0 0 .4rem #a3adbb}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g{border-bottom:1px solid;border-color:#d0d6de;display:flex}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g:last-child{border-bottom:0}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g dl{max-width:95%}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g dd,.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g dt{padding:.6rem 0;min-width:40%}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g dd{margin:auto;width:100%}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g dt{font-weight:bold;padding-left:10px;text-align:left;width:40%}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g a{color:inherit}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g .DefinitionList__toggle___fOmKj{float:right}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g .DefinitionList__lockIcon___UoNbk{float:right;width:1.1rem;height:1.1rem;margin-right:1rem}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g .DefinitionList__lockIcon___UoNbk>svg>g>g>path:first-of-type{fill:#5b6c84}.DefinitionList__details___wc1y5 .DefinitionList__row___qdF0g .DefinitionList__lockIcon___UoNbk>svg>g>g>path:last-of-type{stroke:#5b6c84}.DefinitionList__details___wc1y5 .DefinitionList__display___TSsp2 dt{visibility:hidden}.DefinitionList__details___wc1y5 .DefinitionList__display___TSsp2 dd{margin-left:-4rem}.DefinitionList__dd_wrapper___ZFABf{text-align:left;width:100%}.DefinitionList__caret___sPmyz{width:.8rem;height:.8rem;margin-right:1rem;margin-top:.4rem;float:right}.DefinitionList__placeholder___yL06H{color:#0f77d9}.DefinitionList__warning___hlC0f{display:block;color:#c62828;font-size:.8rem;margin-top:.5rem}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"details": `DefinitionList__details___wc1y5`,
	"row": `DefinitionList__row___qdF0g`,
	"toggle": `DefinitionList__toggle___fOmKj`,
	"lockIcon": `DefinitionList__lockIcon___UoNbk`,
	"display": `DefinitionList__display___TSsp2`,
	"dd_wrapper": `DefinitionList__dd_wrapper___ZFABf`,
	"caret": `DefinitionList__caret___sPmyz`,
	"placeholder": `DefinitionList__placeholder___yL06H`,
	"warning": `DefinitionList__warning___hlC0f`
};
export default ___CSS_LOADER_EXPORT___;
