// @flow

import { graphql } from 'react-relay'

export const ScoreCardsPageComponentQuery = graphql`
  query ScoreCardsPageComponentQuery_Query(
    $id: ID!
    $trackable_id: ID!
    $trackable_start_time: String!
    $trackable_end_time: String!
  ) {
    user(id: $id) {
      user_trackable(trackable_id: $trackable_id) {
        ...ScoreCardsPageComponent_user_trackable
      }
    }
    viewer {
      ...ScoreCardsPageComponent_viewer
    }
  }
`

export const query = {
  user_trackable: graphql`
    fragment ScoreCardsPageComponent_user_trackable on UserTrackable {
      ...ScoreCardsPage_user_trackable
    }
  `,
  viewer: graphql`
    fragment ScoreCardsPageComponent_viewer on Viewer {
      ...ScoreCardsPage_viewer
    }
  `,
}
