// @flow

import { graphql } from 'react-relay'

export const fragment = graphql`
  fragment AssessmentRequesteesList_individual on IndividualRole {
    id
    legal_name
    support_person_assignments {
      id
      accepted_invitation
      relationship
      support_person {
        id
        legal_name
      }
    }
  }
`
