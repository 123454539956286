// @flow

import React from 'react'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'

import { DropDownItem } from 'react-ui/components/DropDown'
import { useDependentToggle } from 'platform_web/hooks/useDependentToggle'

type FuncType = {
  assignable?: boolean,
  isOpen: boolean,
  label?: string,
  onToggleOpen?: (event: SyntheticEvent<*>) => any,
  targetToggle?: () => void,
}

type PropsType = {
  styles?: { [className: string]: string },
  translation: TFunction,
} & FuncType

const StaffActionButtonTrigger = ({
  onToggleOpen,
  label,
  assignable,
  targetToggle,
  isOpen,
  translation,
}: PropsType) => {
  useDependentToggle(!isOpen, targetToggle)

  return (
    <DropDownItem
      label={label}
      disabled={!assignable}
      title={
        !assignable
          ? translation('this_clinician_has_not_onboarded_or_is_not_active')
          : undefined
      }
      onClick={onToggleOpen}
    />
  )
}

export default (props: FuncType) => {
  // Localization
  const { t: translation } = useTranslation('dataTable')

  const {
    onToggleOpen,
    label = translation('assign_clinicians'),
    assignable = true,
    targetToggle,
    isOpen,
  } = props

  return (
    <StaffActionButtonTrigger
      onToggleOpen={onToggleOpen}
      label={label}
      assignable={assignable}
      targetToggle={targetToggle}
      isOpen={isOpen}
      translation={translation}
    />
  )
}
