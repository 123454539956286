// @flow

import React from 'react'

import { Checkbox } from 'care-ui'

type RequestAssessmentListItemProps = {
  disabled?: boolean,
  handleOnChange: (id: string, label: string) => void,
  requestAssessment: {
    questionnaire: {
      category: ?string,
      id: string,
      label: string,
    },
    requestable: boolean,
  },
  selectedItems: Array<{ [key: string]: string }>,
}

const RequestAssessmentListItem = (props: RequestAssessmentListItemProps) => {
  const {
    disabled = false,
    requestAssessment,
    selectedItems,
    handleOnChange,
  } = props

  const { requestable, questionnaire: { id, label } } = requestAssessment

  const isSelected = selectedItems.some(item => item.id === id)

  if (!requestable) return null

  return (
    <Checkbox
      dataTestId="questionnaire"
      inputAttributes={{
        id,
        name: id,
        label,
        onChange: () => handleOnChange(id, label),
        value: isSelected,
        disabled,
      }}
    />
  )
}

export default RequestAssessmentListItem
