// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ center, theme }) => ({
  boxSizing: 'border-box',
  className: 'MainContentRegion',
  display: 'block',
  marginBottom: 0,
  marginLeft: center ? 'auto' : 0,
  marginRight: 'auto',
  marginTop: 0,
  maxWidth: `calc(${theme.maxTextWidth} + calc(${theme.Grid.gutter} * 2))`,
  paddingLeft: 0,
  paddingRight: 0,
  textAlign: center ? 'center' : 'left',
  width: '100%',
  ...theme.clearfix,
  [theme.breakpoints.queries.md]: {
    paddingLeft: `calc(${theme.Grid.gutter} * ${center ? 2.5 : 5})`,
    paddingRight: center ? `calc(${theme.Grid.gutter} * 2.5)` : 0,
    maxWidth: `calc(${theme.maxTextWidth} + calc(${theme.Grid.gutter} * 9))`,
  },
})

export default createComponentWithProxy(styleRules)
