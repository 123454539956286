// @flow

import { graphql } from 'react-relay'

export const currentResultCardFormulaUserTrackable = {
  user_trackable: graphql`
    fragment CurrentResultCardFormula_user_trackable on UserTrackable {
      id
      escalated
      assigned_to_me
      time_since_updated
      suggested_user_resources {
        ...ScoreCardsCareOptionsList_user_resources @relay(mask: false)
      }
      resolution_option_list {
        key
        value
        enabled
      }
      formula_range {
        ... on FormulaRange {
          id
        }
        ... on FormulaRangeInterface {
          color
          label
          description
        }
      }
      ...UserTrackableRawScore_user_trackable
    }
  `,
}
