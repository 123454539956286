// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const AppropriatnessIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 6 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M3,22 C2.448,22 2,21.552 2,21 C2,20.448 2.448,20 3,20 C3.552,20 4,20.448 4,21 C4,21.552 3.552,22 3,22 M3,11 C3.552,11 4,11.449 4,12 C4,12.551 3.552,13 3,13 C2.448,13 2,12.551 2,12 C2,11.449 2.448,11 3,11 M3,2 C3.552,2 4,2.449 4,3 C4,3.551 3.552,4 3,4 C2.448,4 2,3.551 2,3 C2,2.449 2.448,2 3,2 M6,12 C6,10.698 5.161,9.599 4,9.184 L4,5.816 C5.161,5.401 6,4.302 6,3 C6,1.346 4.654,0 3,0 C1.346,0 0,1.346 0,3 C0,4.302 0.839,5.401 2,5.815 L2,9.184 C0.839,9.599 0,10.698 0,12 C0,13.302 0.839,14.401 2,14.815 L2,18.185 C0.839,18.599 0,19.698 0,21 C0,22.654 1.346,24 3,24 C4.654,24 6,22.654 6,21 C6,19.698 5.161,18.599 4,18.185 L4,14.815 C5.161,14.401 6,13.302 6,12" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default AppropriatnessIcon
