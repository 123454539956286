/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SupportingSupporteeQuestionnairesList_user$ref: FragmentReference;
declare export opaque type SupportingSupporteeQuestionnairesList_user$fragmentType: SupportingSupporteeQuestionnairesList_user$ref;
export type SupportingSupporteeQuestionnairesList_user = {|
  +id: string,
  +current_role: {|
    +assessment_request_questionnaires?: ?{|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +questionnaire: {|
            +id: string,
            +label: string,
          |},
          +assessment_request: {|
            +id: string,
            +individual: {|
              +id: string,
              +legal_name: ?string,
              +user: {|
                +id: string
              |},
            |},
          |},
        |}
      |}>
    |}
  |},
  +$refType: SupportingSupporteeQuestionnairesList_user$ref,
|};
export type SupportingSupporteeQuestionnairesList_user$data = SupportingSupporteeQuestionnairesList_user;
export type SupportingSupporteeQuestionnairesList_user$key = {
  +$data?: SupportingSupporteeQuestionnairesList_user$data,
  +$fragmentRefs: SupportingSupporteeQuestionnairesList_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "current_role",
  "assessment_request_questionnaires"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": 10,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "supporteeUserId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./SupportingSupporteeQuestionnairesListPaginationQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "SupportingSupporteeQuestionnairesList_user",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": "assessment_request_questionnaires",
              "args": [
                {
                  "kind": "Variable",
                  "name": "supportee_user_id",
                  "variableName": "supporteeUserId"
                }
              ],
              "concreteType": "AssessmentRequestQuestionnaireTypeConnection",
              "kind": "LinkedField",
              "name": "__SupportingSupporteeQuestionnairesList_assessment_request_questionnaires_connection",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "AssessmentRequestQuestionnaireTypeEdge",
                  "kind": "LinkedField",
                  "name": "edges",
                  "plural": true,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "AssessmentRequestQuestionnaireType",
                      "kind": "LinkedField",
                      "name": "node",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Questionnaire",
                          "kind": "LinkedField",
                          "name": "questionnaire",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "label",
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "AssessmentRequest",
                          "kind": "LinkedField",
                          "name": "assessment_request",
                          "plural": false,
                          "selections": [
                            (v1/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "IndividualRole",
                              "kind": "LinkedField",
                              "name": "individual",
                              "plural": false,
                              "selections": [
                                (v1/*: any*/),
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "legal_name",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "User",
                                  "kind": "LinkedField",
                                  "name": "user",
                                  "plural": false,
                                  "selections": [
                                    (v1/*: any*/)
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "__typename",
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "cursor",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "PageInfo",
                  "kind": "LinkedField",
                  "name": "pageInfo",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "endCursor",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "hasNextPage",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "SupportPersonRole",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b357bad2293618b3b6c407efee8f1310';

module.exports = node;
