// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Form__form___ovui9{display:block;width:100%}.Form__form___ovui9 input:focus{outline:none}.Form__form___ovui9 button,.Form__form___ovui9 button:active{outline:none}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": `Form__form___ovui9`
};
export default ___CSS_LOADER_EXPORT___;
