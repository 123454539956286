// @flow

import * as React from 'react'
import { graphql, QueryRenderer } from 'react-relay'

import {
  type withRelayEnvironmentPropsType,
  withRelayEnvironment,
} from 'containers/RelayEnvironment'
import { QueryRendererLoadingIndicator } from 'platform_web/components/QueryRendererLoadingIndicator'

import { type TenantFormulasLoaderQueryResponse } from './__generated__/TenantFormulasLoaderQuery.graphql'

type PropsType = withRelayEnvironmentPropsType & {
  children: (data: $ReadOnlyArray<Object>) => React.Node,
  +variables: {
    +tenants: $ReadOnlyArray<string>,
  },
}

const query = graphql`
  query TenantFormulasLoaderQuery($tenants: [ID!]!) {
    viewer {
      tenants_with_id(ids: $tenants) {
        id
        name
        effectiveness_important_formulas: important_formulas(
          kind: effectiveness
        ) {
          id
          label
          formula {
            id
            label
          }
        }
        safety_important_formulas: important_formulas(kind: safety) {
          id
          label
          formula {
            id
            label
          }
        }
      }
    }
  }
`

const TenantFormulasLoader = ({
  children,
  environment,
  variables,
}: PropsType) => (
  <QueryRenderer
    environment={environment}
    query={query}
    render={response => (
      <QueryRendererLoadingIndicator response={response}>
        {(res: TenantFormulasLoaderQueryResponse) => {
          const data = res.viewer.tenants_with_id
          if (data) {
            return children(data)
          }
          return null
        }}
      </QueryRendererLoadingIndicator>
    )}
    variables={variables}
  />
)

export default withRelayEnvironment(TenantFormulasLoader)
