// @flow

import React from 'react'
import { connect } from 'react-fela'

import { QuestionnaireRefreshLoader } from 'components/QuestionnaireRefresh'
import { H3, H4 } from 'react-ui/components/Heading'

import FancyContainer from './FancyContainer'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  componentId?: string,
  questionnaire: Object,
  title: string,
  viewOnly: boolean,
}

const styleRules = () => ({
  headingStylesOverride: {
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center',
  },
})

const FancyHealthCardNoData = ({
  componentId,
  rules,
  title,
  questionnaire,
  viewOnly,
}: PropsType) => {
  const cta = (
    <FancyContainer hasData={false} componentId={componentId}>
      <H3 extend={rules.headingStylesOverride}>{title}</H3>
      <H4 extend={rules.headingStylesOverride}>Update my health assessment</H4>
    </FancyContainer>
  )

  if (viewOnly) {
    return cta
  }

  return <QuestionnaireRefreshLoader questionnaire={questionnaire} cta={cta} />
}

export default connect(styleRules)(FancyHealthCardNoData)
