// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { merge, uniqueId } from 'lodash/fp'

import UserCard, {
  type UserCardLoader_user,
} from 'react-ui/components/UserCard'
import { withoutFelaProps } from 'shared/services/fela'

import CheckBox from '../CheckBox'

import type { FelaOptionalPropsType, FelaPropsType } from 'react-ui/typing'

export type UserCheckBoxPropTypes = {
  error?: boolean,
  label?: string,
  readOnly?: boolean,
  staff?: boolean,
  user: ?UserCardLoader_user,
}

const styleRules = ({ checked, error, theme }) => {
  const circleColor = checked ? theme.Input.icon.base : theme.Input.label.base
  const baseIconColor = checked ? theme.Input.label.base : theme.Input.icon.base

  const iconColor = error ? theme.Input.error.accent : baseIconColor

  return {
    UserCheckbox: {
      ':checked:not(:disabled) + label:before': {
        backgroundColor: error ? theme.Input.error.base : circleColor,
        borderColor: error ? theme.Input.error.accent : circleColor,
      },
    },
    label: {
      display: 'block',
      marginBottom: theme.spacing(0.25),
      paddingLeft: 0,
      paddingRight: theme.spacing(1.5),
      flex: '1 1 100%',
      '-ms-flex': '1 1 auto',
      lineHeight: theme.spacing(1),

      ':before': {
        borderRadius: '50%',
        height: theme.spacing(1),
        left: 'auto',
        right: 0,
        top: theme.spacing(0.25),
        width: theme.spacing(1),
      },
    },
    activeIcon: {
      fill: iconColor,
      left: 'auto',
      right: '7px',
      top: '17px',
    },
  }
}

const UserCheckBox = ({
  extend = () => ({}),
  rules,
  user,
  staff = false,
  readOnly = false,
  label = null,
  ...props
}: UserCheckBoxPropTypes & FelaPropsType) => {
  const id = user ? `UserCheckbox-${user.id}` : uniqueId('listItem')

  return readOnly ? (
    <UserCard user={user} link={false} staff={staff} />
  ) : (
    <CheckBox
      extend={(...args) =>
        merge(
          {
            input: rules.UserCheckbox(...args),
            label: rules.label(...args),
            activeIcon: rules.activeIcon(...args),
          },
          extend(...args),
        )
      }
      label={label || <UserCard user={user} link={false} staff={staff} />}
      id={id}
      {...withoutFelaProps(props)}
    />
  )
}

const StyledComponent: React.ComponentType<
  UserCheckBoxPropTypes & FelaOptionalPropsType,
> = connect(styleRules)(UserCheckBox)

export default StyledComponent
