/*
 * @flow
 */

import React from 'react'
import { useTranslation } from 'react-i18next'

import { HoverTracker } from 'services/analytics'
import { trackHelpNowButtonInteraction } from 'services/analytics/events'
import { Button, Modal } from 'care-ui'
import useBreakpoints from 'care-ui/molecules/hooks/useBreakpoints'
import useModal from 'care-ui/molecules/hooks/useModal'

import HelpNowModalBody from '../HelpNowModalBody'
import useHelpNowVisitLink from '../useHelpNowVisitLink'

type Props = {
  +tenant?: ?{
    +id: string,
  },
}

const HelpNowButton = (props: Props) => {
  const { tenant } = props

  const { commit } = useHelpNowVisitLink()
  const { md } = useBreakpoints()

  const { isOpen, closeModal, openModal } = useModal()

  const handleHelpNowClick = () => {
    if (tenant) {
      commit({
        variables: {
          input: {},
        },
        onCompleted: () => {
          openModal()
        },
      })
    }

    openModal()
  }

  const { t: translation } = useTranslation('shared')

  const buttonText = md
    ? translation('i_need_help_now')
    : translation('need_help')

  return (
    <>
      <HoverTracker action={trackHelpNowButtonInteraction}>
        {trackers => (
          <Button
            variant="danger"
            ariaLabel={buttonText}
            {...(trackers: any)}
            onClick={handleHelpNowClick}
            dataTestId="help-now"
          >
            {buttonText}
          </Button>
        )}
      </HoverTracker>

      <Modal
        isOpen={isOpen}
        closeModal={closeModal}
        heading={translation('need_help_now')}
        width="780px"
      >
        <HelpNowModalBody tenantId={tenant?.id} />
      </Modal>
    </>
  )
}

export default HelpNowButton
