// @flow

import React from 'react'
import HttpError from 'found/HttpError'

import { handlePageLoadFlashes } from 'shared/services/flashes'
import { LoadingSpinner } from 'care-ui'

type OptionsType = {
  Component?: Object,
  error?: {
    response?: {
      status: number,
    },
    source?: {
      errors: [
        {
          extensions: {
            error_code: number,
          },
          message: string,
        },
      ],
    },
  },
  match: {
    router: Object,
  },
  props?: Object,
  resolving?: boolean,
}

export const loadingRender = (
  { message }: { +message?: string },
  renderCb: ?Function,
) => (options: OptionsType) => {
  if (options.error) {
    // ServerError was thrown in fetch response, proxy status to found routers error handling (`services/router.js`)
    if (options.error.response) {
      const { error: { response } } = options
      throw new HttpError(response.status)
    }

    // error details coming from the source
    if (options.error.source) {
      const errorDetails = options.error.source.errors[0]
      if (errorDetails.extensions?.error_code) {
        const { router } = options.match
        router.push({ name: 'root' })
        handlePageLoadFlashes({ error: errorDetails.message || '' })
        return <LoadingSpinner message={message} />
      }
    }
  }

  if (
    options.Component &&
    options.props &&
    // The `resolving` key is only present when found-relay has fetched all the required data from GraphQL.
    // We don't care about its value, only its existence.
    options.hasOwnProperty('resolving')
  ) {
    const { Component } = options

    return renderCb ? renderCb(options) : <Component {...options.props} />
  }
  window.scrollTo(0, 0)
  return <LoadingSpinner message={message} />
}
