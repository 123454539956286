/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type FeedbackPage_viewer$ref = any;
export type FeedbackPage_QueryVariables = {||};
export type FeedbackPage_QueryResponse = {|
  +viewer: {|
    +$fragmentRefs: FeedbackPage_viewer$ref
  |}
|};
export type FeedbackPage_Query = {|
  variables: FeedbackPage_QueryVariables,
  response: FeedbackPage_QueryResponse,
|};
*/


/*
query FeedbackPage_Query {
  viewer {
    ...FeedbackPage_viewer
    id
  }
}

fragment FeedbackForm_viewer on Viewer {
  ...FeedbackTopicSelect_viewer
  enums {
    feedback {
      topic_list {
        key
        value
      }
    }
  }
}

fragment FeedbackPage_viewer on Viewer {
  ...FeedbackForm_viewer
}

fragment FeedbackTopicSelect_viewer on Viewer {
  enums {
    feedback {
      topic_list {
        key
        value
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "FeedbackPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "FeedbackPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "FeedbackPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Enums",
            "kind": "LinkedField",
            "name": "enums",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "EnumsFeedbackTopic",
                "kind": "LinkedField",
                "name": "feedback",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SelectOption",
                    "kind": "LinkedField",
                    "name": "topic_list",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "key",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "value",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8758799111279e99c1c0f8430a1755f6",
    "id": null,
    "metadata": {},
    "name": "FeedbackPage_Query",
    "operationKind": "query",
    "text": "query FeedbackPage_Query {\n  viewer {\n    ...FeedbackPage_viewer\n    id\n  }\n}\n\nfragment FeedbackForm_viewer on Viewer {\n  ...FeedbackTopicSelect_viewer\n  enums {\n    feedback {\n      topic_list {\n        key\n        value\n      }\n    }\n  }\n}\n\nfragment FeedbackPage_viewer on Viewer {\n  ...FeedbackForm_viewer\n}\n\nfragment FeedbackTopicSelect_viewer on Viewer {\n  enums {\n    feedback {\n      topic_list {\n        key\n        value\n      }\n    }\n  }\n}\n"
  }
};
// prettier-ignore
(node/*: any*/).hash = '6f0e2f149068ac52769566132103f18b';

module.exports = node;
