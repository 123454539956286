// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const tableStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    ...theme.care.typography.desktop.bodyLg,
  }
}

export const headingStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    ...theme.care.typography.desktop.bodyLgBold,
  }
}

export const rowStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    ...theme.care.typography.desktop.bodyLg,
  }
}
