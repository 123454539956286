// @flow

import { graphql } from 'react-relay'

export const UserSettingsPersonalDetailsPageQuery = graphql`
  query UserSettingsPersonalDetailsPage_Query {
    viewer {
      currentUser {
        ...UserSettingsPersonalDetailsPage_userFields
        profileQuestionnaire {
          ...UserSettingsPersonalDetailsPage_profileQuestionnaire
        }
      }
    }
  }
`

export const userSettingsPersonalDetailsPageUserFields = {
  userFields: graphql`
    fragment UserSettingsPersonalDetailsPage_userFields on User {
      current_role {
        ... on RoleInterface {
          id
          role_type
        }
      }
      tenant {
        contract
        privacyUrl
      }
      multi_roles_enabled
      profileFields {
        id
        label
        value
        noLink
        placeholder
      }
      unconfirmed_email
    }
  `,
  profileQuestionnaire: graphql`
    fragment UserSettingsPersonalDetailsPage_profileQuestionnaire on SimpleQuestionnaire {
      questions {
        name
        label
        displayValue
      }
      orders
    }
  `,
}
