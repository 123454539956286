// @flow

import React, { useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { flatten, orderBy, slice } from 'lodash'

import { AccordionItem } from 'react-ui/components/Accordion'
import createComponentId from 'shared/services/id'
import { Heading } from 'care-ui'
import useBreakpoints from 'care-ui/molecules/hooks/useBreakpoints'

import { query } from '../../queries/ScoreCardsPreviousAnswers'
import ScoredCardsChangeLog from '../ScoreCardsChangeLog'
import { ScoreCardsPreviousAnswerSet } from '../ScoreCardsPreviousAnswerSet'

import {
  accordionItemStyle,
  headerStyle,
  resultCountContainerStyle,
  wrapperStyle,
} from './ScoreCardsPreviousAnswers.style'

import type { ScoreCardsPreviousAnswers_user_question_sets } from '../../queries/__generated__/ScoreCardsPreviousAnswers_user_question_sets.graphql'
import type { ScoreCardsPreviousAnswers_user_trackable } from '../../queries/__generated__/ScoreCardsPreviousAnswers_user_trackable.graphql'

type PropsType = {
  broadcastPointData: Function,
  componentId?: string,
  relay: Object,
  selected_answer: ?string,
  user_question_sets: ScoreCardsPreviousAnswers_user_question_sets,
  user_trackable: ScoreCardsPreviousAnswers_user_trackable,
}

const COUNT = 5

const defaultId = createComponentId(__filename)

const getResult = (user_trackable, answer_set) => {
  const defaultResponse = { value: null, date: null }

  if (!answer_set || !user_trackable?.all_data_sets[0]?.points) {
    return defaultResponse
  }

  const points = []

  user_trackable.all_data_sets.forEach(data_set => {
    points.push(data_set.points)
  })

  const pointObj = flatten(points).find(point => {
    if (!point.questionnaire_answer_set_id && !point.date) {
      return null
    }

    return (
      point.questionnaire_answer_set_id ===
      answer_set.questionnaire_answer_set_id
    )
  })

  if (!pointObj) {
    return defaultResponse
  }

  return { value: pointObj.value, date: pointObj.date }
}

const ScoreCardsPreviousAnswersComponent = (props: PropsType) => {
  const {
    broadcastPointData,
    componentId = defaultId,
    selected_answer,
    user_question_sets,
    user_trackable,
  } = props
  const [isOpen, setOpen] = useState(false)
  const [shouldShowAll, setShouldShowAll] = useState(false)

  const onToggleOpen = () => {
    setOpen(!isOpen)
  }
  const { css } = useFela()

  const { change_log_entries } = user_trackable || {}

  let logCounter = 1
  const uqsAnswerSets = user_question_sets.map(user_question_set => {
    const answer_sets = user_question_set.submitted_answer_sets
    const { label } = user_question_set.question_set
    const sortedAnswerSets = orderBy(answer_sets, 'submitted_at', 'desc')
    return sortedAnswerSets.map(answer_set => {
      const result = getResult(user_trackable, answer_set)
      const openedFromGraph =
        result.date && selected_answer
          ? new Date(result.date).getTime() === selected_answer
          : false
      const className = `${componentId}-${
        answer_set.id
      }-${openedFromGraph.toString()}`

      const renderChangeLog = () => {
        const changeLogLength = change_log_entries?.length
        const index = changeLogLength ? changeLogLength - logCounter : -1
        const renderedLogs = []
        if (!changeLogLength || index < 0 || !change_log_entries) return null

        for (let i = index; i >= 0; i -= 1) {
          const currentLog = change_log_entries[i]
          if (currentLog.created_at >= answer_set.submitted_at) {
            logCounter += 1
            renderedLogs.push(
              <ScoredCardsChangeLog
                changeLog={currentLog}
                label={label}
                key={className}
              />,
            )
          } else break
        }
        return renderedLogs
      }
      return (
        <div className={componentId} key={className} role="row">
          {renderChangeLog()}
          <ScoreCardsPreviousAnswerSet
            broadcastPointData={broadcastPointData}
            result={result}
            openedFromGraph={openedFromGraph}
            answer_set={answer_set}
            label={label}
            user_trackable={user_trackable}
          />
        </div>
      )
    })
  })

  const previousAnswerSets = flatten(uqsAnswerSets)
  useEffect(() => {
    if (previousAnswerSets.length <= 5) setShouldShowAll(true)
  })

  const displayCount = shouldShowAll ? previousAnswerSets.length : COUNT
  const displayedPreviousAnswerSets = slice(previousAnswerSets, 0, displayCount)

  const { lg } = useBreakpoints()

  const { t: translation } = useTranslation('scoreCard', {
    keyPrefix: 'scoreCardsPreviousAnswersPage',
  })

  return (
    <div className={css(wrapperStyle)}>
      <AccordionItem
        withArrow
        extend={({ theme }) => accordionItemStyle(theme, isOpen, lg)}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={translation('previousAnswersHeading')}
      >
        <div className={css(headerStyle)} role="rowgroup">
          <Heading level={4}>{translation('questionSet')}</Heading>
          <Heading level={4}>{translation('rating')}</Heading>
          <Heading level={4}>{translation('answeredOn')}</Heading>
          <Heading level={4}>{translation('status')}</Heading>
          <Heading level={4}>{translation('answeredBy')}</Heading>
        </div>

        {displayedPreviousAnswerSets}

        <div className={css(resultCountContainerStyle)} role="status">
          <div>{`${previousAnswerSets.length} ${translation('results')}`}</div>

          {!shouldShowAll && (
            <div>
              |<button type="button" onClick={() => setShouldShowAll(true)}>
                {translation('seeAll')}
              </button>
            </div>
          )}
        </div>
      </AccordionItem>
    </div>
  )
}

export const ScoreCardsPreviousAnswers = createFragmentContainer(
  ScoreCardsPreviousAnswersComponent,
  query,
)
