/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SupportingSupporteeQuestionnairesList_supportees$ref: FragmentReference;
declare export opaque type SupportingSupporteeQuestionnairesList_supportees$fragmentType: SupportingSupporteeQuestionnairesList_supportees$ref;
export type SupportingSupporteeQuestionnairesList_supportees = $ReadOnlyArray<{|
  +user: {|
    +id: string,
    +legal_name: ?string,
  |},
  +supporting_user_questionnaire: ?{|
    +ensure_questionnaire: {|
      +id: string,
      +label: string,
    |}
  |},
  +$refType: SupportingSupporteeQuestionnairesList_supportees$ref,
|}>;
export type SupportingSupporteeQuestionnairesList_supportees$data = SupportingSupporteeQuestionnairesList_supportees;
export type SupportingSupporteeQuestionnairesList_supportees$key = $ReadOnlyArray<{
  +$data?: SupportingSupporteeQuestionnairesList_supportees$data,
  +$fragmentRefs: SupportingSupporteeQuestionnairesList_supportees$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SupportingSupporteeQuestionnairesList_supportees",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "legal_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "supporting_user_questionnaire",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "ensure_questionnaire",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fd45901d43e3ec7183cc3071f799106c';

module.exports = node;
