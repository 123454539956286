/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type HealthPreferences_user_trackables$ref = any;
export type DashboardPreferencesQueryVariables = {||};
export type DashboardPreferencesQueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +extensiveUserTrackables: {|
        +$fragmentRefs: HealthPreferences_user_trackables$ref
      |},
      +homepage_path: string,
    |}
  |}
|};
export type DashboardPreferencesQuery = {|
  variables: DashboardPreferencesQueryVariables,
  response: DashboardPreferencesQueryResponse,
|};
*/


/*
query DashboardPreferencesQuery {
  viewer {
    currentUser {
      extensiveUserTrackables: user_trackables(kinds: [text, additional_assessment]) {
        ...HealthPreferences_user_trackables
      }
      homepage_path
      id
    }
    id
  }
}

fragment HealthCardFooter_user_trackable on UserTrackable {
  ...HealthCardMenu_user_trackable
  participants {
    name
    id
  }
  primary_user_questionnaire {
    questionnaire {
      id
    }
    id
  }
  requires_support
  refresh_required
  trackable {
    id
  }
  user {
    id
  }
}

fragment HealthCardMenu_user_trackable on UserTrackable {
  primary_user_questionnaire {
    questionnaire {
      id
    }
    id
  }
  trackable {
    id
  }
  user {
    id
  }
}

fragment HealthCard_user_trackable on UserTrackable {
  ...HealthCardFooter_user_trackable
  formula_range {
    __typename
    ... on FormulaRangeInterface {
      __isFormulaRangeInterface: __typename
      color
      label
    }
    ... on FormulaRange {
      id
    }
  }
  time_since_updated
  trackable {
    id
    label
    kind
  }
  trending {
    label
  }
  user {
    id
  }
}

fragment HealthPreferences_user_trackables on UserTrackableConnection {
  nodes {
    id
    in_plan
    trackable {
      id
    }
    ...HealthCard_user_trackable
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "kinds",
    "value": [
      "text",
      "additional_assessment"
    ]
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "homepage_path",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v4 = [
  (v2/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "DashboardPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": "extensiveUserTrackables",
                "args": (v0/*: any*/),
                "concreteType": "UserTrackableConnection",
                "kind": "LinkedField",
                "name": "user_trackables",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "HealthPreferences_user_trackables"
                  }
                ],
                "storageKey": "user_trackables(kinds:[\"text\",\"additional_assessment\"])"
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "DashboardPreferencesQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": "extensiveUserTrackables",
                "args": (v0/*: any*/),
                "concreteType": "UserTrackableConnection",
                "kind": "LinkedField",
                "name": "user_trackables",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "UserTrackable",
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "in_plan",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Trackable",
                        "kind": "LinkedField",
                        "name": "trackable",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "kind",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "UserQuestionnaire",
                        "kind": "LinkedField",
                        "name": "primary_user_questionnaire",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Questionnaire",
                            "kind": "LinkedField",
                            "name": "questionnaire",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": (v4/*: any*/),
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "User",
                        "kind": "LinkedField",
                        "name": "participants",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "requires_support",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "refresh_required",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "formula_range",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "color",
                                "storageKey": null
                              },
                              (v3/*: any*/)
                            ],
                            "type": "FormulaRangeInterface",
                            "abstractKey": "__isFormulaRangeInterface"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v4/*: any*/),
                            "type": "FormulaRange",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "time_since_updated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "TrendType",
                        "kind": "LinkedField",
                        "name": "trending",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "user_trackables(kinds:[\"text\",\"additional_assessment\"])"
              },
              (v1/*: any*/),
              (v2/*: any*/)
            ],
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "2a35c188d68a2fb9098f7fdef9358877",
    "id": null,
    "metadata": {},
    "name": "DashboardPreferencesQuery",
    "operationKind": "query",
    "text": "query DashboardPreferencesQuery {\n  viewer {\n    currentUser {\n      extensiveUserTrackables: user_trackables(kinds: [text, additional_assessment]) {\n        ...HealthPreferences_user_trackables\n      }\n      homepage_path\n      id\n    }\n    id\n  }\n}\n\nfragment HealthCardFooter_user_trackable on UserTrackable {\n  ...HealthCardMenu_user_trackable\n  participants {\n    name\n    id\n  }\n  primary_user_questionnaire {\n    questionnaire {\n      id\n    }\n    id\n  }\n  requires_support\n  refresh_required\n  trackable {\n    id\n  }\n  user {\n    id\n  }\n}\n\nfragment HealthCardMenu_user_trackable on UserTrackable {\n  primary_user_questionnaire {\n    questionnaire {\n      id\n    }\n    id\n  }\n  trackable {\n    id\n  }\n  user {\n    id\n  }\n}\n\nfragment HealthCard_user_trackable on UserTrackable {\n  ...HealthCardFooter_user_trackable\n  formula_range {\n    __typename\n    ... on FormulaRangeInterface {\n      __isFormulaRangeInterface: __typename\n      color\n      label\n    }\n    ... on FormulaRange {\n      id\n    }\n  }\n  time_since_updated\n  trackable {\n    id\n    label\n    kind\n  }\n  trending {\n    label\n  }\n  user {\n    id\n  }\n}\n\nfragment HealthPreferences_user_trackables on UserTrackableConnection {\n  nodes {\n    id\n    in_plan\n    trackable {\n      id\n    }\n    ...HealthCard_user_trackable\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '516db7ead122c8801fd4f2fbd786654f';

module.exports = node;
