// @flow

import * as React from 'react'
import { connect } from 'react-redux'

type PoliciesType = { [key: string]: boolean }

type PropsType = {
  children: PoliciesType => React.Node,
  policies?: PoliciesType,
}

const Can = ({ children, policies = {} }: PropsType) => children(policies)

function mapStateToProps({ policies }): PoliciesType {
  return {
    policies,
  }
}

const enhance: any = connect(mapStateToProps)

export default enhance(Can)
