// @flow

import { createComponentWithProxy } from 'react-fela'
import classNames from 'classnames'

export type TrianglePropTypes = {
  color: string,
  orientation: 'top' | 'bottom' | 'left' | 'right',
  size: string,
}

const styleRules = ({
  color = '#000',
  orientation,
  size = '0.5em',
}: TrianglePropTypes) => {
  const commonProps = {
    ...['Left', 'Right', 'Top', 'Bottom'].reduce(
      (memo, aspect) => ({
        ...memo,
        [`border${aspect}Color`]: color,
        [`border${aspect}Width`]: size,
      }),
      {},
    ),
    borderStyle: 'solid',
    className: classNames('Triangle', `--color-${color}`, `--size-${size}`, {
      [`--${orientation}`]: orientation,
    }).replace(/\s/g, ''),
    display: 'inline-block',
    height: 0,
    width: 0,
  }

  switch (orientation) {
    case 'left': {
      return {
        ...commonProps,
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        borderLeftWidth: 0,
      }
    }

    case 'right': {
      return {
        ...commonProps,
        borderTopColor: 'transparent',
        borderBottomColor: 'transparent',
        borderRightWidth: 0,
      }
    }

    case 'bottom': {
      return {
        ...commonProps,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderBottomWidth: 0,
      }
    }

    case 'top':
    default: {
      return {
        ...commonProps,
        borderLeftColor: 'transparent',
        borderRightColor: 'transparent',
        borderTopWidth: 0,
      }
    }
  }
}

export default createComponentWithProxy(styleRules, 'span')
