// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const careOptionsListStyle = () => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
})

export const userResourceStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    [theme.breakpoints.queries.md]: {
      width: '50%',
      '&:nth-child(odd)': {
        '> div:first-child': {
          '> div:first-child': {
            '> div:first-child': {
              margin: '0 1.5rem 0 0',
            },
          },
        },
      },
      '&:nth-child(even)': {
        '> div:first-child': {
          '> div:first-child': {
            '> div:first-child': {
              margin: '0 0 0 1.5rem',
            },
          },
        },
      },
    },
  }
}
