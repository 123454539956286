/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffEmployeesPage_staff_admin$ref = any;
export type StaffEmployeesPage_QueryVariables = {|
  query_type: string
|};
export type StaffEmployeesPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +staff_admin: {|
        +$fragmentRefs: StaffEmployeesPage_staff_admin$ref
      |}
    |}
  |}
|};
export type StaffEmployeesPage_Query = {|
  variables: StaffEmployeesPage_QueryVariables,
  response: StaffEmployeesPage_QueryResponse,
|};
*/


/*
query StaffEmployeesPage_Query(
  $query_type: String!
) {
  viewer {
    currentUser {
      staff_admin {
        ...StaffEmployeesPage_staff_admin
        id
      }
      id
    }
    id
  }
}

fragment StaffEmployeesPage_staff_admin on StaffAdmin {
  ...StaffInvite_staff_admin
}

fragment StaffInvite_staff_admin on StaffAdmin {
  role_type_options(query_type: $query_type)
  tenants {
    id
    ...UserInviteForm_tenants
  }
}

fragment UserInviteForm_tenants on Tenant {
  id
  name
  deactivated
  emr_provider_id
  emr_integration {
    emr_provider {
      name
      user_id_field_name
    }
    required
  }
  allowAdminClinicalAssignments
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "query_type"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffEmployeesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffAdmin",
                "kind": "LinkedField",
                "name": "staff_admin",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "StaffEmployeesPage_staff_admin"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "StaffEmployeesPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "StaffAdmin",
                "kind": "LinkedField",
                "name": "staff_admin",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Variable",
                        "name": "query_type",
                        "variableName": "query_type"
                      }
                    ],
                    "kind": "ScalarField",
                    "name": "role_type_options",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenants",
                    "plural": true,
                    "selections": [
                      (v1/*: any*/),
                      (v2/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "deactivated",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "emr_provider_id",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "EmrIntegrationType",
                        "kind": "LinkedField",
                        "name": "emr_integration",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "EmrProvider",
                            "kind": "LinkedField",
                            "name": "emr_provider",
                            "plural": false,
                            "selections": [
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "user_id_field_name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "required",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "allowAdminClinicalAssignments",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v1/*: any*/)
                ],
                "storageKey": null
              },
              (v1/*: any*/)
            ],
            "storageKey": null
          },
          (v1/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d32c90b9c878af374f97e81e0520d779",
    "id": null,
    "metadata": {},
    "name": "StaffEmployeesPage_Query",
    "operationKind": "query",
    "text": "query StaffEmployeesPage_Query(\n  $query_type: String!\n) {\n  viewer {\n    currentUser {\n      staff_admin {\n        ...StaffEmployeesPage_staff_admin\n        id\n      }\n      id\n    }\n    id\n  }\n}\n\nfragment StaffEmployeesPage_staff_admin on StaffAdmin {\n  ...StaffInvite_staff_admin\n}\n\nfragment StaffInvite_staff_admin on StaffAdmin {\n  role_type_options(query_type: $query_type)\n  tenants {\n    id\n    ...UserInviteForm_tenants\n  }\n}\n\nfragment UserInviteForm_tenants on Tenant {\n  id\n  name\n  deactivated\n  emr_provider_id\n  emr_integration {\n    emr_provider {\n      name\n      user_id_field_name\n    }\n    required\n  }\n  allowAdminClinicalAssignments\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd71c6524ddc26aea096df034555438b9';

module.exports = node;
