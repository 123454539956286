/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IndividualCliniciansList_tenant$ref = any;
export type IndividualCliniciansList_QueryVariables = {|
  individual_id: string,
  tenant_id: string,
  count: number,
  cursor?: ?string,
  search?: ?string,
|};
export type IndividualCliniciansList_QueryResponse = {|
  +viewer: {|
    +tenant: {|
      +id: string,
      +$fragmentRefs: IndividualCliniciansList_tenant$ref,
    |}
  |}
|};
export type IndividualCliniciansList_Query = {|
  variables: IndividualCliniciansList_QueryVariables,
  response: IndividualCliniciansList_QueryResponse,
|};
*/


/*
query IndividualCliniciansList_Query(
  $individual_id: ID!
  $tenant_id: ID!
  $count: Int!
  $cursor: String
  $search: String
) {
  viewer {
    tenant(id: $tenant_id) {
      id
      ...IndividualCliniciansList_tenant_2wkd3f
    }
    id
  }
}

fragment AvatarLoader_user on User {
  id
  email
  legal_name
}

fragment IndividualCliniciansList_tenant_2wkd3f on Tenant {
  assignable_clinicians(first: $count, after: $cursor, search: $search, individual_id: $individual_id) {
    edges {
      node {
        email
        id
        assigned_to_individual(individual_id: $individual_id)
        user {
          id
          ...UserCheckBoxLoader_user
          name
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment UserCardLoader_user on User {
  ...AvatarLoader_user
  username
  id
  legal_name
  preferred_name
}

fragment UserCheckBoxLoader_user on User {
  ...UserCardLoader_user
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "individual_id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenant_id"
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "tenant_id"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "kind": "Variable",
  "name": "individual_id",
  "variableName": "individual_id"
},
v8 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v9 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "IndividualCliniciansList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "count",
                    "variableName": "count"
                  },
                  {
                    "kind": "Variable",
                    "name": "cursor",
                    "variableName": "cursor"
                  },
                  (v7/*: any*/),
                  (v8/*: any*/)
                ],
                "kind": "FragmentSpread",
                "name": "IndividualCliniciansList_tenant"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "IndividualCliniciansList_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v5/*: any*/),
            "concreteType": "Tenant",
            "kind": "LinkedField",
            "name": "tenant",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": (v9/*: any*/),
                "concreteType": "ClinicianRoleConnection",
                "kind": "LinkedField",
                "name": "assignable_clinicians",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRoleEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClinicianRole",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          (v6/*: any*/),
                          {
                            "alias": null,
                            "args": [
                              (v7/*: any*/)
                            ],
                            "kind": "ScalarField",
                            "name": "assigned_to_individual",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v6/*: any*/),
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "legal_name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "preferred_name",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "name",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v9/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "ClinicianAssignmentsPopover_assignable_clinicians",
                "kind": "LinkedHandle",
                "name": "assignable_clinicians"
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "44a5c48e48bd8628e7323d89b24b8972",
    "id": null,
    "metadata": {},
    "name": "IndividualCliniciansList_Query",
    "operationKind": "query",
    "text": "query IndividualCliniciansList_Query(\n  $individual_id: ID!\n  $tenant_id: ID!\n  $count: Int!\n  $cursor: String\n  $search: String\n) {\n  viewer {\n    tenant(id: $tenant_id) {\n      id\n      ...IndividualCliniciansList_tenant_2wkd3f\n    }\n    id\n  }\n}\n\nfragment AvatarLoader_user on User {\n  id\n  email\n  legal_name\n}\n\nfragment IndividualCliniciansList_tenant_2wkd3f on Tenant {\n  assignable_clinicians(first: $count, after: $cursor, search: $search, individual_id: $individual_id) {\n    edges {\n      node {\n        email\n        id\n        assigned_to_individual(individual_id: $individual_id)\n        user {\n          id\n          ...UserCheckBoxLoader_user\n          name\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserCardLoader_user on User {\n  ...AvatarLoader_user\n  username\n  id\n  legal_name\n  preferred_name\n}\n\nfragment UserCheckBoxLoader_user on User {\n  ...UserCardLoader_user\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ea395c84dab123bcbc63ca50641fe18d';

module.exports = node;
