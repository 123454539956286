/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StaffDashboardSelection_clinician$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianViewingIndividualBanner_clinician$ref: FragmentReference;
declare export opaque type ClinicianViewingIndividualBanner_clinician$fragmentType: ClinicianViewingIndividualBanner_clinician$ref;
export type ClinicianViewingIndividualBanner_clinician = {|
  +$fragmentRefs: StaffDashboardSelection_clinician$ref,
  +$refType: ClinicianViewingIndividualBanner_clinician$ref,
|};
export type ClinicianViewingIndividualBanner_clinician$data = ClinicianViewingIndividualBanner_clinician;
export type ClinicianViewingIndividualBanner_clinician$key = {
  +$data?: ClinicianViewingIndividualBanner_clinician$data,
  +$fragmentRefs: ClinicianViewingIndividualBanner_clinician$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianViewingIndividualBanner_clinician",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffDashboardSelection_clinician"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ebe7e15aea2c23453585ba5ceb439748';

module.exports = node;
