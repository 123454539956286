// @flow

import * as React from 'react'
import { flow, replace, startCase } from 'lodash/fp'

import { FormError } from 'shared/ui/Forms'

type PropsType = {
  children: React.Node,
  errors: { [key: string]: string },
  label?: string,
  name: string,
  touched: { [key: string]: boolean },
}

const transformName = flow(replace('_id', ''), startCase)

const FormField = (props: PropsType) => {
  const { errors, label, name, touched, children } = props

  const title = label || transformName(name)

  return (
    <div>
      <label htmlFor={name}>{title}</label>
      {children}
      <FormError errors={errors} touched={touched} attr={name} />
    </div>
  )
}

export default FormField
