/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardsCareOptionsList_user_resources$ref = any;
type UserResource_user_resource$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsCareOptions_user_trackable$ref: FragmentReference;
declare export opaque type ScoreCardsCareOptions_user_trackable$fragmentType: ScoreCardsCareOptions_user_trackable$ref;
export type ScoreCardsCareOptions_user_trackable = {|
  +suggested_user_resources: $ReadOnlyArray<{|
    +status: UserResourceStatuses,
    +id: string,
    +resource: {|
      +clinical: boolean,
      +id: string,
      +label: string,
    |},
    +$fragmentRefs: UserResource_user_resource$ref & ScoreCardsCareOptionsList_user_resources$ref,
  |}>,
  +$refType: ScoreCardsCareOptions_user_trackable$ref,
|};
export type ScoreCardsCareOptions_user_trackable$data = ScoreCardsCareOptions_user_trackable;
export type ScoreCardsCareOptions_user_trackable$key = {
  +$data?: ScoreCardsCareOptions_user_trackable$data,
  +$fragmentRefs: ScoreCardsCareOptions_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsCareOptions_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResource",
      "kind": "LinkedField",
      "name": "suggested_user_resources",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "status",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Resource",
          "kind": "LinkedField",
          "name": "resource",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "clinical",
              "storageKey": null
            },
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserResource_user_resource"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ScoreCardsCareOptionsList_user_resources"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '58d2ab15d70b35ec2b298db1a494d12e';

module.exports = node;
