// @flow

import { type ThemeType } from 'care-ui/atoms'

export const styleRules = (props: ThemeType) => {
  const { theme: { palette: { component } } } = props

  return {
    Container: {
      textAlign: 'left',
    },
    buttonColumn: {
      display: 'block',
      marginBottom: '20px',
    },
    textColumn: {
      marginBottom: '20px',
      wordBreak: 'break-all',
    },
    invited: {
      color: component.success.active,
      textAlign: 'left',
    },
    invitedIcon: {
      height: '18px',
      width: '18px',
    },
    removed: {
      color: component.danger.active,
    },
  }
}
