/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserNotificationsPage_user$ref = any;
export type UserNotificationsPage_QueryVariables = {||};
export type UserNotificationsPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +$fragmentRefs: UserNotificationsPage_user$ref
    |}
  |}
|};
export type UserNotificationsPage_Query = {|
  variables: UserNotificationsPage_QueryVariables,
  response: UserNotificationsPage_QueryResponse,
|};
*/


/*
query UserNotificationsPage_Query {
  viewer {
    currentUser {
      ...UserNotificationsPage_user
      id
    }
    id
  }
}

fragment UserNotificationsPage_user on User {
  notification_preference {
    sms
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "UserNotificationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserNotificationsPage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "UserNotificationsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "NotificationPreference",
                "kind": "LinkedField",
                "name": "notification_preference",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "sms",
                    "storageKey": null
                  },
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "428451e6a01984b0b9c9e479097944d6",
    "id": null,
    "metadata": {},
    "name": "UserNotificationsPage_Query",
    "operationKind": "query",
    "text": "query UserNotificationsPage_Query {\n  viewer {\n    currentUser {\n      ...UserNotificationsPage_user\n      id\n    }\n    id\n  }\n}\n\nfragment UserNotificationsPage_user on User {\n  notification_preference {\n    sms\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '13e4b9a96e459691a2d74356fd30d876';

module.exports = node;
