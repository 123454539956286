// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import FoundLink from 'found/Link'
import { isPlainObject, omit } from 'lodash/fp'

import { felaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

export type LinkDestinationType = string | *

type PropsType = FelaPropsType & {
  as?: string | React.ComponentType<*>,
  children: React.Node,
  className?: any,
  textDecorationStyle: string,
  to: LinkDestinationType,
}

const styleRules = ({ theme, textDecorationStyle }) => ({
  LinkClass: {
    backgroundColor: 'transparent',
    border: 'none',
    className: 'Link',
    color: theme.palette.link.linkColor,
    display: 'inline',
    paddingBottom: 0,
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: 0,
    textDecoration: textDecorationStyle,
    '&:hover': {
      color: theme.palette.link.hoverColor,
    },
  },
})

// keep a single anchor element in memory to use for location checks
let a: HTMLAnchorElement

const Link = ({ as, children, to, styles, className, ...props }: PropsType) => {
  let href = to
  let isExternalDomain = false
  let isFile = false
  const isRoute = isPlainObject(to)
  if (!isRoute) {
    href = to.toString()
    a = a || document.createElement('a')
    a.setAttribute('href', href)
    // IE11 sets `host` to an empty string for relative URLs
    isExternalDomain = a.host !== window.location.host && a.host !== ''
    isFile = /\..+$/.test(href)
  }

  const isFound = !as && !isExternalDomain && !isFile
  const { LinkClass } = styles
  const commonProps = {
    ...omit(['textDecorationStyle', ...felaProps])(props),
    className: className && className.length > 0 ? className : LinkClass,
  }
  if (isFound) {
    return (
      <FoundLink to={to} {...commonProps} data-testid="FoundLink">
        {children}
      </FoundLink>
    )
  }

  const Component = as || 'a'
  const externalLinkProps = {}
  if (!as && isExternalDomain) {
    Object.assign(externalLinkProps, {
      rel: 'external',
      target: '_blank',
    })
  }

  return (
    <Component {...externalLinkProps} {...commonProps} href={href}>
      {children}
    </Component>
  )
}

export default connect(styleRules)(Link)
