// @flow

import React from 'react'
import { useFragment } from 'react-relay'
import { useRouter } from 'found'
import { get, getOr } from 'lodash/fp'

import { Card } from 'care-ui'

import HealthCardBody from '../HealthCardBody'
import HealthCardFooter from '../HealthCardFooter'

import { healthCardFragment } from './query/HealthCard'

import type { HealthCard_user_trackable$key } from './query/__generated__/HealthCard_user_trackable.graphql'

type PropsType = {
  action?: any => void,
  selectable?: boolean,
  selected?: boolean,
  +user_trackable: HealthCard_user_trackable$key,
}

const HealthCard = (props: PropsType) => {
  const { user_trackable: fragmentRef, selectable, selected, action } = props
  const user_trackable = useFragment(healthCardFragment, fragmentRef)

  const { router } = useRouter()

  const color = getOr(null, 'formula_range.color')(user_trackable)
  const lastUpdate = get('time_since_updated')(user_trackable)
  const formula_range_label = getOr(null, 'formula_range.label')(user_trackable)

  const trackableKind = get('trackable.kind')(user_trackable)
  const rating =
    trackableKind === 'additional_assessment' &&
    formula_range_label === 'Unanswered'
      ? 'Requested'
      : formula_range_label
  const title = get('trackable.label')(user_trackable)
  const trend = get('trending.label')(user_trackable)
  const userId = get('user.id')(user_trackable)
  const trackableId = get('trackable.id')(user_trackable)

  const routeToBOCByTrackableId = () => {
    router.push({
      name: 'user_score_card',
      params: {
        id: userId,
        trackable_id: trackableId,
      },
    })
  }

  const handleClick = () => {
    if (selectable && action) {
      action(trackableId)
    } else {
      routeToBOCByTrackableId()
    }
  }

  const footer = selectable ? null : (
    <HealthCardFooter user_trackable={user_trackable} />
  )

  return (
    <Card headerColor={color} footer={footer} selected={selected}>
      <HealthCardBody
        lastUpdate={lastUpdate}
        rating={rating}
        title={title}
        trend={trend}
        onClick={handleClick}
        selectable={selectable}
        selected={selected}
      />
    </Card>
  )
}

export default HealthCard
