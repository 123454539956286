// @flow

import type { CallbacksType } from './request'
import makeRequest from './request'

type ChangePasswordPayloadType = {
  password: string,
  password_confirmation: string,
  reset_password_token: string,
}

export const changePasswordRequest = (
  payload: ChangePasswordPayloadType,
  cbs?: CallbacksType,
) =>
  makeRequest(
    '/users/password',
    'PATCH',
    // $DisableFlow the flow type definition is correct, it just doesn't work
    { user: payload },
    cbs,
  )

export const newPasswordRequest = (email: string, cbs?: CallbacksType) =>
  makeRequest('/users/password', 'POST', { user: { email } }, cbs)

type UpdateInvitationPayloadType = {
  user: {
    accepted_terms_and_conditions: boolean,
    invitation_token: string,
    password: string,
    username: string,
  },
}

export function updateInvitation(
  payload: UpdateInvitationPayloadType,
): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(
      '/users/invitation',
      'PATCH',
      // $DisableFlow the flow type definition is correct, it just doesn't work
      payload,
      {
        onSuccess: resolve,
        onError: reject,
      },
    )
  })
}

type EditRegistrationPayloadType = {
  current_password: string,
  password: string,
  password_confirmation: string,
}

export const updateRegistrationRequest = (
  payload: EditRegistrationPayloadType,
  cbs?: CallbacksType,
) =>
  makeRequest(
    '/users/registration',
    'PUT',
    // $DisableFlow the flow type definition is correct, it just doesn't work
    { user: payload },
    cbs,
  )

export function requestReinvitation(token: string): Promise<Response> {
  return new Promise((resolve, reject) => {
    makeRequest(`/api/v1/users/invitations/${token}/request`, 'POST', null, {
      onSuccess: resolve,
      onError: reject,
    })
  })
}
