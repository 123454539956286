// @flow

type ThemeType = { [string]: any }

export const dropdown = ({ fullWidth }: { fullWidth: true }) => {
  const widthStyle = fullWidth ? { width: '100%' } : {}

  return {
    position: 'relative',
    display: 'inline-block',
    ...widthStyle,
  }
}

export const dropdownContent = ({
  theme,
  position = 'start',
  fullWidth,
}: {
  fullWidth: boolean,
  position: string,
  theme: ThemeType,
}) => {
  const defaultStyle = {
    display: 'block',
    position: 'absolute',
    backgroundColor: theme.care.palette.surface.default,
    borderRadius: theme.care.radius.sm,
    padding: `${theme.care.spacing.xxs} ${theme.care.spacing.sm}`,
    boxShadow: theme.care.elevation[2],
    marginTop: theme.care.spacing.xxs,
    zIndex: 100,
  }

  // POSITION VARIANCE -> START(default) | END | LEFT | RIGHT | REVERSE
  const positionStyle = {}

  // START
  if (position === 'start') {
    positionStyle.left = 0
    positionStyle.transform = 'translateX(1%)'
  }
  // END
  if (position === 'end') {
    positionStyle.right = 0
  }

  // LEFT
  if (position === 'left') {
    positionStyle.left = 0
    positionStyle.top = 0
    positionStyle.transform = 'translateX(-115%)'
  }

  // REVERSE
  if (position === 'reverse') {
    positionStyle.top = 0
    positionStyle.right = 0
    positionStyle.transform = 'translateY(-105%)'
  }

  const widthStyle = fullWidth ? { width: '98%' } : {}

  return {
    ...defaultStyle,
    ...positionStyle,
    ...widthStyle,
  }
}
