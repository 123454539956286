/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportView = "CLIENT_INSIGHT" | "SERVICE_INSIGHT" | "%future added value";
export type SafetyInitialResultReportQueryVariables = {|
  formula: string,
  from: any,
  tenant: string,
  to: any,
  report_view?: ?ReportView,
|};
export type SafetyInitialResultReportQueryResponse = {|
  +viewer: {|
    +reports: {|
      +safety_initial_result: {|
        +traces: $ReadOnlyArray<{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<string>,
          +y: $ReadOnlyArray<?number>,
          +total: number,
        |}>
      |}
    |}
  |}
|};
export type SafetyInitialResultReportQuery = {|
  variables: SafetyInitialResultReportQueryVariables,
  response: SafetyInitialResultReportQueryResponse,
|};
*/


/*
query SafetyInitialResultReportQuery(
  $formula: ID!
  $from: DateTime!
  $tenant: ID!
  $to: DateTime!
  $report_view: ReportView
) {
  viewer {
    reports {
      safety_initial_result(formula: $formula, from: $from, tenant: $tenant, to: $to, report_view: $report_view) {
        traces {
          name
          type
          x
          y
          total
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "formula"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "report_view"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenant"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "formula",
      "variableName": "formula"
    },
    {
      "kind": "Variable",
      "name": "from",
      "variableName": "from"
    },
    {
      "kind": "Variable",
      "name": "report_view",
      "variableName": "report_view"
    },
    {
      "kind": "Variable",
      "name": "tenant",
      "variableName": "tenant"
    },
    {
      "kind": "Variable",
      "name": "to",
      "variableName": "to"
    }
  ],
  "concreteType": "ReportsSafetyInitialResult",
  "kind": "LinkedField",
  "name": "safety_initial_result",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsSafetyInitialResultTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "x",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "y",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SafetyInitialResultReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SafetyInitialResultReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "028e22f4fa2c4daac1a5a47dffcd9aa5",
    "id": null,
    "metadata": {},
    "name": "SafetyInitialResultReportQuery",
    "operationKind": "query",
    "text": "query SafetyInitialResultReportQuery(\n  $formula: ID!\n  $from: DateTime!\n  $tenant: ID!\n  $to: DateTime!\n  $report_view: ReportView\n) {\n  viewer {\n    reports {\n      safety_initial_result(formula: $formula, from: $from, tenant: $tenant, to: $to, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n          total\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '889aa19553a88ea8bdb5414ced8bd1ef';

module.exports = node;
