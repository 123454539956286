// @flow

import { graphql } from 'react-relay'

export const HelpNowProvidersTenantQuery = graphql`
  query HelpNowModalBodyTenant_Query($id: ID!, $skip: Boolean!) {
    viewer @skip(if: $skip) {
      tenant(id: $id) {
        emergencyNumber
        help_now_providers {
          chat_url
          description
          id
          slug
          logo_url
          phone_number
          sms
          title
          url
        }
      }
    }
  }
`
