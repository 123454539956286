// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import { usePaginationQuery } from 'react-ui/contexts/PaginationQueryContext'
import { type UserInviteInput } from 'mutations/Role/__generated__/RoleStaffInviteMutation.graphql'
import { commit as commitIndividualInvite } from 'mutations/Role/RoleIndividualInvite'
import { commit as commitInvite } from 'mutations/Role/RoleStaffInvite'
import { Button, FlexContainer, Modal } from 'care-ui'

import { styleRules } from './RoleInviteModal.style'

import type { FelaRulesType } from 'react-ui/typing'

type PropsType = {
  children: ({ onFormSubmit: Object => UserInviteInput => void }) => React.Node,
  heading: string,
  queryType: string,
  rules: FelaRulesType,
  title: string,
}

const RoleInviteModal = (props: PropsType) => {
  const { updateVariables } = usePaginationQuery()
  const [isOpen, setIsOpen] = React.useState(false)

  const { heading, title, children, queryType } = props

  const onToggleOpen = () => setIsOpen(prev => !prev)

  // For flow's sake, inlining the args to commit
  const onFormSubmit = environment => values => {
    const { roleType } = values
    if (roleType === 'INDIVIDUAL') {
      commitIndividualInvite({
        environment,
        updateVariables,
        variables: {
          input: values,
        },
      })
    } else {
      commitInvite({
        environment,
        updateVariables,
        variables: {
          input: values,
        },
        queryType,
      })
    }
    onToggleOpen()
  }

  return (
    <>
      <Button
        onClick={onToggleOpen}
        variant="primary"
        ariaLabel={title}
        dataTestId="role-invite-modal-trigger"
      >
        {title}
      </Button>

      <Modal
        isOpen={isOpen}
        closeModal={onToggleOpen}
        heading={heading}
        width="600px"
      >
        <FlexContainer direction="column" wrap="nowrap" alignItems="center">
          {children({ onFormSubmit })}
        </FlexContainer>
      </Modal>
    </>
  )
}

export default connect(styleRules)(RoleInviteModal)
