// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ theme }) => ({
  backgroundColor: theme.Grid.outline ? 'rgba(0,0,0,0.15)' : 'none',
  boxSizing: 'border-box',
  className: 'Container',
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: theme.Grid.containerMaxWidth,
  outline: theme.Grid.outline ? '1px dashed tomato' : 'none',
  paddingLeft: theme.Grid.gutter,
  paddingRight: theme.Grid.gutter,
  width: '100%',
})

export default createComponentWithProxy(styleRules)
