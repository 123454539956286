// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import type { FelaStylesType } from 'react-ui/typing'

const styledRules = {
  wrapper: {
    width: '100%',
    height: '70vh',
    display: 'flex',
    justifyContent: 'center',
  },
  formContainer: {
    padding: '0 15px',
    display: 'flex',
    alignItems: 'center',
  },
}

const ResponsiveForm = ({
  styles,
  children,
}: {
  children: Node,
  styles: FelaStylesType,
}) => (
  <div className={styles.wrapper}>
    <div className={styles.formContainer}>{children}</div>
  </div>
)

export default connect(styledRules)(ResponsiveForm)
