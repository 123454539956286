// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const PlusIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 40 40"
        style={{ enableBackground: 'new 0 0 40 40' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g>
          <g transform="translate(-416.000000, -230.000000)">
            <g transform="translate(236.000000, 210.000000)">
              <g transform="translate(199.798990, 39.798990) rotate(45.000000) translate(-199.798990, -39.798990) translate(185.798990, 25.798990)">
                <g>
                  <path
                    d="M12.6,14L0.3,1.7c-0.4-0.4-0.4-1,0-1.4s1-0.4,1.4,0L14,12.6L26.3,0.3c0.4-0.4,1-0.4,1.4,0
						c0.4,0.4,0.4,1,0,1.4L15.4,14l12.3,12.3c0.4,0.4,0.4,1,0,1.4c-0.4,0.4-1,0.4-1.4,0L14,15.4L1.7,27.7c-0.4,0.4-1,0.4-1.4,0
						c-0.4-0.4-0.4-1,0-1.4L12.6,14z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default PlusIcon
