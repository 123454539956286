// @flow

import { type ThemeType } from 'care-ui/atoms'

export const styleRules = (props: ThemeType) => {
  const { theme: { palette: { component }, care } } = props

  return {
    hr: {
      borderColor: component.muted.mutedBase,
      margin: '18px 18px 36px 18px',
    },
    Modal: {
      maxWidth: '1010px',
      textAlign: 'center',
    },
    content: {
      textAlign: 'left',
    },
    heading: {
      marginBottom: '2rem',
    },
    description: {
      ...care.typography.desktop.bodyLg,
    },
  }
}
