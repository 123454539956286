// @flow

import React from 'react'

import ReleaseWarningBanner from './ReleaseWarningBanner'
import ReleaseWarningWrapper from './ReleaseWarningWrapper'

type PropsType = {
  localVersion: ?string,
}

const ReleaseWarning = ({ localVersion }: PropsType) => (
  <ReleaseWarningWrapper localVersion={localVersion}>
    {({ outOfSync }) => outOfSync && <ReleaseWarningBanner />}
  </ReleaseWarningWrapper>
)

export default ReleaseWarning
