// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const AccessibilityIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        viewBox="0 0 24 24"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g stroke="none" strokeWidth={1} fillRule="evenodd">
          <path d="M24,12 C24,18.6272847 18.6272847,24 12,24 C5.37271525,24 0,18.6272847 0,12 C0,5.37271525 5.37271525,0 12,0 C18.6272847,0 24,5.37271525 24,12 Z M22,12 C22,6.47728475 17.5227153,2 12,2 C6.47728475,2 2,6.47728475 2,12 C2,17.5227153 6.47728475,22 12,22 C17.5227153,22 22,17.5227153 22,12 Z M9.1953,8.6523 C9.1953,9.4803 8.5233,10.1523 7.6953,10.1523 C6.8673,10.1523 6.1953,9.4803 6.1953,8.6523 C6.1953,7.8243 6.8673,7.1523 7.6953,7.1523 C8.5233,7.1523 9.1953,7.8243 9.1953,8.6523 Z M17.8047,8.6523 C17.8047,9.4803 17.1327,10.1523 16.3047,10.1523 C15.4767,10.1523 14.8047,9.4803 14.8047,8.6523 C14.8047,7.8243 15.4767,7.1523 16.3047,7.1523 C17.1327,7.1523 17.8047,7.8243 17.8047,8.6523 Z M5.92447057,15.8869755 C5.62663696,15.4218802 5.7622293,14.8034042 6.22732454,14.5055706 C6.69241978,14.207737 7.31089582,14.3433293 7.60872943,14.8084245 C8.562899,16.2984502 10.2024872,17.2177 11.9996,17.2177 C13.796964,17.2177 15.4351499,16.2991082 16.3919143,14.8077323 C16.6901302,14.3428821 17.3087176,14.2077983 17.7735677,14.5060143 C18.2384179,14.8042302 18.3735017,15.4228176 18.0752857,15.8876677 C16.7550235,17.9456533 14.486498,19.2177 11.9996,19.2177 C9.5127362,19.2177 7.24216951,17.9446865 5.92447057,15.8869755 Z" />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default AccessibilityIcon
