/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type SupportPersonAssignmentRelationship = "ALLIED_HEALTH_PROFESSIONAL" | "CARER" | "FRIEND" | "GRANDPARENT" | "GUARDIAN" | "OTHER" | "PARENT" | "PARTNER" | "RELATIVE" | "SIBLING" | "TEACHER" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SupportPersonTask_individual$ref: FragmentReference;
declare export opaque type SupportPersonTask_individual$fragmentType: SupportPersonTask_individual$ref;
export type SupportPersonTask_individual = {|
  +has_support_person_attached_to_individual: boolean,
  +individual_detail: ?{|
    +read_only: boolean
  |},
  +support_person_assignments: $ReadOnlyArray<{|
    +id: string,
    +nickname: string,
    +relationship: SupportPersonAssignmentRelationship,
    +support_person: {|
      +email: string
    |},
    +last_requested_at: any,
    +accepted_invitation: boolean,
    +last_completed: ?any,
  |}>,
  +$refType: SupportPersonTask_individual$ref,
|};
export type SupportPersonTask_individual$data = SupportPersonTask_individual;
export type SupportPersonTask_individual$key = {
  +$data?: SupportPersonTask_individual$data,
  +$fragmentRefs: SupportPersonTask_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SupportPersonTask_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "has_support_person_attached_to_individual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualDetail",
      "kind": "LinkedField",
      "name": "individual_detail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "read_only",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SupportPersonAssignment",
      "kind": "LinkedField",
      "name": "support_person_assignments",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "nickname",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "relationship",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SupportPersonRole",
          "kind": "LinkedField",
          "name": "support_person",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "email",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_requested_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "accepted_invitation",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_completed",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6a1cffcb2be8a2ad73ee27557a1a4a63';

module.exports = node;
