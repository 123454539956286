// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const styleRules = (props: StyleProps) => {
  const { theme: { palette } } = props

  return {
    container: {
      className: 'OnboardingTemplate',
      marginBottom: '56px',
      position: 'relative',
    },

    firstRow: {
      marginTop: '98px',
      marginBottom: '40px',
    },

    row: {
      justifyContent: 'center',
      color: palette.component.primary.text,
    },

    primaryTitle: {
      color: palette.component.primary.text,
      textAlign: 'center',
    },

    introText: {
      width: '70%',
      margin: '0 auto',
      fontSize: '18px',
      textAlign: 'center',
    },
  }
}
