// @flow

import * as React from 'react'
import { merge } from 'lodash'

import DataGrid from './DataGrid'
import HeaderCellRenderer from './DataGridHeaderCellRenderer'
import SortableHeaderLabel from './SortableHeaderLabel'

const createLabel = ({
  direction,
  label,
}: {
  direction?: -1 | 1,
  label: string,
}) => (
  <SortableHeaderLabel sortDirection={direction}>{label}</SortableHeaderLabel>
)

type CellRendererType = (
  rowData: any,
  columnName: React.Element<any> | string,
  props: any,
) => React.Node

type FormatFuncType = (data: any) => React.Node

type ColumnConfigValue = {
  cellRenderer?: CellRendererType,
  format?: ?FormatFuncType,
  headerCellRenderer?: CellRendererType,
  label?: string,
  name?: string,
  sort?: Object,
  sortable?: boolean,
  width?: string,
}

export type ColumnConfigsType = {
  [key: string]: ColumnConfigValue,
}

export type PageControlsType = {
  hasMore: () => boolean,
  loadMore: number => void,
}

type DataGridHeaderCellRenderer = (
  key: string,
  label: React.Element<any> | string,
  props: { width: string },
) => React.Node

type SortableDataGridPropsType = {
  columnConfig?: ColumnConfigsType,
  data: $ReadOnlyArray<{ +[string]: any }>,
  defaultSort?: Object,
  mapData?: (data: any) => any,
  renderHeaderCell?: DataGridHeaderCellRenderer,
}

function isUnsortable(value) {
  return !value && typeof value !== 'boolean'
}

const SortableDataGrid = ({
  defaultSort,
  columnConfig = {},
  data,
  renderHeaderCell,
  ...props
}: SortableDataGridPropsType) => {
  const [sort, updateSort] = React.useState(defaultSort || {})

  const config = Object.keys(columnConfig).reduce((memo, column) => {
    const col = columnConfig[column]
    return merge(
      memo,
      Object.keys(col).reduce((accum, propName) => {
        let key

        switch (propName) {
          case 'label': {
            key = 'headers'
            break
          }
          case 'width': {
            key = 'columnWidths'
            break
          }
          case 'cellRenderer': {
            key = 'cellRenderers'
            break
          }
          case 'headerCellRenderer': {
            key = 'headerCellRenderers'
            break
          }
          case 'sort': {
            key = 'columnSort'
            break
          }
          default: {
            key = propName
          }
        }

        accum[key] = { [column]: col[propName] }
        return accum
      }, {}),
    )
  }, {})

  const handleHeaderClick = key => {
    updateSort({
      key,
      direction: sort && sort.key === key ? -sort.direction : 1,
    })
  }

  const createExtendForKey = key => ({ theme }) => {
    if (sort.key === key) {
      const color = theme.palette.component.primary.accent
      return {
        color,
        borderColor: color,
      }
    }
    return {}
  }

  const copyData = [...data]

  const sortedData = !(sort.direction === 1 || sort.direction === -1)
    ? data
    : copyData.sort((a, b) => {
        const { mapData = o => o } = props
        const valueA = mapData(a)[sort.key]
        const valueB = mapData(b)[sort.key]

        if (isUnsortable(valueA) && !isUnsortable(valueB)) {
          return 1 * sort.direction
        } else if (isUnsortable(valueB)) {
          return -1 * sort.direction
        }
        return (
          valueA.toString().localeCompare(valueB.toString(), undefined, {
            numeric: true,
          }) * sort.direction
        )
      })

  const headerCellRenderers = {
    ...Object.keys(columnConfig)
      // filter out sortable: false columns
      .filter(
        key =>
          typeof columnConfig[key].sortable !== 'boolean' &&
          columnConfig[key].sortable,
      )
      .reduce(
        (memo, key) => ({
          ...memo,
          [key]: (columnKey, label, headerProps) => {
            const nextLabel = createLabel({
              label,
              direction: sort.key === key ? sort.direction : undefined,
            })

            const { headerCellRenderer } = columnConfig[key]

            return (
              headerCellRenderer &&
              headerCellRenderer(columnKey, nextLabel, {
                ...headerProps,
                extend: createExtendForKey(key),
                onClick: evt => {
                  evt.preventDefault()
                  handleHeaderClick(key)
                },
              })
            )
          },
        }),
        {},
      ),
  }

  const defaultRenderHeaderCell = (key, label, headerProps) => {
    if (
      typeof columnConfig[key].sortable === 'boolean' &&
      !columnConfig[key].sortable
    ) {
      if (renderHeaderCell) {
        return renderHeaderCell(key, label, headerProps)
      }

      return (
        <HeaderCellRenderer key={key} {...headerProps}>
          {label}
        </HeaderCellRenderer>
      )
    }

    const nextLabel = createLabel({
      label,
      direction: sort.key === key ? sort.direction : undefined,
    })

    const nextHeaderProps = {
      ...headerProps,
      extend: createExtendForKey(key),
      onClick: evt => {
        evt.preventDefault()
        handleHeaderClick(key)
      },
    }

    if (renderHeaderCell) {
      return renderHeaderCell(key, nextLabel, nextHeaderProps)
    }

    return (
      <HeaderCellRenderer key={key} {...nextHeaderProps}>
        {nextLabel}
      </HeaderCellRenderer>
    )
  }

  return (
    <DataGrid
      {...(props: any)}
      {...(config: any)}
      data={sortedData}
      headerCellRenderers={headerCellRenderers}
      renderHeaderCell={defaultRenderHeaderCell}
    />
  )
}

export default SortableDataGrid
