// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import Button from 'react-ui/components/Button'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Markdown from 'react-ui/components/Markdown'
import Section from 'react-ui/components/Section'

import type { FelaPropsType } from 'react-ui/typing'

type TitlePropsType = {
  subHeading?: string,
  title: string,
}

type ButtonType = {
  componentId?: string,
  disabled?: boolean,
  ghost?: boolean,
  invert?: boolean,
  label: string,
  onClick?: () => void,
  route?: string,
}

type DescPropsType = {
  backButton: ButtonType,
  button: ButtonType,
  customHeader?: React.Node,
  description: string,
}

type PropsType = TitlePropsType &
  DescPropsType &
  FelaPropsType & {
    children?: React.Node,
    hasEmptyHeader: boolean,
    headerButton?: React.Node,
    light: boolean,
    onClick: () => void,
  }

const styleRules = ({ theme, light }) => ({
  ResponsiveLayout: {
    height: '100%',
  },
  ContainerWrapper: {
    display: 'flex',
    flexDirection: 'column',
    padding: '15px 10px',
    maxWidth: `${theme.Grid.containerMaxWidth}`,
    margin: 'auto',
    [theme.breakpoints.queries.md]: {
      flexDirection: 'row',
      padding: '15px 0',
    },
  },
  Container: {
    [theme.breakpoints.queries.md]: {
      maxWidth: '100%',
    },
    justifyContent: 'center',
  },
  Button: {
    [theme.breakpoints.queries.md]: {
      marginTop: '36px',
      position: 'absolute',
    },
  },
  HeaderContent: {
    margin: 'inherit',
    ...(light && {
      display: 'flex',
      alignItems: 'center',
    }),
  },
  innerTitleHeader: {
    position: 'inherit',
    ...(light && {
      width: '100%',
    }),
  },
  withBackButton: {
    [theme.breakpoints.queries.md]: {
      paddingLeft: '100px',
    },
  },
})

function showButton({
  componentId,
  route,
  onClick,
  label,
  ghost,
  invert,
  disabled,
}: ButtonType) {
  if (!!route === !!onClick) return <span>{label}</span>
  return (
    <Button
      componentId={componentId}
      ghost={ghost}
      invert={invert}
      disabled={disabled}
      onClick={onClick}
      shape="pill"
      to={route}
    >
      {label}
    </Button>
  )
}

const Description = ({ content }) => {
  if (typeof content === 'string') {
    return <Markdown source={content} />
  }

  return content
}

const ResponsiveLayout = ({
  title,
  light = false,
  headerButton,
  description,
  button,
  backButton,
  children,
  subHeading,
  hasEmptyHeader,
  rules,
  styles,
  customHeader,
  onClick,
}: PropsType) => (
  <div className={styles.ResponsiveLayout}>
    {customHeader && (
      <Section color="primary" center={!light} noPadding>
        {customHeader}
      </Section>
    )}
    {!hasEmptyHeader && (
      <Section
        color="primary"
        center={!light}
        noPadding={light}
        extend={() => ({ Section: rules.innerTitleHeader })}
      >
        <div className={styles.ContainerWrapper}>
          {backButton && (
            <div data-component-id="rl-back-button" className={styles.Button}>
              {showButton(backButton)}
            </div>
          )}
          <Container
            extend={rules.Container}
            className={backButton ? styles.withBackButton : ''}
          >
            <div className={styles.HeaderContent}>
              <Heading level={4} onClick={onClick} extend={{ marginTop: 0 }}>
                {title}
              </Heading>
              {subHeading && <Heading level={3}>{subHeading}</Heading>}
              {description && <Description content={description} />}
              {light && headerButton}
            </div>
            {button && showButton(button)}
          </Container>
        </div>
      </Section>
    )}
    {children}
  </div>
)

export default connect(styleRules)(ResponsiveLayout)
