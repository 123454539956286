/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type QuestionnaireRefresh_questionnaire$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type TrackableResultCardAlerts_user_trackable$ref: FragmentReference;
declare export opaque type TrackableResultCardAlerts_user_trackable$fragmentType: TrackableResultCardAlerts_user_trackable$ref;
export type TrackableResultCardAlerts_user_trackable = {|
  +primary_user_questionnaire: ?{|
    +questionnaire: ?{|
      +id: string,
      +$fragmentRefs: QuestionnaireRefresh_questionnaire$ref,
    |}
  |},
  +refresh_required: boolean,
  +$refType: TrackableResultCardAlerts_user_trackable$ref,
|};
export type TrackableResultCardAlerts_user_trackable$data = TrackableResultCardAlerts_user_trackable;
export type TrackableResultCardAlerts_user_trackable$key = {
  +$data?: TrackableResultCardAlerts_user_trackable$data,
  +$fragmentRefs: TrackableResultCardAlerts_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TrackableResultCardAlerts_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "primary_user_questionnaire",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "QuestionnaireRefresh_questionnaire"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refresh_required",
      "storageKey": null
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '7b7b4c7bc7a1158f27d6ab4aa3ba5553';

module.exports = node;
