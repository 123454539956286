// @flow

import { curry } from 'lodash/fp'
import { type GraphQLTaggedNode, commitMutation } from 'relay-runtime'
import * as yup from 'yup'

export const handleFieldChange = curry(
  (
    setFieldValue: (field: string, value: string) => void,
    field: string,
    value: string,
  ) => setFieldValue(field, value),
)

export const requiredTextField = (field_name: string) =>
  yup
    .string()
    .ensure()
    .trim()
    .required(`${field_name} is required`)

export const commitMutationPromise = (
  environment: Object,
  {
    mutation,
    onCompleted,
    onError,
    variables,
  }: {
    mutation: GraphQLTaggedNode,
    onCompleted?: (result: Object) => void,
    onError?: (result: Object) => void,
    variables: Object,
  },
): Promise<any> =>
  new Promise((resolve, reject) =>
    commitMutation(environment, {
      mutation,
      onCompleted: result => {
        if (typeof onCompleted === 'function') onCompleted(result)
        resolve(result)
      },
      onError: result => {
        if (typeof onError === 'function') onError(result)
        reject(result)
      },
      variables,
    }),
  )
