/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AnswerSet_answer_set$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthHistory_user$ref: FragmentReference;
declare export opaque type HealthHistory_user$fragmentType: HealthHistory_user$ref;
export type HealthHistory_user = {|
  +health_history_user_questionnaires_question_sets: $ReadOnlyArray<{|
    +id: string,
    +latest_submitted_non_skipped_answer_set: ?{|
      +$fragmentRefs: AnswerSet_answer_set$ref
    |},
    +question_set: {|
      +label: string
    |},
  |}>,
  +$refType: HealthHistory_user$ref,
|};
export type HealthHistory_user$data = HealthHistory_user;
export type HealthHistory_user$key = {
  +$data?: HealthHistory_user$data,
  +$fragmentRefs: HealthHistory_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthHistory_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "health_history_user_questionnaires_question_sets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AnswerSet",
          "kind": "LinkedField",
          "name": "latest_submitted_non_skipped_answer_set",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AnswerSet_answer_set"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionSet",
          "kind": "LinkedField",
          "name": "question_set",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'bc5e570d675f6f685619ee9c2716e39b';

module.exports = node;
