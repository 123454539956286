// @flow

import { graphql } from 'react-relay'

const ReinviteRolesMutation = graphql`
  mutation ReinviteRolesMutation($input: RolesReintroduceInput!) {
    rolesReintroduce(input: $input) {
      success
      active_individuals_in_another_tenant
    }
  }
`

export default ReinviteRolesMutation
