// @flow

import React, { type ElementType, type Node } from 'react'

import { Layout2Col } from 'react-ui/components/Layout'
import Themelet from 'react-ui/components/Themelet'

const styleRules = ({ theme }) => ({
  asideContent: {
    className: 'PageLayout__asideContent',
    justifyContent: 'center',
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    paddingTop: theme.spacing(1),
  },
  articleContent: {
    className: 'PageLayout__articleContent',
    justifyContent: 'center',
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
  },
})

type PropsType = {
  as?: ElementType,
  aside?: Node,
  asideColSpan?: number,
  children: Node,
  fluid?: boolean,
  renderArticle?: any,
  // This should be a more specific function type, but we can't get Flow to work
  // with it until we have better types for Lodash's `curry` method.
  renderAside?: any,
  reverse?: boolean,
}

const PageLayout = ({
  renderAside,
  reverse = false,
  ...rest
}: PropsType): Node => {
  const renderedAside =
    renderAside ||
    ((props, rule) =>
      React.createElement(Themelet, {
        ...props,
        as: 'aside',
        variant: 'primary',
        extend: (...args) => rule(...args),
      }))

  return (
    <Layout2Col
      extend={styleRules}
      renderAside={renderedAside}
      reverse={reverse}
      {...(rest: any)}
    />
  )
}

export default PageLayout
