// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'
import ReactModal from 'react-modal'

import Cross from 'react-ui/assets/icons/cross.svg'
import Heading from 'react-ui/components/Heading'
import { rootElement } from 'shared/services/elements'
import { withoutFelaProps } from 'shared/services/fela'
import { Button } from 'care-ui'

import { styleRules } from './Modal.style'

import type { FelaPropsType, FelaStylesType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  children: Node,
  componentId?: string,
  contentLabel: string,
  contentStyle?: FelaStylesType,
  isOpen: boolean,
  onRequestClose?: () => void,
  overlayStyle?: FelaStylesType,
  screenReaderLabel: string,
  showClose?: boolean,
  showCross?: boolean,
  shrinkwrap?: boolean,
}

const Modal = (props: PropsType) => {
  const {
    isOpen = false,
    showClose = true,
    showCross = true,
    onRequestClose,
    screenReaderLabel,
    contentLabel,
    children,
    styles = {},
    contentStyle = {},
    overlayStyle = {},
    componentId,
    ...other
  } = props

  const renderedClose = (
    <div>
      <Button onClick={onRequestClose} variant="text" dataTestId="ModalClose">
        Close
      </Button>
    </div>
  )

  return (
    <ReactModal
      {...withoutFelaProps(other)}
      className={styles.Modal}
      overlayClassName={styles.overlay}
      style={{ content: contentStyle, overlay: overlayStyle }}
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={screenReaderLabel || contentLabel}
      appElement={rootElement()}
      data={{
        'component-id': `${componentId || 'Modal'}_${
          isOpen ? 'isOpen' : 'isClosed'
        }`,
      }}
    >
      {contentLabel && (
        <Heading level={3} inherit>
          {contentLabel}
        </Heading>
      )}
      {children}
      {showCross && (
        <div className={styles.closeButton}>
          <Button
            variant="text"
            onClick={onRequestClose}
            data-component-id="Modal_close"
            dataTestId="ModalClose"
            ariaLabel="modal close"
            icon={Cross}
          />
        </div>
      )}
      {showClose ? renderedClose : null}
    </ReactModal>
  )
}

export default connect(styleRules)(Modal)
