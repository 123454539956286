// @flow

import { graphql } from 'react-relay'

export const query = {
  escalations: graphql`
    fragment MyEscalationsTable_escalations on Escalation @relay(plural: true) {
      created_at
      label
    }
  `,
}
