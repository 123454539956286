// @flow

import * as React from 'react'
import { useTranslation } from 'react-i18next'
import { isArray } from 'lodash/fp'

import { Error } from 'shared/ui/Typography/Icons'

import s from './Error.scss'

type PropsType = {
  errors: Array<*>,
}

export const ErrorListTemplate = (props: PropsType) => {
  const { errors } = props
  const { t: translation } = useTranslation('assessment')
  const size = errors.length
  const answerText = translation('answer', {
    count: size,
  })
  const needText = translation('need', {
    count: size,
  })
  const message = isArray(errors)
    ? `${size} ${answerText} ${needText} ${translation('completing')}`
    : errors
  return (
    <div className={s.errors}>
      <div className={s.icon}>
        <Error size="l" color="danger" />
      </div>
      <div className={s.content}>{message}</div>
    </div>
  )
}
