// @flow

import { type ThemeType } from 'care-ui/atoms'

export const buttonContainerRules = ({ theme }: { theme: ThemeType }) => ({
  Section: {
    marginTop: theme.spacing(1.5),
    display: 'flex',
    justifyContent: 'flex-end',
  },
})
