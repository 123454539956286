// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const TickIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 14 14"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M5.01732405,11.229256 L12.1667002,0.470264845 C12.4723631,0.0102772093 13.0930455,-0.114827692 13.5530331,0.190835226 C14.0130208,0.496498145 14.1381257,1.11718055 13.8324628,1.57716819 L5.86510785,13.5671235 C5.70397735,13.8096065 5.45530665,13.9590295 5.18878268,14.0013464 C4.81026462,14.0688869 4.41019692,13.9137249 4.18219773,13.5739713 L0.144530323,7.55723018 C-0.163219409,7.09863608 -0.0409358878,6.47739166 0.417658211,6.16964193 C0.87625231,5.8618922 1.49749673,5.98417572 1.80524646,6.44276982 L5.01732405,11.229256 Z"
            id="tick-path-1"
          />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="icon-/-14x14-/-tick-/-2px">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#tick-path-1" />
            </mask>
            <use id="Line-2" fillRule="nonzero" xlinkHref="#tick-path-1" />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default TickIcon
