// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  ClinicianNoteCreateMutationResponse,
  ClinicianNoteCreateMutationVariables,
} from './__generated__/ClinicianNoteCreateMutation.graphql'

const mutation = graphql`
  mutation ClinicianNoteCreateMutation($input: ClinicianNoteCreateInput!) {
    clinicianNoteCreate(input: $input) {
      clinician_note {
        id
        about {
          clinician_notes {
            totalCount
            nodes {
              id
              created_at
              text
              author {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: ClinicianNoteCreateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: ClinicianNoteCreateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
