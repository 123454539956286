// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const AlertIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        height="100%"
        width="100%"
        viewBox="0 0 14.4 8.2"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            id="up-path-1"
            d="M5.4,13.8V5.4c0-0.6,0.4-1,1-1h8.5c0.6,0,1,0.4,1,1c-0.1,0.4-0.2,0.6-0.4,0.8l-8.4,8.4,c-0.4,0.4-1,0.4-1.4,0C5.5,14.3,5.4,14.1,5.4,13.8z"
          />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g
            id="icon-/-28x28-/-up"
            transform="translate(7.022540, 1.822540) rotate(45.000000) translate(-7.022540, -6.222540)"
          >
            <use id="Stroke-2991" fillRule="nonzero" xlinkHref="#up-path-1" />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default AlertIcon
