// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import FancyContainer from './FancyContainer'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = {
  children: Node,
} & FelaPropsType

const styleRules = () => ({
  fancyContainerOverride: {
    padding: 0,
    justifyContent: 'stretch',
    cursor: 'auto',
  },
})

const UtilContainer = (props: PropsType) => {
  const { rules, children } = props

  return (
    <FancyContainer
      componentId="UtilContainer"
      extend={{ FancyContainer: rules.fancyContainerOverride }}
    >
      {children}
    </FancyContainer>
  )
}

export default connect(styleRules)(UtilContainer)
