/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AnswerSets_answer_sets$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$ref: FragmentReference;
declare export opaque type UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$fragmentType: UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$ref;
export type UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set = {|
  +id: string,
  +submitted_answer_sets: $ReadOnlyArray<{|
    +$fragmentRefs: AnswerSets_answer_sets$ref
  |}>,
  +questionnaires_question_set: {|
    +question_set: {|
      +label: string
    |},
    +questionnaire: {|
      +label: string
    |},
  |},
  +$refType: UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$ref,
|};
export type UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$data = UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set;
export type UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$key = {
  +$data?: UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$data,
  +$fragmentRefs: UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnairesQuestionSetDetailsPage_user_questionnaires_question_set",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerSet",
      "kind": "LinkedField",
      "name": "submitted_answer_sets",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AnswerSets_answer_sets"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "questionnaires_question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionSet",
          "kind": "LinkedField",
          "name": "question_set",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionnairesQuestionSet",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '7b0e0030e83e84ee36428d22dfb86aa5';

module.exports = node;
