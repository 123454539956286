// @flow

import { commitMutation, graphql } from 'react-relay'
import { curry } from 'lodash/fp'

import type {
  ReinviteGenericMutationResponse,
  ReinviteGenericMutationVariables,
} from './__generated__/ReinviteGenericMutation.graphql'

const mutation = graphql`
  mutation ReinviteGenericMutation($input: RoleReinviteInput!) {
    roleReinvite(input: $input) {
      role {
        ... on RoleInterface {
          id
          ...EmployeesTable_role
          ...IndividualsTable_individual
        }
      }
    }
  }
`

export const temporarilySetUserInvitationDelta = curry(
  (roleId: string, store): void => {
    store
      .get(roleId)
      .getLinkedRecord('user')
      .setValue('Pending', 'invitation_delta')
  },
)

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: ReinviteGenericMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: ReinviteGenericMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
    optimisticUpdater: temporarilySetUserInvitationDelta(
      variables.input.roleId,
    ),
  })
}
