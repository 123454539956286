// @flow

export const wrapperStyle = () => ({
  overflow: 'hidden',
  position: 'relative',
})

export const informationStyle = () => ({
  maxWidth: '850px',
  margin: '0 auto',
})
