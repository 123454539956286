// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { includes, map, reject, uniqueId } from 'lodash/fp'

import { AccordionItem } from 'react-ui/components/Accordion'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { shortDateFormat } from 'platform_web/services/dateTime'
import { Button, Heading, Text } from 'care-ui'

import { AssessmentRequestsQuestionnaireLoader } from '../AssessmentRequestsQuestionnaire'

import {
  accordionItemStyle,
  buttonStyle,
  headerStyle,
  requestHeadingStyle,
  wrapperStyle,
} from './AssessmentRequestsComponent.style'
import { assessmentRequestsComponentAssessmentRequest } from './query/AssessmentRequestsComponent'

import type { AssessmentRequestsComponent_assessment_request } from './query/__generated__/AssessmentRequestsComponent_assessment_request.graphql'

type PropsType = {
  assessment_request: AssessmentRequestsComponent_assessment_request,
}

const AssessmentRequestsComponentBase = (props: PropsType) => {
  const { assessment_request } = props
  const {
    id: assessmentRequestId,
    sent_at,
    requestee,
    questionnaires,
    questionnaire_answer_sets,
  } = assessment_request
  const [isOpen, setOpen] = useState(true)
  const { css } = useFela()
  const { current_role: { role_type } } = useCurrentUser()

  const onToggleOpen = () => {
    setOpen(!isOpen)
  }

  const isIndividual = role_type === 'INDIVIDUAL'

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    assessmentRequestId,
  })

  const answeredQuestionnairesIds = map('questionnaire.id')(
    questionnaire_answer_sets,
  )
  const unansweredQuestionnaires = reject(({ id }) =>
    includes(id)(answeredQuestionnairesIds),
  )(questionnaires)

  const { t: translation } = useTranslation('assessment')

  const renderAnsweredQuestionnaires = () => {
    if (!questionnaire_answer_sets.length) return null

    return questionnaire_answer_sets.map(qas => {
      const { label, id: questionnaireId } = qas.questionnaire
      const childAnswerSets = qas.answer_sets
      const requestIds = {
        assessmentRequestId,
        questionnaireId,
      }

      return (
        <AssessmentRequestsQuestionnaireLoader
          label={label}
          answers={qas.answers}
          rating={qas.rating}
          requestIds={requestIds}
          completedAt={qas.completed_at}
          answeredBy={requestee?.legal_name}
          key={uniqueId(label)}
        >
          {childAnswerSets &&
            childAnswerSets.length > 1 &&
            childAnswerSets.map(({ question_set, answers }) => (
              <AssessmentRequestsQuestionnaireLoader
                label={question_set.label}
                answers={answers}
                key={uniqueId(question_set.label)}
                completedAt={qas.completed_at}
                answeredBy={requestee?.legal_name}
                rating={null}
                isChild
              />
            ))}
        </AssessmentRequestsQuestionnaireLoader>
      )
    })
  }

  const renderUnansweredQuestionnaires = () => {
    return unansweredQuestionnaires.map(questionnaire => {
      const { label, id: questionnaireId } = questionnaire
      const requestIds = {
        assessmentRequestId,
        questionnaireId,
      }
      return (
        <AssessmentRequestsQuestionnaireLoader
          label={label}
          key={uniqueId(label)}
          requestIds={requestIds}
          rating={null}
          answers={null}
          completedAt={null}
          answeredBy={requestee?.legal_name}
        />
      )
    })
  }

  const requestHeading = (
    <Text as="div" size="lg" color="title" extend={requestHeadingStyle}>
      <div>
        {translation('requested_on')} {shortDateFormat(sent_at)}
      </div>

      {isIndividual &&
        !questionnaire_answer_sets.length && (
          <div className={css(buttonStyle)}>
            <Button onClick={start}>{translation('complete_all')}</Button>
          </div>
        )}
    </Text>
  )

  return (
    <div className={css(wrapperStyle)}>
      <AccordionItem
        withArrow
        extend={({ theme }) => accordionItemStyle(theme, isOpen)}
        isOpen={isOpen}
        onToggleOpen={onToggleOpen}
        heading={requestHeading}
      >
        <div className={css(headerStyle)} role="rowgroup">
          <Heading level={4}>{translation('question_set')}</Heading>
          <Heading level={4}>{translation('rating')}</Heading>
          <Heading level={4}>{translation('answered_on')}</Heading>
          <Heading level={4}>{translation('status')}</Heading>
          <Heading level={4}>{translation('answered_by')}</Heading>
        </div>

        {renderAnsweredQuestionnaires()}

        {renderUnansweredQuestionnaires()}
      </AccordionItem>
    </div>
  )
}

export const AssessmentRequestsComponentLoader = createFragmentContainer(
  AssessmentRequestsComponentBase,
  assessmentRequestsComponentAssessmentRequest,
)
