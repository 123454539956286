// @flow

import { graphql } from 'react-relay'

export const query = {
  user_questionnaire: graphql`
    fragment UserQuestionnaire_user_questionnaire on UserQuestionnaire {
      label
      description
      progress {
        decimal
        done
        total
        step
      }
      first_user_questionnaires_question_set {
        finished
        questionnaires_question_set {
          id
        }
      }
      user {
        id
      }
      questionnaire {
        id
      }
      ensure_questionnaire {
        id
      }
    }
  `,
  role: graphql`
    fragment UserQuestionnaire_role on Role {
      ... on RoleInterface {
        id
        role_type
      }
    }
  `,
}
