// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { createFragmentContainer, graphql } from 'react-relay'

import { UtilContainer } from 'react-ui/components/FancyHealthCard'
import { H4 } from 'react-ui/components/Heading'
import Can from 'react-ui/utils/Can'

import AssessmentRequestsTask from './AssessmentRequestsTask'
import ClinicianInputTask from './ClinicianInputTask'
import DownloadDataTask from './DownloadDataTask'
import InitialQuestionnaireUpdateTask from './InitialQuestionnaireUpdateTask'
import StaffDownloadDataTask from './StaffDownloadDataTask'
import SummaryQuestionnaireUpdateTask from './SummaryQuestionnaireUpdateTask'
import SupportPersonTask from './SupportPersonTask'

import { type IndividualTasksCard_individual } from './__generated__/IndividualTasksCard_individual.graphql'

type PropsType = {
  individual: IndividualTasksCard_individual,
}

const headingStylesOverride = theme => ({
  display: 'flex',
  alignItems: 'center',
  marginTop: 0,
  padding: '20px',
  borderBottom: `1px solid ${theme.component.borderColor}`,
  height: '20%',
  flexShrink: 0,
})

const tasksContainerStyle = ({ theme }) => ({
  display: 'flex',
  height: '80%',
  overflowY: 'auto',
  flexDirection: 'column',
  '& > div:not(:last-child)': {
    borderBottom: `1px solid ${theme.component.borderColor}`,
  },
})

export const IndividualTasksCard = (props: PropsType) => {
  const { individual } = props
  const { t: translation } = useTranslation('dashboard')
  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])

  const { theme, css } = useFela()

  return (
    <UtilContainer>
      <H4 extend={headingStylesOverride(theme)}>
        {translation('common_tasks')}
      </H4>
      <div className={css(tasksContainerStyle)}>
        {isIndividual && (
          <>
            <SummaryQuestionnaireUpdateTask individual={individual} />
            <InitialQuestionnaireUpdateTask individual={individual} />
            <SupportPersonTask individual={individual} />
            <AssessmentRequestsTask individual={null} />
            <DownloadDataTask />
          </>
        )}
        <Can>
          {({ CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT: canView }) => {
            if (!canView) return null

            return (
              <>
                <StaffDownloadDataTask individual={individual} />
                <ClinicianInputTask individual={individual} />
                <AssessmentRequestsTask individual={individual} />
              </>
            )
          }}
        </Can>
      </div>
    </UtilContainer>
  )
}

export const IndividualTasksCardLoader = createFragmentContainer(
  IndividualTasksCard,
  {
    individual: graphql`
      fragment IndividualTasksCard_individual on IndividualRole {
        ...AssessmentRequestsTask_individual
        ...SupportPersonTask_individual
        ...StaffDownloadDataTask_individual
        ...ClinicianInputTask_individual
        ...SummaryQuestionnaireUpdateTask_individual
        ...InitialQuestionnaireUpdateTask_individual
      }
    `,
  },
)
