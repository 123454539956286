/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type TenantAdminPage_tenant$ref: FragmentReference;
declare export opaque type TenantAdminPage_tenant$fragmentType: TenantAdminPage_tenant$ref;
export type TenantAdminPage_tenant = {|
  +id: string,
  +contact_email: string,
  +contact_phone: string,
  +escalation_emails: ?string,
  +bcc_email: ?string,
  +name: string,
  +notification_after: ?number,
  +$refType: TenantAdminPage_tenant$ref,
|};
export type TenantAdminPage_tenant$data = TenantAdminPage_tenant;
export type TenantAdminPage_tenant$key = {
  +$data?: TenantAdminPage_tenant$data,
  +$fragmentRefs: TenantAdminPage_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "TenantAdminPage_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contact_email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contact_phone",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "escalation_emails",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "bcc_email",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "notification_after",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'f9c7b0c80fcd40a0cd30eeb8ba41e49a';

module.exports = node;
