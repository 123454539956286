// @flow

import { type ThemeType } from 'care-ui/atoms'

type FlexItemStyleProps = {
  alignSelf: 'center' | 'end' | 'start',
  flex: number,
  theme: ThemeType,
}

export const flexItem = (props: FlexItemStyleProps) => {
  const { flex, alignSelf } = props

  return {
    flex,
    alignSelf,
  }
}
