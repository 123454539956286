/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ReportSettingsTenantFetch_settings$ref = any;
export type REPORT_DATE_PRESET = "custom" | "last_seven_days" | "last_thirty_days" | "last_three_days" | "%future added value";
export type ReportsAccessibilityBreakdownDimensionEnum = "age_group" | "gender" | "has_disability" | "indigenous_status" | "language" | "%future added value";
export type ReportsSatisfactionIndividualSignInDimensionEnum = "age_group" | "gender" | "has_disability" | "indigenous_status" | "language" | "%future added value";
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ServiceMetricsHomePage_viewer$ref: FragmentReference;
declare export opaque type ServiceMetricsHomePage_viewer$fragmentType: ServiceMetricsHomePage_viewer$ref;
export type ServiceMetricsHomePage_viewer = {|
  +reports: {|
    +id: string,
    +available_tenants: $ReadOnlyArray<{|
      +id: string,
      +name: string,
    |}>,
    +satisfaction_individual_sign_in_dimensions: $ReadOnlyArray<{|
      +key: ReportsSatisfactionIndividualSignInDimensionEnum,
      +value: string,
    |}>,
    +accessibility_breakdown_dimensions: $ReadOnlyArray<{|
      +key: ReportsAccessibilityBreakdownDimensionEnum,
      +value: string,
    |}>,
    +settings: ?{|
      +from: any,
      +to: any,
      +tenants: $ReadOnlyArray<string>,
      +clinicians: $ReadOnlyArray<string>,
      +selectedDateType: ?REPORT_DATE_PRESET,
      +$fragmentRefs: ReportSettingsTenantFetch_settings$ref,
    |},
  |},
  +currentUser: ?{|
    +tenant: ?{|
      +id: string,
      +multi_roles_enabled: boolean,
    |},
    +roles: ?{|
      +totalCount: number,
      +nodes: ?$ReadOnlyArray<?{|
        +tenant_id?: ?string,
        +id?: string,
        +role_type?: RoleTypes,
      |}>,
    |},
  |},
  +$refType: ServiceMetricsHomePage_viewer$ref,
|};
export type ServiceMetricsHomePage_viewer$data = ServiceMetricsHomePage_viewer;
export type ServiceMetricsHomePage_viewer$key = {
  +$data?: ServiceMetricsHomePage_viewer$data,
  +$fragmentRefs: ServiceMetricsHomePage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ServiceMetricsHomePage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Reports",
      "kind": "LinkedField",
      "name": "reports",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "available_tenants",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SatisfactionIndividualSignInDimension",
          "kind": "LinkedField",
          "name": "satisfaction_individual_sign_in_dimensions",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "AccessibilityBreakdownDimension",
          "kind": "LinkedField",
          "name": "accessibility_breakdown_dimensions",
          "plural": true,
          "selections": (v1/*: any*/),
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "ReportSettings",
              "kind": "LinkedField",
              "name": "settings",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "from",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "to",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "tenants",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "clinicians",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "selectedDateType",
                  "storageKey": null
                },
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "ReportSettingsTenantFetch_settings"
                }
              ],
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "multi_roles_enabled",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "RoleConnection",
          "kind": "LinkedField",
          "name": "roles",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "totalCount",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "nodes",
              "plural": true,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "tenant_id",
                      "storageKey": null
                    },
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "role_type",
                      "storageKey": null
                    }
                  ],
                  "type": "RoleInterface",
                  "abstractKey": "__isRoleInterface"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d375f8114e57b5c20888f921555cf98';

module.exports = node;
