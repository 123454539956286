/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AnswerTable_answers$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AnswerSet_answer_set$ref: FragmentReference;
declare export opaque type AnswerSet_answer_set$fragmentType: AnswerSet_answer_set$ref;
export type AnswerSet_answer_set = {|
  +answers: $ReadOnlyArray<{|
    +$fragmentRefs: AnswerTable_answers$ref
  |}>,
  +id: string,
  +submitted_at: ?any,
  +$refType: AnswerSet_answer_set$ref,
|};
export type AnswerSet_answer_set$data = AnswerSet_answer_set;
export type AnswerSet_answer_set$key = {
  +$data?: AnswerSet_answer_set$data,
  +$fragmentRefs: AnswerSet_answer_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AnswerSet_answer_set",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "answers",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AnswerTable_answers"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "submitted_at",
      "storageKey": null
    }
  ],
  "type": "AnswerSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '304310e33cdaba2a9956b8bc3012fcf7';

module.exports = node;
