// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'
import omit from 'lodash/fp/omit'

import { felaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

export type InputProps = {
  as?: string,
  block?: boolean,
  checked?: boolean,
  children?: React.Node,
  error?: boolean,
  prefix?: React.Node,
  suffix?: React.Node,
  value?: string,
}

const styleRules = ({ block, error, prefix, suffix, theme }) => {
  const addonBase = {
    alignItems: 'center',
    display: 'flex',
    height: '20px',
    justifyContent: 'center',
    overflow: 'hidden',
    position: 'absolute',
    top: '12px',
    width: '20px',
  }

  const blockProps = block
    ? { display: 'block', width: '100%' }
    : { display: 'inline-block', width: 'auto' }

  return {
    Input: {
      className: classNames('Input', {
        '--block': block,
        '--error': error,
      }).replace(/\s/g, ''),
      color: error ? theme.Input.error.text : theme.Input.default.text,
      position: 'relative',
      ...blockProps,
    },

    input: {
      borderWidth: '2px',
      borderStyle: 'solid',
      borderRadius: '5px',
      boxSizing: 'border-box',
      className: classNames('Input__input', {
        '--error': error,
        '--block': block,
      }).replace(/\s/g, ''),
      color: 'inherit',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '31px',
      minHeight: theme.Input.baseHeight,
      paddingBottom: '0',
      paddingLeft: prefix ? '36px' : '14px',
      paddingRight: suffix ? '36px' : '14px',
      paddingTop: '0',
      transition: 'all .25s ease-in-out',
      ...(() => {
        const { accent, base } = {
          ...theme.Input.default,
          ...(error ? theme.Input.error : {}),
        }
        return {
          backgroundColor: base,
          borderColor: accent,
        }
      })(),

      ...blockProps,

      ...((): any =>
        [
          '::-webkit-input-placeholder',
          '::-moz-placeholder',
          ':-ms-input-placeholder',
        ].reduce(
          (memo, selector) => ({
            ...(memo: any),
            [(selector: string)]: {
              color: theme.Input.placeholder,
            },
          }),
          {},
        ))(),

      ':disabled': {
        backgroundColor: theme.Input.disabled.base,
        borderColor: theme.Input.disabled.accent,
      },
      ':focus': {
        borderColor: theme.Input.focus.accent,
        outline: 'none',
      },
      ...theme.care.typography.desktop.bodyLg,
    },

    prefix: {
      ...addonBase,
      className: 'Input__addon-prefix',
      left: '12px',
    },
    suffix: {
      ...addonBase,
      className: 'Input__addon-suffix',
      right: '12px',
    },
  }
}

const Input = ({
  as: Component = 'input',
  children,
  prefix,
  suffix,
  styles,
  ...props
}: InputProps & FelaPropsType) => (
  <span className={styles.Input}>
    <Component
      className={styles.input}
      {...omit(['block', 'error', 'prefix', 'suffix', ...felaProps], props)}
    />
    {children}

    {!!prefix && <span className={styles.prefix}>{prefix}</span>}
    {!!suffix && <span className={styles.suffix}>{suffix}</span>}
  </span>
)

export default connect(styleRules)(Input)
