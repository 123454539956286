// @flow

import React, { type Node } from 'react'
import cx from 'classnames'

import s from './Card.scss'

type PropsType = {
  children: Node,
  className?: string,
}

const Card = ({ children, className }: PropsType) => (
  <section className={cx(s.card, className)}>{children}</section>
)
export default Card
