// @flow

import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { withRouter } from 'found'

import { AnswerSetsLoader } from 'components/AnswerSets'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import { Button } from 'care-ui'

import { query } from '../../queries/UserQuestionSetDetailsPage'

import type { UserQuestionSetDetailsPage_user_question_set } from '../../queries/__generated__/UserQuestionSetDetailsPage_user_question_set.graphql'
import type { UserQuestionSetDetailsPage_user } from '../../queries/__generated__/UserQuestionSetDetailsPage_user.graphql'

type PropsType = {
  +relay: Object,
  router?: Object,
  user: UserQuestionSetDetailsPage_user,
  +user_question_set: UserQuestionSetDetailsPage_user_question_set,
}

const UserQuestionSetDetailsPage = withRouter((props: PropsType) => {
  const {
    user_question_set: { submitted_answer_sets, question_set },
    user: { id },
  } = props

  // Localization
  const { t: translation } = useTranslation('graphs')

  return (
    <Fragment>
      <Section color="white">
        <Container>
          <Button
            variant="text"
            to={{
              name: 'user_dashboard',
              params: {
                id,
              },
            }}
          >
            {translation('back_to_dashboard')}
          </Button>
          <Heading level={3}>{question_set.label}</Heading>
        </Container>
      </Section>

      <Section color="default">
        <Container>
          <AnswerSetsLoader answer_sets={submitted_answer_sets} />
        </Container>
      </Section>
    </Fragment>
  )
})

export const UserQuestionSetDetailsPageLoader = createFragmentContainer(
  UserQuestionSetDetailsPage,
  query,
)
