// @flow

import React from 'react'

import HelpNowPage from 'pages/HelpNowPage'

const PublicHelpNowPage = () => {
  const providers = window.GLOBALS?.publicHelpNowProviders
  return <HelpNowPage providers={providers} />
}

export default PublicHelpNowPage
