// @flow

import React, { type Node, Fragment } from 'react'
import { graphql } from 'react-relay'

import ClinicianViewingIndividualBanner from 'components/ClinicianViewingIndividualBanner'
import WithAuth from 'components/WithAuth'

import type { RootUsersPage_QueryResponse } from './__generated__/RootUsersPage_Query.graphql'

type PropsType = {
  children: Node,
  ...RootUsersPage_QueryResponse,
}

const RootUsersPage = (props: PropsType) => {
  const { children, user, viewer: { currentUser } } = props

  if (!user || !currentUser) return null

  return (
    <Fragment>
      <WithAuth
        requiredPolicies={['CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT']}
      >
        <ClinicianViewingIndividualBanner
          user={user}
          clinician={currentUser}
        />
      </WithAuth>
      {children}
    </Fragment>
  )
}

export const RootUsersPageQuery = graphql`
  query RootUsersPage_Query($id: ID!) {
    user(id: $id, source: "root") {
      ...ClinicianViewingIndividualBanner_user
    }
    viewer {
      currentUser {
        ...ClinicianViewingIndividualBanner_clinician
        current_role {
          ... on RoleInterface {
            id
            role_type
          }
        }
      }
    }
  }
`

export const RootUsersPageLoader = RootUsersPage
