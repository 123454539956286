// @flow

import React, { useEffect } from 'react'
import { useLazyLoadQuery, usePaginationFragment } from 'react-relay'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'

import type { UseMultiSelectReturnTypes } from '../../hooks/useMultiSelect'
import StandardIndividualsTableComponent from '../components/StandardIndividualsTableComponent'
import type { UseIndividualTableReturnType } from '../hooks/useIndividualTable'
import {
  individualsTableFragments,
  individualsTablePaginatedQuery,
} from '../query/IndividualsTable'
import ScoresIndividualsTableComponent from '../ScoresIndividualsTableComponent'

import type { ReadOnlyArrayElement } from 'services/flow'
import type {
  ClinicianIndividualAssignmentStatusEnum,
  IndividualsTable_user,
} from '../query/__generated__/IndividualsTable_user.graphql'

type PropsType = {
  multiSelectPayload: UseMultiSelectReturnTypes,
  useIndividualTablePayload: UseIndividualTableReturnType,
}

type IndividualsConnectionType = $PropertyType<
  IndividualsTable_user,
  'individuals',
>

type ClinicianAssignmentsType = $NonMaybeType<
  $PropertyType<IndividualsConnectionType, 'edges'>,
>

export type IndividualsPropsType<T> = {
  data: ClinicianAssignmentsType,
  important_formulas: $ReadOnlyArray<any>,
  queryState: T,
}

export type IndividualType = $PropertyType<
  $NonMaybeType<ReadOnlyArrayElement<ClinicianAssignmentsType>>,
  'node',
>

export type ClinicianIndividualAssignmentStatusEnumType = ClinicianIndividualAssignmentStatusEnum

const IndividualsListTable = (props: PropsType) => {
  const { useIndividualTablePayload, multiSelectPayload } = props

  const { count, tableCategory, queryVariables } = useIndividualTablePayload

  const {
    allSelected,
    deselectedRoleIds,
    setMultiSelectState,
  } = multiSelectPayload

  const queryData = useLazyLoadQuery(
    individualsTablePaginatedQuery,
    queryVariables,
  )

  const { data, loadNext, hasNext } = usePaginationFragment(
    individualsTableFragments.user,
    queryData?.viewer?.currentUser,
  )

  // MUTLI_SELECT: If all selected, and load more is clicked, this adds newly loaded data to selected items
  useEffect(
    () => {
      setMultiSelectState({ totalCount: data?.individuals_count })
      if (!allSelected) return

      const individualData = (data?.individuals?.edges || []).filter(Boolean)
      const ids = individualData.map(item => item?.node?.id || item?.id)

      const filteredIds = ids.filter(id => !deselectedRoleIds.includes(id))

      setMultiSelectState({ selectedRoleIds: filteredIds })
    },
    [data],
  )

  return (
    <>
      {/* Table Component */}
      {tableCategory === 'scores' && (
        <ScoresIndividualsTableComponent
          user={data}
          queryVariables={queryVariables}
          useIndividualTablePayload={useIndividualTablePayload}
          multiSelectPayload={multiSelectPayload}
        />
      )}

      {tableCategory === 'standard' && (
        <StandardIndividualsTableComponent
          user={data}
          queryVariables={queryVariables}
          useIndividualTablePayload={useIndividualTablePayload}
          multiSelectPayload={multiSelectPayload}
        />
      )}

      {/* Pagination Loader */}
      {hasNext && <LoadMore handleClick={() => loadNext(count)} />}
    </>
  )
}

export default IndividualsListTable
