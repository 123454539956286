// @flow

import difference from 'lodash/difference'
import { get } from 'lodash/fp'

export type DataArrayType = $ReadOnlyArray<Object>
export type DataObjectType = { +[string]: DataArrayType }
export type DataType = DataArrayType | DataObjectType

type RowPredicate = (row: Object) => boolean

export type ObjectFieldType = {
  formatString?: (input: any) => string,
  path: string,
}
export type FieldType = string | ObjectFieldType
export type FieldsType = $ReadOnlyArray<FieldType>

type SearchType = {
  exclude?: Array<string>,
  include?: Array<string>,
}

const fieldValue = (row, field: FieldType): string => {
  if (typeof field === 'string') return get(field)(row)

  const { path, formatString }: ObjectFieldType = field
  const input = get(path)(row)
  return formatString ? formatString(input) : input
}

/**
 * filterArrayBySearchTerm
 * Helper function used by filterBySearchTerm.
 *
 * @param {array} rows
 * @param {string} searchText
 * @param {object} options contains include/exclude - arrays of strings
 *
 * @returns: filtered array
 */
const filterArrayBySearchTerm = (
  rows: DataArrayType,
  searchText: string = '',
  { include, exclude }: SearchType = {},
) => {
  if (searchText) {
    let searchColumns: FieldsType = include || (rows[0] && Object.keys(rows[0]))

    searchColumns = difference(searchColumns, exclude)

    const containsSearchText: RowPredicate = (row: Object): boolean =>
      searchColumns.some((colName: FieldType): boolean => {
        const value = fieldValue(row, colName)
        if (!value) {
          return false
        }
        const valueCheck = value
          .toString()
          .toLowerCase()
          .includes(searchText.trim().toLowerCase())

        // Allow to search for given boolean columns with value of true
        const searchBooleanColumn = include
          ? typeof value === 'boolean' && value === true
          : false

        const matched = valueCheck || searchBooleanColumn

        return matched
      })
    return (rows.filter(containsSearchText): DataArrayType)
  }
  return rows
}

/**
 * filterBySearchTerm
 * Given data, searchText and options will return filtered data.
 *
 * @param {array | object} data
 * @param {string} searchText
 * @param {object} options contains include/exclude - arrays of strings
 *
 * Input data can be either:
 * an array of Objects  - [{}, {}, {}] or
 * an Object where keys are arrays of Objects - { key1: [{}, {}], key2: [{}, {}]}
 *
 * @returns: filtered array | filtered Object
 */

export const filterBySearchTerm = (data: DataType, ...args: any) => {
  if (Array.isArray(data)) {
    return filterArrayBySearchTerm(data, ...args)
  }

  const dataObject: DataObjectType = data

  return Object.keys(data).reduce((accum, key) => {
    const value = dataObject[key]
    accum[key] = Array.isArray(value)
      ? filterArrayBySearchTerm(value, ...args)
      : value
    return accum
  }, {})
}
