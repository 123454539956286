// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import Can from 'react-ui/utils/Can'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'

import IndividualTasksItem from './IndividualTasksItem'

import type { SummaryQuestionnaireUpdateTask_individual } from './__generated__/SummaryQuestionnaireUpdateTask_individual.graphql'

type PropsType = {
  individual: SummaryQuestionnaireUpdateTask_individual,
}

const SummaryQuestionnaireUpdateTaskComponent = (props: PropsType) => {
  const { individual: { tenant } } = props

  const { t: translation } = useTranslation('dashboard')
  const questionnaireId = tenant?.summary_questionnaire?.id || ''

  const { start } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId,
  })

  if (!tenant || !tenant.summary_questionnaire) return null

  return (
    <Can>
      {({ CAN_CREATE_USER_QUESTIONNAIRE }) => {
        return CAN_CREATE_USER_QUESTIONNAIRE ? (
          <IndividualTasksItem
            header={translation('retake_summary_questionnaire')}
            onClick={start}
          >
            {translation(
              'by_keeping_up_to_date_you_can_track_your_health_and_well_being_over_time',
            )}
          </IndividualTasksItem>
        ) : null
      }}
    </Can>
  )
}

export default createFragmentContainer(
  SummaryQuestionnaireUpdateTaskComponent,
  {
    individual: graphql`
      fragment SummaryQuestionnaireUpdateTask_individual on IndividualRole {
        tenant {
          summary_questionnaire {
            id
          }
        }
      }
    `,
  },
)
