// @flow

import React from 'react'
import { useFela } from 'react-fela'

import {
  errorTextStyle,
  inputStyle,
  labelStyle,
  sizeStyle,
  wrapperStyle,
} from './Input.style'

type PropsType = {
  error?: string,
  fieldDataTestId?: string,
  id?: string,
  label?: string,
  labelDataTestId?: string,
  name: string,
  onBlur?: () => void,
  onChange: () => void,
  placeholder: string,
  size?: 'lg' | 'md',
  type?: 'text' | 'number' | 'email' | 'password',
  value: string,
}

const Input = (props: PropsType) => {
  const {
    id,
    name,
    label,
    value,
    placeholder,
    type,
    error,
    onChange,
    onBlur,
    fieldDataTestId,
    labelDataTestId,
  } = props

  const { css } = useFela(props)

  return (
    <div className={css(wrapperStyle)}>
      {label && (
        <label
          htmlFor={name}
          className={css(labelStyle)}
          data-testid={labelDataTestId}
        >
          {label}
        </label>
      )}

      <input
        type={type}
        onChange={onChange}
        onBlur={onBlur}
        className={css(sizeStyle, inputStyle)}
        name={name}
        id={id}
        value={value}
        placeholder={placeholder}
        data-testid={fieldDataTestId}
      />

      {error && <div className={css(errorTextStyle)}>{error}</div>}
    </div>
  )
}

export default Input
