// @flow

import React, { type Node } from 'react'
import { RendererProvider, ThemeProvider } from 'react-fela'

import theme from 'react-ui/themes/synergy-base-theme'
import { defaultRenderer } from 'shared/plugins/default-fela-renderer'
import newTheme from 'care-ui/atoms'

type PropsType = {
  children: Node,
}

const synergyBaseTheme = {
  care: newTheme,
  ...theme,
}

export default function({ children }: PropsType) {
  return (
    <RendererProvider renderer={defaultRenderer()}>
      <ThemeProvider theme={synergyBaseTheme}>{children}</ThemeProvider>
    </RendererProvider>
  )
}
