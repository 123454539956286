// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'

import { ClinicianNotesListViewQueryLoader } from 'react-ui/components/ClinicianNote'
import { Container } from 'react-ui/components/Grid'
import ThankYouMessage from 'react-ui/components/ThankYouMessage'
import { commit as commitClinicianNoteCreate } from 'mutations/ClinicianNote/ClinicianNoteCreate'
import {
  Button,
  FlexContainer,
  FlexItem,
  ScrollableBox,
  TextArea,
} from 'care-ui'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  userId: string,
}

const styleRules = ({ theme }) => ({
  wrapper: {
    textAlign: 'center',
    margin: theme.Grid.gutter,
    maxWidth: '500px',
    width: '100%',
  },
  flexWrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-center',
    paddingTop: theme.Grid.gutter,
  },
  buttonWrapper: {
    marginTop: '2rem',
  },
  clinicianNote: {
    borderRadius: '5px',
    padding: theme.Grid.gutter,
    fontSize: theme.Font.fontSize,
  },
})

const ClinicianNoteForm = ({ userId }: PropsType) => {
  const [clinicianNote, setClinicianNote] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const environment = useRelayEnvironment()

  const handleSubmit = () => {
    setSubmitting(true)

    commitClinicianNoteCreate({
      onCompleted: () => {
        setSubmitted(true)
        setSubmitting(false)
      },
      environment,
      variables: {
        input: {
          clinician_note: {
            about_id: userId,
            text: clinicianNote,
          },
        },
      },
    })
  }

  return (
    <Container>
      {submitted && <ThankYouMessage />}

      {!submitted && (
        <FlexContainer direction="column" gap="sm">
          <TextArea
            rows={10}
            placeholder="Enter text here"
            onChange={({ target }) => setClinicianNote(target.value)}
            value={clinicianNote}
          />

          <FlexItem alignSelf="center">
            <Button
              disabled={submitting || !clinicianNote}
              onClick={handleSubmit}
              variant="primary"
              dataTestId="submit"
            >
              Submit
            </Button>
          </FlexItem>

          <ScrollableBox maxHeight="300px">
            <ClinicianNotesListViewQueryLoader userId={userId} />
          </ScrollableBox>
        </FlexContainer>
      )}
    </Container>
  )
}

export default connect(styleRules)(ClinicianNoteForm)
