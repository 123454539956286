/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type SupportingSupporteeRemoveModal_individual_assignments$ref: FragmentReference;
declare export opaque type SupportingSupporteeRemoveModal_individual_assignments$fragmentType: SupportingSupporteeRemoveModal_individual_assignments$ref;
export type SupportingSupporteeRemoveModal_individual_assignments = $ReadOnlyArray<{|
  +id: string,
  +individual: {|
    +id: string,
    +legal_name: ?string,
  |},
  +$refType: SupportingSupporteeRemoveModal_individual_assignments$ref,
|}>;
export type SupportingSupporteeRemoveModal_individual_assignments$data = SupportingSupporteeRemoveModal_individual_assignments;
export type SupportingSupporteeRemoveModal_individual_assignments$key = $ReadOnlyArray<{
  +$data?: SupportingSupporteeRemoveModal_individual_assignments$data,
  +$fragmentRefs: SupportingSupporteeRemoveModal_individual_assignments$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SupportingSupporteeRemoveModal_individual_assignments",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "individual",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "legal_name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "SupportPersonAssignment",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '2b3aae81cf26e2ec81c6e67d0f72ca87';

module.exports = node;
