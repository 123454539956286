// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  EmrUpdateMutationResponse,
  EmrUpdateMutationVariables,
} from './__generated__/EmrUpdateMutation.graphql'

const mutation = graphql`
  mutation EmrUpdateMutation($input: EmrUpdateInput!) {
    emrUpdate(input: $input) {
      individual_detail {
        emr_user_id
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: EmrUpdateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: EmrUpdateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
