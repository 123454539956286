/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type IndividualAssignmentsPopover_tenant$ref = any;
export type IndividualAssignmentsPopover_QueryVariables = {|
  clinician_id: string,
  count: number,
  cursor?: ?string,
  search?: ?string,
|};
export type IndividualAssignmentsPopover_QueryResponse = {|
  +viewer: {|
    +role: {|
      +tenant?: ?{|
        +$fragmentRefs: IndividualAssignmentsPopover_tenant$ref
      |}
    |}
  |}
|};
export type IndividualAssignmentsPopover_Query = {|
  variables: IndividualAssignmentsPopover_QueryVariables,
  response: IndividualAssignmentsPopover_QueryResponse,
|};
*/


/*
query IndividualAssignmentsPopover_Query(
  $clinician_id: ID!
  $count: Int!
  $cursor: String
  $search: String
) {
  viewer {
    role(id: $clinician_id) {
      __typename
      ... on ClinicianRole {
        tenant {
          ...IndividualAssignmentsPopover_tenant_ja7dZ
          id
        }
        id
      }
      ... on AdminRole {
        id
      }
      ... on IndividualRole {
        id
      }
      ... on LeadClinicianRole {
        id
      }
      ... on ManagerRole {
        id
      }
      ... on OwnerRole {
        id
      }
      ... on ResearcherRole {
        id
      }
      ... on SuperRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
    id
  }
}

fragment AvatarLoader_user on User {
  id
  email
  legal_name
}

fragment IndividualAssignmentsPopover_tenant_ja7dZ on Tenant {
  assignable_individuals(first: $count, after: $cursor, search: $search, clinician_id: $clinician_id) {
    edges {
      node {
        id
        assigned_to_clinician(clinician_id: $clinician_id)
        user {
          ...UserCheckBoxLoader_user
          id
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment UserCardLoader_user on User {
  ...AvatarLoader_user
  username
  id
  legal_name
  preferred_name
}

fragment UserCheckBoxLoader_user on User {
  ...UserCardLoader_user
  id
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "clinician_id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "search"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "clinician_id"
  }
],
v2 = {
  "kind": "Variable",
  "name": "clinician_id",
  "variableName": "clinician_id"
},
v3 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  (v2/*: any*/),
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  (v3/*: any*/)
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IndividualAssignmentsPopover_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "args": [
                          (v2/*: any*/),
                          {
                            "kind": "Variable",
                            "name": "count",
                            "variableName": "count"
                          },
                          {
                            "kind": "Variable",
                            "name": "cursor",
                            "variableName": "cursor"
                          },
                          (v3/*: any*/)
                        ],
                        "kind": "FragmentSpread",
                        "name": "IndividualAssignmentsPopover_tenant"
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "type": "ClinicianRole",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IndividualAssignmentsPopover_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v1/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "role",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "Tenant",
                    "kind": "LinkedField",
                    "name": "tenant",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": (v5/*: any*/),
                        "concreteType": "IndividualRoleConnection",
                        "kind": "LinkedField",
                        "name": "assignable_individuals",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "IndividualRoleEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "IndividualRole",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  (v6/*: any*/),
                                  {
                                    "alias": null,
                                    "args": [
                                      (v2/*: any*/)
                                    ],
                                    "kind": "ScalarField",
                                    "name": "assigned_to_clinician",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "User",
                                    "kind": "LinkedField",
                                    "name": "user",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "email",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "legal_name",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "username",
                                        "storageKey": null
                                      },
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "preferred_name",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v5/*: any*/),
                        "filters": [],
                        "handle": "connection",
                        "key": "IndividualAssignmentsPopover_assignable_individuals",
                        "kind": "LinkedHandle",
                        "name": "assignable_individuals"
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "type": "ClinicianRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "AdminRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "IndividualRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "LeadClinicianRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "ManagerRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "OwnerRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "ResearcherRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "SuperRole",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": (v7/*: any*/),
                "type": "SupportPersonRole",
                "abstractKey": null
              }
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a7d18c1379e929eb1f2e676ae4921a5b",
    "id": null,
    "metadata": {},
    "name": "IndividualAssignmentsPopover_Query",
    "operationKind": "query",
    "text": "query IndividualAssignmentsPopover_Query(\n  $clinician_id: ID!\n  $count: Int!\n  $cursor: String\n  $search: String\n) {\n  viewer {\n    role(id: $clinician_id) {\n      __typename\n      ... on ClinicianRole {\n        tenant {\n          ...IndividualAssignmentsPopover_tenant_ja7dZ\n          id\n        }\n        id\n      }\n      ... on AdminRole {\n        id\n      }\n      ... on IndividualRole {\n        id\n      }\n      ... on LeadClinicianRole {\n        id\n      }\n      ... on ManagerRole {\n        id\n      }\n      ... on OwnerRole {\n        id\n      }\n      ... on ResearcherRole {\n        id\n      }\n      ... on SuperRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment AvatarLoader_user on User {\n  id\n  email\n  legal_name\n}\n\nfragment IndividualAssignmentsPopover_tenant_ja7dZ on Tenant {\n  assignable_individuals(first: $count, after: $cursor, search: $search, clinician_id: $clinician_id) {\n    edges {\n      node {\n        id\n        assigned_to_clinician(clinician_id: $clinician_id)\n        user {\n          ...UserCheckBoxLoader_user\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment UserCardLoader_user on User {\n  ...AvatarLoader_user\n  username\n  id\n  legal_name\n  preferred_name\n}\n\nfragment UserCheckBoxLoader_user on User {\n  ...UserCardLoader_user\n  id\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'aa815b5fcc4d40eb753dca46b64abbff';

module.exports = node;
