// @flow

import React from 'react'
import { filter, includes } from 'lodash/fp'

import { RadioButton } from 'care-ui'

type TrackablesCheckBoxType = {
  disabled: boolean,
  label: string,
  questionnaireId: string,
  selectedQuestionnaires: Array<string>,
  setSelectedQuestionnaires: (result: any) => void,
}

const TrackablesCheckBox = (props: TrackablesCheckBoxType) => {
  const {
    disabled,
    label,
    questionnaireId,
    selectedQuestionnaires,
    setSelectedQuestionnaires,
  } = props

  const onChange = () => {
    if (includes(questionnaireId)(selectedQuestionnaires)) {
      setSelectedQuestionnaires(prev => {
        const updated = filter(q => q !== questionnaireId)(prev)
        return updated
      })
    } else {
      setSelectedQuestionnaires(prev => [...prev, questionnaireId])
    }
  }

  const isChecked = includes(questionnaireId)(selectedQuestionnaires)

  return (
    <RadioButton
      inputAttributes={{
        id: label,
        name: label,
        checked: isChecked,
        onChange,
        disabled,
        label,
        type: 'checkbox',
      }}
    />
  )
}

export default TrackablesCheckBox
