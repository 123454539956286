/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AssessmentRequestsTask_individual$ref = any;
type ClinicianInputTask_individual$ref = any;
type InitialQuestionnaireUpdateTask_individual$ref = any;
type StaffDownloadDataTask_individual$ref = any;
type SummaryQuestionnaireUpdateTask_individual$ref = any;
type SupportPersonTask_individual$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualTasksCard_individual$ref: FragmentReference;
declare export opaque type IndividualTasksCard_individual$fragmentType: IndividualTasksCard_individual$ref;
export type IndividualTasksCard_individual = {|
  +$fragmentRefs: AssessmentRequestsTask_individual$ref & SupportPersonTask_individual$ref & StaffDownloadDataTask_individual$ref & ClinicianInputTask_individual$ref & SummaryQuestionnaireUpdateTask_individual$ref & InitialQuestionnaireUpdateTask_individual$ref,
  +$refType: IndividualTasksCard_individual$ref,
|};
export type IndividualTasksCard_individual$data = IndividualTasksCard_individual;
export type IndividualTasksCard_individual$key = {
  +$data?: IndividualTasksCard_individual$data,
  +$fragmentRefs: IndividualTasksCard_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndividualTasksCard_individual",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssessmentRequestsTask_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SupportPersonTask_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffDownloadDataTask_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClinicianInputTask_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SummaryQuestionnaireUpdateTask_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "InitialQuestionnaireUpdateTask_individual"
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'd122b85ac9710bdf0d5388de960e1370';

module.exports = node;
