// @flow

import loadScript from 'load-script'

declare var Plotly: ?Object

export const loadPlotly = (
  cb: (Plotly: ?Object, err: ?Error) => void,
): void => {
  if (window.Plotly) {
    return cb(window.Plotly)
  }

  loadScript('https://cdn.plot.ly/plotly-1.35.2.min.js', () => {
    if (window.Plotly) return cb(window.Plotly)

    return cb(null, new Error('Unable to load Plotly.'))
  })

  return undefined
}
