/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CurrentResultCard_user_trackable$ref = any;
type ScoreCardsCareOptions_user_trackable$ref = any;
type ScoreCardsPageAbout_trackable$ref = any;
type ScoreCardsPreviousAnswers_user_question_sets$ref = any;
type ScoreCardsPreviousAnswers_user_trackable$ref = any;
type UserTrackableChartControls_formulaRanges$ref = any;
type UserTrackableChartControls_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPage_user_trackable$ref: FragmentReference;
declare export opaque type ScoreCardsPage_user_trackable$fragmentType: ScoreCardsPage_user_trackable$ref;
export type ScoreCardsPage_user_trackable = {|
  +primary_user_questionnaire: ?{|
    +id: string,
    +user: {|
      +id: string
    |},
    +questionnaire: ?{|
      +label: string,
      +id: string,
    |},
    +user_question_sets: $ReadOnlyArray<{|
      +for_role_type: string,
      +$fragmentRefs: ScoreCardsPreviousAnswers_user_question_sets$ref,
    |}>,
  |},
  +user: {|
    +id: string,
    +$fragmentRefs: UserTrackableChartControls_user$ref,
  |},
  +id: string,
  +trackable: {|
    +formula: {|
      +formula_ranges: ?$ReadOnlyArray<{|
        +$fragmentRefs: UserTrackableChartControls_formulaRanges$ref
      |}>
    |},
    +label: string,
    +$fragmentRefs: ScoreCardsPageAbout_trackable$ref,
  |},
  +$fragmentRefs: ScoreCardsCareOptions_user_trackable$ref & CurrentResultCard_user_trackable$ref & ScoreCardsPreviousAnswers_user_trackable$ref,
  +$refType: ScoreCardsPage_user_trackable$ref,
|};
export type ScoreCardsPage_user_trackable$data = ScoreCardsPage_user_trackable;
export type ScoreCardsPage_user_trackable$key = {
  +$data?: ScoreCardsPage_user_trackable$data,
  +$fragmentRefs: ScoreCardsPage_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPage_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "primary_user_questionnaire",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserQuestionSet",
          "kind": "LinkedField",
          "name": "user_question_sets",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "for_role_type",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "ScoreCardsPreviousAnswers_user_question_sets"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserTrackableChartControls_user"
        }
      ],
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Formula",
          "kind": "LinkedField",
          "name": "formula",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "FormulaRange",
              "kind": "LinkedField",
              "name": "formula_ranges",
              "plural": true,
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "UserTrackableChartControls_formulaRanges"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ScoreCardsPageAbout_trackable"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardsCareOptions_user_trackable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CurrentResultCard_user_trackable"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardsPreviousAnswers_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '1331d19ca9fc55d8fbdbe0ba2acea354';

module.exports = node;
