// @flow

import React, { type ComponentType, type Node } from 'react'
import { connect } from 'react-fela'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = {
  children?: Node,
}

type InternalPropsType = FelaPropsType & PropsType

const styleRules = () => ({
  FlashContainer: {
    zIndex: 9999,
    position: 'fixed',
    bottom: '1rem',
    left: '1rem',
  },
})

function FlashContainer({ styles, children }: InternalPropsType) {
  return (
    <div
      className={styles.FlashContainer}
      role="status"
      aria-live="polite"
      aria-relevant="additions"
    >
      {children}
    </div>
  )
}

export default (connect(styleRules)(FlashContainer): ComponentType<PropsType>)
