// @flow

import { graphql } from 'react-relay'

export const listQuery = graphql`
  query SupportingSupporteeQuestionnaireSubmissionPage_Query(
    $id: ID!
    $supporteeUserId: ID!
    $count: Int!
    $cursor: String
  ) {
    node(id: $id) {
      ... on User {
        contract
        current_role {
          ... on SupportPersonRole {
            id
            supportees {
              ...SupportingSupporteeQuestionnaireSubmissionComponent_supportees
            }
          }
        }
        ...SupportingSupporteeQuestionnaireSubmissionComponent_user
          @arguments(
            supporteeUserId: $supporteeUserId
            count: $count
            cursor: $cursor
          )
      }
    }
  }
`
