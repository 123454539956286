// @flow

import React, { type Context, type Node, createContext } from 'react'

export type ContextValueType = {
  [feature: string]: boolean,
}

type PropsType = {
  children: Node,
}

export const FeatureToggleContext: Context<ContextValueType> = createContext({})

export default function FeatureToggleProvider({ children }: PropsType) {
  const { features } = window.GLOBALS
  if (!features)
    throw new Error('`features` has not been provided to window.GLOBALS!')

  return (
    <FeatureToggleContext.Provider value={features}>
      {children}
    </FeatureToggleContext.Provider>
  )
}
