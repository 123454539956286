// @flow

import type { TFunction } from 'react-i18next'
import { useRouter } from 'found'
import { get } from 'lodash'
import * as yup from 'yup'

import { useFeatureToggle } from 'platform_web/hooks/useFeatureToggle'
import { getFormIntialValues } from 'platform_web/utility/form'

const getFormFields = params => {
  const { SMS_INVITE, intialEmailValue, translation } = params

  const formFields = [
    {
      id: SMS_INVITE ? 'username' : 'email',
      label: SMS_INVITE ? translation('username') : translation('email'),
      type: SMS_INVITE ? 'text' : 'email',
      placeholder: SMS_INVITE ? translation('username') : 'name@domain',
      initialValue: SMS_INVITE ? '' : intialEmailValue,
    },
    {
      id: 'password',
      label: translation('password'),
      type: 'password',
      placeholder: translation('password'),
    },
    {
      id: 'rememberMe',
      type: 'checkbox',
      label: translation('keep_me_signed_in'),
      initialValue: false,
    },
  ]

  return formFields
}

const getValidationSchema = (SMS_INVITE, translation) => {
  const emailValidation = yup
    .string()
    .label('Email')
    .email(translation('email_must_be_a_valid_email'))
    .required(translation('email_is_a_required_field'))

  const usernameValidation = yup
    .string()
    .label('Username')
    .required(translation('username_is_a_required_field'))

  const passwordValidation = yup
    .string()
    .label('Password')
    .min(8, translation('password_must_be_at_least_8_characters'))
    .required(translation('password_is_a_required_field'))

  return {
    email: SMS_INVITE ? undefined : emailValidation,
    password: passwordValidation,
    username: SMS_INVITE ? usernameValidation : undefined,
    rememberMe: yup.boolean(),
  }
}

export type SignInFormOptions = {
  translation: TFunction,
}

const useSignInForm = (options: SignInFormOptions = {}) => {
  const { translation } = options
  const { SMS_INVITE } = useFeatureToggle()

  // Getting email from url after region selector redirects to new url
  const { match } = useRouter()
  const email = get(match, 'location.query.email')
  const intialEmailValue = email ? decodeURIComponent(email) : ''

  // Form Fields
  const formFields = getFormFields({
    SMS_INVITE,
    intialEmailValue,
    translation,
  })
  // Initial Form Values
  const initialValues = getFormIntialValues(formFields)

  return {
    formFields,
    initialValues,
    validationSchema: getValidationSchema(SMS_INVITE, translation),
  }
}

export default useSignInForm
