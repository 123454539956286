// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import { FlexContainer } from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

const BaselineScoresEmptyState = () => {
  const { t: translation } = useTranslation('insights')

  return (
    <FlexContainer
      justifyContent="center"
      height="200px"
      alignItems="center"
      direction="column"
      marginTop="md"
    >
      <TextNew as="p" typography="bodyMdBold">
        {translation(
          'no_domains_with_a_baseline_score_can_be_found_within_thin_perion',
        )}
      </TextNew>

      <TextNew as="div" typography="bodyMdBold">
        {translation(
          'try_changing_the_date_range_using_the_date_selector_at_the_top_of_the_page',
        )}
      </TextNew>
    </FlexContainer>
  )
}

export default BaselineScoresEmptyState
