// @flow

import React, { type Node } from 'react'

import carpetImg from 'react-ui/assets/backgrounds/carpet.png'
import leafVerticalImg from 'react-ui/assets/backgrounds/leave_vertical.png'
import Heading from 'react-ui/components/Heading'
import { Layout2Col } from 'react-ui/components/Layout'
import MainContentRegion from 'react-ui/components/MainContentRegion'
import MatchMedia from 'react-ui/components/MatchMedia'
import Themelet from 'react-ui/components/Themelet'
import birdImg from 'shared/ui/Styles/images/bird.png'

const styleRules = ({ theme }) => {
  const vPadding = theme.spacing(1)

  const regionStyles = {
    paddingBottom: vPadding,
    paddingTop: vPadding,
  }

  return {
    asideContent: {
      ...regionStyles,
      className: 'SignInPageLayout__asideContent',
      justifyContent: 'flex-start',
      paddingLeft: theme.Grid.gutter,
      paddingRight: theme.Grid.gutter,
      [theme.breakpoints.queries.md]: {
        justifyContent: 'center',
      },
    },
    articleContent: {
      ...regionStyles,
      className: 'SignInPageLayout__articleContent',
      justifyContent: 'center',
    },
  }
}

const Welcome2Col = ({ children, ...props }: { children: Node }) => (
  <MatchMedia>
    {({ md, lg }) => (
      <Layout2Col
        aside={children}
        asideColSpan={(() => {
          if (lg) {
            return 4
          }
          if (md) {
            return 5
          }

          return 12
        })()}
        {...(props: any)}
      >
        <Heading level={3}>
          Created for both individuals and clinicians, Innowell enables getting
          the right care at the right time.
        </Heading>
      </Layout2Col>
    )}
  </MatchMedia>
)

const defaultRenderArticle = (
  { children, ...props }: { children: Node },
  rule,
) => (
  <Themelet
    as="article"
    extend={({ theme, ...rest }, ...args) => ({
      ...rule({ ...rest, theme }, ...args),
      backgroundAttachment: 'fixed',
      backgroundBlendMode: 'screen',
      backgroundImage: `
        url(${carpetImg})
      `,
      backgroundSize: '100%',
      backgroundRepeat: 'repeat',
      className: 'Welcome2Colarticle-themelet',
      display: 'flex',
      margin: '0 auto',
      [theme.breakpoints.queries.md]: {
        backgroundBlendMode: 'normal, screen',
        backgroundImage: `
            url(${leafVerticalImg}),
            url(${carpetImg})
          `,
        backgroundPosition: `
            calc(100% + 70px) calc(100% + 65px),
            0 0`,
        backgroundRepeat: 'no-repeat, repeat',
        backgroundSize: '208px 580px, 384px 384px',
        justifyContent: 'center',
        paddingRight: '140px',
      },
      [theme.breakpoints.queries.lg]: {
        backgroundBlendMode: 'normal, normal, screen',
        backgroundImage: `
          url(${birdImg}),
          url(${leafVerticalImg}),
          url(${carpetImg})
        `,
        backgroundPosition: `
            calc(100% - 108px) calc(100% - 400px),
            calc(100% + 70px) calc(100% + 65px),
            0 0
          `,
        backgroundRepeat: 'no-repeat, no-repeat, repeat',
        backgroundSize: '90px 76px, 208px 580px, 384px 384px',
        paddingRight: '190px',
      },
    })}
    variant="primary"
    {...(props: any)}
  >
    <MainContentRegion extend={() => ({ paddingLeft: '10px' })}>
      {children}
    </MainContentRegion>
  </Themelet>
)

const EnhancedWelcome2Col = ({
  children,
  renderArticle,
  ...props
}: {
  children: Node,
  renderArticle?: any,
}) => {
  const article = renderArticle || defaultRenderArticle

  return (
    <Welcome2Col {...props} renderArticle={article} extend={styleRules}>
      {children}
    </Welcome2Col>
  )
}

export default EnhancedWelcome2Col
