// @flow

import React, { useRef } from 'react'
import { useFela } from 'react-fela'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { capitalize } from 'lodash/fp'

import Lightbulb from 'react-ui/assets/icons/lightbulb.svg'
import Footer from 'react-ui/components/Footer'
import { Button, DropDownMenu, DropDownMenuItem, Icon, Text } from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import SkippableProgressBar from '../SkippableProgressBar'

import {
  childrenWrapperStyles,
  columnStyles,
  footerButtonsStyles,
  footerContentStyles,
  footerElementStyles,
  footerStyles,
  iconStyles,
  progressPercentStyles,
  responsiveIconStyles,
} from './UserQuestionnaireQuestionSetFooter.style'

import type { UserQuestionnaireQuestionSetFooter_answer_set } from './__generated__/UserQuestionnaireQuestionSetFooter_answer_set.graphql'
import type { UserQuestionnaireQuestionSetFooter_userQuestionnaire } from './__generated__/UserQuestionnaireQuestionSetFooter_userQuestionnaire.graphql'
import type { UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet } from './__generated__/UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet.graphql'

type QuestionnaireFooterPropsType = {
  answer_set: UserQuestionnaireQuestionSetFooter_answer_set,
  busy: boolean,
  onSkipReasonSelect: Function,
  onSubmit: () => void,
  showSkipReasonSelectBox: ?boolean,
  skipReason: ?string,
  submissionDisabled: ?boolean,
  submitting: boolean,
  userQuestionnaire: UserQuestionnaireQuestionSetFooter_userQuestionnaire,
  userQuestionnaireQuestionSet: UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet,
}

type QuestionnaireFooterButtonTextPropsType = {
  nextButtonContent?: Function,
  next_user_questionnaires_question_set: $PropertyType<
    UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet,
    'next_user_questionnaires_question_set',
  >,
  submitting: boolean,
  thank_you_page_relevant: boolean,
  translation: TFunction,
}

type SkippedQuestionsIndexPropsType = {
  allQuestionSets: $ReadOnlyArray<Object>,
  skippedQuestionSets: $ReadOnlyArray<Object>,
}

const qqsButtonText = ({
  thank_you_page_relevant,
  next_user_questionnaires_question_set,
  translation,
}: QuestionnaireFooterButtonTextPropsType) => {
  // const {t:translation} = useTranslation('assessment')

  if (thank_you_page_relevant || next_user_questionnaires_question_set) {
    return translation('next')
  }

  return translation('submit')
}

const getSkippedQuestionsIndex = ({
  skippedQuestionSets,
  allQuestionSets,
}: SkippedQuestionsIndexPropsType) => {
  const allQuestionsMap = new Map()
  const skippedQuestionsIndices = []

  allQuestionSets.map(({ question_set }, index) =>
    allQuestionsMap.set(question_set.id, index),
  )

  skippedQuestionSets.map(({ question_set }) =>
    skippedQuestionsIndices.push(allQuestionsMap.get(question_set.id)),
  )

  return skippedQuestionsIndices
}

const QuestionnaireFooter = ({
  answer_set: { skip_reason_list },
  busy,
  onSubmit: handleSubmit,
  onSkipReasonSelect: handleSelect,
  showSkipReasonSelectBox,
  userQuestionnaire: {
    thank_you_page_relevant,
    allQuestionSets,
    skippedQuestionSets,
  },
  userQuestionnaireQuestionSet: {
    question_set: { tip },
    all_questions_answered,
    progress,
    next_user_questionnaires_question_set,
  },
  submissionDisabled,
  submitting,
}: QuestionnaireFooterPropsType) => {
  const { t: translation } = useTranslation('assessment')
  const formSubmitButton = useRef(null)
  const { css } = useFela()

  const handleSkip = key => {
    handleSelect(
      key,
      () => formSubmitButton.current && formSubmitButton.current.click(),
    )
  }

  return (
    <Footer
      extend={() => ({
        Footer: footerStyles,
        ChildrenWrapper: childrenWrapperStyles,
      })}
      center
      color="white"
      noPadding
    >
      <div className={css(footerContentStyles)}>
        {progress.total > 1 && (
          <div className={css([footerElementStyles, columnStyles])}>
            <SkippableProgressBar
              current={progress.step}
              total={allQuestionSets.length}
              skippedSets={getSkippedQuestionsIndex({
                skippedQuestionSets,
                allQuestionSets,
              })}
            />
            <Text as="p" size="lg" color="title" extend={progressPercentStyles}>
              {progress.percent ? progress.percent : 0}%{' '}
              {capitalize(translation('completed'))}
            </Text>
          </div>
        )}
        {tip && (
          <div className={css(footerElementStyles)}>
            <div className={css(responsiveIconStyles)}>
              <div className={css(iconStyles)}>
                <Icon as={Lightbulb} />
              </div>
            </div>
            <div className={css(responsiveIconStyles)}>
              <em>{tip} </em>
            </div>
          </div>
        )}
        <div className={css(footerButtonsStyles)}>
          {showSkipReasonSelectBox && (
            <>
              <DropDownMenu
                position="reverse"
                dropDownTrigger={
                  <Button variant="text">
                    {translation('skip')} <Icon as={ArrowDown} scale={0.5} />
                  </Button>
                }
              >
                {skip_reason_list.map(({ key, value }) => (
                  <DropDownMenuItem key={key} onClick={() => handleSkip(key)}>
                    {value}
                  </DropDownMenuItem>
                ))}
              </DropDownMenu>

              <button
                type="submit"
                ref={formSubmitButton}
                style={{ display: 'none' }}
              />
            </>
          )}

          {!submitting && (
            <Button
              type="submit"
              disabled={
                busy ||
                submitting ||
                submissionDisabled ||
                !all_questions_answered
              }
              variant="primary"
              onClick={handleSubmit}
              dataTestId="submitQuestionSet"
            >
              {qqsButtonText({
                submitting,
                thank_you_page_relevant,
                next_user_questionnaires_question_set,
                translation,
              })}
            </Button>
          )}

          {submitting && `${translation('submitting')}...`}
        </div>
      </div>
    </Footer>
  )
}

const QuestionnaireFooterLoader = createFragmentContainer(QuestionnaireFooter, {
  userQuestionnaireQuestionSet: graphql`
    fragment UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet on UserQuestionnairesQuestionSet {
      all_questions_answered
      question_set {
        tip
      }
      next_user_questionnaires_question_set {
        questionnaires_question_set {
          id
          label
        }
      }
      ...QuestionSetCard_user_questionnaires_question_set @relay(mask: false)
    }
  `,
  userQuestionnaire: graphql`
    fragment UserQuestionnaireQuestionSetFooter_userQuestionnaire on UserQuestionnaire {
      thank_you_page_relevant
      allQuestionSets: user_questionnaires_question_sets {
        question_set {
          id
        }
      }
      skippedQuestionSets: user_questionnaires_question_sets(scope: skipped) {
        question_set {
          id
        }
      }
    }
  `,
  answer_set: graphql`
    fragment UserQuestionnaireQuestionSetFooter_answer_set on AnswerSetSkipReasonEnumType {
      skip_reason_list {
        key
        value
      }
    }
  `,
})

export default QuestionnaireFooterLoader
export { qqsButtonText, getSkippedQuestionsIndex }
