// @flow

import { graphql } from 'react-relay'

export const IndividualsPageQuery = graphql`
  query IndividualsPage_Query {
    viewer {
      currentUser {
        current_role {
          ...IndividualsPage_current_role
        }
        ...IndividualsPage_user
      }
    }
  }
`

export const IndividualsPageCurrentRoleFragmentQuery = graphql`
  fragment IndividualsPage_current_role on RoleInterface {
    id
    role_type
    ...IndividualsAdminInvite_current_role
    parent {
      ... on RoleParentInterface {
        id
      }
    }
  }
`

export const IndividualsPageUserFragmentQuery = graphql`
  fragment IndividualsPage_user on User {
    id
    tab_options {
      id
      label
      value
    }
    initial_state
    tenant {
      ...IndividualsPage_tenant @relay(mask: false)
    }
  }
`

graphql`
  fragment IndividualsPage_tenant on Tenant @relay(mask: false) {
    id
    important_formulas(kind: individual) {
      label
      formula {
        id
      }
    }
  }
`
