// @flow

import { flow, head, isEmpty, toPairs } from 'lodash/fp'

import type { FlashType } from 'react-ui/components/Flash'
import { createFlash } from 'platform_web/containers/Flash'

export const handleResponseFlash = (response: Response) => {
  const flash = JSON.parse(response.headers.get('X-Flash') || '{}')

  if (!isEmpty(flash)) {
    const [type, message] = flow(toPairs, head)(flash)

    createFlash({ type, message })
  }

  return response
}

type PageLoadFlashType = { [type: FlashType]: string }

export const handlePageLoadFlashes = (flashData: PageLoadFlashType = {}) => {
  toPairs(flashData).forEach(flash => {
    const [type, message] = flash
    // Devise uses this internally to store timeout state, we ignore it
    if (type !== 'timedout') {
      createFlash({ type, message })
    }
  })
}
