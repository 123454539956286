// @flow

import * as React from 'react'
import { createComponentWithProxy, ThemeProvider, withTheme } from 'react-fela'
import { flowRight, merge } from 'lodash/fp'

type LinkThemeType = {
  activeColor: string,
  focusColor: string,
  hoverColor: string,
  linkColor: string,
  visitedColor: string,
}

export type VariantType = 'primary' | 'default' | 'white' | 'grey'

type PropsType = {
  children: React.Node,
  theme: {
    palette: {
      link: LinkThemeType,
      themelet: {
        [key: string]: {
          backgroundColor: string,
          color: string,
          headingTextColor: string,
          link: LinkThemeType,
        },
      },
    },
  },
  variant?: VariantType,
}

const Themelet = ({ children, theme, variant = 'default' }: PropsType) => {
  const {
    backgroundColor,
    color: textColor,
    headingTextColor,
    link,
  } = theme.palette.themelet[variant]

  const themeletTheme = merge(theme, {
    Heading: {
      color: headingTextColor,
    },
    palette: {
      headingTextColor,
      link: {
        ...theme.palette.link,
        ...link,
      },
      textColor,
      bodyBackgroundColor: backgroundColor,
    },
  })

  return (
    <ThemeProvider theme={themeletTheme}>
      <React.Fragment>{children}</React.Fragment>
    </ThemeProvider>
  )
}

const withThemelet = WrappedComponent => ({
  theme,
  variant = 'default',
  ...props
}: PropsType) => (
  <Themelet theme={theme} variant={variant}>
    {React.createElement(WrappedComponent, { ...props, variant })}
  </Themelet>
)

const enhance = flowRight([withTheme, withThemelet])

export default enhance(
  createComponentWithProxy(({ theme, variant }) => ({
    backgroundColor: theme.palette.bodyBackgroundColor,
    color: theme.palette.textColor,
    className: `Themelet--${variant}`,
  })),
)
