/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AssessmentRequestsAnswerTableBase_answers$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssessmentRequestsQuestionnaire_answers$ref: FragmentReference;
declare export opaque type AssessmentRequestsQuestionnaire_answers$fragmentType: AssessmentRequestsQuestionnaire_answers$ref;
export type AssessmentRequestsQuestionnaire_answers = $ReadOnlyArray<{|
  +$fragmentRefs: AssessmentRequestsAnswerTableBase_answers$ref,
  +$refType: AssessmentRequestsQuestionnaire_answers$ref,
|}>;
export type AssessmentRequestsQuestionnaire_answers$data = AssessmentRequestsQuestionnaire_answers;
export type AssessmentRequestsQuestionnaire_answers$key = $ReadOnlyArray<{
  +$data?: AssessmentRequestsQuestionnaire_answers$data,
  +$fragmentRefs: AssessmentRequestsQuestionnaire_answers$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AssessmentRequestsQuestionnaire_answers",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AssessmentRequestsAnswerTableBase_answers"
    }
  ],
  "type": "AnswerInterface",
  "abstractKey": "__isAnswerInterface"
};
// prettier-ignore
(node/*: any*/).hash = '028e09183a411a17a9e40d49f9baf576';

module.exports = node;
