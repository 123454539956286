/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianNoteCard_clinician_note$ref: FragmentReference;
declare export opaque type ClinicianNoteCard_clinician_note$fragmentType: ClinicianNoteCard_clinician_note$ref;
export type ClinicianNoteCard_clinician_note = {|
  +text: string,
  +created_at: any,
  +$refType: ClinicianNoteCard_clinician_note$ref,
|};
export type ClinicianNoteCard_clinician_note$data = ClinicianNoteCard_clinician_note;
export type ClinicianNoteCard_clinician_note$key = {
  +$data?: ClinicianNoteCard_clinician_note$data,
  +$fragmentRefs: ClinicianNoteCard_clinician_note$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianNoteCard_clinician_note",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    }
  ],
  "type": "ClinicianNote",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '94f3867f3b057351a3582b208e992d4f';

module.exports = node;
