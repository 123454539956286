// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const LikeIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 42 41"
        style={{ enableBackground: 'new 0 0 42 41' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g>
          <path
            d="M41.9,23.9c0-1-0.3-1.9-0.9-2.7c0.7-0.9,1-2.1,0.9-3.3c-0.2-2.5-2.5-4.4-5.1-4.4h-9.6c0.1-0.2,0.1-0.5,0.2-0.7
	c0.3-1,0.5-2,0.7-2.9c0.2-0.9,0.2-1.7,0.2-2.5c0-3.6-3.1-7.4-5.7-7.4c-0.9,0-1.7,0.2-2.5,0.6c-0.5,0.2-0.8,0.4-1,0.6
	c-0.4,0.3-0.6,0.7-0.6,1.2v5.6l-4.3,9.3c-0.3-0.2-0.6-0.3-1-0.3H4.9c-2.7,0-4.9,2.2-4.9,4.9v13.5c0,2.7,2.2,4.9,4.9,4.9H10
	c1.9,0,3.5-1,4.3-2.6c1.3,0.6,2.9,0.9,4.2,0.9H34c2.1,0,4-1.4,4.5-3.5c0.2-0.8,0.1-1.7-0.1-2.4c1.2-0.8,1.9-2.1,1.9-3.7
	c0-0.5-0.1-1-0.2-1.4C41.2,26.8,41.9,25.4,41.9,23.9z M10,37.2H4.9c-1,0-1.9-0.8-1.9-1.9V21.8c0-1,0.8-1.9,1.9-1.9h7v0.2v15.2
	C11.8,36.4,11,37.2,10,37.2z M37.6,25.4c-1.3,0.1-1.8,1.7-0.9,2.6c0.3,0.3,0.5,0.7,0.5,1.1c0,0.7-0.6,1.4-1.3,1.4
	c-1.3,0.1-1.8,1.7-0.9,2.6c0.4,0.4,0.5,0.9,0.4,1.4c-0.1,0.6-0.8,1.1-1.5,1.1H18.4c-1.1,0-2.7-0.4-3.6-0.9V21l0.9-0.4
	c0.3-0.2,0.5-0.4,0.7-0.7l5.1-11l0.1-0.6V3.2C22,3.1,22.3,3,22.7,3c0.8,0,2.7,2.3,2.7,4.4c0,0.6-0.1,1.2-0.2,2
	c-0.1,0.8-0.3,1.7-0.6,2.6c-0.3,1.1-0.6,2-0.8,2.5c-0.4,1,0.4,2,1.4,2h11.6c1.1,0,2,0.8,2.1,1.7C39,19,38.6,19.6,38,20
	c-1,0.6-0.9,2.1,0.1,2.6c0.5,0.3,0.8,0.7,0.8,1.3C38.9,24.7,38.4,25.3,37.6,25.4z"
          />
        </g>
      </svg>
    </BaseIcon>
  )
}

export default LikeIcon
