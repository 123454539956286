// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import Heading from 'react-ui/components/Heading'
import { withoutFelaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  as: string,
  children: React.Node,
  print?: boolean,
  title: React.Node,
}

const styleRules = () => ({
  ReportsView: {
    className: 'ReportsView',
  },
})

const ReportsView = ({
  as: Component = 'div',
  children,
  styles,
  title,
  print,
  ...props
}: PropTypes) => {
  const displayHeader = print === true

  return (
    <Component className={styles.ReportsView} {...withoutFelaProps(props)}>
      {displayHeader && <Heading level={3}>{title}</Heading>}
      {children}
    </Component>
  )
}

export default connect(styleRules)(ReportsView)
