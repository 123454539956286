/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
type DataGridRowFooterContentLoader_user$ref = any;
type StaffActions_user$ref = any;
type UserCardLoader_user$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EmployeesTable_user$ref: FragmentReference;
declare export opaque type EmployeesTable_user$fragmentType: EmployeesTable_user$ref;
export type EmployeesTable_user = {|
  +staff_admin: {|
    +staff_count: number,
    +common_role_types: ?$ReadOnlyArray<string>,
    +staff: {|
      +edges: ?$ReadOnlyArray<?{|
        +node: ?{|
          +user: {|
            +email: string,
            +invitation_created_at: ?any,
            +legal_name: ?string,
            +invitation_delta: ?string,
            +$fragmentRefs: AvatarLoader_user$ref & UserCardLoader_user$ref & DataGridRowFooterContentLoader_user$ref & StaffActions_user$ref,
          |},
          +roles: $ReadOnlyArray<{|
            +id?: string,
            +role_type?: RoleTypes,
            +role_status?: string,
            +description?: string,
            +tenant?: ?{|
              +name: string,
              +requires_mfa: boolean,
            |},
          |}>,
        |}
      |}>
    |},
  |},
  +id: string,
  +$refType: EmployeesTable_user$ref,
|};
export type EmployeesTable_user$data = EmployeesTable_user;
export type EmployeesTable_user$key = {
  +$data?: EmployeesTable_user$data,
  +$fragmentRefs: EmployeesTable_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  "staff_admin",
  "staff"
],
v1 = {
  "kind": "Variable",
  "name": "query_type",
  "variableName": "query_type"
},
v2 = {
  "kind": "Variable",
  "name": "search",
  "variableName": "search"
},
v3 = {
  "kind": "Variable",
  "name": "status",
  "variableName": "status"
},
v4 = [
  (v1/*: any*/),
  (v2/*: any*/),
  (v3/*: any*/)
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "count"
    },
    {
      "kind": "RootArgument",
      "name": "cursor"
    },
    {
      "kind": "RootArgument",
      "name": "query_type"
    },
    {
      "kind": "RootArgument",
      "name": "search"
    },
    {
      "kind": "RootArgument",
      "name": "sort"
    },
    {
      "kind": "RootArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": require('./EmployeesTableStaffAdminRefetchQuery.graphql'),
      "identifierField": "id"
    }
  },
  "name": "EmployeesTable_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "StaffAdmin",
      "kind": "LinkedField",
      "name": "staff_admin",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v4/*: any*/),
          "kind": "ScalarField",
          "name": "staff_count",
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v4/*: any*/),
          "kind": "ScalarField",
          "name": "common_role_types",
          "storageKey": null
        },
        {
          "alias": "staff",
          "args": [
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "kind": "Variable",
              "name": "sort",
              "variableName": "sort"
            },
            (v3/*: any*/)
          ],
          "concreteType": "StaffConnection",
          "kind": "LinkedField",
          "name": "__EmployeesTable_staff_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "StaffEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Staff",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "User",
                      "kind": "LinkedField",
                      "name": "user",
                      "plural": false,
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "email",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invitation_created_at",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "legal_name",
                          "storageKey": null
                        },
                        {
                          "alias": null,
                          "args": null,
                          "kind": "ScalarField",
                          "name": "invitation_delta",
                          "storageKey": null
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "AvatarLoader_user"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "UserCardLoader_user"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "DataGridRowFooterContentLoader_user"
                        },
                        {
                          "args": null,
                          "kind": "FragmentSpread",
                          "name": "StaffActions_user"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "roles",
                      "plural": true,
                      "selections": [
                        {
                          "kind": "InlineFragment",
                          "selections": [
                            (v5/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "role_type",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "role_status",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "description",
                              "storageKey": null
                            },
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "Tenant",
                              "kind": "LinkedField",
                              "name": "tenant",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "name",
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "kind": "ScalarField",
                                  "name": "requires_mfa",
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "type": "RoleInterface",
                          "abstractKey": "__isRoleInterface"
                        }
                      ],
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    (v5/*: any*/)
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ba02715c68428297bf24b6bab4932e26';

module.exports = node;
