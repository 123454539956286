/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type AgeRangeEnumType = "from_15_to_19" | "from_20_to_24" | "from_25_to_29" | "from_30_to_34" | "from_35_to_39" | "from_40_to_44" | "from_45_to_49" | "from_50_to_54" | "from_55_to_59" | "from_60_to_64" | "from_65_to_69" | "from_70_to_74" | "from_75_to_79" | "from_80_to_84" | "over_85" | "%future added value";
export type AssistanceNeededType = "already_obtained" | "needed" | "not_needed" | "%future added value";
export type HasDisabilityEnumType = "no_answer_has_disability" | "no_has_disability" | "yes_has_disability" | "%future added value";
export type StateEnumType = "ACT" | "NSW" | "NT" | "QLD" | "SA" | "TAS" | "VIC" | "WA" | "%future added value";
export type SupportLevelEnumType = "dependent" | "independent" | "partially_supported" | "%future added value";
export type IndividualPersonalDetailUpdateInput = {|
  clientMutationId?: ?string,
  individual_personal_detail: IndividualPersonalDetailUpdateInputObject,
  profile_type: string,
|};
export type IndividualPersonalDetailUpdateInputObject = {|
  adopted?: ?string,
  age_range?: ?AgeRangeEnumType,
  assistanceNeeded?: ?AssistanceNeededType,
  child_care_support?: ?any,
  child_finance_support?: ?any,
  currently_studying?: ?any,
  date_of_birth?: ?any,
  employed?: ?string,
  employment_status?: ?any,
  employment_status_father?: ?any,
  employment_status_mother?: ?any,
  ethnicity?: ?any,
  found_service_through?: ?any,
  full_time_equivalent_ratio?: ?number,
  gender?: ?any,
  gender_at_birth?: ?any,
  has_children?: ?string,
  has_disability?: ?HasDisabilityEnumType,
  has_gov_benefit?: ?string,
  household_income?: ?string,
  income_source?: ?any,
  indigenous_community?: ?string,
  indigenous_status?: ?string,
  level_of_education?: ?any,
  level_of_education_father?: ?any,
  level_of_education_mother?: ?any,
  living_circumstances?: ?any,
  main_care_language?: ?string,
  minority?: ?string,
  nature_of_disability?: ?any,
  no_of_children?: ?number,
  other?: ?any,
  place_in_service?: ?string,
  place_of_birth?: ?string,
  postcode?: ?string,
  primary_language?: ?any,
  profession?: ?string,
  professional_achievement?: ?any,
  relationship_status?: ?string,
  residential_status?: ?string,
  sexual_identity?: ?any,
  state?: ?StateEnumType,
  student?: ?string,
  suburb?: ?string,
  support_level?: ?SupportLevelEnumType,
  type_of_gov_benefit?: ?any,
  year_arrived_in_country?: ?number,
  years_in_current_role?: ?number,
  years_practicing_discipline?: ?number,
|};
export type IndividualPersonalDetailUpdateMutationVariables = {|
  input: IndividualPersonalDetailUpdateInput
|};
export type IndividualPersonalDetailUpdateMutationResponse = {|
  +individualPersonalDetailUpdate: ?{|
    +viewer: {|
      +currentUser: ?{|
        +show_individual_onboarding: boolean,
        +policies: $ReadOnlyArray<{|
          +name: string,
          +enabled: boolean,
        |}>,
      |}
    |}
  |}
|};
export type IndividualPersonalDetailUpdateMutation = {|
  variables: IndividualPersonalDetailUpdateMutationVariables,
  response: IndividualPersonalDetailUpdateMutationResponse,
|};
*/


/*
mutation IndividualPersonalDetailUpdateMutation(
  $input: IndividualPersonalDetailUpdateInput!
) {
  individualPersonalDetailUpdate(input: $input) {
    viewer {
      currentUser {
        show_individual_onboarding
        policies {
          name
          enabled
        }
        id
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "show_individual_onboarding",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "Policy",
  "kind": "LinkedField",
  "name": "policies",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "enabled",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "IndividualPersonalDetailUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IndividualPersonalDetailUpdatePayload",
        "kind": "LinkedField",
        "name": "individualPersonalDetailUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "IndividualPersonalDetailUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "IndividualPersonalDetailUpdatePayload",
        "kind": "LinkedField",
        "name": "individualPersonalDetailUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Viewer",
            "kind": "LinkedField",
            "name": "viewer",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "currentUser",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fd1094244c3f8812770dc600f8fe0c61",
    "id": null,
    "metadata": {},
    "name": "IndividualPersonalDetailUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation IndividualPersonalDetailUpdateMutation(\n  $input: IndividualPersonalDetailUpdateInput!\n) {\n  individualPersonalDetailUpdate(input: $input) {\n    viewer {\n      currentUser {\n        show_individual_onboarding\n        policies {\n          name\n          enabled\n        }\n        id\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '5e14e8b0cff33e048f9cde7b6c95845e';

module.exports = node;
