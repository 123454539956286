// @flow

import React, { useState } from 'react'
import { useFela } from 'react-fela'
import { useSelector } from 'react-redux'
import { createFragmentContainer } from 'react-relay'
import { startCase } from 'lodash'

import { UserTrackableChartControls } from 'components/UserTrackable/components/UserTrackableChartControls'
import { Box, Container, FlexContainer, PageHeader } from 'care-ui'

import { scoreCardsPageUserTrackable } from '../../queries/ScoreCardsPage'
import { CurrentResultCard } from '../CurrentResultCard'
import { ScoreCardsCareOptions } from '../ScoreCardsCareOptions'
import { ScoreCardsPageAbout } from '../ScoreCardsPageAbout'
import { ScoreCardsPreviousAnswers } from '../ScoreCardsPreviousAnswers'

import { graphStyle } from './ScoreCardsPage.style'

import type { ScoreCardsPage_user_trackable } from '../../queries/__generated__/ScoreCardsPage_user_trackable.graphql'
import type { ScoreCardsPage_viewer } from '../../queries/__generated__/ScoreCardsPage_viewer.graphql'

type PropsType = {
  componentId?: string,
  +user_trackable: ScoreCardsPage_user_trackable,
  +viewer: ScoreCardsPage_viewer,
}

const ScoreCardsPageBase = (props: PropsType) => {
  const {
    user_trackable,
    user_trackable: {
      primary_user_questionnaire,
      trackable,
      trackable: { formula: { formula_ranges } },
    },
    viewer,
  } = props
  const [selectedAnswer, setSelectedAnswer] = useState(null)
  const { css } = useFela()

  const [isIndividual] = useSelector(({ currentUser: { roleType } }) => [
    roleType === 'INDIVIDUAL',
  ])
  const dashboardRoute = isIndividual ? 'dashboard' : 'user_dashboard'

  if (!primary_user_questionnaire) {
    return ''
  }
  const { user_question_sets } = primary_user_questionnaire
  const broadcastPointData = event => {
    const data = event?.point?.category
    setSelectedAnswer(data)
  }

  const linkTo = {
    name: dashboardRoute,
    params: { id: user_trackable.user.id },
  }

  return (
    <>
      <PageHeader linkTo={linkTo}>{startCase(trackable.label)}</PageHeader>

      <Container>
        <ScoreCardsPageAbout trackable={trackable} />

        <Box
          border="lightBackground"
          boxShadow={1}
          paddingX="md"
          paddingY="md"
          borderRadius="sm"
        >
          <FlexContainer direction="column">
            <FlexContainer direction="row" width="100%">
              <CurrentResultCard user_trackable={user_trackable} />

              <div className={css(graphStyle)}>
                <UserTrackableChartControls
                  broadcastPointData={broadcastPointData}
                  formulaRanges={formula_ranges}
                  user={user_trackable.user}
                />
              </div>
            </FlexContainer>

            <ScoreCardsPreviousAnswers
              broadcastPointData={broadcastPointData}
              selected_answer={selectedAnswer}
              user_question_sets={user_question_sets}
              user_trackable={user_trackable}
            />
          </FlexContainer>
        </Box>

        <ScoreCardsCareOptions
          user_trackable={user_trackable}
          viewer={viewer}
        />
      </Container>
    </>
  )
}

export const ScoreCardsPage = createFragmentContainer(
  ScoreCardsPageBase,
  scoreCardsPageUserTrackable,
)
