/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserUpdateInput = {|
  clientMutationId?: ?string,
  user: UserUpdateInputObject,
|};
export type UserUpdateInputObject = {|
  completed_onboarding?: ?boolean,
  email?: ?string,
  legal_name?: ?string,
  phone_number?: ?string,
  preferred_language?: ?string,
  preferred_name?: ?string,
  selected_role_id?: ?string,
  tenant_id?: ?string,
  username?: ?string,
|};
export type SwitchRoleMutationVariables = {|
  input: UserUpdateInput,
  role_id?: ?string,
|};
export type SwitchRoleMutationResponse = {|
  +userUpdate: ?{|
    +user: {|
      +homepage_path: string
    |}
  |}
|};
export type SwitchRoleMutation = {|
  variables: SwitchRoleMutationVariables,
  response: SwitchRoleMutationResponse,
|};
*/


/*
mutation SwitchRoleMutation(
  $input: UserUpdateInput!
  $role_id: ID
) {
  userUpdate(input: $input) {
    user {
      homepage_path(role_id: $role_id)
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "role_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "role_id",
      "variableName": "role_id"
    }
  ],
  "kind": "ScalarField",
  "name": "homepage_path",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SwitchRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SwitchRoleMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "UserUpdatePayload",
        "kind": "LinkedField",
        "name": "userUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "871581b124f08e7bdd10d461ef1390ff",
    "id": null,
    "metadata": {},
    "name": "SwitchRoleMutation",
    "operationKind": "mutation",
    "text": "mutation SwitchRoleMutation(\n  $input: UserUpdateInput!\n  $role_id: ID\n) {\n  userUpdate(input: $input) {\n    user {\n      homepage_path(role_id: $role_id)\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'bd98ac9b6f2789330305c5765b66dab6';

module.exports = node;
