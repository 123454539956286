// @flow

export class ResponseError extends Error {
  response: Response

  constructor(response: Response, msg: string) {
    super(msg)

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ResponseError)
    }

    this.response = response
  }
}
