// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import classNames from 'classnames'
import { flow, get, map, omit, pick, slice } from 'lodash/fp'

import DataGridRowFooterContent, {
  DataGridRowFooterRenderer,
} from 'components/DataGridRowFooter'
import DataGrid, { TextCell } from 'react-ui/components/DataGrid'
import EscalationReasonCard from 'react-ui/components/EscalationReasonCard'
import { timeColumnConfig } from 'react-ui/components/Tables/columns'
import UserActivityCard from 'react-ui/components/UserActivityCard'

import { staffEscalationsTableDataGridQuery } from './query/StaffEscalationsTableDataGrid'

import type { StaffEscalationsTableDataGrid_escalations } from './query/__generated__/StaffEscalationsTableDataGrid_escalations.graphql'

const customStyle = () => ({
  DataGrid: {
    maxHeight: 'initial',
  },
  headerVisible: {
    '& div': {
      ':last-child': {
        borderColor: 'transparent',
      },
    },
  },
  body: {
    '& .TextCell': {
      paddingTop: 0,
      '&.actionCell': {
        display: 'flex',
        width: '100%',
        justifyContent: 'space-between',
      },
      '&.assignedStaff': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
      },
    },
    '& .BasicCellRenderer': {
      height: '80px',
      display: 'flex',
      alignItems: 'center',
      padding: '25px 16px',
      '&:first-child': {
        paddingLeft: '60px',
      },
      '& div': {
        padding: 0,
        width: '100%',
      },
    },
  },
  row: {
    maxHeight: '80px',
  },
  rowParentBorder: {
    position: 'relative',
  },
})

function columns(showResolvedDetails: boolean): Array<string> {
  return showResolvedDetails
    ? [
        'user',
        'resolving_resource',
        'reason',
        'resolving_role',
        'resolved_at',
        'primary_clinician',
        'actions',
      ]
    : ['user', 'created_at', 'primary_clinician', 'actions']
}

const columnWidth = (
  {
    isfirstColumn,
    isLastColumn,
  }: { isLastColumn?: boolean, isfirstColumn?: boolean },
  showResolvedDetails: boolean,
): string => {
  const columnsCount = showResolvedDetails ? 6 : 3
  const padding = -60 + 60 * columnsCount
  const lastColumnPadding = -60 - 40 * columnsCount
  const firstColumnWidth = `calc(calc(100% + ${padding}px)/${columnsCount})`
  const lastColumnWidth = `calc(calc(100% + ${lastColumnPadding}px)/${columnsCount})`
  const allColumnWidth = `calc(calc(100% - 60px)/${columnsCount})`

  if (isfirstColumn) return firstColumnWidth
  if (isLastColumn) return lastColumnWidth

  return allColumnWidth
}

const renderRow = (data, index, props, children, footer) => {
  const { componentId } = props
  const { id } = data

  // rowId is the className that is used for backend tests
  const rowId = componentId && id ? `${componentId}-${id}` : ''

  const newProps = omit(['componentId', 'border'], {
    ...props,
    className: classNames(props.className, rowId),
  })
  return (
    <div key={props.key} className={props.border}>
      <div {...newProps}>{children}</div>
      {footer && <DataGridRowFooterRenderer content={footer(data)} />}
    </div>
  )
}

const footer = ({ user }) => {
  return <DataGridRowFooterContent user={user} showFlag individual />
}

type PropsType = {
  escalations: StaffEscalationsTableDataGrid_escalations,
  showResolvedDetails: boolean,
}

const StaffEscalationsTableDataGridContainer = (props: PropsType) => {
  const { escalations, showResolvedDetails } = props

  const { t: translation } = useTranslation('staff')

  return (
    <DataGrid
      // $DisableFlow data is an array and should not cause an issue
      data={escalations}
      columns={columns(showResolvedDetails)}
      extend={customStyle}
      renderRow={renderRow}
      footer={footer}
      mapData={rowData => ({
        ...rowData,
        user: get('user')(rowData),
        resolving_resource: get('resolving_resource.label')(rowData),
        reason: pick(['resolved_at', 'resolution_option', 'resolution_text'])(
          rowData,
        ),
        resolving_role: get('resolving_role.user.name')(rowData),
        primary_clinician: get('role.clinicians')(rowData),
        actions: null,
      })}
      columnConfig={{
        user: {
          format: user => <UserActivityCard user={user} staff />,
          label: translation('patient_name'),
          sortable: false,
          width: columnWidth({ isfirstColumn: true }, showResolvedDetails),
        },
        label: {
          label: translation('health_card'),
          sortable: false,
          format: label => <TextCell>{label}</TextCell>,
          width: columnWidth({ isfirstColumn: false }, showResolvedDetails),
        },
        created_at: {
          ...timeColumnConfig(true),
          label: translation('date'),
          sortable: false,
          width: columnWidth({ isfirstColumn: false }, showResolvedDetails),
        },
        resolving_role: {
          label: translation('actioned_by'),
          sortable: false,
          format: resolving_role => <TextCell>{resolving_role}</TextCell>,
          width: columnWidth({ isfirstColumn: false }, showResolvedDetails),
        },
        resolving_resource: {
          label: translation('care_option_actioned'),
          sortable: false,
          format: resource => <TextCell>{resource}</TextCell>,
          width: columnWidth({ isfirstColumn: false }, showResolvedDetails),
        },
        reason: {
          label: translation('reason'),
          sortable: false,
          format: reason => (
            <TextCell>
              <EscalationReasonCard reason={reason} />
            </TextCell>
          ),
          width: columnWidth({ isfirstColumn: false }, showResolvedDetails),
        },
        resolved_at: {
          ...timeColumnConfig(true),
          label: translation('date'),
          sortable: false,
          width: columnWidth({ isfirstColumn: false }, showResolvedDetails),
        },
        primary_clinician: {
          label: translation('primary_clinician'),
          sortable: false,
          format: primary_clinician => (
            <TextCell className="assignedStaff">
              {primary_clinician.length <= 3 ? (
                map(({ user }) => (
                  <span key={`primary_clinician-${user.id}`}>
                    {user.legal_name}
                  </span>
                ))(primary_clinician)
              ) : (
                <>
                  {flow([
                    slice(0, 2),
                    map(({ user }) => (
                      <span key={`primary_clinician-${user.id}`}>
                        {user.legal_name}
                      </span>
                    )),
                  ])(primary_clinician)}
                  <span key="more">{`${translation(
                    'and',
                  )} ${primary_clinician.length - 2} ${translation(
                    'more',
                  )}`}</span>
                </>
              )}
            </TextCell>
          ),
          width: columnWidth({ isLastColumn: true }, showResolvedDetails),
        },
        actions: {
          width: '40px',
          label: ' ',
          sortable: false,
        },
      }}
    />
  )
}

// eslint-disable-next-line import/no-unused-modules
export const StaffEscalationsTableDataGrid = createFragmentContainer(
  StaffEscalationsTableDataGridContainer,
  staffEscalationsTableDataGridQuery,
)
