/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserSettingsPersonalDetailsEditPage_profileQuestionnaire$ref: FragmentReference;
declare export opaque type UserSettingsPersonalDetailsEditPage_profileQuestionnaire$fragmentType: UserSettingsPersonalDetailsEditPage_profileQuestionnaire$ref;
export type UserSettingsPersonalDetailsEditPage_profileQuestionnaire = {|
  +questions: $ReadOnlyArray<{|
    +name: string,
    +label: ?string,
    +title: string,
    +fieldType: string,
    +value: ?any,
    +other: ?string,
    +required: ?boolean,
    +options: ?$ReadOnlyArray<{|
      +label: string,
      +value: any,
    |}>,
    +otherEnabled: ?boolean,
    +validationName: ?string,
    +dependOn: ?any,
    +profile_type: string,
  |}>,
  +orders: $ReadOnlyArray<any>,
  +$refType: UserSettingsPersonalDetailsEditPage_profileQuestionnaire$ref,
|};
export type UserSettingsPersonalDetailsEditPage_profileQuestionnaire$data = UserSettingsPersonalDetailsEditPage_profileQuestionnaire;
export type UserSettingsPersonalDetailsEditPage_profileQuestionnaire$key = {
  +$data?: UserSettingsPersonalDetailsEditPage_profileQuestionnaire$data,
  +$fragmentRefs: UserSettingsPersonalDetailsEditPage_profileQuestionnaire$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserSettingsPersonalDetailsEditPage_profileQuestionnaire",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SimpleQuestion",
      "kind": "LinkedField",
      "name": "questions",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fieldType",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "other",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "required",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SimpleQuestionOption",
          "kind": "LinkedField",
          "name": "options",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "otherEnabled",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "validationName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "dependOn",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "profile_type",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "orders",
      "storageKey": null
    }
  ],
  "type": "SimpleQuestionnaire",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fe4281af58372b7ea3b67147d31fc4e4';

module.exports = node;
