/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireRefresh_questionnaire$ref: FragmentReference;
declare export opaque type QuestionnaireRefresh_questionnaire$fragmentType: QuestionnaireRefresh_questionnaire$ref;
export type QuestionnaireRefresh_questionnaire = {|
  +id: string,
  +$refType: QuestionnaireRefresh_questionnaire$ref,
|};
export type QuestionnaireRefresh_questionnaire$data = QuestionnaireRefresh_questionnaire;
export type QuestionnaireRefresh_questionnaire$key = {
  +$data?: QuestionnaireRefresh_questionnaire$data,
  +$fragmentRefs: QuestionnaireRefresh_questionnaire$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireRefresh_questionnaire",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "type": "Questionnaire",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '861d193d0ec444eb28574bd9d48b436d';

module.exports = node;
