// @flow

import React from 'react'

import { type TextFieldType, TextField } from './TextField'

export type NumberFieldType = TextFieldType

export const NumberField = (props: NumberFieldType) => (
  <TextField {...(props: any)} type="number" />
)
