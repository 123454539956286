// @flow

import idForModule from 'shared/services/id'

/**
 * Creates a logger based on the given filename.
 *
 * @param {string} filepath - A filepath to base the logger name on.
 */
export const createLogger = (filepath: string) => {
  const prefix = idForModule(filepath)
  return (...args: $ReadOnlyArray<any>) => {
    // eslint-disable-next-line no-console
    console.log(`${prefix} |`, ...args)
  }
}
