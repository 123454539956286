// @flow

/* eslint dot-notation: 0 */
import * as colors from './colors'

export type ComponentColorType = {
  +accent: string,
  +active: string,
  +base: string,
  +mutedActive: string,
  +mutedBase: string,
  +text: string,
}

export type ComponentColorsType = {|
  +danger: ComponentColorType,
  +disabled: ComponentColorType,
  +info: ComponentColorType,
  +muted: ComponentColorType,
  +primary: ComponentColorType,
  +secondary: ComponentColorType,
  +success: ComponentColorType,
  +warning: ComponentColorType,
|}

export type PaletteType = {
  +bodyBackgroundColor: string,
  +component: ComponentColorsType,
  +headingTextColor: string,
  +layoutDivider: string,
  [key: string]: any,
}

export default {
  bodyBackgroundColor: colors.white,
  bodyBackgroundBlue: colors.synBlue['500'],
  lightBackground: colors.greyscale['40'],
  dividerColor: colors.greyscale['50'],
  headingTextColor: colors.greyscale['800'],
  textColor: colors.greyscale['800'],
  layoutDivider: colors.greyscale['900'],
  subTextColor: colors.greyscale['200'],

  userSetting: {
    titleColor: colors.greyscale['300'],
  },
  input: {
    placeholder: colors.greyscale['500'],
    label: {
      accent: colors.greyscale['200'],
      base: colors.white,
      text: colors.greyscale['700'],
    },
    default: {
      accent: colors.greyscale['200'],
      base: colors.white,
      text: colors.greyscale['700'],
    },
    focus: {
      accent: colors.synBlue['500'],
    },
    disabled: {
      accent: colors.greyscale['100'],
      base: colors.greyscale['50'],
    },
    error: {
      accent: colors.alertRed['600'],
      base: colors.alertRed['50'],
      text: colors.greyscale['800'],
    },
    icon: {
      base: colors.synBlue['500'],
    },
  },

  component: {
    primary: {
      accent: colors.synBlue['600'],
      active: colors.synBlue['800'],
      base: colors.synBlue['500'],
      mutedBase: colors.synBlue['50'],
      mutedActive: colors.synBlue['100'],
      text: colors.white,
    },

    secondary: {
      accent: colors.synGreen['a300'],
      active: colors.synGreen['500'],
      base: colors.synGreen['a200'],
      mutedBase: colors.synBlue['50'],
      mutedActive: colors.synGreen['100'],
      text: colors.white,
    },

    info: {
      accent: colors.synBlue['a300'],
      active: colors.synBlue['600'],
      base: colors.synBlue['a200'],
      mutedBase: colors.synBlue['50'],
      mutedActive: colors.synBlue['100'],
      text: colors.white,
    },

    danger: {
      accent: colors.alertRed['900'],
      active: colors.alertRed['900'],
      base: colors.alertRed['800'],
      mutedBase: colors.alertRed['50'],
      mutedActive: colors.alertRed['100'],
      text: colors.white,
    },

    warning: {
      accent: colors.alertOrange['600'],
      active: colors.alertOrange['800'],
      base: colors.alertOrange['500'],
      mutedBase: colors.alertOrange['50'],
      mutedActive: colors.alertOrange['100'],
      text: colors.white,
    },

    success: {
      accent: colors.alertGreen['600'],
      active: colors.alertGreen['800'],
      base: colors.alertGreen['500'],
      mutedBase: colors.alertGreen['50'],
      mutedActive: colors.alertGreen['100'],
      text: colors.white,
    },

    muted: {
      accent: colors.greyscale['700'],
      active: colors.greyscale['900'],
      base: colors.greyscale['600'],
      mutedBase: colors.greyscale['200'],
      mutedActive: colors.greyscale['500'],
      text: colors.white,
    },
    disabled: {
      base: colors.greyscale['100'],
      text: colors.greyscale['600'],
    },
  },

  lineChart: ['#BF00B6', '#008479', '#982863'],

  dataGrid: {
    header: {
      // Here's a rouge color that's not named
      accent: '#A1ADBD',
      base: 'transparent',
      text: colors.greyscale['700'],
    },
    cell: {
      accent: colors.greyscale['100'],
      base: colors.white,
      text: colors.greyscale['700'],
    },
    footer: {
      accent: colors.greyscale['40'],
    },
  },

  dropDown: {
    caret: colors.greyscale['800'],
  },
  Header: {
    selectedColor: colors.black,
    notSelectedColor: colors.newGreen['100'],
    backgroundColor: colors.white,
  },

  link: {
    linkColor: colors.synBlue['500'],
    visitedColor: colors.synBlue['500'],
    focusColor: colors.synBlue['500'],
    hoverColor: colors.synBlue['500'],
    activeColor: colors.synBlue['500'],
  },

  reports: {
    // Bespoke colours for reports
    chartColors: [
      '#E0182D',
      '#E6CA17',
      '#991133',
      '#38B3CF',
      '#DB752C',
      '#A3CC3B',
      '#2D8A3A',
      '#A9A9A9',
      '#3B58BF',
      '#BF00B6',
      '#691682',
    ],
    valueColor: colors.greyscale['500'],
  },

  section: {
    default: {
      backgroundColor: colors.greyscale['50'],
    },
    grey: {
      backgroundColor: colors.greyscale['800'],
      color: colors.white,
      headingTextColor: colors.white,
      link: {
        linkColor: colors.synBlue['200'],
        visitedColor: colors.synBlue['200'],
        focusColor: colors.synBlue['200'],
        hoverColor: colors.synBlue['200'],
        activeColor: colors.synBlue['200'],
      },
    },
    primary: {
      backgroundColor: colors.synBlue['600'],
      color: colors.white,
      headingTextColor: colors.white,
      link: {
        linkColor: colors.white,
        visitedColor: colors.white,
        focusColor: colors.white,
        hoverColor: colors.white,
        activeColor: colors.white,
      },
    },
    white: {
      backgroundColor: colors.white,
    },
    lightGrey: {
      backgroundColor: colors.greyscale['30'],
      color: colors.greyscale['400'],
    },
  },

  tabs: {
    backgroundColor: colors.synBlue['700'],
  },

  themelet: {
    default: {
      backgroundColor: colors.greyscale['50'],
    },
    grey: {
      backgroundColor: colors.greyscale['800'],
      color: colors.white,
      headingTextColor: colors.white,
      link: {
        linkColor: colors.synBlue['200'],
        visitedColor: colors.synBlue['200'],
        focusColor: colors.synBlue['200'],
        hoverColor: colors.synBlue['200'],
        activeColor: colors.synBlue['200'],
      },
    },
    primary: {
      backgroundColor: colors.synBlue['600'],
      color: colors.white,
      headingTextColor: colors.white,
      link: {
        linkColor: colors.white,
        visitedColor: colors.white,
        focusColor: colors.white,
        hoverColor: colors.white,
        activeColor: colors.white,
      },
    },
    white: {
      backgroundColor: colors.white,
      color: colors.greyscale['800'],
      headingTextColor: colors.greyscale['800'],
      link: {
        linkColor: colors.synBlue['500'],
        visitedColor: colors.synBlue['500'],
        focusColor: colors.synBlue['500'],
        hoverColor: colors.synBlue['500'],
        activeColor: colors.synBlue['500'],
      },
    },
    lightGrey: {
      backgroundColor: colors.greyscale['30'],
      color: colors.greyscale['400'],
    },
  },
  toolTip: {
    backgroundColor: colors.white,
    color: colors.greyscale['900'],
    headingTextColor: colors.greyscale['900'],
    link: {
      linkColor: colors.greyscale['900'],
      visitedColor: colors.greyscale['900'],
      focusColor: colors.greyscale['900'],
      hoverColor: colors.greyscale['900'],
      activeColor: colors.greyscale['900'],
    },
  },
  questionnaireCard: {
    alertGreen: colors.alertGreen['600'],
    gray: colors.greyscale['500'],
  },
  skippedQuestions: {
    percentage: '#6B1A84',
  },
  skippableProgress: {
    done: colors.newGreen['200'],
    skipped: colors.synBlue['a50'],
    hoverBackground: colors.synBlue['25'],
  },
  summaryGraphs: {
    backgroundColor: colors.synBlue['900'],
    textColor: colors.synBlue['900'],
    borderColor: colors.synBlue['900'],
    notificationColor: '#FC3530',
    highlightColor: 'rgba(192, 233, 242, 0.25)',
  },
}
