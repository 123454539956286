/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ActivityLogCardContainer_user$ref = any;
export type ActivityLogCardContainerQueryVariables = {|
  id: string,
  count: number,
  cursor?: ?string,
|};
export type ActivityLogCardContainerQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: ActivityLogCardContainer_user$ref
  |}
|};
export type ActivityLogCardContainerQuery = {|
  variables: ActivityLogCardContainerQueryVariables,
  response: ActivityLogCardContainerQueryResponse,
|};
*/


/*
query ActivityLogCardContainerQuery(
  $id: ID!
  $count: Int!
  $cursor: String
) {
  node(id: $id) {
    __typename
    ... on User {
      ...ActivityLogCardContainer_user_1G22uz
    }
    id
  }
}

fragment ActivityLogCardContainer_user_1G22uz on User {
  id
  linked_individual {
    activity_logs(first: $count, after: $cursor) {
      edges {
        node {
          ...ActivityLogCard_activityLog
          __typename
        }
        cursor
      }
      pageInfo {
        endCursor
        hasNextPage
      }
    }
    id
  }
}

fragment ActivityLogCard_activityLog on ActivityLog {
  display_text
  occured_at
  ...ActivityLogChangeSet_activityLog
}

fragment ActivityLogChangeSet_activityLog on ActivityLog {
  action_type
  changeset
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ActivityLogCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "args": [
                  {
                    "kind": "Variable",
                    "name": "count",
                    "variableName": "count"
                  },
                  {
                    "kind": "Variable",
                    "name": "cursor",
                    "variableName": "cursor"
                  }
                ],
                "kind": "FragmentSpread",
                "name": "ActivityLogCardContainer_user"
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "ActivityLogCardContainerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v4/*: any*/),
          (v5/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "linked_individual",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "concreteType": "ActivityLogConnection",
                    "kind": "LinkedField",
                    "name": "activity_logs",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ActivityLogEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ActivityLog",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "display_text",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "occured_at",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "action_type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "changeset",
                                "storageKey": null
                              },
                              (v4/*: any*/)
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": (v6/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "ActivityLogCardContainer_activity_logs",
                    "kind": "LinkedHandle",
                    "name": "activity_logs"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ad444505ec0d286bca64086846ae470d",
    "id": null,
    "metadata": {},
    "name": "ActivityLogCardContainerQuery",
    "operationKind": "query",
    "text": "query ActivityLogCardContainerQuery(\n  $id: ID!\n  $count: Int!\n  $cursor: String\n) {\n  node(id: $id) {\n    __typename\n    ... on User {\n      ...ActivityLogCardContainer_user_1G22uz\n    }\n    id\n  }\n}\n\nfragment ActivityLogCardContainer_user_1G22uz on User {\n  id\n  linked_individual {\n    activity_logs(first: $count, after: $cursor) {\n      edges {\n        node {\n          ...ActivityLogCard_activityLog\n          __typename\n        }\n        cursor\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n      }\n    }\n    id\n  }\n}\n\nfragment ActivityLogCard_activityLog on ActivityLog {\n  display_text\n  occured_at\n  ...ActivityLogChangeSet_activityLog\n}\n\nfragment ActivityLogChangeSet_activityLog on ActivityLog {\n  action_type\n  changeset\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '908b00f25b4cf2961135844631a24e7b';

module.exports = node;
