// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'
import classNames from 'classnames'

import { type FelaStyleRulesType } from 'react-ui/typing'

export type ColumnStyleProps = {
  children?: Node,
  col?: number | 'auto',
  extend?: (props: any) => Object,
  testId?: string,
}

type PropsType = ColumnStyleProps & FelaStyleRulesType

const styleRules = ({ col, theme, ...props }: PropsType) => {
  // Build up an object of styles grouped by media queries.
  // eg.
  // {
  //  '@media screen and (min-width: 481px)': {
  //      content: 'sm and up'
  //   }
  //  '@media screen and (min-width: 800px)': {
  //      content: 'md and up'
  //   }
  // }
  const queries = Object.keys(theme.Grid.queries)

  // Look for column spans defined by media query props
  // (e.g. <Column xs={12} lg={4} />)
  const responsiveProps = queries
    .filter(size => !!props[size])
    .reduce((memo, size) => {
      const query = theme.Grid.queries[size]

      // Add the dimensions rules / number of columns
      const prop = props[size]
      if (prop) {
        const percentage =
          typeof prop === 'number'
            ? `${prop / theme.Grid.columns * 100}%`
            : 'auto'
        memo[query] = {
          flexBasis: percentage,
          flexShrink: 0,
          width: percentage,
        }
      }
      return memo
    }, {})

  return {
    boxSizing: 'border-box',
    height: '100%',
    className: classNames('Column', {
      [`--col-${String(col)}`]: !!col,
      ...queries.reduce((memo, size) => {
        if (typeof props[size] !== 'undefined') {
          memo[`--${size}-${props[size]}`] = true
        }
        return memo
      }, {}),
    }).replace(/\s/g, ''),
    display: 'flex',
    flexBasis:
      col && typeof col === 'number'
        ? `${col / theme.Grid.columns * 100}%`
        : 'auto',
    flexDirection: 'column',
    flexGrow: 0,
    flexShrink: 1,
    flexWrap: 'wrap',
    outline: theme.Grid.outline ? '1px dotted red' : 'none',
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    width:
      col && typeof col === 'number'
        ? `${col / theme.Grid.columns * 100}%`
        : 'auto',
    ...responsiveProps,
  }
}

const Column = (props: ColumnStyleProps) => {
  const { children, extend, testId, ...rest } = props
  const { css } = useFela(rest)

  return (
    <div className={css(styleRules, extend)} data-testid={testId}>
      {children}
    </div>
  )
}

export default Column
