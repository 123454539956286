// @flow

import React, { type Node } from 'react'
import { useFela } from 'react-fela'
import { graphql } from 'react-relay'
import { sortBy } from 'lodash'

import { Container } from 'react-ui/components/Grid'
import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import { PaginationQueryLoader } from 'react-ui/components/Pagination/PaginationQueryLoader'
import PopOver, { PopOverDoneFooter } from 'react-ui/components/PopOver'

import { AssignmentCheckbox } from './AssignmentCheckbox'
import { AssignmentsSearchControl } from './AssignmentsSearchControl'

const containerStyle = () => ({
  container: {
    width: '450px',
    ':before': {
      display: 'none',
    },
  },
  tether: {
    minHeight: '400px',
  },
  scrollPane: {
    maxHeight: 'auto',
    overflowY: 'hidden',
  },
})

const listContainerStyle = () => ({
  maxHeight: '346px',
  overflowY: 'auto',
  marginTop: '5px',
})

const DEFAULT_COUNT = 25

const assignableIndividualsQuery = graphql`
  query IndividualAssignmentsPopover_Query(
    $clinician_id: ID!
    $count: Int!
    $cursor: String
    $search: String
  ) {
    viewer {
      role(id: $clinician_id) {
        ... on ClinicianRole {
          tenant {
            ...IndividualAssignmentsPopover_tenant
              @arguments(
                clinician_id: $clinician_id
                count: $count
                cursor: $cursor
                search: $search
              )
          }
        }
      }
    }
  }
`

const assignableIndividualsFragment = {
  tenant: graphql`
    fragment IndividualAssignmentsPopover_tenant on Tenant
      @argumentDefinitions(
        clinician_id: { type: "ID!" }
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        search: { type: "String" }
      ) {
      assignable_individuals(
        first: $count
        after: $cursor
        search: $search
        clinician_id: $clinician_id
      )
        @connection(
          key: "IndividualAssignmentsPopover_assignable_individuals"
          filters: []
        ) {
        edges {
          node {
            id
            assigned_to_clinician(clinician_id: $clinician_id)
            user {
              ...UserCheckBoxLoader_user
            }
          }
        }
      }
    }
  `,
}

type IndividualAssignmentsPopoverPropType = {
  clinician: { +assignable: boolean, +id: string },
  per?: number,
  renderTrigger: (...args: any[]) => Node,
}

export const IndividualAssignmentsPopover = ({
  clinician,
  renderTrigger,
  per = DEFAULT_COUNT,
}: IndividualAssignmentsPopoverPropType) => {
  const { css } = useFela()

  return (
    <PopOver
      attachment="middle center"
      targetModifier="visible"
      target={document.body}
      extend={containerStyle}
      overlay
      renderTrigger={renderTrigger}
      renderFooter={({ onToggleOpen }) => (
        <PopOverDoneFooter
          onToggleOpen={onToggleOpen}
          label={clinician.assignable ? undefined : 'Close'}
        />
      )}
    >
      <PaginationQueryLoader
        query={assignableIndividualsQuery}
        fragments={assignableIndividualsFragment}
        variables={{ clinician_id: clinician.id, search: '', count: per }}
        controls={AssignmentsSearchControl}
        getFragments={data => data.viewer.role}
      >
        {({ relay, tenant: { assignable_individuals: { edges } } }) => {
          const data = sortBy(
            edges.map(({ node }) => node),
            ({ assigned_to_clinician }) => !assigned_to_clinician,
          )
          return (
            <Container className={css(listContainerStyle)}>
              {data.map(individual => (
                <AssignmentCheckbox
                  key={individual.id}
                  clinician={clinician}
                  individual={individual}
                  assigned={individual.assigned_to_clinician}
                  user={individual.user}
                  assignable={clinician.assignable}
                />
              ))}
              {relay.hasMore() && (
                <LoadMore handleClick={() => relay.loadMore(per)} />
              )}
            </Container>
          )
        }}
      </PaginationQueryLoader>
    </PopOver>
  )
}
