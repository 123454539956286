// @flow

import { includes, isEmpty, reject } from 'lodash/fp'

import { compact } from 'shared/utils/ObjectUtils'

export const normalizeClinicianValues = ({
  full_time_equivalent_ratio,
  primary_language,
  is_language_different,
  main_care_language,
  profession,
  same_profession_in_service,
  place_in_service,
  ...rest
}: {
  full_time_equivalent_ratio?: string,
  is_language_different?: string,
  main_care_language?: string,
  place_in_service?: string,
  primary_language?: string,
  profession?: string,
  same_profession_in_service?: string,
}) =>
  compact({
    ...(rest: any),
    primary_language,
    main_care_language:
      // NOTE: only assign language when is_language_different is set
      (is_language_different === 'no' && primary_language) ||
      main_care_language,
    profession,
    place_in_service:
      // NOTE: only assign profession when same_profession_in_service is set
      (same_profession_in_service === 'no' && place_in_service) ||
      (same_profession_in_service === 'yes' ? '' : undefined),
    full_time_equivalent_ratio:
      (full_time_equivalent_ratio && parseFloat(full_time_equivalent_ratio)) ||
      undefined,
  })

export const normalizeIndividualPersonalDetailValues = ({
  primary_language_initial,
  primary_language,
  has_children,
  no_of_children,
  no_of_children_initial,
  indigenous_status_initial,
  indigenous_status,
  suburb: rawSuburb,
  ...rest
}: {
  has_children?: string,
  indigenous_status?: string,
  indigenous_status_initial?: string,
  no_of_children?: string,
  no_of_children_initial?: string,
  primary_language?: string,
  primary_language_initial?: string,
  suburb?: string,
}) => {
  const [suburb, state] = reject(isEmpty)(
    (rawSuburb || '')
      .replace(/\s+/g, ' ')
      .trim()
      .split(/\s*,\s+/),
  )
  return normalizeClinicianValues({
    ...rest,
    suburb,
    state,
    indigenous_status:
      (includes(indigenous_status_initial)([
        'neither',
        'prefer_not_to_answer',
      ]) &&
        indigenous_status_initial) ||
      indigenous_status,
    has_children: has_children || no_of_children_initial,
    no_of_children: parseInt(
      no_of_children_initial === 'yes' && no_of_children,
      10,
    ),
    primary_language:
      (includes(primary_language_initial)([
        'english',
        'prefer_not_to_answer',
      ]) &&
        primary_language_initial) ||
      primary_language,
  })
}
