/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffIndividualsList_clinician$ref: FragmentReference;
declare export opaque type StaffIndividualsList_clinician$fragmentType: StaffIndividualsList_clinician$ref;
export type StaffIndividualsList_clinician = {|
  +id: string,
  +assignable: boolean,
  +clinician_assignments_as_clinician: $ReadOnlyArray<{|
    +id: string
  |}>,
  +$refType: StaffIndividualsList_clinician$ref,
|};
export type StaffIndividualsList_clinician$data = StaffIndividualsList_clinician;
export type StaffIndividualsList_clinician$key = {
  +$data?: StaffIndividualsList_clinician$data,
  +$fragmentRefs: StaffIndividualsList_clinician$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaffIndividualsList_clinician",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "assignable",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianAssignment",
      "kind": "LinkedField",
      "name": "clinician_assignments_as_clinician",
      "plural": true,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "type": "ClinicianRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b454f1f8decbd7bd07557645edbddef9';

module.exports = node;
