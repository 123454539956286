// @flow

import merge from 'lodash/fp/merge'

import * as breakpoints from './breakpoints'
import * as colors from './colors'
import defaultEasing from './easing'
import mediaQueries from './media-queries'
import defaultPalette, {
  type ComponentColorsType,
  type PaletteType,
} from './palette'

export type ThemeType = {
  Button: {
    +ghostActiveBg: string,
    +ghostActiveText: string,
    +invertBorder: string,
    ...ComponentColorsType,
  },
  Grid: {
    +columns: number,
    +containerMaxWidth: number,
    +gutter: string,
    +outline: boolean,
    +queries: breakpoints.BreakpointsType,
  },
  name: string,
  palette: PaletteType,
  spacing: number => string,
}

export type CreateThemeOptions = {
  easing?: *,
  name: string,
  palette?: PaletteType,
}

const borderStyle = `2px solid ${colors.greyscale['200']}`

// TODO: Group font Sizes into a single Font Object
// plus: -- this fonts rules should be in PX
//       -- em relay to the component that we are trying to override
const fontStyle = {
  fontSize: '1em',
  lineHeight: '1.35',
}

const smallFontStyle = {
  fontSize: '.9em',
  lineHeight: '1.1',
}

const tinyFontStyle = {
  fontSize: '.7em',
  lineHeight: '1',
}

export default function createTheme({
  name,
  palette = defaultPalette,
  easing = defaultEasing,
}: CreateThemeOptions) {
  return {
    name,
    component: {
      borderRadius: 5,
      horizontalPadding: 16,
      borderColor: colors.greyscale['100'],
      lightGrayBg: colors.greyscale['100'],
    },
    Avatar: {
      backgroundColor: colors.greyscale['100'],
      invertColor: palette.bodyBackgroundColor,
    },
    Button: merge(palette.component, {
      ghostActiveBg: palette.component.primary.accent,
      ghostActiveText: palette.component.primary.text,
      invertBorder: 'rgba(255, 255, 255, 0.8)',
    }),
    DataGrid: palette.dataGrid,
    Filter: { backgroundColor: palette.bodyBackgroundColor },
    Page: {
      backgroundColor: palette.bodyBackgroundColor,
      backgroundInverted: colors.synBlue['500'],
    },
    Field: { error: { text: colors.alertRed['800'] } },
    Font: fontStyle,
    Small: smallFontStyle,
    Tiny: tinyFontStyle,
    Heading: { color: palette.headingTextColor },
    Input: merge(palette.input, {
      baseHeight: '2.3333rem',
    }),
    DataGridWrapperDefault: {
      backgroundColor: palette.section.default.backgroundColor,
    },
    Grid: {
      columns: 12,
      containerMaxWidth: 1200,
      gutter: '16px',
      outline: false,
      queries: breakpoints.queries,
    },
    Header: {
      color: palette.Header.selectedColor,
      backgroundColor: palette.bodyBackgroundColor,
      minHeight: '82px',
      display: 'flex',
      alignItems: 'center',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: '22px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: 'medium',
      letterSpacing: '0px',
    },
    NavigationTab: {
      backgroundColor: palette.bodyBackgroundColor,
      borderBottom: 'none',
      borderLeft: 'none',
      borderRight: 'none',
      borderStyle: 'solid',
      borderTop: 'none',
      color: palette.Header.notSelectedColor,
      fontSize: '22px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: 'medium',
      height: '22px',
      lineHeight: '22px',
      paddingRight: '20px',
      verticalAlign: 'middle',
      ':hover': {
        color: palette.Header.selectedColor,
        backgroundColor: palette.bodyBackgroundColor,
      },
      ':active': {
        backgroundColor: 'transparent',
        color: '#000',
      },
      ':not(:last-child)': {
        borderRight: '2px solid black',
      },
      ':not(:first-child)': {
        paddingLeft: '20px',
      },
      ':first-child': {
        paddingLeft: 0,
      },
    },
    NavigationTabSelected: {
      color: palette.Header.selectedColor,
      backgroundColor: palette.bodyBackgroundColor,
      lineHeight: '22px',
      height: '22px',
      verticalAlign: 'middle',
      borderStyle: 'solid',
      borderRight: 'none',
      borderBottom: 'none',
      borderLeft: 'none',
      borderTop: 'none',
      paddingRight: '20px',
      fontSize: '22px',
      fontStyle: 'normal',
      fontVariant: 'normal',
      fontWeight: 'medium',
      ':first-child': {
        paddingLeft: 0,
      },
    },
    NavigationTabWrapper: {
      color: palette.Header.selectedColor,
      backgroundColor: palette.bodyBackgroundColor,
      display: 'block',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
      paddingLeft: '14px',
      height: '82px',
      lineHeight: '82px',
      letterSpacing: '0.19px',
      listStyleType: 'none',
    },
    SectionButton: {
      backgroundColor: 'white',
      color: 'black',
      border: '1px solid #707070 !important',
      borderRadius: '50px',
      height: '38px',
      font: 'normal normal medium 14px/19px',
      fontSize: '14px',
      ':hover': {
        backgroundColor: 'black !important',
        color: 'white !important',
      },
    },
    ToggleButton: {
      ...palette.input,
      checked: palette.component.primary,
      timingFunction: easing.IN_OUT_CIRC,
    },
    TrendIndicator: {
      colors: {
        deterioration: colors.alertRed['700'],
        improvement: colors.alertGreen['500'],
      },
    },
    Form: {
      ...fontStyle,
      backgroundColor: colors.white,
      border: borderStyle,
      color: colors.greyscale['700'],
      height: '42px',
      padding: '10px',
      width: '100%',
    },
    breakpoints,
    bodyBackgroundColor: palette.bodyBackgroundColor,
    maxTextWidth: '568px',
    userQuestMaxWidth: '680px',
    mq: mediaQueries,
    palette,
    spacing: (units: number) => {
      const unitSize = 28
      return `${unitSize * units}px`
    },
    getReportColour: (idx: number): string =>
      palette.reports.chartColors[idx % palette.reports.chartColors.length],
    clearfix: {
      '&:after': { clear: 'both', content: '" "', display: 'table' },
      '&:before': { content: '" "', display: 'table' },
    },
  }
}
