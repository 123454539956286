// @flow

import React, { type Node, Component } from 'react'

import Radio from './Radio'

import s from './RadioSet.scss'

export type UiStyleProps = {
  uiStyle: 'primary' | 'secondary' | 'plain' | 'danger',
}

type PropsType = UiStyleProps & {
  disabled?: boolean,
  kind?: string,
  label?: string,
  name: string,
  onBlur?: Function,
  onChange: Function,
  onClick?: Function,
  options: Array<{
    title: Node,
    value: string | number | boolean,
  }>,
  value?: string | number | boolean,
}

class RadioSet extends Component<PropsType> {
  renderOptions = () => {
    const {
      disabled,
      kind,
      name,
      onChange,
      onBlur,
      onClick,
      options,
      uiStyle,
      value: currentValue,
    } = this.props

    const radioOptions: Array<Node> = options.map(({ value, title }) => (
      <Radio
        checked={currentValue === value}
        key={String(value)}
        kind={kind}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        onClick={onClick}
        uiStyle={uiStyle}
        value={value}
        disabled={disabled || false}
      >
        {title}
      </Radio>
    ))

    return radioOptions
  }

  render() {
    const { label } = this.props
    return (
      <div className={s.container}>
        {label}
        <div className={s.options}>{this.renderOptions()}</div>
      </div>
    )
  }
}

export default RadioSet
