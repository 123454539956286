// @flow

import React, { type Node, Component } from 'react'

import s from './VerticalFlex.scss'

type PropsType = {
  children: Node,
}
type StateType = {
  minHeight: number,
}

const calcContainerHeight = (elem: HTMLElement) =>
  Math.ceil(window.innerHeight - elem.getBoundingClientRect().top)

class VerticalFlex extends Component<PropsType, StateType> {
  container: ?HTMLDivElement

  state = {
    minHeight: 0,
  }

  componentWillMount() {
    window.addEventListener('resize', this.resize)
  }

  componentDidMount() {
    this.resize()
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize)
  }

  resize = () => {
    const minHeight = this.container ? calcContainerHeight(this.container) : 0

    this.setState({ minHeight })
  }

  render() {
    const { props: { children }, state: { minHeight } } = this
    return (
      <div
        className={s.verticalFlexContainer}
        ref={container => {
          this.container = container
        }}
        style={{ minHeight }}
      >
        {children}
      </div>
    )
  }
}

export default VerticalFlex
