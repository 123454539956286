// @flow

import React, { type ComponentType } from 'react'
import { useRelayEnvironment } from 'react-relay'

export type withRelayEnvironmentPropsType = {
  environment: Object,
}

const withRelayEnvironment = (Component: ComponentType<any>) => (props: *) => {
  const environment = useRelayEnvironment()

  return <Component {...(props: any)} environment={environment} />
}

export default withRelayEnvironment
