/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffDashboardSelection_clinician$ref: FragmentReference;
declare export opaque type StaffDashboardSelection_clinician$fragmentType: StaffDashboardSelection_clinician$ref;
export type StaffDashboardSelection_clinician = {|
  +id: string,
  +tab_options: $ReadOnlyArray<{|
    +id: string,
    +label: string,
    +value: string,
  |}>,
  +initial_state: string,
  +tenant: ?{|
    +stratified_scoring: boolean,
    +id: string,
  |},
  +current_role: {|
    +parent?: ?{|
      +id?: string
    |}
  |},
  +$refType: StaffDashboardSelection_clinician$ref,
|};
export type StaffDashboardSelection_clinician$data = StaffDashboardSelection_clinician;
export type StaffDashboardSelection_clinician$key = {
  +$data?: StaffDashboardSelection_clinician$data,
  +$fragmentRefs: StaffDashboardSelection_clinician$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaffDashboardSelection_clinician",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TabOption",
      "kind": "LinkedField",
      "name": "tab_options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initial_state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stratified_scoring",
          "storageKey": null
        },
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "parent",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "type": "RoleParentInterface",
                  "abstractKey": "__isRoleParentInterface"
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '14d5a421c90ee866e2d6f6337ab9a955';

module.exports = node;
