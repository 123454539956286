// @flow

import React, { Fragment, useEffect, useRef } from 'react'
import { useRouter } from 'found'
import { map } from 'lodash/fp'

import Accordion, { AccordionItem } from 'react-ui/components/Accordion'
import {
  type QuestionType,
  DataSecurityQuestions,
  GeneralQuestions,
  IndividualQuestions,
  InnowellFeaturesQuestions,
  InnowellPeopleQuestions,
  TechnologyQuestions,
} from 'react-ui/components/FrequentlyAskedQuestions/questions'
import { Heading, Text } from 'care-ui'

type SectionType = {
  questions: $ReadOnlyArray<QuestionType>,
  title: string,
}

const sections = [
  {
    title: 'About Innowell',
    questions: GeneralQuestions,
  },
  {
    title: 'Individual',
    questions: IndividualQuestions,
  },
  {
    title: 'Technology',
    questions: TechnologyQuestions,
  },
  {
    title: 'Innowell Features',
    questions: InnowellFeaturesQuestions,
  },
  {
    title: 'Data and Security',
    questions: DataSecurityQuestions,
  },
  {
    title: 'Innowell People',
    questions: InnowellPeopleQuestions,
  },
]

const FrequentlyAskedQuestionsPage = () => {
  const ref = useRef(null)
  const { match } = useRouter()
  const { location: { query } } = match
  const { id } = query

  useEffect(() => {
    if (ref.current) {
      ref.current.scrollIntoView({ block: 'center' })
    }
  })

  const renderQuestion = ({ answer, key, question }: QuestionType) => {
    const shouldFocus = key === id
    const refProp = shouldFocus ? { ref } : {}

    return (
      <AccordionItem key={key} heading={question} shouldOpen={shouldFocus}>
        <span {...refProp}>
          <Text size="lg">{answer}</Text>
        </span>
      </AccordionItem>
    )
  }

  const renderSection = ({ title, questions }: SectionType) => {
    if (questions.length <= 0) {
      return null
    }
    return (
      <section key={title}>
        <Heading level={2} margin="xs">
          {title}
        </Heading>
        <Accordion>{map(renderQuestion)(questions)}</Accordion>
      </section>
    )
  }

  return (
    <Fragment>
      <Heading level={1} margin="xs">
        Frequently Asked Questions
      </Heading>
      {map(renderSection, sections)}
    </Fragment>
  )
}

export default FrequentlyAskedQuestionsPage
