// @flow

import { useState } from 'react'

export type Tab = {
  label: string,
  value: string,
}

export type UseTabsReturnType = {
  selectedTab: string,
  setSelectedTab: (selectedTab: string) => void,
  tabOptions: Array<Tab>,
}

const useTabs = (
  defaultTab: string,
  tabOptions: Array<Tab>,
): UseTabsReturnType => {
  const [selectedTab, setSelectedTab] = useState(defaultTab)

  return {
    selectedTab,
    setSelectedTab,
    tabOptions,
  }
}

export default useTabs
