/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClinicianDropdown_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type CommonTasksButtons_user$ref: FragmentReference;
declare export opaque type CommonTasksButtons_user$fragmentType: CommonTasksButtons_user$ref;
export type CommonTasksButtons_user = {|
  +$fragmentRefs: ClinicianDropdown_user$ref,
  +$refType: CommonTasksButtons_user$ref,
|};
export type CommonTasksButtons_user$data = CommonTasksButtons_user;
export type CommonTasksButtons_user$key = {
  +$data?: CommonTasksButtons_user$data,
  +$fragmentRefs: CommonTasksButtons_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CommonTasksButtons_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClinicianDropdown_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4fbacba124e78677fcfcec2e5ee5440e';

module.exports = node;
