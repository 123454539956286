// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { ButtonGroup } from 'react-ui/components/Button'
import { Button } from 'care-ui'

import FieldsForAllUsers from './FieldsForAllUsers'
import FieldsForIndividuals from './FieldsForIndividuals'
import {
  formStyle,
  inputStyle,
  submitButtonStyle,
} from './UserInviteForm.style'
import getUserInviteFormValidations from './UserInviteFormValidations'

import type { FelaRulesType, FelaStylesType } from 'react-ui/typing'
import { type FormikProps } from 'services/flow'
import type { UserInviteForm_tenantGroups } from './__generated__/UserInviteForm_tenantGroups.graphql'
import type { UserInviteForm_tenants } from './__generated__/UserInviteForm_tenants.graphql'

export type ValuesType = {
  +clinicianAssignmentId: ?string,
  +email: string,
  +emrUserId?: string,
  +phone_number?: string,
  +roleType: string,
  +tenantGroupId: string,
  +tenantId: string,
}

type EmrIntegrationProps = {
  required: boolean,
  userIdFieldName: string,
}

type PropsType = FormikProps & {
  emrIntegration: ?EmrIntegrationProps,
  globalInvitableRoles: Array<string>,
  globalOnlyInvitableRoles: Array<string>,
  invitableMultiTenantRoleTypes: Array<string>,
  invitableRoleTypes: Array<string>,
  invitableUser: {
    clinicianAssignmentId?: ?string,
    email: string,
    emrUserId?: string,
    inviteType?: string,
    phone_number?: string,
    roleType: string,
    tenantGroupId?: string,
    tenantId: string,
  },
  onSubmit: (values: ValuesType) => void,
  rules: FelaRulesType,
  styles: FelaStylesType,
  tenantGroups?: UserInviteForm_tenantGroups,
  tenants?: UserInviteForm_tenants,
}

const UserInviteForm = (props: PropsType) => {
  const { css, theme } = useFela()
  const { t: translation } = useTranslation(['validations', 'staff'])

  const formik = useFormik({
    initialValues: {
      inviteType: props.invitableUser.inviteType || 'EMAIL',
      email: props.invitableUser.email,
      roleType: props.invitableUser.roleType,
      tenantId: props.invitableUser.tenantId,
      tenantGroupId: props.invitableUser.tenantGroupId,
      emrUserId: props.invitableUser.emrUserId,
      phone_number: props.invitableUser.phone_number,
      clinicianAssignmentId: props.invitableUser.clinicianAssignmentId,
    },
    validationSchema: yup
      .object()
      .shape(getUserInviteFormValidations(props, translation)),
    onSubmit: (values, { resetForm }) => {
      props.onSubmit(values)
      resetForm()
    },
    validateOnChange: false,
    validateOnBlur: true,
  })

  const inputProps = id => ({
    id,
    onBlur: formik.handleBlur,
    onChange: formik.handleChange,
    value: formik.values[id],
    extend: inputStyle,
    error: formik.touched[id] && formik.errors[id],
  })

  const isDisabled = !formik.dirty || !formik.isValid || formik.isSubmitting

  return (
    <form onSubmit={formik.handleSubmit} noValidate className={css(formStyle)}>
      <FieldsForAllUsers
        inputProps={inputProps}
        formProps={{ ...props, ...formik }}
        translation={translation}
      />

      {formik.values.roleType === 'INDIVIDUAL' && (
        <FieldsForIndividuals
          inputProps={inputProps}
          formProps={{ ...props, ...formik }}
          translation={translation}
        />
      )}

      <ButtonGroup
        buttonsPosition="end"
        extend={{ ButtonGroup: submitButtonStyle({ theme }) }}
      >
        <Button
          type="submit"
          disabled={isDisabled}
          variant="primary"
          ariaLabel={translation('invite')}
          dataTestId="invite"
        >
          {translation('invite', { ns: 'staff' })}
        </Button>
      </ButtonGroup>
    </form>
  )
}

export const UserInviteFormLoader = createFragmentContainer(UserInviteForm, {
  tenants: graphql`
    fragment UserInviteForm_tenants on Tenant @relay(plural: true) {
      id
      name
      deactivated
      emr_provider_id
      emr_integration {
        emr_provider {
          name
          user_id_field_name
        }
        required
      }
      allowAdminClinicalAssignments
    }
  `,
  tenantGroups: graphql`
    fragment UserInviteForm_tenantGroups on TenantGroup @relay(plural: true) {
      id
      label
    }
  `,
})
