// @flow

import { omit } from 'lodash/fp'

export const prepareUpdatePayload = (
  model: string,
  payload: { +id: string },
): { +id: string } => ({
  id: payload.id,
  [model]: omit(['id'])(payload[model]),
})
