// @flow

import React, { type Node, Fragment } from 'react'
import { connect } from 'react-fela'
import cx from 'classnames'

import imgCarpet from 'react-ui/assets/backgrounds/carpet.png'
import imgCloud from 'react-ui/assets/backgrounds/cloud.png'
import imgLeavesHorizontal from 'react-ui/assets/backgrounds/leave_horizontal.png'

type PropsType = {
  backgroundElements?: string[],
  backgroundName?: string,
  children?: Node,
  className?: string,
  styles?: { [className: string]: string },
}

const styleRules = ({ theme }, renderer) => {
  const moveX = renderer.renderKeyframe(() => ({
    '0%': { transform: 'translateX(-100vh)' },
    '100%': { transform: 'translateX(0)' },
  }))

  const cloudStyles = {
    position: 'absolute',
    backgroundImage: `url(${imgCloud})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'contain',

    animationName: moveX,
    animationDuration: '5s',
    animationRepeat: 'infinite',
    animationFillMode: 'both',

    '@media (max-width: 500px)': {
      display: 'none',
    },
  }

  return {
    container: {
      position: 'relative',
      minHeight: '100vh',
      overflow: 'hidden',
    },

    primary: {
      backgroundImage: `url(${imgCarpet})`,
      backgroundColor: theme.palette.component.primary.base,
      backgroundRepeat: 'repeat',
      backgroundBlendMode: 'screen',
      backgroundSize: '400px',
    },

    leavesHorizontal: {
      position: 'absolute',
      bottom: 0,
      width: '100%',
      height: '300px',
      backgroundImage: `url(${imgLeavesHorizontal})`,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
      backgroundSize: 'contain',
      transform: 'translateY(50%)',
    },

    // ------------ Clouds ------------
    // Display three clouds by default
    cloud1: {
      top: '37%',
      right: '-37px',
      width: '348px',
      height: '54px',
      ...cloudStyles,
    },

    cloud2: {
      top: '48%',
      left: '136px',
      width: '207px',
      height: '31px',
      ...cloudStyles,
    },

    cloud3: {
      top: '70%',
      left: '-27px',
      width: '294px',
      height: '45px',
      transform: 'scaleX(-1)',
      ...cloudStyles,
    },

    // Display just one cloud
    cloudX1: {
      top: '55%',
      left: '-47px',
      width: '207px',
      height: '31px',
      ...cloudStyles,
    },
    // ------------ end Clouds ------------
  }
}

const Background = ({
  className = '',
  backgroundName = 'primary',
  backgroundElements = [],
  styles = {},
  children,
}: PropsType) => (
  <div className={cx(styles.container, styles[backgroundName], className)}>
    {backgroundElements.length > 0 &&
      backgroundElements.map(el => {
        if (el === 'clouds') {
          return (
            <Fragment key={el}>
              <div className={styles.cloud1} />
              <div className={styles.cloud2} />
              <div className={styles.cloud3} />
            </Fragment>
          )
        }
        if (el === 'cloudX1') {
          return (
            <div key={el} className={styles.clouds}>
              <div className={styles[el]} />
            </div>
          )
        }
        return styles[el] && <div key={el} className={styles[el]} />
      })}
    {children}
  </div>
)

export default connect(styleRules)(Background)
