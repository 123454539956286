/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ResearchDataSharingPage_user_research_programs_responses$ref: FragmentReference;
declare export opaque type ResearchDataSharingPage_user_research_programs_responses$fragmentType: ResearchDataSharingPage_user_research_programs_responses$ref;
export type ResearchDataSharingPage_user_research_programs_responses = $ReadOnlyArray<{|
  +id: string,
  +user_id: string,
  +tenant_research_program_id: string,
  +accepted: boolean,
  +tenant_research_program: {|
    +research_program: {|
      +title: string,
      +description: string,
    |}
  |},
  +$refType: ResearchDataSharingPage_user_research_programs_responses$ref,
|}>;
export type ResearchDataSharingPage_user_research_programs_responses$data = ResearchDataSharingPage_user_research_programs_responses;
export type ResearchDataSharingPage_user_research_programs_responses$key = $ReadOnlyArray<{
  +$data?: ResearchDataSharingPage_user_research_programs_responses$data,
  +$fragmentRefs: ResearchDataSharingPage_user_research_programs_responses$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ResearchDataSharingPage_user_research_programs_responses",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "user_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenant_research_program_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accepted",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantResearchProgram",
      "kind": "LinkedField",
      "name": "tenant_research_program",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ResearchProgram",
          "kind": "LinkedField",
          "name": "research_program",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "description",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserResearchProgramsResponse",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '3c9779bcd5437f764970eaebb4a6c2f3';

module.exports = node;
