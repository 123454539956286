// @flow

import { graphql } from 'react-relay'

export const usernameUniqueCheck = graphql`
  query UserSettingsUserFieldPage_usernameUniqueCheck_Query(
    $testUsername: String!
  ) {
    viewer {
      currentUser {
        usernameUniqueness(testUsername: $testUsername)
      }
    }
  }
`

export const UserSettingsUserFieldPageQuery = graphql`
  query UserSettingsUserFieldPage_Query {
    viewer {
      currentUser {
        ...UserSettingsUserFieldPage_user
      }
    }
  }
`

export const query = {
  user: graphql`
    fragment UserSettingsUserFieldPage_user on User {
      id
      legal_name
      preferred_name
      preferred_language
      phone_number
      username
      email
      unconfirmed_email
    }
  `,
}
