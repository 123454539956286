// @flow

import React, { type Node } from 'react'
import { useTranslation } from 'react-i18next'
import useRouter from 'found/useRouter'

import { usePolicies } from 'platform_web/hooks/usePolicies'
import { Button, FlexContainer, FlexItem } from 'care-ui'

type PropsType = {
  inviteButton?: Node,
}

const StaffNavigationTabs = (props: PropsType) => {
  const { inviteButton } = props

  const { routes } = useRouter().match
  const routeName = routes[routes.length - 1].name

  const policies = usePolicies()

  const canViewIndividuals = policies.CAN_LIST_INDIVIDUALS
  const canViewClinicians = policies.CAN_VIEW_LEAD_CLINICIAN_LINKS
  const canViewEmployees = policies.CAN_LIST_TENANT_EMPLOYEES
  const canViewNotifications = policies.CAN_VIEW_NOTIFICATIONS
  const canViewManagers = policies.CAN_LIST_TENANT_MANAGERS

  const { t: translation } = useTranslation('shared')

  return (
    <FlexContainer paddingX="xxs" justifyContent="space-between">
      <FlexContainer gap="xxxs">
        {/* NAV ITEMS */}
        {canViewIndividuals && (
          <FlexItem>
            <Button
              variant="text"
              to="staff_individuals"
              active={routeName === 'staff_individuals'}
            >
              {translation('individuals')}
            </Button>
          </FlexItem>
        )}

        {canViewClinicians && (
          <FlexItem>
            <Button
              variant="text"
              to="staff_clinicians"
              active={routeName === 'staff_clinicians'}
            >
              {translation('clinicians')}
            </Button>
          </FlexItem>
        )}

        {canViewEmployees && (
          <FlexItem>
            <Button
              variant="text"
              to="staff_employees"
              active={routeName === 'staff_employees'}
            >
              {translation('employees')}
            </Button>
          </FlexItem>
        )}

        {canViewManagers && (
          <FlexItem>
            <Button
              variant="text"
              to="staff_managers"
              active={routeName === 'staff_managers'}
            >
              {translation('managers')}
            </Button>
          </FlexItem>
        )}

        {canViewNotifications && (
          <FlexItem>
            <Button
              variant="text"
              to="staff_notifications"
              active={routeName === 'staff_notifications'}
            >
              {translation('notifications')}
            </Button>
          </FlexItem>
        )}
      </FlexContainer>

      {/* INVITE BUTTONS */}
      {inviteButton}
    </FlexContainer>
  )
}

export default StaffNavigationTabs
