// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { map } from 'lodash/fp'

import DefinitionList, {
  type DetailType,
} from 'react-ui/components/DefinitionList'
import createComponentId from 'shared/services/id'

import type { ConnectedCliniciansList_clinician_assignments } from './__generated__/ConnectedCliniciansList_clinician_assignments.graphql'

type PropsType = {
  clinician_assignments: ConnectedCliniciansList_clinician_assignments,
  componentId?: string,
}

const defaultId = createComponentId(__filename)

const ConnectedCliniciansListBase = (props: PropsType) => {
  const { componentId = defaultId, clinician_assignments } = props

  const details: Array<DetailType> = map(clinician_assignment => ({
    id: clinician_assignment.clinician.id,
    label: clinician_assignment.clinician.name,
  }))(clinician_assignments)

  return (
    <div className={componentId}>
      <DefinitionList details={details} />
    </div>
  )
}

export const ConnectedCliniciansListLoader = createFragmentContainer(
  ConnectedCliniciansListBase,
  {
    clinician_assignments: graphql`
      fragment ConnectedCliniciansList_clinician_assignments on ClinicianAssignment
        @relay(plural: true) {
        clinician {
          id
          name
        }
      }
    `,
  },
)
