// @flow

import React, { type ElementType, type Node } from 'react'
import { connect } from 'react-fela'
import classNames from 'classnames'
import { curry, omit } from 'lodash/fp'

import { felaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  as?: ElementType,
  aside: Node,
  asideColSpan?: number,
  children: Node,
  fluid?: boolean,
  renderArticle?: any,
  // This should be a more specific function type, but we can't get Flow to work
  // with it until we have better types for Lodash's `curry` method.
  renderAside?: any,
  reverse?: boolean,
}

const styleRules = ({
  asideColSpan = 4,
  fluid = false,
  theme,
  reverse = false,
}) => {
  const contentColSpan = theme.Grid.columns - asideColSpan
  const fixedContainerQuery = `@media screen and (min-width: ${
    theme.Grid.containerMaxWidth
  }px)`
  const { gutter } = theme.Grid

  const asideScalar = asideColSpan / theme.Grid.columns
  const asideWidthInPx = `calc(${asideScalar *
    theme.Grid.containerMaxWidth}px - ${gutter})`
  const asideWidthInPercent = `calc(${asideScalar * 100}% - ${gutter})`

  const articleScalar = contentColSpan / theme.Grid.columns
  const articleWidthInPx = `calc(${articleScalar *
    theme.Grid.containerMaxWidth}px + ${gutter})`
  const articleWidthInPercent = `calc(${articleScalar * 100}% + ${gutter})`

  const multiCol = asideColSpan < theme.Grid.columns

  return {
    Layout2Col: {
      alignItems: 'stretch',
      className: classNames(`Layout2Col--${asideColSpan}`, {
        '--reverse': !!reverse,
      }).replace(/\s/g, ''),
      display: 'flex',
      flex: '1 1 auto',
      '-ms-flex': '1 1 auto',
      flexFlow: multiCol
        ? `${reverse ? 'row-reverse' : 'row'} nowrap`
        : 'column wrap',
      justifyContent: 'stretch',
      maxWidth: '100%',
      position: 'relative',
      width: '100%',
      zIndex: 0,
      [theme.breakpoints.queries.sm]: {
        flexDirection: 'row',
      },
    },

    aside: {
      boxSizing: 'border-box',
      className: classNames(`Layout2Col__aside--${asideColSpan}`, {
        '--reverse': !!reverse,
      }).replace(/\s/g, ''),
      display: 'flex',
      flexFlow: 'column wrap',
      alignItems: reverse ? 'flex-start' : 'flex-end',
      justifyContent: 'flex-start',
      position: 'relative',
      maxWidth: '100%',
      width: multiCol ? asideWidthInPercent : '100%',
      zIndex: 2,
      [fixedContainerQuery]: {
        width: `calc(
          calc(calc(100vw - ${theme.Grid.containerMaxWidth}px) / 2)
          + ${asideWidthInPx}
        )`,
      },
    },

    asideContent: {
      alignItems: 'stretch',
      className: `Layout2Col__asideContent--${asideColSpan}`,
      display: 'flex',
      flexFlow: 'column wrap',
      flex: '1 1 auto',
      justifyContent: 'flex-start',
      maxWidth: '100%',
      position: 'relative',
      width: '100%',
      [fixedContainerQuery]: {
        width: asideWidthInPx,
      },
    },

    article: {
      alignItems: reverse ? 'flex-end' : 'flex-start',
      className: classNames('Layout2Col__article', {
        '--fluid': !!fluid,
      }).replace(/\s/g, ''),
      display: 'flex',
      flexGrow: 1,
      flexFlow: 'column wrap',
      justifyContent: 'flex-start',
      marginBottom: 0,
      marginTop: 0,
      position: 'relative',
      width: multiCol ? articleWidthInPercent : '100%',
      zIndex: 1,
      [fixedContainerQuery]: {
        width: `calc(
          calc(calc(100vw - ${theme.Grid.containerMaxWidth}px) / 2)
          + ${articleWidthInPx}
        )`,
      },
    },

    articleContent: {
      boxSizing: 'border-box',
      className: 'Layout2Col__articleContent',
      display: 'flex',
      flex: '1 1 auto',
      flexDirection: 'column',
      maxWidth: '100%',
      paddingLeft: gutter,
      width: '100%',
      [fixedContainerQuery]: {
        width: fluid ? '100%' : articleWidthInPx,
      },
    },
  }
}

function defaultRender(Component, { children, ...props }, _styleRule) {
  return <Component {...(props: any)}>{children}</Component>
}

const Layout2Col = ({
  as: Component = 'div',
  aside,
  children,
  renderAside = curry(defaultRender)('aside'),
  renderArticle = curry(defaultRender)('article'),
  rules,
  styles,
  ...props
}: PropsType) => (
  <Component
    {...omit(['asideColSpan', 'fluid', 'reverse', ...felaProps], props)}
    className={styles.Layout2Col}
  >
    {renderAside(
      {
        className: styles.aside,
        children: <div className={styles.asideContent}>{aside}</div>,
      },
      rules.aside,
    )}
    {renderArticle(
      {
        className: styles.article,
        children: <div className={styles.articleContent}>{children}</div>,
      },
      rules.article,
    )}
  </Component>
)

export default connect(styleRules)(Layout2Col)
