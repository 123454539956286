// @flow

import { graphql } from 'react-relay'

export const ConnectedTenantsPageQuery = graphql`
  query ConnectedTenantsPage_Query {
    viewer {
      currentUser {
        ...ConnectedTenantsPage_user
      }
    }
  }
`

export const connectedTenantsPageUser = {
  user: graphql`
    fragment ConnectedTenantsPage_user on User {
      roles(roleTypes: [INDIVIDUAL]) {
        nodes {
          ... on IndividualRole {
            ...IndividualRolesList_individuals
          }
        }
      }
      individual_tenants {
        ...ConnectedTenantsList_tenants
      }
    }
  `,
}
