// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import { merge } from 'lodash/fp'

import type { AvatarPropTypes } from 'react-ui/components/Avatar/Avatar'
import ClinicianNoteActivity from 'react-ui/components/ClinicianNote'
import UserCard from 'react-ui/components/UserCard'
import { withoutFelaProps } from 'shared/services/fela'
import { usePolicies } from 'platform_web/hooks/usePolicies'

import type { FelaOptionalPropsType, FelaPropsType } from '../../typing'

import { type UserActivityCardLoader_user } from './__generated__/UserActivityCardLoader_user.graphql'

const styleRules = () => ({
  UserActivityCard: {
    className: 'UserActivityCard',
  },
  lastSeen: {
    display: 'block',
    className: 'UserActivityCard__lastSeen',
    fontSize: '14px',
    lineHeight: '21px',
    overflow: 'hidden',
    position: 'relative',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
})

export type UserActivityCardProps = FelaPropsType &
  AvatarPropTypes & {
    children?: Node,
    clinHelpQuestionnaire: boolean,
    escalated?: boolean,
    link?: boolean,
    showFlag?: boolean,
    staff?: boolean,
    status: string,
    user: UserActivityCardLoader_user,
  }

const UserActivityCard = ({
  extend = () => ({}),
  children,
  escalated = false,
  rules,
  user,
  showFlag = false,
  link = true,
  staff = false,
  status,
  ...props
}: UserActivityCardProps) => {
  const policies = usePolicies()
  const isLink = link && policies.CAN_VIEW_CLINICIAN_VIEWING_INDIVIDUAL_CONTENT
  const isStatusExplicitOrImplicit =
    status === 'Explicit' || status === 'Implicit'
  const showClinicianActivity =
    policies.CAN_USE_CLINICIAN_NOTE &&
    policies.CAN_VIEW_CLINICIAN_LINKS &&
    isStatusExplicitOrImplicit

  return (
    <UserCard
      staff={staff}
      escalated={escalated}
      showFlag={showFlag}
      link={isLink}
      user={user}
      extend={(...args) =>
        merge(
          {
            UserCard: rules.UserActivityCard(...args),
          },
          extend(...args),
        )
      }
      {...withoutFelaProps(props)}
    >
      {children}

      {showClinicianActivity && <ClinicianNoteActivity user={user} />}
    </UserCard>
  )
}

const Connected: React.StatelessFunctionalComponent<
  UserActivityCardProps & FelaOptionalPropsType,
> = connect(styleRules)(UserActivityCard)

export default Connected
