// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  actions?: React.Node,
  children: React.Node,
  title: React.Node,
}

const styleRules = ({ theme }) => ({
  actions: {
    className: 'Admin1Col__actions',
    display: 'block',
    float: 'right',
    marginLeft: theme.Grid.gutter,
  },
  Section: {
    paddingTop: '2rem',
    paddingBottom: '2rem',
  },
})

const Primary1Col = ({
  actions,
  children,
  styles,
  title,
  rules,
}: PropsType) => (
  <React.Fragment>
    <Section color="primary" extend={rules}>
      <Container>
        {actions && <div className={styles.actions}>{actions}</div>}
        <Heading level={2}>{title}</Heading>
      </Container>
    </Section>
    <Section color="default" extend={rules}>
      <Container>{children}</Container>
    </Section>
  </React.Fragment>
)

export default connect(styleRules)(Primary1Col)
