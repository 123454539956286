// @flow

import React, { type Node } from 'react'

import { Tooltip } from 'care-ui'

type RenderWithTooltipProps = {
  children: Node,
  tooltipTestId: string,
  tooltipText: string,
}

const RenderWithTooltip = (props: RenderWithTooltipProps) => {
  const { children, tooltipText, tooltipTestId } = props

  if (tooltipText?.length <= 20) {
    return children
  }

  return (
    <Tooltip
      dataTestId={`${tooltipTestId}Tooltip`}
      text={tooltipText}
      variant="tooltip"
    >
      {children}
    </Tooltip>
  )
}

export default RenderWithTooltip
