// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BasicTable__table____8zTU{border-collapse:collapse;width:100%;margin:1rem 0}.BasicTable__table____8zTU td,.BasicTable__table____8zTU th{padding:1rem}.BasicTable__table____8zTU th:first-of-type,.BasicTable__table____8zTU td:first-of-type{padding-left:2rem}.BasicTable__table____8zTU th:last-of-type,.BasicTable__table____8zTU td:last-of-type{padding-right:2rem}.BasicTable__table____8zTU thead{background-color:rgba(0,0,0,0);text-align:left}.BasicTable__table____8zTU tbody{background-color:#fff}.BasicTable__tableContainer___oCoZ2{overflow-x:scroll}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"table": `BasicTable__table____8zTU`,
	"tableContainer": `BasicTable__tableContainer___oCoZ2`
};
export default ___CSS_LOADER_EXPORT___;
