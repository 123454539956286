// @flow

import { graphql } from 'react-relay'

export const healthCardListFragment = graphql`
  fragment HealthPreferences_user_trackables on UserTrackableConnection {
    nodes {
      id
      in_plan
      trackable {
        id
      }
      ...HealthCard_user_trackable
    }
  }
`
