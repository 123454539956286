// @flow

import { graphql } from 'react-relay'

export const scoreCardsPageUserTrackable = {
  user_trackable: graphql`
    fragment ScoreCardsPage_user_trackable on UserTrackable {
      ...ScoreCardsCareOptions_user_trackable
      ...CurrentResultCard_user_trackable
      ...ScoreCardsPreviousAnswers_user_trackable
      primary_user_questionnaire {
        id
        user {
          id
        }
        questionnaire {
          label
          id
        }
        user_question_sets {
          for_role_type
          ...ScoreCardsPreviousAnswers_user_question_sets
        }
      }
      user {
        id
        ...UserTrackableChartControls_user
      }
      id
      trackable {
        ...ScoreCardsPageAbout_trackable
        formula {
          formula_ranges {
            ...UserTrackableChartControls_formulaRanges
          }
        }
        label
      }
    }
  `,
  viewer: graphql`
    fragment ScoreCardsPage_viewer on Viewer {
      ...ScoreCardsCareOptions_viewer
      currentUser {
        id
      }
    }
  `,
}
