/*
 * @flow
 */

import pushToServer from './server/push'

export type Event = {
  action: 'View' | 'Click' | 'Hover' | 'Leave' | 'Resume',
  category:
    | 'Fitbit'
    | 'Help Now'
    | 'Dashboard'
    | 'Clinician Interaction'
    | 'WelcomePage'
    | 'Questionnaire'
    | 'Initial Questionnaire',
  duration?: number,
  label: string,
  totalDuration?: number,
}

const dispatchEvent = (data: Event) => {
  pushToServer({
    timestamp: new Date(),
    path: window.location.pathname,
    action_type: data.action,
    ...(data: any),
  })
}

export default dispatchEvent
