// @flow

import * as React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import { ResourceLoader } from 'components/Resource/Resource'
import { unpackConnection } from 'services/relay'
import createComponentId from 'shared/services/id'

import type { ResourceList_resourcesConnectionInterface } from './__generated__/ResourceList_resourcesConnectionInterface.graphql'

type PropsType = {
  componentId?: string,
  resourcesConnectionInterface: ResourceList_resourcesConnectionInterface,
}

const defaultId = createComponentId(__filename)

const ResourceListBase = (props: PropsType) => {
  const { componentId = defaultId, resourcesConnectionInterface } = props

  const resources = unpackConnection(
    resourcesConnectionInterface.resource_library_connection,
  )

  const renderedResources = resources.map(resource => (
    <div key={resource.id}>
      <ResourceLoader resource={resource} />
    </div>
  ))

  return <div className={componentId}>{renderedResources}</div>
}

export const ResourceListLoader = createFragmentContainer(ResourceListBase, {
  resourcesConnectionInterface: graphql`
    fragment ResourceList_resourcesConnectionInterface on ResourcesConnectionInterface
      @argumentDefinitions(count: { type: "Int", defaultValue: 100000 }) {
      resource_library_connection(first: $count)
        @connection(
          key: "ResourceList_resource_library_connection"
          filters: []
        ) {
        edges {
          node {
            ...Resource_resource
            id
          }
        }
      }
    }
  `,
})
