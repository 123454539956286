// @flow

import { differenceInSeconds } from 'date-fns/fp'

type OnlineUserType = {
  +last_seen_at: ?string,
}

export const onlineTimeFrame = 60

export const isOnline = (date: Date): boolean =>
  differenceInSeconds(date)(new Date()) <= onlineTimeFrame

export const userLastSeen = (user: OnlineUserType) => {
  const lastSeenDate = user.last_seen_at ? new Date(user.last_seen_at) : null
  const online = lastSeenDate ? isOnline(lastSeenDate) : false
  return { lastSeenDate, isOnline: online }
}
