// @flow

import { jsonSessionStorage } from 'services/browserStorage'

export function setTokens(response: Response, callback: () => void) {
  // eslint-disable-next-line promise/always-return,promise/catch-or-return
  response.json().then(params => {
    jsonSessionStorage.setItem('csrfToken', params.csrfToken)
    callback() // to ensure that the tokens are set before continuing with other functions
  })
}
