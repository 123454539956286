// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import Button, { ButtonGroup } from 'react-ui/components/Button'
import Heading from 'react-ui/components/Heading'
import { Primary1Col } from 'react-ui/components/Layout'

import type { TenantAdminPage_tenant } from './__generated__/TenantAdminPage_tenant.graphql'

type PropsType = {
  tenant: TenantAdminPage_tenant,
}

const TenantAdminPage = (props: PropsType) => {
  const { t: translation } = useTranslation('shared')
  const { tenant } = props

  return (
    <Primary1Col
      actions={
        <ButtonGroup>
          <React.Fragment>
            <Button to="tenants" shape="pill" ghost invert>
              {translation('back')}
            </Button>
          </React.Fragment>
        </ButtonGroup>
      }
      title="Tenant"
    >
      <Heading level={3}>{tenant.name}</Heading>
      <p>
        {translation('escalation_emails')}:
        {tenant.escalation_emails}
      </p>
      <p>
        {translation('contact_email')}:
        {tenant.contact_email}
      </p>
      <p>
        {translation('contact_phone_number')}:
        {tenant.contact_phone}
      </p>
    </Primary1Col>
  )
}

export const TenantAdminPageQuery = graphql`
  query TenantAdminPage_Query($id: ID!) {
    tenant(id: $id) {
      ...TenantAdminPage_tenant
    }
  }
`

export const TenantAdminPageLoader = createFragmentContainer(TenantAdminPage, {
  tenant: graphql`
    fragment TenantAdminPage_tenant on Tenant {
      id
      contact_email
      contact_phone
      escalation_emails
      bcc_email
      name
      notification_after
    }
  `,
})
