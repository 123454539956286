// @flow

import React, { type Node, useRef, useState } from 'react'
import { useFela } from 'react-fela'

import { Text } from 'care-ui'

import { sizeStyle, textStyle, tooltipContainerStyle } from './Tooltip.style'

type PropsType = {
  children: Node,
  dataTestId?: string,
  note?: string,
  size?: 'lg' | 'md',
  text: string,
  variant?: 'tooltip' | 'info',
  width?: string,
}

const Tooltip = ({
  children,
  dataTestId,
  text,
  variant,
  width,
  note,
  size,
}: PropsType) => {
  const [leftPosition, setLeft] = useState('0')
  const [topPosition, setTop] = useState('0')

  const { css, theme } = useFela({
    left: leftPosition,
    top: topPosition,
    variant,
    size,
    width,
  })
  const containerRef = useRef(null)

  const onMouseEnter = ({ clientX }) => {
    if (!containerRef.current) return null

    const { top, height } = containerRef.current.getBoundingClientRect()

    const gap = parseInt(theme.care.spacing.xxs, 10)

    const currentLeft = `${clientX}px`
    const currentTop = `${top + height + gap}px`

    setLeft(currentLeft)
    setTop(currentTop)

    return null
  }

  return (
    <div
      ref={containerRef}
      onMouseEnter={onMouseEnter}
      className={css(sizeStyle, tooltipContainerStyle)}
    >
      {children}
      <Text
        as="span"
        data-testid={dataTestId}
        size="md"
        center
        extend={() =>
          textStyle({
            theme,
            left: leftPosition,
            top: topPosition,
            variant,
            width,
          })
        }
      >
        {text}&nbsp;
        {note && (
          <>
            <br />
            <br />
            {note}
          </>
        )}
      </Text>
    </div>
  )
}

export default Tooltip
