// @flow

import React from 'react'
import { connect } from 'react-fela'

import type { FelaPropsType } from 'react-ui/typing'

const supportGridStyle = {
  '@supports (display: grid)': {
    wordBreak: 'break-word',
    minHeight: '0',
    flexGrow: '1',
    paddingTop: '0',
  },
}
const styleRules = ({ theme }) => ({
  Body: {
    outline: 'none',
    height: '100%',
    marginBottom: '0',
    padding: '10px 10px 0 10px',
    whiteSpace: 'wrap',
    minHeight: '84px',
    margin: '0',
    display: 'flex',
    flexDirection: 'column',
    ...supportGridStyle,
  },

  Title: {
    fontSize: '.8rem',
    display: 'flex',
    marginBottom: '0.6rem',
    lineHeight: '1',
    textAlign: 'left',
    minHeight: '50px',
    ...supportGridStyle,
    '& a': {
      color: theme.palette.link.linkColor,
      lineHeight: '1.2',
    },
    [theme.breakpoints.queries.sm]: {
      fontSize: '.9rem',
      ...supportGridStyle,
    },
  },

  TrendWrapper: {
    display: 'none',
    fontSize: '.7rem',
    justifyContent: 'space-between',
    height: 'auto',
    width: '100%',
    minHeight: '35px',
    alignItems: 'flex-end',
    ...supportGridStyle,
    color: theme.palette.questionnaireCard.gray,
    [theme.breakpoints.queries.md]: {
      display: 'flex',
    },
  },
})

type TrackableResultCardBodyTypes = FelaPropsType & {
  timeSinceUpdate: string,
  title: string,
  trendIndicator: string,
}

const TrackableResultCardBody = ({
  title,
  styles,
  trendIndicator,
  timeSinceUpdate,
}: TrackableResultCardBodyTypes) => (
  <div data-component-id="card-body" className={styles.Body}>
    <div className={styles.Title}>{title}</div>
    <div className={styles.TrendWrapper}>
      {trendIndicator && <div> {trendIndicator}</div>}
      {trendIndicator && timeSinceUpdate && <div> {timeSinceUpdate}</div>}
    </div>
  </div>
)

export default connect(styleRules)(TrackableResultCardBody)
