// @flow

import React, { type Node } from 'react'
import cx from 'classnames'
import Link from 'found/Link'
import useRouter from 'found/useRouter'

import { Chevron } from 'shared/ui/Typography/Icons'
import { Text } from 'care-ui'

import s from './NavLink.scss'

export type PropsType = {
  children: Node,
  className?: string,
  locationCheck: Array<string>,
  to: { name: string } | string,
}

const NavLink = (props: PropsType) => {
  const { children, to, locationCheck } = props
  const currentLocation = useRouter().match.location.pathname

  const isActive = locationCheck.some(el => currentLocation.includes(el))

  return (
    <Link
      to={to}
      className={cx(s.root, { [s.active]: isActive }, props.className)}
    >
      <>
        <Text as="span" size="lg" bold>
          {children}
        </Text>
        {isActive || (
          <Chevron
            addedClassName={s.caret}
            size="m"
            color="secondary"
            rotation="left"
          />
        )}
      </>
    </Link>
  )
}

export default NavLink
