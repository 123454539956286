// @flow

import { type ComponentType } from 'react'
import { createComponentWithProxy } from 'react-fela'

/**
 * This is a component that outputs text which is only visible to screen readers.
 * Only use this to add assistive helper text for extra context.
 * Do NOT use it just to hide interactive content.
 * For more details, see:
 * - https://a11yproject.com/posts/how-to-hide-content/
 * - https://polaris.shopify.com/components/titles-and-text/visually-hidden
 */

const styleRules = {
  position: 'absolute !important',
  top: '0',
  clip: 'rect(1px, 1px, 1px, 1px) !important',
  overflow: 'hidden !important',
  height: '1px !important',
  width: '1px !important',
  padding: '0 !important',
  border: '0 !important',
}

const VisuallyHidden: ComponentType<{}> = createComponentWithProxy(
  styleRules,
  'span',
)

export default VisuallyHidden
