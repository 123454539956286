// @flow

import React from 'react'
import { merge } from 'lodash/fp'
import numeral from 'numeral'

import HeroNumber from 'react-ui/components/HeroNumber'
import SegmentationTable, {
  type PropsType,
} from 'react-ui/components/SegmentationTable'

const BasicSummaryTable = ({ columnConfig, traces, ...props }: PropsType) => {
  const mergedColumnConfig = merge(
    {
      label: {
        format: ({ name }: { name: string }) => name,
      },
      value0: {
        format: value => numeral(value).format('0,0'),
      },
      value1: {
        format: value => <HeroNumber value={value / 100 || 0} format="0.00%" />,
      },
    },
    columnConfig,
  )
  const mappedTraces = traces.map(({ y, ...trace }) => ({
    ...trace,
    y: y.map(value => Number(value)),
  }))

  return (
    <SegmentationTable
      {...props}
      columnConfig={mergedColumnConfig}
      traces={mappedTraces}
    />
  )
}

export default BasicSummaryTable
