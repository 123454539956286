// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import cx from 'classnames'

import { checkPasswordPolicy } from 'shared/services/passwordCheck'
import { Input } from 'shared/ui/Forms/Fields'

import s from './EnterPassword.scss'

type PropTypes = {
  errors?: { [key: string]: string },
  hasConfirm?: boolean,
  onChange?: Function,
  touched?: { [key: string]: boolean },
  userPasswordConfirm?: string,
  value: string,
  white?: boolean,
}

const EnterPassword = ({
  value,
  userPasswordConfirm = '',
  hasConfirm = false,
  onChange,
  white = false,
  touched,
  errors,
  ...rest
}: PropTypes) => {
  const { entered_matched, entered_not_matched } = checkPasswordPolicy(
    value,
    userPasswordConfirm,
  )

  const { t: translation } = useTranslation('shared')

  let uiStyle = 'primary'
  if (entered_matched) {
    uiStyle = 'success'
  } else if (entered_not_matched) {
    uiStyle = 'danger'
  }
  let Confirm = <div />

  if (hasConfirm) {
    Confirm = (
      <Input
        uiStyle={uiStyle}
        name="password_confirmation"
        type="password"
        label={translation('confirm_new_password')}
        placeholder={translation('repeat_password')}
        onChange={onChange}
        value={userPasswordConfirm}
        errors={errors}
        touched={touched}
        white={white}
        required
        {...(rest: any)}
      />
    )
  }

  return (
    <div className={cx(s.form, { [s.white]: white })}>
      <Input
        uiStyle={uiStyle}
        name="password"
        type="password"
        label={translation('new_password')}
        placeholder={translation('enter_new_password')}
        onChange={onChange}
        value={value}
        errors={errors}
        touched={touched}
        white={white}
        required
        {...(rest: any)}
      />
      {Confirm}
    </div>
  )
}

export default EnterPassword
