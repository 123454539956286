// @flow

import React, { type Node } from 'react'

import PopOver from 'react-ui/components/PopOver/PopOver'

type PropsType = {
  children?: Node,
}

const DropDown = ({ children, ...props }: PropsType) => {
  return (
    <PopOver {...props}>
      {({ onToggleOpen }) =>
        React.Children.map(
          children,
          child =>
            child &&
            React.cloneElement(child, {
              onClick: e => {
                if (onToggleOpen) {
                  onToggleOpen()
                }
                if (child.props.onClick) {
                  child.props.onClick(e)
                }
              },
              targetToggle: onToggleOpen,
            }),
        )
      }
    </PopOver>
  )
}

export default DropDown
