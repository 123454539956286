/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AssessmentRequesteesList_individual$ref = any;
export type IndividualTrackablesListQueryVariables = {|
  id: string,
  requesteeRoleId?: ?string,
  loadList: boolean,
|};
export type IndividualTrackablesListQueryResponse = {|
  +user: {|
    +linked_individual: ?{|
      +id: string,
      +requestableQuestionnaires?: $ReadOnlyArray<{|
        +id: string,
        +label: string,
        +category: ?string,
        +library_description: ?string,
      |}>,
      +$fragmentRefs: AssessmentRequesteesList_individual$ref,
    |}
  |}
|};
export type IndividualTrackablesListQuery = {|
  variables: IndividualTrackablesListQueryVariables,
  response: IndividualTrackablesListQueryResponse,
|};
*/


/*
query IndividualTrackablesListQuery(
  $id: ID!
  $requesteeRoleId: ID
  $loadList: Boolean!
) {
  user(id: $id) {
    linked_individual {
      id
      requestableQuestionnaires(requesteeRoleId: $requesteeRoleId) @include(if: $loadList) {
        id
        label
        category
        library_description
      }
      ...AssessmentRequesteesList_individual
    }
    id
  }
}

fragment AssessmentRequesteesList_individual on IndividualRole {
  id
  legal_name
  support_person_assignments {
    id
    accepted_invitation
    relationship
    support_person {
      id
      legal_name
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "loadList"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "requesteeRoleId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "condition": "loadList",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "requesteeRoleId",
          "variableName": "requesteeRoleId"
        }
      ],
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "requestableQuestionnaires",
      "plural": true,
      "selections": [
        (v4/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "category",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "library_description",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legal_name",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "IndividualTrackablesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndividualRole",
            "kind": "LinkedField",
            "name": "linked_individual",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "AssessmentRequesteesList_individual"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "IndividualTrackablesListQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndividualRole",
            "kind": "LinkedField",
            "name": "linked_individual",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "SupportPersonAssignment",
                "kind": "LinkedField",
                "name": "support_person_assignments",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "accepted_invitation",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "relationship",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "SupportPersonRole",
                    "kind": "LinkedField",
                    "name": "support_person",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v5/*: any*/)
            ],
            "storageKey": null
          },
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "29ed17c619c40c9be188dbedf07a113e",
    "id": null,
    "metadata": {},
    "name": "IndividualTrackablesListQuery",
    "operationKind": "query",
    "text": "query IndividualTrackablesListQuery(\n  $id: ID!\n  $requesteeRoleId: ID\n  $loadList: Boolean!\n) {\n  user(id: $id) {\n    linked_individual {\n      id\n      requestableQuestionnaires(requesteeRoleId: $requesteeRoleId) @include(if: $loadList) {\n        id\n        label\n        category\n        library_description\n      }\n      ...AssessmentRequesteesList_individual\n    }\n    id\n  }\n}\n\nfragment AssessmentRequesteesList_individual on IndividualRole {\n  id\n  legal_name\n  support_person_assignments {\n    id\n    accepted_invitation\n    relationship\n    support_person {\n      id\n      legal_name\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'd6bed0e4f84124ffde41284150ecf9d2';

module.exports = node;
