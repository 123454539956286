/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type TrackableDropdown_user$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type SummaryGraph_user$ref: FragmentReference;
declare export opaque type SummaryGraph_user$fragmentType: SummaryGraph_user$ref;
export type SummaryGraph_user = {|
  +id: string,
  +summary_data_set: $ReadOnlyArray<{|
    +trackable: {|
      +id: string,
      +label: string,
    |},
    +answered_by: {|
      +role_type?: RoleTypes
    |},
    +points: $ReadOnlyArray<{|
      +date: string,
      +scaled_value: ?number,
      +raw_score: ?number,
      +formula_range: ?{|
        +boundLower: number,
        +boundUpper: ?number,
        +label: string,
      |},
    |}>,
  |}>,
  +$fragmentRefs: TrackableDropdown_user$ref,
  +$refType: SummaryGraph_user$ref,
|};
export type SummaryGraph_user$data = SummaryGraph_user;
export type SummaryGraph_user$key = {
  +$data?: SummaryGraph_user$data,
  +$fragmentRefs: SummaryGraph_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "end_time"
    },
    {
      "kind": "RootArgument",
      "name": "start_time"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SummaryGraph_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "end_time",
          "variableName": "end_time"
        },
        {
          "kind": "Variable",
          "name": "start_time",
          "variableName": "start_time"
        }
      ],
      "concreteType": "SummaryDataSetType",
      "kind": "LinkedField",
      "name": "summary_data_set",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Trackable",
          "kind": "LinkedField",
          "name": "trackable",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "answered_by",
          "plural": false,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "role_type",
                  "storageKey": null
                }
              ],
              "type": "RoleInterface",
              "abstractKey": "__isRoleInterface"
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "UserTrackableData",
          "kind": "LinkedField",
          "name": "points",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "scaled_value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "raw_score",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FormulaRange",
              "kind": "LinkedField",
              "name": "formula_range",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "boundLower",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "boundUpper",
                  "storageKey": null
                },
                (v1/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TrackableDropdown_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '521568679c74f2e05a2c4a984824947c';

module.exports = node;
