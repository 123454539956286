// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserUpdateMutationResponse,
  UserUpdateMutationVariables,
} from './__generated__/UserUpdateMutation.graphql'

const mutation = graphql`
  mutation UserUpdateMutation($input: UserUpdateInput!) {
    userUpdate(input: $input) {
      user {
        completed_onboarding
        email
        preferred_name
        preferred_language
        policies {
          name
          enabled
        }
        current_role {
          ... on RoleInterface {
            id
            policies {
              name
              enabled
            }
          }
        }
        homepage_path # required for redirect after change role
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: UserUpdateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: UserUpdateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
