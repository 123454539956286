// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  AssessmentRequestCreateMutationResponse,
  AssessmentRequestCreateMutationVariables,
} from './__generated__/AssessmentRequestCreateMutation.graphql'

const mutation = graphql`
  mutation AssessmentRequestCreateMutation(
    $input: AssessmentRequestCreateInput!
  ) {
    assessmentRequestCreate(input: $input) {
      assessment_request {
        id
      }
      user {
        ...AssessmentRequestsList_user
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: AssessmentRequestCreateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: AssessmentRequestCreateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
