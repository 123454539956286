// @flow

import { graphql } from 'react-relay'

const RequestAssessmentsMutation = graphql`
  mutation AssessmentRequestsBulkCreateMutation(
    $input: AssessmentRequestsBulkCreateInput!
  ) {
    assessmentRequestBulkCreate(input: $input) {
      success
    }
  }
`

export default RequestAssessmentsMutation
