// @flow

/**
 * This components provides a "lazy loader" style wrapper for components that require data about the tenants selected to filter on before they can render.
 * (A good example of this is the available date range, this cannot be known until the tenants available for metrics are select)
 */

import * as React from 'react'
import {
  commitLocalUpdate,
  createFragmentContainer,
  graphql,
  QueryRenderer,
} from 'react-relay'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import createComponentId from 'shared/services/id'

import ServiceMetricsFilters from './ServiceMetricsFilters'

import type { ReportSettingsTenantFetch_settings } from './__generated__/ReportSettingsTenantFetch_settings.graphql'
import type { ReportSettingsTenantFetchQueryResponse } from './__generated__/ReportSettingsTenantFetchQuery.graphql'

type PropsType = {
  componentId?: string,
  relay: Object,
  reportView: string,
  settings: ReportSettingsTenantFetch_settings,
  +tenants: $ReadOnlyArray<{
    +id: string,
    +name: string,
  }>,
}

const query = graphql`
  query ReportSettingsTenantFetchQuery($tenants: [ID!]!, $clinicians: [ID!]!) {
    viewer {
      reports {
        start_date(tenants: $tenants, clinicians: $clinicians)
      }
      tenants_with_id(ids: $tenants) {
        id
        name
        metrics_clinicians {
          id
          name
          user {
            ...UserCheckBoxLoader_user
          }
        }
      }
    }
  }
`

type StateType = {
  openClinicians: boolean,
  openDates: boolean,
  openTenants: boolean,
}

function getInitialState() {
  return {
    openClinicians: false,
    openDates: false,
    openTenants: false,
  }
}

export class ReportSettingsTenantFetchBase extends React.Component<
  PropsType,
  StateType,
> {
  state = getInitialState()

  static defaultProps = {
    componentId: createComponentId(__filename),
  }

  render() {
    const { relay, settings, tenants: allTenants, reportView } = this.props
    const { openClinicians, openTenants } = this.state

    return (
      <QueryRenderer
        environment={relay.environment}
        query={query}
        variables={settings}
        render={response => (
          <QueryRendererLoadingIndicator response={response}>
            {({
              viewer: { reports, tenants_with_id },
            }: ReportSettingsTenantFetchQueryResponse) => {
              const allClinicians = tenants_with_id.reduce(
                (memo, tenant) => memo.concat(tenant.metrics_clinicians),
                [],
              )
              return (
                <ServiceMetricsFilters
                  clinicians={allClinicians}
                  minDate={new Date(reports.start_date)}
                  onChangeClinicians={clinicianIds => {
                    commitLocalUpdate(relay.environment, store => {
                      const getReport = store.get('reportSettings')
                      if (getReport)
                        getReport.setValue(clinicianIds, 'clinicians')
                    })
                  }}
                  onChangeDateType={nextType => {
                    commitLocalUpdate(relay.environment, store => {
                      const getReport = store.get('reportSettings')
                      if (getReport)
                        getReport.setValue(nextType, 'selectedDateType')
                    })
                  }}
                  onChangeDates={({ from, to }) => {
                    commitLocalUpdate(relay.environment, store => {
                      const getReport = store.get('reportSettings')
                      if (getReport) {
                        getReport.setValue(from?.toISOString(), 'from')
                        getReport.setValue(to?.toISOString(), 'to')
                      }
                    })
                  }}
                  onChangeTenants={tenantIds => {
                    commitLocalUpdate(relay.environment, store => {
                      const safeTenantIds =
                        tenantIds.length === 0
                          ? allTenants.map(({ id }) => id)
                          : tenantIds
                      const getReport = store.get('reportSettings')
                      if (getReport) {
                        getReport.setValue(safeTenantIds, 'tenants')
                        getReport.setValue([], 'clinicians')
                      }
                    })
                  }}
                  onChangeCliniciansOpenState={value => {
                    this.setState({
                      ...getInitialState(),
                      openClinicians: value,
                    })
                  }}
                  // eslint-disable-next-line no-unused-vars
                  onChangeDatesOpenState={value => {
                    this.setState({ ...getInitialState() })
                  }}
                  onChangeTenantsOpenState={value => {
                    this.setState({ ...getInitialState(), openTenants: value })
                  }}
                  openTenants={openTenants}
                  openClinicians={openClinicians}
                  reportView={reportView}
                  selectedClinicians={settings.clinicians}
                  selectedDateType={settings.selectedDateType || ''}
                  selectedDateRange={{
                    from: new Date(settings.from) || new Date(),
                    to: new Date(settings.to) || new Date(),
                  }}
                  selectedTenants={settings.tenants}
                  tenants={allTenants}
                />
              )
            }}
          </QueryRendererLoadingIndicator>
        )}
      />
    )
  }
}

export const ReportSettingsTenantFetchLoader = createFragmentContainer(
  ReportSettingsTenantFetchBase,
  {
    settings: graphql`
      fragment ReportSettingsTenantFetch_settings on ReportSettings {
        clinicians
        tenants
        from
        to
        selectedDateType
      }
    `,
  },
)
