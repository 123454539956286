/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserCardLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type NavigationProvider_user$ref: FragmentReference;
declare export opaque type NavigationProvider_user$fragmentType: NavigationProvider_user$ref;
export type NavigationProvider_user = {|
  +current_role: {|
    +id?: string,
    +removed?: boolean,
    +tenant_id?: ?string,
    +parent?: ?{|
      +__typename: string,
      +name?: string,
      +id?: string,
      +logo_display_url?: string,
      +privacyUrl?: string,
      +tncUrl?: string,
    |},
    +title?: string,
  |},
  +tenant: ?{|
    +__typename: string,
    +logo_display_url: string,
    +id: string,
    +name: string,
    +multi_roles_enabled: boolean,
  |},
  +current_tenants: ?$ReadOnlyArray<{|
    +__typename: string,
    +logo_display_url: string,
    +id: string,
    +name: string,
    +multi_roles_enabled: boolean,
  |}>,
  +roles: ?{|
    +totalCount: number,
    +nodes: ?$ReadOnlyArray<?{|
      +id?: string,
      +removed?: boolean,
      +tenant_id?: ?string,
      +parent?: ?{|
        +__typename: string,
        +name?: string,
        +id?: string,
        +logo_display_url?: string,
        +multi_roles_enabled?: boolean,
      |},
      +title?: string,
    |}>,
  |},
  +$fragmentRefs: UserCardLoader_user$ref,
  +$refType: NavigationProvider_user$ref,
|};
export type NavigationProvider_user$data = NavigationProvider_user;
export type NavigationProvider_user$key = {
  +$data?: NavigationProvider_user$data,
  +$fragmentRefs: NavigationProvider_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "removed",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tenant_id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "logo_display_url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "privacyUrl",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "tncUrl",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "kind": "InlineFragment",
  "selections": [
    (v7/*: any*/),
    (v0/*: any*/)
  ],
  "type": "RoleParentInterface",
  "abstractKey": "__isRoleParentInterface"
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "multi_roles_enabled",
  "storageKey": null
},
v11 = [
  (v3/*: any*/),
  (v4/*: any*/),
  (v0/*: any*/),
  (v7/*: any*/),
  (v10/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "NavigationProvider_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/),
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "parent",
              "plural": false,
              "selections": [
                (v3/*: any*/),
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v4/*: any*/),
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "type": "Tenant",
                  "abstractKey": null
                },
                {
                  "kind": "InlineFragment",
                  "selections": [
                    (v5/*: any*/),
                    (v6/*: any*/)
                  ],
                  "type": "TenantGroup",
                  "abstractKey": null
                },
                (v8/*: any*/)
              ],
              "storageKey": null
            },
            (v9/*: any*/)
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": (v11/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "current_tenants",
      "plural": true,
      "selections": (v11/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                (v1/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "parent",
                  "plural": false,
                  "selections": [
                    (v3/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v4/*: any*/),
                        (v10/*: any*/)
                      ],
                      "type": "Tenant",
                      "abstractKey": null
                    },
                    (v8/*: any*/)
                  ],
                  "storageKey": null
                },
                (v9/*: any*/)
              ],
              "type": "RoleInterface",
              "abstractKey": "__isRoleInterface"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCardLoader_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '0627f8138814f5ce161166c28d4b3a9d';

module.exports = node;
