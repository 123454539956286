/*
 * @flow
 */

import axios from 'axios'

import { type Event } from 'services/analytics/eventDispatcher'
import Sentry from 'shared/services/sentry'

export type ServerEvent = {
  action_type: string,
  path: string,
  timestamp: Date,
} & Event

export default (event: ServerEvent) => {
  return axios
    .post('/api/v1/analytics/register_event', event)
    .catch(Sentry.captureException)
}
