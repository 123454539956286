// @flow

import { useContext } from 'react'

import {
  type FeatureToggleContextValueType,
  FeatureToggleContext,
} from 'containers/FeatureToggle'

type ReturnType = FeatureToggleContextValueType

export function useFeatureToggle(): ReturnType {
  return useContext(FeatureToggleContext)
}
