// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

type PropsType = {
  children: Node,
  componentId?: string,
  handleClick: () => void,
  hasData: boolean,
  styles?: { [className: string]: string },
}

const styleRules = ({ hasData = true, theme }) => ({
  FancyContainer: {
    className: 'FancyContainer',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    alignItems: hasData ? 'initial' : 'center',
    justifyContent: hasData ? 'space-between' : 'center',
    width: '368px',
    height: '308px',
    marginBottom: theme.spacing(1),
    padding: theme.spacing(0.5),
    backgroundColor: hasData
      ? theme.palette.bodyBackgroundColor
      : theme.palette.dividerColor,
    border: `1px solid ${theme.component.borderColor}`,
    borderRadius: '5px',
    cursor: 'pointer',
  },
})

const FancyContainer = ({
  componentId,
  styles = {},
  children,
  handleClick,
}: PropsType) => (
  <div
    className={styles.FancyContainer}
    data-component-id={componentId}
    data-testid={componentId}
    onClick={handleClick}
    onKeyPress={handleClick}
    tabIndex="0"
    role="button"
  >
    {children}
  </div>
)

export default connect(styleRules)(FancyContainer)
