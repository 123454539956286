/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthCard_user_trackable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthPreferences_user_trackables$ref: FragmentReference;
declare export opaque type HealthPreferences_user_trackables$fragmentType: HealthPreferences_user_trackables$ref;
export type HealthPreferences_user_trackables = {|
  +nodes: ?$ReadOnlyArray<?{|
    +id: string,
    +in_plan: boolean,
    +trackable: {|
      +id: string
    |},
    +$fragmentRefs: HealthCard_user_trackable$ref,
  |}>,
  +$refType: HealthPreferences_user_trackables$ref,
|};
export type HealthPreferences_user_trackables$data = HealthPreferences_user_trackables;
export type HealthPreferences_user_trackables$key = {
  +$data?: HealthPreferences_user_trackables$data,
  +$fragmentRefs: HealthPreferences_user_trackables$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthPreferences_user_trackables",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "UserTrackable",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "in_plan",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Trackable",
          "kind": "LinkedField",
          "name": "trackable",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "HealthCard_user_trackable"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserTrackableConnection",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b3ba0253ee42537aa4b3a2c7141e4e8a';

module.exports = node;
