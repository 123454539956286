// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import Can from 'react-ui/utils/Can'
import { useQuestionnaireSession } from 'platform_web/features/Questionnaire'
import { Button, DropDownMenu, DropDownMenuItem, Icon } from 'care-ui'
import ArrowDown from 'care-ui/atoms/icons/arrow-down.svg'

import { retakeQuestionnaireQuery } from './query/RetakeQuestionnaireDropdown'

import type { RetakeQuestionnaireDropdown_individual } from './query/__generated__/RetakeQuestionnaireDropdown_individual.graphql'

type PropsType = {
  individual: RetakeQuestionnaireDropdown_individual,
}

const RetakeQuestionnaireDropdown = (props: PropsType) => {
  const { t: translation } = useTranslation('dashboard')
  const { individual: { tenant } } = props
  const { initial_questionnaire, summary_questionnaire } = tenant || {}

  const { start: startInitial } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId: initial_questionnaire?.id,
  })

  const { start: startSummary } = useQuestionnaireSession({
    answererRoleType: 'INDIVIDUAL',
    questionnaireId: summary_questionnaire?.id,
  })

  return (
    <Can>
      {({ CAN_CREATE_USER_QUESTIONNAIRE }) => {
        return CAN_CREATE_USER_QUESTIONNAIRE ? (
          <DropDownMenu
            position="end"
            dropDownTrigger={
              <Button
                variant="secondary"
                ariaLabel="Retake questionnaire"
                dataTestId="retake-questionnaire"
              >
                {translation('retake_questionnaire')}
                <Icon as={ArrowDown} />
              </Button>
            }
          >
            <>
              <DropDownMenuItem
                key="retakeSummaryQuestionnaire-dropdown"
                onClick={startSummary}
              >
                {translation('summary_questionnaire')}
              </DropDownMenuItem>

              <DropDownMenuItem
                key="retakeInitialQuestionnaire-dropdown"
                onClick={startInitial}
              >
                {translation('full_questionnaire')}
              </DropDownMenuItem>
            </>
          </DropDownMenu>
        ) : null
      }}
    </Can>
  )
}

export default createFragmentContainer(RetakeQuestionnaireDropdown,retakeQuestionnaireQuery)
