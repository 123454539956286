// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'
import { useRouter } from 'found'

import IndividualTasksItem from './IndividualTasksItem'

import type { AssessmentRequestsTask_individual } from './__generated__/AssessmentRequestsTask_individual.graphql'

type PropsType = {
  individual: ?AssessmentRequestsTask_individual,
}
const AssessmentRequestsTaskBase = ({ individual }: PropsType) => {
  const { t: translation } = useTranslation('dashboard')
  const { router } = useRouter()
  const { user } = individual || {}

  const REQUEST_ASSESSMENTS = window.GLOBALS.features?.REQUEST_ASSESSMENTS

  if (!REQUEST_ASSESSMENTS) return null

  const routeName = user ? 'user_assessment_requests' : 'assessment_requests'
  const header = user
    ? translation('request_assessments')
    : translation('view_requested_assessments')
  const description = user
    ? translation('send_an_email_with_a_list_of_assessments_to_be_completed')
    : translation(
        'view_requested_assessments_from_your_clinician_and_review_previous_results',
      )

  const handleClick = () => {
    router.push({
      name: routeName,
      params: {
        id: user?.id,
      },
    })
  }

  return (
    <IndividualTasksItem header={header} onClick={handleClick}>
      {description}
    </IndividualTasksItem>
  )
}

export default createFragmentContainer(AssessmentRequestsTaskBase, {
  individual: graphql`
    fragment AssessmentRequestsTask_individual on IndividualRole {
      user {
        id
      }
    }
  `,
})
