/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type WelcomePageContainer_userCurrentRole$ref: FragmentReference;
declare export opaque type WelcomePageContainer_userCurrentRole$fragmentType: WelcomePageContainer_userCurrentRole$ref;
export type WelcomePageContainer_userCurrentRole = {|
  +role_type: RoleTypes,
  +id?: string,
  +initial_questionnaire_status?: string,
  +tenant?: ?{|
    +summary_questionnaire: ?{|
      +id: string
    |},
    +initial_questionnaire: {|
      +id: string
    |},
  |},
  +$refType: WelcomePageContainer_userCurrentRole$ref,
|};
export type WelcomePageContainer_userCurrentRole$data = WelcomePageContainer_userCurrentRole;
export type WelcomePageContainer_userCurrentRole$key = {
  +$data?: WelcomePageContainer_userCurrentRole$data,
  +$fragmentRefs: WelcomePageContainer_userCurrentRole$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "WelcomePageContainer_userCurrentRole",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role_type",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "initial_questionnaire_status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Tenant",
          "kind": "LinkedField",
          "name": "tenant",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Questionnaire",
              "kind": "LinkedField",
              "name": "summary_questionnaire",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Questionnaire",
              "kind": "LinkedField",
              "name": "initial_questionnaire",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "IndividualRole",
      "abstractKey": null
    }
  ],
  "type": "RoleInterface",
  "abstractKey": "__isRoleInterface"
};
})();
// prettier-ignore
(node/*: any*/).hash = '856e07d654b970d542539e3e94e1a2f9';

module.exports = node;
