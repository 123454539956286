/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type HelpNowPage_tenant$ref: FragmentReference;
declare export opaque type HelpNowPage_tenant$fragmentType: HelpNowPage_tenant$ref;
export type HelpNowPage_tenant = {|
  +help_now_providers: $ReadOnlyArray<{|
    +chat_url: ?string,
    +description: ?string,
    +hours: ?string,
    +id: string,
    +slug: string,
    +logo_url: ?string,
    +phone_number: ?string,
    +sequence: number,
    +sms: ?string,
    +title: string,
    +url: ?string,
  |}>,
  +emergencyNumber: string,
  +$refType: HelpNowPage_tenant$ref,
|};
export type HelpNowPage_tenant$data = HelpNowPage_tenant;
export type HelpNowPage_tenant$key = {
  +$data?: HelpNowPage_tenant$data,
  +$fragmentRefs: HelpNowPage_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HelpNowPage_tenant",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "HelpNowProvider",
      "kind": "LinkedField",
      "name": "help_now_providers",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "chat_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "hours",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "slug",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "logo_url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "phone_number",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sequence",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "sms",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "title",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "emergencyNumber",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9991cb4673ff2ce29f33d36f61479b78';

module.exports = node;
