/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ClinicianDropdown_user$ref: FragmentReference;
declare export opaque type ClinicianDropdown_user$fragmentType: ClinicianDropdown_user$ref;
export type ClinicianDropdown_user = {|
  +id: string,
  +staff_individuals_page_actions: {|
    +scores: $ReadOnlyArray<string>
  |},
  +$refType: ClinicianDropdown_user$ref,
|};
export type ClinicianDropdown_user$data = ClinicianDropdown_user;
export type ClinicianDropdown_user$key = {
  +$data?: ClinicianDropdown_user$data,
  +$fragmentRefs: ClinicianDropdown_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ClinicianDropdown_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualsTableAction",
      "kind": "LinkedField",
      "name": "staff_individuals_page_actions",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "scores",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '99280670d7df5b4d7a43b98a5354665a';

module.exports = node;
