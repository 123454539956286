// @flow

import React from 'react'
import { createComponent } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { Column, Container, Row } from 'react-ui/components/Grid'
import Section from 'react-ui/components/Section'
import Spacer from 'react-ui/components/Spacer'
import createComponentId from 'shared/services/id'
import { Notice } from 'shared/ui/Typography/Icons'

import { verticallyCentered } from './ReadOnlyBanner.style'

type PropsType = {
  componentId?: string,
}

const defaultId = createComponentId(__filename)

const VerticallyCentered = createComponent(verticallyCentered, 'div')

const ReadOnlyBanner = (props: PropsType) => {
  const { componentId = defaultId } = props

  const { t: translation } = useTranslation('shared')

  return (
    <Section className={componentId} color="grey" noPadding>
      <Container>
        <Row>
          <Column col={3} />

          <Column col={9}>
            <VerticallyCentered>
              <Notice color="white" size="ml" />

              <Spacer units={0.5} axis="horizontal" />

              <span>
                <b>{translation('you_are_in_read_only_mode')}</b>{' '}
                {translation(
                  'please_contact_your_service_to_change_these_settings',
                )}
              </span>
            </VerticallyCentered>
          </Column>
        </Row>
      </Container>
    </Section>
  )
}

export default ReadOnlyBanner
