/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarListLoader_data$ref = any;
type AvatarLoader_user$ref = any;
type EditEmrModal_individual$ref = any;
type IndividualCliniciansList_individual$ref = any;
type UserActivityCardLoader_user$ref = any;
export type EmrStatusEnumType = "mandatory" | "none" | "optional" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualsTable_individual$ref: FragmentReference;
declare export opaque type IndividualsTable_individual$fragmentType: IndividualsTable_individual$ref;
export type IndividualsTable_individual = {|
  +do_with_clinician: {|
    +requested: boolean,
    +question_sets: $ReadOnlyArray<{|
      +id: string,
      +label: string,
    |}>,
  |},
  +duty_of_care_clinicians: $ReadOnlyArray<{|
    +id: string,
    +user: {|
      +id: string,
      +name: string,
      +$fragmentRefs: AvatarLoader_user$ref,
    |},
  |}>,
  +escalated: boolean,
  +id: string,
  +individual_detail: ?{|
    +id: string,
    +emr_user_id: ?string,
    +discharged_at: ?any,
  |},
  +initial_questionnaire_status: string,
  +clinicians: $ReadOnlyArray<{|
    +$fragmentRefs: AvatarListLoader_data$ref
  |}>,
  +role_status: string,
  +removed_at: ?any,
  +tenant: ?{|
    +stratified_scoring: boolean,
    +summary_questionnaire: ?{|
      +answerable: ?boolean,
      +id: string,
    |},
    +emr_status: EmrStatusEnumType,
    +emr_integration: ?{|
      +emr_provider: {|
        +user_id_field_name: string
      |}
    |},
  |},
  +user: {|
    +id: string,
    +email: string,
    +name: string,
    +legal_name: ?string,
    +invitation_delta: ?string,
    +preferred_name: ?string,
    +last_seen_at: ?any,
    +invitation_created_at: ?any,
    +invitation_expired: boolean,
    +invitation_requested_at: ?any,
    +invitation_accepted: boolean,
    +invitation_accepted_at: ?any,
    +personal_details: {|
      +date_of_birth: ?any
    |},
    +stratified_scoring_result: ?{|
      +status: string,
      +cluster_probabilities: $ReadOnlyArray<{|
        +label: string,
        +probability: number,
      |}>,
    |},
    +$fragmentRefs: UserActivityCardLoader_user$ref,
  |},
  +canBecomeActive: boolean,
  +$fragmentRefs: EditEmrModal_individual$ref & IndividualCliniciansList_individual$ref,
  +$refType: IndividualsTable_individual$ref,
|};
export type IndividualsTable_individual$data = IndividualsTable_individual;
export type IndividualsTable_individual$key = {
  +$data?: IndividualsTable_individual$data,
  +$fragmentRefs: IndividualsTable_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndividualsTable_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "DoWithClinician",
      "kind": "LinkedField",
      "name": "do_with_clinician",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "requested",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionSet",
          "kind": "LinkedField",
          "name": "question_sets",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            (v1/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "duty_of_care_clinicians",
      "args": null,
      "concreteType": "ClinicianRole",
      "kind": "LinkedField",
      "name": "clinicians",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            (v2/*: any*/),
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "AvatarLoader_user"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "escalated",
      "storageKey": null
    },
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualDetail",
      "kind": "LinkedField",
      "name": "individual_detail",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emr_user_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "discharged_at",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initial_questionnaire_status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianRole",
      "kind": "LinkedField",
      "name": "clinicians",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AvatarListLoader_data"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role_status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "removed_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "stratified_scoring",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "summary_questionnaire",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "answerable",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emr_status",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "EmrIntegrationType",
          "kind": "LinkedField",
          "name": "emr_integration",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmrProvider",
              "kind": "LinkedField",
              "name": "emr_provider",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_id_field_name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "legal_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_delta",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "preferred_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "last_seen_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_created_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_expired",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_requested_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_accepted",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "invitation_accepted_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualPersonalDetail",
          "kind": "LinkedField",
          "name": "personal_details",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "date_of_birth",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "StratifiedScoringResult",
          "kind": "LinkedField",
          "name": "stratified_scoring_result",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "status",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "ClusterProbability",
              "kind": "LinkedField",
              "name": "cluster_probabilities",
              "plural": true,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "probability",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserActivityCardLoader_user"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "canBecomeActive",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "EditEmrModal_individual"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IndividualCliniciansList_individual"
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '640aaf05a67d23cc5d1e431f7d974047';

module.exports = node;
