// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { useLazyLoadQuery } from 'react-relay'
import { useRouter } from 'found'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Link from 'react-ui/components/Link'
import Section from 'react-ui/components/Section'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { SuspenseLoader } from 'care-ui'

import { listQuery } from '../../queries/SupportingSupporteeQuestionnaireSubmissionPage'
import SupportingSupporteeQuestionnaireSubmissionComponent from '../SupportingSupporteeQuestionnaireSubmissionComponent'

import {
  informationStyle,
  wrapperStyle,
} from './SupportingSupporteeQuestionnaireSubmissionPage.style'

const SupportingSupporteeQuestionnaireSubmissionPageLoader = () => {
  const { css } = useFela()

  const { match } = useRouter()
  const { params: { id: supporteeUserId } } = match
  const { id } = useCurrentUser()
  const { node } = useLazyLoadQuery(
    listQuery,
    { id, supporteeUserId, count: 10, cursor: null },
    { fetchPolicy: 'network-only' },
  )
  const { current_role, contract } = node || {}
  const { supportees: supporteesFragmentRef } = current_role || {}

  const { t: translation } = useTranslation('supporting')

  return (
    <div className={css(wrapperStyle)}>
      <Section center color="white">
        <Container>
          <Heading level={2} extend={informationStyle}>
            {translation(
              'thank_you_for_successfully_completing_this_questionnaire',
            )}
          </Heading>
        </Container>

        <Container>
          <SupportingSupporteeQuestionnaireSubmissionComponent
            user={node}
            supportees={supporteesFragmentRef}
            supporteeUserId={supporteeUserId}
          />
        </Container>
      </Section>

      <Section center color="white">
        <div className={css(informationStyle)}>
          <div>
            <Heading level={3}>{translation('what_else_can_i_do_now')}</Heading>

            <small>
              {translation(
                'theres_no_right_or_wrong_way_to_go_about_supporting_someone_often_the_first_step_is_having_an_open_conversation_while_this_can_be_difficult_to_do_check_out_the',
              )}&nbsp;
              {contract === 'aus' && (
                <>
                  <Link to="https://www.beyondblue.org.au/about-us/about-our-work/youthbeyondblue/the-check-in-app">
                    {translation('checkin')}
                  </Link>&nbsp;{translation('or')}&nbsp;
                  <Link to="https://au.reachout.com/tools-and-apps/chats-for-life">
                    {translation('chats_for_life')}
                  </Link>&nbsp;{translation('website')}&nbsp;
                </>
              )}
              {contract === 'ahs' && (
                <>
                  <Link to="https://kidshelpphone.ca/get-info/need-to-have-a-tough-conversation-with-someone-heres-how/">
                    {translation('kids_help_phone')}
                  </Link>&nbsp;{translation('or')}&nbsp;
                  <Link to="https://albertahealthservices.ca/amh/amh.aspx">
                    {translation('alberta_health_services')}
                  </Link>&nbsp;{translation('website')}&nbsp;
                </>
              )}
              {contract === 'london' && (
                <>
                  <Link to="https://kidshelpphone.ca/get-info/need-to-have-a-tough-conversation-with-someone-heres-how/">
                    {translation('kids_help_phone')}
                  </Link>&nbsp;{translation('website_or_contact')}&nbsp;
                  <Link to="mail:femapresearch@lhsc.on.ca">
                    femapresearch@lhsc.on.ca
                  </Link>
                  {translation('or_call_5196466000_ext_65196')}&nbsp;
                </>
              )}
              {translation('to_help_you_plan_better_for_these_conversations')}
            </small>
          </div>
          <div>
            <Heading level={3}>
              {translation('what_is_this_questionnaire_for')}
            </Heading>

            <small>
              {translation(
                'this_questionnaire_will_help_to_provide_different_perspectives_on_the_health_and_well_being_of_the_person_you_are_caring_for',
              )}
            </small>
          </div>

          <div>
            <Heading level={3}>
              {translation('who_will_see_your_answers')}
            </Heading>

            <small>
              {translation(
                'your_answers_will_be_seen_by_the_person_you_are_caring_for_and_their_connected_clinicians',
              )}
            </small>
          </div>

          <div>
            <Heading level={3}>
              {translation('how_often_should_you_answer_this_questionnaire')}
            </Heading>

            <small>
              {translation(
                'you_can_reanswer_the_questionnaire_at_anytime_by_logging_into_the_innowell_platform_the_person_you_are_caring_for_can_also_send_an_email_request_for_you_to_update_the_questionnaire',
              )}
            </small>
          </div>
        </div>
      </Section>
    </div>
  )
}

const SupportingSupporteeQuestionnaireSubmissionPage = () => (
  <SuspenseLoader>
    <SupportingSupporteeQuestionnaireSubmissionPageLoader />
  </SuspenseLoader>
)

export default SupportingSupporteeQuestionnaireSubmissionPage
