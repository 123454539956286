/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardChartContext_user$ref: FragmentReference;
declare export opaque type ScoreCardChartContext_user$fragmentType: ScoreCardChartContext_user$ref;
export type ScoreCardChartContext_user = {|
  +id: string,
  +activity: $ReadOnlyArray<{|
    +steps: number,
    +date: any,
    +goal: ?number,
  |}>,
  +sleep: $ReadOnlyArray<{|
    +date: any,
    +asleep: {|
      +startTime: any,
      +endTime: any,
      +hours: number,
      +minutes: number,
    |},
  |}>,
  +user_trackable: {|
    +data_sets: $ReadOnlyArray<{|
      +answered_by: {|
        +user?: {|
          +name: string
        |},
        +role_type?: RoleTypes,
      |},
      +points: $ReadOnlyArray<{|
        +id: string,
        +date: string,
        +scaled_value: ?number,
        +value: ?number,
        +raw_score: ?number,
        +trajectory_data_points?: ?$ReadOnlyArray<{|
          +id: string,
          +date: string,
          +value: ?number,
          +upper: ?number,
          +lower: ?number,
        |}>,
      |}>,
    |}>,
    +additional_plot_data_sets: ?$ReadOnlyArray<{|
      +answered_by: {|
        +user?: {|
          +name: string
        |},
        +role_type?: RoleTypes,
      |},
      +points: $ReadOnlyArray<{|
        +id: string,
        +date: string,
        +raw_score: ?number,
        +trajectory_data_points?: ?$ReadOnlyArray<{|
          +id: string,
          +date: string,
          +value: ?number,
          +upper: ?number,
          +lower: ?number,
        |}>,
      |}>,
    |}>,
    +trackable: {|
      +id: string,
      +label: string,
      +plot_raw_score: boolean,
      +formula_range_group: {|
        +id: string,
        +inversed_range: boolean,
      |},
      +additional_plot_formula: ?{|
        +id: string,
        +display_name: ?string,
        +formula_ranges: ?$ReadOnlyArray<{|
          +id: string,
          +boundLower: number,
          +boundUpper: ?number,
        |}>,
      |},
    |},
    +change_log_entries: ?$ReadOnlyArray<{|
      +id: string,
      +created_at: any,
      +reason: string,
    |}>,
  |},
  +$refType: ScoreCardChartContext_user$ref,
|};
export type ScoreCardChartContext_user$data = ScoreCardChartContext_user;
export type ScoreCardChartContext_user$key = {
  +$data?: ScoreCardChartContext_user$data,
  +$fragmentRefs: ScoreCardChartContext_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Variable",
    "name": "end_date",
    "variableName": "fitbit_end_date"
  },
  {
    "kind": "Variable",
    "name": "start_date",
    "variableName": "fitbit_start_date"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "end_time",
    "variableName": "trackable_end_time"
  },
  {
    "kind": "Variable",
    "name": "start_time",
    "variableName": "trackable_start_time"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "concreteType": null,
  "kind": "LinkedField",
  "name": "answered_by",
  "plural": false,
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_type",
          "storageKey": null
        }
      ],
      "type": "RoleInterface",
      "abstractKey": "__isRoleInterface"
    }
  ],
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "value",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "raw_score",
  "storageKey": null
},
v7 = {
  "condition": "is_clinician",
  "kind": "Condition",
  "passingValue": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "TrajectoryPredictionViewData",
      "kind": "LinkedField",
      "name": "trajectory_data_points",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v2/*: any*/),
        (v5/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "upper",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "lower",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ]
};
return {
  "argumentDefinitions": [
    {
      "kind": "RootArgument",
      "name": "fitbit_end_date"
    },
    {
      "kind": "RootArgument",
      "name": "fitbit_start_date"
    },
    {
      "kind": "RootArgument",
      "name": "is_clinician"
    },
    {
      "kind": "RootArgument",
      "name": "trackable_end_time"
    },
    {
      "kind": "RootArgument",
      "name": "trackable_id"
    },
    {
      "kind": "RootArgument",
      "name": "trackable_start_time"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardChartContext_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "ActivityData",
      "kind": "LinkedField",
      "name": "activity",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "steps",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "goal",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": (v1/*: any*/),
      "concreteType": "SleepData",
      "kind": "LinkedField",
      "name": "sleep",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "TimeRangeNoZone",
          "kind": "LinkedField",
          "name": "asleep",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "startTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endTime",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hours",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minutes",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "trackable_id",
          "variableName": "trackable_id"
        }
      ],
      "concreteType": "UserTrackable",
      "kind": "LinkedField",
      "name": "user_trackable",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "UserTrackableDataSet",
          "kind": "LinkedField",
          "name": "data_sets",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserTrackableData",
              "kind": "LinkedField",
              "name": "points",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "scaled_value",
                  "storageKey": null
                },
                (v5/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "UserTrackableDataSet",
          "kind": "LinkedField",
          "name": "additional_plot_data_sets",
          "plural": true,
          "selections": [
            (v4/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "UserTrackableData",
              "kind": "LinkedField",
              "name": "points",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                (v2/*: any*/),
                (v6/*: any*/),
                (v7/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Trackable",
          "kind": "LinkedField",
          "name": "trackable",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "plot_raw_score",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "FormulaRangeGroup",
              "kind": "LinkedField",
              "name": "formula_range_group",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "inversed_range",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Formula",
              "kind": "LinkedField",
              "name": "additional_plot_formula",
              "plural": false,
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "display_name",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "FormulaRange",
                  "kind": "LinkedField",
                  "name": "formula_ranges",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "boundLower",
                      "storageKey": null
                    },
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "boundUpper",
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": (v3/*: any*/),
          "concreteType": "ChangeLogEntry",
          "kind": "LinkedField",
          "name": "change_log_entries",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "created_at",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "reason",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '5c85b8b120531b0e92032843fb3da587';

module.exports = node;
