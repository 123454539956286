// @flow

import * as Sentry from '@sentry/browser'

type SentryUserType = {
  roleType: ?string,
  tenant: ?string,
  userId: ?string,
}

const globals = window.GLOBALS || {}

if (globals.sentryDSNPublic) {
  Sentry.init({
    dsn: globals.sentryDSNPublic,
    environment: `${globals.appEnvironment}-${globals.appRegion}`,
    release: process.env.RELEASE,
    ignoreErrors: ['UnhandledRejection'],
  })
}

const SentryUserConfig = (userData: SentryUserType) => {
  Sentry.configureScope(function(scope) {
    scope.setUser({ ...userData, ip_address: '{{auto}}' })
  })
}

export default Sentry
export { SentryUserConfig }
