/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$ref: FragmentReference;
declare export opaque type StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$fragmentType: StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$ref;
export type StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet = {|
  +progress: {|
    +step: number,
    +total: number,
  |},
  +question_set: {|
    +description: ?string,
    +label: string,
  |},
  +$refType: StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$ref,
|};
export type StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$data = StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet;
export type StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$key = {
  +$data?: StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$data,
  +$fragmentRefs: StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StickyQuestionnaireHeaderLoader_userQuestionnairesQuestionSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Progress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "step",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "description",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionnairesQuestionSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '705f7d9fcddd49fc1a42d56cd9299c87';

module.exports = node;
