// @flow

import React, { type Node, useContext } from 'react'
import { connect } from 'react-fela'

import Button, { ButtonStatusContext } from 'react-ui/components/Button'
import Card from 'react-ui/components/Card/Card'
import { H3 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import Spacer from 'react-ui/components/Spacer'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => {
  const { gray } = theme.palette.questionnaireCard

  return {
    iconStyle: { color: gray },
    contentWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
    },
    titleWrapper: {
      marginTop: '0',
      position: 'relative',
      textAlign: 'center',
      top: '-20px',
      [theme.breakpoints.queries.md]: { textAlign: 'center', margin: '5px 0' },
    },
    textWrapper: {
      maxWidth: '100%',
      letterSpacing: '0',
      lineHeight: '28px',
      margin: '0',
      '> ul': {
        textAlign: 'left',
        padding: '20px',
        margin: 'auto',
      },
    },
    buttonWrapper: {
      display: 'flex',
      justifyContent: 'center',
      padding: '5px',
      width: '100%',
      [theme.breakpoints.queries.sm]: { '>a': { width: '100%' } },
    },
  }
}

type PropsType = FelaPropsType & {
  avatar: Node,
  customNote?: Node,
  onButtonClick?: () => void,
  points: Array<string>,
  title?: string,
}

const QuestionnaireInfoCard = (props: PropsType) => {
  const {
    points,
    title,
    avatar,
    styles,
    rules,
    onButtonClick,
    customNote,
  } = props

  const { isLoading } = useContext(ButtonStatusContext)

  return (
    <Card maxWidth="34rem" padding="1.5rem 1.5rem 4rem">
      <div className={styles.contentWrapper}>
        {avatar && <Icon as={avatar} scale={1.0} extend={rules.iconStyle} />}

        <Spacer units={0.5} />

        {title && <H3 extend={rules.titleWrapper}>{title}</H3>}

        {points && (
          <div className={styles.textWrapper}>
            <ul>
              {points.map(val => <li key={val}>{val}</li>)}
              {customNote}
            </ul>
          </div>
        )}

        <div className={styles.buttonWrapper}>
          {onButtonClick && (
            <Button shape="pill" onClick={onButtonClick} disabled={isLoading}>
              Start
            </Button>
          )}
        </div>
      </div>
    </Card>
  )
}

export default connect(styleRules)(QuestionnaireInfoCard)
