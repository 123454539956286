// @flow

import type { formulaRangesType } from './defaultOptions'

const getTrajectoryData = (dataPoints: Array<Object>) => {
  const listSet = []
  dataPoints.map(({ trajectory_data_points = [] }) => {
    const data = trajectory_data_points?.map(
      ({ date: trajectoryDate, lower, upper }) => [
        new Date(trajectoryDate).getTime(),
        lower,
        upper,
      ],
    )

    return listSet.push(data)
  })

  return listSet
}

/** Return data as
 * {
 *  name:"User name",
 *  data:
 *  [
 *    [dataStamp, value]
 *  ]
 * }
 *
 */
export const formatDataSeries = (
  dataSet: Array<Object>,
  lineOptions?: Object,
) => {
  const listSet = []
  dataSet.map(
    ({ points = [], answered_by: { role_type = '', user: { name = '' } } }) => {
      const set = { ...(lineOptions: any) }

      const data = points.map(({ date, raw_score, value, scaled_value }) => ({
        x: new Date(date).getTime(),
        y: raw_score,
        scaled_value,
        formulaValue: value,
      }))

      const trajectoryData = getTrajectoryData(points)

      set.name = name
      set.data = data
      set.role = role_type
      set.trajectoryData = trajectoryData

      return listSet.push(set)
    },
  )

  return listSet
}

/** Generate an index of labels
 *  {
 *  Concern: 4,
 *  Low: 3,
 *  Medium: 5
 *  }
 */
export function generateIndex(formulas: formulaRangesType) {
  const labelIndex = {}
  /* eslint no-return-assign: "error" */
  formulas.map(item => (labelIndex[item.label] = item.value))
  return labelIndex
}

/** Generate an inverted index of labels
 *  {
 *  4: Concern,
 *  3: Low,
 *  5: Medium
 *  }
 */

export function generateInvertedIndex(formulas: formulaRangesType) {
  const labelIndex = {}
  /* eslint no-return-assign: "error" */
  formulas.map(item => (labelIndex[item.value] = item.label))
  return labelIndex
}

export const getSmallestKey = (obj: Object) => {
  const arr = Object.keys(obj).map(key => obj[key])
  return Math.min.apply(null, arr)
}

export const getBiggestKey = (obj: Object) => {
  const arr = Object.keys(obj).map(key => obj[key])
  return Math.max.apply(null, arr)
}

/*
 * Used as helper to calculate activity
 */
export const calculateGoal = (steps: number, goal: ?number) => {
  const filterGoal = typeof goal !== 'number' ? 0 : goal
  if (steps < filterGoal) {
    return 0
  }
  return steps - filterGoal
}

/*
 * Used as helper to calculate steps, if user has more steps than the goal will subtract the goal from the steps because the goal will be already plotted on top giving the total number
 */
export const calculateSteps = (steps: number, goal: ?number) => {
  const filterGoal = typeof goal !== 'number' ? 0 : goal
  return Math.min(steps, filterGoal)
}

/**
 * Filter a series to only points within a specified date range.
 * This instantly cuts down the cached data points when switching date ranges
 * (e.g. Month -> Week views) before the final data comes back from the server.
 */
export const withinDateRange = ({
  series,
  startDate,
  endDate,
  accessor,
}: {
  accessor?: Object => Date,
  endDate: Date,
  series: $ReadOnlyArray<Object>,
  startDate: Date,
}): Array<Object> =>
  series.filter(point => {
    const date = accessor
      ? accessor(point)
      : new Date(Array.isArray(point) ? point[0] : point.date)
    return date >= startDate && date <= endDate
  })
