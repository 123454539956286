// @flow

import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { updateRegistrationRequest } from 'shared/services/api/registration'
import Sentry from 'shared/services/sentry'
import { Layout as l } from 'shared/ui/Styles'
import EditRegistrationForm, {
  type EditRegistrationFormValues,
} from 'platform_web/pages/authentication/components/EditRegistrationForm'
import { Heading } from 'care-ui'

import type {
  FormikSetStatusFunc,
  FormikSetSubmittingFunc,
} from 'services/flow'

const EditRegistrationPage = () => {
  const [updated, setUpdated] = useState(false)

  const { t: translation } = useTranslation('auth')

  const formatError = (errors: any) => {
    if (errors.password) {
      return `${translation('sorry_your_new_password_is')} ${
        errors.password[0]
      }`
    }
    return translation('sorry_we_couldnt_change_your_passowrd')
  }

  const updateRegistration = async (
    values: EditRegistrationFormValues,
    setSubmitting: FormikSetSubmittingFunc,
    setStatus: FormikSetStatusFunc,
  ) => {
    try {
      await updateRegistrationRequest(values, {
        onSuccess: () => {
          setUpdated(true)
        },
        onError: error => {
          switch (error.response.status) {
            case 422:
              error.response
                .json()
                .then(body => {
                  const message = formatError(body?.errors)
                  setStatus({ error: message })
                  setSubmitting(false)
                  return body
                })
                .catch(Sentry.captureException)
              break

            default:
              setStatus({
                error: translation(
                  'sorry_an_error_occured_with_our_server_weve_notified_our_team_so_that_they_can_address_it_please_try_again',
                ),
              })
              setSubmitting(false)
              break
          }
        },
      })
    } catch (e) {
      // Handle any unforeseen errors
      Sentry.captureException(e)
      setSubmitting(false)
    }
  }

  const renderedForm = () => {
    if (updated) {
      return (
        <div>
          <Heading
            level={3}
            extend={({ theme }) => ({
              color: theme.palette.component.primary.base,
            })}
          >
            {translation('success')}
          </Heading>

          <p>{translation('your_password_has_been_updated')}</p>
        </div>
      )
    }

    return (
      <div>
        <Heading level={3} margin="xs">
          {translation('update_your_password')}
        </Heading>

        <div className={l['my-2']} />
        <EditRegistrationForm onSubmit={updateRegistration} />
      </div>
    )
  }

  return renderedForm()
}

export default EditRegistrationPage
