// @flow

import { difference, extend } from 'lodash/fp'

type PluginConfig = {
  baseEmInPixels: string | number,
  exclude?: Array<string>,
}

const pixelsToRems = ({ baseEmInPixels, exclude = [] }: PluginConfig) => {
  const baseEmValue = parseFloat(baseEmInPixels)
  const pixelValueTest = /([0-9]+(\.[0-9]+)?)px/

  const filteredKeys = obj => {
    const keys = Object.keys(obj)
    return keys ? difference(keys, exclude) : []
  }

  const pixelValueToRemString = px => `${parseFloat(px) / baseEmValue}rem`

  const transformStyleString = styleString => {
    let newString = styleString
    let matches

    do {
      matches = newString.match(pixelValueTest)
      if (!matches) {
        break
      }

      const pixelString = matches[0]
      const remString = pixelValueToRemString(matches[1])

      newString = newString.replace(pixelString, remString)
    } while (matches)

    return newString
  }

  const transformStyleObject = (style: Object) => {
    const propertiesToCheck = filteredKeys(style)

    const updatedStyles = propertiesToCheck.reduce((newStyles, property) => {
      const styleValue = style[property]
      /* eslint-disable no-param-reassign */
      if (typeof styleValue === 'string') {
        newStyles[property] = transformStyleString(styleValue)
      } else if (typeof styleValue === 'object') {
        newStyles[property] = transformStyleObject(styleValue)
      }

      /* eslint-enable no-param-reassign */
      return newStyles
    }, {})

    return extend(style, updatedStyles)
  }

  return transformStyleObject
}

export { pixelsToRems }
