// @flow

import { graphql } from 'react-relay'

export const requestAssessmentButtonIndividualQuery = {
  individual: graphql`
    fragment RequestAssessmentButton_individual on IndividualRole {
      user {
        id
      }
    }
  `,
}
