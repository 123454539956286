// @flow

import React from 'react'
import { map } from 'lodash/fp'

import IndividualDetail from '../IndividualDetail'

import { type FelaStylesType } from 'react-ui/typing'
import { type AboutMeTab_user } from '../../queries/__generated__/AboutMeTab_user.graphql'

type PropsType = {
  styles: FelaStylesType,
  +user: AboutMeTab_user,
}

const DetailsSide = (props: PropsType) => {
  const { user: { profileDisplayFields }, styles } = props

  return (
    <div className={styles.details}>
      <dl>
        {map(
          ({ id, label, displayValue }) => (
            <IndividualDetail
              key={id}
              name={label}
              value={displayValue}
              styles={styles}
            />
          ),
          profileDisplayFields,
        )}
      </dl>
    </div>
  )
}

export default DetailsSide
