// @flow

import { useEffect, useState } from 'react'
import type { GraphQLTaggedNode } from 'relay-runtime'

import useModal from 'care-ui/molecules/hooks/useModal'

type ActionButtonType = {
  action: string,
  disabled: boolean,
  label: string,
  modalComponent: any,
  mutationQuery: GraphQLTaggedNode,
}

type ModalType = {
  actionButtonGroups: Array<ActionButtonType>,
}

const useActionModal = (props: ModalType) => {
  const { actionButtonGroups } = props
  // prettier-ignore
  const [actionType, setActionType] = useState <string>('')
  const { isOpen, closeModal, openModal } = useModal()

  useEffect(
    () => {
      if (actionType) {
        openModal()
      }
    },
    [actionType],
  )

  const handleButtonClick = (action: string) => {
    setActionType(action)
  }

  const handleCloseModal = () => {
    closeModal()
    setActionType('')
  }

  const actionButton = actionButtonGroups.find(
    button => button.action === actionType,
  )
  const ModalComponent = actionButton ? actionButton.modalComponent : null

  return {
    actionButton,
    handleButtonClick,
    handleCloseModal,
    isOpen,
    ModalComponent,
  }
}

export default useActionModal
