// @flow

import { graphql } from 'react-relay'

export const feedbackFormViewer = {
  viewer: graphql`
    fragment FeedbackForm_viewer on Viewer {
      ...FeedbackTopicSelect_viewer
      enums {
        feedback {
          topic_list {
            key
            value
          }
        }
      }
    }
  `,
}
