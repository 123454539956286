// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { format } from 'date-fns/esm/fp'

import InfoCircle from 'react-ui/assets/icons/information-circle.svg'
import Icon from 'react-ui/components/Icon'
import { shortDate } from 'services/dateTime'
import TextToolTip from 'platform_web/components/TextToolTip'

import { scoreCardsChangeLogChangeLog } from '../../queries/ScoreCardsChangeLog'

import {
  containerStyle,
  iconStyle,
  toolTipStyle,
} from './ScoreCardsChangeLog.style'

import type { ScoreCardsChangeLog_changeLog } from '../../queries/__generated__/ScoreCardsChangeLog_changeLog.graphql'

type PropsType = {
  changeLog: ScoreCardsChangeLog_changeLog,
  label: string,
}

const ScoredCardsChangeLog = (props: PropsType) => {
  const { changeLog, label } = props
  const { css } = useFela()

  const date = format(shortDate)(new Date(changeLog.created_at))

  const { t: translation } = useTranslation('scoreCard', {
    keyPrefix: 'scoreCardsChangeLogPage',
  })

  return (
    <div className={css(containerStyle)}>
      <div className={css(iconStyle)}>
        <Icon as={InfoCircle} scale={0.5} />
      </div>

      <div>{`${translation('asOf')} ${date}, ${label} ${translation(
        'wasUpdated',
      )}`}</div>

      <div className={css(toolTipStyle)}>
        <TextToolTip label={translation('learnMore')}>
          {changeLog.reason}
        </TextToolTip>
      </div>
    </div>
  )
}

const ScoredCardsChangeLogLoader = createFragmentContainer(
  ScoredCardsChangeLog,
  scoreCardsChangeLogChangeLog,
)

export default ScoredCardsChangeLogLoader
