// @flow

import * as React from 'react'

type PropsType = {
  +alwaysShowEmpty?: boolean,
  +items: $ReadOnlyArray<Object | string>,
  +itemsType?: string,
  +keyName?: string,
  +nullLabel?: string,
  +value: ?string,
  +valueName?: string,
}

const Select = (props: PropsType) => {
  const {
    alwaysShowEmpty = false,
    items = [],
    itemsType = 'items',
    keyName,
    nullLabel = '-- Please Select --',
    value,
    valueName,
    ...other
  } = props

  if (items.length === 0) {
    return <span>No {itemsType} available</span>
  }

  const objectOption = item => (
    <option key={item.key ? item.key : item[keyName]} value={item[keyName]}>
      {item[valueName]}
    </option>
  )

  const stringOption = item => (
    <option key={item} value={item}>
      {item}
    </option>
  )

  const renderedOption = item =>
    typeof item === 'object' ? objectOption(item) : stringOption(item)

  const renderedOptions = items.map(item => renderedOption(item))

  const renderedPleaseSelect = <option value="">{nullLabel}</option>

  return (
    <select {...(other: any)} value={value}>
      {!value || alwaysShowEmpty ? renderedPleaseSelect : null}
      {renderedOptions}
    </select>
  )
}

export default Select
