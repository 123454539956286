// @flow

import {
  flow,
  isEmpty,
  map,
  reject,
  replace,
  toLower,
  toPairs,
  toString,
  upperFirst,
} from 'lodash/fp'

import Sentry from 'shared/services/sentry'

export type FormType = {
  errors: Array<Object>,
  formData: { [key: string]: string },
}

type ErrorsType = $ReadOnlyArray<{ property: string }>

export function errorsMap(errors: ErrorsType) {
  const mapped = {}
  errors.forEach(error => {
    mapped[error.property.substr(1)] = true
  })
  return mapped
}

export const prepareAnswers = (form: FormType) => {
  const errorsLookup = errorsMap(form.errors)

  return flow(
    toPairs,
    map(([questionId, value]) => ({
      question_id: questionId,
      value: toString(value),
    })),
    reject(
      ({ question_id, value }) =>
        errorsLookup[question_id] === true || isEmpty(value),
    ),
  )(form.formData)
}

const transformMessage = flow(
  toLower,
  upperFirst,
  replace('<=', 'less than or equal to'),
  replace('>=', 'greater than or equal to'),
  replace('<', 'less than'),
  replace('>', 'greater than'),
  replace('=', 'equal to'),
)

type ErrorType = {
  message: string,
  name: string,
}

/**
 * Transforms errors provided by react-json-form-schema. This is for producing nicer validation error messages.
 */
export const transformErrors = (errors: ErrorType): ErrorType =>
  map(error => {
    const updatedError = (() => {
      switch (error.name) {
        case 'required':
          return {
            ...error,
            message: 'This question is required',
          }
        default:
          return {
            ...error,
            message: transformMessage(error.message),
          }
      }
    })()

    return updatedError
  })(errors)

/**
 * Handle errors during GraphQL operations
 * Important note: ensure FE and BE error codes are in sync! (platform_schema.rb)
 */

export const handleGraphqlErrors = (
  errors: {
    extenstions: {
      error_code: string,
    },
    message: string,
  },
  operation: string,
  callback: () => void,
) => {
  const { extensions: { error_code } = {} } = errors[0]
  if (errors) {
    switch (error_code) {
      case 'UserQuestionnaireAlreadyFinished':
        callback()
        break
      default:
        Sentry.captureMessage(
          `${operation} failed due to an error in the mutation`,
        )
    }
  }
}
