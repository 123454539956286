/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type StaffIndividualSelectionList_user$ref = any;
export type RoleSortDirection = "ASC" | "DESC" | "%future added value";
export type RoleSortableAttributes = "activeStatus" | "assignmentCount" | "assignmentName" | "dateOfBirth" | "dischargedStatus" | "email" | "formulaResult" | "individualAssignmentCount" | "initialQuestionnaireStatus" | "invitedAt" | "invitedStatus" | "name" | "recentActivity" | "removedAt" | "removedStatus" | "roleType" | "%future added value";
export type RoleSortable = {|
  column: RoleSortableAttributes,
  direction?: ?RoleSortDirection,
  id?: ?string,
|};
export type StaffIndividualSelectionList_PaginatedQueryVariables = {|
  count: number,
  cursor?: ?string,
  search?: ?string,
  sort?: ?$ReadOnlyArray<RoleSortable>,
  assigned?: ?boolean,
  prioritise?: ?boolean,
  status?: ?$ReadOnlyArray<string>,
|};
export type StaffIndividualSelectionList_PaginatedQueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +$fragmentRefs: StaffIndividualSelectionList_user$ref
    |}
  |}
|};
export type StaffIndividualSelectionList_PaginatedQuery = {|
  variables: StaffIndividualSelectionList_PaginatedQueryVariables,
  response: StaffIndividualSelectionList_PaginatedQueryResponse,
|};
*/


/*
query StaffIndividualSelectionList_PaginatedQuery(
  $count: Int!
  $cursor: String
  $search: String
  $sort: [RoleSortable!]
  $assigned: Boolean
  $prioritise: Boolean
  $status: [String!]
) {
  viewer {
    currentUser {
      ...StaffIndividualSelectionList_user
      id
    }
    id
  }
}

fragment StaffIndividualSelectionList_user on User {
  id
  individuals(first: $count, after: $cursor, filters: {roleStatus: $status, search: $search, sort: $sort, assignedToMe: $assigned, prioritiseEscalations: $prioritise}) {
    edges {
      node {
        id
        legal_name
        user {
          id
          username
        }
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "assigned"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "count"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "prioritise"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "search"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sort"
},
v6 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "status"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "assignedToMe",
        "variableName": "assigned"
      },
      {
        "kind": "Variable",
        "name": "prioritiseEscalations",
        "variableName": "prioritise"
      },
      {
        "kind": "Variable",
        "name": "roleStatus",
        "variableName": "status"
      },
      {
        "kind": "Variable",
        "name": "search",
        "variableName": "search"
      },
      {
        "kind": "Variable",
        "name": "sort",
        "variableName": "sort"
      }
    ],
    "kind": "ObjectValue",
    "name": "filters"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StaffIndividualSelectionList_PaginatedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "StaffIndividualSelectionList_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v6/*: any*/)
    ],
    "kind": "Operation",
    "name": "StaffIndividualSelectionList_PaginatedQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "ClinicianIndividualConnection",
                "kind": "LinkedField",
                "name": "individuals",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianIndividualEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "IndividualRole",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "legal_name",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "User",
                            "kind": "LinkedField",
                            "name": "user",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "username",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": (v8/*: any*/),
                "filters": [
                  "filters"
                ],
                "handle": "connection",
                "key": "Individuals_currentUser_individuals",
                "kind": "LinkedHandle",
                "name": "individuals"
              }
            ],
            "storageKey": null
          },
          (v7/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9d133e4c2f36682df2c412823e070f84",
    "id": null,
    "metadata": {},
    "name": "StaffIndividualSelectionList_PaginatedQuery",
    "operationKind": "query",
    "text": "query StaffIndividualSelectionList_PaginatedQuery(\n  $count: Int!\n  $cursor: String\n  $search: String\n  $sort: [RoleSortable!]\n  $assigned: Boolean\n  $prioritise: Boolean\n  $status: [String!]\n) {\n  viewer {\n    currentUser {\n      ...StaffIndividualSelectionList_user\n      id\n    }\n    id\n  }\n}\n\nfragment StaffIndividualSelectionList_user on User {\n  id\n  individuals(first: $count, after: $cursor, filters: {roleStatus: $status, search: $search, sort: $sort, assignedToMe: $assigned, prioritiseEscalations: $prioritise}) {\n    edges {\n      node {\n        id\n        legal_name\n        user {\n          id\n          username\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '855286a177cbb31438a8397951459e2e';

module.exports = node;
