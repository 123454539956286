// @flow

import { createFragmentContainer, graphql } from 'react-relay'

import UserCard from './UserCard'

export default createFragmentContainer(UserCard, {
  user: graphql`
    fragment UserCardLoader_user on User {
      ...AvatarLoader_user
      username
      id
      legal_name
      preferred_name
    }
  `,
})
