// @flow

/**
 * Given a phone number string, returns a phone number link, per
 * https://tools.ietf.org/html/rfc3966.
 *
 * @param {string} phoneNumber
 */
export const phoneLink = (phoneNumber: string) =>
  `tel:${phoneNumber.replace(/\s/g, '-')}`
