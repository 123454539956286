// @flow

import * as React from 'react'

import Link, { type LinkDestinationType } from './Link'

type PropsType = {
  children: React.Node,
  link?: LinkDestinationType,
}

const OptionalLink = ({ link, children }: PropsType) => {
  if (link) {
    return <Link to={link}>{children}</Link>
  }
  return <>{children}</>
}

export default OptionalLink
