/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ClinicianNoteCardContainer_user$ref = any;
export type ClinicianNoteCreateInput = {|
  clientMutationId?: ?string,
  clinician_note: ClinicianNoteCreateInputObject,
|};
export type ClinicianNoteCreateInputObject = {|
  about_id: string,
  text: string,
|};
export type useCreateClinicianNoteMutationVariables = {|
  input: ClinicianNoteCreateInput
|};
export type useCreateClinicianNoteMutationResponse = {|
  +clinicianNoteCreate: ?{|
    +clinician_note: {|
      +about: {|
        +$fragmentRefs: ClinicianNoteCardContainer_user$ref
      |}
    |}
  |}
|};
export type useCreateClinicianNoteMutation = {|
  variables: useCreateClinicianNoteMutationVariables,
  response: useCreateClinicianNoteMutationResponse,
|};
*/


/*
mutation useCreateClinicianNoteMutation(
  $input: ClinicianNoteCreateInput!
) {
  clinicianNoteCreate(input: $input) {
    clinician_note {
      about {
        ...ClinicianNoteCardContainer_user
        id
      }
      id
    }
  }
}

fragment ClinicianNoteCardContainer_user on User {
  id
  clinician_notes(first: 10) {
    edges {
      node {
        ...ClinicianNoteCard_clinician_note
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment ClinicianNoteCard_clinician_note on ClinicianNote {
  text
  created_at
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useCreateClinicianNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClinicianNoteCreatePayload",
        "kind": "LinkedField",
        "name": "clinicianNoteCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianNote",
            "kind": "LinkedField",
            "name": "clinician_note",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "about",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "ClinicianNoteCardContainer_user"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useCreateClinicianNoteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClinicianNoteCreatePayload",
        "kind": "LinkedField",
        "name": "clinicianNoteCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianNote",
            "kind": "LinkedField",
            "name": "clinician_note",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "about",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "ClinicianNoteConnection",
                    "kind": "LinkedField",
                    "name": "clinician_notes",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "ClinicianNoteEdge",
                        "kind": "LinkedField",
                        "name": "edges",
                        "plural": true,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClinicianNote",
                            "kind": "LinkedField",
                            "name": "node",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "text",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "created_at",
                                "storageKey": null
                              },
                              (v2/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "__typename",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "cursor",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "PageInfo",
                        "kind": "LinkedField",
                        "name": "pageInfo",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "endCursor",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "hasNextPage",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "clinician_notes(first:10)"
                  },
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "filters": null,
                    "handle": "connection",
                    "key": "ClinicianNoteCardContainer_clinician_notes",
                    "kind": "LinkedHandle",
                    "name": "clinician_notes"
                  }
                ],
                "storageKey": null
              },
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d207eb00950ec3c633aa3d3083bf0886",
    "id": null,
    "metadata": {},
    "name": "useCreateClinicianNoteMutation",
    "operationKind": "mutation",
    "text": "mutation useCreateClinicianNoteMutation(\n  $input: ClinicianNoteCreateInput!\n) {\n  clinicianNoteCreate(input: $input) {\n    clinician_note {\n      about {\n        ...ClinicianNoteCardContainer_user\n        id\n      }\n      id\n    }\n  }\n}\n\nfragment ClinicianNoteCardContainer_user on User {\n  id\n  clinician_notes(first: 10) {\n    edges {\n      node {\n        ...ClinicianNoteCard_clinician_note\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment ClinicianNoteCard_clinician_note on ClinicianNote {\n  text\n  created_at\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '588946a9fefe137d0f6269dfc18524b7';

module.exports = node;
