/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StaffInvite_staff_admin$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffEmployeesPage_staff_admin$ref: FragmentReference;
declare export opaque type StaffEmployeesPage_staff_admin$fragmentType: StaffEmployeesPage_staff_admin$ref;
export type StaffEmployeesPage_staff_admin = {|
  +$fragmentRefs: StaffInvite_staff_admin$ref,
  +$refType: StaffEmployeesPage_staff_admin$ref,
|};
export type StaffEmployeesPage_staff_admin$data = StaffEmployeesPage_staff_admin;
export type StaffEmployeesPage_staff_admin$key = {
  +$data?: StaffEmployeesPage_staff_admin$data,
  +$fragmentRefs: StaffEmployeesPage_staff_admin$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "StaffEmployeesPage_staff_admin",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "StaffInvite_staff_admin"
    }
  ],
  "type": "StaffAdmin",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'a75c4e141df0c03ff5889a7b574195c0';

module.exports = node;
