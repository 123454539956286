/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type QuestionnaireComplete_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireShowPage_user$ref: FragmentReference;
declare export opaque type QuestionnaireShowPage_user$fragmentType: QuestionnaireShowPage_user$ref;
export type QuestionnaireShowPage_user = {|
  +$fragmentRefs: QuestionnaireComplete_user$ref,
  +$refType: QuestionnaireShowPage_user$ref,
|};
export type QuestionnaireShowPage_user$data = QuestionnaireShowPage_user;
export type QuestionnaireShowPage_user$key = {
  +$data?: QuestionnaireShowPage_user$data,
  +$fragmentRefs: QuestionnaireShowPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireShowPage_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionnaireComplete_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '93770128549b938621bb3020a25dc357';

module.exports = node;
