/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualsPage_tenant$ref: FragmentReference;
declare export opaque type IndividualsPage_tenant$fragmentType: IndividualsPage_tenant$ref;
export type IndividualsPage_tenant = {
  +id: string,
  +important_formulas: $ReadOnlyArray<{
    +label: string,
    +formula: {
      +id: string,
      ...
    },
    ...
  }>,
  ...
};
export type IndividualsPage_tenant$data = IndividualsPage_tenant;
export type IndividualsPage_tenant$key = {
  +$data?: IndividualsPage_tenant$data,
  +$fragmentRefs: IndividualsPage_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "IndividualsPage_tenant",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "kind",
          "value": "individual"
        }
      ],
      "concreteType": "ImportantFormula",
      "kind": "LinkedField",
      "name": "important_formulas",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Formula",
          "kind": "LinkedField",
          "name": "formula",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": "important_formulas(kind:\"individual\")"
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '72f4537117f9d560e19fb063ce9ee540';

module.exports = node;
