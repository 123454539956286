// @flow

import React, { Fragment } from 'react'

import Link from 'react-ui/components/Link'

const TechnologyQuestions = [
  {
    key: 'techIssues',
    question: <Fragment> What do you do with technical issues? </Fragment>,
    answer: (
      <Fragment>
        Always save and submit your data as you progress through Innowell.
        <br />
        <br />
        If you are experiencing technical issues, please reach out to your
        health provider (i.e. the organisation who invited you to Innowell).
        <br />
        <br />
        You can find{' '}
        <Link to="connected_organisations">
          Your clinicians and healthcare providers{' '}
        </Link>
        information under the Profile Settings menu.
        <br />
        <br />
        Do you need help now? There are{' '}
        <Link to="help">services who can help</Link>
      </Fragment>
    ),
  },
  {
    key: 'invitationNotReveived',
    question: <Fragment> Why is an invitation email not received? </Fragment>,
    answer: (
      <Fragment>
        When an email invitation is not received:
        <ul>
          <li>
            Check and confirm the email invite was sent to the correct email
            address
          </li>
          <li>
            Check and confirm the date and time the email was sent. It is
            possible that the email went to junk/ spam/ trash folders in a
            person’s email system
          </li>
          <li>
            Resend an email. If the email invite was sent more than seven (7)
            days ago, it will have expired, and a new email invite needs to be
            resent
          </li>
        </ul>
      </Fragment>
    ),
  },
]

export default TechnologyQuestions
