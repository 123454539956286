// @flow

import { graphql } from 'react-relay'

const DischargeRolesMutation = graphql`
  mutation DischargeRolesMutation($input: RolesDischargeInput!) {
    rolesDischarge(input: $input) {
      success
    }
  }
`

export default DischargeRolesMutation
