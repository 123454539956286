// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const containerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    maxWidth: '45rem',
    margin: '0 auto 1.5rem',
    '& > span:first-child': {
      width: '20%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      textAlign: 'left',
      whiteSpace: 'nowrap',
    },
    ...theme.care.typography.desktop.bodyLg,
  }
}
