// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import AlertIcon from 'react-ui/assets/icons/minor-alert-circle.svg'
import Icon from 'react-ui/components/Icon'
import { withoutFelaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

const styleRules = ({ theme }) => ({
  FormError: {
    className: 'FormError',
    color: theme.Field.error.text,
    fontSize: '16px',
    lineHeight: 'inherit',
    marginBottom: '1rem',
  },
  icon: {
    className: 'FormError__icon',
    lineHeight: theme.spacing(1),
    marginRight: '10px',
    transform: 'translateY(2px)',
    '& circle.cls-2': {
      fill: 'currentColor',
      stroke: 'transparent',
    },

    '& path.cls-3': {
      fill: '#fff',
      stroke: '#fff',
    },
    '& path.cls-4': {
      fill: '#fff',
      stroke: 'none',
    },
  },
})

type PropsType = FelaPropsType & {
  as?: string,
  children: React.Node,
}

const FormError = ({
  as: Component = 'div',
  children,
  rules,
  styles,
  ...props
}: PropsType) => (
  <Component {...withoutFelaProps(props)} className={styles.FormError}>
    <Icon as={AlertIcon} extend={rules.icon} scale={0.57142857} />
    {children}
  </Component>
)

export default connect(styleRules)(FormError)
