// @flow

import * as React from 'react'
import { useFela } from 'react-fela'
import merge from 'lodash/fp/merge'

import DataGrid from 'react-ui/components/DataGrid'
import type { TraceType } from 'services/plotly'

import SegmentationCellRenderer from './SegmentationTableCellRenderer'
import SegmentationHeaderCellRenderer from './SegmentationTableHeaderCellRenderer'
import SegmentLabel from './SegmentLabel'
import TruncatedTraces from './TruncatedTraces'

export type PropsType = {
  columnConfig?: *,
  extend?: () => Object,
  getColumnName?: (traces: $ReadOnlyArray<TraceType>, index: number) => string,
  noHeaders?: boolean,
  +traces: $ReadOnlyArray<TraceType>,
}

function mapTracesToDataGridData(getColumnName, traces, theme) {
  const rowCount = traces.reduce((memo, { y }) => Math.max(memo, y.length), 0)

  return Array.from({ length: rowCount }).map((_, rowIndex) => ({
    label: {
      name: traces[0].x[rowIndex],
      color: theme.getReportColour(rowIndex),
    },
    ...Array.from({ length: traces.length }).reduce(
      (memo, _void, traceIndex) => ({
        ...(memo: any),
        [getColumnName(traces, traceIndex)]: traces[traceIndex].y[rowIndex],
      }),
      {},
    ),
  }))
}

function mapTracesToColumnConfigs(getColumnName, traces, columnWidth) {
  return traces.reduce(
    (memo, { name }, index) => ({
      ...memo,
      [getColumnName(traces, index)]: {
        label: name,
        width: `${columnWidth}px`,
      },
    }),
    {},
  )
}

// Explicitly set column order to the order of the traces array
// to avoid browser inconsistency issues with Object.keys
function mapTracesToColumnOrders(getColumnName, traces) {
  return ['label'].concat(traces.map((_trace, i) => getColumnName(traces, i)))
}

function renderCell(rowData, key, rowProps) {
  return (
    <SegmentationCellRenderer key={key} {...rowProps}>
      {rowData[key]}
    </SegmentationCellRenderer>
  )
}

function renderHeaderCell(key, label, rowProps) {
  return (
    <SegmentationHeaderCellRenderer key={key} {...rowProps}>
      {label}
    </SegmentationHeaderCellRenderer>
  )
}

export const Table = ({
  extend = () => ({}),
  columnConfig = {},
  getColumnName = (traces, index) => `value${index}`,
  noHeaders = false,
  traces,
  ...props
}: PropsType) => {
  const { theme } = useFela()

  const traceColumnWidth = 120
  const data = mapTracesToDataGridData(getColumnName, traces, theme)
  const defaultColumnConfig = {
    label: {
      format: ({ color, name }: { color: string, name: string }) => (
        <SegmentLabel color={color}>{name}</SegmentLabel>
      ),
      // Render an empty header label for the first column
      label: ' ',
      sortable: false,
      width: `calc(100% - ${traces.length * traceColumnWidth}px)`,
    },
    ...(mapTracesToColumnConfigs(getColumnName, traces, traceColumnWidth): any),
  }
  const mergedColumnConfig = merge(defaultColumnConfig, columnConfig)

  const extendOverrides = (...args) => {
    const headerOverrides = {
      marginBottom: 0,
      display: noHeaders ? 'none' : 'flex',
    }
    return merge(
      {
        headerHidden: headerOverrides,
        headerVisible: headerOverrides,
      },
      extend(...args),
    )
  }

  return (
    <DataGrid
      renderCell={renderCell}
      renderHeaderCell={renderHeaderCell}
      columns={mapTracesToColumnOrders(getColumnName, traces)}
      columnConfig={mergedColumnConfig}
      data={data}
      extend={extendOverrides}
      {...(props: any)}
    />
  )
}

const TruncatedSegmentationTable = ({ traces, ...props }: PropsType) => (
  <TruncatedTraces traces={traces}>
    {data => <Table traces={data} {...(props: any)} />}
  </TruncatedTraces>
)

export default TruncatedSegmentationTable
