// @flow

import React, { type Node } from 'react'

import Checkbox from './Checkbox'

type PropsType = {
  disabled?: boolean,
  label?: string,
  name: string,
  onBlur?: Function,
  onChange?: Function,
  options: Array<{
    title: Node,
    value: string | number | boolean,
  }>,
  selectedValues?: $ReadOnlyArray<string | number | boolean>,
  uiStyle: 'primary' | 'plain' | 'danger',
}

const CheckboxSet = ({
  disabled,
  name,
  onChange,
  onBlur,
  options,
  uiStyle,
  selectedValues,
  label,
}: PropsType) => {
  return (
    <div>
      {label}
      <div>
        {options.map(({ value, title }) => (
          <Checkbox
            checked={selectedValues && selectedValues.includes(value)}
            key={String(value)}
            name={name}
            onChange={onChange}
            onBlur={onBlur}
            uiStyle={uiStyle}
            value={value}
            disabled={disabled || false}
          >
            {title}
          </Checkbox>
        ))}
      </div>
    </div>
  )
}

export default CheckboxSet
