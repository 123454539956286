/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireShowPage_supportee$ref: FragmentReference;
declare export opaque type QuestionnaireShowPage_supportee$fragmentType: QuestionnaireShowPage_supportee$ref;
export type QuestionnaireShowPage_supportee = {
  +id: string,
  +tenant: ?{
    +id: string,
    +name: string,
    +support_person_help: ?string,
    ...
  },
  +user: {
    +id: string,
    +email: string,
    +name: string,
    ...
  },
  +supporting_user_questionnaire: ?{
    +id: string,
    +questionnaire: ?{
      +id: string,
      ...
    },
    +ensure_questionnaire: {
      +id: string,
      ...
    },
    +user: {
      +id: string,
      ...
    },
    ...
  },
  ...
};
export type QuestionnaireShowPage_supportee$data = QuestionnaireShowPage_supportee;
export type QuestionnaireShowPage_supportee$key = {
  +$data?: QuestionnaireShowPage_supportee$data,
  +$fragmentRefs: QuestionnaireShowPage_supportee$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "QuestionnaireShowPage_supportee",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "support_person_help",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "supporting_user_questionnaire",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "ensure_questionnaire",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "User",
          "kind": "LinkedField",
          "name": "user",
          "plural": false,
          "selections": (v2/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f9ca0fc788c03027c2fb8cf4aebdad0b';

module.exports = node;
