// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const bodyStyle = () => ({
  display: 'flex',
  flexGrow: 1,
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
})

export const titleStyle = (props: StyleProps) => {
  const { theme: { care } } = props

  return {
    ...care.typography.desktop.bodyLg,
  }
}

export const containerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.care.spacing.xxs,
  }
}

export const ratingStyle = (props: StyleProps) => {
  const { theme: { care } } = props

  return {
    ...care.typography.desktop.bodyLgBold,
  }
}

export const scoreContainerStyle = (props: StyleProps) => {
  const { theme: { care } } = props

  return {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    ...care.typography.desktop.bodyLg,
    minHeight: care.spacing.lg,
  }
}
