/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserContext_user$ref: FragmentReference;
declare export opaque type UserContext_user$fragmentType: UserContext_user$ref;
export type UserContext_user = {|
  +id: string,
  +homepage_path: string,
  +legal_name: ?string,
  +preferred_name: ?string,
  +completed_onboarding: boolean,
  +show_individual_onboarding: boolean,
  +user_research_programs_responses: $ReadOnlyArray<{|
    +id: string
  |}>,
  +unanswered_tenant_research_programs: $ReadOnlyArray<{|
    +id: string
  |}>,
  +current_role: {|
    +id?: string,
    +role_type?: RoleTypes,
  |},
  +roles: ?{|
    +nodes: ?$ReadOnlyArray<?{|
      +id?: string,
      +role_type?: RoleTypes,
    |}>
  |},
  +$refType: UserContext_user$ref,
|};
export type UserContext_user$data = UserContext_user;
export type UserContext_user$key = {
  +$data?: UserContext_user$data,
  +$fragmentRefs: UserContext_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
],
v2 = {
  "kind": "InlineFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role_type",
      "storageKey": null
    }
  ],
  "type": "RoleInterface",
  "abstractKey": "__isRoleInterface"
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserContext_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "homepage_path",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legal_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferred_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "completed_onboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "show_individual_onboarding",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResearchProgramsResponse",
      "kind": "LinkedField",
      "name": "user_research_programs_responses",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TenantResearchProgram",
      "kind": "LinkedField",
      "name": "unanswered_tenant_research_programs",
      "plural": true,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "current_role",
      "plural": false,
      "selections": [
        (v2/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "IndividualRole",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "RoleConnection",
      "kind": "LinkedField",
      "name": "roles",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '4b85752912e30e86c550bb5ef82b63de';

module.exports = node;
