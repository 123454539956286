// @flow

/* eslint-env browser */
import * as React from 'react'
import { withTheme } from 'react-fela'

type MatchesType = {
  [name: string]: boolean,
}

type PropTypes2 = {
  children: MatchesType => React.Node,
  theme: Object,
}

class MatchMedia extends React.Component<PropTypes2, MatchesType> {
  state = {}

  componentWillMount() {
    const { theme } = this.props
    const fallback = Object.keys(theme.breakpoints.queries)[0]
    const { queries } = theme.breakpoints
    let matches = Object.keys(queries).reduce(
      (memo, key) => ({
        ...memo,
        [key]: key === fallback,
      }),
      {},
    )

    if (
      typeof window !== 'undefined' &&
      typeof window.matchMedia === 'function'
    ) {
      this.queries = Object.keys(queries).reduce(
        (memo, key) => ({
          ...memo,
          [key]: this.addQuery(key, queries[key]),
        }),
        {},
      )

      matches = Object.keys(this.queries).reduce(
        (memo, key) => ({
          ...memo,
          [key]: this.queries[key].matches,
        }),
        {},
      )
    }
    this.setState(matches)
  }

  componentWillReceiveProps(nextProps) {
    const isBrowser = typeof window !== 'undefined' && window.matchMedia
    const { theme: nextTheme } = nextProps
    const { queries: nextqueries } = nextTheme.breakpoints
    const { theme } = this.props
    const { queries } = theme.breakpoints

    // update existing props...
    Object.keys(queries).forEach(key => {
      const isRemoved = !nextqueries[key]
      const hasChanged = queries[key] !== nextqueries[key]

      if (isRemoved || hasChanged) {
        this.removeQuery(key)
      }
      if (isBrowser && hasChanged) {
        this.addQuery(key, nextqueries[key])
      }
    })

    // add any queries from new props
    Object.keys(nextqueries).forEach(key => {
      if (isBrowser && !queries[key]) {
        this.addQuery(key, nextqueries[key])
      }
    })
  }

  componentWillUnmount() {
    Object.keys(this.queries).forEach(key => this.removeQuery(key))
  }

  queries = {}

  listeners = {}

  addQuery(key, query) {
    const mql = window.matchMedia(query.replace(/^@media /, ''))
    this.listeners[key] = this.handleMediaQueryChange.bind(this, key)
    mql.addListener(this.listeners[key])
    return mql
  }

  removeQuery(key) {
    const mql = this.queries[key]
    const listener = this.listeners[key]
    if (mql && listener) {
      mql.removeListener(listener)
    }
  }

  handleMediaQueryChange = (key, evt) => {
    this.setState({
      [key]: evt.matches,
    })
  }

  render() {
    return this.props.children(this.state)
  }
}

export default withTheme(MatchMedia)
