// @flow

import { graphql } from 'react-relay'

export const scoreCardsPageAboutTrackable = {
  trackable: graphql`
    fragment ScoreCardsPageAbout_trackable on Trackable {
      description
      label
    }
  `,
}
