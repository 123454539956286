// @flow

import React, { type Node, useState } from 'react'
import {
  createPaginationContainer,
  QueryRenderer,
  useRelayEnvironment,
} from 'react-relay'
import { type GraphQLTaggedNode } from 'relay-runtime'

import {
  type VariablesType,
  PaginationQueryVariablesContextProvider,
  usePaginationQuery,
} from 'react-ui/contexts/PaginationQueryContext'
import { QueryRendererLoadingIndicator } from 'platform_web/components/QueryRendererLoadingIndicator'

type ChildrenType = Node | ((props: any) => Node)

type PaginationQueryContextComponentType<T> = {
  children: ((props: VariablesType<T>) => Node) | any[],
  variables: T,
}

type PaginationQueryLoaderType<T> = {
  children: ChildrenType,
  controls?: (props: VariablesType<any>) => Node,
  fragments: { [string]: GraphQLTaggedNode },
  getFragments: any => any,
  query: GraphQLTaggedNode,
  variables: T,
}

const render = (children: ChildrenType, props: any) =>
  typeof children === 'function' ? children(props) : children

const PaginationFragmentComponent = ({ relay, children, ...fragments }) => {
  const variablesContext = usePaginationQuery()
  // NOTE: we are going to expose both the `variables` and `relay` to children
  return render(children, {
    relay,
    ...variablesContext,
    ...fragments,
  })
}

export const PaginationQueryContextComponent = <T = any>({
  variables: initialVariables = {},
  children,
}: PaginationQueryContextComponentType<T>) => {
  // NOTE: we are going to expose the `variables` to children
  const [variables, setVariables] = useState(initialVariables)
  const updateVariables = newVariables =>
    setVariables({ ...variables, ...newVariables })
  const variablesContext = { variables, setVariables, updateVariables }
  return (
    <PaginationQueryVariablesContextProvider value={variablesContext}>
      {render(children, variablesContext)}
    </PaginationQueryVariablesContextProvider>
  )
}

export const PaginationQueryLoader = ({
  children,
  query,
  fragments,
  getFragments,
  variables: initialVariables,
  controls,
}: PaginationQueryLoaderType<any>) => {
  const environment = useRelayEnvironment()

  const PaginationFragmentContainer = createPaginationContainer(
    PaginationFragmentComponent,
    fragments,
    {
      query,
      direction: 'forward',
      getVariables: ({ variables }, paginationInfo, fragmentVariables) => ({
        ...variables,
        ...fragmentVariables,
        ...paginationInfo,
      }),
    },
  )

  const queryRender = variablesProps => response => (
    <QueryRendererLoadingIndicator response={response}>
      {originResponse => (
        <PaginationFragmentContainer
          {...variablesProps}
          {...getFragments(originResponse)}
        >
          {props => render(children, props)}
        </PaginationFragmentContainer>
      )}
    </QueryRendererLoadingIndicator>
  )

  return (
    <PaginationQueryContextComponent variables={initialVariables}>
      {variablesContext => (
        <>
          {controls && controls(variablesContext)}
          <QueryRenderer
            environment={environment}
            query={query}
            variables={variablesContext.variables}
            render={queryRender(variablesContext)}
          />
        </>
      )}
    </PaginationQueryContextComponent>
  )
}
