/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthCardContainer_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthCardsTab_user$ref: FragmentReference;
declare export opaque type HealthCardsTab_user$fragmentType: HealthCardsTab_user$ref;
export type HealthCardsTab_user = {|
  +id: string,
  +summaryUserTrackables: {|
    +totalCount: number
  |},
  +fitbit: {|
    +connected: boolean,
    +connectionUrl: ?string,
  |},
  +linked_individual: ?{|
    +duty_of_care_clinicians: $ReadOnlyArray<{|
      +user: {|
        +id: string
      |}
    |}>,
    +individual_detail: ?{|
      +read_only: boolean
    |},
  |},
  +$fragmentRefs: HealthCardContainer_user$ref,
  +$refType: HealthCardsTab_user$ref,
|};
export type HealthCardsTab_user$data = HealthCardsTab_user;
export type HealthCardsTab_user$key = {
  +$data?: HealthCardsTab_user$data,
  +$fragmentRefs: HealthCardsTab_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthCardsTab_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": "summaryUserTrackables",
      "args": [
        {
          "kind": "Literal",
          "name": "kinds",
          "value": [
            "gauge"
          ]
        }
      ],
      "concreteType": "UserTrackableConnection",
      "kind": "LinkedField",
      "name": "user_trackables",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "totalCount",
          "storageKey": null
        }
      ],
      "storageKey": "user_trackables(kinds:[\"gauge\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Fitbit",
      "kind": "LinkedField",
      "name": "fitbit",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connected",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "connectionUrl",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "linked_individual",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianRole",
          "kind": "LinkedField",
          "name": "duty_of_care_clinicians",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualDetail",
          "kind": "LinkedField",
          "name": "individual_detail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "read_only",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthCardContainer_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6a1e2c6896f15853100c344a69dd39e7';

module.exports = node;
