// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const LockIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        width="18px"
        height="28px"
        viewBox="0 0 18 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <g
          id="Page-1"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <g id="Group-3" transform="translate(0.000000, 1.000000)">
            <path
              d="M4,9 L14,9 C16.209139,9 18,10.790861 18,13 L18,23 C18,25.209139 16.209139,27 14,27 L4,27 C1.790861,27 2.705415e-16,25.209139 0,23 L0,13 C-2.705415e-16,10.790861 1.790861,9 4,9 Z M9,20 C10.1045695,20 11,19.1045695 11,18 C11,16.8954305 10.1045695,16 9,16 C7.8954305,16 7,16.8954305 7,18 C7,19.1045695 7.8954305,20 9,20 Z"
              id="Combined-Shape"
              fill="#FFFFFF"
            />
            <path
              d="M13.9698402,5.35719313 C13.9698402,2.39849706 11.7380155,0 8.98492011,0 C6.23182475,0 4,2.39849706 4,5.35719313 L4,14 L14,14 L13.9698402,5.35719313 Z"
              id="Oval"
              stroke="#FFFFFF"
              strokeWidth="2"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}
export default LockIcon
