// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  RoleIndividualInviteMutationResponse,
  RoleIndividualInviteMutationVariables,
} from './__generated__/RoleIndividualInviteMutation.graphql'

const mutation = graphql`
  mutation RoleIndividualInviteMutation($input: UserInviteInput!) {
    userInvite(input: $input) {
      role {
        ... on IndividualRole {
          ...IndividualsTable_individual_mutation
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  updateVariables,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: RoleIndividualInviteMutationResponse) => void,
  onError?: (result: Object) => void,
  updateVariables?: Function,
  variables: RoleIndividualInviteMutationVariables,
}) => {
  const updater = () => {
    if (updateVariables) {
      updateVariables({ search: '' })
    }
  }

  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
    updater,
  })
}
