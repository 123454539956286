// @flow

import React from 'react'

import type { TraceType } from 'services/plotly'

import HeroTable from '.'

type PropsType = {
  columnFormat?: {
    [key: string]: ?string,
  },
  +traces: $ReadOnlyArray<TraceType>,
  valueFormat?: {
    [key: string]: ?(value: number) => number | void,
  },
}

const HeroTableWithPercent = ({
  columnFormat = {
    '0': '0,0',
    '1': '0.00%',
  },
  valueFormat = { '1': value => value / 100 },
  ...rest
}: PropsType) => (
  <HeroTable
    columnFormat={columnFormat}
    valueFormat={valueFormat}
    {...(rest: any)}
  />
)

export default HeroTableWithPercent
