// @flow

import React from 'react'
import { connect } from 'react-fela'

import { type FelaStylesType } from 'react-ui/typing'

type PropsType = {
  current: number,
  skippedSets: Array<number>,
  styles: FelaStylesType,
  total: number,
}

const styleRules = ({ theme }) => {
  return {
    ProgressBar: {
      className: 'ProgressBar__Container',
      width: '100%',
      display: 'flex',
      borderRadius: '50px',
      marginTop: '10px',
      background: theme.care.palette.surface.subtle,
      '> .progress': {
        height: theme.care.spacing.md,
        width: '100%',
      },
      '> .progress:first-child': {
        borderTopLeftRadius: '50px',
        borderBottomLeftRadius: '50px',
      },
      '> .current': {
        borderRadius: '0 50px 50px 0',
      },
      '> .done': {
        background: theme.care.palette.surface.accent,
      },
      '> .skipped': {
        background: `repeating-linear-gradient(-45deg, #fff, #fff 0.3px, ${
          theme.care.palette.surface.accent
        } 0.3px, ${theme.care.palette.surface.accent} 4px)`,
      },
    },
  }
}

const SkippableProgressBar = ({
  styles,
  current = 0,
  total = 0,
  skippedSets = [],
}: PropsType) => {
  const progressBar = []

  for (let i = 0; i < total; i += 1) {
    const classNames = ['progress']

    if (i === current - 1) classNames.push('current')
    if (skippedSets.indexOf(i) !== -1 && i < current) classNames.push('skipped')
    else if (i < current) classNames.push('done')

    const className = classNames.join(' ')

    progressBar.push(<span key={i} className={className} />)
  }

  return (
    <>
      <div className={styles.ProgressBar} data-testid="skippable-progress-bar">
        {progressBar}
      </div>
    </>
  )
}

export default connect(styleRules)(SkippableProgressBar)
