// @flow

import React from 'react'
import { connect } from 'react-fela'

import HelpNowButton from 'platform_web/features/Header/HelpNowButton'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  showHelpNowButton: boolean,
  tenant: Object,
}

const styleRules = () => ({
  actionWrapper: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
})

const Actions = ({ styles, tenant, showHelpNowButton }: PropsType) => (
  <div className={styles.actionWrapper}>
    {showHelpNowButton && <HelpNowButton tenant={tenant} />}
  </div>
)

export default connect(styleRules)(Actions)
