// @flow

export const OPEN_MAIN_NAV = 'OPEN_MAIN_NAV'
export const CLOSE_MAIN_NAV = 'CLOSE_MAIN_NAV'
export const OPEN_SUB_MENU = 'OPEN_SUB_MENU'
export const CLOSE_SUB_MENU = 'CLOSE_SUB_MENU'
export const OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE =
  'OPEN_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE'
export const CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE =
  'CLOSE_CLINICIAN_INDIVIDUAL_GLOBAL_TABLE'
export const MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN =
  'MARK_DASHBOARD_QUESTIONNAIRE_MODAL_SEEN'
export const TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS =
  'TOGGLE_ONBOARDING_QUESTIONNAIRE_SUCCESS'
export const CREATE_FLASH = 'CREATE_FLASH'
export const REMOVE_FLASH = 'REMOVE_FLASH'
export const CLEAR_FLASH = 'CLEAR_FLASH'
