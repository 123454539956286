// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import Card from 'react-ui/components/Card/Card'
import { H3 } from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import LinkableContent from 'react-ui/components/LinkableContent'
import { useWindowSize } from 'platform_web/hooks/useWindowSize'
import { Button } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type WelcomeCardContentProps = FelaPropsType & {
  analytics?: () => void,
  buttonLabel: string,
  description: string,
  icon: Node,
  id: string,
  isButtonDisabled?: boolean,
  linkTo?: string | Object,
  showButton: boolean,
  title: string,
}

const WelcomeStyleRules = ({ theme }) => ({
  CardWrapper: {
    display: 'flex',
    height: '100%',
    justifyContent: 'flex-start',
    padding: '5px 15px',
    [theme.breakpoints.queries.md]: {
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px 35px',
      minHeight: '440px',
    },
    [theme.breakpoints.queries.xs]: {
      margin: `${theme.Grid.gutter} 0`,
    },
  },
  infoWrapper: {
    display: 'flex',
    flex: '1 1 auto',
    flexDirection: 'column',
    marginLeft: '15px',
    alignItems: 'flex-start',
    justifyContent: 'center',
    [theme.breakpoints.queries.md]: {
      marginTop: '40px',
      marginLeft: '0',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
  infos: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    [theme.breakpoints.queries.xsOnly]: {
      paddingBottom: '5px',
      fontSize: '16px',
    },
    [theme.breakpoints.queries.md]: {
      paddingBottom: '20px',
      minHeight: '75px',
      textAlign: 'center',
    },
  },
  desc: {
    display: 'flex',
    lineHeight: '1rem',
    color: theme.palette.input.placeholder,
    fontSize: '14px',
    flexDirection: 'column',
    textAlign: 'left',
    [theme.breakpoints.queries.smOnly]: {
      width: '100%',
      justifyContent: 'space-between',
      padding: '5px 0 15px 0',
    },
    [theme.breakpoints.queries.md]: {
      textAlign: 'center',
      lineHeight: '20px',
      minHeight: '65px',
      fontSize: '16px',
    },
  },

  Card: {
    position: 'relative',
    padding: '5px',
    margin: '10px auto',
    width: '100%',
    height: '100%',
    minHeight: '125px',
    boxShadow: '0',
    [theme.breakpoints.queries.md]: {
      padding: 'initial',
      maxWidth: '240px',
      margin: '0',
      minHeight: '300px',
      maxHeight: '440px',
    },
  },
  icon: {
    backgroundColor: theme.palette.lightBackground,
    borderRadius: '50%',
    width: '70px',
    height: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    '& svg': {
      fill: '#4c558c',
      width: '70px',
      height: '70px',
    },
    [theme.breakpoints.queries.md]: {
      width: '120px',
      height: '120px',
      '& svg': {
        width: '120px',
        height: '120px',
      },
    },
  },
  Button: {
    [theme.breakpoints.queries.md]: {
      width: '150px',
      height: 'inherit',
      lineHeight: 'inherit',
      margin: '20px 0 ',
      alignSelf: 'center',
    },
  },
  heading: {
    marginTop: 0,
  },
})

const WelcomeCardContent = ({
  styles,
  rules,
  id,
  title,
  showButton = true,
  isButtonDisabled,
  buttonLabel,
  icon,
  linkTo,
  description,
  analytics = () => {},
}: WelcomeCardContentProps) => {
  const size = useWindowSize()

  return (
    <div className={styles.CardWrapper} data-component-id={id}>
      {size.md && (
        <div className={styles.infos}>
          <H3 extend={rules.heading}>{title}</H3>
        </div>
      )}
      <div className={styles.icon}>
        <Icon as={icon} />
      </div>
      <div className={styles.infoWrapper}>
        {(size.xs || size.smOnly) && (
          <div className={styles.infos}>
            <H3 extend={rules.heading}>{title}</H3>
          </div>
        )}
        <div className={styles.desc}>
          {description}
          {title === 'Action' && (
            <>
              <br />
              <small>
                <i>Available Soon</i>
              </small>
            </>
          )}
        </div>
        {size.md &&
          showButton && (
            <Button
              variant="primary"
              disabled={isButtonDisabled}
              to={linkTo}
              onClick={analytics}
            >
              {buttonLabel}
            </Button>
          )}
      </div>
    </div>
  )
}

const WelcomeCard = (props: any) => (
  <Card extend={props.rules.Card}>
    {props.linkTo ? (
      <LinkableContent linkTo={props.linkTo} analytics={props.analytics}>
        <WelcomeCardContent {...(props: any)} />
      </LinkableContent>
    ) : (
      <WelcomeCardContent {...(props: any)} />
    )}
  </Card>
)

export default connect(WelcomeStyleRules)(WelcomeCard)
