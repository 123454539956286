/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type UserInviteFormTenantCliniciansQueryVariables = {|
  id: string
|};
export type UserInviteFormTenantCliniciansQueryResponse = {|
  +tenant: {|
    +assignable_clinicians: {|
      +nodes: ?$ReadOnlyArray<?{|
        +id: string,
        +name: string,
      |}>
    |}
  |}
|};
export type UserInviteFormTenantCliniciansQuery = {|
  variables: UserInviteFormTenantCliniciansQueryVariables,
  response: UserInviteFormTenantCliniciansQueryResponse,
|};
*/


/*
query UserInviteFormTenantCliniciansQuery(
  $id: ID!
) {
  tenant(id: $id) {
    assignable_clinicians {
      nodes {
        id
        name
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ClinicianRoleConnection",
  "kind": "LinkedField",
  "name": "assignable_clinicians",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianRole",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserInviteFormTenantCliniciansQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserInviteFormTenantCliniciansQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ba7a4da8432ad113a7d01dbfbc9b24fd",
    "id": null,
    "metadata": {},
    "name": "UserInviteFormTenantCliniciansQuery",
    "operationKind": "query",
    "text": "query UserInviteFormTenantCliniciansQuery(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    assignable_clinicians {\n      nodes {\n        id\n        name\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '9c82f24cb6847abbc4a51faea53d4b63';

module.exports = node;
