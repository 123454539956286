// @flow

import * as React from 'react'
import { get } from 'lodash/fp'

import { FormError as ReactUiFormError } from 'react-ui/components/Form'

type PropsType = {
  attr: string,
  errors: {
    [attr: string]: string,
  },
  touched: {
    [attr: string]: boolean,
  },
}

const FormError = (props: PropsType) => {
  const { errors, attr, touched } = props
  const retrieveFromNestedPath = get(attr)
  const error = retrieveFromNestedPath(errors)
  const isTouched = retrieveFromNestedPath(touched)

  if (!error || !isTouched) {
    return null
  }

  return <ReactUiFormError>{error}</ReactUiFormError>
}

export default FormError
