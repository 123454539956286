// @flow

import React from 'react'
import { Formik, FormikProps } from 'formik'
import * as yup from 'yup'

import { FormError } from 'react-ui/components/Form'

type FormPropsType = {
  children: any,
  initialValues: { [key: string]: string },
  onSubmit: (args: any) => void,
  validationSchema?: { [key: string]: string | any },
}

const Form = (props: FormPropsType) => {
  const { initialValues, onSubmit, validationSchema, children } = props

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={yup.object().shape(validationSchema)}
    >
      {(formikProps: FormikProps) => {
        const { handleSubmit, status } = formikProps

        return (
          <form onSubmit={handleSubmit}>
            {status?.error && <FormError>{status.error}</FormError>}

            {/* Form Body */}
            {children({ formik: formikProps })}
          </form>
        )
      }}
    </Formik>
  )
}

export default Form
