// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import { styledRulesLink } from './UserRemoveInstructions.style'

type Props = {
  contract: ?string,
  privacyUrl: ?string,
}

const UserRemoveInstructions = (props: Props) => {
  const { contract, privacyUrl } = props
  const { css } = useFela()

  const isAhs = contract === 'ahs'
  const isLondon = contract === 'london'

  const emailAddress = isAhs ? 'emh@ahs.com' : 'femapresearch@lhsc.on.ca'

  const { t: translation } = useTranslation('settings')

  return isAhs || isLondon ? (
    <>
      {translation(
        'to_be_removed_from_the_innowell_platform_email_your_request_to',
      )}&nbsp;
      <a className={css(styledRulesLink)} href={`mailto:${emailAddress}`}>
        {emailAddress}
      </a>
      {isLondon && (
        <span>&nbsp;{translation('or_call_519_646_6000_ext_65196')}</span>
      )}
      <br />
      {translation('please_refer_to_the')}&nbsp;
      <a className={css(styledRulesLink)} href={privacyUrl}>
        {translation('privacy_policy')}
      </a>&nbsp;
      {translation('for_further_details')}
    </>
  ) : null
}

export default UserRemoveInstructions
