/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FeedbackTopics = "general" | "improvements" | "technical" | "unsure" | "working_well" | "%future added value";
export type FeedbackCreateInput = {|
  clientMutationId?: ?string,
  feedback: FeedbackCreateInputObject,
|};
export type FeedbackCreateInputObject = {|
  description: string,
  subject: string,
  topic: FeedbackTopics,
|};
export type SubmitFeedbackMutationVariables = {|
  input: FeedbackCreateInput
|};
export type SubmitFeedbackMutationResponse = {|
  +feedbackCreate: ?{|
    +success: ?boolean
  |}
|};
export type SubmitFeedbackMutation = {|
  variables: SubmitFeedbackMutationVariables,
  response: SubmitFeedbackMutationResponse,
|};
*/


/*
mutation SubmitFeedbackMutation(
  $input: FeedbackCreateInput!
) {
  feedbackCreate(input: $input) {
    success
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "FeedbackCreatePayload",
    "kind": "LinkedField",
    "name": "feedbackCreate",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SubmitFeedbackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SubmitFeedbackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "8a066c2f30cf9c300d5fd5ff8e93a405",
    "id": null,
    "metadata": {},
    "name": "SubmitFeedbackMutation",
    "operationKind": "mutation",
    "text": "mutation SubmitFeedbackMutation(\n  $input: FeedbackCreateInput!\n) {\n  feedbackCreate(input: $input) {\n    success\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '55f3df26f67e10cef25164b0ab2bf1f0';

module.exports = node;
