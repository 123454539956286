// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const resultStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    textTransform: 'uppercase',
    padding: `${theme.care.spacing.xxxs} ${theme.care.spacing.xxs}`,
    borderRadius: theme.care.radius.sm,
  }
}

export const listContainerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    padding: `${theme.care.spacing.xxs} 0`,
    gap: theme.care.spacing.xxs,
  }
}

export const listStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    gap: theme.care.spacing.xxs,
    padding: 0,
    margin: 0,
    listStyleType: 'none',
  }
}

export const trackableContainerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.care.spacing.xxs,
  }
}

export const trackableStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.care.spacing.xxxs} ${theme.care.spacing.xxs}`,
    borderRadius: theme.care.radius.sm,
    background: theme.care.palette.surface.disabled,
  }
}

export const labelStyle = () => ({
  display: 'flex',
})
