// @flow

import { graphql } from 'react-relay'

export const query = {
  questionSet: graphql`
    fragment StaticQuestionnaireInformation_questionSet on QuestionSet {
      time_required
    }
  `,
}
