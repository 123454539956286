// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rdp {
  background: white;
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 14px;
  margin: 0px;
}

.rdp-day_selected {
  background: rgba(112, 107, 255, 1);
  color: rgba(242, 241, 243, 1);
}

.rdp-day_range_middle {
  background: rgba(230, 229, 255, 1);
  color: rgba(4, 0, 104, 1);
}

.rdp-table {
  border-collapse: separate;
  border-spacing: 0 8px;
}`, "",{"version":3,"sources":["webpack://./app/javascript/packs/care-ui/organisms/DateRangePicker/dateRangePicker.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,WAAW;AACb;;AAEA;EACE,kCAAkC;EAClC,6BAA6B;AAC/B;;AAEA;EACE,kCAAkC;EAClC,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;EACzB,qBAAqB;AACvB","sourcesContent":[".rdp {\n  background: white;\n  font-family: 'Raleway';\n  font-weight: 400;\n  font-size: 14px;\n  margin: 0px;\n}\n\n.rdp-day_selected {\n  background: rgba(112, 107, 255, 1);\n  color: rgba(242, 241, 243, 1);\n}\n\n.rdp-day_range_middle {\n  background: rgba(230, 229, 255, 1);\n  color: rgba(4, 0, 104, 1);\n}\n\n.rdp-table {\n  border-collapse: separate;\n  border-spacing: 0 8px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
