// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import Banner from 'react-ui/components/Banner'
import pageReload from 'services/pageReload'
import { Button } from 'care-ui'

const ReleaseWarningBanner = () => {
  const { t: translation } = useTranslation('shared')

  return (
    <Banner>
      <Button onClick={pageReload} variant="text">
        <b>{translation('a_new_version_of_the_app_has_been_released')}</b>
        {translation('please')}
        <u>{translation('click_here')}</u>
        {translation('to_reload_and_avoid_errors')}
      </Button>
    </Banner>
  )
}

export default ReleaseWarningBanner
