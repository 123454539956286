/*
 * @flow
 */

import track from 'react-tracking'

import HoverTracker from './HoverTracker'

export { track, HoverTracker }

export type ReactTrackingHoCProps = {
  tracking: {
    trackEvent: ({ [string]: string }) => void,
  },
}

export const STARTED_ONBOARDING_QUESTIONNAIRE =
  'Started Onboarding Questionnaire'
export const FINISHED_ONBOARDING_QUESTIONNAIRE =
  'Finished Onboarding Questionnaire'

export const SET_INITIAL_USER_PASSWORD = 'Set Initial User Password'
export const FAIL_SETTING_INITIAL_USER_PASSWORD =
  'Fail Setting Initial User Password'

export const SET_INITIAL_USER_NAME = 'Set Initial User Name'
