/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnaireQuestionSetFooter_answer_set$ref: FragmentReference;
declare export opaque type UserQuestionnaireQuestionSetFooter_answer_set$fragmentType: UserQuestionnaireQuestionSetFooter_answer_set$ref;
export type UserQuestionnaireQuestionSetFooter_answer_set = {|
  +skip_reason_list: $ReadOnlyArray<{|
    +key: string,
    +value: string,
  |}>,
  +$refType: UserQuestionnaireQuestionSetFooter_answer_set$ref,
|};
export type UserQuestionnaireQuestionSetFooter_answer_set$data = UserQuestionnaireQuestionSetFooter_answer_set;
export type UserQuestionnaireQuestionSetFooter_answer_set$key = {
  +$data?: UserQuestionnaireQuestionSetFooter_answer_set$data,
  +$fragmentRefs: UserQuestionnaireQuestionSetFooter_answer_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnaireQuestionSetFooter_answer_set",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SelectOption",
      "kind": "LinkedField",
      "name": "skip_reason_list",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "key",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AnswerSetSkipReasonEnumType",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '68876bff3efc220c003bcdfe6ca2f49a';

module.exports = node;
