// @flow

import * as React from 'react'
import { connect } from 'react-fela'

import MinorArrowDown from 'react-ui/assets/icons/minor-arrow-down.svg'
import Icon from 'react-ui/components/Icon'
import Spacer from 'react-ui/components/Spacer'
import { withoutFelaProps } from 'shared/services/fela'
import { Button } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropTypes = FelaPropsType & {
  disabled?: boolean,
  label: React.Node,
}

const styleRules = ({ disabled, theme }) => ({
  LinkDropDown: {
    className: 'LinkDropDown',
    ...(() => {
      if (!disabled) {
        return {}
      }
      return {
        color: 'inherit',
        cursor: 'not-allowed',
        opacity: 0.8,
      }
    })(),
  },
  icon: {
    className: 'LinkDropDown__icon',
    color: theme.care.palette.text.default,
    height: '16px',
    lineHeight: 'inherit',
    width: '16px',
  },
})

const LinkDropDown = ({ label, rules, ...props }: PropTypes) => (
  <Button
    {...withoutFelaProps(props)}
    extend={(...args) => rules.LinkDropDown(...args)}
    variant="primary"
  >
    {label}
    <Spacer units={0.5} axis="horizontal" />
    <Icon as={MinorArrowDown} extend={(...args) => rules.icon(...args)} />
  </Button>
)

export default connect(styleRules)(LinkDropDown)
