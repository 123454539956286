// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useRelayEnvironment } from 'react-relay'
import useRouter from 'found/useRouter'
import { kebabCase } from 'lodash'

import ArrowRight from 'react-ui/assets/icons/arrow-right.svg'
import CheckMark from 'react-ui/assets/icons/minor-tick-circle.svg'
import Card from 'react-ui/components/Card/Card'
import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Icon from 'react-ui/components/Icon'
import type { QuestionnaireComplete_user_questionnaires_question_sets } from 'react-ui/components/QuestionnaireCompletePage/__generated__/QuestionnaireComplete_user_questionnaires_question_sets.graphql'
import type { QuestionnaireComplete_userQuestionnaire } from 'react-ui/components/QuestionnaireCompletePage/__generated__/QuestionnaireComplete_userQuestionnaire.graphql'
import { useCurrentUser } from 'react-ui/contexts/UserContext'
import { commit as commitUserQuestionnaireSkipAndDoAgain } from 'mutations/UserQuestionnaireSkipAndDoAgain'
import { routeQuestionSet } from 'services/questionnaire/routeQuestionnaireQuestionSet'

type Props = {
  completed?: boolean,
  list: $ReadOnlyArray<QuestionnaireComplete_user_questionnaires_question_sets>,
  title: string,
  user_questionnaire: QuestionnaireComplete_userQuestionnaire,
}

const containerStyle = () => ({
  textAlign: 'left',
  maxWidth: '65rem',
  margin: '3rem auto',
})
const cardContainerStyle = () => ({
  display: 'flex',
  flexWrap: 'wrap',
  margin: 0,
  justifyContent: 'space-between',
  padding: '2rem',
  flexDirection: 'row',
})

const titleStyle = () => ({
  paddingLeft: '1rem',
  fontWeight: 'bold',
})

const headingStyle = () => ({
  marginTop: '0',
  lineHeight: '1.5rem',
})
const cardStyle = ({ theme, completed }) => ({
  position: 'relative',
  minHeight: '4.5rem',
  padding: '0.75rem 1rem',
  width: '100%',
  justifyContent: 'center',
  ...(!completed ? { cursor: 'pointer' } : {}),
  [theme.breakpoints.queries.sm]: {
    width: '47%',
  },
})

const skipContentStyle = () => ({
  margin: 0,
  lineHeight: '1.5rem',
})

const iconStyle = () => ({
  position: 'absolute',
  right: '1rem',
  top: '50%',
  transform: 'translateY(-50%)',
})

const QuestionSetsList = ({
  list,
  title,
  completed = false,
  user_questionnaire: { ensure_questionnaire: { id: questionnaireId } },
}: Props) => {
  const { css, theme } = useFela()
  const environment = useRelayEnvironment()
  const { router } = useRouter()
  const { id: answereeUserId, current_role: { role_type } } = useCurrentUser()

  const iconColor = completed ? theme.palette.component.success.base : ''
  const iconSize = completed ? 0.6 : 0.4
  const iconDesign = completed ? CheckMark : ArrowRight

  const handleClick = questionnaireQuestionSetId => {
    commitUserQuestionnaireSkipAndDoAgain({
      environment,
      variables: {
        input: {
          questionnaireId,
          answereeId: answereeUserId,
          questionnaireQuestionSetId,
        },
      },
      onCompleted: ({ userQuestionnaireSkip }) => {
        if (!userQuestionnaireSkip)
          throw new Error('No userQuestionnaireSkip returned')

        const { user_questionnaire } = userQuestionnaireSkip

        const userQuestionnaireQuestionSet =
          user_questionnaire.first_user_questionnaires_question_set

        if (!userQuestionnaireQuestionSet)
          throw new Error('No questionnaireQuestionSetId found')

        routeQuestionSet({
          answererRoleType: role_type,
          answereeUserId,
          questionnaireQuestionSetId:
            userQuestionnaireQuestionSet.questionnaires_question_set.id,
          router,
        })
      },
    })
  }

  return (
    <Container>
      <div className={css(containerStyle)} data-testid={kebabCase(title)}>
        <p className={css(titleStyle)} data-testid="title">
          {title}
        </p>
        <Card extend={cardContainerStyle}>
          {list.map(
            ({
              question_set: { label },
              questionnaires_question_set,
              latest_submitted_answer_set,
            }) => (
              <Card
                extend={cardStyle({ theme, completed })}
                key={label}
                onClick={
                  !completed
                    ? () => handleClick(questionnaires_question_set?.id)
                    : null
                }
                data-testid="question-set"
              >
                <Heading level={4} extend={headingStyle}>
                  {label}
                </Heading>
                {!completed && (
                  <p className={css(skipContentStyle)}>
                    {latest_submitted_answer_set?.skip_reason_value}
                  </p>
                )}
                <Icon
                  as={iconDesign}
                  extend={iconStyle()}
                  color={iconColor}
                  scale={iconSize}
                />
              </Card>
            ),
          )}
        </Card>
      </div>
    </Container>
  )
}

export default QuestionSetsList
