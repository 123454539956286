// @flow

import React, { Component, createRef, forwardRef } from 'react'
import { useFela } from 'react-fela'
import cx from 'classnames'

import { withoutFelaProps } from 'shared/services/fela'

import s from './Input.scss'

type PropsType = {
  className?: string,
  disabled?: boolean,
  fullWidth?: boolean,
  onChange?: Function,
  uiStyle?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
}

type StateType = {
  wasFocused?: boolean, // a flag to show if it's focused before the disabling
}

const inputStyle = ({ theme }) => ({
  ...theme.care.typography.desktop.bodyLg,
})

const InputFunctionComponent =
  forwardRef <
  PropsType, any
  >
  ((props: PropsType, ref: any) => {
    const { css } = useFela()
    const {
      className,
      uiStyle = 'primary',
      fullWidth = false,
      ...other
    } = props

    let classes = {
      [s.root]: true,
      [s[uiStyle]]: true,
      [s.fullWidth]: fullWidth,
    }

    if (className) {
      classes = {
        ...classes,
        [className]: true,
      }
    }

    return (
      <input ref={ref} data-testid="AnswerOption" className={cx(classes,css(inputStyle))} {...withoutFelaProps(other)} />
    )
  })

export class Input extends Component<PropsType, StateType> {
  inputRef: any

  constructor(props: PropsType) {
    super(props)
    this.inputRef = createRef()
    this.state = { wasFocused: false }
  }

  componentDidUpdate(nextProps: PropsType) {
    if (
      this.props.disabled &&
      !nextProps.disabled &&
      this.state.wasFocused &&
      this.inputRef.current
    ) {
      this.reFocus()
    }
  }

  reFocus() {
    this.setState({ wasFocused: false })
    // re-enable and focus on the text input
    this.inputRef.current.disabled = false
    this.inputRef.current.focus()
  }

  wrappedOnChange() {
    return (event: Object) => {
      if (this.props.onChange) {
        this.props.onChange(event)
      }
      this.setState({ wasFocused: true })
    }
  }

  render() {
    return (
      <InputFunctionComponent
        {...this.props}
        ref={this.inputRef}
        onChange={this.wrappedOnChange()}
      />
    )
  }
}
