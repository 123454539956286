/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserTrackableList_user_trackables$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ExtensiveCardsSection_user$ref: FragmentReference;
declare export opaque type ExtensiveCardsSection_user$fragmentType: ExtensiveCardsSection_user$ref;
export type ExtensiveCardsSection_user = {|
  +extensiveUserTrackables: {|
    +nodes: ?$ReadOnlyArray<?{|
      +in_plan: boolean,
      +formula_range: ?{|
        +color?: string
      |},
      +trackable: {|
        +label: string
      |},
      +$fragmentRefs: UserTrackableList_user_trackables$ref,
    |}>
  |},
  +linked_individual: ?{|
    +individual_detail: ?{|
      +read_only: boolean
    |}
  |},
  +$refType: ExtensiveCardsSection_user$ref,
|};
export type ExtensiveCardsSection_user$data = ExtensiveCardsSection_user;
export type ExtensiveCardsSection_user$key = {
  +$data?: ExtensiveCardsSection_user$data,
  +$fragmentRefs: ExtensiveCardsSection_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ExtensiveCardsSection_user",
  "selections": [
    {
      "alias": "extensiveUserTrackables",
      "args": [
        {
          "kind": "Literal",
          "name": "kinds",
          "value": [
            "text",
            "additional_assessment"
          ]
        }
      ],
      "concreteType": "UserTrackableConnection",
      "kind": "LinkedField",
      "name": "user_trackables",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "UserTrackable",
          "kind": "LinkedField",
          "name": "nodes",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "in_plan",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "formula_range",
              "plural": false,
              "selections": [
                {
                  "kind": "InlineFragment",
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "color",
                      "storageKey": null
                    }
                  ],
                  "type": "FormulaRangeInterface",
                  "abstractKey": "__isFormulaRangeInterface"
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "Trackable",
              "kind": "LinkedField",
              "name": "trackable",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "label",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "UserTrackableList_user_trackables"
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": "user_trackables(kinds:[\"text\",\"additional_assessment\"])"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "linked_individual",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualDetail",
          "kind": "LinkedField",
          "name": "individual_detail",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "read_only",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'bde50ec2922e4945dc606d4dfc4675f5';

module.exports = node;
