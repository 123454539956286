/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserQuestionnaireDetailsPage_user_questionnaire$ref = any;
export type UserQuestionnaireDetailsPage_QueryVariables = {|
  id: string,
  questionnaire_id: string,
|};
export type UserQuestionnaireDetailsPage_QueryResponse = {|
  +user: {|
    +user_questionnaire: {|
      +$fragmentRefs: UserQuestionnaireDetailsPage_user_questionnaire$ref
    |}
  |}
|};
export type UserQuestionnaireDetailsPage_Query = {|
  variables: UserQuestionnaireDetailsPage_QueryVariables,
  response: UserQuestionnaireDetailsPage_QueryResponse,
|};
*/


/*
query UserQuestionnaireDetailsPage_Query(
  $id: ID!
  $questionnaire_id: ID!
) {
  user(id: $id, source: "root") {
    user_questionnaire(questionnaire_id: $questionnaire_id) {
      ...UserQuestionnaireDetailsPage_user_questionnaire
      id
    }
    id
  }
}

fragment UserQuestionSetDetailsView_user_question_set on UserQuestionSet {
  id
  user {
    id
  }
  question_set {
    id
  }
}

fragment UserQuestionSetTable_user_question_sets on UserQuestionSet {
  ...UserQuestionSetDetailsView_user_question_set
  id
  submitted_answer_sets {
    submitted_at
    skip_reason_value
    skipped
    answered_by {
      __typename
      ... on RoleInterface {
        __isRoleInterface: __typename
        id
        user {
          name
          id
        }
      }
      ... on AdminRole {
        id
      }
      ... on ClinicianRole {
        id
      }
      ... on IndividualRole {
        id
      }
      ... on LeadClinicianRole {
        id
      }
      ... on ManagerRole {
        id
      }
      ... on OwnerRole {
        id
      }
      ... on ResearcherRole {
        id
      }
      ... on SuperRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
    id
  }
  question_set {
    id
    label
  }
}

fragment UserQuestionnaireDetailsPage_user_questionnaire on UserQuestionnaire {
  id
  questionnaire {
    label
    id
  }
  ensure_questionnaire {
    label
    id
  }
  user_question_sets {
    for_role_type
    ...UserQuestionSetTable_user_question_sets
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "questionnaire_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "root"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "questionnaire_id",
    "variableName": "questionnaire_id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v5 = [
  (v4/*: any*/),
  (v3/*: any*/)
],
v6 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserQuestionnaireDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "UserQuestionnaire",
            "kind": "LinkedField",
            "name": "user_questionnaire",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserQuestionnaireDetailsPage_user_questionnaire"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserQuestionnaireDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "UserQuestionnaire",
            "kind": "LinkedField",
            "name": "user_questionnaire",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Questionnaire",
                "kind": "LinkedField",
                "name": "questionnaire",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Questionnaire",
                "kind": "LinkedField",
                "name": "ensure_questionnaire",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "UserQuestionSet",
                "kind": "LinkedField",
                "name": "user_question_sets",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "for_role_type",
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "User",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": (v6/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "QuestionSet",
                    "kind": "LinkedField",
                    "name": "question_set",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "AnswerSet",
                    "kind": "LinkedField",
                    "name": "submitted_answer_sets",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "submitted_at",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skip_reason_value",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "skipped",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "answered_by",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v3/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "user",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  (v3/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RoleInterface",
                            "abstractKey": "__isRoleInterface"
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "AdminRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "ClinicianRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "IndividualRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "LeadClinicianRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "ManagerRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "OwnerRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "ResearcherRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "SuperRole",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": (v6/*: any*/),
                            "type": "SupportPersonRole",
                            "abstractKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      (v3/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a27c6b4177a98f07c7e8dd6b017a9fa5",
    "id": null,
    "metadata": {},
    "name": "UserQuestionnaireDetailsPage_Query",
    "operationKind": "query",
    "text": "query UserQuestionnaireDetailsPage_Query(\n  $id: ID!\n  $questionnaire_id: ID!\n) {\n  user(id: $id, source: \"root\") {\n    user_questionnaire(questionnaire_id: $questionnaire_id) {\n      ...UserQuestionnaireDetailsPage_user_questionnaire\n      id\n    }\n    id\n  }\n}\n\nfragment UserQuestionSetDetailsView_user_question_set on UserQuestionSet {\n  id\n  user {\n    id\n  }\n  question_set {\n    id\n  }\n}\n\nfragment UserQuestionSetTable_user_question_sets on UserQuestionSet {\n  ...UserQuestionSetDetailsView_user_question_set\n  id\n  submitted_answer_sets {\n    submitted_at\n    skip_reason_value\n    skipped\n    answered_by {\n      __typename\n      ... on RoleInterface {\n        __isRoleInterface: __typename\n        id\n        user {\n          name\n          id\n        }\n      }\n      ... on AdminRole {\n        id\n      }\n      ... on ClinicianRole {\n        id\n      }\n      ... on IndividualRole {\n        id\n      }\n      ... on LeadClinicianRole {\n        id\n      }\n      ... on ManagerRole {\n        id\n      }\n      ... on OwnerRole {\n        id\n      }\n      ... on ResearcherRole {\n        id\n      }\n      ... on SuperRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n    id\n  }\n  question_set {\n    id\n    label\n  }\n}\n\nfragment UserQuestionnaireDetailsPage_user_questionnaire on UserQuestionnaire {\n  id\n  questionnaire {\n    label\n    id\n  }\n  ensure_questionnaire {\n    label\n    id\n  }\n  user_question_sets {\n    for_role_type\n    ...UserQuestionSetTable_user_question_sets\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'c33bf2e8a1a4f4591ffd7e9d8d0466c5';

module.exports = node;
