// @flow

import { createComponentWithProxy } from 'react-fela'

const AnchorTab = ({ theme, isSelected }) => {
  const { palette } = theme

  const tabButtonsStatusStyles = isSelected
    ? {
        color: palette.component.primary.accent,
        borderBottom: `1px solid ${palette.component.primary.accent}`,
      }
    : {
        color: palette.component.muted.mutedBase,
        '&:hover': {
          color: palette.component.primary.accent,
          borderBottom: `1px solid ${palette.component.primary.accent}`,
        },
        borderBottom: `1px solid ${palette.component.muted.mutedBase}`,
      }

  const fontSize = 18
  const verticalPadding = fontSize / 2

  return {
    className: 'Tabs__tab',
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'flex-end',
    paddingLeft: theme.component.horizontalPadding,
    paddingRight: theme.component.horizontalPadding,
    paddingTop: `${verticalPadding}px`,
    paddingBottom: `${verticalPadding}px`,
    margin: `0 ${theme.component.horizontalPadding}px 0 0`,
    fontSize: `${fontSize}px`,
    borderStyle: 'none',
    borderTopLeftRadius: theme.component.borderRadius,
    borderTopRightRadius: theme.component.borderRadius,
    cursor: 'pointer',
    outlineColor: theme.Input.focus.accent,
    backgroundColor: 'transparent',

    ...tabButtonsStatusStyles,
  }
}

export default createComponentWithProxy(AnchorTab, 'button')
