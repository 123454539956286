/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserCheckBoxLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualAssignmentsPopover_tenant$ref: FragmentReference;
declare export opaque type IndividualAssignmentsPopover_tenant$fragmentType: IndividualAssignmentsPopover_tenant$ref;
export type IndividualAssignmentsPopover_tenant = {|
  +assignable_individuals: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +id: string,
        +assigned_to_clinician: boolean,
        +user: {|
          +$fragmentRefs: UserCheckBoxLoader_user$ref
        |},
      |}
    |}>
  |},
  +$refType: IndividualAssignmentsPopover_tenant$ref,
|};
export type IndividualAssignmentsPopover_tenant$data = IndividualAssignmentsPopover_tenant;
export type IndividualAssignmentsPopover_tenant$key = {
  +$data?: IndividualAssignmentsPopover_tenant$data,
  +$fragmentRefs: IndividualAssignmentsPopover_tenant$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "clinician_id"
    },
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "assignable_individuals"
        ]
      }
    ]
  },
  "name": "IndividualAssignmentsPopover_tenant",
  "selections": [
    {
      "alias": "assignable_individuals",
      "args": null,
      "concreteType": "IndividualRoleConnection",
      "kind": "LinkedField",
      "name": "__IndividualAssignmentsPopover_assignable_individuals_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualRoleEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "IndividualRole",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": [
                    {
                      "kind": "Variable",
                      "name": "clinician_id",
                      "variableName": "clinician_id"
                    }
                  ],
                  "kind": "ScalarField",
                  "name": "assigned_to_clinician",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "User",
                  "kind": "LinkedField",
                  "name": "user",
                  "plural": false,
                  "selections": [
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "UserCheckBoxLoader_user"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "__typename",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '2bcf054e979a0c3f9c5c99079b93c1b9';

module.exports = node;
