// @flow

import { graphql } from 'react-relay'

export const clinicianDropDownIndividual = graphql`
  fragment ClinicianDropdown_individual on IndividualRole {
    tenant {
      summary_questionnaire {
        id
        answerable
      }
    }
    user {
      id
      clinician_notes {
        totalCount
      }
      ...ClinicianNoteActivity_user
    }
    duty_of_care_clinicians {
      user {
        id
      }
    }
  }
`

export const clinicianDropDownUser = graphql`
  fragment ClinicianDropdown_user on User {
    id
    staff_individuals_page_actions {
      scores
    }
  }
`
