// @flow

import React, { useState } from 'react'
import { curry } from 'lodash/fp'

import { FormError } from 'react-ui/components/Form'
import Spacer from 'react-ui/components/Spacer'
import createComponentId from 'shared/services/id'
import { RadioSet } from 'shared/ui/Forms/Fields'

import { type PropsType } from '.'

const selectOptions = options =>
  options.map(({ label, value }) => ({
    title: label,
    value,
  }))

const handleChange = curry(
  (
    { onTouched, onChange }: PropsType,
    { target: { value } }: SyntheticInputEvent<*>,
  ) => {
    onTouched()
    onChange(value)
  },
)

const defaultId = createComponentId(__filename)

const RadioWidget = ({
  componentId = defaultId,
  disabled,
  onClick,
  options: { enumOptions },
  value,
  rawErrors,
  ...otherProps
}: PropsType) => {
  const [touched, setTouched] = useState(false)
  const onTouched = () => setTouched(true)
  return (
    <div className={componentId} style={{ marginTop: '20px' }}>
      <RadioSet
        disabled={disabled}
        name={Math.random().toString()}
        onChange={handleChange({ ...otherProps, onTouched })}
        onClick={onClick}
        options={selectOptions(enumOptions)}
        uiStyle="primary"
        value={value}
      />
      {touched &&
        rawErrors && (
          <React.Fragment>
            <Spacer units={1} />
            <FormError>{rawErrors}</FormError>
          </React.Fragment>
        )}
    </div>
  )
}

export default RadioWidget
