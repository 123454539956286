// @flow

import React, { type Context, createContext, useContext, useState } from 'react'
import { Resolver } from 'found-relay'

import ConnectRouter from 'services/router'

type ContextPropsType = {
  reset: () => void,
}
type PropsType = {
  environment: Object,
}

const RouterResetContext: Context<ContextPropsType> = createContext({
  reset: () => {},
})

export default function Router({ environment }: PropsType) {
  function createResolver() {
    return new Resolver(environment)
  }

  const [resolver, setResolver] = useState(createResolver())

  function reset() {
    setResolver(createResolver())
  }

  return (
    <RouterResetContext.Provider value={{ reset }}>
      <ConnectRouter resolver={resolver} />
    </RouterResetContext.Provider>
  )
}

export function useRouterReset() {
  return useContext(RouterResetContext).reset
}
