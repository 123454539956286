/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedbackTopicSelect_viewer$ref: FragmentReference;
declare export opaque type FeedbackTopicSelect_viewer$fragmentType: FeedbackTopicSelect_viewer$ref;
export type FeedbackTopicSelect_viewer = {|
  +enums: {|
    +feedback: {|
      +topic_list: $ReadOnlyArray<{|
        +key: string,
        +value: string,
      |}>
    |}
  |},
  +$refType: FeedbackTopicSelect_viewer$ref,
|};
export type FeedbackTopicSelect_viewer$data = FeedbackTopicSelect_viewer;
export type FeedbackTopicSelect_viewer$key = {
  +$data?: FeedbackTopicSelect_viewer$data,
  +$fragmentRefs: FeedbackTopicSelect_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackTopicSelect_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Enums",
      "kind": "LinkedField",
      "name": "enums",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EnumsFeedbackTopic",
          "kind": "LinkedField",
          "name": "feedback",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SelectOption",
              "kind": "LinkedField",
              "name": "topic_list",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "key",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "value",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'b2d7853447f5b959d51c34a5042134aa';

module.exports = node;
