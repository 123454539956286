// @flow

import React, {
  type ComponentType,
  type Context,
  type Node,
  createContext,
} from 'react'
import { createComponentWithProxy } from 'react-fela'
import classNames from 'classnames'
import Link from 'found/Link'
import { omit } from 'lodash/fp'

import createComponentId from 'shared/services/id'
import { createLogger } from 'shared/services/logging'

import { type FelaStyleRulesType } from 'react-ui/typing'

export type ButtonProps = {
  children?: Node,
  className?: string,
  componentId?: string,
  disabled?: boolean,
  href?: string,
  icon?: Node,
  id?: string,
  onClick?: (event: SyntheticEvent<HTMLElement>) => any,
  shape?: 'square' | 'rounded' | 'circle' | 'pill',
  to?: ?(string | { [string]: any }),
  type?: 'button' | 'submit' | 'reset',
}

export type ButtonStyleProps = {
  color?:
    | 'danger'
    | 'info'
    | 'muted'
    | 'primary'
    | 'secondary'
    | 'success'
    | 'warning',
  fullWidth?: boolean,
  ghost?: boolean,
  invert?: boolean,
  naked?: boolean,
  shape?: 'square' | 'rounded' | 'circle' | 'pill',
} & ButtonProps

export type ButtonStatusContextType = {
  isLoading: boolean,
}

export const ButtonStatusContext: Context<
  ButtonStatusContextType,
> = createContext({})

const log = createLogger(__filename)

const defaultId = createComponentId(__filename)

const Button = ({
  children,
  componentId = defaultId,
  disabled,
  href,
  icon,
  shape,
  to,
  type = 'button',
  ...props
}: ButtonProps) => {
  if (!href && !to && !props.onClick && !disabled && type !== 'submit') {
    log('The Button component needs a `href`, `onClick` or `to` prop')
  }

  const { Element, action } = (() => {
    if (disabled) {
      // ensure that buttons that act like links, when disabled, show as disabled
      return { Element: 'button', action: { disabled, type } }
    } else if (to) {
      return { Element: Link, action: { to } }
    } else if (href) {
      return { Element: 'a', action: { href } }
    }
    return { Element: 'button', action: { type, disabled } }
  })()

  const otherProps = omit('targetToggle')({ ...props })

  return (
    <Element {...action} {...otherProps} data-testid={componentId}>
      {shape !== 'circle' && children}
      {icon && children && ' '}
      {icon}
    </Element>
  )
}

const styleRules = ({
  color = 'primary',
  fullWidth,
  ghost,
  invert,
  naked,
  shape = 'square',
  theme,
}: ButtonStyleProps & FelaStyleRulesType) => {
  const palette = theme.Button[color]
  const disabledStyle = {
    cursor: 'not-allowed',
    opacity: 0.8,
    border: 'none',
    backgroundColor: theme.palette.component.disabled.base,
    color: theme.palette.component.disabled.text,
  }

  return {
    borderRadius: (() => {
      if (shape === 'circle') {
        return '100%'
      }
      if (shape === 'rounded') {
        return '0.325rem'
      }
      if (shape === 'pill') {
        return '5rem'
      }
      return 0
    })(),
    borderStyle: 'solid',
    borderWidth: '2px',
    boxSizing: 'border-box',
    className: classNames('Button', {
      [`--${shape}`]: !!shape,
      '--block': fullWidth,
      '--ghost': ghost,
      '--invert': invert,
      '--naked': naked,
    }).replace(/\s/g, ''),
    cursor: 'pointer',
    display: fullWidth && shape !== 'circle' ? 'block' : 'inline-block',
    fontSize: '18px',
    fontWeight: 'normal',
    height: theme.spacing(1.5),
    lineHeight: '36px',
    ...(() => {
      if (shape === 'circle') {
        return {
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }
      }
      const hPadding =
        shape === 'pill'
          ? `calc(${theme.Grid.gutter} * 1.25)`
          : theme.Grid.gutter
      const vPadding = 0
      return {
        paddingBottom: vPadding,
        paddingLeft: hPadding,
        paddingRight: hPadding,
        paddingTop: vPadding,
      }
    })(),
    textAlign: 'center',
    textDecoration: 'none',
    transitionDuration: '.125s',
    transitionProperty: 'background-color, color',
    whiteSpace: 'nowrap',
    width: (() => {
      if (shape === 'circle') {
        return '42px'
      }
      if (fullWidth) {
        return '100%'
      }
      return 'auto'
    })(),

    ':disabled': disabledStyle,
    ':disabled:hover': disabledStyle,

    // Apply colors
    ...((): any => {
      if (invert && !ghost) {
        return {
          backgroundColor: palette.text,
          borderColor: palette.text,
          color: palette.base,
          ':hover': {
            borderColor: theme.Button.invertBorder,
            backgroundColor: palette.text,
            color: palette.accent,
          },
        }
      }

      if (ghost && !invert) {
        return {
          backgroundColor: 'transparent',
          borderColor: palette.base,
          color: palette.base,
          ':hover': {
            borderColor: palette.accent,
            backgroundColor: palette.accent,
            color: palette.text,
          },
        }
      }

      if (ghost && invert) {
        return {
          backgroundColor: 'transparent',
          borderColor: palette.text,
          color: palette.text,
          ':hover': {
            borderColor: palette.text,
            backgroundColor: palette.text,
            color: palette.accent,
          },
        }
      }

      return {
        backgroundColor: palette.base,
        borderColor: palette.base,
        color: palette.text,
        ':hover': {
          borderColor: palette.accent,
          backgroundColor: palette.accent,
          color: palette.text,
        },
        ':active': {
          backgroundColor: palette.active,
          color: palette.text,
        },
      }
    })(),

    // reset if naked
    ...(() => {
      if (!naked) {
        return {}
      }

      return {
        backgroundColor: 'transparent',
        borderWidth: '0',
        color: invert ? palette.base : undefined,
        textDecoration: 'initial',
        paddingBottom: 0,
        paddingLeft: 0,
        paddingRight: 0,
        paddingTop: 0,
        ':hover': {
          backgroundColor: 'transparent',
          color: invert ? palette.active : undefined,
        },
      }
    })(),
  }
}

const StyledButton = createComponentWithProxy(styleRules, Button, ['shape'])

export default (StyledButton: ComponentType<ButtonStyleProps>)
