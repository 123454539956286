// @flow

import { graphql } from 'react-relay'

export const healthCardFragment = graphql`
  fragment HealthCard_user_trackable on UserTrackable {
    ...HealthCardFooter_user_trackable
    formula_range {
      ... on FormulaRangeInterface {
        color
        label
      }
    }
    time_since_updated
    trackable {
      id
      label
      kind
    }
    trending {
      label
    }
    user {
      id
    }
  }
`
