// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'
import { navy } from 'care-ui/atoms/colors/colorTokens'

import useGraphPagination from '../../hooks/useGraphPagination'
import { type OverviewQueryVariableType } from '../../hooks/useOverviewInsights'
import { query as stbEscalationsQuery } from '../../queries/StbEscalationsInsightsQuery'

import { type StbEscalationsInsightsQuery } from '../../queries/__generated__/StbEscalationsInsightsQuery.graphql'

type ComponentProps = {
  loadStbEscalationsQuery: (
    overviewQueryVariables: OverviewQueryVariableType,
  ) => void,
  overviewQueryVariables: OverviewQueryVariableType,
  queryReference: PreloadedQuery<StbEscalationsInsightsQuery>,
}

const Component = (props: ComponentProps) => {
  const {
    overviewQueryVariables,
    queryReference,
    loadStbEscalationsQuery,
  } = props
  const queryResult = usePreloadedQuery(stbEscalationsQuery, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.stb_escalations.series_categories',
  )
  const series = get(queryResult, 'viewer.insights.stb_escalations.series')

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    overviewQueryVariables,
    loadQuery: loadStbEscalationsQuery,
  })

  return (
    <Graph
      colors={[navy[400], navy[500]]}
      graphType="column"
      series={series}
      seriesCategories={seriesCategories}
      onPaginateLeft={() => onPaginate('left')}
      onPaginateRight={() => onPaginate('right')}
      isRightPaginationDisabled={isRightPaginationDisabled}
    />
  )
}

type StbEscalationsInsightsGraphProps = {
  overviewQueryVariables: OverviewQueryVariableType,
}

// Query Loader
const StbEscalationsInsightsGraph = (
  props: StbEscalationsInsightsGraphProps,
) => {
  const { overviewQueryVariables } = props

  const [
    stbEscalationsQueryReference,
    loadStbEscalationsQuery,
  ] = useQueryLoader(stbEscalationsQuery)

  // Load query when query variables change
  useEffect(
    () => {
      loadStbEscalationsQuery(overviewQueryVariables)
    },
    [overviewQueryVariables],
  )

  // transalations
  const { t: translation } = useTranslation('insights')

  return (
    <GraphContainer
      heading={translation('stb_escalations')}
      graphInfoText={translation(
        'this_graph_visualises_the_number_of_suicidal_thoughts_and_behaviours_notifications_over_the_specified_period',
      )}
    >
      <Suspense>
        {stbEscalationsQueryReference != null && (
          <Component
            overviewQueryVariables={overviewQueryVariables}
            queryReference={stbEscalationsQueryReference}
            loadStbEscalationsQuery={loadStbEscalationsQuery}
          />
        )}
      </Suspense>
    </GraphContainer>
  )
}

export default StbEscalationsInsightsGraph
