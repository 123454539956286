// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import Edit from 'react-ui/assets/icons/edit.svg'
import { DropDownItem } from 'react-ui/components/DropDown'
import Icon from 'react-ui/components/Icon'
import { useDependentToggle } from 'platform_web/hooks/useDependentToggle'
import { Button, Modal } from 'care-ui'

import ClinicianNoteForm from './ClinicianNoteForm'

import { type FelaPropsType } from 'react-ui/typing'
import { type ClinicianNoteActivity_user } from './__generated__/ClinicianNoteActivity_user.graphql'

type PropsType = FelaPropsType & {
  staff: boolean,
  targetToggle?: () => void,
  user?: ClinicianNoteActivity_user,
}

const styleRules = () => ({
  clinicianNoteIcon: {
    marginRight: '5px',
  },
  totalNotes: {
    fontSize: '14px',
  },
})

const ClinicianNoteActivityBase = (props: PropsType) => {
  const [isOpen, setIsOpen] = useState(false)
  const { rules, styles, user, staff = false, targetToggle } = props

  const onToggleOpen = () => setIsOpen(prev => !prev)

  useDependentToggle(!isOpen, targetToggle)

  // Localization
  const { t: translation } = useTranslation(['dataTable', 'shared'])

  const { id, clinician_notes } = user || {}

  if (!user || !clinician_notes) return null

  const { totalCount } = clinician_notes || {}

  const counter = totalCount > 0 ? ` (${totalCount})` : ''

  return (
    <>
      {staff && (
        <DropDownItem
          onClick={onToggleOpen}
          label={translation('add_note_count', { count: counter })}
        />
      )}

      {!staff && (
        <Button
          dataTestId="clinicianNoteModalButton"
          variant="text"
          onClick={onToggleOpen}
        >
          <span>
            <Icon as={Edit} extend={rules.clinicianNoteIcon} scale={0.5} />
          </span>
          <span className={styles.totalNotes}>
            {totalCount} {translation('total')}
          </span>
        </Button>
      )}

      <Modal
        isOpen={isOpen}
        closeModal={onToggleOpen}
        heading={translation('add_note', { ns: 'shared' })}
      >
        <ClinicianNoteForm userId={id} />
      </Modal>
    </>
  )
}

const ClinicianNoteActivity = createFragmentContainer(
  connect(styleRules)(ClinicianNoteActivityBase),
  {
    user: graphql`
      fragment ClinicianNoteActivity_user on User {
        id
        clinician_notes {
          totalCount
        }
      }
    `,
  },
)

export default ClinicianNoteActivity
