/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type SupportingSupporteeQuestionnairesList_user$ref = any;
export type SupportingSupporteeQuestionnairesListPaginationQueryVariables = {|
  count?: ?number,
  cursor?: ?string,
  supporteeUserId?: ?string,
  id: string,
|};
export type SupportingSupporteeQuestionnairesListPaginationQueryResponse = {|
  +node: ?{|
    +$fragmentRefs: SupportingSupporteeQuestionnairesList_user$ref
  |}
|};
export type SupportingSupporteeQuestionnairesListPaginationQuery = {|
  variables: SupportingSupporteeQuestionnairesListPaginationQueryVariables,
  response: SupportingSupporteeQuestionnairesListPaginationQueryResponse,
|};
*/


/*
query SupportingSupporteeQuestionnairesListPaginationQuery(
  $count: Int = 10
  $cursor: String
  $supporteeUserId: ID
  $id: ID!
) {
  node(id: $id) {
    __typename
    ...SupportingSupporteeQuestionnairesList_user_3ZCwxp
    id
  }
}

fragment SupportingSupporteeQuestionnairesList_user_3ZCwxp on User {
  id
  current_role {
    __typename
    ... on SupportPersonRole {
      assessment_request_questionnaires(supportee_user_id: $supporteeUserId, first: $count, after: $cursor) {
        edges {
          node {
            questionnaire {
              id
              label
            }
            assessment_request {
              id
              individual {
                id
                legal_name
                user {
                  id
                }
              }
            }
            __typename
          }
          cursor
        }
        pageInfo {
          endCursor
          hasNextPage
        }
      }
      id
    }
    ... on AdminRole {
      id
    }
    ... on ClinicianRole {
      id
    }
    ... on IndividualRole {
      id
    }
    ... on LeadClinicianRole {
      id
    }
    ... on ManagerRole {
      id
    }
    ... on OwnerRole {
      id
    }
    ... on ResearcherRole {
      id
    }
    ... on SuperRole {
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": 10,
  "kind": "LocalArgument",
  "name": "count"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "cursor"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "id"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "supporteeUserId"
},
v4 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Variable",
    "name": "supportee_user_id",
    "variableName": "supporteeUserId"
  }
],
v8 = [
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SupportingSupporteeQuestionnairesListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "args": [
              {
                "kind": "Variable",
                "name": "count",
                "variableName": "count"
              },
              {
                "kind": "Variable",
                "name": "cursor",
                "variableName": "cursor"
              },
              {
                "kind": "Variable",
                "name": "supporteeUserId",
                "variableName": "supporteeUserId"
              }
            ],
            "kind": "FragmentSpread",
            "name": "SupportingSupporteeQuestionnairesList_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SupportingSupporteeQuestionnairesListPaginationQuery",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          (v5/*: any*/),
          (v6/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": null,
                "kind": "LinkedField",
                "name": "current_role",
                "plural": false,
                "selections": [
                  (v5/*: any*/),
                  {
                    "kind": "InlineFragment",
                    "selections": [
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "concreteType": "AssessmentRequestQuestionnaireTypeConnection",
                        "kind": "LinkedField",
                        "name": "assessment_request_questionnaires",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "AssessmentRequestQuestionnaireTypeEdge",
                            "kind": "LinkedField",
                            "name": "edges",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AssessmentRequestQuestionnaireType",
                                "kind": "LinkedField",
                                "name": "node",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "Questionnaire",
                                    "kind": "LinkedField",
                                    "name": "questionnaire",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "label",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "AssessmentRequest",
                                    "kind": "LinkedField",
                                    "name": "assessment_request",
                                    "plural": false,
                                    "selections": [
                                      (v6/*: any*/),
                                      {
                                        "alias": null,
                                        "args": null,
                                        "concreteType": "IndividualRole",
                                        "kind": "LinkedField",
                                        "name": "individual",
                                        "plural": false,
                                        "selections": [
                                          (v6/*: any*/),
                                          {
                                            "alias": null,
                                            "args": null,
                                            "kind": "ScalarField",
                                            "name": "legal_name",
                                            "storageKey": null
                                          },
                                          {
                                            "alias": null,
                                            "args": null,
                                            "concreteType": "User",
                                            "kind": "LinkedField",
                                            "name": "user",
                                            "plural": false,
                                            "selections": (v8/*: any*/),
                                            "storageKey": null
                                          }
                                        ],
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v5/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "cursor",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "PageInfo",
                            "kind": "LinkedField",
                            "name": "pageInfo",
                            "plural": false,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "endCursor",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "hasNextPage",
                                "storageKey": null
                              }
                            ],
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": (v7/*: any*/),
                        "filters": [
                          "supportee_user_id"
                        ],
                        "handle": "connection",
                        "key": "SupportingSupporteeQuestionnairesList_assessment_request_questionnaires",
                        "kind": "LinkedHandle",
                        "name": "assessment_request_questionnaires"
                      },
                      (v6/*: any*/)
                    ],
                    "type": "SupportPersonRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "AdminRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "ClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "IndividualRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "LeadClinicianRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "ManagerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "OwnerRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "ResearcherRole",
                    "abstractKey": null
                  },
                  {
                    "kind": "InlineFragment",
                    "selections": (v8/*: any*/),
                    "type": "SuperRole",
                    "abstractKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "type": "User",
            "abstractKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "63e0200e9059bae77e82d093d3aec329",
    "id": null,
    "metadata": {},
    "name": "SupportingSupporteeQuestionnairesListPaginationQuery",
    "operationKind": "query",
    "text": "query SupportingSupporteeQuestionnairesListPaginationQuery(\n  $count: Int = 10\n  $cursor: String\n  $supporteeUserId: ID\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ...SupportingSupporteeQuestionnairesList_user_3ZCwxp\n    id\n  }\n}\n\nfragment SupportingSupporteeQuestionnairesList_user_3ZCwxp on User {\n  id\n  current_role {\n    __typename\n    ... on SupportPersonRole {\n      assessment_request_questionnaires(supportee_user_id: $supporteeUserId, first: $count, after: $cursor) {\n        edges {\n          node {\n            questionnaire {\n              id\n              label\n            }\n            assessment_request {\n              id\n              individual {\n                id\n                legal_name\n                user {\n                  id\n                }\n              }\n            }\n            __typename\n          }\n          cursor\n        }\n        pageInfo {\n          endCursor\n          hasNextPage\n        }\n      }\n      id\n    }\n    ... on AdminRole {\n      id\n    }\n    ... on ClinicianRole {\n      id\n    }\n    ... on IndividualRole {\n      id\n    }\n    ... on LeadClinicianRole {\n      id\n    }\n    ... on ManagerRole {\n      id\n    }\n    ... on OwnerRole {\n      id\n    }\n    ... on ResearcherRole {\n      id\n    }\n    ... on SuperRole {\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'b357bad2293618b3b6c407efee8f1310';

module.exports = node;
