// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  ReintroduceMutationResponse,
  ReintroduceMutationVariables,
} from './__generated__/ReintroduceMutation.graphql'

const mutation = graphql`
  mutation ReintroduceMutation($input: RoleReintroduceInput!) {
    roleReintroduce(input: $input) {
      role {
        ...IndividualsTable_individual_mutation
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: ReintroduceMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: ReintroduceMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
