// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'
import { map } from 'lodash/fp'

import { ConnectedCliniciansListLoader } from 'components/ConnectedTenants/ConnectedCliniciansList'
import Heading from 'react-ui/components/Heading'
import createComponentId from 'shared/services/id'

type PropsType = {
  componentId?: string,
  +individuals: any,
}

const defaultId = createComponentId(__filename)

const IndividualRolesListBase = (props: PropsType) => {
  const { componentId = defaultId, individuals } = props

  const renderedIndividuals = map(individual => (
    <div className={`${componentId}-${individual.id}`} key={individual.id}>
      <Heading level={4}>{individual.tenant.name}</Heading>
      <ConnectedCliniciansListLoader
        clinician_assignments={individual.clinician_assignments}
      />
    </div>
  ))(individuals)

  return <div className={componentId}>{renderedIndividuals}</div>
}

export const IndividualRolesListLoader = createFragmentContainer(
  IndividualRolesListBase,
  {
    individuals: graphql`
      fragment IndividualRolesList_individuals on IndividualRole
        @relay(plural: true) {
        ... on IndividualRole {
          clinician_assignments {
            ...ConnectedCliniciansList_clinician_assignments
          }
        }
        ... on RoleInterface {
          id
          tenant {
            name
          }
        }
      }
    `,
  },
)
