// @flow

import { graphql } from 'react-relay'

export const scoreCardsCareOptionsListUserResources = {
  user_resources: graphql`
    fragment ScoreCardsCareOptionsList_user_resources on UserResource
      @relay(plural: true) {
      ...ScoreCardsCareOptionsList_user_resource @relay(mask: false)
    }
  `,
  viewer: graphql`
    fragment ScoreCardsCareOptionsList_viewer on Viewer {
      ...UserResource_viewer
    }
  `,
}

// eslint-disable-next-line no-unused-vars
const scoreCardsCareOptionsListUserResource = graphql`
  fragment ScoreCardsCareOptionsList_user_resource on UserResource {
    ...UserResource_user_resource
    status
    id
    resource {
      clinical
      id
      label
    }
  }
`
