// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserQuestionnaireRefreshMutationResponse,
  UserQuestionnaireRefreshMutationVariables,
} from './__generated__/UserQuestionnaireRefreshMutation.graphql'

const mutation = graphql`
  mutation UserQuestionnaireRefreshMutation(
    $input: UserQuestionnaireRefreshInput!
  ) {
    userQuestionnaireRefresh(input: $input) {
      user_questionnaire {
        user {
          id
          email
          preferred_name
        }
        id
        ready_to_complete
        ...UserQuestionnaire_user_questionnaire @relay(mask: false)
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: UserQuestionnaireRefreshMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: UserQuestionnaireRefreshMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
