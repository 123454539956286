/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnairesQuestionSet_Answer_question$ref: FragmentReference;
declare export opaque type UserQuestionnairesQuestionSet_Answer_question$fragmentType: UserQuestionnairesQuestionSet_Answer_question$ref;
export type UserQuestionnairesQuestionSet_Answer_question = {|
  +answer: ?{|
    +id?: string,
    +value?: number,
  |},
  +$refType: UserQuestionnairesQuestionSet_Answer_question$ref,
|};
export type UserQuestionnairesQuestionSet_Answer_question$data = UserQuestionnairesQuestionSet_Answer_question;
export type UserQuestionnairesQuestionSet_Answer_question$key = {
  +$data?: UserQuestionnairesQuestionSet_Answer_question$data,
  +$fragmentRefs: UserQuestionnairesQuestionSet_Answer_question$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnairesQuestionSet_Answer_question",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "answer",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AnswerInteger",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AnswerImperialLength",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AnswerString",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AnswerMultichoice",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v0/*: any*/),
          "type": "AnswerMultiselect",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionnairesQuestionSetQuestionEdge",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f7c91d6a28cec4f990b889aaa9e7d3e0';

module.exports = node;
