/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ConnectedTenantsPage_user$ref = any;
export type ConnectedTenantsPage_QueryVariables = {||};
export type ConnectedTenantsPage_QueryResponse = {|
  +viewer: {|
    +currentUser: ?{|
      +$fragmentRefs: ConnectedTenantsPage_user$ref
    |}
  |}
|};
export type ConnectedTenantsPage_Query = {|
  variables: ConnectedTenantsPage_QueryVariables,
  response: ConnectedTenantsPage_QueryResponse,
|};
*/


/*
query ConnectedTenantsPage_Query {
  viewer {
    currentUser {
      ...ConnectedTenantsPage_user
      id
    }
    id
  }
}

fragment ConnectedCliniciansList_clinician_assignments on ClinicianAssignment {
  clinician {
    id
    name
  }
}

fragment ConnectedTenantsList_tenants on Tenant {
  label
  name
}

fragment ConnectedTenantsPage_user on User {
  roles(roleTypes: [INDIVIDUAL]) {
    nodes {
      __typename
      ... on IndividualRole {
        ...IndividualRolesList_individuals
        id
      }
      ... on AdminRole {
        id
      }
      ... on ClinicianRole {
        id
      }
      ... on LeadClinicianRole {
        id
      }
      ... on ManagerRole {
        id
      }
      ... on OwnerRole {
        id
      }
      ... on ResearcherRole {
        id
      }
      ... on SuperRole {
        id
      }
      ... on SupportPersonRole {
        id
      }
    }
  }
  individual_tenants {
    ...ConnectedTenantsList_tenants
    id
  }
}

fragment IndividualRolesList_individuals on IndividualRole {
  clinician_assignments {
    ...ConnectedCliniciansList_clinician_assignments
    id
  }
  ... on RoleInterface {
    __isRoleInterface: __typename
    id
    tenant {
      name
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v2 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ConnectedTenantsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "ConnectedTenantsPage_user"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ConnectedTenantsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "roleTypes",
                    "value": [
                      "INDIVIDUAL"
                    ]
                  }
                ],
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "ClinicianAssignment",
                            "kind": "LinkedField",
                            "name": "clinician_assignments",
                            "plural": true,
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "ClinicianRole",
                                "kind": "LinkedField",
                                "name": "clinician",
                                "plural": false,
                                "selections": [
                                  (v0/*: any*/),
                                  (v1/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v0/*: any*/)
                            ],
                            "storageKey": null
                          },
                          (v0/*: any*/),
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Tenant",
                                "kind": "LinkedField",
                                "name": "tenant",
                                "plural": false,
                                "selections": [
                                  (v1/*: any*/),
                                  (v0/*: any*/)
                                ],
                                "storageKey": null
                              }
                            ],
                            "type": "RoleInterface",
                            "abstractKey": "__isRoleInterface"
                          }
                        ],
                        "type": "IndividualRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "AdminRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "ClinicianRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "LeadClinicianRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "ManagerRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "OwnerRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "ResearcherRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "SuperRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "SupportPersonRole",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "roles(roleTypes:[\"INDIVIDUAL\"])"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "individual_tenants",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "label",
                    "storageKey": null
                  },
                  (v1/*: any*/),
                  (v0/*: any*/)
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "59d2ee406f2240d42ec64b105125eaf7",
    "id": null,
    "metadata": {},
    "name": "ConnectedTenantsPage_Query",
    "operationKind": "query",
    "text": "query ConnectedTenantsPage_Query {\n  viewer {\n    currentUser {\n      ...ConnectedTenantsPage_user\n      id\n    }\n    id\n  }\n}\n\nfragment ConnectedCliniciansList_clinician_assignments on ClinicianAssignment {\n  clinician {\n    id\n    name\n  }\n}\n\nfragment ConnectedTenantsList_tenants on Tenant {\n  label\n  name\n}\n\nfragment ConnectedTenantsPage_user on User {\n  roles(roleTypes: [INDIVIDUAL]) {\n    nodes {\n      __typename\n      ... on IndividualRole {\n        ...IndividualRolesList_individuals\n        id\n      }\n      ... on AdminRole {\n        id\n      }\n      ... on ClinicianRole {\n        id\n      }\n      ... on LeadClinicianRole {\n        id\n      }\n      ... on ManagerRole {\n        id\n      }\n      ... on OwnerRole {\n        id\n      }\n      ... on ResearcherRole {\n        id\n      }\n      ... on SuperRole {\n        id\n      }\n      ... on SupportPersonRole {\n        id\n      }\n    }\n  }\n  individual_tenants {\n    ...ConnectedTenantsList_tenants\n    id\n  }\n}\n\nfragment IndividualRolesList_individuals on IndividualRole {\n  clinician_assignments {\n    ...ConnectedCliniciansList_clinician_assignments\n    id\n  }\n  ... on RoleInterface {\n    __isRoleInterface: __typename\n    id\n    tenant {\n      name\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ee27eda4a14fb3e2ab1631e085cbd2a4';

module.exports = node;
