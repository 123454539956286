// @flow

import React from 'react'
import { connect } from 'react-fela'
import { merge, omit } from 'lodash/fp'
import numeral from 'numeral'

import { felaProps } from 'shared/services/fela'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  as: string,
  format?: string | null,
  value: number | string,
  variant: 'default' | 'grande',
}

const styleRules = ({ theme, variant = 'default' }) =>
  merge(
    {
      HeroNumber: {
        className: 'HeroNumber',
        display: 'inline-block',
        color: theme.care.palette.text.positive,
        ...theme.care.typography.desktop.bodyLgBold,
      },
      suffix: {
        className: 'HeroNumber__suffix',
        color: theme.care.palette.text.positive,
        ...theme.care.typography.desktop.bodyLgBold,
      },
    },
    (() => {
      if (variant === 'grande') {
        return {
          HeroNumber: {
            className: 'HeroNumber--variant-grande',
            fontSize: '47px',
            lineHeight: theme.spacing(2),
          },
        }
      }

      return {}
    })(),
  )

const HeroNumber = ({
  as: Component = 'span',
  format,
  styles,
  value,
  ...props
}: PropsType) => {
  // Don't format the value if 'none' is passed as the format
  const formattedValue =
    format === null ? String(value) : numeral(value).format(format || '0,0')
  const parts = formattedValue.split('.')
  const suffix = parts[1]
  return (
    <Component
      {...omit(['variant', ...felaProps])(props)}
      className={styles.HeroNumber}
    >
      {parts[0]}
      {!!suffix && <small className={styles.suffix}>.{suffix}</small>}
    </Component>
  )
}

export default connect(styleRules)(HeroNumber)
