// @flow

import { graphql } from 'react-relay'

export const commonTasksButtonsIndividual = graphql`
  fragment CommonTasksButtons_individual on IndividualRole {
    ...RetakeQuestionnaireDropdown_individual
    ...SupportTaskButton_individual
    ...RequestAssessmentButton_individual
    ...ClinicianDropdown_individual
    ...StaffDownloadReportButton_individual
  }
`

export const commonTasksButtonsUser = graphql`
  fragment CommonTasksButtons_user on User {
    ...ClinicianDropdown_user
  }
`
