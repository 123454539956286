// @flow

import { graphql } from 'react-relay'

import { commitMutationPromise } from 'shared/services/formik'

import type {
  IndividualPersonalDetailUpdateMutationResponse,
  IndividualPersonalDetailUpdateMutationVariables,
} from './__generated__/IndividualPersonalDetailUpdateMutation.graphql'

const mutation = graphql`
  mutation IndividualPersonalDetailUpdateMutation(
    $input: IndividualPersonalDetailUpdateInput!
  ) {
    individualPersonalDetailUpdate(input: $input) {
      viewer {
        currentUser {
          show_individual_onboarding
          policies {
            name
            enabled
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (
    result: IndividualPersonalDetailUpdateMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: IndividualPersonalDetailUpdateMutationVariables,
}) =>
  commitMutationPromise(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
