/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualsPage_user$ref: FragmentReference;
declare export opaque type IndividualsPage_user$fragmentType: IndividualsPage_user$ref;
export type IndividualsPage_user = {|
  +id: string,
  +tab_options: $ReadOnlyArray<{|
    +id: string,
    +label: string,
    +value: string,
  |}>,
  +initial_state: string,
  +tenant: ?{|
    +id: string,
    +important_formulas: $ReadOnlyArray<{|
      +label: string,
      +formula: {|
        +id: string
      |},
    |}>,
  |},
  +$refType: IndividualsPage_user$ref,
|};
export type IndividualsPage_user$data = IndividualsPage_user;
export type IndividualsPage_user$key = {
  +$data?: IndividualsPage_user$data,
  +$fragmentRefs: IndividualsPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndividualsPage_user",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "TabOption",
      "kind": "LinkedField",
      "name": "tab_options",
      "plural": true,
      "selections": [
        (v0/*: any*/),
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "initial_state",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": [
            {
              "kind": "Literal",
              "name": "kind",
              "value": "individual"
            }
          ],
          "concreteType": "ImportantFormula",
          "kind": "LinkedField",
          "name": "important_formulas",
          "plural": true,
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Formula",
              "kind": "LinkedField",
              "name": "formula",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": "important_formulas(kind:\"individual\")"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ae0df00c5e787b8e9b9ec834e5f79f3';

module.exports = node;
