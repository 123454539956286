// @flow

import { graphql } from 'react-relay'

export const assessmentRequestsListPaginatedQuery = graphql`
  query AssessmentRequestsList_PaginatedQuery(
    $id: ID!
    $count: Int!
    $cursor: String
    $isIndividual: Boolean!
    $isClincian: Boolean!
  ) {
    node(id: $id) {
      ... on User {
        ...AssessmentRequestsList_user
          @include(if: $isClincian)
          @arguments(count: $count, cursor: $cursor)
        ...AssessmentRequestsList_individual_user
          @include(if: $isIndividual)
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`

export const assessmentRequestsListFragment = graphql`
  fragment AssessmentRequestsList_user on User
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 2 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "AssessmentRequestsListPaginationQuery") {
    id
    assessment_requested(first: $count, after: $cursor)
      @connection(key: "AssessmentRequestsList_assessment_requested") {
      edges {
        node {
          ... on AssessmentRequest {
            ...AssessmentRequestsComponent_assessment_request
          }
        }
      }
    }
  }
`

export const individualAssessmentRequestsListFragment = graphql`
  fragment AssessmentRequestsList_individual_user on User
    @argumentDefinitions(
      count: { type: "Int", defaultValue: 2 }
      cursor: { type: "String" }
    )
    @refetchable(queryName: "UserAssessmentRequestsListPaginationQuery") {
    id
    current_role {
      ... on IndividualRole {
        assessment_requested(first: $count, after: $cursor)
          @connection(
            key: "AssessmentRequestsList_individual_assessment_requested"
          ) {
          edges {
            node {
              ... on AssessmentRequest {
                ...AssessmentRequestsComponent_assessment_request
              }
            }
          }
        }
      }
    }
  }
`
