// @flow

import { graphql } from 'react-relay'

import { commitMutationPromise } from 'shared/services/formik'

import type {
  IndividualPersonalDetailCreateMutationResponse,
  IndividualPersonalDetailCreateMutationVariables,
} from './__generated__/IndividualPersonalDetailCreateMutation.graphql'

const mutation = graphql`
  mutation IndividualPersonalDetailCreateMutation(
    $input: IndividualPersonalDetailCreateInput!
  ) {
    individualPersonalDetailCreate(input: $input) {
      viewer {
        currentUser {
          show_individual_onboarding
          show_clinician_onboarding
          policies {
            name
            enabled
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (
    result: IndividualPersonalDetailCreateMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: IndividualPersonalDetailCreateMutationVariables,
}) =>
  commitMutationPromise(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
