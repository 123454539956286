// @flow

import { graphql } from 'react-relay'

export const healthHistoryUser = {
  user: graphql`
    fragment HealthHistory_user on User {
      health_history_user_questionnaires_question_sets {
        id
        latest_submitted_non_skipped_answer_set {
          ...AnswerSet_answer_set
        }
        question_set {
          label
        }
      }
    }
  `,
}
