// @flow

import { useState } from 'react'
import { useMutation } from 'react-relay'
import { type GraphQLTaggedNode } from 'relay-runtime'

import type { UseMultiSelectReturnTypes } from 'platform_web/pages/Staff/hooks/useMultiSelect'
import { type QueryVariablesType } from 'platform_web/pages/Staff/Individuals/hooks/useIndividualTable'

export type UseMultiRoleAssignmentReturnTypes = {
  assignRoles: (assignIndividuals?: boolean) => void,
  assignedRoleIds: Array<string>,
  failedList: Array<string>,
  isInFlight: boolean,
  toggleAssignedRoleIds: (roleId: string) => void,
}

type UseMultiRoleAssignmentType = {
  closeModal: () => void,
  multiSelectPayload: UseMultiSelectReturnTypes,
  mutationQuery: GraphQLTaggedNode,
  queryVariables: QueryVariablesType,
}

const useMultiRoleAssignment = (
  props: UseMultiRoleAssignmentType,
): UseMultiRoleAssignmentReturnTypes => {
  const [assignedRoleIds, setAssignedRoleIds] = useState([])

  const toggleAssignedRoleIds = (roleId: string) => {
    const updatedAssignedRoleIds: Array<string> = assignedRoleIds.includes(
      roleId,
    )
      ? assignedRoleIds.filter(i => i !== roleId)
      : [...assignedRoleIds, roleId]
    setAssignedRoleIds(updatedAssignedRoleIds)
  }

  const {
    closeModal,
    multiSelectPayload,
    queryVariables,
    mutationQuery,
  } = props

  const {
    assigned,
    filterEscalation,
    helpRequested,
    prioritise,
    status,
    search,
  } = queryVariables

  const filters = {
    assignedToMe: !!assigned,
    filterEscalation: !!filterEscalation,
    helpRequested: !!helpRequested,
    prioritiseEscalations: !!prioritise,
    roleStatus: [String(status)],
    search: String(search),
  }

  const filtersIndividuals = {
    search: String(search),
    roleTypes: ['CLINICIAN'],
  }

  const {
    allSelected,
    selectedRoleIds,
    deselectedRoleIds,
    resetMultiSelectState,
  } = multiSelectPayload

  // Commit mutation to perform action
  const [commit, isInFlight] = useMutation(mutationQuery)
  // prettier-ignore
  const [failedList, setFailedList] = useState([])

  const assignRoles = (assignIndividuals: boolean = false) => {
    const input = assignIndividuals
      ? {
          individuals: {
            dataTableType: 'clinician',
            selectedRoleIds: assignedRoleIds,
          },
          clinicians: {
            selectedRoleIds: allSelected ? [] : selectedRoleIds,
            deselectedRoleIds,
            ...(allSelected && { filters: filtersIndividuals }),
          },
        }
      : {
          individuals: {
            dataTableType: 'individual',
            selectedRoleIds: allSelected ? [] : selectedRoleIds,
            deselectedRoleIds,
            ...(allSelected && { filters }),
          },
          clinicians: {
            selectedRoleIds: assignedRoleIds,
          },
        }

    commit({
      variables: {
        input,
      },
      onCompleted: data => {
        setFailedList(data?.clinicianAssignmentsCreate?.unassignable_clinicians)
      },
    })
    if (!assignIndividuals) {
      closeModal()
    }
    resetMultiSelectState()
  }

  return {
    assignRoles,
    assignedRoleIds,
    toggleAssignedRoleIds,
    failedList,
    isInFlight,
  }
}

export default useMultiRoleAssignment
