// @flow

import { type ThemeType } from 'care-ui/atoms'

export const styleRules = ({
  asideColSpan,
  theme,
}: {
  asideColSpan: number,
  theme: ThemeType,
}) => {
  const { columns, containerMaxWidth, gutter } = theme.Grid
  const fixedContainerQuery = `@media screen and (min-width: ${containerMaxWidth}px)`
  const contentColSpan = columns - asideColSpan
  const articleScalar = contentColSpan / columns
  const articleWidthInPx = `calc(${articleScalar *
    containerMaxWidth}px + ${gutter})`
  const contentColSpanScalar = contentColSpan / columns

  return {
    ButtonWrapper: {
      marginBottom: theme.spacing(1.5),
    },
    heading: {
      marginTop: theme.care.spacing.xs,
    },
    ServiceMetricsPage: {
      backgroundColor: theme.palette.lightBackground,
      className: 'ServiceMetricsPage',
      display: 'flex',
      flex: '1 1 100%',
      '-ms-flex': '1 1 auto',
      flexFlow: 'column nowrap',
    },
    Wrapper: {
      backgroundColor: theme.Header.backgroundColor,
      borderBottomColor: theme.palette.dividerColor,
      borderBottomStyle: 'solid',
      borderBottomWidth: '1px',
    },
    actions: {
      className: `ServiceMetricsPage__actions--asideColSpan-${asideColSpan}`,
      paddingBottom: theme.spacing(1.5),
      paddingTop: theme.spacing(1.5),
      position: 'relative',

      ':after': {
        borderBottom: '1px solid #D0D6DE',
        content: '""',
        display: 'block',
        position: 'absolute',
        bottom: 0,
        width: '100%',
        left: `-${gutter}`,
        right: `-${gutter}`,

        /**
         * Here be dragons:
         * 1. The aside column does not line up with the grid, it's moved to the
         *    left by 1/2 of the grid gutter.
         * 2. The horizontal rule stretches outside of the container.
         *    Set the width to:
         *    the size of the contentColSpan + the grid gutter offset
         */
        [theme.mq.MULTI_COLUMN]: {
          left: `calc(${gutter} * -2)`,
          maxWidth: `calc(
            ${gutter} +
            ${gutter} +
            ${contentColSpanScalar * 100}vw
        )`,
        },

        [fixedContainerQuery]: {
          maxWidth: `calc(
          calc(calc(100vw - ${containerMaxWidth}px) / 2)
          + ${articleWidthInPx}
          + ${gutter}
        )`,
        },
      },
    },
    article: {
      justifyContent: 'flex-start',
    },
    articleContent: {
      boxSizing: 'border-box',
      paddingBottom: theme.spacing(1.5),
      paddingLeft: theme.Grid.gutter,
      paddingRight: theme.Grid.gutter,
    },
    aside: {
      backgroundColor: 'white',
      justifyContent: 'flex-start',
      [theme.mq.MULTI_COLUMN]: {
        // This column does not line up with the grid :(
        transform: `translateX(-${gutter})`,
      },
      '@media only print': {
        display: 'none',
      },
    },
    asideContent: {
      alignItems: 'stretch',
      [theme.mq.MULTI_COLUMN]: {
        alignItems: 'flex-end',
        paddingBottom: theme.spacing(1.5),
        paddingRight: '45px',
        paddingTop: theme.spacing(1.5),
        transform: `translateX(calc(${gutter} * 2))`,
      },
    },
    reportViewContainer: {
      className: 'reportsViewContainer',
      margin: '2em auto -2.6em',
    },
    reportViewAction: {
      className: 'reportsViewAction',
      borderRadius: '0.5em 0.5em 0em 0em',
      backgroundColor: '#206059',
      padding: '0.5em 1em',
      color: '#d4e0e0',
      marginRight: '1em',
      '&:hover': {
        backgroundColor: '#ebebeb',
        color: '#2b7870',
      },
    },
    reportViewActionActive: {
      className: 'reportsViewActionActive',
      borderRadius: '0.5em 0.5em 0em 0em',
      backgroundColor: '#ebebeb',
      padding: '0.5em 1em',
      color: '#3d8c86',
      marginRight: '1em',
      '&:hover': {
        backgroundColor: '#206059',
        color: '#d4e0e0',
      },
    },
  }
}
