/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ClinicianNoteCreateInput = {|
  clientMutationId?: ?string,
  clinician_note: ClinicianNoteCreateInputObject,
|};
export type ClinicianNoteCreateInputObject = {|
  about_id: string,
  text: string,
|};
export type ClinicianNoteCreateMutationVariables = {|
  input: ClinicianNoteCreateInput
|};
export type ClinicianNoteCreateMutationResponse = {|
  +clinicianNoteCreate: ?{|
    +clinician_note: {|
      +id: string,
      +about: {|
        +clinician_notes: ?{|
          +totalCount: number,
          +nodes: ?$ReadOnlyArray<?{|
            +id: string,
            +created_at: any,
            +text: string,
            +author: {|
              +id: string,
              +name: string,
            |},
          |}>,
        |}
      |},
    |}
  |}
|};
export type ClinicianNoteCreateMutation = {|
  variables: ClinicianNoteCreateMutationVariables,
  response: ClinicianNoteCreateMutationResponse,
|};
*/


/*
mutation ClinicianNoteCreateMutation(
  $input: ClinicianNoteCreateInput!
) {
  clinicianNoteCreate(input: $input) {
    clinician_note {
      id
      about {
        clinician_notes {
          totalCount
          nodes {
            id
            created_at
            text
            author {
              id
              name
            }
          }
        }
        id
      }
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "ClinicianNoteConnection",
  "kind": "LinkedField",
  "name": "clinician_notes",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "totalCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianNote",
      "kind": "LinkedField",
      "name": "nodes",
      "plural": true,
      "selections": [
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "created_at",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "text",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianRole",
          "kind": "LinkedField",
          "name": "author",
          "plural": false,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "ClinicianNoteCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClinicianNoteCreatePayload",
        "kind": "LinkedField",
        "name": "clinicianNoteCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianNote",
            "kind": "LinkedField",
            "name": "clinician_note",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "about",
                "plural": false,
                "selections": [
                  (v3/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "ClinicianNoteCreateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "ClinicianNoteCreatePayload",
        "kind": "LinkedField",
        "name": "clinicianNoteCreate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianNote",
            "kind": "LinkedField",
            "name": "clinician_note",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "User",
                "kind": "LinkedField",
                "name": "about",
                "plural": false,
                "selections": [
                  (v3/*: any*/),
                  (v2/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fdfb69d01afbb6c26ced8606230f947d",
    "id": null,
    "metadata": {},
    "name": "ClinicianNoteCreateMutation",
    "operationKind": "mutation",
    "text": "mutation ClinicianNoteCreateMutation(\n  $input: ClinicianNoteCreateInput!\n) {\n  clinicianNoteCreate(input: $input) {\n    clinician_note {\n      id\n      about {\n        clinician_notes {\n          totalCount\n          nodes {\n            id\n            created_at\n            text\n            author {\n              id\n              name\n            }\n          }\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '553bafabeab1924a602d1820b68cc020';

module.exports = node;
