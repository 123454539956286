// @flow

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  usePreloadedQuery,
  useQueryLoader,
  useRelayEnvironment,
} from 'react-relay'

import { commit as commitAssessmentRequestCreate } from 'mutations/AssessmentRequestCreate'
import { useAssessmentRequesteeContext } from 'platform_web/features/AssessmentRequests'
import {
  Box,
  Button,
  DropDownMenuItem,
  FlexContainer,
  FlexItem,
  Heading,
  Modal,
  SearchBar,
  SuspenseLoader,
} from 'care-ui'
import useModal from 'care-ui/molecules/hooks/useModal'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

import AssessmentRequesteesList from '../AssessmentRequesteesList'
import AssessmentTrackablesList from '../AssessmentTrackablesList'
import { questionnaireFields } from '../helpers'
import SelectedAssessmentChips from '../SelectedAssessmentChips'

import { query } from './query/IndividualTrackablesList'

type BasePropsType = {
  queryReference: any,
  requesteeRoleId: ?string,
  selectedQuestionnaires: Array<string>,
  setLinkedIndividual: any => void,
  setSelectedQuestionnaires: (Array<string>) => void,
}

const IndividualTrackablesListBase = (props: BasePropsType) => {
  const {
    queryReference,
    requesteeRoleId,
    selectedQuestionnaires,
    setLinkedIndividual,
    setSelectedQuestionnaires,
  } = props
  const { user } = usePreloadedQuery(query, queryReference)

  const { t: translation } = useTranslation('assessment')
  const { linked_individual } = user || {}
  const { id: individualId, requestableQuestionnaires } =
    linked_individual || {}

  useEffect(
    () => {
      setLinkedIndividual(linked_individual)
    },
    [linked_individual],
  )

  const {
    allHealthDomains,
    ...questionnaireFieldsPayload
  } = questionnaireFields({
    requestableQuestionnaires,
    requesteeRoleId,
    individualId,
    selectedQuestionnaires,
  })

  // SEARCH FUNCTIONALITY
  // Frontend only filter search
  const [searchString, setSearchString] = useState('')

  const handleOnChange = (e: SyntheticInputEvent<>) => {
    setSearchString(e.target.value)
  }

  const clearSearch = () => {
    setSearchString('')
  }

  const filtered = allHealthDomains.filter(healthDomain =>
    healthDomain.label.toLowerCase().includes(searchString.toLowerCase()),
  )

  return (
    <>
      <>
        <Heading level={6} margin="xs">
          {translation('select_questionnaire_to_request')}
        </Heading>

        <Box marginY={selectedQuestionnaires?.length ? '0' : 'xxs'}>
          <SearchBar
            inputAttributes={{
              id: 'searchInput',
              onChange: handleOnChange,
              value: searchString,
              placeholder: translation('search_for_questionnaire'),
            }}
            dataTestId="search-bar"
            ariaLabel={translation('search_bar')}
            clearSearch={clearSearch}
            fullWidth
          />
        </Box>
      </>

      {!!selectedQuestionnaires?.length && (
        <Box paddingX="xxxs">
          <SelectedAssessmentChips
            selectedQuestionnairesIDs={selectedQuestionnaires}
            setSelectedQuestionnaires={setSelectedQuestionnaires}
            requestableQuestionnaires={requestableQuestionnaires}
          />
        </Box>
      )}

      <AssessmentTrackablesList
        questionnaireFieldsPayload={questionnaireFieldsPayload}
        selectedQuestionnaires={selectedQuestionnaires}
        setSelectedQuestionnaires={setSelectedQuestionnaires}
        filtered={filtered}
      />
    </>
  )
}

const IndividualTrackablesList = ({ isMenuItem }: { isMenuItem?: boolean }) => {
  const [queryReference, loadQuery] = useQueryLoader(query)
  const [linkedIndividual, setLinkedIndividual] = useState()
  const {
    userId,
    setAssessmentRequesteeId,
    assessmentRequesteeId,
    preSelected,
    setPreSelected,
  } = useAssessmentRequesteeContext()
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([])

  const environment = useRelayEnvironment()

  const { id: linkedIndividualId } = linkedIndividual || {}

  const { isOpen, openModal, closeModal } = useModal()

  const { t: translation } = useTranslation('assessment')

  useEffect(
    () => {
      if (!assessmentRequesteeId) {
        setAssessmentRequesteeId(linkedIndividualId)
      }
    },
    [linkedIndividualId, assessmentRequesteeId],
  )

  useEffect(
    () => {
      if (preSelected) {
        setSelectedQuestionnaires([preSelected])
        openModal()
      }
    },
    [preSelected],
  )

  useEffect(
    () => {
      if (!preSelected) setSelectedQuestionnaires([])

      loadQuery({
        id: userId,
        requesteeRoleId: assessmentRequesteeId,
        loadList: Boolean(assessmentRequesteeId),
      })
    },
    [assessmentRequesteeId, preSelected],
  )

  const handleModalClose = () => {
    setSelectedQuestionnaires([])
    setPreSelected('')
    closeModal()
  }

  const submitRequest = () => {
    if (
      selectedQuestionnaires.length &&
      assessmentRequesteeId &&
      linkedIndividualId
    ) {
      commitAssessmentRequestCreate({
        environment,
        onCompleted: () => setSelectedQuestionnaires([]),
        variables: {
          input: {
            assessment_request: {
              questionnaireIds: selectedQuestionnaires,
              requesteeRoleId: assessmentRequesteeId,
              individualId: linkedIndividualId,
            },
          },
        },
      })
    }

    handleModalClose()
  }

  const content = queryReference !== null && (
    <SuspenseLoader message={translation('updating_list')}>
      <IndividualTrackablesListBase
        queryReference={queryReference}
        selectedQuestionnaires={selectedQuestionnaires}
        setSelectedQuestionnaires={setSelectedQuestionnaires}
        requesteeRoleId={assessmentRequesteeId}
        setLinkedIndividual={setLinkedIndividual}
      />
    </SuspenseLoader>
  )

  return (
    <>
      {isMenuItem && (
        <DropDownMenuItem onClick={openModal}>
          <TextNew as="span" typography="bodyLg">
            {translation('request_assessments')}
          </TextNew>
        </DropDownMenuItem>
      )}

      {!isMenuItem && (
        <Button variant="primary" onClick={openModal}>
          {translation('request_assessments')}
        </Button>
      )}

      <Modal
        closeModal={handleModalClose}
        isOpen={isOpen}
        heading={translation('request_assessments')}
        width="600px"
      >
        <AssessmentRequesteesList
          individual={linkedIndividual}
          setRequesteeRoleId={setAssessmentRequesteeId}
          requesteeRoleId={assessmentRequesteeId}
        />

        <FlexContainer direction="column" gap="xs" justifyContent="center">
          <Box width="100%">{content}</Box>

          <FlexItem alignSelf="center">
            <Button
              variant="primary"
              onClick={submitRequest}
              dataTestId="submitRequest"
              disabled={!selectedQuestionnaires?.length}
            >
              {translation('send_requests')}
            </Button>
          </FlexItem>
        </FlexContainer>
      </Modal>
    </>
  )
}

export default IndividualTrackablesList
