// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const styleRules = (props: StyleProps) => {
  const { theme: { Grid: { gutter } } } = props

  return {
    container: {
      marginTop: gutter,
      marginBottom: gutter,
    },
    Select: {
      className: 'Select',
      width: '245px',
    },
    DateInput: {
      marginBottom: gutter,
    },
  }
}
