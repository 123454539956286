// @flow

import React, { useState } from 'react'
import { format } from 'date-fns'

import { type Lifetime } from 'platform_web/components/QuarterPicker/hooks/useQuarterGenerator'
import {
  Box,
  DropDownMenu,
  DropDownMenuItem,
  FlexContainer,
  Text,
} from 'care-ui'
import TextNew from 'care-ui/molecules/TextNew/TextNew'

type QuarterRangeProps = {
  onChange: (queryVariables: {
    [key: string]: string,
  }) => void,
  quarter: Lifetime,
  setSelectedLabel: (label: string) => void,
  toggleParentDropdown: () => void,
}

const formatDate = (date = new Date()) => format(new Date(date), 'yyyy-MM-dd')

const LifetimeQuarter = (props: QuarterRangeProps) => {
  const { quarter, setSelectedLabel, toggleParentDropdown, onChange } = props
  const { label, secondaryLabel, quarterStartDate, quarterEndDate } = quarter
  const [closeDropDown, setCloseDropDown] = useState(true)

  const toggleDropDownMenu = () => {
    toggleParentDropdown()
    setSelectedLabel(label)
    onChange({
      quarter_start_date: formatDate(quarterStartDate),
      quarter_end_date: formatDate(quarterEndDate),
    })
  }

  return (
    <DropDownMenu
      position="left"
      closeDropDown={closeDropDown}
      setCloseDropDown={setCloseDropDown}
      dropDownTrigger={
        <Box width="305px">
          <DropDownMenuItem
            onClick={toggleDropDownMenu}
            dataTestId="customDateSelector"
          >
            <Box width="100%">
              <FlexContainer
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                gap="xxxs"
              >
                <FlexContainer>
                  <FlexContainer>
                    <Text size="md">{label}</Text>
                  </FlexContainer>
                  {secondaryLabel && (
                    <Box marginY="xxs">
                      <TextNew as="div" color="caption" typography="bodySm">
                        {secondaryLabel}
                      </TextNew>
                    </Box>
                  )}
                </FlexContainer>
              </FlexContainer>
            </Box>
          </DropDownMenuItem>
        </Box>
      }
    />
  )
}

export default LifetimeQuarter
