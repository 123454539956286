// @flow

import { commit as commitGeneric } from './ReinviteGeneric'
import { commit as commitIndividual } from './ReinviteIndividual'
import { commit as commitStaff } from './ReinviteStaff'

import { type RoleType } from 'react-ui/typing'

const commit = (roleType: RoleType) => {
  switch (roleType) {
    case 'INDIVIDUAL':
      return commitIndividual
    case 'ADMIN':
    case 'CLINICIAN':
    case 'LEAD_CLINICIAN':
    case 'MANAGER':
    case 'OWNER':
    case 'RESEARCHER':
      return commitStaff
    default:
      return commitGeneric
  }
}

export default commit
