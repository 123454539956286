// @flow

export const white = '#fff'
export const black = '#000'

export const synBlue = {
  '25': '#edf7ff',
  '50': '#daeeff',
  '100': '#a8d7ff',
  '200': '#7ac2ff',
  '300': '#4eabff',
  '400': '#2b97fb',
  '500': '#0f77d9',
  '600': '#0968C3',
  '700': '#0358b1',
  '800': '#024490',
  '900': '#003272',
  a50: '#6c82a2',
  a100: '#94c6fa',
  a200: '#59a8f7',
  a300: '#1e8df5',
  a400: '#007ef5',
  a500: '#0069f0',
  a600: '#005fd9',
  a700: '#004daa',
}

export const synGreen = {
  '50': '#deefee',
  '100': '#bcdfdc',
  '200': '#72bdb7',
  '300': '#3ba69d',
  '400': '#1d9b91',
  '500': '#008479',
  '600': '#01786f',
  '700': '#006059',
  '800': '#004e49',
  '900': '#003b36',
  a50: '#e9f9f5',
  a100: '#b3ebde',
  a150: '#59cfbd',
  a200: '#4dc3b9',
  a300: '#0ea89b',
  a400: '#029388',
  a700: '#016a63',
}

export const synYellow = {
  '50': '#f6f3ea',
  '100': '#efebdf',
  '200': '#e4ddc6',
  '300': '#cec196',
  '400': '#b4a369',
  '500': '#85743a',
  '600': '#74632a',
  '700': '#65541a',
  '800': '#53430d',
  '900': '#3d3108',
  a100: '#f3eddb',
  a200: '#ebe1bf',
  a400: '#c6ac57',
  a700: '#745c0b',
}

export const greyscale = {
  '25': '#fafafa',
  '30': '#f8f9fc',
  '40': '#f4f4f4',
  '45': '#f2eeee',
  '50': '#e7e9ee',
  '100': '#d0d6de',
  '200': '#a3adbb',
  '300': '#7f91a7',
  '400': '#71829c',
  '500': '#637691',
  '600': '#5b6c84',
  '700': '#48566a',
  '800': '#3a4656',
  '900': '#293240',
  a100: '#bfd0e9',
  a200: '#a5c0e5',
  a400: '#5674a0',
  a700: '#354c6a',
}

export const alertGreen = {
  '50': '#e8f5e9',
  '100': '#c8e6c9',
  '200': '#a5d6a7',
  '300': '#81c784',
  '400': '#66bb6a',
  '500': '#008801',
  '600': '#007801',
  '700': '#006A01',
  '800': '#005B01',
  '900': '#004C01',
  a100: '#b9f6ca',
  a200: '#69f0ae',
  a400: '#00e676',
  a700: '#006A01',
}
export const alertOrange = {
  '50': '#fff3e0',
  '100': '#ffe0b2',
  '200': '#ffcc80',
  '300': '#ffb74d',
  '400': '#ffa726',
  '500': '#ff9800',
  '600': '#fb8c00',
  '700': '#f57c00',
  '800': '#c25429',
  '900': '#e65100',
  a100: '#ffd180',
  a200: '#ffab40',
  a400: '#ff9100',
  a700: '#ff6d00',
}

export const alertRed = {
  '50': '#ffebee',
  '100': '#ffcdd2',
  '200': '#ef9a9a',
  '300': '#e57373',
  '400': '#ef5350',
  '500': '#f44336',
  '600': '#e53935',
  '700': '#d32f2f',
  '800': '#c62828',
  '900': '#b71c1c',
  a100: '#ff8a80',
  a200: '#ff5252',
  a400: '#ff1744',
  a700: '#d50000',
}

export const newGreen = {
  '100': '#3a813c',
  '200': '#00b289',
}
