// @flow

import React, { type Node, useRef, useState } from 'react'
import { useFela } from 'react-fela'
import { useClickAway } from 'react-use'

import { truncateText } from 'platform_web/utility/formatText'
import { Box, Button, DropDownMenuItem, ScrollableBox } from 'care-ui'
import { ChevronDownIcon } from 'care-ui/atoms/icons-new'
import FlexContainer from 'care-ui/layouts/Flex/FlexContainer'

import {
  labelStyle,
  optionImageStyle,
  optionsContainer,
  selectBoxWrapper,
} from './SimpleSelect.style'

type OptionType = {
  icon?: Node,
  label: string,
  value: string,
}

type SimpleSelectProps = {
  dataTestIdForDropdown?: string,
  dataTestIdForDropdownOptions?: string,
  defaultOption?: OptionType,
  hasScrollbox?: boolean,
  id?: string,
  label?: string,
  name?: string,
  onChange: (value: string) => void,
  options: Array<OptionType>,
  position?: string,
  size?: 'lg' | 'md' | 'sm',
  truncateLabel?: boolean,
  variant?: 'primary' | 'secondary' | 'text' | 'pill' | 'danger',
}

const SimpleSelect = (props: SimpleSelectProps) => {
  const {
    defaultOption,
    label,
    options,
    onChange,
    name,
    hasScrollbox = false,
    id,
    dataTestIdForDropdown,
    dataTestIdForDropdownOptions,
    size = 'md',
    variant = 'secondary',
    truncateLabel = false,
  } = props

  const { css } = useFela({ ...props })

  const OptionsContainerComponent = hasScrollbox ? ScrollableBox : Box

  // Toggle Select Box open close
  const [isSelectBoxOpen, setIsSelectBoxOpen] = useState(false)
  const toggleSelectBox = () => setIsSelectBoxOpen(!isSelectBoxOpen)

  // Logic For selctbox
  const defaultOpt = defaultOption || options[0]
  const [selectedOption, setSelectedOption] = useState(defaultOpt)

  const handleOnClick = option => {
    setSelectedOption(option)
    onChange(option.value)
    toggleSelectBox()
  }

  // CLICK OUTSIDE HANDLER
  const ref = useRef(null)

  useClickAway(ref, () => {
    setIsSelectBoxOpen(false)
  })

  // Label for dropdown
  const getLabel = option => {
    // options with icon/image
    const { icon } = option || {}

    if (icon) {
      return (
        <FlexContainer alignItems="center" wrap="nowrap">
          <img src={icon} alt="flag" className={css(optionImageStyle)} />
          {option?.label}
        </FlexContainer>
      )
    }

    return (
      <div>{truncateLabel ? truncateText(option?.label) : option?.label}</div>
    )
  }

  return (
    <div
      className={css(selectBoxWrapper)}
      ref={ref}
      name={name}
      id={id}
      data-testid={dataTestIdForDropdown}
    >
      {label && (
        <label htmlFor={name} className={css(labelStyle)}>
          {label}
        </label>
      )}

      <Button
        variant={variant}
        onClick={toggleSelectBox}
        size={size}
        fullWidth
        rightIcon={ChevronDownIcon}
        compactPadding={!!selectedOption?.icon}
      >
        {getLabel(selectedOption)}
      </Button>

      {isSelectBoxOpen && (
        <div className={css(optionsContainer)}>
          <OptionsContainerComponent maxHeight="250px">
            {options.map(option => {
              if (option.value === selectedOption.value) return null

              return (
                <DropDownMenuItem
                  onClick={() => handleOnClick(option)}
                  dataTestId={dataTestIdForDropdownOptions}
                  key={option.value}
                >
                  {option?.icon ? getLabel(option) : option.label}
                </DropDownMenuItem>
              )
            })}
          </OptionsContainerComponent>
        </div>
      )}
    </div>
  )
}

export default SimpleSelect
