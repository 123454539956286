// @flow

import React from 'react'
import { includes } from 'lodash/fp'

import { Box, Chip, FlexContainer, ScrollableBox } from 'care-ui'

type PropsType = {
  requestableQuestionnaires: Array<{ [string]: string }>,
  selectedQuestionnairesIDs: Array<string>,
  setSelectedQuestionnaires: (result: any) => void,
}

const SelectedAssessmentChips = (props: PropsType) => {
  const {
    requestableQuestionnaires,
    selectedQuestionnairesIDs,
    setSelectedQuestionnaires,
  } = props

  const selectedQuestionnaires = requestableQuestionnaires?.filter(
    requestableQuestionnaire =>
      includes(requestableQuestionnaire.id)(selectedQuestionnairesIDs),
  )

  const onChange = questionnaireId => {
    setSelectedQuestionnaires(
      prev =>
        prev.includes(questionnaireId)
          ? prev.filter(q => q !== questionnaireId)
          : [...prev, questionnaireId],
    )
  }

  return (
    <ScrollableBox direction="horizontal" maxWidth="68vw">
      <Box paddingX="xxxs">
        <FlexContainer gap="xs" wrap="nowrap">
          {selectedQuestionnaires?.map(selectedQuestionnaire => (
            <Chip
              key={selectedQuestionnaire.id}
              label={selectedQuestionnaire.label}
              onClick={() => onChange(selectedQuestionnaire.id)}
            />
          ))}
        </FlexContainer>
      </Box>
    </ScrollableBox>
  )
}

export default SelectedAssessmentChips
