// @flow

export const navy = {
  '50': '#F0F0FF',
  '100': '#E6E5FF',
  '200': '#C9C7FF',
  '300': '#A6A3FF',
  '400': '#706BFF',
  '500': '#040068',
  '600': '#030066',
  '700': '#02003D',
  '800': '#010029',
  '900': '#010014',
  '950': '#00000A',
}

export const red = {
  '50': '#FBEAEA',
  '100': '#F6D0D0',
  '200': '#EDA6A6',
  '300': '#E47777',
  '400': '#DB4848',
  '500': '#C62727',
  '600': '#9E1F1F',
  '700': '#771717',
  '800': '#511010',
  '900': '#260808',
  '950': '#150404',
}

export const orange = {
  '50': '#FFFAF0',
  '100': '#FFF5E0',
  '200': '#FFEBC2',
  '300': '#FFE2A8',
  '400': '#FFD88A',
  '500': '#FFCD6B',
  '600': '#FFB624',
  '700': '#DB9200',
  '800': '#8F5F00',
  '900': '#473000',
  '950': '#241800',
}

export const yellow = {
  '50': '#FFFEEB',
  '100': '#FFFDDB',
  '200': '#FFFBB3',
  '300': '#FEF98F',
  '400': '#FEF440',
  '500': '#FEF441',
  '600': '#FEF101',
  '700': '#C1B701',
  '800': '#7F7901',
  '900': '#423F00',
  '950': '#1E1D00',
}

export const green = {
  '50': '#F0F9F6',
  '100': '#DEF2EA',
  '200': '#BDE5D4',
  '300': '#9CD9BF',
  '400': '#7BCCAA',
  '500': '#5ABF95',
  '600': '#3FA278',
  '700': '#2F795A',
  '800': '#1F513C',
  '900': '#10281E',
  '950': '#091610',
}

export const pink = {
  '50': '#FFFAFF',
  '100': '#FFF5FF',
  '200': '#FFE5FF',
  '300': '#FFDBFF',
  '400': '#FFD1FF',
  '500': '#FFC5FF',
  '600': '#FF6BFF',
  '700': '#FF0FFF',
  '800': '#B300B3',
  '900': '#5C005C',
  '950': '#2E002E',
}

export const purple = {
  '50': '#F7F0FF',
  '100': '#EFE0FF',
  '200': '#E2C7FF',
  '300': '#D2A8FF',
  '400': '#C58FFF',
  '500': '#B670FF',
  '600': '#9029FF',
  '700': '#6A00DB',
  '800': '#470094',
  '900': '#230047',
  '950': '#110024',
}

export const mint = {
  '50': '#FBFEFE',
  '100': '#F2FBFC',
  '200': '#E6F6FA',
  '300': '#D9F2F7',
  '400': '#CCEDF5',
  '500': '#C0E9F2',
  '600': '#78CFE3',
  '700': '#30B6D4',
  '800': '#1E7B90',
  '900': '#0F3D48',
  '950': '#082126',
}

export const daybreak = {
  '50': '#FFFBFA',
  '100': '#FFFBFA',
  '200': '#FFF8F5',
  '300': '#FFF4F0',
  '400': '#FFF0EB',
  '500': '#FFEBE4',
  '600': '#FFA585',
  '700': '#FF5E24',
  '800': '#C23400',
  '900': '#611A00',
  '950': '#2E0C00',
}

export const grey = {
  '50': '#F2F1F3',
  '100': '#E4E4E7',
  '200': '#CCCBD2',
  '300': '#B1B0BA',
  '400': '#9998A4',
  '500': '#7E7D8D',
  '600': '#656472',
  '700': '#4B4A54',
  '800': '#323239',
  '900': '#18181B',
  '950': '#0C0C0E',
}

export const white = '#FFF'
export const black = '#000'
