// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  AssessmentRequestAcknowledgeMutationResponse,
  AssessmentRequestAcknowledgeMutationVariables,
} from './__generated__/AssessmentRequestAcknowledgeMutation.graphql'

const mutation = graphql`
  mutation AssessmentRequestAcknowledgeMutation(
    $input: AssessmentRequestAcknowledgeInput!
  ) {
    assessmentRequestAcknowledge(input: $input) {
      assessment_request {
        id
      }
      user {
        linked_individual {
          ...DashboardPage_user_assessment_request
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: AssessmentRequestAcknowledgeMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: AssessmentRequestAcknowledgeMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
