// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const FilePdfIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 23 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g stroke="none" strokeWidth="1" fillRule="evenodd">
          <g transform="translate(-8.000000, -28.000000)">
            <g transform="translate(8.000000, 28.000000)">
              <g id="icon-FORMAT">
                <polygon
                  fill="#D32F2F"
                  points="0 0 16.6107992 0 23 6.52272727 23 28 0 28"
                />
                <polygon
                  fill="#EF9A9A"
                  points="16.6200008 0 16.6200008 6.53259277 23.0088348 6.53259277"
                />
                <text
                  fontFamily=".AppleSystemUIFont"
                  fontSize="8"
                  fontWeight="normal"
                  fill="#FFFFFF"
                >
                  <tspan x="3.4609375" y="22">
                    PDF
                  </tspan>
                </text>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default FilePdfIcon
