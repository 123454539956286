// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserMfaAuthenticateMutationResponse,
  UserMfaAuthenticateMutationVariables,
} from './__generated__/UserMfaAuthenticateMutation.graphql'

const mutation = graphql`
  mutation UserMfaAuthenticateMutation($input: UserMfaAuthenticateInput!) {
    userMfaAuthenticate(input: $input) {
      currentUser {
        mfa_code_required
        mfa_last_verified_at
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: UserMfaAuthenticateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: UserMfaAuthenticateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
