// @flow

import React from 'react'
import { connect } from 'react-fela'

import type { FelaPropsType } from 'react-ui/typing'

export type GaugeChartType = FelaPropsType & {
  activeColor: string,
  activeIndex: number,
  labels: Array<string>,
}

// angles to make an arc (in degrees)
const START_ANGLE = -16
const END_ANGLE = 196
const TOTAL_ARC_ANGLE = END_ANGLE - START_ANGLE
const RADIUS = 52 // circle radius
const MAX_DASH_SIZE = Math.PI * 2 * RADIUS // === circumference
const BASE_DASH_SIZE = MAX_DASH_SIZE / (360 / TOTAL_ARC_ANGLE)

const boundedValue = ({ lowerBound = 0, upperBound, value }) => {
  if (value < lowerBound) {
    return lowerBound
  }
  if (value > upperBound) {
    return upperBound
  }
  return value
}

const styleRules = ({ activeColor, activeIndex, labels }) => {
  const boundedIndex = boundedValue({
    upperBound: labels.length - 1,
    value: activeIndex,
  })

  const coloredDashSize = BASE_DASH_SIZE / (labels.length - 1) * boundedIndex

  return {
    GaugeChart: {
      className: 'GaugeChart',
      width: '100%',
      height: '15rem',
    },

    axisLabelsContainer: {
      className: 'GaugeChart__axisLabelsContainer',
    },

    axisLabel: {
      className: 'GaugeChart__axisLabel',
      display: 'flex',
      fontSize: '0.5em',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      height: '20px',
      lineHeight: '20px',
    },

    circlesContainer: {
      className: 'GaugeChart__circlesContainer',
      strokeWidth: '8',
      strokeLinecap: 'round',
    },

    baseCircle: {
      className: 'GaugeChart__baseCircle',
      stroke: '#e7e9ee',
      strokeDasharray: `${BASE_DASH_SIZE} ${MAX_DASH_SIZE}`,
    },

    colorCircle: {
      className: 'GaugeChart__colorCircle',
      stroke: activeColor,
      strokeDasharray: `${coloredDashSize} ${MAX_DASH_SIZE}`,
    },
  }
}

const GaugeChart = ({
  labels = [],
  activeIndex = 0,
  styles = {},
}: GaugeChartType) => {
  const baseCircePath = `M-50,40 A${RADIUS},${RADIUS} 0 1,1 50,40`

  // adjust text position (using svg coordinates)
  const positionOffset = {
    x: 12,
    y: 0,
  }

  // base max width of the container for text labels
  const containerWidthBase = 45

  const degreesToRadians = degrees => degrees * Math.PI / 180

  const calcCoordinates = degrees => ({
    x: Math.cos(degreesToRadians(degrees)) * RADIUS,
    y: Math.sin(degreesToRadians(degrees)) * RADIUS,
  })

  const getCoordinates = angle => {
    const coordinates = calcCoordinates(angle)

    // adjust containerWidth based on label position
    const containerWidth =
      containerWidthBase + (RADIUS - Math.abs(coordinates.x) - 2)

    let x = positionOffset.x * Math.sign(coordinates.x) + coordinates.x
    let y = positionOffset.y - coordinates.y

    if (angle === 90) {
      x = -containerWidth / 2
      y -= 5
    }
    if (angle > 90) {
      x -= containerWidth
    }
    if (angle < 0 || angle > 180) {
      y += 15
    }

    return { x, y, containerWidth }
  }

  // split circle into segments based on the number of labels
  const getLabelAngle = labelIndex => {
    const segmentAngle = TOTAL_ARC_ANGLE / (labels.length - 1)
    return START_ANGLE + segmentAngle * labelIndex
  }

  // calculate pointer angle based on the label index
  // uses pointer coordinates where 0 degrees points North
  const pointerAngle = getLabelAngle(
    boundedValue({
      upperBound: labels.length - 1,
      value: activeIndex,
    }),
  )

  return (
    <svg className={styles.GaugeChart} viewBox="0 0 1 110">
      <g transform="translate(0, 50) scale(.9)">
        {/* Axis Labels */}
        <g id="labels" className={styles.axisLabelsContainer}>
          {[...labels].reverse().map((title, i) => {
            const angle = getLabelAngle(i)
            const coordinates = getCoordinates(angle)

            // align text depending on the side of the circle
            let textAlign = 'left'

            if (angle > 90) {
              textAlign = 'right'
            }
            if (angle === 90) {
              textAlign = 'center'
            }

            return (
              <foreignObject
                key={title}
                x={coordinates.x}
                y={coordinates.y}
                width={coordinates.containerWidth}
                height="25"
              >
                <div className={styles.axisLabel} style={{ textAlign }}>
                  {title}
                </div>
              </foreignObject>
            )
          })}
        </g>

        {/* Circles */}
        <g id="circles" fill="none" className={styles.circlesContainer}>
          {/* Base circle */}
          <path d={baseCircePath} className={styles.baseCircle} />
          {/* Color circle */}
          <path d={baseCircePath} className={styles.colorCircle} />
        </g>

        {/* Pointer */}
        <g transform={`translate(0 -15) rotate(${pointerAngle - 90} 0 40)`}>
          <circle id="BigCircle" cx="0" cy="40" r="8" fill="#efefef" />
          <path
            d="M0,0h0a2.36,2.36,0,0,1,2.36,2.24l1.9,37.37a4.26,4.26,0,0,1-4,4.48h-.22a4.26,4.26,0,0,1-4.26-4.26v-.22l1.9-37.37A2.36,2.36,0,0,1,0,0Z"
            fill="#878787"
          />
          <circle id="SmallCircle" cx="0" cy="40" r="2" fill="#fff" />
        </g>
      </g>
    </svg>
  )
}

export default connect(styleRules)(GaugeChart)
