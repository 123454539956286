// @flow

import { graphql } from 'react-relay'

export const clinicianQuery = graphql`
  query UserInviteFormTenantCliniciansQuery($id: ID!) {
    tenant(id: $id) {
      assignable_clinicians {
        nodes {
          ... on ClinicianRole {
            id
            name
          }
        }
      }
    }
  }
`
