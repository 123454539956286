// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserQuestionnairesQuestionSetSkipMutationResponse,
  UserQuestionnairesQuestionSetSkipMutationVariables,
} from './__generated__/UserQuestionnairesQuestionSetSkipMutation.graphql'

// NOTE: we want the relay to update the notifications
const mutation = graphql`
  mutation UserQuestionnairesQuestionSetSkipMutation(
    $input: UserQuestionnairesQuestionSetSkipInput!
  ) {
    userQuestionnairesQuestionSetSkip(input: $input) {
      viewer {
        id
        currentUser {
          ...NotificationDisplay_user
        }
      }
      next_user_questionnaires_question_set {
        ...UserQuestionnairesQuestionSet_user_questionnaires_question_set
          @relay(mask: false)
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (
    result: UserQuestionnairesQuestionSetSkipMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: UserQuestionnairesQuestionSetSkipMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
    updater: store => {
      const payload = store.getRootField('userQuestionnairesQuestionSetSkip')
      const uQQS =
        payload &&
        payload.getLinkedRecord('next_user_questionnaires_question_set')
      if (!uQQS) return
      const { answeree_id, assessment_request_id } = variables.input
      const next_questionnaires_question_set = uQQS.getLinkedRecord(
        'questionnaires_question_set',
      )
      const next_questionnaires_question_set_id =
        next_questionnaires_question_set &&
        next_questionnaires_question_set.getValue('id')
      const viewer = store.get('viewer')
      // add next question set to the cache
      const currentUser = viewer && viewer.getLinkedRecord('currentUser')
      const currentRole =
        currentUser && currentUser.getLinkedRecord('current_role')
      if (currentRole)
        currentRole.setLinkedRecord(uQQS, 'user_questionnaires_question_set', {
          questionnaires_question_set_id: next_questionnaires_question_set_id,
          answeree_id,
          assessment_request_id,
        })
    },
  })
}
