/*
 * @flow
 */

import dispatch from './eventDispatcher'

export const trackFitbitConnectModalViewed = () =>
  dispatch({
    category: 'Fitbit',
    label: 'View Connect Modal',
    action: 'View',
  })

export const trackFitbitNotInterested = () =>
  dispatch({
    category: 'Fitbit',
    label: 'Not Interested',
    action: 'Click',
  })

export const trackFitbitConnected = () =>
  dispatch({
    category: 'Fitbit',
    label: 'Connect',
    action: 'Click',
  })

export const trackFitbitDisconnectModalViewed = () =>
  dispatch({
    category: 'Fitbit',
    label: 'View Disconnect Modal',
    action: 'View',
  })

export const trackFitbitDisconnected = () =>
  dispatch({
    category: 'Fitbit',
    label: 'Disconnect',
    action: 'Click',
  })

export const trackFitbitViewSleepGraph = () =>
  dispatch({
    category: 'Fitbit',
    label: 'View Sleep Graph',
    action: 'View',
  })

export const trackFitbitViewActivityGraph = () =>
  dispatch({
    category: 'Fitbit',
    label: 'View Activity Graph',
    action: 'View',
  })

export const trackFitbitViewMoodGraph = () =>
  dispatch({
    category: 'Fitbit',
    label: 'View Mood Graph',
    action: 'View',
  })

export const clinicianInteractionStart = () =>
  dispatch({
    category: 'Clinician Interaction',
    label: 'Clinician Interaction Start',
    action: 'View',
  })

export const clinicianInteractionEnd = () =>
  dispatch({
    category: 'Clinician Interaction',
    label: 'Clinician Interaction End',
    action: 'Leave',
  })

export const trackHelpNowButtonInteraction = ({
  duration,
  totalDuration,
  action,
}: {
  action: 'Hover' | 'Click',
  duration: number,
  totalDuration: number,
}) =>
  dispatch({
    category: 'Help Now',
    label: 'Button Interaction',
    action,
    duration,
    totalDuration,
  })

export const resumeInitialQuestionnaire = () =>
  dispatch({
    category: 'WelcomePage',
    label: 'Resume initial Questionnaire',
    action: 'Click',
  })
export const updateQuestionnaire = () =>
  dispatch({
    category: 'WelcomePage',
    label: 'Update Questionnaire',
    action: 'Click',
  })
export const welcomeToDashboard = () =>
  dispatch({
    category: 'WelcomePage',
    label: 'Go to dashboard',
    action: 'Click',
  })

export const notCompletedInitialQsPopUp = () => {
  dispatch({
    category: 'Questionnaire',
    label: 'Initial questionnaire pop up',
    action: 'View',
  })
}

export const notCompletedSummaryPopUp = () => {
  dispatch({
    category: 'Questionnaire',
    label: 'Summary questionnaire pop up',
    action: 'View',
  })
}

export const resumeInitialQsPopUpModalTracker = () => {
  dispatch({
    category: 'Questionnaire',
    label: 'Initial questionnaire pop up',
    action: 'Resume',
  })
}

export const resumeSummaryQsPopUpModalTracker = () => {
  dispatch({
    category: 'Questionnaire',
    label: 'Summary questionnaire pop up',
    action: 'Resume',
  })
}

export const initialQuestionnaireSubmit = () => {
  dispatch({
    category: 'Initial Questionnaire',
    label: 'Initial Questionnaire submit on Thank you page',
    action: 'Click',
  })
}
