/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$ref: FragmentReference;
declare export opaque type UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$fragmentType: UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$ref;
export type UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet = {|
  +all_questions_answered: boolean,
  +question_set: {|
    +tip: ?string
  |},
  +next_user_questionnaires_question_set: ?{|
    +questionnaires_question_set: {|
      +id: string,
      +label: string,
    |}
  |},
  +progress: {|
    +decimal: number,
    +done: number,
    +step: number,
    +percent: ?number,
    +total: number,
  |},
  +$refType: UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$ref,
|};
export type UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$data = UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet;
export type UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$key = {
  +$data?: UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$data,
  +$fragmentRefs: UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnaireQuestionSetFooter_userQuestionnaireQuestionSet",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "all_questions_answered",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "tip",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "next_user_questionnaires_question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionnairesQuestionSet",
          "kind": "LinkedField",
          "name": "questionnaires_question_set",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "label",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Progress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "decimal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "done",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "step",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "percent",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionnairesQuestionSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '4104f64e9e21c511be2bf0a891be9c29';

module.exports = node;
