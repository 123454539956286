// @flow

import { type ThemeType } from 'care-ui/atoms'

export const asideStyles = () => ({
  boxShadow: 'none',
})

export const articleContentStyles = ({ theme }: { theme: ThemeType }) => ({
  paddingRight: theme.Grid.gutter,
})

export const asideContentStyles = ({ theme }: { theme: ThemeType }) => ({
  paddingLeft: theme.Grid.gutter,
  paddingTop: theme.spacing(1),
})

export const mainContentRegionStyles = () => ({
  marginLeft: 'auto',
})
