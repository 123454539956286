// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ theme }) => ({
  alignItems: 'stretch',
  boxSizing: 'border-box',
  className: 'Row',
  display: 'flex',
  flexDirection: 'row',
  flexFlow: 'wrap',
  justifyContent: 'flex-start',
  marginLeft: `-${theme.Grid.gutter}`,
  marginRight: `-${theme.Grid.gutter}`,
})

export default createComponentWithProxy(styleRules)
