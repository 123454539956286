/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserTrackableView_user_trackable$ref: FragmentReference;
declare export opaque type UserTrackableView_user_trackable$fragmentType: UserTrackableView_user_trackable$ref;
export type UserTrackableView_user_trackable = {|
  +id: string,
  +trackable: {|
    +id: string
  |},
  +user: {|
    +id: string
  |},
  +$refType: UserTrackableView_user_trackable$ref,
|};
export type UserTrackableView_user_trackable$data = UserTrackableView_user_trackable;
export type UserTrackableView_user_trackable$key = {
  +$data?: UserTrackableView_user_trackable$data,
  +$fragmentRefs: UserTrackableView_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserTrackableView_user_trackable",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'e7d59b4df0341ff89fa26478aeb3cd6a';

module.exports = node;
