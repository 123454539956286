// @flow

import { graphql } from 'react-relay'

export const answerTableAnswers = {
  answers: graphql`
    fragment AnswerTable_answers on AnswerInterface @relay(plural: true) {
      id
      display_value
      question {
        label
      }
    }
  `,
}
