// @flow

import { graphql } from 'react-relay'

export const fragment = graphql`
  fragment ActivityLogCard_activityLog on ActivityLog {
    display_text
    occured_at
    ...ActivityLogChangeSet_activityLog
  }
`
