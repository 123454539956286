/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type HelpNowVisitLinkInput = {|
  clientMutationId?: ?string
|};
export type useHelpNowVisitLinkMutationVariables = {|
  input: HelpNowVisitLinkInput
|};
export type useHelpNowVisitLinkMutationResponse = {|
  +helpNowVisitLink: ?{|
    +clientMutationId: ?string
  |}
|};
export type useHelpNowVisitLinkMutation = {|
  variables: useHelpNowVisitLinkMutationVariables,
  response: useHelpNowVisitLinkMutationResponse,
|};
*/


/*
mutation useHelpNowVisitLinkMutation(
  $input: HelpNowVisitLinkInput!
) {
  helpNowVisitLink(input: $input) {
    clientMutationId
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "HelpNowVisitLinkPayload",
    "kind": "LinkedField",
    "name": "helpNowVisitLink",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "clientMutationId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useHelpNowVisitLinkMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useHelpNowVisitLinkMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "9066e17d9de694cb05506484e8494628",
    "id": null,
    "metadata": {},
    "name": "useHelpNowVisitLinkMutation",
    "operationKind": "mutation",
    "text": "mutation useHelpNowVisitLinkMutation(\n  $input: HelpNowVisitLinkInput!\n) {\n  helpNowVisitLink(input: $input) {\n    clientMutationId\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '0ba3391be266b541df126ae9b53ca107';

module.exports = node;
