// @flow

import { useEffect, useState } from 'react'
import { isEmpty } from 'lodash/fp'

import usePolicies from './usePolicies'

const useAuthPolicy = (policies: Array<string>) => {
  const [isLoading, setIsLoading] = useState(true)

  const authPolicies = usePolicies()

  useEffect(
    () => {
      if (!isEmpty(authPolicies)) setIsLoading(false)
    },
    [authPolicies],
  )

  const isAuthorized = policies.some(policy => authPolicies[policy])

  return { isAuthorized, isLoading }
}

export default useAuthPolicy
