// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  SwitchRoleMutationResponse,
  SwitchRoleMutationVariables,
} from './__generated__/SwitchRoleMutation.graphql'

const mutation = graphql`
  mutation SwitchRoleMutation($input: UserUpdateInput!, $role_id: ID) {
    userUpdate(input: $input) {
      user {
        homepage_path(role_id: $role_id)
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: SwitchRoleMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: SwitchRoleMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
