// @flow

import BaseInput from './BaseInput'
import CheckboxesWidget from './CheckboxesWidget'
import PairInteger from './PairInteger'
import RadioWidget from './RadioWidget'

export type PropsType = {
  autofocus: boolean,
  componentId?: string,
  disabled?: boolean,
  formContext: any,
  id?: string,
  onBlur: Function,
  onChange: Function,
  onClick: Function,
  onFocus: Function,
  onTouched: Function,
  options: {
    emptyValue?: any,
    enumOptions: $ReadOnlyArray<{
      label: string,
      value: string,
    }>,
    inline?: boolean,
    inputType?: string,
  },
  rawErrors?: string,
  readonly: boolean,
  required: boolean,
  schema: {
    feet_max_value: number,
    feet_min_value: number,
    inches_max_value: number,
    inches_min_value: number,
    items: {
      enum: $ReadOnlyArray<{ value: string }>,
      enumNames: $ReadOnlyArray<{ value: string }>,
      type: string,
    },
    title: string,
    type: string,
    uniqueItems: boolean,
  },
  styles: any,
  touched: boolean,
  type?: string,
  value: any,
}

export const widgets = {
  BaseInput,
  CheckboxesWidget,
  RadioWidget,
  PairInteger,
}
