/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type DataGridRowFooterContentLoader_user$ref = any;
type IndividualUserAdditionalInformationCardLoader_individual$ref = any;
type UserActivityCardLoader_user$ref = any;
export type EscalationResolutionOptions = "care_option_assigned" | "other" | "transferred_to_another_service" | "unable_to_contact" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type StaffEscalationsTableDataGrid_escalations$ref: FragmentReference;
declare export opaque type StaffEscalationsTableDataGrid_escalations$fragmentType: StaffEscalationsTableDataGrid_escalations$ref;
export type StaffEscalationsTableDataGrid_escalations = $ReadOnlyArray<{|
  +created_at: any,
  +user: {|
    +legal_name: ?string,
    +linked_individual: ?{|
      +$fragmentRefs: IndividualUserAdditionalInformationCardLoader_individual$ref
    |},
    +$fragmentRefs: UserActivityCardLoader_user$ref & DataGridRowFooterContentLoader_user$ref,
  |},
  +label: string,
  +resolving_role: ?{|
    +id?: string,
    +user?: {|
      +name: string
    |},
  |},
  +resolving_resource: ?{|
    +label: string
  |},
  +resolved_at: ?any,
  +role: {|
    +clinicians: $ReadOnlyArray<{|
      +id: string,
      +user: {|
        +id: string,
        +legal_name: ?string,
      |},
    |}>
  |},
  +resolution_option: ?EscalationResolutionOptions,
  +resolution_text: ?string,
  +$refType: StaffEscalationsTableDataGrid_escalations$ref,
|}>;
export type StaffEscalationsTableDataGrid_escalations$data = StaffEscalationsTableDataGrid_escalations;
export type StaffEscalationsTableDataGrid_escalations$key = $ReadOnlyArray<{
  +$data?: StaffEscalationsTableDataGrid_escalations$data,
  +$fragmentRefs: StaffEscalationsTableDataGrid_escalations$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "legal_name",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "StaffEscalationsTableDataGrid_escalations",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "created_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "IndividualRole",
          "kind": "LinkedField",
          "name": "linked_individual",
          "plural": false,
          "selections": [
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "IndividualUserAdditionalInformationCardLoader_individual"
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "UserActivityCardLoader_user"
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "DataGridRowFooterContentLoader_user"
        }
      ],
      "storageKey": null
    },
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "resolving_role",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "RoleInterface",
          "abstractKey": "__isRoleInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resolving_resource",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolved_at",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "role",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "ClinicianRole",
          "kind": "LinkedField",
          "name": "clinicians",
          "plural": true,
          "selections": [
            (v2/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "User",
              "kind": "LinkedField",
              "name": "user",
              "plural": false,
              "selections": [
                (v2/*: any*/),
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolution_option",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "resolution_text",
      "storageKey": null
    }
  ],
  "type": "Escalation",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'addb0cc1d6f058cff73ffb8e90e94492';

module.exports = node;
