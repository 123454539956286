/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireComplete_user$ref: FragmentReference;
declare export opaque type QuestionnaireComplete_user$fragmentType: QuestionnaireComplete_user$ref;
export type QuestionnaireComplete_user = {|
  +id: string,
  +name: string,
  +legal_name: ?string,
  +preferred_name: ?string,
  +eligible_for_dashboard_setup: boolean,
  +contract: ?string,
  +linked_individual: ?{|
    +initial_questionnaire_status: string
  |},
  +$refType: QuestionnaireComplete_user$ref,
|};
export type QuestionnaireComplete_user$data = QuestionnaireComplete_user;
export type QuestionnaireComplete_user$key = {
  +$data?: QuestionnaireComplete_user$data,
  +$fragmentRefs: QuestionnaireComplete_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireComplete_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legal_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferred_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "eligible_for_dashboard_setup",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "contract",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualRole",
      "kind": "LinkedField",
      "name": "linked_individual",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "initial_questionnaire_status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'e430cb2eb3ce4588dbb134107d9dfae5';

module.exports = node;
