/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type QuestionKinds = "DATE" | "IMPERIAL_LENGTH" | "INTEGER" | "MULTICHOICE" | "MULTISELECT" | "NUMBER" | "STRING" | "SUBURB_AUTOCOMPLETE" | "TEXT" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnairesQuestionSet_Question_question$ref: FragmentReference;
declare export opaque type UserQuestionnairesQuestionSet_Question_question$fragmentType: UserQuestionnairesQuestionSet_Question_question$ref;
export type UserQuestionnairesQuestionSet_Question_question = {|
  +id: string,
  +conditionals: $ReadOnlyArray<{|
    +question_option: {|
      +id: string,
      +question: {|
        +id: string
      |},
    |}
  |}>,
  +condition_scenario: ?{|
    +equation: string
  |},
  +jsonSchema: ?{|
    +enum?: ?$ReadOnlyArray<string>,
    +enumNames?: ?$ReadOnlyArray<string>,
    +enumValues?: ?$ReadOnlyArray<number>,
    +maximum?: ?number,
    +minimum?: ?number,
    +feet_max_value?: ?number,
    +feet_min_value?: ?number,
    +inches_max_value?: ?number,
    +inches_min_value?: ?number,
    +minLength?: ?string,
    +maxLength?: ?string,
  |},
  +kind: QuestionKinds,
  +label: ?string,
  +image: ?{|
    +url: string,
    +alt_txt: string,
  |},
  +required: boolean,
  +uiSchema: ?{|
    +uiWidget: ?string
  |},
  +$refType: UserQuestionnairesQuestionSet_Question_question$ref,
|};
export type UserQuestionnairesQuestionSet_Question_question$data = UserQuestionnairesQuestionSet_Question_question;
export type UserQuestionnairesQuestionSet_Question_question$key = {
  +$data?: UserQuestionnairesQuestionSet_Question_question$data,
  +$fragmentRefs: UserQuestionnairesQuestionSet_Question_question$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enum",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enumNames",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "enumValues",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnairesQuestionSet_Question_question",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Conditional",
      "kind": "LinkedField",
      "name": "conditionals",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionOption",
          "kind": "LinkedField",
          "name": "question_option",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "Question",
              "kind": "LinkedField",
              "name": "question",
              "plural": false,
              "selections": [
                (v0/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ConditionScenario",
      "kind": "LinkedField",
      "name": "condition_scenario",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "equation",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "jsonSchema",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "JsonSchemaMultichoice",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": (v1/*: any*/),
          "type": "JsonSchemaMultiselect",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maximum",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minimum",
              "storageKey": null
            }
          ],
          "type": "JsonSchemaInteger",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feet_max_value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "feet_min_value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "inches_max_value",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "inches_min_value",
              "storageKey": null
            }
          ],
          "type": "JsonSchemaImperialLength",
          "abstractKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "minLength",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "maxLength",
              "storageKey": null
            }
          ],
          "type": "JsonSchemaString",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "kind",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Image",
      "kind": "LinkedField",
      "name": "image",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alt_txt",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "required",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UiSchema",
      "kind": "LinkedField",
      "name": "uiSchema",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "uiWidget",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Question",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '55b6837a92d130eacf58852f22e15652';

module.exports = node;
