// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip__highcharts-tooltip___RkYLs>span{background:rgba(0,0,0,0);border:0px;padding:5px}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"highcharts-tooltip": `tooltip__highcharts-tooltip___RkYLs`
};
export default ___CSS_LOADER_EXPORT___;
