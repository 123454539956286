/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type UserResourceEvents = "cancel_support_request" | "request_support" | "start_supporting" | "start_using_resource" | "stop_supporting" | "stop_using_resource" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserResourceStatusUpdate_user_resource$ref: FragmentReference;
declare export opaque type UserResourceStatusUpdate_user_resource$fragmentType: UserResourceStatusUpdate_user_resource$ref;
export type UserResourceStatusUpdate_user_resource = {|
  +id: string,
  +resource: {|
    +id: string
  |},
  +user: {|
    +id: string
  |},
  +allowed_events: $ReadOnlyArray<{|
    +event_name: UserResourceEvents,
    +disabled: ?boolean,
  |}>,
  +$refType: UserResourceStatusUpdate_user_resource$ref,
|};
export type UserResourceStatusUpdate_user_resource$data = UserResourceStatusUpdate_user_resource;
export type UserResourceStatusUpdate_user_resource$key = {
  +$data?: UserResourceStatusUpdate_user_resource$data,
  +$fragmentRefs: UserResourceStatusUpdate_user_resource$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserResourceStatusUpdate_user_resource",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserResourceAllowedEvent",
      "kind": "LinkedField",
      "name": "allowed_events",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "event_name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "disabled",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserResource",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '55d2f750f26396798e6e15400a3d3035';

module.exports = node;
