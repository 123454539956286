// @flow

import React, { Fragment } from 'react'

import Link from 'react-ui/components/Link'

const InnowellFeaturesQuestions = [
  {
    key: 'whatisInitialQuestionnaire',
    question: <Fragment> What is the Initial Questionnaire? </Fragment>,
    answer: (
      <Fragment>
        The initial questionnaire is a comprehensive health assessment completed
        as part of an intake (onboarding) process. Each set of questions is a
        specific collection of validated psychometric tools that are used to
        provide real time, standardised data that then sits on a person’s
        personal Dashboard. This initial data set enables individuals and health
        professionals to make decisions about needs and priorities as they work
        together in ongoing care.
      </Fragment>
    ),
  },
  {
    key: 'needToCompleteQuestionnaire',
    question: (
      <Fragment>
        Does the initial questionnaire need to be completed all at once?
      </Fragment>
    ),
    answer: (
      <Fragment>
        Each set of questions can be skipped with the option to complete them
        later with a health professional or a support person. A person’s answers
        are sorted into health cards that can be viewed from their Dashboard.
        Any question set that is skipped with the option to ‘do later with a
        clinician’ raises an orange flag visible in the service’s client list.
      </Fragment>
    ),
  },
  {
    key: 'whatisDashboard',
    question: <Fragment> What is the Dashboard? </Fragment>,
    answer: (
      <Fragment>
        The Dashboard holds a person’s health and wellbeing story. It includes
        their Profile, Health History and Health Card data. It can be updated
        anytime to reflect how a person is currently feeling and functioning,
        helping to track needs, coordinate care and identify health and
        wellbeing goals.
      </Fragment>
    ),
  },
  {
    key: 'howDashboardInformCare',
    question: <Fragment> How does the dashboard inform care? </Fragment>,
    answer: (
      <Fragment>
        The health service will engage with a person’s dashboard as part of a
        holistic approach to care. The combination of psychometric data and
        health information informs and guides ongoing care and treatment
        options, providing opportunities to assess and monitor a person’s
        progress.
      </Fragment>
    ),
  },
  {
    key: 'whatisSummaryDashboard',
    question: <Fragment> What is the Summary Dashboard? </Fragment>,
    answer: (
      <Fragment>
        The Summary Dashboard consists of 2 - 5 specific gauges reflecting a
        health domain. All gauges are updated together to provide an overall
        impression of a person’s health and wellbeing.
      </Fragment>
    ),
  },
  {
    key: 'whoContibutesToSmmaryDashboard',
    question: (
      <Fragment> Who can contribute to a person’s Summary Dashboard? </Fragment>
    ),
    answer: (
      <Fragment>
        A clinician can contribute feedback to the Summary Dashboard. When a
        support person is invited, they can also contribute. They answer a
        series of questions linked to the individual’s Summary Dashboard adding
        to the overall data related to a person’s health and wellbeing.
      </Fragment>
    ),
  },
  {
    key: 'whoisSupportPerson',
    question: <Fragment> Who is a Support Person? </Fragment>,
    answer: (
      <Fragment>
        A support person is a person nominated by an individual to give
        additional feedback on their everyday functioning and wellbeing. A
        support person will be able to answer a series of questions on areas of
        health linked to the Summary Dashboard. They can be invited and removed
        at anytime by the individual, as needed.
        <br />
        They cannot see the full dashboard unless an individual chooses to show
        it to them from their own device.
      </Fragment>
    ),
  },
  {
    key: 'whatAreHealthCards',
    question: <Fragment> What are Health Cards? </Fragment>,
    answer: (
      <Fragment>
        The Health Cards reflect a range of health areas that make up a
        multidimensional view of a person’s health and wellbeing. When you
        complete the initial questionnaire, the response data is sorted into the
        relevant card. On the back of a Health Card it will show details of the
        health area that include:
        <ul>
          <li>information</li>
          <li>results</li>
          <li>factsheets</li>
          <li>care options</li>
          <li>previous responses (psychometric data sets)</li>
        </ul>
      </Fragment>
    ),
  },
  {
    key: 'healthCardsColours',
    question: <Fragment> Why are the Health Cards coloured? </Fragment>,
    answer: (
      <Fragment>
        Each health card is displayed as red, amber, yellow or green providing
        you with real time feedback. Drawing from the individual’s responses,
        the colour of the health card tells you whether this area of health is
        <ul>
          <li>Red: a high concern</li>
          <li>Amber: a medium concern</li>
          <li>Yellow: a mild concern</li>
          <li>Green: a low concern or no concern</li>
        </ul>
      </Fragment>
    ),
  },
  {
    key: 'whoDecidesHealthCardsRange',
    question: <Fragment> Who decides on the range of health cards? </Fragment>,
    answer: (
      <Fragment>
        The range of Health Cards are decided by each health service. If you
        think other cards would enable you to tell your story better, please
        provide this feedback to Innowell at
        <Link to="mailto: support@innowell.org"> support@innowell.org </Link>
      </Fragment>
    ),
  },
  {
    key: 'howToAcceptMultipleRoles',
    question: (
      <Fragment> How to access multiple roles (Staff and Individual) </Fragment>
    ),
    answer: (
      <Fragment>
        Innowell limits users from having an indivdual role and a staff role
        using the same email address. However, there is an option to gain access
        to these roles using a “+” in your email address that helps create
        another email address to avoid the conflict. For example if your email
        is firstname.lastname@gmail.com then by adding the “+” such as
        firstname.lastname+text@gmail.com, will allow the user to gain access to
        the other roles.
      </Fragment>
    ),
  },
  {
    key: 'whatIsTrajectoryGraph',
    question: <Fragment>What is the trajectory graph?</Fragment>,
    answer: (
      <Fragment>
        This predictive tool uses a person’s demographic and clinical
        information to learn about their specific pattern of outcomes over time.
        The tool helps clinicians identify what trajectory someone is on based
        on current results, which can be used to guide decision-making about
        their care and how frequently they may need to be monitored. The more
        uncertain an individual appears (i.e. a wider predicted path), the more
        frequent they should be measured.
      </Fragment>
    ),
  },
]

export default InnowellFeaturesQuestions
