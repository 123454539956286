/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type TenantAdminPage_tenant$ref = any;
export type TenantAdminPage_QueryVariables = {|
  id: string
|};
export type TenantAdminPage_QueryResponse = {|
  +tenant: {|
    +$fragmentRefs: TenantAdminPage_tenant$ref
  |}
|};
export type TenantAdminPage_Query = {|
  variables: TenantAdminPage_QueryVariables,
  response: TenantAdminPage_QueryResponse,
|};
*/


/*
query TenantAdminPage_Query(
  $id: ID!
) {
  tenant(id: $id) {
    ...TenantAdminPage_tenant
    id
  }
}

fragment TenantAdminPage_tenant on Tenant {
  id
  contact_email
  contact_phone
  escalation_emails
  bcc_email
  name
  notification_after
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "TenantAdminPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "TenantAdminPage_tenant"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "TenantAdminPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "Tenant",
        "kind": "LinkedField",
        "name": "tenant",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contact_email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "contact_phone",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "escalation_emails",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "bcc_email",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "name",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "notification_after",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "fe9099ce3ea39b879cef598491486629",
    "id": null,
    "metadata": {},
    "name": "TenantAdminPage_Query",
    "operationKind": "query",
    "text": "query TenantAdminPage_Query(\n  $id: ID!\n) {\n  tenant(id: $id) {\n    ...TenantAdminPage_tenant\n    id\n  }\n}\n\nfragment TenantAdminPage_tenant on Tenant {\n  id\n  contact_email\n  contact_phone\n  escalation_emails\n  bcc_email\n  name\n  notification_after\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '3492afe149a847232de205fcc4d3240a';

module.exports = node;
