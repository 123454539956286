/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type AssessmentRequestsAnswerTableBase_answers$ref: FragmentReference;
declare export opaque type AssessmentRequestsAnswerTableBase_answers$fragmentType: AssessmentRequestsAnswerTableBase_answers$ref;
export type AssessmentRequestsAnswerTableBase_answers = $ReadOnlyArray<{|
  +id: string,
  +display_value: string,
  +question: {|
    +label: ?string
  |},
  +$refType: AssessmentRequestsAnswerTableBase_answers$ref,
|}>;
export type AssessmentRequestsAnswerTableBase_answers$data = AssessmentRequestsAnswerTableBase_answers;
export type AssessmentRequestsAnswerTableBase_answers$key = $ReadOnlyArray<{
  +$data?: AssessmentRequestsAnswerTableBase_answers$data,
  +$fragmentRefs: AssessmentRequestsAnswerTableBase_answers$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "AssessmentRequestsAnswerTableBase_answers",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "display_value",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Question",
      "kind": "LinkedField",
      "name": "question",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "AnswerInterface",
  "abstractKey": "__isAnswerInterface"
};
// prettier-ignore
(node/*: any*/).hash = '6299c349835ea86039693a0b4a93d755';

module.exports = node;
