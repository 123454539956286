// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  DischargeMutationResponse,
  DischargeMutationVariables,
} from './__generated__/DischargeMutation.graphql'

const mutation = graphql`
  mutation DischargeMutation($input: RoleDischargeInput!) {
    roleDischarge(input: $input) {
      role {
        ...IndividualsTable_individual_mutation
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: DischargeMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: DischargeMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
