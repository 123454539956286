// @flow

import React from 'react'
import { createFragmentContainer, graphql } from 'react-relay'

import DataGridRowFooterContent from './DataGridRowFooterContent'

const DataGridRowFooterContentLoader = ({ user, ...props }) => {
  return <DataGridRowFooterContent user={user} {...props} />
}

export default createFragmentContainer(DataGridRowFooterContentLoader, {
  user: graphql`
    fragment DataGridRowFooterContentLoader_user on User {
      id
      email
      legal_name
      preferred_name
      last_seen_at
      linked_individual {
        do_with_clinician {
          question_sets {
            label
          }
        }
        individual_detail {
          emr_user_id
        }
        initial_questionnaire_status
      }
      personal_details {
        date_of_birth
      }
    }
  `,
})
