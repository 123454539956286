// @flow

import { commit as commitRemove } from './RemoveGeneric'
import { commit as commitRemoveIndividuals } from './RemoveIndividual'
import { commit as commitRemoveStaff } from './RemoveStaff'

import { type RoleType } from 'react-ui/typing'

// will return the correct mutation for a specific role type.
// if roleType is unknown, it will return a generic action,
// the generic action is WAAAYYYYY inefficient on the refetch, so please
// only use sparingly
//
// currently don't have a "remove" process for SUPER and SUPORT_PERSON
// (they can be removed, but refetch isn't set up)
// I think this is ok as supers are removed from active admin
// and support persons aren't removed, (they have their support link removed)

const commitRemoveForType = (roleType: RoleType) => {
  switch (roleType) {
    case 'INDIVIDUAL':
      return commitRemoveIndividuals
    case 'ADMIN':
    case 'CLINICIAN':
    case 'LEAD_CLINICIAN':
    case 'MANAGER':
    case 'OWNER':
    case 'RESEARCHER':
      return commitRemoveStaff
    default:
      return commitRemove
  }
}

export default commitRemoveForType
