// @flow

import { graphql } from 'react-relay'

export const healthCardFragment = graphql`
  fragment HealthCardMenu_user_trackable on UserTrackable {
    primary_user_questionnaire {
      questionnaire {
        id
      }
    }
    trackable {
      id
    }
    user {
      id
    }
  }
`
