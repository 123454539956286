/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type EmrUpdateInput = {|
  clientMutationId?: ?string,
  id: string,
  individual_detail: EmrUpdateInputObject,
|};
export type EmrUpdateInputObject = {|
  emr_user_id?: ?string
|};
export type EmrUpdateMutationVariables = {|
  input: EmrUpdateInput
|};
export type EmrUpdateMutationResponse = {|
  +emrUpdate: ?{|
    +individual_detail: {|
      +emr_user_id: ?string
    |}
  |}
|};
export type EmrUpdateMutation = {|
  variables: EmrUpdateMutationVariables,
  response: EmrUpdateMutationResponse,
|};
*/


/*
mutation EmrUpdateMutation(
  $input: EmrUpdateInput!
) {
  emrUpdate(input: $input) {
    individual_detail {
      emr_user_id
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emr_user_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EmrUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmrUpdatePayload",
        "kind": "LinkedField",
        "name": "emrUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndividualDetail",
            "kind": "LinkedField",
            "name": "individual_detail",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EmrUpdateMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "EmrUpdatePayload",
        "kind": "LinkedField",
        "name": "emrUpdate",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "IndividualDetail",
            "kind": "LinkedField",
            "name": "individual_detail",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "cd949281a09cb1aea2e5be0b702029c8",
    "id": null,
    "metadata": {},
    "name": "EmrUpdateMutation",
    "operationKind": "mutation",
    "text": "mutation EmrUpdateMutation(\n  $input: EmrUpdateInput!\n) {\n  emrUpdate(input: $input) {\n    individual_detail {\n      emr_user_id\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1d813abaecc65389e735eeba654655a7';

module.exports = node;
