// @flow

import * as React from 'react'
import { connect } from 'react-fela'
import type { TFunction } from 'react-i18next'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'
import { withFormik } from 'formik'
import * as yup from 'yup'

import { FormError } from 'shared/ui/Forms'
import s from 'shared/ui/Styles/AdminForm.scss'
import { Button, FlexContainer, FlexItem, Text } from 'care-ui'

import { FeedbackTopicSelectLoader } from '../FeedbackTopicSelect'

import { styleRules } from './FeedbackForm.style'
import { feedbackFormViewer } from './query/FeedbackForm'

import type { FelaStylesType } from 'react-ui/typing'
import type { FeedbackForm_viewer } from './query/__generated__/FeedbackForm_viewer.graphql'

type ValuesType = {
  +description: string,
  +subject: string,
  +topic: string,
}

type PropsType = {
  feedback: ValuesType,
  styles: FelaStylesType,
  viewer: FeedbackForm_viewer,
}

type FormPropsType = PropsType & {
  errors: { [key: string]: string },
  handleBlur: () => void,
  handleChange: () => void,
  handleSubmit: () => void,
  isSubmitting: boolean,
  setFieldValue: () => void,
  setValues: ({ [string]: string }) => void,
  touched: { [key: string]: boolean },
  translation: TFunction,
  values: ValuesType,
  viewer: FeedbackForm_viewer,
}

const Form = (props: FormPropsType) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setValues,
    styles,
    touched,
    values,
    viewer,
    translation,
  } = props

  return (
    <form onSubmit={handleSubmit} noValidate className={s.adminForm}>
      <FlexContainer direction="column" gap="xs">
        <div>
          <Text as="label" size="lg" htmlFor="topic">
            {translation('topic')}
          </Text>

          <FeedbackTopicSelectLoader
            id="topic"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.topic}
            viewer={viewer}
            setValues={setValues}
          />

          <FormError
            errors={errors}
            touched={touched}
            values={values}
            attr="topic"
          />
        </div>

        <div>
          <Text as="label" size="lg" htmlFor="subject">
            {translation('subject')}
          </Text>

          <input
            id="subject"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={translation('let_us_know_how_we_can_help')}
            type="text"
            value={values.subject}
            className={styles.input}
          />

          <FormError errors={errors} touched={touched} attr="subject" />
        </div>

        <div>
          <Text as="label" size="lg" htmlFor="description">
            {translation('description')}
          </Text>

          <textarea
            cols="112"
            rows="8"
            id="description"
            onBlur={handleBlur}
            onChange={handleChange}
            placeholder={translation(
              'please_include_as_much_information_as_possible',
            )}
            value={values.description}
            className={styles.input}
          />

          <FormError errors={errors} touched={touched} attr="description" />
        </div>

        <FlexItem alignSelf="end">
          <Button
            variant="primary"
            type="submit"
            disabled={!values?.topic || !values.subject || !values.description}
            ariaLabel={translation('send')}
            dataTestId="send"
          >
            {translation('send')}
          </Button>
        </FlexItem>
      </FlexContainer>
    </form>
  )
}

const FeedbackForm = withFormik({
  mapPropsToValues: (props: PropsType) => ({
    topic: props.feedback.topic,
    subject: props.feedback.subject,
    description: props.feedback.description,
  }),
  handleSubmit: (values: ValuesType, { props, resetForm }) => {
    const { onSubmit } = props

    if (onSubmit) {
      onSubmit(values, () => {
        resetForm()
      })
    }
  },
  validationSchema: props => {
    const { translation } = props

    return yup.object().shape({
      topic: yup
        .string()
        .label('topic')
        .required(translation('topic_is_required')),
      subject: yup
        .string()
        .label('subject')
        .required(translation('subject_is_required')),
      description: yup
        .string()
        .label('description')
        .required(translation('description_is_required')),
    })
  },
})(Form)

const FeedbackFormWithTranslation = (props: PropsType) => {
  const { t: translation } = useTranslation('feedback')

  return <FeedbackForm {...props} translation={translation} />
}

export const FeedbackFormLoader = connect(styleRules)(
  createFragmentContainer(FeedbackFormWithTranslation, feedbackFormViewer),
)
