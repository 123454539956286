// @flow

export const customStyles = () => ({
  body: {
    minWidth: 'auto',
  },
  headerVisible: {
    minWidth: 'auto',
  },
})
