// @flow

import { filter } from 'lodash/fp'

type TabOption = {
  +id: string,
  +label: string,
  +value?: string,
}

export const getValidClinicianFilters = (
  tabOptions: $ReadOnlyArray<TabOption>,
  validValues: Array<string>,
) => {
  const filters = []

  const isValidValue = val => validValues.includes(val)

  tabOptions.forEach(option =>
    filters.push({
      label: option.label,
      value: option.id,
    }),
  )

  const validFilters = filter(option => isValidValue(option.value))(filters)

  return validFilters
}
