// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const containerStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    boxSizing: 'border-box',
    gap: theme.care.spacing.lg,
    padding: `${theme.care.spacing.xxs} ${theme.care.spacing.lg}`,
    [theme.care.breakpoints.queries.md]: {
      padding: theme.care.spacing.sm,
    },
  }
}

export const optionStyle = () => ({
  cursor: 'pointer',
})
