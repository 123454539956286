// @flow

import {
  asUTC,
  longDayWeekNameFormat,
  shortDateFormat,
  shortDayTimeFormat,
} from 'services/dateTime'

import {
  type formulaRangesType,
  colorSelector,
  yAxisLine,
} from './defaultOptions'

type PropsType = {
  secondaryLabel: ?string,
  sortedAdditionalFormulas: ?formulaRangesType,
}

type TitlePropsType = {
  isPrimary: boolean,
  label: string,
}

type TooltipFormatterPropsType = {
  labelInverted: Object,
  point: Object,
  series: Object,
}

type YAxisPropsType = {
  isAdditional?: boolean,
  plotRawScore: boolean,
}

const lineStyle = isPrimary => `
  height:1px;
  width: 20px;
  border-color: ${colorSelector.INDIVIDUAL};
  border-style: ${isPrimary ? 'solid' : 'dashed'};
`

const dataPointStyle = isPrimary => `
  display: inline-block;
  border: 1px solid ${colorSelector.INDIVIDUAL};
  height: 8px;
  width: 8px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: ${isPrimary ? colorSelector.INDIVIDUAL : 'white'};
`

const legendStyle = `
  display: inline-flex;
  position: relative;
  margin-right: 0.5rem;
  align-items: center;
`

const labelContainerStyle = isPrimary => `
  transform: rotate(${isPrimary ? '0' : '180deg'});
  display: flex;
`

const textStyle = `
  font-size: 14px;
`

export const graphTitle = ({ label, isPrimary }: TitlePropsType) => `
  <div style='${labelContainerStyle(isPrimary)}'>
    <div style='${legendStyle}'>
      <div style='${dataPointStyle(isPrimary)}'></div>
      <hr style='${lineStyle(isPrimary)}'/>
    </div>
    <div style='${textStyle}'>${label}</div>
  </div>
`

export const secondaryGraphOptions = ({
  sortedAdditionalFormulas,
  secondaryLabel,
}: PropsType) => {
  const hasAdditionalGraph =
    sortedAdditionalFormulas && sortedAdditionalFormulas?.length > 0

  const secondaryGraph = {
    ...(sortedAdditionalFormulas && yAxisLine(sortedAdditionalFormulas)),
    reversed: true,
    opposite: true,
    labels: {
      x: secondaryLabel ? 15 : 30,
    },
    tickPositioner() {
      const yAxisObject =
        sortedAdditionalFormulas && yAxisLine(sortedAdditionalFormulas)
      const positions = [yAxisObject?.min, yAxisObject?.max]
      return positions
    },
    title: {
      text: secondaryLabel
        ? graphTitle({ label: secondaryLabel, isPrimary: false })
        : '<div></div>',
      offset: 15,
      useHTML: true,
    },
    crosshair: {
      width: 0,
    },
  }

  return { secondaryGraph, hasAdditionalGraph }
}

export const plotLineOptions = (entries?: Array<Object>) => {
  const plotLines =
    entries?.flatMap <
    Object >
    (entry => {
      const dateChangeLog = entry?.created_at
      const dateChangeLogUTC = dateChangeLog && asUTC(dateChangeLog).getTime()

      const plotLineForHover = {
        color: 'transparent',
        width: 20,
        value: dateChangeLogUTC,
        customTooltip: true,
        events: {
          mouseover(e) {
            const series = this.axis.series[0]
            const { chart } = series
            const PointClass = series.pointClass
            const { tooltip } = chart
            const point = new PointClass().init(series, [
              'PlotA',
              this.options.value,
            ])
            const normalizedEvent = chart.pointer.normalize(e)

            const pointXPixelValue = chart.xAxis[0].toPixels(this.options.value)

            point.tooltipPos = [
              pointXPixelValue - chart.plotLeft,
              normalizedEvent.chartY - chart.plotTop,
            ]

            point.color = '#fff'

            point.reason = entry?.reason

            point.customTooltip = this.options.customTooltip

            tooltip.refresh(point)
          },
          mouseout() {
            this.axis.chart.tooltip.hide()
          },
        },
      }

      const plotLineForDisplay = {
        color: '#E6E6E6',
        width: 2,
        value: dateChangeLogUTC,
        dashStyle: 'shortdash',
      }

      return [plotLineForDisplay, plotLineForHover]
    })

  return { plotLines }
}

export const tooltipFormatter = ({
  point,
  series,
  labelInverted,
}: TooltipFormatterPropsType) => {
  const customTooltipStyle = `
    font-size: 14px;
  `
  if (point.customTooltip) {
    return `
      <span style="${customTooltipStyle}">
        <span>${shortDateFormat(new Date(point.y))}</span>
        <br />
        <span>${point.reason}</span>
      </span>
    `
  }

  if (point.isTrajectoryPoint) {
    return `
      <span style="line-height: 20px; font-size: 14px; display:flex; flex-direction: column;">
        <b>Predicted path as of ${shortDayTimeFormat(
          new Date(point.x),
        )} - <a href='/resources/faq?id=whatIsTrajectoryGraph'>more info</a></b>
        <br />
        <em>
          The trajectory tool is learning about an individuals pattern over time,
          and importantly offers guidance on how frequently the individual needs 
          to update their questionnaire, and how regularly they should be monitored.
        </em>
      </span>
    `
  }

  const { isAdditional } = series?.userOptions

  const toolTipText = isAdditional ? point.y : labelInverted[point.formulaValue]

  return `
    <span style="line-height: 20px; font-size: 14px;">
      <b>${longDayWeekNameFormat(new Date(point.x))}</b>
      <br />
      ${toolTipText}
      <br/>
    </span>
  `
}

export const getPlotBands = (series: Object, isAdditional?: boolean) => {
  const { plotRawScore, data: seriesData } = series || {}

  if (plotRawScore || isAdditional) return seriesData

  const plotBands = seriesData.map(({ x, scaled_value, formulaValue }) => ({
    x,
    y: scaled_value,
    formulaValue,
  }))

  return plotBands
}

export const getYAxis = ({ plotRawScore, isAdditional }: YAxisPropsType) => {
  if (isAdditional) return 1

  return plotRawScore ? 2 : 0
}
