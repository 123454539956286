/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type QuestionnaireComplete_userQuestionnaire$ref = any;
type UserQuestionnaire_user_questionnaire$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireShowPage_user_questionnaire$ref: FragmentReference;
declare export opaque type QuestionnaireShowPage_user_questionnaire$fragmentType: QuestionnaireShowPage_user_questionnaire$ref;
export type QuestionnaireShowPage_user_questionnaire = {|
  +questionnaire: ?{|
    +id: string
  |},
  +first_user_questionnaires_question_set: ?{|
    +questionnaires_question_set: {|
      +id: string
    |}
  |},
  +finished: boolean,
  +first_questionnaire_interaction: boolean,
  +ready_to_complete: boolean,
  +progress: {|
    +decimal: number,
    +done: number,
    +total: number,
    +step: number,
  |},
  +thank_you_page_relevant: boolean,
  +$fragmentRefs: UserQuestionnaire_user_questionnaire$ref & QuestionnaireComplete_userQuestionnaire$ref,
  +$refType: QuestionnaireShowPage_user_questionnaire$ref,
|};
export type QuestionnaireShowPage_user_questionnaire$data = QuestionnaireShowPage_user_questionnaire;
export type QuestionnaireShowPage_user_questionnaire$key = {
  +$data?: QuestionnaireShowPage_user_questionnaire$data,
  +$fragmentRefs: QuestionnaireShowPage_user_questionnaire$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "QuestionnaireShowPage_user_questionnaire",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Questionnaire",
      "kind": "LinkedField",
      "name": "questionnaire",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "first_user_questionnaires_question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "QuestionnairesQuestionSet",
          "kind": "LinkedField",
          "name": "questionnaires_question_set",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "finished",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "first_questionnaire_interaction",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "ready_to_complete",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Progress",
      "kind": "LinkedField",
      "name": "progress",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "decimal",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "done",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "step",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "thank_you_page_relevant",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionnaire_user_questionnaire"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionnaireComplete_userQuestionnaire"
    }
  ],
  "type": "UserQuestionnaire",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '9d945ff3eea1637d3ff49343ae9e9d2b';

module.exports = node;
