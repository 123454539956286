// @flow

import { graphql } from 'react-relay'

export const healthCardContainerFragment = graphql`
  fragment HealthCardContainer_user on User {
    id
    extensiveUserTrackables: user_trackables(
      kinds: [text, additional_assessment]
    ) {
      nodes {
        ...HealthCardList_user_trackables
        ...HealthPriorityList_user_trackables
        in_plan
        formula_range {
          ... on FormulaRangeInterface {
            color
          }
        }
        trackable {
          label
        }
      }
    }
    linked_individual {
      individual_detail {
        read_only
      }
    }
  }
`
