// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const MicrophoneIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="microphoneIcon"
        version="1.1"
        viewBox="0 0 21.8 28"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
      >
        <g id="VIDEO-CALLs_3_" transform="translate(0.000000, 896.000000)">
          <g
            id="Video-Controls-inactive_3_"
            transform="translate(486.000000, 14.000000)"
          >
            <g
              id="button-_x2F_-your-mic_1_"
              transform="translate(199.000000, 1.000000)"
            >
              <g id="Group-5_1_" transform="translate(20.000000, 20.000000)">
                <g id="icon-_x2F_-28x28-_x2F_-microphone_1_">
                  <g>
                    <path
                      id="path-1_4_"
                      className="st0"
                      d="M-697.4-925.3v6.4c0,1.8,1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2v-6.4c0-1.8-1.4-3.2-3.2-3.2
                      C-695.9-928.5-697.4-927-697.4-925.3z M-692.9-908.2v3.9c0,0.7-0.6,1.3-1.3,1.3s-1.3-0.6-1.3-1.3v-3.9
                      c-5.3-0.6-9.5-5.2-9.5-10.7c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3c0,4.6,3.7,8.3,8.3,8.3c4.6,0,8.3-3.7,8.3-8.3
                      c0-0.7,0.6-1.3,1.3-1.3s1.3,0.6,1.3,1.3C-683.4-913.4-687.5-908.8-692.9-908.2z M-699.9-925.3c0-3.2,2.6-5.7,5.7-5.7
                      c3.2,0,5.7,2.6,5.7,5.7v6.4c0,3.2-2.6,5.7-5.7,5.7c-3.2,0-5.7-2.6-5.7-5.7V-925.3z"
                    />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default MicrophoneIcon
