/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type ConnectedTenantsList_tenants$ref: FragmentReference;
declare export opaque type ConnectedTenantsList_tenants$fragmentType: ConnectedTenantsList_tenants$ref;
export type ConnectedTenantsList_tenants = $ReadOnlyArray<{|
  +label: string,
  +name: string,
  +$refType: ConnectedTenantsList_tenants$ref,
|}>;
export type ConnectedTenantsList_tenants$data = ConnectedTenantsList_tenants;
export type ConnectedTenantsList_tenants$key = $ReadOnlyArray<{
  +$data?: ConnectedTenantsList_tenants$data,
  +$fragmentRefs: ConnectedTenantsList_tenants$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ConnectedTenantsList_tenants",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "type": "Tenant",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '628b86554fc8082e57c1de2b23edd65c';

module.exports = node;
