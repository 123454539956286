/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserQuestionnairesQuestionSet_user_questionnaires_question_set$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$ref: FragmentReference;
declare export opaque type UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$fragmentType: UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$ref;
export type UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set = {|
  +id: string,
  +$fragmentRefs: UserQuestionnairesQuestionSet_user_questionnaires_question_set$ref,
  +$refType: UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$ref,
|};
export type UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$data = UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set;
export type UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$key = {
  +$data?: UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$data,
  +$fragmentRefs: UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnairesQuestionSetAnswerPage_user_questionnaires_question_set",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionnairesQuestionSet_user_questionnaires_question_set"
    }
  ],
  "type": "UserQuestionnairesQuestionSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '802991069dda05272cd404dda540acfb';

module.exports = node;
