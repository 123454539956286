// @flow

import { useEffect, useState } from 'react'

import { jsonLocalStorage } from 'services/browserStorage'

const useChartToggle = () => {
  // prettier-ignore
  const [chartSetting, setChartSetting] = useState<string>('')

  useEffect(() => {
    const checkChartSetting = () => {
      const savedChartSetting =
        jsonLocalStorage.getItem('chartSetting') || 'GAUGE'
      setChartSetting(savedChartSetting)
    }

    checkChartSetting()

    window.addEventListener('storage', checkChartSetting)

    return () => {
      window.removeEventListener('storage', checkChartSetting)
    }
  }, [])

  const isSummaryGauge = chartSetting === 'GAUGE'
  const isSummaryGraph = chartSetting === 'GRAPH'

  return { isSummaryGauge, isSummaryGraph, setChartSetting }
}

export default useChartToggle
