/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserQuestionnaire_role$ref: FragmentReference;
declare export opaque type UserQuestionnaire_role$fragmentType: UserQuestionnaire_role$ref;
export type UserQuestionnaire_role = {|
  +id?: string,
  +role_type?: RoleTypes,
  +$refType: UserQuestionnaire_role$ref,
|};
export type UserQuestionnaire_role$data = UserQuestionnaire_role;
export type UserQuestionnaire_role$key = {
  +$data?: UserQuestionnaire_role$data,
  +$fragmentRefs: UserQuestionnaire_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserQuestionnaire_role",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "role_type",
          "storageKey": null
        }
      ],
      "type": "RoleInterface",
      "abstractKey": "__isRoleInterface"
    }
  ],
  "type": "Role",
  "abstractKey": "__isRole"
};
// prettier-ignore
(node/*: any*/).hash = 'b8fb38b6e5cff96577114bb6f83a2b1b';

module.exports = node;
