/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type QuestionnaireComplete_user_questionnaires_question_sets$ref: FragmentReference;
declare export opaque type QuestionnaireComplete_user_questionnaires_question_sets$fragmentType: QuestionnaireComplete_user_questionnaires_question_sets$ref;
export type QuestionnaireComplete_user_questionnaires_question_sets = {
  +latest_submitted_answer_set: ?{
    +skip_reason_value: ?string,
    ...
  },
  +question_set: {
    +label: string,
    ...
  },
  +questionnaires_question_set: {
    +id: string,
    ...
  },
  ...
};
export type QuestionnaireComplete_user_questionnaires_question_sets$data = QuestionnaireComplete_user_questionnaires_question_sets;
export type QuestionnaireComplete_user_questionnaires_question_sets$key = {
  +$data?: QuestionnaireComplete_user_questionnaires_question_sets$data,
  +$fragmentRefs: QuestionnaireComplete_user_questionnaires_question_sets$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "mask": false
  },
  "name": "QuestionnaireComplete_user_questionnaires_question_sets",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerSet",
      "kind": "LinkedField",
      "name": "latest_submitted_answer_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "skip_reason_value",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionnairesQuestionSet",
      "kind": "LinkedField",
      "name": "questionnaires_question_set",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "UserQuestionnairesQuestionSet",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'bb411a5ea09180abb4020a919b8c4801';

module.exports = node;
