/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type StaffEscalationsTableDataGrid_escalations$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type EscalationsTable_user$ref: FragmentReference;
declare export opaque type EscalationsTable_user$fragmentType: EscalationsTable_user$ref;
export type EscalationsTable_user = {|
  +clinical_escalations?: {|
    +edges: ?$ReadOnlyArray<?{|
      +node: ?{|
        +$fragmentRefs: StaffEscalationsTableDataGrid_escalations$ref
      |}
    |}>
  |},
  +$refType: EscalationsTable_user$ref,
|};
export type EscalationsTable_user$data = EscalationsTable_user;
export type EscalationsTable_user$key = {
  +$data?: EscalationsTable_user$data,
  +$fragmentRefs: EscalationsTable_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "assigned"
    },
    {
      "defaultValue": 25,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "search"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sort"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "stage"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "status"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": [
          "clinical_escalations"
        ]
      }
    ]
  },
  "name": "EscalationsTable_user",
  "selections": [
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "alias": "clinical_escalations",
          "args": null,
          "concreteType": "EscalationConnection",
          "kind": "LinkedField",
          "name": "__EscalationsPageLeadClinician_clinical_escalations_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EscalationEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Escalation",
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "StaffEscalationsTableDataGrid_escalations"
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "type": "EscalationHandlerInterface",
      "abstractKey": "__isEscalationHandlerInterface"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '565922c5e571c125a87879081b7ff58e';

module.exports = node;
