// @flow

import React, { useState } from 'react'
import { format } from 'date-fns'

import { type QuarterGroup } from 'platform_web/components/QuarterPicker/hooks/useQuarterGenerator'
import {
  Box,
  DropDownMenu,
  DropDownMenuItem,
  FlexContainer,
  IconNew,
  Text,
} from 'care-ui'
import { ChevronLeftIcon } from 'care-ui/atoms/icons-new'

type QuarterRangeProps = {
  onChange: (queryVariables: {
    [key: string]: string,
  }) => void,
  quarterGroup: QuarterGroup,
  setSelectedLabel: (label: string) => void,
  toggleParentDropdown: () => void,
}

const formatDate = (date = new Date()) => format(new Date(date), 'yyyy-MM-dd')

const Quarter = (props: QuarterRangeProps) => {
  const {
    quarterGroup,
    setSelectedLabel,
    toggleParentDropdown,
    onChange,
  } = props
  const { label, quarters } = quarterGroup
  const [closeDropDown, setCloseDropDown] = useState(true)

  const toggleDropDownMenu = () => {
    setCloseDropDown(prevState => !prevState)
  }

  const handleOnClick = option => {
    setSelectedLabel(option.label)
    onChange({
      quarter_start_date: formatDate(option.quarterStartDate),
      quarter_end_date: formatDate(option.quarterEndDate),
    })
    toggleParentDropdown()
  }

  return (
    <DropDownMenu
      position="left"
      closeDropDown={closeDropDown}
      setCloseDropDown={setCloseDropDown}
      dropDownTrigger={
        <Box width="305px">
          <DropDownMenuItem
            onClick={toggleDropDownMenu}
            dataTestId="customDateSelector"
          >
            <Box width="100%">
              <FlexContainer
                justifyContent="space-between"
                alignItems="center"
                wrap="nowrap"
                gap="xxxs"
              >
                <FlexContainer>
                  <FlexContainer>
                    {quarters && <IconNew as={ChevronLeftIcon} />}
                    <Text size="md">{label}</Text>
                  </FlexContainer>
                </FlexContainer>
              </FlexContainer>
            </Box>
          </DropDownMenuItem>
        </Box>
      }
    >
      {quarters?.map(option => (
        <DropDownMenuItem
          onClick={() => handleOnClick(option)}
          key={option.label}
        >
          {option.label}
        </DropDownMenuItem>
      ))}
    </DropDownMenu>
  )
}

export default Quarter
