// @flow

type RequestAssessmentType = {
  questionnaire: {
    category: ?string,
    id: string,
    label: string,
  },
  requestable: boolean,
}

type RequestAssessmentsType = {
  requestAssessments: Array<RequestAssessmentType>,
  selectedRequestAssessments: Array<{ [key: string]: string }>,
}

const useRequestAssessment = (props: RequestAssessmentsType) => {
  const { requestAssessments, selectedRequestAssessments } = props

  const initialRequestAssessment = requestAssessments.find(
    requestAssessment => requestAssessment.questionnaire.category === 'initial',
  )
  const summaryRequestAssessment = requestAssessments.find(
    requestAssessment => requestAssessment.questionnaire.category === 'summary',
  )
  const summaryHealthDomainsRequestAssessment = requestAssessments.filter(
    requestAssessment =>
      requestAssessment.questionnaire.category === 'health_domain_summary',
  )
  const otherRequestAssessments: Array<
    RequestAssessmentType,
  > = requestAssessments.filter(
    requestAssessment =>
      requestAssessment.questionnaire.category !== 'initial' &&
      requestAssessment.questionnaire.category !== 'summary',
  )

  const initialQuestionnaireId = initialRequestAssessment?.questionnaire.id
  const summaryQuestionnaireId = summaryRequestAssessment?.questionnaire.id
  const summaryHealthDomainsQuestionnaireIds = summaryHealthDomainsRequestAssessment.map(
    assessment => assessment.questionnaire.id,
  )

  const disableInitialRequestAssessment = selectedRequestAssessments.some(
    item => item.id !== initialQuestionnaireId,
  )
  const disableSummaryRequestAssessment = selectedRequestAssessments.some(
    item =>
      item.id !== summaryQuestionnaireId ||
      summaryHealthDomainsQuestionnaireIds.includes(item.id),
  )

  const disableOtherRequestAssessment = (questionnaireId: string) => {
    const isSummaryHealthDomains = summaryHealthDomainsQuestionnaireIds.includes(
      questionnaireId,
    )

    return (
      selectedRequestAssessments.some(
        item => item.id === initialQuestionnaireId,
      ) ||
      (isSummaryHealthDomains &&
        selectedRequestAssessments.some(
          item => item.id === summaryQuestionnaireId,
        ))
    )
  }

  return {
    initialRequestAssessment,
    summaryRequestAssessment,
    otherRequestAssessments,
    disableInitialRequestAssessment,
    disableSummaryRequestAssessment,
    disableOtherRequestAssessment,
  }
}

export default useRequestAssessment
