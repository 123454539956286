// @flow

import * as React from 'react'
import Route from 'found/Route'

import SwitchRolePage from 'pages/Role/components/SwitchRolePage'
import SwitchTenantPage from 'pages/Tenant/SwitchTenantPage'
import SigningInPage from 'pages/User/SigningInPage'
import SigningOutPage from 'pages/User/SigningOutPage'
import UserQuestionnaireLoadingPage from 'pages/UserQuestionnaire/components/UserQuestionnaireLoadingPage'

export default (
  <Route path="/loading">
    <Route
      Component={SwitchRolePage}
      path="/change_role/:roleId"
      name="changeRole"
    />
    <Route
      Component={SwitchTenantPage}
      path="/change_tenant/:tenantId"
      name="changeTenant"
    />
    <Route
      Component={SigningInPage}
      path="/signing_in/:nextLocation"
      name="signingIn"
    />
    <Route Component={SigningOutPage} path="/signing_out" name="signingOut" />
    <Route
      Component={UserQuestionnaireLoadingPage}
      path="/questionnaire/:questionnaireId/:showStartPage?/:answereeId?"
      name="loadQuestionnaire"
    />
  </Route>
)
