// @flow

import React from 'react'

export type ScrollType = { scrollX: number, scrollY: number }

const ScrollContext: any = React.createContext({
  scrollX: 0,
  scrollY: 0,
})

export default ScrollContext
