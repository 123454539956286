// @flow

export const spacings = {
  xxxs: '4px',
  xxs: '8px',
  xs: '12px',
  sm: '16px',
  md: '20px',
  lg: '24px',
  xl: '36px',
  xxl: '48px',
  xxxl: '64px',
}
