/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ServiceMetricsHomePage_viewer$ref = any;
export type ServiceMetricsHomePageQueryVariables = {||};
export type ServiceMetricsHomePageQueryResponse = {|
  +viewer: {|
    +$fragmentRefs: ServiceMetricsHomePage_viewer$ref
  |}
|};
export type ServiceMetricsHomePageQuery = {|
  variables: ServiceMetricsHomePageQueryVariables,
  response: ServiceMetricsHomePageQueryResponse,
|};
*/


/*
query ServiceMetricsHomePageQuery {
  viewer {
    ...ServiceMetricsHomePage_viewer
    id
  }
}

fragment ServiceMetricsHomePage_viewer on Viewer {
  reports {
    id
    available_tenants {
      id
      name
    }
    satisfaction_individual_sign_in_dimensions {
      key
      value
    }
    accessibility_breakdown_dimensions {
      key
      value
    }
  }
  currentUser {
    tenant {
      id
      multi_roles_enabled
    }
    roles {
      totalCount
      nodes {
        __typename
        ... on RoleInterface {
          __isRoleInterface: __typename
          tenant_id
          id
          role_type
        }
        ... on AdminRole {
          id
        }
        ... on ClinicianRole {
          id
        }
        ... on IndividualRole {
          id
        }
        ... on LeadClinicianRole {
          id
        }
        ... on ManagerRole {
          id
        }
        ... on OwnerRole {
          id
        }
        ... on ResearcherRole {
          id
        }
        ... on SuperRole {
          id
        }
        ... on SupportPersonRole {
          id
        }
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "key",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "value",
    "storageKey": null
  }
],
v2 = [
  (v0/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ServiceMetricsHomePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ServiceMetricsHomePage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ServiceMetricsHomePageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v0/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "available_tenants",
                "plural": true,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "name",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "SatisfactionIndividualSignInDimension",
                "kind": "LinkedField",
                "name": "satisfaction_individual_sign_in_dimensions",
                "plural": true,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "AccessibilityBreakdownDimension",
                "kind": "LinkedField",
                "name": "accessibility_breakdown_dimensions",
                "plural": true,
                "selections": (v1/*: any*/),
                "storageKey": null
              },
              {
                "kind": "ClientExtension",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ReportSettings",
                    "kind": "LinkedField",
                    "name": "settings",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "clinicians",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "tenants",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "from",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "to",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "selectedDateType",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ]
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "currentUser",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "Tenant",
                "kind": "LinkedField",
                "name": "tenant",
                "plural": false,
                "selections": [
                  (v0/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "multi_roles_enabled",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "RoleConnection",
                "kind": "LinkedField",
                "name": "roles",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "totalCount",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "nodes",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "tenant_id",
                            "storageKey": null
                          },
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role_type",
                            "storageKey": null
                          }
                        ],
                        "type": "RoleInterface",
                        "abstractKey": "__isRoleInterface"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "AdminRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "ClinicianRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "IndividualRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "LeadClinicianRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "ManagerRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "OwnerRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "ResearcherRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "SuperRole",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v2/*: any*/),
                        "type": "SupportPersonRole",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v0/*: any*/)
            ],
            "storageKey": null
          },
          (v0/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9feb27efa23b0bbbc133a34a333ca7cd",
    "id": null,
    "metadata": {},
    "name": "ServiceMetricsHomePageQuery",
    "operationKind": "query",
    "text": "query ServiceMetricsHomePageQuery {\n  viewer {\n    ...ServiceMetricsHomePage_viewer\n    id\n  }\n}\n\nfragment ServiceMetricsHomePage_viewer on Viewer {\n  reports {\n    id\n    available_tenants {\n      id\n      name\n    }\n    satisfaction_individual_sign_in_dimensions {\n      key\n      value\n    }\n    accessibility_breakdown_dimensions {\n      key\n      value\n    }\n  }\n  currentUser {\n    tenant {\n      id\n      multi_roles_enabled\n    }\n    roles {\n      totalCount\n      nodes {\n        __typename\n        ... on RoleInterface {\n          __isRoleInterface: __typename\n          tenant_id\n          id\n          role_type\n        }\n        ... on AdminRole {\n          id\n        }\n        ... on ClinicianRole {\n          id\n        }\n        ... on IndividualRole {\n          id\n        }\n        ... on LeadClinicianRole {\n          id\n        }\n        ... on ManagerRole {\n          id\n        }\n        ... on OwnerRole {\n          id\n        }\n        ... on ResearcherRole {\n          id\n        }\n        ... on SuperRole {\n          id\n        }\n        ... on SupportPersonRole {\n          id\n        }\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f3b4f8c9df9a0d05871411669c75f099';

module.exports = node;
