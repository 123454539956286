// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const containerRules = (props: StyleProps) => {
  const { theme } = props

  return {
    Section: {
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      marginTop: theme.spacing(1),
    },
  }
}
