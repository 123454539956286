// @flow

import { useRelayEnvironment } from 'react-relay'
import { useRouter } from 'found'
import { omit } from 'lodash'

import { commit as commitUserQuestionnaireRefresh } from 'mutations/UserQuestionnaireRefresh'
import {
  type PropTypes as routeQuestionnaireType,
  routeQuestionnaire,
} from 'services/questionnaire/routeQuestionnaire'
import { routeQuestionSet } from 'services/questionnaire/routeQuestionnaireQuestionSet'

type QuestionnaireType = routeQuestionnaireType & {
  showQuestionnairePage?: boolean,
}

const useQuestionnaireSession = (questionnaireProps: QuestionnaireType) => {
  const { router } = useRouter()
  const environment = useRelayEnvironment()

  let start

  if (questionnaireProps.showQuestionnairePage) {
    start = () =>
      routeQuestionnaire({
        ...omit(questionnaireProps, 'showQuestionnairePage'),
        router,
      })
  } else {
    start = () =>
      commitUserQuestionnaireRefresh({
        environment,
        variables: {
          input: {
            questionnaireId: questionnaireProps.questionnaireId,
            answereeId: questionnaireProps.answereeUserId,
            assessmentRequestId: questionnaireProps.assessmentRequestId,
          },
        },
        onCompleted: ({ userQuestionnaireRefresh }) => {
          if (!userQuestionnaireRefresh)
            throw new Error('No userQuestionnaireRefresh returned')

          const { user_questionnaire } = userQuestionnaireRefresh

          if (user_questionnaire.ready_to_complete) {
            routeQuestionnaire({
              ...omit(questionnaireProps, 'showQuestionnairePage'),
              router,
            })
            return
          }

          const userQuestionnaireQuestionSet =
            userQuestionnaireRefresh.user_questionnaire
              .first_user_questionnaires_question_set

          if (!userQuestionnaireQuestionSet)
            throw new Error('No questionnaireQuestionSetId found')

          routeQuestionSet({
            ...questionnaireProps,
            questionnaireQuestionSetId:
              userQuestionnaireQuestionSet.questionnaires_question_set.id,
            router,
          })
        },
      })
  }

  return { start }
}

export default useQuestionnaireSession
