// @flow

import { graphql } from 'react-relay'

export const assignableIndividualsQuery = graphql`
  query AssignableIndividualsPaginationQuery(
    $clinicians: RolesSelectionInput!
    $tenant_id: ID!
    $count: Int!
    $cursor: String
    $search: String
  ) {
    viewer {
      ...AssignableIndividualsPagination_tenant
    }
  }
`

export const assignableIndividualsFragment = graphql`
  fragment AssignableIndividualsPagination_tenant on Viewer
    @refetchable(queryName: "AssignableIndividualsPaginationRefetchQuery") {
    tenant(id: $tenant_id) {
      multi_select_assignable_individuals(
        search: $search
        clinicians: $clinicians
        first: $count
        after: $cursor
      )
        @connection(
          key: "AssignableIndividualsPagination_multi_select_assignable_individuals"
        ) {
        edges {
          node {
            email
            id
            user {
              id
              name
            }
          }
        }
      }
    }
  }
`
