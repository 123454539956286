// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordForms__mainError___fX6aE{color:red;font-weight:bold}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"mainError": `PasswordForms__mainError___fX6aE`
};
export default ___CSS_LOADER_EXPORT___;
