// @flow

import { graphql } from 'react-relay'

export const supporteesFragment = graphql`
  fragment SupportingSupporteeSelectionPage_supportees on IndividualRole
    @relay(plural: true) {
    ...QuestionnaireShowPage_supportee @relay(mask: false)
  }
`

export const query = graphql`
  query SupportingSupporteeSelectionPage_Query(
    $id: ID!
    $count: Int!
    $cursor: String
  ) {
    node(id: $id) {
      ... on User {
        name
        current_role {
          ... on SupportPersonRole {
            id
            supportees {
              ...SupportingSupporteeQuestionnairesList_supportees
              ...SupportingSupporteeSelectionPage_supportees
            }
            individual_assignments {
              ...SupportingSupporteeInfoWrapper_individual_assignments
            }
          }
        }
        ...SupportingSupporteeQuestionnairesList_user
          @arguments(count: $count, cursor: $cursor)
      }
    }
  }
`
