// @flow

import { createComponentWithProxy } from 'react-fela'

const styleRules = ({ theme, width = '84px' }) => ({
  borderRadius: '5px',
  boxSizing: 'border-box',
  className: 'flag',
  display: 'inline-block',
  height: 'auto',
  marginLeft: 0,
  marginRight: theme.Grid.gutter,
  width,
})

export default createComponentWithProxy(styleRules)
