// @flow

import React, { Suspense, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import {
  type PreloadedQuery,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay'
import { get } from 'lodash'

import { Graph, GraphContainer } from 'care-ui'

import useGraphPagination from '../../hooks/useGraphPagination'
import { type OverviewQueryVariableType } from '../../hooks/useOverviewInsights'
import { query as clinicianLoginsQuery } from '../../queries/ClinicianLoginsInsightsQuery'

import { type ClinicianLoginsInsightsQuery } from '../../queries/__generated__/ClinicianLoginsInsightsQuery.graphql'

type ComponentProps = {
  loadClinicianLoginsQuery: (
    overviewQueryVariables: OverviewQueryVariableType,
  ) => void,
  overviewQueryVariables: OverviewQueryVariableType,
  queryReference: PreloadedQuery<ClinicianLoginsInsightsQuery>,
}

const Component = (props: ComponentProps) => {
  const {
    overviewQueryVariables,
    queryReference,
    loadClinicianLoginsQuery,
  } = props

  const queryResult = usePreloadedQuery(clinicianLoginsQuery, queryReference)

  const seriesCategories = get(
    queryResult,
    'viewer.insights.clinician_logins.series_categories',
  )
  const series = get(queryResult, 'viewer.insights.clinician_logins.series')

  // Pagination
  const { onPaginate, isRightPaginationDisabled } = useGraphPagination({
    overviewQueryVariables,
    loadQuery: loadClinicianLoginsQuery,
  })

  const { t: translation } = useTranslation('insights')

  return (
    <Graph
      graphType="stack"
      series={series}
      seriesCategories={seriesCategories}
      onPaginateLeft={() => onPaginate('left')}
      onPaginateRight={() => onPaginate('right')}
      isRightPaginationDisabled={isRightPaginationDisabled}
      xAxisTitle={translation('number_of_clinician_sign_ins')}
    />
  )
}

type IndividualLoginsInsightsGraphProps = {
  overviewQueryVariables: OverviewQueryVariableType,
}

// Query Loader
const ClinicianLoginsInsightsGraph = (
  props: IndividualLoginsInsightsGraphProps,
) => {
  const { overviewQueryVariables } = props

  const [
    clinicianLoginsQueryReference,
    loadClinicianLoginsQuery,
  ] = useQueryLoader(clinicianLoginsQuery)

  // Load query when query variables change
  useEffect(
    () => {
      loadClinicianLoginsQuery(overviewQueryVariables)
    },
    [overviewQueryVariables],
  )

  // transalations
  const { t: translation } = useTranslation('insights')

  return (
    <GraphContainer
      heading={translation('clinician_sign_ins')}
      graphInfoText={translation(
        'this_graph_visualises_the_number_of_clinician_sign_ins_over_the_specified_period_helping_to_track_employee_engagement_and_activity_on_the_platform_the_graph_will_break_down_the_sign_ins_based_on_the_number_of_times_each_clinician_signed_in',
      )}
    >
      <Suspense>
        {clinicianLoginsQueryReference != null && (
          <Component
            overviewQueryVariables={overviewQueryVariables}
            queryReference={clinicianLoginsQueryReference}
            loadClinicianLoginsQuery={loadClinicianLoginsQuery}
          />
        )}
      </Suspense>
    </GraphContainer>
  )
}

export default ClinicianLoginsInsightsGraph
