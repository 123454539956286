// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  UserQuestionnairesQuestionSetAnswerMutationResponse,
  UserQuestionnairesQuestionSetAnswerMutationVariables,
} from './__generated__/UserQuestionnairesQuestionSetAnswerMutation.graphql'

const mutation = graphql`
  mutation UserQuestionnairesQuestionSetAnswerMutation(
    $input: UserQuestionnairesQuestionSetAnswerInput!
  ) {
    userQuestionnairesQuestionSetAnswer(input: $input) {
      user_questionnaires_question_set {
        all_questions_answered
        skippable
        visible_questions {
          edges {
            ...UserQuestionnairesQuestionSet_Answer_question @relay(mask: false)
            node {
              required
              ...UserQuestionnairesQuestionSet_Question_question
                @relay(mask: false)
            }
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (
    result: UserQuestionnairesQuestionSetAnswerMutationResponse,
  ) => void,
  onError?: (result: Object) => void,
  variables: UserQuestionnairesQuestionSetAnswerMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
