/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type AvatarLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserCardLoader_user$ref: FragmentReference;
declare export opaque type UserCardLoader_user$fragmentType: UserCardLoader_user$ref;
export type UserCardLoader_user = {|
  +username: string,
  +id: string,
  +legal_name: ?string,
  +preferred_name: ?string,
  +$fragmentRefs: AvatarLoader_user$ref,
  +$refType: UserCardLoader_user$ref,
|};
export type UserCardLoader_user$data = UserCardLoader_user;
export type UserCardLoader_user$key = {
  +$data?: UserCardLoader_user$data,
  +$fragmentRefs: UserCardLoader_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserCardLoader_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "legal_name",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "preferred_name",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AvatarLoader_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '472fedd7bf055c4107565faefb72b1c3';

module.exports = node;
