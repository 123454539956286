// @flow

import { createComponentWithProxy } from 'react-fela'

const Icon = createComponentWithProxy(
  ({ theme, color = 'currentColor', scale = 1 }) => ({
    className: `Icon--color-${color}`,
    display: 'inline-block',
    height: theme.spacing(scale),
    lineHeight: theme.spacing(scale),
    width: theme.spacing(scale),
    userSelect: 'none',

    '& [data-name="SVG background containers"]': {
      visibility: 'hidden',
    },

    '& *[fill]:not([fill="none"])': {
      fill: color,
    },

    '& *[stroke]:not([stroke="none"])': {
      stroke: color,
    },
  }),
)

export default Icon
