// @flow

import { type ThemeType } from 'care-ui/atoms'

export type StyleProps = {
  theme: ThemeType,
}

export const triggerStyle = () => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
})

export const menuStyle = (props: StyleProps) => {
  const { theme } = props

  return {
    position: 'absolute',
    top: 0,
    right: 0,
    left: theme.care.spacing.sm,
    transform: 'translateY(-99%)',
    backgroundColor: theme.care.palette.surface.subtle,
  }
}
