// @flow

import * as colors from 'react-ui/themes/synergy-base-theme/colors'

export const accordionItemStyle = () => ({
  AccordionItem: {
    marginTop: '1.2rem',
    marginBottom: '0',
    backgroundColor: 'white',
    borderRadius: '0.3rem',
    boxShadow: `${colors.greyscale['200']} 0 0 0.4rem`,
  },
  header: {
    backgroundColor: 'white',
    flexDirection: 'row-reverse',
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: '0.3rem',
    paddingRight: 0,
    borderRadius: '0.3rem',
  },
  headerContainer: {
    display: 'flex',
    backgroundColor: 'white',
    borderRadius: '0.3rem',
    height: '2.6rem',
    paddingTop: '0.6rem',
  },
  heading: {
    fontSize: '1rem',
    borderRadius: '0.3rem',
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: 'white',
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '1.9rem',
    paddingRight: '1.9rem',
    borderRadius: '0 0 4px 4px',
  },
})

export const offButtonStyle = () => ({
  paddingRight: '1rem',
  position: 'relative',
  bottom: '0.31rem',
})
