// @flow

import { type ThemeType } from 'care-ui/atoms'

type PropsType = {
  theme: ThemeType,
}

export const mainContentStyle = ({ theme }: PropsType) => ({
  marginTop: 0,
  marginBottom: '2rem',
  padding: '2rem 1rem',
  borderRadius: '5px',
  borderColor: theme.palette.lightBackground,
  width: '100%',
  boxShadow: '0 3px 6px 2px rgba(7, 7, 8, 0.14)',
  [theme.care.breakpoints.queries.md]: {
    marginTop: '1rem',
    marginBottom: '3rem',
    padding: '2.2rem 1.4rem 1.4rem',
  },
})

export const wrapperStyle = ({ theme }: PropsType) => ({
  margin: 'auto',
  display: 'flex',
  justifyContent: 'space-between',
  [theme.breakpoints.queries.xsOnly]: {
    justifyContent: 'center',
    flexDirection: 'column',
    '> a': {
      margin: 'auto',
      marginBottom: '20px',
    },
  },
})
