// @flow

import React from 'react'
import { graphql, QueryRenderer, useRelayEnvironment } from 'react-relay'

import { QueryRendererLoadingIndicator } from 'components/QueryRendererLoadingIndicator'
import UnauthorizedPage from 'pages/UnauthorizedPage'
import { useAuthPolicy } from 'platform_web/features/Policies'
import StaffNavigationTabs from 'platform_web/features/StaffNavigationTabs'
import Container from 'care-ui/layouts/Grid/Container'
import Divider from 'care-ui/molecules/Divider/Divider'

import EscalationsTable from './EscalationsTable'

import { type StaffEscalationsPage_QueryResponse } from './__generated__/StaffEscalationsPage_Query.graphql'

type PropsType = StaffEscalationsPage_QueryResponse

const StaffEscalationsPage = (props: PropsType) => {
  const tenant = props.viewer.currentUser?.tenant

  return (
    <>
      <nav>
        <Container>
          <StaffNavigationTabs />
        </Container>
        <Divider />
      </nav>

      <main>
        <Container>
          <EscalationsTable tenant={tenant} />
        </Container>
      </main>
    </>
  )
}

const StaffEscalationsPageQuery = graphql`
  query StaffEscalationsPage_Query {
    viewer {
      currentUser {
        id
        tenant {
          stage_1_escalation_timeout
          stage_2_escalation_timeout
        }
      }
    }
  }
`

const StaffEscalationsPageLoader = () => {
  const environment = useRelayEnvironment()

  return (
    <QueryRenderer
      environment={environment}
      query={StaffEscalationsPageQuery}
      variables={{}}
      render={response => {
        return (
          <QueryRendererLoadingIndicator response={response}>
            {props => {
              return <StaffEscalationsPage {...(props: any)} />
            }}
          </QueryRendererLoadingIndicator>
        )
      }}
    />
  )
}

export const StaffEscalationsPageWithPolicies = () => {
  const { isAuthorized, isLoading } = useAuthPolicy(['CAN_VIEW_NOTIFICATIONS'])

  if (isLoading) return null

  return isAuthorized ? <StaffEscalationsPageLoader /> : <UnauthorizedPage />
}
