// @flow

import React from 'react'
import { graphql } from 'react-relay'
import useRouter from 'found/useRouter'
import { findKey, isEqual } from 'lodash/fp'

import LoadMore from 'react-ui/components/LoadMore/LoadMore'
import { nodesOf } from 'shared/utils/paginationUtils'
import { StaffEscalationsTableDataGrid } from 'platform_web/components/Staff/StaffEscalationsTableDataGrid'
import TableDataLoader from 'platform_web/features/TableDataLoader/TableDataLoader'

import { PATH_ROUTES } from '../constants/EscalationsTableConstants'
import useEscalationsTable from '../hooks/useEscalationsTable'

import EscalationsTableControls from './EscalationsTableControl'

const escalationsTablePaginatedQuery = graphql`
  query EscalationsTable_PaginatedQuery(
    $count: Int!
    $cursor: String
    $search: String
    $status: [EscalationStatuses!]!
    $sort: [EscalationSortable!]!
    $stage: EscalationStageOption
    $assigned: Boolean
  ) {
    viewer {
      currentUser {
        ...EscalationsTable_user
          @arguments(
            count: $count
            cursor: $cursor
            status: $status
            search: $search
            sort: $sort
            stage: $stage
            assigned: $assigned
          )
      }
    }
  }
`

const escalationsTableFragments = {
  user: graphql`
    fragment EscalationsTable_user on User
      @argumentDefinitions(
        count: { type: "Int", defaultValue: 25 }
        cursor: { type: "String" }
        search: { type: "String" }
        status: { type: "[EscalationStatuses!]!" }
        sort: { type: "[EscalationSortable!]" }
        stage: { type: "EscalationStageOption" }
        assigned: { type: "Boolean" }
      ) {
      ... on EscalationHandlerInterface {
        clinical_escalations(
          status: $status
          first: $count
          after: $cursor
          search: $search
          sort: $sort
          stage: $stage
          assigned: $assigned
        )
          @connection(
            key: "EscalationsPageLeadClinician_clinical_escalations"
            filters: ["lead_clinician"]
          ) {
          edges {
            node {
              ...StaffEscalationsTableDataGrid_escalations
            }
          }
        }
      }
    }
  `,
}

type EscalationsTablePropsType = {
  tenant: any,
}

const EscalationsTable = (props: EscalationsTablePropsType) => {
  const { tenant } = props
  const { router, match } = useRouter()
  const initialPath = findKey(p => isEqual(p, match.params))(PATH_ROUTES)

  const useEscalationsTablePayload = useEscalationsTable({
    defaultTableType: initialPath,
    tenant,
  })

  const {
    queryVariables,
    showResolvedDetails,
    count,
  } = useEscalationsTablePayload

  return (
    <>
      {/* Table Controls */}
      <EscalationsTableControls
        useEscalationsTablePayload={useEscalationsTablePayload}
        router={router}
      />

      {/* Table Data */}
      <TableDataLoader
        query={escalationsTablePaginatedQuery}
        fragments={escalationsTableFragments}
        queryVariables={queryVariables}
        getFragments={data => ({ user: data.viewer.currentUser })}
      >
        {({ relay, user }) => {
          return (
            <>
              <StaffEscalationsTableDataGrid
                escalations={nodesOf(user.clinical_escalations)}
                relay={relay}
                showResolvedDetails={showResolvedDetails}
              />
              {relay.hasMore() && (
                <LoadMore handleClick={() => relay.loadMore(count)} />
              )}
            </>
          )
        }}
      </TableDataLoader>
    </>
  )
}

export default EscalationsTable
