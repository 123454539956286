// @flow

const sort = { column: 'createdAt', direction: 'DESC' }

export const ESCALATIONS_TABLE_CONFIGURATIONS = {
  open_notifications: {
    status: ['open'],
    stage: undefined,
    sort,
    sortCode: 'createdAt-date',
    search: '',
  },
  open_stage1_notifications: {
    status: ['open'],
    stage: 'STAGE_1',
    sort,
    sortCode: 'createdAt-date',
    search: '',
  },
  open_stage2_notifications: {
    status: ['open'],
    stage: 'STAGE_2',
    sort,
    sortCode: 'createdAt-date',
    search: '',
  },
  resolved_notifications: {
    status: ['resolved'],
    stage: undefined,
    sort: { column: 'resolvedAt', direction: 'DESC' },
    sortCode: 'resolvedAt-date',
    search: '',
  },
}

export const PATH_ROUTES = {
  open_notifications: {
    status: undefined,
    stage: undefined,
  },
  open_stage1_notifications: {
    status: 'open',
    stage: 'stage1',
  },
  open_stage2_notifications: {
    status: 'open',
    stage: 'stage2',
  },
  resolved_notifications: {
    status: 'resolved',
    stage: undefined,
  },
}
