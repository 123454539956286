// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import Heading from 'react-ui/components/Heading'
import Section from 'react-ui/components/Section'
import Spacer from 'react-ui/components/Spacer'

import { type FelaRulesType, type FelaStylesType } from 'react-ui/typing'

export type QuestionnaireCompleteInfoType = {
  body?: Node,
  cta: Node,
  subSection?: Node,
  text?: string,
  title: string,
}

type PropsType = {
  info: QuestionnaireCompleteInfoType,
  rules: FelaRulesType,
  styles: FelaStylesType,
}

const styleRules = () => ({
  wrapper: {
    overflow: 'hidden',
    position: 'relative',
  },
  information: {
    maxWidth: '850px',
    margin: '0 auto',
  },
})

export const QuestionnaireComplete = ({
  info: { title, cta, text, subSection, body },
  styles,
  rules,
}: PropsType) => (
  <div className={styles.wrapper}>
    <Section center color="white">
      <Container>
        {/*  Indentation Lost with Title after using the Heading component */}
        <Heading level={2} extend={rules.information}>
          {title}
        </Heading>
      </Container>
      {subSection && subSection}
      <Container>
        <Spacer units={2} />
        {cta}
      </Container>
    </Section>

    <Section center color={text ? 'white' : 'primary'}>
      {text && <div className={styles.information}>{text}</div>}
      {body && body}
    </Section>
  </div>
)

export const QuestionnaireCompleteRenderer = connect(styleRules)(
  QuestionnaireComplete,
)
