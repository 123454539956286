/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type ResourceLibraryPage_viewer$ref = any;
export type ResourceLibraryPage_QueryVariables = {||};
export type ResourceLibraryPage_QueryResponse = {|
  +viewer: {|
    +$fragmentRefs: ResourceLibraryPage_viewer$ref
  |}
|};
export type ResourceLibraryPage_Query = {|
  variables: ResourceLibraryPage_QueryVariables,
  response: ResourceLibraryPage_QueryResponse,
|};
*/


/*
query ResourceLibraryPage_Query {
  viewer {
    ...ResourceLibraryPage_viewer
    id
  }
}

fragment CareOption_resource on Resource {
  description
  id
  label
  url
}

fragment ResourceLibraryPage_viewer on Viewer {
  id
  ...ResourceList_resourcesConnectionInterface
}

fragment ResourceList_resourcesConnectionInterface on ResourcesConnectionInterface {
  __isResourcesConnectionInterface: __typename
  resource_library_connection(first: 100000) {
    edges {
      node {
        ...Resource_resource
        id
        __typename
      }
      cursor
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}

fragment Resource_resource on Resource {
  ...CareOption_resource
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 100000
  }
];
return {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "ResourceLibraryPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "ResourceLibraryPage_viewer"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "ResourceLibraryPage_Query",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v0/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": (v1/*: any*/),
                "concreteType": "ResourceConnection",
                "kind": "LinkedField",
                "name": "resource_library_connection",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ResourceEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Resource",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "description",
                            "storageKey": null
                          },
                          (v0/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "label",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "url",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__typename",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "cursor",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "PageInfo",
                    "kind": "LinkedField",
                    "name": "pageInfo",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "endCursor",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hasNextPage",
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "resource_library_connection(first:100000)"
              },
              {
                "alias": null,
                "args": (v1/*: any*/),
                "filters": [],
                "handle": "connection",
                "key": "ResourceList_resource_library_connection",
                "kind": "LinkedHandle",
                "name": "resource_library_connection"
              }
            ],
            "type": "ResourcesConnectionInterface",
            "abstractKey": "__isResourcesConnectionInterface"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "898e3c79e185ac691ce92cbb96c6c096",
    "id": null,
    "metadata": {},
    "name": "ResourceLibraryPage_Query",
    "operationKind": "query",
    "text": "query ResourceLibraryPage_Query {\n  viewer {\n    ...ResourceLibraryPage_viewer\n    id\n  }\n}\n\nfragment CareOption_resource on Resource {\n  description\n  id\n  label\n  url\n}\n\nfragment ResourceLibraryPage_viewer on Viewer {\n  id\n  ...ResourceList_resourcesConnectionInterface\n}\n\nfragment ResourceList_resourcesConnectionInterface on ResourcesConnectionInterface {\n  __isResourcesConnectionInterface: __typename\n  resource_library_connection(first: 100000) {\n    edges {\n      node {\n        ...Resource_resource\n        id\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment Resource_resource on Resource {\n  ...CareOption_resource\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'ab5892d3a5a709f9f5e8619efb647c64';

module.exports = node;
