// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const ChevronIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        version="1.1"
        x="0px"
        y="0px"
        viewBox="0 0 256 256"
        style={{ enableBackground: 'new 0 0 256 256' }}
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
      >
        <g>
          <g>
            <polygon points="225.813,48.907 128,146.72 30.187,48.907 0,79.093 128,207.093 256,79.093" />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default ChevronIcon
