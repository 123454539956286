// @flow

import { graphql } from 'react-relay'

export const healthCardListFragment = graphql`
  fragment HealthCardList_user_trackables on UserTrackable
    @relay(plural: true) {
    ...HealthCard_user_trackable
  }
`
