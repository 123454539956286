/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardsPage_user_trackable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPageComponent_user_trackable$ref: FragmentReference;
declare export opaque type ScoreCardsPageComponent_user_trackable$fragmentType: ScoreCardsPageComponent_user_trackable$ref;
export type ScoreCardsPageComponent_user_trackable = {|
  +$fragmentRefs: ScoreCardsPage_user_trackable$ref,
  +$refType: ScoreCardsPageComponent_user_trackable$ref,
|};
export type ScoreCardsPageComponent_user_trackable$data = ScoreCardsPageComponent_user_trackable;
export type ScoreCardsPageComponent_user_trackable$key = {
  +$data?: ScoreCardsPageComponent_user_trackable$data,
  +$fragmentRefs: ScoreCardsPageComponent_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ScoreCardsPageComponent_user_trackable",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ScoreCardsPage_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c9af10b5e0abfcf8c7731114e0829249';

module.exports = node;
