// @flow

import { useTranslation } from 'react-i18next'
import { camelCase, map, sortBy, union } from 'lodash/fp'

import type { IndividualsPageUser } from '../Individuals/IndividualsPage'

const getStandardColumnSort = translation => [
  { header: 'name', label: translation('patient_name') },
  { header: 'assignmentName', label: translation('clinicians') },
  { header: 'activeStatus', label: translation('status') },
  { header: 'invitedStatus', label: translation('status') },
  { header: 'removedStatus', label: translation('status') },
  { header: 'dischargedStatus', label: translation('status') },
]

const getScoresColumnSort = translation => [
  { header: 'recentActivity', label: translation('recent_activity'), order: 1 },
  {
    header: 'name',
    label: translation('notifications'),
    order: 5,
    prioritise: true,
  },
  { header: 'name', label: translation('patient_name'), order: 3 },
  { header: 'assignmentName', label: translation('clinicians'), order: 8 },
]

const sortOptionsBy = ({ user, tableCategory, translation }) => {
  const importantFormulas = user?.tenant?.important_formulas
  const importantColumns = map.convert({ cap: false })((item, index) => {
    const itemOrder = [7, 2, 4, 6]

    const prioritise =
      item.label === translation('suicidal_thoughts_and_behaviours')

    return {
      header: 'formulaResult',
      label: item.label,
      id: item.formula.id,
      order: itemOrder[index],
      prioritise,
    }
  })(importantFormulas)

  const SCORES_COLUMN_SORT = getScoresColumnSort(translation)
  const STANDARD_COLUMN_SORT = getStandardColumnSort(translation)

  const updatedScoresColumnSort = union(importantColumns, SCORES_COLUMN_SORT)
  const sortedUpdatedScoresColumnSort = sortBy('order')(updatedScoresColumnSort)

  const sortedOptions =
    tableCategory === 'scores'
      ? sortedUpdatedScoresColumnSort
      : STANDARD_COLUMN_SORT

  // Add unique sortCode for the source of truth
  const sortedOptionsWithSortCode = map(option => ({
    ...option,
    sortCode: `${option.header}-${camelCase(option.label)}`,
  }))(sortedOptions)

  return sortedOptionsWithSortCode
}

type UseIndividualTableSortProps = {
  currentUser?: IndividualsPageUser,
  tableCategory: string,
}

const useIndividualTableSort = (props: UseIndividualTableSortProps) => {
  const { currentUser, tableCategory } = props

  // Localization
  const { t: translation } = useTranslation('dataTable')

  const sortOptions = sortOptionsBy({
    user: currentUser,
    tableCategory,
    translation,
  })

  return {
    sortOptions,
  }
}

export default useIndividualTableSort
