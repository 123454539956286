/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthCardFooter_user_trackable$ref = any;
export type TenantTrackableKindEnum = "additional_assessment" | "gauge" | "text" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthCard_user_trackable$ref: FragmentReference;
declare export opaque type HealthCard_user_trackable$fragmentType: HealthCard_user_trackable$ref;
export type HealthCard_user_trackable = {|
  +formula_range: ?{|
    +color?: string,
    +label?: string,
  |},
  +time_since_updated: ?string,
  +trackable: {|
    +id: string,
    +label: string,
    +kind: TenantTrackableKindEnum,
  |},
  +trending: ?{|
    +label: string
  |},
  +user: {|
    +id: string
  |},
  +$fragmentRefs: HealthCardFooter_user_trackable$ref,
  +$refType: HealthCard_user_trackable$ref,
|};
export type HealthCard_user_trackable$data = HealthCard_user_trackable;
export type HealthCard_user_trackable$key = {
  +$data?: HealthCard_user_trackable$data,
  +$fragmentRefs: HealthCard_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "label",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthCard_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "formula_range",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            (v0/*: any*/)
          ],
          "type": "FormulaRangeInterface",
          "abstractKey": "__isFormulaRangeInterface"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "time_since_updated",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "kind",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "TrendType",
      "kind": "LinkedField",
      "name": "trending",
      "plural": false,
      "selections": [
        (v0/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        (v1/*: any*/)
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthCardFooter_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = 'fcd369bce6c740924fd327fa4a251526';

module.exports = node;
