// @flow

import React, { type Node } from 'react'
import cx from 'classnames'

import s from './Form.scss'

type PropsType = {
  children: Node,
  className?: string,
}

const Form = ({ children, className, ...rest }: PropsType) => (
  <form className={cx(s.form, className)} {...(rest: any)}>
    {children}
  </form>
)

export default Form
