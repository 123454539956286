/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type BaselineScoresQueryVariables = {|
  quarter_start_date: any,
  quarter_end_date: any,
  tenant_id: string,
|};
export type BaselineScoresQueryResponse = {|
  +viewer: {|
    +outcome_report: {|
      +baseline_scores: $ReadOnlyArray<{|
        +series: $ReadOnlyArray<{|
          +name: string,
          +color: string,
          +data: $ReadOnlyArray<number>,
        |}>,
        +health_domain_label: string,
        +total: number,
      |}>
    |}
  |}
|};
export type BaselineScoresQuery = {|
  variables: BaselineScoresQueryVariables,
  response: BaselineScoresQueryResponse,
|};
*/


/*
query BaselineScoresQuery(
  $quarter_start_date: DateTime!
  $quarter_end_date: DateTime!
  $tenant_id: ID!
) {
  viewer {
    outcome_report {
      baseline_scores(quarter_start_date: $quarter_start_date, quarter_end_date: $quarter_end_date, tenant_id: $tenant_id) {
        series {
          name
          color
          data
        }
        health_domain_label
        total
      }
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quarter_end_date"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "quarter_start_date"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenant_id"
},
v3 = {
  "alias": null,
  "args": null,
  "concreteType": "OutcomeReportType",
  "kind": "LinkedField",
  "name": "outcome_report",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "quarter_end_date",
          "variableName": "quarter_end_date"
        },
        {
          "kind": "Variable",
          "name": "quarter_start_date",
          "variableName": "quarter_start_date"
        },
        {
          "kind": "Variable",
          "name": "tenant_id",
          "variableName": "tenant_id"
        }
      ],
      "concreteType": "OutcomeReportDatasetType",
      "kind": "LinkedField",
      "name": "baseline_scores",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "OutcomeReportSeriesType",
          "kind": "LinkedField",
          "name": "series",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "name",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "color",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "data",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "health_domain_label",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "total",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "BaselineScoresQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "BaselineScoresQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8ed7d50f125806cdac67a96c740b4b40",
    "id": null,
    "metadata": {},
    "name": "BaselineScoresQuery",
    "operationKind": "query",
    "text": "query BaselineScoresQuery(\n  $quarter_start_date: DateTime!\n  $quarter_end_date: DateTime!\n  $tenant_id: ID!\n) {\n  viewer {\n    outcome_report {\n      baseline_scores(quarter_start_date: $quarter_start_date, quarter_end_date: $quarter_end_date, tenant_id: $tenant_id) {\n        series {\n          name\n          color\n          data\n        }\n        health_domain_label\n        total\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '52ce33086f1550d13c635f1fc3877793';

module.exports = node;
