/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
export type EmrStatusEnumType = "mandatory" | "none" | "optional" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type EditEmrModal_individual$ref: FragmentReference;
declare export opaque type EditEmrModal_individual$fragmentType: EditEmrModal_individual$ref;
export type EditEmrModal_individual = {|
  +individual_detail: ?{|
    +id: string,
    +emr_user_id: ?string,
  |},
  +tenant: ?{|
    +emr_integration: ?{|
      +emr_provider: {|
        +user_id_field_name: string
      |}
    |},
    +emr_status: EmrStatusEnumType,
  |},
  +$refType: EditEmrModal_individual$ref,
|};
export type EditEmrModal_individual$data = EditEmrModal_individual;
export type EditEmrModal_individual$key = {
  +$data?: EditEmrModal_individual$data,
  +$fragmentRefs: EditEmrModal_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EditEmrModal_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "IndividualDetail",
      "kind": "LinkedField",
      "name": "individual_detail",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emr_user_id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EmrIntegrationType",
          "kind": "LinkedField",
          "name": "emr_integration",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "EmrProvider",
              "kind": "LinkedField",
              "name": "emr_provider",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "user_id_field_name",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "emr_status",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '9774fcc1329689df208d3612791dbfeb';

module.exports = node;
