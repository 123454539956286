// @flow

import { type ThemeType } from 'care-ui/atoms'

export const currentResultCardStyle = ({ theme }: { theme: ThemeType }) => ({
  width: '100%',
  borderRadius: '5px',
  border: '1px transparent',
  borderColor: theme.palette.lightBackground,
  boxShadow: '0 3px 6px 2px rgba(7, 7, 8, 0.14)',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  [theme.breakpoints.queries.md]: {
    width: '28.4%',
  },
})

export const buttonWrapperStyle = {
  margin: '2rem auto 1.7rem',
  display: 'block',
}
