// @flow

import * as React from 'react'
import { connect, createComponent } from 'react-fela'

import { withoutFelaProps } from 'shared/services/fela'
import { Heading, Text } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  as?: string,
  children: React.Node,
  title?: React.Node,
}

const styleRules = ({ theme }) => ({
  ReportCard: {
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px 2px rgba(7,7,8,0.14)',
    className: 'ReportCard',
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    paddingLeft: `calc(${theme.Grid.gutter} * 2)`,
    paddingRight: `calc(${theme.Grid.gutter} * 2)`,
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  heading: {
    className: 'ReportCard__heading',
    marginTop: 0,
    marginBottom: 0,
  },
})

const ReportCard = ({
  as: Component = 'section',
  children,
  rules,
  styles,
  title,
  ...props
}: PropsType) => (
  <Component {...withoutFelaProps(props)} className={styles.ReportCard}>
    {title && (
      <header>
        <Heading level={3} extend={(...args) => rules.heading(...args)}>
          {title}
        </Heading>
      </header>
    )}
    {children}
  </Component>
)

ReportCard.SubHeading = ({ children }) => <Text size="lg">{children}</Text>

ReportCard.Divider = createComponent(
  ({ theme }) => ({
    boxSizing: 'content-box',
    className: 'ReportCard__Divider',
    display: 'block',
    height: 0,
    width: '100%',
    borderColor: theme.palette.dividerColor,
    borderStyle: 'solid',
    borderWidth: '2px',
    borderBottomWidth: 0,
    borderLeftWidth: 0,
    borderRightWidth: 0,
  }),
  'hr',
)

export default connect(styleRules)(ReportCard)
