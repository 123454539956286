/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type AvatarListLoader_data$ref = any;
export type ClinicianIndividualAssignmentDeleteInput = {|
  clientMutationId?: ?string,
  clinician_assignment: ClinicianIndividualAssignmentDeleteInputObject,
|};
export type ClinicianIndividualAssignmentDeleteInputObject = {|
  clinician_id: string,
  individual_id: string,
|};
export type ClinicianIndividualAssignmentDeleteMutationVariables = {|
  individual_id: string,
  clinician_id: string,
  input: ClinicianIndividualAssignmentDeleteInput,
|};
export type ClinicianIndividualAssignmentDeleteMutationResponse = {|
  +clinicianIndividualAssignmentDelete: ?{|
    +clinician_assignment: {|
      +individual: {|
        +assigned_to_clinician: boolean,
        +duty_of_care_clinicians: $ReadOnlyArray<{|
          +$fragmentRefs: AvatarListLoader_data$ref
        |}>,
        +clinicians: $ReadOnlyArray<{|
          +$fragmentRefs: AvatarListLoader_data$ref
        |}>,
      |},
      +clinician: {|
        +id: string,
        +assigned_to_individual: boolean,
        +clinician_assignments_as_clinician: $ReadOnlyArray<{|
          +id: string
        |}>,
      |},
    |}
  |}
|};
export type ClinicianIndividualAssignmentDeleteMutation = {|
  variables: ClinicianIndividualAssignmentDeleteMutationVariables,
  response: ClinicianIndividualAssignmentDeleteMutationResponse,
|};
*/


/*
mutation ClinicianIndividualAssignmentDeleteMutation(
  $individual_id: ID!
  $clinician_id: ID!
  $input: ClinicianIndividualAssignmentDeleteInput!
) {
  clinicianIndividualAssignmentDelete(input: $input) {
    clinician_assignment {
      individual {
        assigned_to_clinician(clinician_id: $clinician_id)
        duty_of_care_clinicians {
          ...AvatarListLoader_data
          id
        }
        clinicians {
          ...AvatarListLoader_data
          id
        }
        id
      }
      clinician {
        id
        assigned_to_individual(individual_id: $individual_id)
        clinician_assignments_as_clinician {
          id
        }
      }
      id
    }
  }
}

fragment AvatarListLoader_data on ClinicianRole {
  id
  user {
    id
    name
    ...AvatarLoader_user
  }
}

fragment AvatarLoader_user on User {
  id
  email
  legal_name
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinician_id"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "individual_id"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "input"
},
v3 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "clinician_id",
      "variableName": "clinician_id"
    }
  ],
  "kind": "ScalarField",
  "name": "assigned_to_clinician",
  "storageKey": null
},
v5 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AvatarListLoader_data"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ClinicianRole",
  "kind": "LinkedField",
  "name": "clinician",
  "plural": false,
  "selections": [
    (v6/*: any*/),
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "individual_id",
          "variableName": "individual_id"
        }
      ],
      "kind": "ScalarField",
      "name": "assigned_to_individual",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ClinicianAssignment",
      "kind": "LinkedField",
      "name": "clinician_assignments_as_clinician",
      "plural": true,
      "selections": [
        (v6/*: any*/)
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v8 = [
  (v6/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "User",
    "kind": "LinkedField",
    "name": "user",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "name",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "email",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "legal_name",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "ClinicianIndividualAssignmentDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ClinicianIndividualAssignmentDeletePayload",
        "kind": "LinkedField",
        "name": "clinicianIndividualAssignmentDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianAssignment",
            "kind": "LinkedField",
            "name": "clinician_assignment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "individual",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "duty_of_care_clinicians",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "clinicians",
                    "plural": true,
                    "selections": (v5/*: any*/),
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "ClinicianIndividualAssignmentDeleteMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "ClinicianIndividualAssignmentDeletePayload",
        "kind": "LinkedField",
        "name": "clinicianIndividualAssignmentDelete",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ClinicianAssignment",
            "kind": "LinkedField",
            "name": "clinician_assignment",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "IndividualRole",
                "kind": "LinkedField",
                "name": "individual",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "duty_of_care_clinicians",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ClinicianRole",
                    "kind": "LinkedField",
                    "name": "clinicians",
                    "plural": true,
                    "selections": (v8/*: any*/),
                    "storageKey": null
                  },
                  (v6/*: any*/)
                ],
                "storageKey": null
              },
              (v7/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6810fe0ee8f59a9cadf56e3f1dcf4cda",
    "id": null,
    "metadata": {},
    "name": "ClinicianIndividualAssignmentDeleteMutation",
    "operationKind": "mutation",
    "text": "mutation ClinicianIndividualAssignmentDeleteMutation(\n  $individual_id: ID!\n  $clinician_id: ID!\n  $input: ClinicianIndividualAssignmentDeleteInput!\n) {\n  clinicianIndividualAssignmentDelete(input: $input) {\n    clinician_assignment {\n      individual {\n        assigned_to_clinician(clinician_id: $clinician_id)\n        duty_of_care_clinicians {\n          ...AvatarListLoader_data\n          id\n        }\n        clinicians {\n          ...AvatarListLoader_data\n          id\n        }\n        id\n      }\n      clinician {\n        id\n        assigned_to_individual(individual_id: $individual_id)\n        clinician_assignments_as_clinician {\n          id\n        }\n      }\n      id\n    }\n  }\n}\n\nfragment AvatarListLoader_data on ClinicianRole {\n  id\n  user {\n    id\n    name\n    ...AvatarLoader_user\n  }\n}\n\nfragment AvatarLoader_user on User {\n  id\n  email\n  legal_name\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '924438924d3841c57198ffb33fca8491';

module.exports = node;
