// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const ErrorIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        x="0px"
        y="0px"
        viewBox="0 0 28 28"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
      >
        <defs>
          <path
            d="M14.0012902,22 C13.4490055,22 13.0012902,21.5522847 13.0012902,21 C13.0012902,20.4477153 13.4490055,20 14.0012902,20 C14.553575,20 15.0012902,20.4477153 15.0012902,21 C15.0012902,21.5522847 14.553575,22 14.0012902,22 Z M14.0012902,7 C14.553575,7 15.0012902,7.44771525 15.0012902,8 L15.0012902,18 C15.0012902,18.5522847 14.553575,19 14.0012902,19 C13.4490055,19 13.0012902,18.5522847 13.0012902,18 L13.0012902,8 C13.0012902,7.44771525 13.4490055,7 14.0012902,7 Z M14,26 C20.627417,26 26,20.627417 26,14 C26,7.372583 20.627417,2 14,2 C7.372583,2 2,7.372583 2,14 C2,20.627417 7.372583,26 14,26 Z M14,28 C6.2680135,28 0,21.7319865 0,14 C0,6.2680135 6.2680135,0 14,0 C21.7319865,0 28,6.2680135 28,14 C28,21.7319865 21.7319865,28 14,28 Z"
            id="error-path-1"
          />
        </defs>
        <g id="Symbols" stroke="none" strokeWidth="1" fillRule="evenodd">
          <g id="icon-/-28x28-/-error">
            <mask id="mask-2" fill="white">
              <use xlinkHref="#error-path-1" />
            </mask>
            <use
              id="Stroke-2991"
              fillRule="nonzero"
              xlinkHref="#error-path-1"
            />
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}

export default ErrorIcon
