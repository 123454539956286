/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ClinicianNoteActivity_user$ref = any;
type UserCardLoader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserActivityCardLoader_user$ref: FragmentReference;
declare export opaque type UserActivityCardLoader_user$fragmentType: UserActivityCardLoader_user$ref;
export type UserActivityCardLoader_user = {|
  +$fragmentRefs: UserCardLoader_user$ref & ClinicianNoteActivity_user$ref,
  +$refType: UserActivityCardLoader_user$ref,
|};
export type UserActivityCardLoader_user$data = UserActivityCardLoader_user;
export type UserActivityCardLoader_user$key = {
  +$data?: UserActivityCardLoader_user$data,
  +$fragmentRefs: UserActivityCardLoader_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserActivityCardLoader_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserCardLoader_user"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "ClinicianNoteActivity_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ffd2c4fba02cfed1696e7a6bdac03728';

module.exports = node;
