// @flow

export const hexToHue = (colorHex: string) => {
  // Convert hex to RGB first
  let r = 0
  let g = 0
  let b = 0
  if (colorHex.length === 4) {
    r = `0x${colorHex[1]}${colorHex[1]}`
    g = `0x${colorHex[2]}${colorHex[2]}`
    b = `0x${colorHex[3]}${colorHex[3]}`
  } else if (colorHex.length === 7) {
    r = `0x${colorHex[1]}${colorHex[2]}`
    g = `0x${colorHex[3]}${colorHex[4]}`
    b = `0x${colorHex[5]}${colorHex[6]}`
  }

  // convert Hexadecimal to Decimal to fix type error
  r = parseInt(r, 16)
  g = parseInt(g, 16)
  b = parseInt(b, 16)

  // Then to Hue
  r /= 255
  g /= 255
  b /= 255
  const colorMin = Math.min(r, g, b)
  const colorMax = Math.max(r, g, b)
  const delta = colorMax - colorMin
  let hue = 0

  if (delta === 0) hue = 0
  else if (colorMax === r) hue = ((g - b) / delta) % 6
  else if (colorMax === g) hue = (b - r) / delta + 2
  else hue = (r - g) / delta + 4

  hue = Math.round(hue * 60)

  if (hue < 0) hue += 360

  return hue
}
