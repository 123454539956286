// @flow

import { graphql } from 'react-relay'

export const fragment = graphql`
  fragment ActivityLogChangeSet_activityLog on ActivityLog {
    action_type
    changeset
  }
`
