// @flow

import React, { type Node } from 'react'

import Button from 'react-ui/components/Button'
import PopOver from 'react-ui/components/PopOver'

type PropsType = {
  children: Node,
  label: string,
}

const containerStyle = ({ theme }) => ({
  backgroundColor: theme.palette.toolTip.backgroundColor,
  borderColor: theme.palette.toolTip.backgroundColor,
  color: theme.palette.toolTip.color,
  ':before': {
    backgroundColor: '#fff',
  },
  fontSize: '14px',
  lineHeight: '21px',
  paddingBottom: '10px',
  paddingTop: '10px',
})

const scrollPaneStyle = ({ theme }) => ({
  maxHeight: 'auto',
  paddingLeft: theme.Grid.gutter,
  paddingRight: theme.Grid.gutter,
})

const triggerStyle = () => ({
  fontSize: '1rem',
})

const TextToolTip = ({ label, children }: PropsType) => {
  return (
    <PopOver
      attachment="bottom left"
      targetAttachment="top right"
      offset="-10px 40px"
      scale={0.5}
      extend={(...args) => ({
        container: containerStyle(...args),
        scrollPane: scrollPaneStyle(...args),
      })}
      renderTrigger={({ onToggleOpen }: { onToggleOpen: () => void }) => (
        <Button
          invert
          naked
          onBlur={onToggleOpen}
          onClick={onToggleOpen}
          onFocus={onToggleOpen}
          extend={triggerStyle}
        >
          {label}
        </Button>
      )}
    >
      {children}
    </PopOver>
  )
}

export default TextToolTip
