// @flow

import { graphql } from 'react-relay'

export const scoreCardsCareOptions_user = {
  user_trackable: graphql`
    fragment ScoreCardsCareOptions_user_trackable on UserTrackable {
      suggested_user_resources {
        ...ScoreCardsCareOptionsList_user_resources @relay(mask: false)
        ...ScoreCardsCareOptionsList_user_resources
      }
    }
  `,
  viewer: graphql`
    fragment ScoreCardsCareOptions_viewer on Viewer {
      ...ScoreCardsCareOptionsList_viewer
    }
  `,
}
