// @flow

import { useState } from 'react'

const useSelectedChips = () => {
  const [selectedItems, setSelectedItems] = useState([])

  const toggleSelectedItems = (
    item: { [key: string]: string },
    toggleDependentItems?: (itemId: string) => void,
  ) => {
    const result = selectedItems.some(i => i.id === item.id)

    const updatedSelectedItems: Array<{ [key: string]: string }> = result
      ? selectedItems.filter(i => i.id !== item.id)
      : [...selectedItems, item]

    setSelectedItems(updatedSelectedItems)

    if (toggleDependentItems) toggleDependentItems(item.id)
  }

  return {
    selectedItems,
    toggleSelectedItems,
  }
}

export default useSelectedChips
