// @flow

import React from 'react'
import { useFela } from 'react-fela'

import ActivityChart from 'react-ui/components/Charts/HighChartOptions/ActivityChart'
import LineChartBackCard from 'react-ui/components/Charts/HighChartOptions/LineChartBackCard'
import SleepChart from 'react-ui/components/Charts/HighChartOptions/SleepChart'
import { useScoreCardChart } from 'react-ui/contexts/ScoreCardChartContext'

import UserTrackableSelectContainer from './UserTrackableSelectContainer'

const containerStyle = ({ theme }) => ({
  borderRadius: '8px',
  backgroundColor: theme.palette.section.white.backgroundColor,
  color: theme.palette.themelet.white.color,
  // By default the height of the chart is driven by the parent element.
  // Giving a minHeight to parent element will stop chart height flickering.
  // So adding default minHeight to UserTrackableChart to fix page jumping as per doco
  // https://api.highcharts.com/highcharts/chart.height
  minHeight: '400px',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
})

const UserTrackableChart = () => {
  const {
    displayActivity,
    displaySleep,
    filterDataSets,
    isFitbitTab,
  } = useScoreCardChart()

  const { css } = useFela()

  return (
    <div className={css(containerStyle)}>
      <UserTrackableSelectContainer
        disabled={isFitbitTab}
        dataSets={filterDataSets}
      >
        <>
          {displaySleep && <SleepChart />}

          {displayActivity && (
            <ActivityChart testId="ActivityChart" />
          )}

          {!isFitbitTab && (
            <LineChartBackCard testId="MoodChart" />
          )}
        </>
      </UserTrackableSelectContainer>
    </div>
  )
}

export default UserTrackableChart
