// @flow

import React, { type Context, type Element } from 'react'

import Menu from 'react-ui/components/Menu'
import UserCard from 'react-ui/components/UserCard'

type ContextType = {
  menu?: Element<typeof Menu>,
  userCard?: Element<typeof UserCard>,
}

export default (React.createContext(
  ({ menu: undefined }: ContextType),
): Context<ContextType>)
