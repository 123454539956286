// @flow

import React from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'

import SkippableProgressBar from 'components/SkippableProgressBar'
import ArrowRight from 'react-ui/assets/icons/arrow-long-right.svg'
import Footer from 'react-ui/components/Footer'
import Icon from 'react-ui/components/Icon'
import { Button, Text } from 'care-ui'

type OnboardingIndividualQuestionnaireFormType = {
  current?: number,
  disabled?: boolean,
  handleSubmit?: () => void,
  total?: number,
}

const buttonContentStyle = () => ({
  display: 'flex',
  width: '55px',
  alignItems: 'center',
  height: '100%',
  justifyContent: 'space-between',
  color: 'white',
})

const footerStyle = ({ theme }) => ({
  Footer: {
    left: 0,
    bottom: 0,
    position: 'fixed',
    width: '100vw',
    zIndex: '2',
  },
  ChildrenWrapper: {
    boxShadow: `0 4px 8px .25rem ${theme.palette.themelet.lightGrey.color}`,
    padding: 0,
  },
})

const footerContentStyle = ({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  width: '85%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  paddingBottom: `calc(${theme.Grid.gutter}/2)`,
  [theme.breakpoints.queries.md]: {
    maxWidth: '680px',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    paddingBottom: theme.Grid.gutter,
  },
})

const footerElementStyle = ({ theme }) => ({
  alignItems: 'flex-start',
  display: 'flex',
  flexDirection: 'column',
  height: 'auto',
  padding: `calc(${theme.Grid.gutter}/2) calc(${theme.Grid.gutter}/2)  0`,
  textAlign: 'center',
  width: '100%',
  [theme.breakpoints.queries.md]: {
    padding: `${theme.Grid.gutter} calc(${theme.Grid.gutter}/2) 0`,
  },
})

const progressPercentStyle = () => ({
  paddingTop: '5px',
})

const footerButtonsStyle = ({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  height: 'auto',
  padding: `calc(${theme.Grid.gutter}/2) calc(${theme.Grid.gutter}/2) 0`,
  textAlign: 'center',
  width: '15%',
  [theme.breakpoints.queries.md]: {
    padding: `${theme.Grid.gutter} calc(${theme.Grid.gutter}/2) 0`,
  },
})

const OnboardingQuestionnaireFooter = ({
  handleSubmit,
  disabled,
  current = 0,
  total = 0,
}: OnboardingIndividualQuestionnaireFormType) => {
  // Localization
  const { t: translation } = useTranslation('onboarding')
  const { css, theme } = useFela()

  const percent = total ? current / total * 100 : 0

  const nextButtonContent = () => (
    <div className={css(buttonContentStyle)}>
      <span>{translation('next')}</span>
      <Icon as={ArrowRight} scale={0.5} />
    </div>
  )

  return (
    <Footer extend={footerStyle} center color="white" noPadding>
      <div className={css(footerContentStyle({ theme }))}>
        <div className={css(footerElementStyle({ theme }))}>
          {total > 1 && (
            <>
              <SkippableProgressBar current={current} total={total} />
              <Text
                as="p"
                size="lg"
                color="title"
                extend={progressPercentStyle}
              >
                {Math.floor(percent)}% {translation('completed')}
              </Text>
            </>
          )}
        </div>

        <div className={css(footerButtonsStyle({ theme }))}>
          {handleSubmit && (
            <Button
              type="submit"
              variant="primary"
              onClick={handleSubmit}
              disabled={disabled}
            >
              {nextButtonContent()}
            </Button>
          )}
        </div>
      </div>
    </Footer>
  )
}

export default OnboardingQuestionnaireFooter
