// @flow

import React from 'react'
import { get } from 'lodash/fp'

import { FlexContainer, Heading } from 'care-ui'

import {
  type DateRangeSelectionType as SelectionTypes,
  ServiceMetricsCliniciansSelector as CliniciansSelector,
  ServiceMetricsDateRangeSelector as DateSelector,
  ServiceMetricsTenantsSelector as TenantsSelector,
} from './selectors'

type SelectedIdsType = $ReadOnlyArray<string>

type DateRangeType = {
  from: Date,
  to: Date,
}

type OpenStateChangeType = (isOpen: boolean) => void

type PropTypes = {
  +clinicians: $ReadOnlyArray<{
    +id: string,
    +name: string,
    +user: {
      +id?: string,
    },
  }>,
  minDate: Date,
  onChangeClinicians: (selectedClinicians: SelectedIdsType) => void,
  onChangeCliniciansOpenState: OpenStateChangeType,
  onChangeDateType: (type: string) => void,
  onChangeDates: (dateRange: DateRangeType) => void,
  onChangeDatesOpenState: OpenStateChangeType,
  onChangeTenants: (selectedTenants: SelectedIdsType) => void,
  onChangeTenantsOpenState: OpenStateChangeType,
  openClinicians?: boolean,
  openTenants?: boolean,
  reportView: string,
  +selectedClinicians: SelectedIdsType,
  selectedDateRange: DateRangeType,
  selectedDateType?: SelectionTypes,
  +selectedTenants: SelectedIdsType,
  +tenants: $ReadOnlyArray<{
    +id: string,
    +name: string,
  }>,
}

const ServiceMetricsFilters = (props: PropTypes) => {
  const {
    clinicians,
    minDate,
    onChangeClinicians,
    onChangeCliniciansOpenState,
    onChangeDates,
    onChangeDateType,
    onChangeTenants,
    onChangeTenantsOpenState,
    openClinicians,
    openTenants,
    selectedClinicians,
    selectedDateType,
    selectedDateRange,
    selectedTenants,
    tenants,
    reportView,
  } = props

  return (
    <FlexContainer
      direction="row"
      wrap="nowrap"
      gap="sm"
      alignItems="center"
      justifyContent="space-around"
    >
      {tenants.length <= 1 && (
        <Heading level={4}>{get('[0].name', tenants)}</Heading>
      )}

      {tenants.length > 1 && (
        <TenantsSelector
          isOpen={openTenants}
          onChange={onChangeTenants}
          onToggleOpen={() => onChangeTenantsOpenState(!openTenants)}
          selectedTenants={
            selectedTenants.length
              ? selectedTenants
              : tenants.map(tenant => tenant.id)
          }
          tenants={tenants}
        />
      )}

      {clinicians.length <= 1 && (
        <Heading level={4}>{get('[0].name', clinicians)}</Heading>
      )}

      {clinicians.length > 1 &&
        (!reportView || (reportView && reportView !== 'CLIENT_INSIGHT')) && (
          <CliniciansSelector
            clinicians={clinicians}
            isOpen={openClinicians}
            selectedClinicians={selectedClinicians}
            onChange={onChangeClinicians}
            onToggleOpen={() => onChangeCliniciansOpenState(!openClinicians)}
          />
        )}

      <DateSelector
        minDate={minDate}
        onChange={onChangeDates}
        onChangeType={onChangeDateType}
        selectionType={selectedDateType}
        selectedDateRange={selectedDateRange}
      />
    </FlexContainer>
  )
}
export default ServiceMetricsFilters
