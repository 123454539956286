/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ScoreCardsPreviousAnswerSet_answer_set$ref = any;
type UserQuestionSetDetailsView_user_question_set$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ScoreCardsPreviousAnswers_user_question_sets$ref: FragmentReference;
declare export opaque type ScoreCardsPreviousAnswers_user_question_sets$fragmentType: ScoreCardsPreviousAnswers_user_question_sets$ref;
export type ScoreCardsPreviousAnswers_user_question_sets = $ReadOnlyArray<{|
  +id: string,
  +submitted_answer_sets: $ReadOnlyArray<{|
    +questionnaire_answer_set_id: string,
    +submitted_at: ?any,
    +id: string,
    +$fragmentRefs: ScoreCardsPreviousAnswerSet_answer_set$ref,
  |}>,
  +question_set: {|
    +id: string,
    +label: string,
  |},
  +$fragmentRefs: UserQuestionSetDetailsView_user_question_set$ref,
  +$refType: ScoreCardsPreviousAnswers_user_question_sets$ref,
|}>;
export type ScoreCardsPreviousAnswers_user_question_sets$data = ScoreCardsPreviousAnswers_user_question_sets;
export type ScoreCardsPreviousAnswers_user_question_sets$key = $ReadOnlyArray<{
  +$data?: ScoreCardsPreviousAnswers_user_question_sets$data,
  +$fragmentRefs: ScoreCardsPreviousAnswers_user_question_sets$ref,
  ...
}>;
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "ScoreCardsPreviousAnswers_user_question_sets",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "AnswerSet",
      "kind": "LinkedField",
      "name": "submitted_answer_sets",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "questionnaire_answer_set_id",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "submitted_at",
          "storageKey": null
        },
        (v0/*: any*/),
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ScoreCardsPreviousAnswerSet_answer_set"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "QuestionSet",
      "kind": "LinkedField",
      "name": "question_set",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "label",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserQuestionSetDetailsView_user_question_set"
    }
  ],
  "type": "UserQuestionSet",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '71713481ef46e6f9a912f872941f3a39';

module.exports = node;
