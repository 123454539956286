// @flow

import * as React from 'react'
import makeRouteConfig from 'found/makeRouteConfig'
import Redirect from 'found/Redirect'
import Route from 'found/Route'
import { get } from 'lodash'

import { DashboardPageQueryLoader } from 'pages/DashboardPage'
import {
  PopUpNotificationShowPreviewLoader,
  PopUpNotificationShowPreviewQuery,
} from 'pages/PopUpNotification/PopUpNotificationShowPreview'
import FrequentlyAskedQuestionsPage from 'pages/public/FrequentlyAskedQuestionsPage'
import LegalPage from 'pages/public/LegalPage'
import {
  QuestionnaireShowPageLoader,
  QuestionnaireShowPageQuery,
  SpecificQuestionnaireShowPageQuery,
} from 'pages/Questionnaire/QuestionnaireShowPage'
import {
  ResourceLibraryPageLoader,
  ResourceLibraryPageQuery,
} from 'pages/ResourceLibraryPage'
import { ResearchDataSharingPageLoader } from 'pages/Role/components/ResearchDataSharingPage'
import { RootAdminPage } from 'pages/RootAdminPage'
import RootPage from 'pages/RootPage'
import {
  RootPlatformPageLoader,
  RootPlatformPageQuery,
} from 'pages/RootPlatformPage'
import ServiceMetricsHomePageLoader, {
  ServiceMetricsHomePageQuery,
} from 'pages/ServiceMetrics'
import { ConnectedTenantsPageLoader } from 'pages/Settings/ConnectedTenantsPage'
import { ConnectedTenantsPageQuery } from 'pages/Settings/ConnectedTenantsPage/queries/ConnectedTenantsPage'
import HealthHistoryPageLoader from 'pages/Settings/HealthHistoryPage'
import { HealthHistoryPageQuery } from 'pages/Settings/HealthHistoryPage/queries/HealthHistoryPage'
import { UserNotificationsPageLoader } from 'pages/Settings/PersonalDetails/components/UserNotificationsPage'
import UserSettingsPage from 'pages/Settings/PersonalDetails/components/UserSettingsPage'
import { UserSettingsPersonalDetailsEditPageLoader } from 'pages/Settings/PersonalDetails/components/UserSettingsPersonalDetailsEditPage'
import { UserSettingsPersonalDetailsPageLoader } from 'pages/Settings/PersonalDetails/components/UserSettingsPersonalDetailsPage'
import { UserSettingsUserFieldPageLoader } from 'pages/Settings/PersonalDetails/components/UserSettingsUserFieldPage'
import { UserSettingsPersonalDetailsEditPageQuery } from 'pages/Settings/PersonalDetails/queries/UserSettingsPersonalDetailsEditPage'
import { UserSettingsPersonalDetailsPageQuery } from 'pages/Settings/PersonalDetails/queries/UserSettingsPersonalDetailsPage'
import { UserSettingsUserFieldPageQuery } from 'pages/Settings/PersonalDetails/queries/UserSettingsUserFieldPage'
import {
  SupportingSupporteeQuestionnaireSubmissionPage,
  SupportingSupporteeSelectionPage,
} from 'pages/Supporting'
import {
  HelpNowProvidersTenantQuery,
  TenantHelpNowPageLoader,
} from 'pages/Tenant/HelpNowPage'
import {
  UserQuestionnairesQuestionSetAnswerPageLoader,
  UserQuestionnairesQuestionSetAnswerPageQuery,
} from 'pages/UserQuestionnairesQuestionSet/UserQuestionnairesQuestionSetAnswerPage'
import { loadingRender } from 'services/routeConfig/services'
import LoadableWelcomePage from 'platform_web/containers/WelcomePage/LoadableWelcomePage'
import { WelcomePageContainerQuery } from 'platform_web/containers/WelcomePage/WelcomePageContainer'
import {
  AssessmentRequestsCheckPage,
  AssessmentRequestsPage,
} from 'platform_web/pages/AssessmentRequests'
import EditRegistrationPage from 'platform_web/pages/authentication/EditRegistration'
import { DashboardPreferences } from 'platform_web/pages/DashboardPreferences'
import FeedbackPage, { FeedbackPageQuery } from 'platform_web/pages/Feedback'
import Insights from 'platform_web/pages/Insights/InsightsPage'

import adminRoutes from './routeConfig/admin'
import authentication from './routeConfig/authentication'
import loadingRoutes from './routeConfig/loading'
import publicRoutes from './routeConfig/public'
import staffRoutes from './routeConfig/staff'
import usersRoutes from './routeConfig/users'

export default makeRouteConfig(
  <Route Component={RootPage}>
    <Route
      path="/active_admin"
      render={() => {
        window.location.reload()
      }}
    />
    {publicRoutes}
    {loadingRoutes}
    <Route path="/users">{authentication}</Route>
    <Route
      // NOTE: this is to reuse the `RootPlatformPageLoader` component
      // (which means to prevent the component being recreated)
      //  even if the route has changed
      key="PlatformRoot"
      Component={RootPlatformPageLoader}
      query={RootPlatformPageQuery}
    >
      <Route
        Component={LoadableWelcomePage}
        path="/"
        name="root"
        query={WelcomePageContainerQuery}
        render={loadingRender(
          { message: 'Welcome' },
          ({ Component, props }) => (
            <Component
              {...(props: any)}
              user={get(props, 'viewer.currentUser')}
              userCurrentRole={get(props, 'viewer.currentUser.current_role')}
            />
          ),
        )}
      />
      <Route Component={LegalPage} path="/resources">
        <Route
          Component={FrequentlyAskedQuestionsPage}
          name="resources_frequently_asked_questions"
          path="/faq"
        />
      </Route>
      <Route Component={RootAdminPage} path="/admin">
        {adminRoutes}
      </Route>
      <Route path="/users">{usersRoutes}</Route>
      <Route path="/staff">{staffRoutes}</Route>
      <Redirect from="/service_metrics" to="/insights" />
      <Route
        Component={ServiceMetricsHomePageLoader}
        query={ServiceMetricsHomePageQuery}
        name="insights"
        path="/insights/:report_view?"
        render={loadingRender(
          { message: 'Insights' },
          ({ Component, props }) => (
            <Component
              {...(props: any)}
              reports={get(props, 'viewer.reports')}
            />
          ),
        )}
      />
      <Route Component={Insights} name="insights_new" path="/insights_new" />
      <Route
        Component={DashboardPageQueryLoader}
        name="dashboard"
        path="/dashboard"
      />
      <Route
        Component={AssessmentRequestsPage}
        name="assessment_requests"
        path="/assessment_requests"
      />
      <Route
        Component={AssessmentRequestsCheckPage}
        name="assessment_requests_check"
        path="/assessment_requests_check/:assessment_request_id"
      />
      <Route
        Component={DashboardPreferences}
        name="dashboard_preferences"
        path="/dashboard/preferences"
      />

      <Route
        Component={QuestionnaireShowPageLoader}
        name="questionnaireShow"
        path="questionnaires/:id"
        query={QuestionnaireShowPageQuery}
        render={loadingRender(
          { message: 'Questionnaire show' },
          ({ Component, props }) => (
            <Component
              {...(props: any)}
              user_questionnaire={get(
                props,
                'viewer.currentUser.user_questionnaire',
              )}
              user={get(props, 'viewer.currentUser')}
              role={get(props, 'viewer.currentUser.current_role')}
              supportees={
                props.viewer.currentUser.current_role.supportees || []
              }
              supportee={null}
            />
          ),
        )}
      />

      <Route
        Component={PopUpNotificationShowPreviewLoader}
        name="notificationPreview"
        path="notification/preview/:id"
        query={PopUpNotificationShowPreviewQuery}
        render={loadingRender(
          { message: 'Notification preview' },
          ({ Component, props }) => (
            <Component
              {...(props: any)}
              notification_preview={get(
                props,
                'viewer.currentUser.notification_preview',
              )}
            />
          ),
        )}
      />

      <Route path="/questionnaires_question_sets">
        <Route
          name="user_questionnaires_question_set_answer"
          // NOTE: this is to reuse the `UserQuestionnairesQuestionSetAnswerPageLoader` component
          // (which means to prevent the component being recreated)
          //  even if the route has changed
          key="user_questionnaires_question_set_answer"
          path="/:questionnaires_question_set_id"
          Component={UserQuestionnairesQuestionSetAnswerPageLoader}
          query={UserQuestionnairesQuestionSetAnswerPageQuery}
          // NOTE: STORE_OR_NETWORK is to tell relay to reuse the mutation response instead of
          // loading the given GraphQL query
          // NOTE: this only works for `relay-runtime` `~5.0.0`
          // please update the implementation accordingly when performing the upgrade
          // NOTE: for the latest `relay-runtime` `10.1.2`, it is:
          // fetchPolicy="store-and-network"
          // @see https://relay.dev/docs/en/v10.1.2/introduction-to-relay
          // @see https://relay.dev/docs/en/query-renderer
          render={loadingRender(
            { message: "User's Questionnaires Question Set" },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user_questionnaires_question_set={get(
                  props,
                  'viewer.currentUser.current_role.user_questionnaires_question_set',
                )}
                answer_set={get(props, 'viewer.enums.answer_set')}
              />
            ),
          )}
        />
        <Route
          name="user_questionnaires_question_set_answer_answeree"
          key="user_questionnaires_question_set_answer_answeree"
          path="/:questionnaires_question_set_id/answeree/:answeree_id"
          Component={UserQuestionnairesQuestionSetAnswerPageLoader}
          query={UserQuestionnairesQuestionSetAnswerPageQuery}
          render={loadingRender(
            { message: "User's Questionnaires Question Set" },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user_questionnaires_question_set={get(
                  props,
                  'viewer.currentUser.current_role.user_questionnaires_question_set',
                )}
                answer_set={get(props, 'viewer.enums.answer_set')}
              />
            ),
          )}
        />
        <Route
          name="user_questionnaires_question_set_answer_assessment_request"
          key="user_questionnaires_question_set_answer_assessment_request"
          path="/:questionnaires_question_set_id/requestee/:assessment_request_id"
          Component={UserQuestionnairesQuestionSetAnswerPageLoader}
          query={UserQuestionnairesQuestionSetAnswerPageQuery}
          render={loadingRender(
            { message: "User's Questionnaires Question Set" },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user_questionnaires_question_set={get(
                  props,
                  'viewer.currentUser.current_role.user_questionnaires_question_set',
                )}
                answer_set={get(props, 'viewer.enums.answer_set')}
              />
            ),
          )}
        />
        <Route
          name="user_questionnaires_question_set_answer_assessment_request_questionnaire"
          key="user_questionnaires_question_set_answer_assessment_request_questionnaire"
          path="/:questionnaires_question_set_id/requestee/:assessment_request_id/questionnaire/:questionnaire_id"
          Component={UserQuestionnairesQuestionSetAnswerPageLoader}
          query={UserQuestionnairesQuestionSetAnswerPageQuery}
          render={loadingRender(
            { message: "User's Questionnaires Question Set" },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user_questionnaires_question_set={get(
                  props,
                  'viewer.currentUser.current_role.user_questionnaires_question_set',
                )}
                answer_set={get(props, 'viewer.enums.answer_set')}
              />
            ),
          )}
        />
      </Route>
      <Route Component={UserSettingsPage} name="settings" path="/settings">
        <Route
          Component={UserSettingsUserFieldPageLoader}
          name="settings_user_field"
          path="/user/:field"
          query={UserSettingsUserFieldPageQuery}
          render={loadingRender(
            { message: 'User field settings' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user={get(props, 'viewer.currentUser')}
              />
            ),
          )}
        />
        <Route
          Component={UserSettingsPersonalDetailsEditPageLoader}
          name="settings_personal_details_field"
          path="/personal_details/:field"
          query={UserSettingsPersonalDetailsEditPageQuery}
          render={loadingRender(
            { message: 'Personal details settings' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                profileQuestionnaire={get(
                  props,
                  'viewer.currentUser.profileQuestionnaire',
                )}
              />
            ),
          )}
        />
        <Route
          Component={UserSettingsPersonalDetailsEditPageLoader}
          name="settings_clinician_detail_field"
          path="/clinician_details/:field"
          query={UserSettingsPersonalDetailsEditPageQuery}
          render={loadingRender(
            { message: 'Personal details settings' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                profileQuestionnaire={get(
                  props,
                  'viewer.currentUser.profileQuestionnaire',
                )}
              />
            ),
          )}
        />
        <Route
          Component={UserSettingsPersonalDetailsPageLoader}
          name="settings_personal_details"
          path="/personal_details"
          query={UserSettingsPersonalDetailsPageQuery}
          render={loadingRender(
            { message: 'Personal details settings' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                userFields={get(props, 'viewer.currentUser')}
                currentRole={get(props, 'viewer.currentUser.current_role')}
                profileQuestionnaire={get(
                  props,
                  'viewer.currentUser.profileQuestionnaire',
                )}
              />
            ),
          )}
        />
        <Route
          Component={ConnectedTenantsPageLoader}
          query={ConnectedTenantsPageQuery}
          name="connected_organisations"
          path="/connected_organisations"
          render={loadingRender(
            { message: 'Connected Organisations and Clinicians' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user={get(props, 'viewer.currentUser')}
              />
            ),
          )}
        />
        <Route
          Component={ResearchDataSharingPageLoader}
          name="research_data_sharing_settings"
          path="/research_data_sharing"
        />
        <Route
          Component={UserNotificationsPageLoader}
          name="notifications_settings"
          path="/notifications"
        />
        <Route
          Component={FeedbackPage}
          name="contact_us"
          path="/contact-us"
          query={FeedbackPageQuery}
        />

        <Route
          name="update-password"
          path="/update-password"
          render={() => <EditRegistrationPage />}
        />
        <Route
          Component={HealthHistoryPageLoader}
          query={HealthHistoryPageQuery}
          name="health-history"
          path="health-history"
          render={loadingRender(
            { message: 'Health History' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user={get(props, 'viewer.currentUser')}
              />
            ),
          )}
        />
      </Route>
      <Redirect
        from="/notifications/:status?"
        to="/staff/notifications/:status?"
      />
      <Route
        Component={ResourceLibraryPageLoader}
        name="resource_library"
        path="/resource_library"
        query={ResourceLibraryPageQuery}
        render={loadingRender({ message: 'Resource Library' })}
      />
      <Route path="/clinician">
        <Redirect from="/individuals" to="/staff/individuals" />
      </Route>
      <Route path="/lead_clinician">
        <Redirect from="/clinicians" to="/staff/clinicians" />
        <Redirect from="/individuals" to="/staff/individuals" />
      </Route>
      <Route
        Component={TenantHelpNowPageLoader}
        name="tenant_help_now"
        path="/tenant/:id/help"
        query={HelpNowProvidersTenantQuery}
        render={loadingRender(
          { message: 'Help providers' },
          ({ Component, props }) => (
            <Component tenant={get(props, 'viewer.tenant')} />
          ),
        )}
      />
      <Route path="/supporting">
        <Route
          Component={SupportingSupporteeSelectionPage}
          name="support_person_supportee_selection"
        />
        <Route
          Component={QuestionnaireShowPageLoader}
          name="supportPersonQuestionnaireShow"
          path="/:id/questionnaires/:questionnaire_id"
          query={SpecificQuestionnaireShowPageQuery}
          render={loadingRender(
            { message: 'Support person questionnaire ' },
            ({ Component, props }) => (
              <Component
                {...(props: any)}
                user={props.user}
                user_questionnaire={props.user.user_questionnaire}
                role={get(props, 'viewer.currentUser.current_role')}
                supportees={
                  props.viewer.currentUser.current_role.supportees || null
                } // so that when supportees in undefined, it will not generate a relay error
                supportee={null}
              />
            ),
          )}
        />
        <Route
          Component={SupportingSupporteeQuestionnaireSubmissionPage}
          name="supportPersonQuestionnaireSubmission"
          path="/:id/submission"
        />
      </Route>
    </Route>
  </Route>,
)
