// @flow

import * as React from 'react'
import cx from 'classnames'

import s from './Circle.scss'

export type CircleSizeType = 'xl' | 'l' | 'ml' | 'm'
export type CircleColorType =
  | 'lightGrey'
  | 'green'
  | 'white'
  | 'primary'
  | 'darkBlue'
  | 'grey'
  | 'secondary'

type PropsType = {
  alignment: 'centered' | 'none',
  children: React.Node,
  className?: string,
  fill: CircleColorType,
  materialShadow?: 'none' | 'green' | 'primary' | 'secondary',
  outline?: CircleColorType | 'none',
  shadow?: boolean,
  size: CircleSizeType,
}

export const Circle = ({
  children,
  fill,
  outline = 'none',
  size,
  shadow,
  alignment,
  className,
  materialShadow = 'none',
}: PropsType) => {
  const classNames = cx(className, {
    [s.Circle]: true,
    [s.fill]: fill !== 'none',
    [s[fill]]: fill !== 'none',
    [s[`outline--${outline}`]]: outline !== 'none',
    [s[size]]: true,
    [s.shadow]: shadow,
    [s.centered]: alignment === 'centered',
    [s[`materialShadow--${materialShadow}`]]: materialShadow !== 'none',
  })

  return <span className={classNames}>{children}</span>
}

Circle.defaultProps = {
  shadow: false,
}
