/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type InitialQuestionnaireUpdateTask_individual$ref: FragmentReference;
declare export opaque type InitialQuestionnaireUpdateTask_individual$fragmentType: InitialQuestionnaireUpdateTask_individual$ref;
export type InitialQuestionnaireUpdateTask_individual = {|
  +tenant: ?{|
    +initial_questionnaire: {|
      +id: string
    |}
  |},
  +$refType: InitialQuestionnaireUpdateTask_individual$ref,
|};
export type InitialQuestionnaireUpdateTask_individual$data = InitialQuestionnaireUpdateTask_individual;
export type InitialQuestionnaireUpdateTask_individual$key = {
  +$data?: InitialQuestionnaireUpdateTask_individual$data,
  +$fragmentRefs: InitialQuestionnaireUpdateTask_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "InitialQuestionnaireUpdateTask_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Tenant",
      "kind": "LinkedField",
      "name": "tenant",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "initial_questionnaire",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '1caf52d2db9394a58b519a9d7779b060';

module.exports = node;
