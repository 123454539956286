// @flow

import React, { type Node } from 'react'
import cx from 'classnames'

import { Input as InputBasic } from 'shared/ui/Basics'
import { Text } from 'care-ui'

import s from './Input.scss'

type PropsType = {
  className?: string,
  classNameContainer?: string,
  errors?: { [key: string]: string },
  label?: Node,
  lightGrayLabel?: boolean,
  name: string,
  onChange?: Function,
  onMouseClick?: Function,
  placeholder?: string,
  required?: true,
  touched?: { [key: string]: boolean },
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week',
  uiStyle?: 'primary' | 'secondary' | 'success' | 'info' | 'warning' | 'danger',
  value?: string | boolean | number,
  white?: boolean,
}

const Input = ({
  type,
  required,
  label,
  name,
  placeholder,
  className,
  classNameContainer,
  uiStyle = 'primary',
  errors,
  touched,
  white,
  lightGrayLabel = false,
  ...props
}: PropsType) => {
  let errorMessage = null
  if (errors && errors[name] && touched && touched[name]) {
    errorMessage = errors[name]
  }

  if (type === 'checkbox') {
    const { value: checked, ...rest } = props

    return (
      <div
        className={cx(s.checkbox, className, {
          [s.white]: white,
        })}
      >
        <InputBasic
          type="checkbox"
          aria-required={required}
          id={name}
          name={name}
          uiStyle={uiStyle}
          className={s.input}
          checked={checked}
          {...(rest: any)}
        />
        <Text as="label" size="lg" htmlFor={name}>
          {label}
        </Text>
        {errorMessage && <span className={s.error}>{errorMessage}</span>}
      </div>
    )
  }

  return (
    <div
      className={cx(s.inputContainer, classNameContainer, {
        [s.white]: white,
      })}
    >
      <Text as="label" size="lg" htmlFor={name}>
        {label}
      </Text>
      <InputBasic
        id={name}
        type={type}
        name={name}
        {...(props: any)}
        aria-required={required}
        placeholder={placeholder}
        uiStyle={uiStyle}
        className={cx(s.input, className, {
          [s[uiStyle]]: true,
          [s.white]: white,
          [s.danger]: errorMessage,
          [s.lightGrayLabel]: lightGrayLabel,
        })}
      />
      {errorMessage && <span className={s.error}>{errorMessage}</span>}
    </div>
  )
}

export default Input
