// @flow

import * as React from 'react'
import { Collapse } from 'react-collapse'
import { connect } from 'react-fela'

import MinusIcon from 'react-ui/assets/icons/minus.svg'
import PlusIcon from 'react-ui/assets/icons/plus.svg'
import ArrowDown from 'react-ui/assets/icons/triangular-arrow-down.svg'
import ArrowRight from 'react-ui/assets/icons/triangular-arrow-right.svg'
import Icon from 'react-ui/components/Icon'
import Themelet from 'react-ui/components/Themelet'
import { withoutFelaProps } from 'shared/services/fela'
import { Text } from 'care-ui'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  as?: string,
  children: React.Node,
  componentId?: string,
  heading: React.Node,
  isOpen?: boolean,
  offButton: React.Node,
  onToggleOpen: () => void,
  shouldOpen?: boolean,
  withArrow?: boolean,
}

const styleRules = ({ theme }) => {
  const paddings = {
    paddingBottom: theme.spacing(0.5),
    paddingLeft: theme.Grid.gutter,
    paddingRight: theme.Grid.gutter,
    paddingTop: theme.spacing(0.5),
  }

  return {
    AccordionItem: {
      className: 'AccordionItem',
      display: 'block',
      marginBottom: theme.spacing(0.5),
    },
    children: {
      className: 'AccordionItem__children',
      display: 'block',
      ...paddings,
    },
    header: {
      alignItems: 'flex-start',
      backgroundColor: 'inherit',
      border: 'none',
      className: 'AccordionItem__header',
      cursor: 'pointer',
      display: 'flex',
      flexFlow: 'row no-wrap',
      fontSize: 'inherit',
      lineHeight: 'inherit',
      justifyContent: 'space-between',
      ...paddings,
      textAlign: 'left',
      width: '100%',
      margin: 0,
    },
    heading: {
      className: 'AccordionItem__heading',
      display: 'block',
      flex: '0 1 100%',
      '-ms-flex': '0 1 auto',
      width: '100%',
    },
    icon: {
      className: 'AccordionItem__icon',
      flex: '0 0 auto',
      marginLeft: theme.Grid.gutter,
      stroke: theme.palette.component.primary.accent,
      strokeWidth: '2px',
      verticalAlign: 'top',
    },
    headerContainer: {
      className: 'AccordionItem__headerContainer',
    },
  }
}

const AccordionItem = ({
  as: Component = 'section',
  children,
  componentId = 'AccordionItem',
  heading,
  isOpen = false,
  onToggleOpen,
  rules,
  styles,
  offButton = '',
  withArrow = false,
  shouldOpen = false,
  ...props
}: PropsType) => {
  const isOpenIcon = withArrow ? ArrowDown : MinusIcon
  const isClosedIcon = withArrow ? ArrowRight : PlusIcon

  React.useEffect(() => {
    const handleScrollEnd = () => {
      if (shouldOpen) onToggleOpen()
    }

    window.addEventListener('scrollend', handleScrollEnd, { once: true })
  }, [])

  return (
    <Component
      {...withoutFelaProps(props)}
      className={styles.AccordionItem}
      data-component-id={componentId}
    >
      <Themelet extend={rules.headerContainer} as="header" variant="default">
        <button
          className={styles.header}
          type="button"
          aria-expanded={isOpen}
          onClick={evt => {
            evt.preventDefault()
            onToggleOpen()
          }}
        >
          <Text size="lg" bold className={styles.heading}>
            {heading}
          </Text>
          <Icon as={isOpen ? isOpenIcon : isClosedIcon} extend={rules.icon} />
        </button>
        {offButton}
      </Themelet>
      <Collapse isOpened={isOpen} forceInitialAnimation>
        <Text className={styles.children}>{children}</Text>
      </Collapse>
    </Component>
  )
}

export default connect(styleRules)(AccordionItem)
