// @flow

import React from 'react'

import { Divider } from 'care-ui'

import BaselineScores from './components/BaselineScores'
import RecoveryTrajectories from './components/RecoveryTrajectories'
import type { OutcomeReportingQueryVariableType } from './hooks/useOutcomeReporting'

type OutcomeReportTypes = {
  outcomeReportQueryVariables: OutcomeReportingQueryVariableType,
}

const Outcomes = (props: OutcomeReportTypes) => {
  const { outcomeReportQueryVariables } = props

  return (
    <>
      <RecoveryTrajectories
        outcomeReportQueryVariables={outcomeReportQueryVariables}
      />
      <Divider />
      <BaselineScores
        outcomeReportQueryVariables={outcomeReportQueryVariables}
      />
    </>
  )
}

export default Outcomes
