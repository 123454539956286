// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highcharts-credits {
  display: none;
}

text {
  font-family: 'Raleway';
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
  color: rgba(126, 125, 141, 1);
}

.pagination-left {
  background-color: '#E6E5FF';
  color: #040068;
}

.pagination-left:disabled {
  background-color: '#B1B0BA';
  color: #040068;
  position: absolute;
  bottom: 0;
}

.sharedTooltip{
  display: inline-block;
  width: 4px; 
  height: 12px; 
  margin-right: 5px;
  border-radius: 2px;
}

.tooltipHeader{
  font-size: 12px;
  font-family: 'Raleway';
  font-weight: 600;
  color: '#040068';
  line-height: 20px;
}

.tooltipTextBody{
  font-size: 14px;
  font-family: 'Raleway';
  color: '#040068';
  line-height: 20px;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  gap: 5px;
}
.tooltipTextValue {
  font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./app/javascript/packs/care-ui/organisms/Graphs/graph.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,sBAAsB;EACtB,gBAAgB;EAChB,eAAe;EACf,iBAAiB;EACjB,6BAA6B;AAC/B;;AAEA;EACE,2BAA2B;EAC3B,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,cAAc;EACd,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,qBAAqB;EACrB,UAAU;EACV,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,QAAQ;AACV;AACA;EACE,gBAAgB;AAClB","sourcesContent":[".highcharts-credits {\n  display: none;\n}\n\ntext {\n  font-family: 'Raleway';\n  font-weight: 400;\n  font-size: 12px;\n  line-height: 20px;\n  color: rgba(126, 125, 141, 1);\n}\n\n.pagination-left {\n  background-color: '#E6E5FF';\n  color: #040068;\n}\n\n.pagination-left:disabled {\n  background-color: '#B1B0BA';\n  color: #040068;\n  position: absolute;\n  bottom: 0;\n}\n\n.sharedTooltip{\n  display: inline-block;\n  width: 4px; \n  height: 12px; \n  margin-right: 5px;\n  border-radius: 2px;\n}\n\n.tooltipHeader{\n  font-size: 12px;\n  font-family: 'Raleway';\n  font-weight: 600;\n  color: '#040068';\n  line-height: 20px;\n}\n\n.tooltipTextBody{\n  font-size: 14px;\n  font-family: 'Raleway';\n  color: '#040068';\n  line-height: 20px;\n}\n\n.contentContainer {\n  display: flex;\n  flex-direction: column;\n  gap: 5px;\n}\n.tooltipTextValue {\n  font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
