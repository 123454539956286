/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type CommonTasksButtons_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type ChartHeader_user$ref: FragmentReference;
declare export opaque type ChartHeader_user$fragmentType: ChartHeader_user$ref;
export type ChartHeader_user = {|
  +$fragmentRefs: CommonTasksButtons_user$ref,
  +$refType: ChartHeader_user$ref,
|};
export type ChartHeader_user$data = ChartHeader_user;
export type ChartHeader_user$key = {
  +$data?: ChartHeader_user$data,
  +$fragmentRefs: ChartHeader_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "ChartHeader_user",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "CommonTasksButtons_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ef1b2d445e475572814701e3deb5c7b3';

module.exports = node;
