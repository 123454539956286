/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type FitbitDisconnectInput = {|
  clientMutationId?: ?string
|};
export type DisconnectFitbitMutationVariables = {|
  input: FitbitDisconnectInput
|};
export type DisconnectFitbitMutationResponse = {|
  +fitbitDisconnect: ?{|
    +user: {|
      +fitbit: {|
        +connected: boolean,
        +connectionUrl: ?string,
      |}
    |}
  |}
|};
export type DisconnectFitbitMutation = {|
  variables: DisconnectFitbitMutationVariables,
  response: DisconnectFitbitMutationResponse,
|};
*/


/*
mutation DisconnectFitbitMutation(
  $input: FitbitDisconnectInput!
) {
  fitbitDisconnect(input: $input) {
    user {
      fitbit {
        connected
        connectionUrl
      }
      id
    }
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "Fitbit",
  "kind": "LinkedField",
  "name": "fitbit",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connected",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "connectionUrl",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "DisconnectFitbitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FitbitDisconnectPayload",
        "kind": "LinkedField",
        "name": "fitbitDisconnect",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "DisconnectFitbitMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "FitbitDisconnectPayload",
        "kind": "LinkedField",
        "name": "fitbitDisconnect",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "User",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "25716f7d257a047090f6de295815450b",
    "id": null,
    "metadata": {},
    "name": "DisconnectFitbitMutation",
    "operationKind": "mutation",
    "text": "mutation DisconnectFitbitMutation(\n  $input: FitbitDisconnectInput!\n) {\n  fitbitDisconnect(input: $input) {\n    user {\n      fitbit {\n        connected\n        connectionUrl\n      }\n      id\n    }\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '1e03c43641ad1e3e1294a69dd968acc3';

module.exports = node;
