// @flow

import React, { type Node, useEffect, useState } from 'react'
import { useFela } from 'react-fela'
import { useTranslation } from 'react-i18next'
import {
  createFragmentContainer,
  graphql,
  useRelayEnvironment,
} from 'react-relay'

import Modal from 'react-ui/components/Modal/Modal'
import * as colors from 'react-ui/themes/synergy-base-theme/colors'
import { Button } from 'care-ui'

import { commit } from '../../mutations/UserNotificationAcknowledge'

import { NotificationCarousel } from './NotificationCarousel'

import { type SystemNotificationPopUp_notifications } from './__generated__/SystemNotificationPopUp_notifications.graphql'

type PropsType = {
  children?: Node,
  notifications: SystemNotificationPopUp_notifications,
}

const modelStyles = () => ({
  Modal: {
    borderRadius: 0,
  },
})

const modelContentStyles = {
  padding: 0,
}

const containerRule = {
  backgroundColor: colors.greyscale['45'],
}

const footerRule = {
  paddingBottom: '40px',
  textAlign: 'center',
}

const SystemNotificationPopUpComponent = ({ notifications }: PropsType) => {
  // Localization
  const { t: translation } = useTranslation('shared')
  const [isOpen, setOpen] = useState(notifications.length > 0)
  const environment = useRelayEnvironment()
  const { css } = useFela()
  const onClose = () => setOpen(false)
  const onAcknowledge = () => {
    onClose()
    commit({
      environment,
      variables: {
        input: {
          ids: notifications.map(n => n.id),
        },
      },
    })
  }

  useEffect(
    () => {
      setOpen(notifications.length > 0)
    },
    [notifications],
  )

  return (
    <Modal
      isOpen={isOpen}
      contentStyle={modelContentStyles}
      onRequestClose={onClose}
      showClose={false}
      showCross={false}
      shrinkwrap
      extend={modelStyles}
    >
      <div className={css(containerRule)}>
        <NotificationCarousel notifications={notifications} />
        <div className={css(footerRule)}>
          <Button onClick={onAcknowledge} variant="primary">
            {translation('lets_go')}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export const SystemNotificationPopUp = createFragmentContainer(
  SystemNotificationPopUpComponent,
  {
    notifications: graphql`
      fragment SystemNotificationPopUp_notifications on PopUpNotification
        @relay(plural: true) {
        id
        description
        image_url
      }
    `,
  },
)
