/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthHistory_user$ref = any;
type MyEscalationsTable_escalations$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthHistoryPage_user$ref: FragmentReference;
declare export opaque type HealthHistoryPage_user$fragmentType: HealthHistoryPage_user$ref;
export type HealthHistoryPage_user = {|
  +escalations: $ReadOnlyArray<{|
    +$fragmentRefs: MyEscalationsTable_escalations$ref
  |}>,
  +$fragmentRefs: HealthHistory_user$ref,
  +$refType: HealthHistoryPage_user$ref,
|};
export type HealthHistoryPage_user$data = HealthHistoryPage_user;
export type HealthHistoryPage_user$key = {
  +$data?: HealthHistoryPage_user$data,
  +$fragmentRefs: HealthHistoryPage_user$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthHistoryPage_user",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Escalation",
      "kind": "LinkedField",
      "name": "escalations",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "MyEscalationsTable_escalations"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthHistory_user"
    }
  ],
  "type": "User",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'cc588c29d41a30c491c3bc0aa9212783';

module.exports = node;
