// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const displayInline = (props: StyleProps) => {
  const { theme } = props

  return {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    maxWidth: '67rem',
    margin: '2rem auto',
    [theme.breakpoints.queries.sm]: {
      flexWrap: 'nowrap',
      justifyContent: 'space-between',
    },
  }
}

export const markdownStyle = () => ({
  display: 'inline',
  '& > p': {
    display: 'inline',
  },
})
