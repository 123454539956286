// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { Container } from 'react-ui/components/Grid'
import { PaginationSearchControl } from 'react-ui/components/Pagination/PaginationSearchControl'

const containerStyle = () => ({
  textAlign: 'center',
  padding: '16px 32px',
  width: '300px',
})

export const AssignmentsSearchControl = () => {
  const { css } = useFela()
  return (
    <Container className={css(containerStyle)}>
      <PaginationSearchControl />
    </Container>
  )
}
