/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
type UserQuestionSetDetailsPage_user$ref = any;
type UserQuestionSetDetailsPage_user_question_set$ref = any;
export type UserQuestionSetDetailsPage_QueryVariables = {|
  id: string,
  question_set_id: string,
|};
export type UserQuestionSetDetailsPage_QueryResponse = {|
  +user: {|
    +user_question_set: {|
      +$fragmentRefs: UserQuestionSetDetailsPage_user_question_set$ref
    |},
    +$fragmentRefs: UserQuestionSetDetailsPage_user$ref,
  |}
|};
export type UserQuestionSetDetailsPage_Query = {|
  variables: UserQuestionSetDetailsPage_QueryVariables,
  response: UserQuestionSetDetailsPage_QueryResponse,
|};
*/


/*
query UserQuestionSetDetailsPage_Query(
  $id: ID!
  $question_set_id: ID!
) {
  user(id: $id, source: "questionnaire") {
    ...UserQuestionSetDetailsPage_user
    user_question_set(question_set_id: $question_set_id) {
      ...UserQuestionSetDetailsPage_user_question_set
      id
    }
    id
  }
}

fragment AnswerSet_answer_set on AnswerSet {
  answers {
    __typename
    ...AnswerTable_answers
    ... on AnswerImperialLength {
      id
    }
    ... on AnswerInteger {
      id
    }
    ... on AnswerMultichoice {
      id
    }
    ... on AnswerMultiselect {
      id
    }
    ... on AnswerString {
      id
    }
  }
  id
  submitted_at
}

fragment AnswerSets_answer_sets on AnswerSet {
  ...AnswerSet_answer_set
  id
  submitted_at
}

fragment AnswerTable_answers on AnswerInterface {
  __isAnswerInterface: __typename
  id
  display_value
  question {
    label
    id
  }
}

fragment UserQuestionSetDetailsPage_user on User {
  id
}

fragment UserQuestionSetDetailsPage_user_question_set on UserQuestionSet {
  id
  submitted_answer_sets {
    ...AnswerSets_answer_sets
    id
  }
  question_set {
    label
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "question_set_id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Literal",
    "name": "source",
    "value": "questionnaire"
  }
],
v2 = [
  {
    "kind": "Variable",
    "name": "question_set_id",
    "variableName": "question_set_id"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "label",
    "storageKey": null
  },
  (v3/*: any*/)
],
v5 = [
  (v3/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UserQuestionSetDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "UserQuestionSet",
            "kind": "LinkedField",
            "name": "user_question_set",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "UserQuestionSetDetailsPage_user_question_set"
              }
            ],
            "storageKey": null
          },
          {
            "args": null,
            "kind": "FragmentSpread",
            "name": "UserQuestionSetDetailsPage_user"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UserQuestionSetDetailsPage_Query",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "User",
        "kind": "LinkedField",
        "name": "user",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": "UserQuestionSet",
            "kind": "LinkedField",
            "name": "user_question_set",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "AnswerSet",
                "kind": "LinkedField",
                "name": "submitted_answer_sets",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "answers",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "__typename",
                        "storageKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "display_value",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "Question",
                            "kind": "LinkedField",
                            "name": "question",
                            "plural": false,
                            "selections": (v4/*: any*/),
                            "storageKey": null
                          }
                        ],
                        "type": "AnswerInterface",
                        "abstractKey": "__isAnswerInterface"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "AnswerImperialLength",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "AnswerInteger",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "AnswerMultichoice",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "AnswerMultiselect",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v5/*: any*/),
                        "type": "AnswerString",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "submitted_at",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "QuestionSet",
                "kind": "LinkedField",
                "name": "question_set",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "983091f118fd04c50e874325ecaafcc2",
    "id": null,
    "metadata": {},
    "name": "UserQuestionSetDetailsPage_Query",
    "operationKind": "query",
    "text": "query UserQuestionSetDetailsPage_Query(\n  $id: ID!\n  $question_set_id: ID!\n) {\n  user(id: $id, source: \"questionnaire\") {\n    ...UserQuestionSetDetailsPage_user\n    user_question_set(question_set_id: $question_set_id) {\n      ...UserQuestionSetDetailsPage_user_question_set\n      id\n    }\n    id\n  }\n}\n\nfragment AnswerSet_answer_set on AnswerSet {\n  answers {\n    __typename\n    ...AnswerTable_answers\n    ... on AnswerImperialLength {\n      id\n    }\n    ... on AnswerInteger {\n      id\n    }\n    ... on AnswerMultichoice {\n      id\n    }\n    ... on AnswerMultiselect {\n      id\n    }\n    ... on AnswerString {\n      id\n    }\n  }\n  id\n  submitted_at\n}\n\nfragment AnswerSets_answer_sets on AnswerSet {\n  ...AnswerSet_answer_set\n  id\n  submitted_at\n}\n\nfragment AnswerTable_answers on AnswerInterface {\n  __isAnswerInterface: __typename\n  id\n  display_value\n  question {\n    label\n    id\n  }\n}\n\nfragment UserQuestionSetDetailsPage_user on User {\n  id\n}\n\nfragment UserQuestionSetDetailsPage_user_question_set on UserQuestionSet {\n  id\n  submitted_answer_sets {\n    ...AnswerSets_answer_sets\n    id\n  }\n  question_set {\n    label\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = '009f1a3df98c022ac0a8fe0860f986b2';

module.exports = node;
