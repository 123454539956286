// @flow

import { graphql } from 'relay-runtime'

export const baselineScoreQuery = graphql`
  query BaselineScoresQuery(
    $quarter_start_date: DateTime!
    $quarter_end_date: DateTime!
    $tenant_id: ID!
  ) {
  viewer {
    outcome_report {
      baseline_scores(
        quarter_start_date: $quarter_start_date
        quarter_end_date: $quarter_end_date
        tenant_id: $tenant_id
      ) {
        series {
          name
          color
          data
        }
        health_domain_label
        total
      }
    }
  }
}
`
