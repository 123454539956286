/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type ChartHeader_user$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserDashboardPage_viewer$ref: FragmentReference;
declare export opaque type UserDashboardPage_viewer$fragmentType: UserDashboardPage_viewer$ref;
export type UserDashboardPage_viewer = {|
  +currentUser: ?{|
    +id: string,
    +$fragmentRefs: ChartHeader_user$ref,
  |},
  +$refType: UserDashboardPage_viewer$ref,
|};
export type UserDashboardPage_viewer$data = UserDashboardPage_viewer;
export type UserDashboardPage_viewer$key = {
  +$data?: UserDashboardPage_viewer$data,
  +$fragmentRefs: UserDashboardPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserDashboardPage_viewer",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "currentUser",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "ChartHeader_user"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ee1407bd749acc97a0b18a9613ad2a1c';

module.exports = node;
