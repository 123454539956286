/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type CareOption_resource$ref: FragmentReference;
declare export opaque type CareOption_resource$fragmentType: CareOption_resource$ref;
export type CareOption_resource = {|
  +description: string,
  +id: string,
  +label: string,
  +url: ?string,
  +$refType: CareOption_resource$ref,
|};
export type CareOption_resource$data = CareOption_resource;
export type CareOption_resource$key = {
  +$data?: CareOption_resource$data,
  +$fragmentRefs: CareOption_resource$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "CareOption_resource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "description",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "label",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "url",
      "storageKey": null
    }
  ],
  "type": "Resource",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'c4987a079ac2a9c92cfa7fb915931f30';

module.exports = node;
