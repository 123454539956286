/*
 * @flow
 * Convert CONSTANT_CASE to Title Case
*/

import { compose, isString, lowerCase, replace, startCase } from 'lodash/fp'

const normalizeInput = input => (isString(input) ? input : '')

export default compose(startCase, lowerCase, replace('_', ' '), normalizeInput)
