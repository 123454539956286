// @flow

import React, { type Node } from 'react'
import { connect } from 'react-fela'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  children: Node,
}
const styleRules = () => ({
  main: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100%',
    height: '100%',
  },
})

const RootPage = (props: PropsType) => {
  const { children, styles } = props

  return (
    <div role="main" className={styles.main}>
      {children}
    </div>
  )
}

export default connect(styleRules)(RootPage)
