// @flow

import { graphql } from 'react-relay'

export const healthCardFragment = graphql`
  fragment HealthCardFooter_user_trackable on UserTrackable {
    ...HealthCardMenu_user_trackable
    participants {
      name
    }
    primary_user_questionnaire {
      questionnaire {
        id
      }
    }
    requires_support
    refresh_required
    trackable {
      id
    }
    user {
      id
    }
  }
`
