// @flow

import { graphql } from 'react-relay'

const RemoveRolesMutation = graphql`
  mutation RemoveRolesMutation($input: RolesRemoveInput!) {
    rolesRemove(input: $input) {
      success
    }
  }
`

export default RemoveRolesMutation
