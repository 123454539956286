// @flow

import { type ThemeType } from 'care-ui/atoms'

export const containerStyle = ({ theme }: { theme: ThemeType }) => ({
  boxSizing: 'border-box',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.care.radius.md,
  color: theme.care.palette.text.positive,
  width: '100%',
})

export const subtitleStyle = ({ theme }: { theme: ThemeType }) => ({
  marginBottom: theme.care.spacing.lg,
})
