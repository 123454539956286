// @flow

import { graphql } from 'react-relay'

export const query = {
  user: graphql`
    fragment AboutMeTab_user on User {
      ...AvatarLoader_user
      legal_name
      profileDisplayFields {
        id
        label
        displayValue
      }
    }
  `,
}
