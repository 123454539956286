// @flow

import { graphql } from 'react-relay'

export const query = graphql`
  query AssessmentRequestsCheckPageQuery($assessmentRequestId: ID!) {
    viewer {
      assessment_request(id: $assessmentRequestId) {
        questionnaire_answer_sets {
          id
        }
      }
    }
  }
`
