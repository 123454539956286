// @flow

import React, { type Node } from 'react'

type PropsType = {
  children?: Node,
  className?: string,
}

/**
 * The styles for this component are defined in the root DataGrid component for performance.
 */
const TextCell = ({ children, className = '' }: PropsType) => (
  <div className={`TextCell ${className}`}>{children}</div>
)

export default TextCell
