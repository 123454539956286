// @flow

import { graphql } from 'react-relay'

export const HealthHistoryPageQuery = graphql`
  query HealthHistoryPage_Query {
    viewer {
      currentUser {
        ...HealthHistoryPage_user
      }
    }
  }
`

export const healthHistoryPageUser = {
  user: graphql`
    fragment HealthHistoryPage_user on User {
      ...HealthHistory_user
      escalations {
        ...MyEscalationsTable_escalations
      }
    }
  `,
}
