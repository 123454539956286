// @flow

import React, { type Element } from 'react'
import { createComponentWithProxy } from 'react-fela'

import { withoutFelaProps } from 'shared/services/fela'

import MenuItem from './MenuItem'
import MenuItemGroup from './MenuItemGroup'

const styleRules = () => ({
  alignItems: 'stretch',
  className: 'Menu',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  width: '100%',
})

// Note: Using let rather than const so that the exported, styled component
// can be used as an allowable child type.
// eslint-disable-next-line import/no-mutable-exports
let StyledMenu

type AllowedChildTypes =
  | Element<typeof MenuItem>
  | Element<typeof MenuItemGroup>
  | Element<typeof StyledMenu>

type PropsType = {
  as?: string,
  children: $ReadOnlyArray<AllowedChildTypes>,
}

const Menu = ({ as: Component = 'div', children, ...props }: PropsType) => (
  <Component {...withoutFelaProps(props)}>
    {React.Children.map(children, child => {
      if (child && child.type === StyledMenu) {
        return React.createElement(MenuItem, child.props)
      }
      return child
    })}
  </Component>
)

StyledMenu = createComponentWithProxy(styleRules, Menu)

export default StyledMenu
