// @flow

import React, { Fragment } from 'react'

const DataSecurityQuestions = [
  {
    key: 'isYourDataSecure',
    question: <Fragment> Is your data secure? </Fragment>,
    answer: (
      <Fragment>
        All personal and health information (data) is stored on computer systems
        located in Australia. The data is secure, highly restricted and
        protected by the highest standards in organisational, physical and
        logical security measures. Data communications between Innowell and
        health services are protected by strong encryption.
      </Fragment>
    ),
  },
  {
    key: 'whatisDataSharing',
    question: <Fragment> What is data sharing? </Fragment>,
    answer: (
      <Fragment>
        Data sharing is an agreement to share health data for research purposes.
        All data is non-identified health data stored in a secure
        password-protected Research Data Store managed by The University of
        Sydney.
      </Fragment>
    ),
  },
  {
    key: 'doYouHaveToShareData',
    question: <Fragment> Do you have to share your data? </Fragment>,
    answer: (
      <Fragment>
        Individuals can use Innowell and choose not to share their data. It is a
        personal choice. To learn more please refer to the Innowell menu on your
        Welcome Back page and go to Profile Settings / Your Data
      </Fragment>
    ),
  },
  {
    key: 'stopSharingData',
    question: <Fragment> Can you stop sharing your data? </Fragment>,
    answer: (
      <Fragment>
        You can stop sharing your data for research purposes at anytime.
        <br />
        <br />
        To learn more or make any required changes please refer to the Innowell
        menu on your Welcome Back page and go to Profile Settings / Your Data
        and follow the prompts.
      </Fragment>
    ),
  },
  {
    key: 'discharged',
    question: (
      <Fragment>
        What happens when you are discharged or change your health provider?
      </Fragment>
    ),
    answer: (
      <Fragment>
        When you are no longer working with a health professional or a health
        service your Innowell account may be switched to Read Only. In Read Only
        mode you can continue to access care options and review your previous
        data. You will not be able to enter new information or update your
        health cards.
      </Fragment>
    ),
  },
]

export default DataSecurityQuestions
