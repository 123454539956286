// @flow

import { commitMutation, graphql } from 'react-relay'

import type {
  EscalationResolveUpdateMutationResponse,
  EscalationResolveUpdateMutationVariables,
} from './__generated__/EscalationResolveUpdateMutation.graphql'

const mutation = graphql`
  mutation EscalationResolveUpdateMutation(
    $input: UserTrackableEscalationResolveInput!
  ) {
    userTrackableEscalationResolve(input: $input) {
      escalations {
        id
        resolving_role {
          __typename
          ... on ClinicianRole {
            id
          }
        }
      }
    }
  }
`

export const commit = ({
  environment,
  onCompleted,
  onError,
  variables,
}: {
  environment: Object,
  onCompleted?: (result: EscalationResolveUpdateMutationResponse) => void,
  onError?: (result: Object) => void,
  variables: EscalationResolveUpdateMutationVariables,
}) => {
  commitMutation(environment, {
    mutation,
    onCompleted,
    onError,
    variables,
  })
}
