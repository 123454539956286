// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer } from 'react-relay'

import Icon from 'react-ui/components/Icon/Icon'
import Can from 'react-ui/utils/Can'
import { ViewerEnumsContext } from 'containers/ViewerEnums'
import { Box, Button, FlexContainer, Modal, ScrollableBox, Text } from 'care-ui'
import Support from 'care-ui/atoms/icons/add-person.svg'
import useModal from 'care-ui/molecules/hooks/useModal'

import AddSupportPersonForm from '../../SupportPersonModal/AddSupportPersonForm'
import ViewSupportPersonInfo from '../../SupportPersonModal/ViewSupportPersonInfo'

import { SupportTaskButtonIndividual } from './query/SupportTaskButton'

import type { SupportTaskButton_individual } from './query/__generated__/SupportTaskButton_individual.graphql'

type PropsType = {
  individual: SupportTaskButton_individual,
}

const SupportTaskButton = ({ individual }: PropsType) => {
  const { isOpen, closeModal, openModal } = useModal()

  const {
    individual_detail,
    support_person_assignments: supportPersonAssignments,
  } =
    individual || {}

  const { t: translation } = useTranslation('shared')

  return (
    <Can>
      {({
        CAN_CREATE_SUPPORT_PERSON_ASSIGNMENTS: canCreate,
        CAN_USE_SUPPORT_PERSON: featureToggle,
      }) => {
        const isReadOnlyIndividual = Boolean(
          individual_detail && individual_detail.read_only,
        )

        if (!featureToggle || isReadOnlyIndividual || !canCreate) return null

        return (
          <>
            <Box marginX="xs">
              <Button
                onClick={openModal}
                variant="secondary"
                dataTestId="supportTaskButton"
                ariaLabel={translation('add_support_person')}
                tooltipText={translation('add_support_person')}
              >
                <Icon as={Support} scale={0.8} />
              </Button>
            </Box>

            <Modal
              heading={translation('add_support_person')}
              closeModal={closeModal}
              isOpen={isOpen}
              dataTestId="add_support_person_modal"
            >
              <FlexContainer direction="column" gap="sm">
                <Text as="p">
                  {translation(
                    'different_perspectives_can_help_add_more_information_to_your_health_profile_they_will_receive_an_email_invite_to_answer_a_questionnaire_about_your_health_and_well_being',
                  )}
                </Text>

                <Text as="p" bold>
                  {translation(
                    'they_will_not_be_able_to_view_any_of_your_health_information_if_you_remove_a_support_person_they_wont_be_notified',
                  )}
                </Text>

                <ViewerEnumsContext.Consumer>
                  {({ relationships }) => (
                    <Box marginY="xs">
                      <AddSupportPersonForm relationships={relationships} />
                    </Box>
                  )}
                </ViewerEnumsContext.Consumer>
              </FlexContainer>

              {supportPersonAssignments && (
                <ScrollableBox maxHeight="30vh" maxWidth="100%">
                  {supportPersonAssignments?.map(assignment => (
                    <ViewSupportPersonInfo
                      key={assignment.id}
                      supportPersonAssignment={assignment}
                      extend={{
                        hr: {
                          borderColor: 'black',
                          margin: '18px 18px 36px 18px',
                        },
                      }}
                    />
                  ))}
                </ScrollableBox>
              )}
            </Modal>
          </>
        )
      }}
    </Can>
  )
}

export default createFragmentContainer(SupportTaskButton, {
  individual: SupportTaskButtonIndividual,
})
