// @flow

import React from 'react'
import { compact, flow, map, uniqBy } from 'lodash/fp'

import { type KeyedAvatarType, AvatarGroup } from 'react-ui/components/Avatar'
import { type PersonProps } from 'react-ui/components/CareOption'
import DashedAvatar from 'react-ui/components/DashedAvatar'

type ParticipantType = KeyedAvatarType | PersonProps | boolean

type ParticipantGroupType = {
  participants: Array<?ParticipantType>,
  withEmptySlot?: boolean,
}

export const ParticipantGroup = ({
  participants,
  withEmptySlot,
}: ParticipantGroupType) => {
  const avatars = flow(compact, uniqBy('id'), map('avatar'), compact)(
    participants,
  )

  if (withEmptySlot) {
    avatars.push({ id: 'empty-avatar', node: <DashedAvatar /> })
  }

  return <AvatarGroup avatars={avatars} />
}
