// @flow

import React, { useState } from 'react'
import { connect } from 'react-fela'
import { useTranslation } from 'react-i18next'
import { createFragmentContainer, graphql } from 'react-relay'

import Button from 'react-ui/components/Button'
import { DropDownItem } from 'react-ui/components/DropDown'
import Heading from 'react-ui/components/Heading'
import Modal from 'react-ui/components/Modal'
import Spacer from 'react-ui/components/Spacer'
import { useDependentToggle } from 'platform_web/hooks/useDependentToggle'

import EditEmrForm from './EditEmrForm'

import type { FelaPropsType } from 'react-ui/typing'
import type { EditEmrModal_individual } from './__generated__/EditEmrModal_individual.graphql'

type PropsType = FelaPropsType & {
  actions?: boolean,
  emrEditable: boolean,
  individual: any,
  relay: Object,
  staff?: boolean,
  targetToggle?: () => void,
}

function styleRules({ theme }) {
  return {
    wrapper: {
      width: '100%',
    },
    link: {
      ...theme.Small,
      height: 'auto',
      minHeight: `${theme.Small.lineHeight}em`,
      textAlign: 'inherit',
      whiteSpace: 'normal',
    },
    staffLink: {
      color: theme.care.palette.text.positive,
      ...theme.care.typography.desktop.bodyLg,
      textDecoration: 'underline',
      ...theme.Small,
      cursor: 'pointer',
    },
    Modal: {
      width: '100%',
      maxWidth: theme.Grid.containerMaxWidth,
      textAlign: 'center',
    },
  }
}

type LinkTextPropType = {
  currentId: ?string,
  emrEditable: boolean,
  emrName: string,
}

export function linkText({
  emrEditable,
  emrName,
  currentId,
}: LinkTextPropType): ?string {
  if (currentId) {
    return `${emrName}: ${currentId}`
  }
  if (emrEditable) {
    return `Add ${emrName}`
  }
  return null
}

type getDetailsPropType = {
  individual_detail: $PropertyType<
    EditEmrModal_individual,
    'individual_detail',
  >,
  tenant: $PropertyType<EditEmrModal_individual, 'tenant'>,
}

type getDetailsReturnType =
  | false
  | {
      currentEmrId: ?string,
      detailsId: string,
      emrName: string,
    }

export function getDetails({
  tenant,
  individual_detail,
}: getDetailsPropType): getDetailsReturnType {
  if (!tenant || !individual_detail) return false
  const { id: detailsId, emr_user_id: currentEmrId } = individual_detail
  const { emr_integration, emr_status: emrStatus } = tenant
  if (emrStatus === 'none' || !emr_integration) return false
  const { emr_provider: { user_id_field_name: emrName } } = emr_integration

  return {
    emrName,
    currentEmrId,
    detailsId,
  }
}

export function EditEmrModalBase({
  emrEditable,
  individual: { tenant, individual_detail },
  relay,
  rules,
  styles,
  staff = false,
  actions = false,
  targetToggle,
}: PropsType) {
  const [isOpen, setIsOpen] = useState(false)

  const onToggleOpen = () => setIsOpen(prev => !prev)

  useDependentToggle(!isOpen, targetToggle)

  // Localization
  const { t: translation } = useTranslation('dataTable')

  const details = getDetails({ tenant, individual_detail })
  if (!details) return null
  const { emrName, currentEmrId, detailsId } = details

  function Text() {
    return (
      <div className={styles.link}>
        {linkText({
          currentId: currentEmrId,
          emrEditable,
          emrName,
        })}
      </div>
    )
  }

  const StaffActionsLabel = `${
    currentEmrId ? translation('edit') : translation('add')
  } ${emrName}`
  const StaffFooterText = `${currentEmrId ||
    `${translation('add')} ${emrName}`}`

  if (!emrEditable) {
    if (!details.currentEmrId) return null
    return <Text />
  }

  return (
    <div className={styles.wrapper}>
      {staff &&
        actions && (
          <DropDownItem onClick={onToggleOpen} label={StaffActionsLabel} />
        )}
      {staff &&
        !actions && (
          <span
            className={styles.staffLink}
            onKeyPress={onToggleOpen}
            tabIndex="0"
            role="button"
            onClick={onToggleOpen}
          >
            {StaffFooterText}
          </span>
        )}
      {!staff && (
        <Button onClick={onToggleOpen} className={styles.link} naked invert>
          <Text />
        </Button>
      )}
      {isOpen && (
        <Modal
          shrinkwrap
          id="edit_emr_modal"
          isOpen={isOpen}
          onRequestClose={onToggleOpen}
          showClose={false}
          extend={{ Modal: rules.Modal }}
        >
          <Heading level={2}>{emrName}</Heading>
          <Spacer units={1} />
          <EditEmrForm
            currentId={currentEmrId}
            emrName={emrName}
            id={detailsId}
            afterSubmit={onToggleOpen}
            relay={relay}
          />
        </Modal>
      )}
    </div>
  )
}

const EditEmrModal = createFragmentContainer(
  connect(styleRules)(EditEmrModalBase),
  {
    individual: graphql`
      fragment EditEmrModal_individual on IndividualRole {
        individual_detail {
          id
          emr_user_id
        }
        tenant {
          emr_integration {
            emr_provider {
              user_id_field_name
            }
          }
          emr_status
        }
      }
    `,
  },
)

export default EditEmrModal
