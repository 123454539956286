// @flow

import { graphql } from 'react-relay'

export const FeedbackPageQuery = graphql`
  query FeedbackPage_Query {
    viewer {
      ...FeedbackPage_viewer
    }
  }
`

export const feedbackPageViewer = {
  viewer: graphql`
    fragment FeedbackPage_viewer on Viewer {
      ...FeedbackForm_viewer
    }
  `,
}
