// @flow

import React from 'react'
import { useTranslation } from 'react-i18next'

import useRegionSelector from 'platform_web/pages/authentication/hooks/useRegionSelector'
import { SimpleSelect } from 'care-ui'

type RegionSelectorProps = {
  email?: string,
}

const RegionSelector = (props: RegionSelectorProps) => {
  const { email } = props
  const { selectedRegion, supportedRegions, updateRegion } = useRegionSelector()

  const { t: translation } = useTranslation('auth')

  const handleOnChange = value => {
    const region =
      supportedRegions.find(item => item.value === value) || supportedRegions[0]

    updateRegion(region, email)
  }

  return (
    <SimpleSelect
      dataTestIdForDropdown="regionSelector"
      dataTestIdForDropdownOptions="regionOption"
      onChange={handleOnChange}
      options={supportedRegions}
      defaultOption={selectedRegion}
      label={translation('select_your_region')}
    />
  )
}

export default RegionSelector
