// @flow

import * as React from 'react'
import { useFela, withTheme } from 'react-fela'

import { Heading } from 'care-ui'

import { type FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  button?: React.Node,
  spread?: boolean,
  text: string,
}

const containerRules = ({ theme }) => ({
  ...theme.Header,
  backgroundColor: 'transparent',
})

const buttonRules = ({ theme }) => ({
  display: 'flex',
  width: '100%',
  justifyContent: 'space-between',
  flexDirection: 'column',
  alignItems: 'flex-start',
  padding: `${theme.care.spacing.md} 0 0`,
  [theme.care.breakpoints.queries.md]: {
    alignItems: 'center',
    flexDirection: 'row',
    padding: 0,
  },
})

function SimpleHeading(props: PropsType) {
  const { text, button, spread, extend } = props
  const { css } = useFela()

  return (
    <div className={css([containerRules, extend])}>
      {spread ? (
        <div className={css(buttonRules)}>
          <Heading level={1}>{text}</Heading>
          <div>{button}</div>
        </div>
      ) : (
        <Heading level={1}>
          {props.text} {props.button}
        </Heading>
      )}
    </div>
  )
}

export default withTheme(SimpleHeading)
