/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type UserResource_user_resource$ref = any;
export type UserResourceStatuses = "completed" | "in_progress" | "not_started" | "skipped" | "support_requested" | "supported" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type UserResourceChangeState_user_resource$ref: FragmentReference;
declare export opaque type UserResourceChangeState_user_resource$fragmentType: UserResourceChangeState_user_resource$ref;
export type UserResourceChangeState_user_resource = {|
  +status: UserResourceStatuses,
  +id: string,
  +resource: {|
    +clinical: boolean
  |},
  +$fragmentRefs: UserResource_user_resource$ref,
  +$refType: UserResourceChangeState_user_resource$ref,
|};
export type UserResourceChangeState_user_resource$data = UserResourceChangeState_user_resource;
export type UserResourceChangeState_user_resource$key = {
  +$data?: UserResourceChangeState_user_resource$data,
  +$fragmentRefs: UserResourceChangeState_user_resource$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "UserResourceChangeState_user_resource",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Resource",
      "kind": "LinkedField",
      "name": "resource",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "clinical",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "UserResource_user_resource"
    }
  ],
  "type": "UserResource",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = 'ee33556758c4a1017ce55b819edc7dca';

module.exports = node;
