// @flow

import React from 'react'
import { connect } from 'react-fela'

import { H4 } from 'react-ui/components/Heading'

import FancyContainer from './FancyContainer'

import type { FelaPropsType } from 'react-ui/typing'

type PropsType = FelaPropsType & {
  componentId?: string,
  lastUpdatedLabel: string,
  routeToBOCByTrackableId: () => void,
  title: string,
}

const styleRules = ({ theme }) => ({
  footer: {
    className: 'FancyHealthCard__footer',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around',
    fontSize: '14px',
    color: theme.palette.component.muted.mutedActive,
  },

  content: {
    textAlign: 'center',
  },

  indicatorHolder: {
    display: 'flex',
    width: '100%',
  },

  trendIndicator: {
    width: '50%',
    textAlign: 'right',
  },

  updatedAt: {
    width: '50%',
  },

  textSeparator: {
    marginLeft: '14px',
    marginRight: '14px',
  },

  buttonStylesOverride: {
    marginTop: theme.spacing(0.5),
    height: 'auto',
  },

  headingStylesOverride: {
    marginTop: 0,
    marginBottom: 0,
    textAlign: 'center',
  },
})

const FancyHealthCardSkipped = ({
  componentId,
  rules,
  title,
  styles,
  lastUpdatedLabel,
  routeToBOCByTrackableId,
}: PropsType) => {
  return (
    <FancyContainer
      componentId={componentId}
      handleClick={routeToBOCByTrackableId}
    >
      <H4 extend={rules.headingStylesOverride}>{title}</H4>
      <div className={styles.content}>
        <p>Questionnaire was skipped.</p>
        <p>Click to see previous results.</p>
      </div>
      <div className={styles.footer}>
        <div className={styles.indicatorHolder}>
          <span className={styles.trendIndicator}>Skipped</span>
          <span className={styles.textSeparator}>|</span>
          <span className={styles.updatedAt}>
            Updated {lastUpdatedLabel} ago
          </span>
        </div>
      </div>
    </FancyContainer>
  )
}

export default connect(styleRules)(FancyHealthCardSkipped)
