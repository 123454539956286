/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type IndividualsAdminInvite_current_role$ref = any;
export type RoleTypes = "ADMIN" | "CLINICIAN" | "INDIVIDUAL" | "LEAD_CLINICIAN" | "MANAGER" | "OWNER" | "RESEARCHER" | "SUPER" | "SUPPORT_PERSON" | "%future added value";
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualsPage_current_role$ref: FragmentReference;
declare export opaque type IndividualsPage_current_role$fragmentType: IndividualsPage_current_role$ref;
export type IndividualsPage_current_role = {|
  +id: string,
  +role_type: RoleTypes,
  +parent: ?{|
    +id?: string
  |},
  +$fragmentRefs: IndividualsAdminInvite_current_role$ref,
  +$refType: IndividualsPage_current_role$ref,
|};
export type IndividualsPage_current_role$data = IndividualsPage_current_role;
export type IndividualsPage_current_role$key = {
  +$data?: IndividualsPage_current_role$data,
  +$fragmentRefs: IndividualsPage_current_role$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndividualsPage_current_role",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "role_type",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "parent",
      "plural": false,
      "selections": [
        {
          "kind": "InlineFragment",
          "selections": [
            (v0/*: any*/)
          ],
          "type": "RoleParentInterface",
          "abstractKey": "__isRoleParentInterface"
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "IndividualsAdminInvite_current_role"
    }
  ],
  "type": "RoleInterface",
  "abstractKey": "__isRoleInterface"
};
})();
// prettier-ignore
(node/*: any*/).hash = '01cd8b47149a043f4ed15bb603f4ecde';

module.exports = node;
