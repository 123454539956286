// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const VideoIcon = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        id="videoIcon"
        version="1.1"
        viewBox="0 0 28 28"
        x="0px"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        xmlSpace="preserve"
        y="0px"
      >
        <g
          id="Video-Controls-inactive_2_"
          transform="translate(486.000000, 14.000000)"
        >
          <g
            id="button-_x2F_-your-video_1_"
            transform="translate(96.000000, 1.000000)"
          >
            <g id="Group-2_1_" transform="translate(21.000000, 20.000000)">
              <g
                id="icon-_x2F_-28x28-_x2F_-video_1_"
                transform="translate(2.000000, 0.000000)"
              >
                <g>
                  <path
                    id="path-1_3_"
                    className="st0"
                    d="M-584.6-23.6l5.1,3.4v-10.5l-5.1,3.4V-23.6z M-584.6-30.4l5.7-3.8c0.8-0.6,2,0,2,1.1v15.3
                    c0,1-1.1,1.6-2,1.1l-5.7-3.8v1.4c0,1.8-1.4,3.2-3.2,3.2h-14c-1.8,0-3.2-1.4-3.2-3.2v-12.7c0-1.8,1.4-3.2,3.2-3.2h14
                    c1.8,0,3.2,1.4,3.2,3.2V-30.4z M-602.5-31.8v12.7c0,0.4,0.3,0.6,0.6,0.6h14c0.3,0,0.6-0.3,0.6-0.6v-12.7c0-0.4-0.3-0.6-0.6-0.6
                    h-14C-602.2-32.5-602.5-32.2-602.5-31.8z"
                  />
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </BaseIcon>
  )
}
export default VideoIcon
