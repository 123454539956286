// @flow

import { type ThemeType } from 'care-ui/atoms'

export const accordionItemStyle = (theme: ThemeType, isOpen: boolean) => ({
  AccordionItem: {
    marginTop: '1.6rem',
    marginBottom: '0',
    backgroundColor: 'white',
    width: 'fit-content',
    [theme.breakpoints.queries.lg]: {
      width: '100%',
    },
  },
  header: {
    backgroundColor: theme.palette.dividerColor,
    flexDirection: 'row-reverse',
    paddingLeft: '0.4rem',
    borderRadius: isOpen ? '4px 4px 0 0' : '4px',
  },
  headerContainer: {
    backgroundColor: 'white',
  },
  heading: {
    fontSize: '1rem',
  },
  icon: {
    marginLeft: '0.3rem',
    margin: '0.4rem 0.9rem 0 0.3rem',
    width: '0.5rem',
    height: '0.5rem',
  },
  children: {
    backgroundColor: theme.palette.dividerColor,
    paddingTop: '0',
    paddingBottom: '1rem',
    paddingLeft: '0.7rem',
    paddingRight: '0.7rem',
    borderRadius: '0 0 4px 4px',
  },
})

export const headerStyle = () => ({
  display: 'flex',
  padding: '0 7rem 0 2rem',
  position: 'relative',
  // top: '-0.4rem',
  '& h4': {
    textAlign: 'center',
    ':first-child': {
      width: '20rem',
      textAlign: 'left',
    },
    ':nth-child(2)': {
      width: '10rem',
    },
    ':nth-child(3)': {
      width: '10rem',
    },
    ':nth-child(4)': {
      width: '20rem',
    },
    ':nth-child(5)': {
      whiteSpace: 'no-wrap',
    },
  },
})

export const wrapperStyle = () => ({
  overflowX: 'auto',
})

export const requestHeadingStyle = () => ({
  display: 'flex',
})

export const buttonStyle = () => ({
  display: 'flex',
  height: 'auto',
  marginLeft: '1rem',
})
