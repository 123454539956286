/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
import type { FragmentReference } from "relay-runtime";
declare export opaque type IndividualCliniciansList_individual$ref: FragmentReference;
declare export opaque type IndividualCliniciansList_individual$fragmentType: IndividualCliniciansList_individual$ref;
export type IndividualCliniciansList_individual = {|
  +id: string,
  +tenant_id: ?string,
  +$refType: IndividualCliniciansList_individual$ref,
|};
export type IndividualCliniciansList_individual$data = IndividualCliniciansList_individual;
export type IndividualCliniciansList_individual$key = {
  +$data?: IndividualCliniciansList_individual$data,
  +$fragmentRefs: IndividualCliniciansList_individual$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "IndividualCliniciansList_individual",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "tenant_id",
      "storageKey": null
    }
  ],
  "type": "IndividualRole",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '8f5fa54ad91efccad2113ebedd277dc4';

module.exports = node;
