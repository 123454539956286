/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ConcreteRequest } from 'relay-runtime';
export type ReportView = "CLIENT_INSIGHT" | "SERVICE_INSIGHT" | "%future added value";
export type SatisfactionAcceptabilityReportQueryVariables = {|
  from: any,
  to: any,
  tenants: $ReadOnlyArray<string>,
  clinicians: $ReadOnlyArray<string>,
  report_view?: ?ReportView,
|};
export type SatisfactionAcceptabilityReportQueryResponse = {|
  +viewer: {|
    +reports: {|
      +satisfaction_care_option_rating: {|
        +traces: $ReadOnlyArray<?{|
          +name: string,
          +type: string,
          +x: $ReadOnlyArray<?string>,
          +y: $ReadOnlyArray<?number>,
        |}>
      |}
    |}
  |}
|};
export type SatisfactionAcceptabilityReportQuery = {|
  variables: SatisfactionAcceptabilityReportQueryVariables,
  response: SatisfactionAcceptabilityReportQueryResponse,
|};
*/


/*
query SatisfactionAcceptabilityReportQuery(
  $from: DateTime!
  $to: DateTime!
  $tenants: [ID!]!
  $clinicians: [ID!]!
  $report_view: ReportView
) {
  viewer {
    reports {
      satisfaction_care_option_rating(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {
        traces {
          name
          type
          x
          y
        }
      }
      id
    }
    id
  }
}
*/

const node/*: ConcreteRequest*/ = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "clinicians"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "from"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "report_view"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tenants"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "to"
},
v5 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "clinicians",
      "variableName": "clinicians"
    },
    {
      "kind": "Variable",
      "name": "from",
      "variableName": "from"
    },
    {
      "kind": "Variable",
      "name": "report_view",
      "variableName": "report_view"
    },
    {
      "kind": "Variable",
      "name": "tenants",
      "variableName": "tenants"
    },
    {
      "kind": "Variable",
      "name": "to",
      "variableName": "to"
    }
  ],
  "concreteType": "ReportsSatisfactionCareOptionRating",
  "kind": "LinkedField",
  "name": "satisfaction_care_option_rating",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "ReportsSatisfactionCareOptionRatingTrace",
      "kind": "LinkedField",
      "name": "traces",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "type",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "x",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "y",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SatisfactionAcceptabilityReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/),
      (v0/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SatisfactionAcceptabilityReportQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Viewer",
        "kind": "LinkedField",
        "name": "viewer",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Reports",
            "kind": "LinkedField",
            "name": "reports",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v6/*: any*/)
            ],
            "storageKey": null
          },
          (v6/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c92e5c6a8bfeef44fd396eeaa101dbd2",
    "id": null,
    "metadata": {},
    "name": "SatisfactionAcceptabilityReportQuery",
    "operationKind": "query",
    "text": "query SatisfactionAcceptabilityReportQuery(\n  $from: DateTime!\n  $to: DateTime!\n  $tenants: [ID!]!\n  $clinicians: [ID!]!\n  $report_view: ReportView\n) {\n  viewer {\n    reports {\n      satisfaction_care_option_rating(from: $from, to: $to, tenants: $tenants, clinicians: $clinicians, report_view: $report_view) {\n        traces {\n          name\n          type\n          x\n          y\n        }\n      }\n      id\n    }\n    id\n  }\n}\n"
  }
};
})();
// prettier-ignore
(node/*: any*/).hash = 'f90b775a83b2f385694e6af42eb16a44';

module.exports = node;
