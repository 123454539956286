// @flow

import React from 'react'
import { useFela } from 'react-fela'

import { dropdownItemStyle } from './DropDownItem.style'

type PropsType = {
  disabled?: boolean,
  key?: string,
  label?: string,
}

const DropDownItem = (props: PropsType) => {
  const { label, key } = props

  const { css } = useFela({ ...props })

  return (
    <button
      {...props}
      key={key}
      className={css(dropdownItemStyle)}
      type="button"
    >
      {label}
    </button>
  )
}

export default DropDownItem
