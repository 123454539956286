// @flow

import { type ThemeType } from 'care-ui/atoms'

type StyleProps = {
  theme: ThemeType,
}

export const styledRules = (props: StyleProps) => {
  const { theme } = props

  return {
    container: {
      display: 'flex',
      flexDirection: 'column',
      maxWidth: '80%',
      [theme.breakpoints.queries.xsOnly]: {
        maxWidth: '100%',
      },
    },
    avatar: {
      display: 'flex',
    },
    wrap: {
      display: 'flex',
      flexDirection: 'row',
      [theme.breakpoints.queries.xsOnly]: {
        flexDirection: 'column',
      },
    },
    row: {
      display: 'flex',
      marginBottom: '10px',
      [theme.breakpoints.queries.xsOnly]: {
        flexDirection: 'column',
      },
    },
    details: {
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    name: {
      margin: 0,
      textAlign: 'right',
      display: 'inline',
      fontWeight: 'bold',
      width: '20%',
      [theme.breakpoints.queries.xsOnly]: {
        textAlign: 'left',
        width: '100%',
      },
    },
    value: {
      margin: 0,
      display: 'inline',
      textAlign: 'left',
      paddingLeft: '10px',
      width: '80%',
      [theme.breakpoints.queries.xsOnly]: {
        paddingLeft: '0',
      },
    },
  }
}
