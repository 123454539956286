// @flow

import React from 'react'

import BaseIcon, { type IconPropsType } from './BaseIcon'

const Profile = (props: IconPropsType) => {
  const { ...otherProps } = props
  return (
    <BaseIcon {...otherProps}>
      <svg
        width="35px"
        height="35px"
        viewBox="0 0 35 35"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs />
        <g
          id="Mobile-INDIVIDUAL"
          stroke="none"
          strokeWidth="1"
          fill="none"
          fillRule="evenodd"
        >
          <path
            d="M27.472,28.121 L22.9,26.343 C23.332,26.244 23.746,26.135 24.143,26.016 C25.366,25.651 26.215,25.272 26.686,24.994 C27.58,24.464 27.65,23.195 26.819,22.572 C26.835,22.585 26.791,22.542 26.701,22.433 C26.503,22.194 26.293,21.864 26.089,21.43 C25.451,20.072 25.053,18.136 25.053,15.515 C25.053,9.582 23.403,7.008 20.649,7.008 C19.642,6.121 18.777,5.833 17.133,5.833 C13.325,5.833 9.214,9.464 9.214,15.515 C9.214,18.136 8.817,20.072 8.178,21.429 C7.973,21.864 7.764,22.194 7.566,22.433 C7.475,22.542 7.43,22.585 7.448,22.571 C6.619,23.192 6.683,24.455 7.571,24.988 C8.038,25.269 8.882,25.649 10.103,26.016 C10.478,26.128 10.87,26.232 11.278,26.327 L7.148,27.767 C4.534,25.132 2.917,21.505 2.917,17.5 C2.917,9.446 9.447,2.916 17.5,2.916 C25.554,2.916 32.083,9.446 32.083,17.5 C32.083,21.692 30.304,25.461 27.472,28.121 M9.867,29.908 L13.502,28.641 C13.966,28.478 14.318,28.094 14.437,27.617 L14.937,25.611 C15.147,24.77 14.581,23.934 13.722,23.814 C12.69,23.671 11.763,23.468 10.94,23.222 C10.82,23.185 10.704,23.149 10.595,23.114 C10.669,22.975 10.743,22.826 10.817,22.671 C11.646,20.908 12.13,18.549 12.13,15.515 C12.13,13.029 12.922,11.258 14.263,10.074 C15.22,9.228 16.443,8.75 17.133,8.75 C18.22,8.75 18.347,8.806 19.038,9.497 C19.312,9.77 19.683,9.924 20.07,9.924 L20.657,9.924 C20.889,9.924 21.035,9.995 21.266,10.355 C21.792,11.177 22.137,12.843 22.137,15.515 C22.137,18.549 22.62,20.908 23.45,22.671 C23.522,22.824 23.595,22.971 23.669,23.109 C23.555,23.146 23.435,23.184 23.309,23.221 C22.487,23.467 21.564,23.669 20.54,23.811 C19.682,23.932 19.118,24.769 19.328,25.61 L19.83,27.618 C19.946,28.077 20.276,28.451 20.717,28.623 L24.7,30.172 C22.575,31.383 20.121,32.083 17.5,32.083 C14.698,32.083 12.089,31.279 9.867,29.908 M17.5,0 C7.836,0 0,7.835 0,17.5 C0,27.165 7.836,35 17.5,35 C27.165,35 35,27.165 35,17.5 C35,7.835 27.165,0 17.5,0"
            id="Fill-1"
            fill="#FFFFFF"
          />
        </g>
      </svg>
    </BaseIcon>
  )
}
export default Profile
