/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type FeedbackForm_viewer$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type FeedbackPage_viewer$ref: FragmentReference;
declare export opaque type FeedbackPage_viewer$fragmentType: FeedbackPage_viewer$ref;
export type FeedbackPage_viewer = {|
  +$fragmentRefs: FeedbackForm_viewer$ref,
  +$refType: FeedbackPage_viewer$ref,
|};
export type FeedbackPage_viewer$data = FeedbackPage_viewer;
export type FeedbackPage_viewer$key = {
  +$data?: FeedbackPage_viewer$data,
  +$fragmentRefs: FeedbackPage_viewer$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FeedbackPage_viewer",
  "selections": [
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "FeedbackForm_viewer"
    }
  ],
  "type": "Viewer",
  "abstractKey": null
};
// prettier-ignore
(node/*: any*/).hash = '6436603fc30fe46f07064c8c459161d0';

module.exports = node;
