// @flow

/**
 * @file Manages transforming questionSets (and questions, answers) into schema formats suitable for https://github.com/mozilla-services/react-jsonschema-form to render
 * @author Sam Richardson
 */

import type {
  QuestionSetType,
  QuestionsType,
} from 'components/UserQuestionnairesQuestionSet/UserQuestionnairesQuestionSet'
import { decodeId } from 'shared/services/base64idHelper'

type excludeNullValuesInputType = { +[string]: * }
type excludeNullValuesReturnType = { +[string]: $NonMaybeType<*> }
type jsonSchemaType = ?{|
  +enum?: ?$ReadOnlyArray<string>,
  +enumNames?: ?$ReadOnlyArray<string>,
  +enumValues?: ?$ReadOnlyArray<number>,
  +feet_max_value?: ?number,
  +feet_min_value?: ?number,
  +inches_max_value?: ?number,
  +inches_min_value?: ?number,
  +maxLength?: ?string,
  +maximum?: ?number,
  +minLength?: ?string,
  +minimum?: ?number,
|}
export type AnswerType =
  | $ReadOnlyArray<string>
  | string
  | number
  | typeof undefined
  | null

export type QuestionPropertyType = {
  conditions: {
    equation: {
      eq?: string,
      formula?: {
        evaluate: ({ [questionId: string]: AnswerType }) => boolean | number,
      },
      questions?: $ReadOnlyArray<string>,
    },
    questionOptions: $ReadOnlyArray<{
      id: string,
      questionId: string,
    }>,
  },
  image?: {
    altTxt: string,
    url: string,
  },
  kind: 'multiselect' | 'multichoice' | 'imperial_length' | 'string' | 'number',
  title: string,
  type: 'array' | 'string' | 'number',
  values: { [questionOptionId: string]: number },
}

export type PropertiesType = {
  [questionId: string]: QuestionPropertyType,
}

type JsonSchemaType = {
  description: string,
  properties: PropertiesType,
  required: Array<?string>,
  title: string,
  type: 'object',
}

/**
 * @function excludeNullValues
 * Removes null key / values from an object.
 */
export const excludeNullValues = (
  obj: ?excludeNullValuesInputType,
): excludeNullValuesReturnType => {
  if (!obj) return {}

  const builder = {}
  Object.keys(obj).forEach(key => {
    const value = obj[key]
    if (value === null) return
    builder[key] = value
  })

  return builder
}

const buildDataReduce = (builder, edge) => {
  if (!edge) return builder
  const { node, answer } = edge
  if (!node || !answer) return builder
  return Object.assign(builder, { [node.id]: answer.value })
}

/**
 * @function buildData
 * Given a questionSet returns data suitable for display in forms.
 */
export const buildData = (
  questions: QuestionsType,
): { [key: string]: string } =>
  ((questions && questions.edges) || []).reduce(buildDataReduce, {})

function mapIdsToValues(jsonSchema) {
  if (!jsonSchema.enum || !jsonSchema.enumValues) return {}
  const builder = {}
  const ids = jsonSchema.enum || []
  const values = jsonSchema.enumValues
  ids.forEach((id, index) => {
    builder[id] = values[index]
  })
  return builder
}

const questionData = (type: string, selections: jsonSchemaType) => {
  switch (type) {
    case 'array':
      return {
        uniqueItems: true,
        items: {
          ...excludeNullValues(selections),
          type: 'string',
        },
      }
    default:
      return excludeNullValues(selections)
  }
}

/**
 * @function buildJsonSchema
 * Given a questionSet, returns a jsonSchema for use in forms.
 */
export const buildJsonSchema = ({
  questionSet,
  questions,
}: {
  questionSet: QuestionSetType,
  questions: QuestionsType,
}): JsonSchemaType => ({
  description: questionSet.description || '',
  required: ((questions && questions.edges) || [])
    .map(e => e?.node)
    .filter(q => q?.required)
    .map(q => q?.id),
  title: questionSet.label,
  type: 'object',
  properties: ((questions && questions.edges) || []).reduce((obj, edge) => {
    if (!edge) return obj
    const { node: question } = edge
    if (!question) return obj

    const kind = question.kind.toLowerCase()
    const type = (() => {
      switch (kind) {
        case 'multichoice':
          return 'string'
        case 'multiselect':
          return 'array'
        case 'imperial_length':
          return 'integer'
        default:
          return kind
      }
    })()

    const conditions = question.condition_scenario
      ? { equation: {} }
      : {
          questionOptions: (question.conditionals || []).map(condition => ({
            questionId: condition.question_option.question.id,
            id: decodeId(condition.question_option.id),
          })),
        }

    const questionDataObject = questionData(type, question.jsonSchema)

    return Object.assign(obj, {
      [question.id]: {
        title: question.label || '',
        image: question.image,
        values: mapIdsToValues(question.jsonSchema || {}),
        conditions,
        type,
        kind,
        ...(questionDataObject: any),
      },
    })
  }, {}),
})

/**
 * @function buildUiSchema
 * Given a question set, builds a uiSchema object suitable for use in forms.
 */
export const buildUiSchema = (
  questions: QuestionsType,
): {
  [key: string]: Object,
} =>
  ((questions && questions.edges) || []).reduce((obj, edge) => {
    if (!edge) return obj
    const { node: question } = edge
    if (!question) return obj

    const uiSchema = {
      'ui:widget': question.uiSchema && question.uiSchema.uiWidget,
    }
    return Object.assign(obj, { [question.id]: excludeNullValues(uiSchema) })
  }, {})
