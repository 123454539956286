// @flow

import { map } from 'lodash/fp'

export const formatDataSet = (
  dataSet: Array<Object>,
  invisible: Array<string>,
) => {
  const formattedDataSet = []

  dataSet.forEach(data => {
    const { trackable, points, answered_by } = data
    if (answered_by?.role_type === 'INDIVIDUAL') {
      const isInvisible = invisible.includes(trackable.label)
      formattedDataSet.push({
        points,
        label: trackable.label,
        isInvisible,
      })
    }
  })

  return formattedDataSet
}

export const formatDataSeries = (dataSet: Array<Object>) => {
  const listSet = []

  dataSet.map(({ points = [], label = '' }) => {
    const set = {}

    const data = map(({ date, scaled_value, raw_score, formula_range }) => {
      const { label: rating, boundUpper, boundLower } = formula_range || {}

      return {
        x: new Date(date).getTime(),
        y: scaled_value,
        tooltip: `${rating}: ${raw_score} (Range ${boundLower} - ${boundUpper})`,
        rating,
      }
    })(points)

    set.name = label
    set.label = label
    set.data = data

    return listSet.push(set)
  })

  return listSet
}
