/**
 * @flow
 */

/* eslint-disable */

'use strict';

/*::
import type { ReaderFragment } from 'relay-runtime';
type HealthCardMenu_user_trackable$ref = any;
import type { FragmentReference } from "relay-runtime";
declare export opaque type HealthCardFooter_user_trackable$ref: FragmentReference;
declare export opaque type HealthCardFooter_user_trackable$fragmentType: HealthCardFooter_user_trackable$ref;
export type HealthCardFooter_user_trackable = {|
  +participants: $ReadOnlyArray<{|
    +name: string
  |}>,
  +primary_user_questionnaire: ?{|
    +questionnaire: ?{|
      +id: string
    |}
  |},
  +requires_support: boolean,
  +refresh_required: boolean,
  +trackable: {|
    +id: string
  |},
  +user: {|
    +id: string
  |},
  +$fragmentRefs: HealthCardMenu_user_trackable$ref,
  +$refType: HealthCardFooter_user_trackable$ref,
|};
export type HealthCardFooter_user_trackable$data = HealthCardFooter_user_trackable;
export type HealthCardFooter_user_trackable$key = {
  +$data?: HealthCardFooter_user_trackable$data,
  +$fragmentRefs: HealthCardFooter_user_trackable$ref,
  ...
};
*/


const node/*: ReaderFragment*/ = (function(){
var v0 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "HealthCardFooter_user_trackable",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "participants",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "UserQuestionnaire",
      "kind": "LinkedField",
      "name": "primary_user_questionnaire",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "Questionnaire",
          "kind": "LinkedField",
          "name": "questionnaire",
          "plural": false,
          "selections": (v0/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "requires_support",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "refresh_required",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Trackable",
      "kind": "LinkedField",
      "name": "trackable",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "HealthCardMenu_user_trackable"
    }
  ],
  "type": "UserTrackable",
  "abstractKey": null
};
})();
// prettier-ignore
(node/*: any*/).hash = '6f99b4388587c0425141365563bd813a';

module.exports = node;
